import React, { Component } from "react";
import * as actions from "../../actions/playlist.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Badge,
} from "shards-react";
import Swal from "sweetalert2";
import ContentPagination from "../Layout/Common/Pagination";

class Playlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playlists: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
    };
  }

  async componentDidMount() {
    //this.props.getPlaylist();
    await this.getPlaylist(this.state.currentPage);
  }

  formatPlaylistType(playlist_display) {
    if (playlist_display) {
      if (playlist_display === "Y")
        return (
          <Badge theme="success" className="font-12">
            Yes
          </Badge>
        );
      else if (playlist_display === "N")
        return (
          <Badge theme="info" className="font-12">
            No
          </Badge>
        );
    }
  }

  async getPlaylist(page) {
    this.setState({ loading: true });

    await this.props.getPlaylist(page);
    const { playlists, total } = this.props.playlistReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.playlistReducer.playlists) {
      this.setState({
        playlists,
        totalPages,
      });
    }
    this.setState({ loading: false });
  }

  async deletePlaylist(playlist_id) {
    await this.props.deletePlaylist(playlist_id);
    setTimeout(() => {
      this.getPlaylist(this.state.currentPage);
    }, 100);
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">Playlist Image</th>
            <th className="text-center">ชื่อ Playlist</th>
            <th className="text-center">รายละเอียด Playlist</th>
            <th className="text-center">หนังสือใน Playlist</th>
            <th className="text-center">แสดงในส่วน 2Read Playlist</th>
            <th className="text-center">แก้ไข/ลบ</th>
          </tr>
        </thead>
        <tbody>
          {this.state.playlists.map((playlist, index) => {
            return (
              <tr key={playlist.playlist_id}>
                <td className="text-center align-middle">
                  <img
                    id="playlist_image"
                    name="playlist_image"
                    src={playlist.playlist_image}
                    width="180px"
                    height="60px"
                    alt=""
                  />
                </td>
                <td className="text-center align-middle">
                  {playlist.playlist_name}
                </td>
                <td className="text-center align-middle" width="180">
                  {" "}
                  {playlist.playlist_desc}
                </td>

                <td className="text-center align-middle">
                  <button
                    type="button"
                    className="btn btn-sm btn-warning mr-2"
                    onClick={() =>
                      this.props.history.push(
                        `/playlists/detail/${playlist.playlist_id}`
                      )
                    }
                  >
                    หนังสือใน Playlist
                  </button>
                </td>

                <td className="text-center align-middle">
                  {" "}
                  {this.formatPlaylistType(playlist.playlist_display)}
                </td>

                <td className="text-center align-middle">
                  <button
                    type="button"
                    className="btn btn-sm btn-success mr-2"
                    onClick={() =>
                      this.props.history.push(
                        `/playlists/edit/${playlist.playlist_id}`
                      )
                    }
                  >
                    Edit
                  </button>

                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure to delete?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes, delete it!",
                        cancelButtonText: "No, cancel!",
                      }).then((result) => {
                        if (result.value) {
                          this.deletePlaylist(playlist.playlist_id);
                        }
                      });
                    }}
                    type="button"
                    className="btn btn-sm  btn-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getPlaylist(page);
  }

  render() {
    return (
      <Container fluid>
        <div style={{ padding: 20 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Playlist</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => this.props.history.push(`/playlists/create`)}
                    className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add Playlist
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.playlists.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ playlistReducer }) => ({
  playlistReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);

import {
  HTTP_BOOK_CHARACTER_FETCHING,
  HTTP_BOOK_CHARACTER_SUCCESS,
  HTTP_BOOK_CHARACTER_FAILED,
  HTTP_BOOK_CHARACTER_ADDED,
  HTTP_BOOK_CHARACTER_UPDATED,
  HTTP_BOOK_CHARACTER_DELETED,
} from "../constants/book";
  
  const initialState = {
    characters: [],
    isFetching: false,
    isError: false
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_BOOK_CHARACTER_SUCCESS:
        return { 
          ...state, 
          characters: payload, 
          isFetching: false, 
          isError: false 
      };
      case HTTP_BOOK_CHARACTER_FAILED:
        return { ...state, characters: [], isFetching: false, isError: true };
      case HTTP_BOOK_CHARACTER_FETCHING:
        return { ...state, characters: [], isFetching: true, isError: false };
      case HTTP_BOOK_CHARACTER_ADDED: {
        const characters = [...state.characters, payload]

        return {
          ...state,
          characters
        };
      }
      case HTTP_BOOK_CHARACTER_UPDATED: {
        const characters = state.characters

        const updatedCharacters = characters.map(item => {
          let character = item
          
          if(item.id === payload.id)  {
            character = payload
          }

          return character
        })

        return {
          ...state,
          characters: updatedCharacters
        };
      }
      case HTTP_BOOK_CHARACTER_DELETED: {
        const characters = state.characters

        const updatedCharacters = characters.filter(item => item.id !== payload)

        return {
          ...state,
          characters: updatedCharacters
        };
      }
      default:
        return state;
    }
  };
  
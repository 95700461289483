import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormGroup,
  FormSelect
} from "shards-react";
// import { Form } from "react-bootstrap";
import * as actions from "../../actions/user.action";
import { connect } from "react-redux";

class userFrom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: []
    }
  }

  async componentDidMount() {
    await this.props.getAllUserRole()

    if(this.props.userReducer.roles)  {
      this.setState({roles: this.props.userReducer.roles})
    }
  }

  render() {
    const { values, handleChange, isDisableField } = this.props;
    
    return (
      <React.Fragment>
        <fieldset disabled={isDisableField}>
          <Row form>
            <Col md="6" className="form-group">
              <label>Username</label>
              <FormInput
                name="user_username"
                id="user_username"
                placeholder="Username"
                onChange={handleChange}
                value={values.user_username}
              />
            </Col>
            <Col md="6">
              <label>Password</label>
              <FormInput
                type="password"
                name="user_admin_password"
                id="user_admin_password"
                placeholder="password"
                autoComplete="new-password"
                onChange={handleChange}
                value={values.user_admin_password}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>Firstname</label>
              <FormInput
                placeholder="First Name"
                name="user_firstname"
                id="user_firstname"
                onChange={handleChange}
                value={values.user_firstname}
              />
            </Col>
            <Col md="6">
              <label>Lastname</label>
              <FormInput
                name="user_lastname"
                id="user_lastname"
                placeholder="Last Name"
                onChange={handleChange}
                value={values.user_lastname}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <FormGroup>
                <label>Email</label>
                <FormInput
                  name="user_email"
                  id="user_email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={values.user_email}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Phone</label>
                <FormInput
                  name="user_phone"
                  id="user_phone"
                  placeholder="Phone"
                  onChange={handleChange}
                  value={values.user_phone}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <FormGroup>
                <label>User Role</label>
                <FormSelect
                  name="user_role"
                  id="user_role"  
                  onChange={handleChange}
                  value={values.user_role}
                >
                  { this.state.roles.map(role => {
                      return (
                        <option value={role.role_id}>{role.role_name}</option>
                      )
                    })
                  }
                </FormSelect>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer}) => ({ userReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(userFrom);
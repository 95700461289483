import { httpClient } from "./../utils/HttpClient";

import {
    HTTP_REPORT_PURCHASE_SUCCESS,
    HTTP_REPORT_PURCHASE_FETCHING,
    HTTP_REPORT_PURCHASE_FAILED,

    server,
} from "../constants";

/* REPORT PURCHASE ACTION */
const setStateReportPurchaseToSuccess = (payload) => ({
  type: HTTP_REPORT_PURCHASE_SUCCESS,
  payload: payload,
});

const setStateReportPurchaseToFetching = () => ({
  type: HTTP_REPORT_PURCHASE_FETCHING,
});

const setStateReportPurchaseToFailed = () => ({
  type: HTTP_REPORT_PURCHASE_FAILED,
});


/* Get Report Purchase */
export const getReportPurchase = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportPurchaseToFetching());
    await doGetReportPurchase(dispatch,value);
  };
};

const doGetReportPurchase = (dispatch, value) => {

  let dateFilter = "";
  if (value) {
  
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if(!value.start)
        dateFilter += "?end_date=" + value.end + " 23:59.00";
      else
        dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
    //alert(dateFilter)

    // if (value.transaction_type) {
    //   dateFilter = "?transaction_type=" + value.transaction_type;
    // }
  }

  //alert(dateFilter)
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/purchase` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateReportPurchaseToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateReportPurchaseToFailed());
    });
};


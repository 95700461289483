import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../../constants";
import { Card, CardHeader, CardBody, Row, Col } from "shards-react";
import { httpClient } from "../../../../utils/HttpClient";
import Swal from "sweetalert2";
import ChapterForm from "../chapterForm";
import imageCompression from "browser-image-compression";
import {
  getChapterByID,
  getChapterByBookID,
} from "../../../../actions/book_chapter.action";
import { getBookByID } from "../../../../actions/book.action";
import * as Yup from "yup";
import moment from "moment";
import { connect } from "react-redux";
import { Button } from 'antd';

const chapterValidationSchema = Yup.object().shape({
  chaptor: Yup.string().required("กรุณาระบุ Chapter Number"),
  chaptor_title: Yup.string().required("กรุณาระบุ Chapter Title"),
  chaptor_price: Yup.string().required("กรุณาระบุ Chapter Price"),
  silver_coin_use_checked: Yup.boolean(),
  chaptor_silver_price: Yup.string().when('silver_coin_use_checked', {
    is: true,
    then: Yup.string().required('กรุณาระบุ ราคา (เหรียญเงิน)'),
    otherwise: Yup.string()
  }),
  published_date: Yup.string().required("กรุณาเลือกวันที่เผยแพร่"),
});

class EditChapter extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      chapter: "",
      page:"",
      bookPage: "",
      characters: [],
      isChatBook: false,
      chapterContent: '',
      chapterThumbnailFile: '',
      chapterTime: null,
      audioUrl: ""
    };

    this.updateMainCharacter = this.updateMainCharacter.bind(this)
    this.updateContent = this.updateContent.bind(this)
    this.updateTime = this.updateTime.bind(this)
    this.updateThumbnail = this.updateThumbnail.bind(this)
    this.removeThumbnail = this.removeThumbnail.bind(this)
    this.updateAudio = this.updateAudio.bind(this)
  }

  async componentDidMount() {
    let book_chaptor_id = this.props.match.params.chapter_id;
    await this.props.getChapterByID(book_chaptor_id);

    const chapter = this.props.bookChapterReducer.chapter;

    if (chapter) {
      await this.props.getBookByID(this.props.bookChapterReducer.chapter.book_id);

      if(this.props.bookReducer.book) {
        const isChatBook = (this.props.bookReducer.book.categories.filter(item => item.category_id === 12).length > 0)

        await this.setState({
          isChatBook,
          characters: this.props.bookReducer.book.characters,
          book_id: this.props.bookReducer.book.book_id,
        });
      }

      this.setState({ 
        chapter: this.props.bookChapterReducer.chapter,
        chapterTime: new Date(this.props.bookChapterReducer.chapter.published_date)
      });
    }

    const { page, bookPage } = this.props.location
      await this.setState({ page, bookPage});
  }

  updateThumbnail(preview, file) {
    const chapter = this.state.chapter
    chapter.chapter_thumbnail = preview
    this.setState({ 
      chapter: chapter,
      chapterThumbnailFile: file 
    });
  } 

  removeThumbnail() {
    const chapter = this.state.chapter
    chapter.chapter_thumbnail = ""
    this.setState({ 
      chapter: chapter,
      chapterThumbnailFile: null
    });
  }

  updateAudio(url) {
    this.setState({audioUrl: url})
  }

  updateContent(html) {
    const chapter = this.state.chapter
    chapter.chapter_content = html
    this.setState({ chapter });
  } 

  updateTime(time)  {
    this.setState({ chapterTime: time });
  }

  updateMainCharacter(id) {
    const chapter = this.state.chapter
    chapter.main_character = id

    this.setState({chapter})
  }

  async uploadImage(image) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
      initialQuality: 0.8
    }
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, {type: image.type});

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        const token = localStorage.getItem("token");
        formData.append("upload", imagefile);
        
        const response = await httpClient.post(server.IMG_URL, formData, {
          headers: {
            enctype: "multipart/form-data",
            "Authorization": `Bearer ${token}`
          },
        });

        if (response.status === 201 || response.status === 200) {
          return response.data.url
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง",
        timer: 3000
      });
    }
  }

  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 10 }}>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <Card>
            <CardHeader className="border-bottom">
              <div className="d-flex align-items-center">
                <h6 className="m-0 flex-fill">EDIT CHAPTER</h6>
                <Button
                  type="button"
                  loading={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="btn btn-primary mr-2"
                >
                  บันทึก
                </Button>
                <Button
                  type="button"
                  loading={isSubmitting}
                  className="btn btn-secondary mr-2"
                  onClick={() => {
                    values.book_chaptor_status = 'A'
                    handleSubmit();
                  }}
                >
                  บันทึกและเผยแพร่
                </Button>
              </div>
            </CardHeader>
            <CardBody className="pt-3">
              <Row>
                <Col>
                  {this.state.chapter ? (
                    <ChapterForm
                      values={{ 
                        ...values,
                        isChatBook: this.state.isChatBook
                      }}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      onContentChange={this.updateContent}
                      onTimeChange={this.updateTime}
                      onUpdateThumbnail={this.updateThumbnail}
                      onRemoveThumbnail={this.removeThumbnail}
                      onUpdateAudio={this.updateAudio}
                      handleMainCharaterChange={this.updateMainCharacter}
                      errors={errors}
                      touched={touched}
                    ></ChapterForm>
                  ) : null}

                  <div className="text-center mt-4">
                    <Button
                      type="button"
                      loading={isSubmitting}
                      className="btn btn-primary mr-2"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      บันทึก
                    </Button>
                    <Button
                      type="button"
                      loading={isSubmitting}
                      className="btn btn-secondary mr-2"
                      onClick={() => {
                        values.book_chaptor_status = 'A'
                        handleSubmit();
                      }}
                    >
                      บันทึกและเผยแพร่
                    </Button>
                    <a
                      onClick={() => {
                        this.props.getChapterByBookID(values.book_id);
                        this.props.history.push({
                          pathname: '/book_chapter/' + this.state.chapter.book_id,
                          page: this.state.page 
                        })
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </a>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </div>
    );
  };

  onSendNotification() {
    Swal.fire({
      title: "Push notification",
      text: "ยืนยันการส่ง Push Notification ให้ User ที่ติดตามหนังสือเล่มนี้?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ส่ง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.sendNewChapterNotification();
      }
    });
  }

  async sendNewChapterNotification() {
    let params = {
      bookId: this.state.chapter.book_id,
      bookChapterId: this.state.chapter.book_chaptor_id,
    };

    const token = localStorage.getItem("token");
    let response = await httpClient.post(
      `admin/member/push-notification/new-chapter`,
      JSON.stringify(params),
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.chapter ? (
          <Formik
            enableReinitialize
            initialValues={
              this.state.chapter ?
              { 
                ...this.state.chapter, 
                free_only_member_checked: this.state.chapter.free_only_member === 'Y' ? true : false,
                silver_coin_use_checked: this.state.chapter.silver_coin_use === 'Y' ? true : false,
                characters: this.state.characters
              } 
              : {}}
            validationSchema={chapterValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              let username = "";
              let result = localStorage.getItem("user_profile");
              if (result) username = JSON.parse(result).user_username;

              let price = Number(
                values.chaptor_price.toString().replace(/,/g, "")
              );

              let price_silver = Number(
                values.chaptor_silver_price.toString().replace(/,/g, "")
              );

              let price_audio_gold = "";
              if(values.audio_gold_price)
              {
                  price_audio_gold = Number(values.audio_gold_price.toString().replace(/,/g, ""));
              }
              else
                price_audio_gold = 0;

              let price_audio_silver = "";
              if(values.audio_silver_price)
              {
                price_audio_silver = Number(values.audio_silver_price.toString().replace(/,/g, ""));
              }
              else
                price_audio_silver  = 0;

              //package
              let price_package_gold = "";
              let price_package_silver = "";

              if(values.package_gold_price)
              {
                price_package_gold = Number(values.package_gold_price.toString().replace(/,/g, ""));
              }
              else
                price_package_gold = 0;

              if(values.package_silver_price)
              {
                price_package_silver = Number(values.package_silver_price.toString().replace(/,/g, ""));
              }
              else
                price_package_silver = 0;

       
  
              let updateDate = new Date();
              updateDate = moment(updateDate).format("YYYY-MM-DD HH:mm:ss");
                
              values.published_date = new Date(values.published_date)
              let startHour = 0
              let startMinute = 0

              if(this.state.chapterTime)  {
                startHour = this.state.chapterTime.getHours();
                startMinute = this.state.chapterTime.getMinutes();
              }

              values.published_date.setHours(startHour);
              values.published_date.setMinutes(startMinute);

              values.free_only_member =  values.free_only_member_checked ? 'Y' : 'N'
              values.silver_coin_use =  values.silver_coin_use_checked ? 'Y' : 'N'

              if(this.state.chapterThumbnailFile) {
                values.chapter_thumbnail = await this.uploadImage(this.state.chapterThumbnailFile)
              }

              let params = {
                chaptor: values.chaptor,
                book_id: values.book_id,
                book_chaptor_name: values.book_chaptor_name,
                chaptor_title: values.chaptor_title,
                published_date: values.published_date,
                chaptor_price: price || 0,
                chaptor_silver_price: price_silver || 0,
                chaptor_content: this.state.chapter.chapter_content,
                book_chaptor_status: values.book_chaptor_status,
                free_only_member: values.free_only_member,
                updated_by: username,
                updated_datetime: updateDate,
                silver_coin_use: values.silver_coin_use,
                chapter_audio_file: this.state.audioUrl,
                chapter_content_type: values.chapter_content_type,
                chapter_thumbnail: values.chapter_thumbnail,
                audio_gold_price: price_audio_gold || 0,
                audio_silver_price: price_audio_silver || 0,
                package_gold_price: price_package_gold || 0,
                package_silver_price: price_package_silver || 0,
              };

              const token = localStorage.getItem("token");
              let response = await httpClient.patch(
                server.BOOK_CHAPTER_URL + "/" + `${values.book_chaptor_id}`,
                JSON.stringify(params),
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                  },
                }
              );

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "ข้อมูล ถูกบันทึกเรียบร้อยแล้ว",
                  showConfirmButton: false,
                  timer: 3000,
                });
                setSubmitting(false);
                this.props.getChapterByBookID(values.book_id);

                if (params.book_chaptor_status === "A") {
                  // this.onSendNotification();
                }

                this.props.history.push({
                  pathname: '/book_chapter/' + this.state.chapter.book_id,
                  page: this.state.page ,
                  bookPage: this.state.bookPage 
                })
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                  timer: 3000,
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookChapterReducer, bookReducer }) => ({ bookChapterReducer, bookReducer });

const mapDispatchToProps = {
  getChapterByID,
  getChapterByBookID,
  getBookByID
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChapter);

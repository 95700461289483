import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormGroup,
  FormTextarea,
  DatePicker,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,
} from "shards-react";
import NumberFormat from "react-number-format";
import { getCategory } from "../../actions/category.action";
import { getBooks } from "../../actions/book.action";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import { httpClient } from "./../../utils/HttpClient";
import { server } from "./../../constants";
import moment from "moment";

class CampaignFrom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pushTarget: "",
      books: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      value: "",
      campaign_repeat: "",
      img_src_campaign: "",
    };
    this.uploadImage = this.uploadImage.bind(this);
  }

  async uploadImage(image) {
    // Call Api For Upload Image
    if (image) {
      var formDataImg = new FormData();
      var imagefile = image;
      formDataImg.append("upload", imagefile);

      let response_img = await httpClient.post(server.IMG_URL, formDataImg, {
        headers: {
          processData: false,
          enctype: "multipart/form-data",
        },
      });

      if (response_img.status === 201 || response_img.status === 200) {
        if (response_img.data.uploaded === 1) {
          this.setState({
            img_src_campaign: response_img.data.url,
          });
          this.props.change(response_img.data.url);
        }
      }
    }
  }

  async componentDidMount() {
    let initialValues = this.props.values;
    await this.props.getBooks();
    this.props.getCategory();

    if (initialValues.days_goal > 0) this.props.values.pushTarget = "checkin";
    else if (initialValues.chapter_goal > 0)
      this.props.values.pushTarget = "chapter";
    else if (initialValues.amount_goal > 0)
      this.props.values.pushTarget = "topup";

    if (initialValues.book_id) this.setState({ bookId: initialValues.book_id });

    if (initialValues.end_date) {
      let endDate = moment(initialValues.end_date).format("YYYY-MM-DD");
      this.props.values.end_date = endDate;
    }

    if (initialValues.member_silver_coin_expire) {
      let expireDate = moment(initialValues.member_silver_coin_expire).format(
        "YYYY-MM-DD"
      );
      this.props.values.member_silver_coin_expire = expireDate;
    }

    await this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
      pushTarget: "book",
    });

    if (this.props.values.book_title) {
      await this.setState({
        value: this.props.values.book_title,
      });
    }
  }

  bindingCategoryName = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.categoryReducer;

      const options = result.map((item) => (
        <option key={item.value} value={item.category_id}>
          {item.category_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="category_id"
            id="category_id"
            placeholder=""
            onChange={handleChange}
            value={values.category_id}
          >
            <option value="">Select Category</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
    this.props.values.book_id = suggestion.book_id;
    this.props.values.book_title = suggestion.book_title;
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  renderTargetOption() {
    let optionList = [
      {
        value: "",
        label: "เลือกเงื่อนไขในการรับเหรียญ",
      },
      {
        value: "checkin",
        label: "Check in",
      },
      {
        value: "chapter",
        label: "จำนวน chapter ที่ต้องซื้อ",
      },
      {
        value: "topup",
        label: "จำนวนยอดเงินที่ต้องเติม",
      },
    ];

    let optionElements = optionList.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ));

    return optionElements;
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };

    const {
      values,
      handleChange,
      isDisableField,
      setFieldValue,
      errors,
      touched,
    } = this.props;

    const ImageCampaign = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            id="campaign_image"
            name="campaign_image"
            src={value}
            className="img-fluid"
          />
        );
      } else {
        return (
          <img
            id="campaign_image"
            name="campaign_image"
            src={this.state.img_src_campaign}
            className="img-fluid"
          />
        );
      }
    };

    return (
      <React.Fragment>
        <fieldset disabled={isDisableField}>
          <Row form>
            <Col md="12" className="form-group">
              <label>ชื่อ Campaign</label>
              <FormInput
                name="campaign_name"
                id="campaign_name"
                onChange={handleChange}
                value={values.campaign_name}
              />
              {errors.campaign_name && touched.campaign_name ? (
                <div className="font-12 text-primary mt-1">
                  {errors.campaign_name}
                </div>
              ) : null}
            </Col>
          </Row>

          <Row form>
            <Col className="form-group">
              <label>รูป Campaign</label>
              <span className="text-danger ml-1">
                1,200 x 630 px
              </span>
              <input
                id="img_campaign"
                name="img_campaign"
                type="file"
                onChange={(event) => {
                  values.campaign_image = "";
                  setFieldValue("img_campaign", event.currentTarget.files[0]);
                  this.uploadImage(event.currentTarget.files[0]);
                }}
                className="form-control"
              />
              <div sm="10" className="ml-2 mt-3 text-center">
                <ImageCampaign value={values.campaign_image} />
              </div>
            </Col>
          </Row>

          <FormGroup>
            <label>รายละเอียด Campaign</label>
            <FormTextarea
              rows="3"
              name="campaign_desc"
              id="campaign_desc"
              onChange={handleChange}
              value={values.campaign_desc}
            />
            {errors.campaign_desc && touched.campaign_desc ? (
              <div className="font-12 text-primary mt-1">
                {errors.campaign_desc}
              </div>
            ) : null}
          </FormGroup>

          <Row form>
            <Col md="12" className="form-group">
              <label>
                รางวัล{" "}
                <span className="text-muted" style={{ fontSize: 12 }}>
                  (Silver Coin)
                </span>
              </label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="campaign_coin"
                id="campaign_coin"
                placeholder=""
                onChange={handleChange}
                value={values.campaign_coin}
              />
              {errors.campaign_coin && touched.campaign_coin ? (
                <div className="font-12 text-primary mt-1">
                  {errors.campaign_coin}
                </div>
              ) : null}
            </Col>

            {/* <Col md="6" className="form-group">
              <label>
                โควต้า{" "}
                <span className="text-muted" style={{ fontSize: 12 }}>
                  (จำนวน User)
                </span>
              </label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                
                inputMode="numeric"
                name="limit_no"
                id="limit_no"
                placeholder=""
                onChange={handleChange}
                value={values.limit_no}
              />
            </Col> */}
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>วันที่เริ่มต้น/สิ้นสุด</label>
              <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                <DatePickerField
                  size="sm"
                  name="start_date"
                  onChange={setFieldValue}
                  value={values.start_date}
                  placeholder="เริ่มต้น"
                  dropdownMode="select"
                  className="text-center"
                />
                <DatePickerField
                  size="sm"
                  name="end_date"
                  onChange={setFieldValue}
                  value={values.end_date}
                  placeholder="สิ้นสุด"
                  dropdownMode="select"
                  className="text-center"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.start_date && touched.start_date ? (
                <div className="font-12 text-primary mt-1">
                  {errors.start_date}
                </div>
              ) : null}
            </Col>

            <Col md="6" className="form-group">
              <label>วันที่เหรียญหมดอายุ</label>
              <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                <DatePickerField
                  size="sm"
                  name="member_silver_coin_expire"
                  onChange={setFieldValue}
                  value={values.member_silver_coin_expire}
                  dropdownMode="select"
                  className="text-center"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.member_silver_coin_expire &&
              touched.member_silver_coin_expire ? (
                <div className="font-12 text-primary mt-1">
                  {errors.member_silver_coin_expire}
                </div>
              ) : null}
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>สามารถทำ Campaign ซ้ำได้</label>
              <FormSelect
                name=""
                id="campaign_repeat"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.campaign_repeat}
              >
                <option value="Y" label="Yes" />
                <option value="N" label="No" />
              </FormSelect>
            </Col>

            {values.campaign_repeat === "Y" ? (
              <Col md="6" className="form-group">
                <label>จำนวนครั้งที่สามารถทำซ้ำได้</label>
                <FormInput
                  type="number"
                  name="number_repeat"
                  id="number_repeat"
                  className="flex-fill mb-2"
                  onChange={handleChange}
                  value={values.number_repeat}
                />
                <p style={{ fontSize: 12 }} className="text-muted mb-0">
                  *ใส่ 0 หากไม่จำกัดจำนวนครั้ง
                </p>
              </Col>
            ) : null}
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>แสดงในหน้า Coin Reward</label>
              <FormSelect
                name="is_visible"
                id="is_visible"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={values.is_visible}
              >
                <option value="Y" label="Yes" />
                <option value="N" label="No" />
              </FormSelect>
            </Col>
          </Row>

          <hr />

          <Row form>
            <Col md="3" className="form-group">
              <label className="text-accent">เงื่อนไขการได้รับเหรียญ</label>

              <div className="mt-2">
                <FormSelect
                  name="pushTarget"
                  id="pushTarget"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values.pushTarget}
                >
                  {this.renderTargetOption()}
                </FormSelect>
              </div>
              {errors.pushTarget && touched.pushTarget ? (
                <div className="font-12 text-primary mt-1">{errors.pushTarget}</div>
              ) : null}
            </Col>
          </Row>
          <hr />

          <Row form>
            {values.pushTarget === "checkin" ? (
              <React.Fragment>
                <Col md="6" className="form-group">
                  <div>
                    <label>Check-in</label>
                    <FormInput
                      type="number"
                      name="days_goal"
                      id="days_goal"
                      className="flex-fill mb-2"
                      onChange={handleChange}
                      value={values.days_goal}
                    />
                    {errors.days_goal && touched.days_goal ? (
                      <div className="font-12 text-primary mt-1">
                        {errors.days_goal}
                      </div>
                    ) : null}
                  </div>
                </Col>
                <Col md="6">
                  <label>จำนวนเหรียญที่ได้รับในแต่ละวัน (Optional)</label>
                  <FormInput
                    type="number"
                    name="daily_checkin_receive"
                    id="daily_checkin_receive"
                    className="flex-fill mb-2"
                    onChange={handleChange}
                    value={values.daily_checkin_receive}
                  />
                </Col>
              </React.Fragment>
            ) : null}
          </Row>

          <Row form>
            {values.pushTarget === "chapter" ? (
              <React.Fragment>
                <Col md="6" className="form-group">
                  <label>จำนวน Chapter ที่ซื้อ</label>
                  <FormInput
                    type="number"
                    name="chapter_goal"
                    id="chapter_goal"
                    className="flex-fill mb-2"
                    onChange={handleChange}
                    value={values.chapter_goal}
                  />
                  {errors.chapter_goal && touched.chapter_goal ? (
                    <div className="font-12 text-primary mt-1">
                      {errors.chapter_goal}
                    </div>
                  ) : null}
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <label>เลือกหมวดหมู่หนังสือ (Optional)</label>

                    {this.bindingCategoryName({
                      values,
                      handleChange,
                    })}
                  </FormGroup>
                </Col>
              </React.Fragment>
            ) : null}
          </Row>

          <Row>
            {values.pushTarget === "chapter" ? (
              <Col md="6" className="form-group">
                <label>ชื่อหนังสือ (Optional)</label>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  shouldRenderSuggestions={this.shouldRenderSuggestions}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                />
              </Col>
            ) : null}
          </Row>

          <Row form>
            {values.pushTarget === "topup" ? (
              <Col md="6" className="form-group">
                <label>ยอดการเติมเงิน</label>
                <FormInput
                  type="number"
                  name="amount_goal"
                  id="amount_goal"
                  className="flex-fill mb-2"
                  onChange={handleChange}
                  value={values.amount_goal}
                />
                {errors.amount_goal && touched.amount_goal ? (
                  <div className="font-12 text-primary mt-1">
                    {errors.amount_goal}
                  </div>
                ) : null}
              </Col>
            ) : null}
          </Row>
        </fieldset>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ categoryReducer, bookReducer }) => ({
  categoryReducer,
  bookReducer,
});

const mapDispatchToProps = {
  getCategory,
  getBooks,
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFrom);

import {
    HTTP_BOOK_PUBLISH_SUCCESS,
    HTTP_ฺฺBOOK_PUBLISH_FETCHING,
    HTTP_BOOK_PUBLISH_FAILED,
  } from "../constants/report";
  
  const initialState = {
    books: null,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_BOOK_PUBLISH_SUCCESS:
        return {
          ...state,
          books: payload.books,
          total: payload.total,
          isFetching: false,
          isError: false,
        };
      case HTTP_BOOK_PUBLISH_FAILED:
        return { ...state, books: null, isFetching: false, isError: true };
      case HTTP_ฺฺBOOK_PUBLISH_FETCHING:
        return { ...state, books: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
  
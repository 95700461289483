import React, { Component } from "react";
import * as actions from "../../actions/stickerSet.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  FormTextarea,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import MenuSticker from "./menuSticker";

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <img
        src={thumb}
        alt={file.name}
        className="img-thumbnail mt-2"
        width="100px"
        height="100px"
      />
    );
  }
}

class StickerSet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      StickerSetModalOpen: false,
      set_name: "",
      set_desc: "",
      set_id: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    this.props.getStickerSet();
  }

  toggleModal(row, action) {
    if (action === "edit") {
      let setName = "";
      let setDesc = "";
      let thumbnailImg = "";
      let setID = "";

      if (row.set_id) setID = row.set_id;
      if (row.set_name) setName = row.set_name;
      if (row.set_desc) setDesc = row.set_desc;
      if (row.thumbnail_image) thumbnailImg = row.thumbnail_image;

      this.setState({
        StickerSetModalOpen: !this.state.StickerSetModalOpen,
        set_id: setID,
        set_name: setName,
        set_desc: setDesc,
        thumbnail_image: thumbnailImg,
        add_update: action,
      });
    } else {
      this.setState({
        set_id: "",
        set_name: "",
        set_desc: "",
        set_id: "",
        thumbnail_image:"",
        StickerSetModalOpen: !this.state.StickerSetModalOpen,
        add_update: action,
      });
    }
  }

  closeModal() {
    this.setState({
      StickerSetModalOpen: !this.state.StickerSetModalOpen,
    });
  }

  formatThumbnail(cell, row) {
    if (row.thumbnail_image) {
      return (
        <img
          id="thumbnail_image"
          name="thumbnail_image"
          src={row.thumbnail_image}
          width="50px"
          height="50px"
        />
      );
    }
  }

  formatCreateDateTime(cell, row) {
    if (row.created_datetime) {
      return moment(row.created_datetime)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatAction(cell, row) {
    let disabled = "";
    let username = "";

    if (row.order_status === "A") disabled = true;
    else disabled = false;

    var result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    return (
      <div>
        <button
          type="button"
          className="btn btn-xs btn-success text-white"
          onClick={(e) => this.toggleModal(row, "edit")}
        >
          Edit
        </button>

        <span style={{ color: "grey" }}> | </span>

        <button
          type="button"
          className="btn btn-xs btn-danger"
          onClick={() => {
            return Swal.fire({
              title: "Are you sure to delete?",
              text: "You won't be able to revert this!",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, cancel!",
            }).then((result) => {
              if (result.value) {
                this.props.deleteStickerSet(row.set_id, username);
              }
            });
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  async addEditStickerSet(set_id, set_name, set_desc, thumbnail_image) {
    if (set_name) {
      // Call Api For Upload Image
      let img_url = "";
      if (thumbnail_image) {
        var formDataImg = new FormData();
        var imagefile = thumbnail_image;

        formDataImg.append("upload", imagefile);

        let response_img = await httpClient.post(server.IMG_URL, formDataImg, {
          headers: {
            processData: false,
            enctype: "multipart/form-data",
          },
        });

        if (response_img.status === 201 || response_img.status === 200) {
          if (response_img.data.uploaded === 1) {
            img_url = response_img.data.url;
          }
        }
      }

      let result = localStorage.getItem("user_profile");
      let username;
      if (result) username = JSON.parse(result).user_username;

      let dateTime = new Date();
      dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

      let formData = new FormData();
      if (this.state.add_update === "edit") {
        formData.append("set_id", set_id);
      }
      formData.append("set_name", set_name);
      formData.append("set_desc", set_desc);

      if (img_url) formData.append("thumbnail_image", img_url);

      formData.append("dbstatus", "A");
      formData.append("created_datetime", dateTime);
      formData.append("created_by", username);

      formData.append("updated_datetime", dateTime);
      formData.append("updated_by", username);

      let object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      let json = JSON.stringify(object);

      let response = "";

      const token = localStorage.getItem("token");
      if (this.state.add_update === "add") {
        response = await httpClient.post(`${server.STICKER_SET_URL}`, json, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      } else if (this.state.add_update === "edit") {
        response = await httpClient.patch(
          `${server.STICKER_SET_URL}` + "/" + `${set_id}`,
          json,
          {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      }
      
      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          StickerSetModalOpen: false,
        });

        this.props.getStickerSet();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอก Set Name",
        timer: 1500,
      });
    }
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.stickerSetReducer;

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="set_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Set ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="set_name"
                width="60px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Set Name
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="set_desc"
                width="60px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Sticker Description
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="thumbnail_image"
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatThumbnail.bind(this)}
              >
                Thumbnail
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_datetime"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCreateDateTime}
                csvFormat={this.formatCreateDateTime}
              >
                Create Date
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="updated_by"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Update By
              </TableHeaderColumn>

              <TableHeaderColumn
                width="40px"
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatAction.bind(this)}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>

            <Modal
              open={this.state.StickerSetModalOpen}
              toggle={this.toggleModal}
            >
              <ModalHeader>Add/Edit Sticker Set</ModalHeader>
              <ModalBody>
                <Formik
                  initialValues={{
                    set_id: this.state.set_id,
                    set_name: this.state.set_name,
                    set_desc: this.state.set_desc,
                    thumbnail_image: this.state.thumbnail_image,
                  }}
                  render={({ values, handleChange, setFieldValue }) => (
                    <React.Fragment>
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Set Name</label>
                          <FormInput
                            name="set_name"
                            id="set_name"
                            onChange={handleChange}
                            value={values.set_name}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Description</label>
                          <FormTextarea
                            rows="2"
                            name="set_desc"
                            id="set_desc"
                            onChange={handleChange}
                            value={values.set_desc}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col className="form-group">
                          <label>Thumbnail</label>
                          <input
                            id="file"
                            name="file"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                            className="form-control"
                          />
                          <Row form>
                            {values.thumbnail_image && (
                              <Col sm="6">
                                <React.Fragment>
                                  <label className="ml-2 text-accent">
                                    (OLD)
                                  </label>
                                  <img
                                    id="old_img"
                                    name="old_img"
                                    src={values.thumbnail_image}
                                    className="img-thumbnail mt-2"
                                    width="100px"
                                    height="100px"
                                  />
                                </React.Fragment>
                              </Col>
                            )}
                            <Col sm="6">
                              <React.Fragment>
                                {values.file && (
                                  <label className="ml-2 text-accent">
                                    (NEW)
                                  </label>
                                )}
                                <Thumb
                                  id="new_img"
                                  name="new_img"
                                  file={values.file}
                                />
                              </React.Fragment>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <div className="text-right">
                        <button
                          type="button"
                          className="btn btn-primary pull-right"
                          onClick={() => {
                            this.addEditStickerSet(
                              this.state.set_id,
                              values.set_name,
                              values.set_desc,
                              values.file
                            );
                          }}
                          style={{
                            marginRight: 10,
                            color: "white",
                            width: 100,
                          }}
                        >
                          บันทึก
                        </button>
                        <button
                          onClick={(e) => this.closeModal()}
                          type="Button"
                          className="btn btn-danger pull-right"
                          style={{ color: "white", width: 100 }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                />
              </ModalBody>
            </Modal>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 20 }}>
            <MenuSticker />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Sticker Set</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={(e) => this.toggleModal(null, "add")}
                      className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Add Sticker Set
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ stickerSetReducer }) => ({
  stickerSetReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerSet);

import React, { Component } from "react";
import * as actions from "../../../actions/book_chapter.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
} from "shards-react";
import { Modal, message, Button } from "antd";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import NumberFormat from "react-number-format";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

class BookChapter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book_id: "",
      chapters: [],
      currentPage: 0,
      currentBookPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      sorting: false,
      sortChapterModalOpen: false,
      allChapters: [],
      chapterLoading: false,
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async componentDidMount() {
    const current_page = localStorage.getItem("chapter_current_page");

    if (this.props.location.page)
      await this.setState({ currentPage: this.props.location.page });
    else if (current_page) await this.setState({ currentPage: Number(current_page) });

    if (this.props.location.bookPage) {
      await this.setState({ currentBookPage: this.props.location.bookPage });
      localStorage.setItem("book_current_page", this.props.location.bookPage);
    }

    let bookID = this.props.match.params.book_id;
    await this.setState({ book_id: bookID });
    await this.getChapters(this.state.currentPage);

    localStorage.removeItem("chapter_current_page");
  }

  async deleteChapter(chaptor_id) {
    await this.props.deleteBookChapter(chaptor_id, this.state.book_id);
    setTimeout(() => {
      this.getChapters(this.state.currentPage);
    }, 100);
  }

  async getChapters(page) {
    this.setState({ loading: true });

    await this.props.getChapterByBookID(this.state.book_id, page);

    const { chapters, total } = this.props.bookChapterReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      chapters,
      totalPages,
      loading: false,
    });
  }

  async changeChapterStatus(chapter) {
    const newStatus = chapter.book_chaptor_status === "A" ? "I" : "A";
    let newChapters = this.state.chapters.map((newChapter) => {
      return {
        ...newChapter,
        book_chaptor_status:
          newChapter.book_chaptor_id === chapter.book_chaptor_id
            ? newStatus
            : newChapter.book_chaptor_status,
      };
    });

    this.setState({ chapters: newChapters });

    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let params = {
      book_chaptor_status: newStatus,
      updated_by: username,
      updated_datetime: dateTime,
    };

    const token = localStorage.getItem("token");
    let response = await httpClient.patch(
      `${server.BOOK_CHAPTER_URL}/status/${chapter.book_chaptor_id}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 201 && response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    } else {
      if (newStatus === "A") this.onSendNotification(chapter.book_chaptor_id);
    }
  }

  onSendNotification(chapterId) {
    Swal.fire({
      title: "Push notification",
      text: "ยืนยันการส่ง Push Notification ให้ User ที่ติดตามหนังสือเล่มนี้?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ส่ง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.sendNewChapterNotification(chapterId);
      }
    });
  }

  async sendNewChapterNotification(chapterId) {
    let params = {
      bookId: this.state.book_id,
      bookChapterId: chapterId,
    };

    const token = localStorage.getItem("token");
    let response = await httpClient.post(
      `admin/member/push-notification/new-chapter`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  createTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">ลำดับแสดงผล</th>
            <th>บท/ตอน</th>
            <th>ชื่อตอน</th>
            <th className="text-center">ยอดวิว</th>
            <th className="text-center">ราคา(เหรียญทอง)</th>
            <th className="text-center">ราคา(เหรียญเงิน)</th>
            <th className="text-center">แสดง/ซ่อน</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.chapters.map((chapter, index) => {
            return (
              <tr key={chapter.book_chaptor_id}>
                <td className="text-center align-middle">{chapter.chaptor}</td>
                <td className="align-middle">{chapter.chaptor_title}</td>
                <td className="align-middle">{chapter.book_chaptor_name}</td>
                <td className="text-center align-middle">
                  <NumberFormat
                    value={chapter.total_view}
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => value}
                  />
                </td>
                <td className="text-center align-middle">
                  {chapter.chaptor_price === 0 ? "ฟรี" : chapter.chaptor_price}
                </td>
                <td className="text-center align-middle">
                  {chapter.silver_coin_use === "Y"
                    ? chapter.chaptor_silver_price === 0
                      ? "ฟรี"
                      : chapter.chaptor_silver_price
                    : "-"}
                </td>
                <td className="text-center align-middle">
                  <FormCheckbox
                    toggle
                    inline
                    checked={chapter.book_chaptor_status === "A" ? true : false}
                    onChange={() => this.changeChapterStatus(chapter)}
                    className="mr-0 ml-2"
                  />
                </td>
                <td className="text-center align-middle" width="180">
                  <a
                    type="button"
                    className="btn btn-xs btn-secondary text-white mr-2"
                    onClick={() =>
                      this.props.history.push(
                        {
                          pathname: `/book_chapters/edit/${chapter.book_chaptor_id}`,
                          page: this.state.currentPage,
                          bookPage: this.state.currentBookPage,
                        },
                        localStorage.setItem(
                          "chapter_current_page",
                          this.state.currentPage
                        )
                      )
                    }
                  >
                    Edit
                  </a>

                  <button
                    type="button"
                    className="btn btn-xs btn-danger"
                    onClick={() => {
                      return Swal.fire({
                        title: "ยืนยันการลบ",
                        text: `ตอนที่ ${chapter.chaptor}: ${chapter.chaptor_title}`,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Delete",
                        cancelButtonText: "Cancel",
                      }).then((result) => {
                        if (result.value) {
                          this.deleteChapter(chapter.book_chaptor_id);
                        }
                      });
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getChapters(page);

    localStorage.setItem("chapter_current_page", page);
  }

  async openSortChapterModal() {
    this.setState({
      sortChapterModalOpen: true,
      chapterLoading: true,
    });

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `${server.BOOK_CHAPTER_URL}/book/${this.state.book_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      this.setState({
        allChapters: result.data.chapters,
        chapterLoading: false,
      });
    } catch (e) {}
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const allChapters = this.reorder(
      this.state.allChapters,
      result.source.index,
      result.destination.index
    ).map((item, index) => {
      return {
        ...item,
        chaptor: index + 1,
      };
    });

    this.setState({ allChapters });
  }

  async sortChapters() {
    this.setState({ sorting: true });
    const token = localStorage.getItem("token");
    const chapterIndex = this.state.allChapters.map((item) => {
      return {
        book_chaptor_id: item.book_chaptor_id,
        chaptor: item.chaptor,
      };
    });

    const response = await httpClient.put(
      `${server.BOOK_CHAPTER_URL}/sort`,
      chapterIndex,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 201 || response.status === 200) {
      message.success("บันทึกข้อมูลเรียบร้อยแล้ว");
      this.getChapters(this.state.currentPage);

      this.setState({
        sortChapterModalOpen: false,
        sorting: false,
      });
    } else {
      this.setState({ sorting: false });
      message.error("บันทึกข้อมูลไม่สำเร็จ");
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="m-0">Chapter</h6>
                  </Col>
                  <Col className="text-right">
                    <Button
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/book`,
                          page: this.state.currentBookPage,
                        });
                      }}
                      type="Button"
                      className="btn btn-danger mr-2"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() =>
                        this.props.history.push(
                          `/book_chapters/${this.state.book_id}/create`
                        )
                      }
                      className="btn btn-secondary mr-2"
                    >
                      Add Chapter
                    </Button>
                    <Button
                      onClick={() => this.openSortChapterModal()}
                      className="btn btn-primary"
                    >
                      Sort Chapter
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.createTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.chapters.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>

        <Modal
          title={null}
          footer={null}
          visible={this.state.sortChapterModalOpen}
          onCancel={() => this.setState({ sortChapterModalOpen: false })}
          closable={false}
          width={600}
        >
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <ul
                  className="list-group mt-4"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {this.state.allChapters.map((item, index) => (
                    <Draggable
                      key={item.book_chaptor_id}
                      draggableId={item.book_chaptor_id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`list-group-item d-flex align-items-center rounded-0 ${
                            index > 0 ? "border-top" : ""
                          }`}
                        >
                          {item.chaptor_title}
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>

          <div className="mt-4 text-right">
            <Button
              onClick={() => this.sortChapters()}
              className="btn btn-danger mr-2"
              loading={this.state.sorting}
            >
              บันทึก
            </Button>
            <Button
              onClick={() => this.setState({ sortChapterModalOpen: false })}
              className="btn btn-secondary mr-2"
            >
              ยกเลิก
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookChapterReducer }) => ({
  bookChapterReducer,
});

const mapDispatchToProps = {
  ...actions,
};
export default connect(mapStateToProps, mapDispatchToProps)(BookChapter);

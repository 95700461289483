import {
  HTTP_PLAYLISTS_FETCHING,
  HTTP_PLAYLISTS_SUCCESS,
  HTTP_PLAYLISTS_FAILED,
  HTTP_PLAYLIST_FETCHING,
  HTTP_PLAYLIST_SUCCESS,
  HTTP_PLAYLIST_FAILED,
} from "../constants/playlist";

const initialState = {
  playlists: [],
  result: null,
  isFetching: false,
  isError: false,
  total: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_PLAYLISTS_SUCCESS:
      return {
        ...state,
        playlists: payload.playlists,
        total: payload.total,
        isFetching: false,
        isError: false,
      };
    case HTTP_PLAYLISTS_FAILED:
      return { ...state, playlists: null, isFetching: false, isError: true };
    case HTTP_PLAYLISTS_FETCHING:
      return { ...state, playlists: null, isFetching: true, isError: false };
    case HTTP_PLAYLIST_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    case HTTP_PLAYLIST_FETCHING:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_PLAYLIST_FAILED:
      return { ...state, result: null, isFetching: true, isError: false };
    default:
      return state;
  }
};

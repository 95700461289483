import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import * as actions from "../../../actions/member.action";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import MemberFrom from "../../Member/memberForm";
import moment from "moment";
import * as Yup from "yup";

const memberValidationSchema = Yup.object().shape({
  member_name: Yup.string().required("กรุณากรอก Name"),
});

class EditMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      activeTab: "1",
      from_page: this.props.match.params.from_page,
    };

    this.getMemberInfo = this.getMemberInfo.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this.getMemberInfo();

    const { page } = this.props.location;
    this.setState({ page });
  }

  getMemberInfo() {
    this.props.getMemberById(this.props.match.params.member_id);
  }

  handleTabChange(key) {
    this.setState({
      activeTab: key,
    });
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 20 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">UPDATE MEMBER</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  {/* <form className="form-horizontal" onSubmit={handleSubmit}> */}
                  <MemberFrom
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isDisableField={false}
                    isDiableUsername={true}
                    laberImg={"Member Image"}
                    errors={errors}
                    touched={touched}
                    getMemberInfo={this.getMemberInfo}
                    isVisibleSignUpChannel={true}
                    isMember={true}
                    onTabChange={this.handleTabChange}
                    memberId={this.props.match.params.member_id}
                  ></MemberFrom>

                  <div className="mt-5 text-right">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      onClick={handleSubmit}
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      บันทึก
                    </button>

                    {this.state.from_page === "member" && (
                      <button
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/member",
                            page: this.state.page,
                          })
                        }
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ย้อนกลับ
                      </button>
                    )}
                    {this.state.from_page === "active_member" && (
                      <button
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/active_member",
                            page: this.state.page,
                          })
                        }
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ย้อนกลับ
                      </button>
                    )}
                  </div>
                  {/* </form> */}
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    const { result } = this.props.memberReducer;

    if (result) {
      result["member_password"] = "";
      result["silver_coin_added"] = "";
      result["silver_coin_expired_date"] = "";
    }

    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          initialValues={result ? result : {}}
          validationSchema={memberValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            //Call API For Upload Image
            let img_url = "";
            if (values.file) {
              var formDataImg = new FormData();
              var imagefile = values.file;

              formDataImg.append("upload", imagefile);

              let response_img = await httpClient.post(
                server.IMG_URL,
                formDataImg,
                {
                  headers: {
                    processData: false,
                    enctype: "multipart/form-data",
                  },
                }
              );

              if (response_img.status === 201 || response_img.status === 200) {
                if (response_img.data.uploaded === 1) {
                  img_url = response_img.data.url;
                }
              }
            }

            let goldCoin = 0;
            //let silverCoin = 0;

            if (values.member_coin) {
              goldCoin = Number(
                values.member_coin.toString().replace(/,/g, "")
              );
            }

            // if (values.member_silver_coin) {
            //   silverCoin = Number(
            //     values.member_silver_coin.toString().replace(/,/g, "")
            //   );
            // }

            let formData = new FormData();
            formData.append("member_id", values.member_id);
            if (values.member_username)
              formData.append("member_username", values.member_username);
            if (values.member_password) {
              formData.append("member_password", values.member_password);
            }
            if (values.member_name)
              formData.append("member_name", values.member_name);
            if (values.member_phone)
              formData.append("member_phone", values.member_phone);
            if (values.member_email)
              formData.append("member_email", values.member_email);
            if (values.member_history)
              formData.append("member_history", values.member_history);
            if (img_url) formData.append("member_image", img_url);
            formData.append("member_coin", goldCoin);
            //formData.append("member_silver_coin", silverCoin);

            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;

            formData.append("updated_by", username);

            let dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
            formData.append("updated_datetime", dateTime);
            formData.append("unlimited", values.unlimited);

            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            const token = localStorage.getItem("token");
            let response = await httpClient.patch(
              server.MEMBER_URL + "/" + `${values.member_id}`,
              json,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.status === 201 || response.status === 200) {
              alert("Update Complete");
              setSubmitting(false);
              if (this.state.from_page === "active_member") {
                this.props.history.push({
                  pathname: "/active_member",
                  page: this.state.page,
                });
              } else {
                this.props.history.push({
                  pathname: "/member",
                  page: this.state.page,
                });
              }
            } else {
              alert("Update Fail");
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ memberReducer }) => ({ memberReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMember);

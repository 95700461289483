import React from "react";
import { Nav } from "shards-react";
import { NavLink as RouteNavLink } from "react-router-dom";
import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../../flux";
import * as actions from "../../../../actions/user.action";
import { connect } from "react-redux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const userProfile = JSON.parse(localStorage.getItem("user_profile"));
    
    if(userProfile) {
      let newNavItems = this.state.navItems

      newNavItems.map((item) => {
        if(item.key !== 'parent') {
          item.show = userProfile[item.key] === 'Y'
        } else {
          let isChildExist = false

          item.menus.map((item) => {
            item.show = userProfile[item.key] === 'Y'
           
            if(userProfile[item.key] === 'Y')  {
              isChildExist = true
            }
          })

          item.show = isChildExist
        }
      })

      this.setState({navItems: newNavItems})
    }
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }

  render() {
    const { navItems: items } = this.state;

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          { 
            items.map((item, idx) => {
              return <SidebarNavItem key={idx} item={item} />
            })
          }
        </Nav>
      </div>
    )
  }
}


const mapStateToProps = ({ loginReducer}) => ({ loginReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems);


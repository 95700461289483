import React, { Component } from "react";
import * as actions from "../../../actions/category.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody
} from "shards-react";
import Swal from "sweetalert2";
import MenuContent from "../Menu";

class Category extends Component {
  async componentDidMount() {
    this.props.getCategory();
  }

  createRows = () => {
    try {
      const { result, isFetching } = this.props.categoryReducer;
      return (
        !isFetching &&
        result != null &&
        result.map(item => (
          <tr key={item.category_id}>
            <td>{item.category_name}</td>

            <td style={{ textAlign: "center" }}>
              <img
                id="category_image"
                name="category_image"
                src={item.category_image}
                width="180px"
                height="60px"
              />
            </td>

            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-sm btn-info"
                onClick={() =>
                  this.props.history.push(`/sub_category/${item.category_id}`)
                }
              >
                Sub Category
              </button>
            </td>

            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-sm btn-primary mr-2"
                onClick={() =>
                  this.props.history.push(`/categorys/edit/${item.category_id}`)
                }
              >
                Edit
              </button>

              <button
                type="button"
                className="btn btn-sm btn-warning mr-2"
                onClick={() => {
                  Swal.fire({
                    title: "ต้องการรีเซ็ตการเรียงลำดับ?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Reset",
                    cancelButtonText: "Cancel"
                  }).then(result => {
                    if (result.value) {
                      this.props.resetOrderBook(item.category_id);
                    }
                  });
                }}
              >
                Reset Order
              </button>

              <button
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure to delete?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!"
                  }).then(result => {
                    if (result.value) {
                      this.props.deleteCategory(item.category_id);
                    }
                  });
                }}
                type="button"
                className="btn btn-sm  btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        ))
      );
    } catch (e) {}
  };

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <div style={{ padding: 10 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Category</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => this.props.history.push(`/categorys/create`)}
                    className=" btn btn-md btn-success d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add Category
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table id="campaign_table" className="table mb-0">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Category Name</th>
                    <th style={{ textAlign: "center" }}>Category Image</th>
                    <th style={{ textAlign: "center" }}>Sub Category</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>{this.createRows()}</tbody>
              </table>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ categoryReducer, appReducer }) => ({
  categoryReducer,
  appReducer
});

const mapDispatchToProps = {
  ...actions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Category);

import { httpClient } from "../utils/HttpClient";
import { server } from "../constants";
import Swal from "sweetalert2";

import {
  HTTP_PLAYLIST_ITEM_FETCHING,
  HTTP_PLAYLIST_ITEM_SUCCESS,
  HTTP_PLAYLIST_ITEM_FAILED,
  HTTP_PLAYLIST_BOOKS_FETCHING,
  HTTP_PLAYLIST_BOOKS_SUCCESS,
  HTTP_PLAYLIST_BOOKS_FAILED,
} from "../constants/playlist";

const setStatePlaylistItemToSuccess = (payload) => ({
  type: HTTP_PLAYLIST_ITEM_SUCCESS,
  payload: payload,
});

const setStatePlaylistItemToFetching = () => ({
  type: HTTP_PLAYLIST_ITEM_FETCHING,
});

const setStatePlaylistItemToFailed = () => ({
  type: HTTP_PLAYLIST_ITEM_FAILED,
});

/* Get Playlist Item */
export const getPlaylistItem = (value) => {

  return async (dispatch) => {
    dispatch(setStatePlaylistItemToFetching());
    await doGetPlaylistItem(dispatch, value);
  };
};

const doGetPlaylistItem = (dispatch, value) => {
  let dataFilter = "";

  if (value) {
    dataFilter = "?playlist_id=" + value;
  }
  
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.PLAYLIST_ITEM_URL}` + dataFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStatePlaylistItemToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStatePlaylistItemToFailed());
    });
};

export const deletePlaylistItem = (item_id,playlist_id) => {
  return async (dispatch) => {
    dispatch(setStatePlaylistItemToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      server.PLAYLIST_ITEM_URL + "/" + `${item_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result) {
      alert("Delete Complete");
      await doGetPlaylistItem(dispatch,playlist_id);
    }
  };
};

//////////////////////////////////////////////////////////////////

const setStatePlaylistBookToSuccess = (payload) => ({
  type: HTTP_PLAYLIST_BOOKS_SUCCESS,
  payload: payload,
});

const setStatePlaylistBookToFetching = () => ({
  type: HTTP_PLAYLIST_BOOKS_FETCHING,
});

const setStatePlaylistBookToFailed = () => ({
  type: HTTP_PLAYLIST_BOOKS_FAILED,
});

/* Get Book Item */
export const getBooklistItem = () => {
  return async (dispatch) => {
    dispatch(setStatePlaylistBookToFetching());
    await doGetBooklistItem(dispatch);
  };
};

const doGetBooklistItem = (dispatch) => {
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.BOOKS_ITEM_URL}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStatePlaylistBookToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStatePlaylistBookToFailed());
    });
};

import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormSelect,
  FormTextarea,
  DatePicker,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import NumberFormat from "react-number-format";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import { getBooks } from "../../../actions/book.action";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import imageCompression from 'browser-image-compression';
import Swal from "sweetalert2";

class BannerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img_src_web: "",
      img_src_mobile: "",
      pushTarget: "",
      books: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      value: "",
    };

    if (this.props.values.banner_image)
      this.setState({ img_src_web: this.props.values.banner_image });

    this.uploadImage = this.uploadImage.bind(this);
  }

  async componentDidMount() {
    let initialValues = this.props.values;
    await this.props.getBooks();

    if (initialValues.action === "add") {
      this.props.values.pushTarget = "book";
    } else {
      if (initialValues.book_id) {
        this.props.values.pushTarget = "book";
        this.setState({ bookId: initialValues.book_id });
      } else this.props.values.pushTarget = "other";
    }

    if (initialValues.book_id) this.setState({ bookId: initialValues.book_id });

    await this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
    });

    if (this.props.values.book_title) {
      await this.setState({
        value: this.props.values.book_title,
      });
    }
  }

  async uploadImage(image, type) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, {type: image.type});

      // Call Api For Upload Image
      if (image) {
        var formDataImg = new FormData();
        var imagefile = file;
        formDataImg.append("upload", imagefile);

        let response_img = await httpClient.post(server.IMG_URL, formDataImg, {
          headers: {
            processData: false,
            enctype: "multipart/form-data",
          },
        });

        if (response_img.status === 201 || response_img.status === 200) {
          if (response_img.data.uploaded === 1) {
            if (type === "web") {
              this.setState({
                img_src_web: response_img.data.url,
              });

              this.props.change(response_img.data.url, "web");
            }
            if (type === "mobile") {
              this.setState({
                img_src_mobile: response_img.data.url,
              });
              this.props.change(response_img.data.url, "mobile");
            }
          }
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง",
        timer: 3000
      });
    }
  }

  renderTargetOption() {
    let optionList = [
      {
        value: "book",
        label: "เลือกจากหนังสือ",
      },
      {
        value: "other",
        label: "อื่นๆ",
      },
    ];

    let optionElements = optionList.map((item) => (
      <option value={item.value} key={item.value}>
        {item.label}
      </option>
    ));

    return optionElements;
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
    this.props.values.book_id = suggestion.book_id;
    this.props.values.book_title = suggestion.book_title;
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    const {
      values,
      handleChange,
      handleBlur,
      setFieldValue,
      errors,
      touched,
    } = this.props;

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };

    const ImageWeb = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="banner_image"
            name="banner_image"
            src={value}
          />
        );
      } else {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="banner_image"
            name="banner_image"
            src={this.state.img_src_web}
          />
        );
      }
    };

    const ImageMobile = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="banner_image_mobile"
            name="banner_image_mobile"
            src={value}
          />
        );
      } else {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="banner_image_mobile"
            name="banner_image_mobile"
            src={this.state.img_src_mobile}
          />
        );
      }
    };

    return (
      <React.Fragment>
        <fieldset>
          <Row form>
            <Col md="3" className="form-group">
              <label className="text-accent">ประเภท Banner</label>

              <div className="mt-2">
                <FormSelect
                  name="pushTarget"
                  id="pushTarget"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  value={values.pushTarget}
                >
                  {this.renderTargetOption()}
                </FormSelect>
              </div>
              {errors.pushTarget && touched.pushTarget ? (
                <div className="font-12 text-primary mt-1">{errors.pushTarget}</div>
              ) : null}
            </Col>
          </Row>

          <Row>
            {values.pushTarget === "other" ? (
              <Col md="12" className="form-group">
                <label>ชื่อ Banner</label>
                <FormInput
                  name="banner_name"
                  id="banner_name"
                  placeholder=""
                  onChange={handleChange}
                  value={values.banner_name}
                />
                {errors.banner_name && touched.banner_name ? (
                  <div className="font-12 text-primary mt-1">
                    {errors.banner_name}
                  </div>
                ) : null}
              </Col>
            ) : null}
          </Row>

          <Row>
            {values.pushTarget === "book" ? (
              <Col md="12" className="form-group">
                <label>ชื่อหนังสือ</label>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  shouldRenderSuggestions={this.shouldRenderSuggestions}
                  onSuggestionSelected={this.onSuggestionSelected}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                />
                {errors.book_id && touched.book_id ? (
                  <div className="font-12 text-primary mt-1">
                    {errors.book_id}
                  </div>
                ) : null}
              </Col>
            ) : null}

            { values.pushTarget === "other" &&
              <>
                <Col md="12" className="form-group">
                  <label>ลิงค์แบนเนอร์</label>
                  <FormInput
                    name="banner_url"
                    id="banner_url"
                    onChange={handleChange}
                    value={values.banner_url}
                  />
                  {errors.banner_url && touched.banner_url ? (
                    <div className="font-12 text-primary mt-1">
                      {errors.banner_url}
                    </div>
                  ) : null}
                </Col>

                <Col md="12" className="form-group">
                  <label>ลิงค์แบนเนอร์สำหรับแอพ</label>
                  <FormInput
                    name="banner_mobile_url"
                    id="banner_mobile_url"
                    onChange={handleChange}
                    value={values.banner_mobile_url}
                  />
                  {errors.banner_mobile_url && touched.banner_mobile_url ? (
                    <div className="font-12 text-primary mt-1">
                      {errors.banner_mobile_url}
                    </div>
                  ) : null}
                </Col>
              </>
            }
          </Row>


          <Row form>
            <Col md="6" className="form-group">
              <label>ตำแหน่ง Banner</label>

              <div>
                <FormSelect
                  id="banner_type"
                  name="banner_type"
                  value={values.banner_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" label="Select" />
                  <option value="TOP" label="ด้านบน" />
                  <option value="BOTTOM" label="ด้านล่าง" />
                  <option value="POPUP" label="Popup ก่อนเข้าหน้าเวปไซต์/Application" />
                </FormSelect>
              </div>

              {errors.banner_type && touched.banner_type ? (
                <div className="font-12 text-primary mt-1">
                  {errors.banner_type}
                </div>
              ) : null}
            </Col>
            <Col md="6">
              <label>ลำดับที่แสดงผล</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                
                inputMode="numeric"
                name="norder"
                id="norder"
                placeholder=""
                onChange={handleChange}
                value={values.norder}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="12" className="form-group">
              <label>รายละเอียด Banner</label>
              <FormTextarea
                rows="2"
                name="banner_desc"
                id="banner_desc"
                onChange={handleChange}
                value={values.banner_desc}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>แสดง/ไม่แสดง</label>

              <div>
                <FormSelect
                  id="banner_status"
                  name="banner_status"
                  value={values.banner_status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" label="Select" />
                  <option value="A" label="แสดง" />
                  <option value="I" label="ไม่แสดง" />
                </FormSelect>
              </div>

              {errors.banner_status && touched.banner_status ? (
                <div className="font-12 text-primary mt-1">
                  {errors.banner_status}
                </div>
              ) : null}
            </Col>
          </Row>

          { (values.pushTarget === "other" || values.banner_type === 'POPUP' || values.banner_type === 'BOTTOM') && (
            <React.Fragment>
              {/* <Row>
                <Col className="form-group">
                  <label>
                    รูปแบนเนอร์{" "}
                    <span className=" text-danger">
                      {" "}
                      (ขนาดรูปที่แนะนำ 2318 x 800 px)
                    </span>
                  </label>
                  <input
                    id="img_web"
                    name="img_web"
                    type="file"
                    onChange={(event) => {
                      values.banner_image = "";
                      setFieldValue("img_web", event.currentTarget.files[0]);
                      this.uploadImage(event.currentTarget.files[0], "web");
                    }}
                    className="form-control"
                  />
                  <div sm="10" className="ml-2 mt-3 text-center">
                    <ImageWeb value={values.banner_image} />
                  </div>
                </Col>
              </Row> */}

              <Row>
                <Col className="form-group">
                  <label>
                    รูปแบนเนอร์
                    <span className="text-danger ml-1">
                      (ขนาดรูปที่แนะนำ: ด้านบน 1200x630, ด้านล่าง 1200x400, Popup 375x660)
                    </span>
                  </label>
                  <input
                    id="img_mobile"
                    name="img_mobile"
                    type="file"
                    onChange={(event) => {
                      values.banner_image_mobile = "";
                      setFieldValue("img_mobile", event.currentTarget.files[0]);
                      this.uploadImage(event.currentTarget.files[0], "mobile");
                    }}
                    className="form-control"
                  />
                  <div sm="10" className="ml-2 mt-3 text-center">
                    <ImageMobile value={values.banner_image_mobile} />
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          )}

          <Row form>
            <Col md="6" className="form-group">
              <label>วันที่เริ่มต้น/สิ้นสุด</label>
              <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                <DatePickerField
                  size="sm"
                  name="startdate"
                  onChange={setFieldValue}
                  value={values.startdate}
                  placeholder="เริ่มต้น"
                  dropdownMode="select"
                  className="text-center"
                />
                <DatePickerField
                  size="sm"
                  name="enddate"
                  onChange={setFieldValue}
                  value={values.enddate}
                  placeholder="สิ้นสุด"
                  dropdownMode="select"
                  className="text-center"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.startdate && touched.startdate ? (
                <div className="font-12 text-primary mt-1">
                  {errors.startdate}
                </div>
              ) : null}
              {errors.enddate && touched.enddate ? (
                <div className="font-12 text-primary mt-1">
                  {errors.enddate}
                </div>
              ) : null}
            </Col>
          </Row>
        </fieldset>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookReducer }) => ({
  bookReducer,
});

const mapDispatchToProps = {
  getBooks,
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerForm);

export default function() {
  return [
    {
      title: "Dashboard",
      key: "dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">bar_chart</i>',
      show: false
    },
    {
      title: "Silver Coin Campaign",
      key: "campaign",
      to: "/campaign",
      htmlBefore: '<i class="material-icons">money</i>',
      show: false
    },
    {
      title: "Packages",
      key: "package",
      to: "/packages",
      htmlBefore: '<i class="material-icons">attach_money</i>',
      show: false
    },
    {
      title: "Redeem Code",
      key: "campaign",
      htmlBefore: '<i class="material-icons">redeem</i>',
      to: "/redeem",
      show: false
    },
    {
      title: "Push Notification",
      key: "push_notification",
      htmlBefore: '<i class="material-icons">mobile_screen_share</i>',
      to: "/push-notification",
      show: false,
    },
    {
      title: "Report",
      key: "parent",
      htmlBefore: '<i class="material-icons">print</i>',
      htmlAfter: '<i class="material-icons mr-0">expand_more</i>',
      open: false,
      show: false,
      menus: [
        {
          title: "Report",
          key: "marketing_report",
          to: "/report/gift",
          show: false,
        },
        // {
        //   title: "Accountant Report",
        //   key: "accountant_report",
        //   to: "/report/book_rank",
        //   show: false,
        // },
        {
          title: "Accountant Report(New)",
          key: "accountant_report",
          to: "/accountant_reports/orders",
          show: false,
        },
        {
          title: "Marketing Report(New)",
          key: "marketing_report",
          to: "/marketing_reports/book_rank",
          show: false,
        }
        // {
        //   title: "BotRead Report",
        //   key: "marketing_report",
        //   to: "/report/chapter_botread",
        //   show: false,
        // }
      ]
    },
    {
      title: "Member Management",
      key: "parent",
      to: "",
      htmlBefore: '<i class="material-icons">face</i>',
      htmlAfter: '<i class="material-icons mr-0">expand_more</i>',
      open: false,
      show: false,
      menus: [
        {
          title: "Member",
          key: "member",
          to: "/member",
          show: false,
        },
        {
          title: "Writer",
          key: "writer",
          to: "/writer",
          show: false,
        },
        {
          title: "UGC Writer",
          key: "writer",
          to: "/ugc-writer",
          show: false,
        },
        {
          title: "Botnoi",
          key: "writer",
          to: "/botnoi",
          show: false,
        },
        {
          title: "Money Withdrawal",
          key: "writer",
          to: "/withdraw",
          show: false,
        },
        {
          title: "Editor",
          key: "member",
          to: "/editor",
          show: false,
        },
        {
          title: "Publisher",
          key: "member",
          to: "/publisher",
          show: false,
        },
        {
          title: "Admin Users",
          key: "user",
          to: "/user",
          show: false,
        },
        {
          title: "Admin User Roles",
          key: "user",
          to: "/role",
          show: false,
        },
      ]
    },
    {
      title: "Content Management",
      key: "parent",
      htmlBefore: '<i class="material-icons">laptop</i>',
      htmlAfter: '<i class="material-icons mr-0">expand_more</i>',
      to: "",
      show: false,
      open: false,
      menus: [
        {
          title: "Book",
          key: "book",
          to: "/book",
          show: false,
        },
        {
          title: "Banner",
          key: "banner",
          to: "/banners/view",
          show: false,
        },
        {
          title: "Category",
          key: "category",
          to: "/category",
          show: false,
        },
        {
          title: "Playlist",
          key: "playlist",
          to: "/playlist",
          show: false,
        },
        {
          title: "Writer Recommend",
          key: "writer_recommend",
          to: "/writer_recommend",
          show: true,
        },
      ]
    },
    {
      title: "Comments & Reviews",
      key: "parent",
      to: "",
      htmlBefore: '<i class="material-icons">create</i>',
      htmlAfter: '<i class="material-icons mr-0">expand_more</i>',
      open: false,
      show: false,
      menus: [
        {
          title: "Comment",
          key: "comment",
          to: "/comment/book",
          show: true,
        },
        {
          title: "Sticker",
          key: "comment",
          to: "/sticker",
          show: true,
        }
      ]
    }
  ];
}
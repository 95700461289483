import { httpClient } from "../utils/HttpClient";
import {server} from "../constants"

import {
    HTTP_BANNER_SUCCESS,
    HTTP_BANNER_FETCHING,
    HTTP_BANNER_FAILED,
  } from "../constants/banner";

const setStateBannerToSuccess = payload => ({
  type: HTTP_BANNER_SUCCESS,
  payload: payload
});

const setStateBannerToFetching = () => ({
  type: HTTP_BANNER_FETCHING
});

const setStateBannerToFailed = () => ({
  type: HTTP_BANNER_FAILED
});

/* Get Banner */
export const getBanner = () => {
  return async (dispatch) => {
    dispatch(setStateBannerToFetching());
    await doGetBanner(dispatch);
  };
};

const doGetBanner = (dispatch) => {
  const token = localStorage.getItem("token");
  httpClient
    .get(`${server.BANNER_URL}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateBannerToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateBannerToFailed());
    });
};

export const getBannerByID = (banner_id) => {
  return async (dispatch) => {
    dispatch(setStateBannerToFetching());

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`${server.BANNER_URL}/id/${banner_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      dispatch(setStateBannerToSuccess(result.data));
    } catch(err)  {
      dispatch(setStateBannerToFailed());
    }
  };
};

export const deleteBanner = (banner_id) => {
  return async (dispatch) => {
    dispatch(setStateBannerToFetching());
    const token = localStorage.getItem("token");
    const result = await httpClient.delete(
      `${server.BANNER_URL}/${banner_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(result)
    {
      alert('Delete Complete')
      await doGetBanner(dispatch);
    }
  };
};



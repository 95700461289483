import React, { Component } from "react";
import {
  getPlaylistItem,
  getBooklistItem,
  deletePlaylistItem,
} from "../../actions/playlistItem.action";
import { getPlaylist } from "../../actions/playlist.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  FormGroup,
} from "shards-react"
import { Formik } from "formik"
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table"
import moment from "moment"
import { httpClient } from "../../utils/HttpClient"
import { server } from "../../constants"

const CategoryType = {
  นิยาย: "นิยาย",
  การลงทุน: "การลงทุน",
  พัฒนาตนเอง: "พัฒนาตนเอง",
  ธุรกิจ: "ธุรกิจ",
  การ์ตูน: "การ์ตูน",
  ไลฟ์สไตล์: "ไลฟ์สไตล์"
};

function enumFormatter(cell, row, enumObject) {
  return enumObject[cell];
}

class PlaylistItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      silverCoinModalOpen: false,
      book_id: "",
      book_title: "",
      playlist_id: "",
      isAdding: false,
      add_update: "",
      item_id: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  formatCreateDateTime(cell, row) {
    if (row.created_datetime) {
      return moment(row.created_datetime)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatImageBook(cell, row) {
    if (row.book_image) {
      return (
        <img
          id="book_image"
          name="book_image"
          src={row.book_image}
          width="50px"
          height="60px"
        />
      );
    }
  }

  bindingPlaylistName = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.playlistReducer;
      const options = result.map((item) => (
        <option key={item.value} value={item.playlist_id}>
          {item.playlist_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="playlist_id"
            id="playlist_id"
            placeholder=""
            onChange={handleChange}
            value={values.playlist_id}
          >
            <option value="">Select Playlist</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  toggleModal(row, action) {
    let title = "";
    let playlistID = "";
    let bookID = "";

    if (row.book_id) bookID = row.book_id;
    if (row.book_title) title = row.book_title;
    if (row.playlist_id) playlistID = row.playlist_id;

    this.setState({
      silverCoinModalOpen: !this.state.silverCoinModalOpen,
      book_id: bookID,
      book_title: title,
      playlist_id: playlistID,
      add_update: action,
      item_id: row.item_id,
    });
  }

  closeModal() {
    this.setState({
      silverCoinModalOpen: !this.state.silverCoinModalOpen,
    });
  }

  async addEditPlaylistItem(book_id, playlist_id) {
    if (playlist_id) {
      let result = localStorage.getItem("user_profile");
      let username;
      if (result) username = JSON.parse(result).user_username;

      let dateTime = new Date();
      dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

      let formData = new FormData();

      if (this.state.add_update === "add") {
        formData.append("book_id", book_id);
        formData.append("playlist_id", playlist_id);
        formData.append("dbstatus", "A");
        formData.append("created_datetime", dateTime);
        formData.append("created_by", username);
      } else if (this.state.add_update === "edit") {
        formData.append("playlist_id", playlist_id);
      }
      formData.append("updated_datetime", dateTime);
      formData.append("updated_by", username);

      let object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      let json = JSON.stringify(object);

      let response = "";

      const token = localStorage.getItem("token")
      if (this.state.add_update === "add") {
        response = await httpClient.post(`${server.PLAYLIST_ITEM_URL}`, json, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
      } else if (this.state.add_update === "edit") {
        response = await httpClient.patch(
          `${server.PLAYLIST_ITEM_URL}` + "/" + `${this.state.item_id}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          }
        );
      }

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          silverCoinModalOpen: false,
        });

        this.props.getPlaylistItem();
        this.props.getBooklistItem();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณาเลือก Playlist",
        timer: 1500,
      });
    }
  }

  async componentDidMount() {
    this.props.getPlaylistItem();
    this.props.getBooklistItem();
    this.props.getPlaylist();
  }

  renderPlaylistItem = () => {
    try {
      const { result, isFetching } = this.props.playlistItemReducer;

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="item_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Item ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="playlist_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                ชื่อ Playlist
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="playlist_desc"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                รายละเอียด Playlist
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_title"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                ชื่อหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="creator_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                ชื่อผู้แต่ง
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="category_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{
                  type: "SelectFilter",
                  options: CategoryType,
                  placeholder: " ",
                }}
                dataFormat={enumFormatter}
                formatExtraData={CategoryType}
              >
                ประเภทหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_datetime"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCreateDateTime}
                csvFormat={this.formatCreateDateTime}
              >
                Create Date
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="updated_by"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Update By
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  btnFormatter(cell, row) {
    if (row.playlist_id) {
      return (
        <React.Fragment>
          <a
            type="button"
            className="btn btn-xs btn-success text-white"
            onClick={(e) => this.toggleModal(row, "edit")}
          >
            Edit Playlist
          </a>

          <span style={{ color: "grey" }}> | </span>

          <a
            onClick={() => {
              return Swal.fire({
                text: "Are you sure to delete?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
              }).then((result) => {
                if (result.value) {
                  this.props.deletePlaylistItem(row.item_id);
                }
              });
            }}
            type="button"
            className="btn btn-xs btn-danger text-white"
          >
            Delete 
          </a>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <a
            type="button"
            className="btn btn-xs btn-info text-white"
            onClick={(e) => this.toggleModal(row, "add")}
          >
            Add To Playlist
          </a>
        </React.Fragment>
      );
    }
  }

  renderBooklistItem = () => {
    try {
      const { result, isFetching } = this.props.playlistItemBookReducer;

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="book_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Book ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="item_id"
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Item ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_cover"
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatImageBook}
              >
                รูปหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_title"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                ชื่อหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="creator_name"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                ชื่อผู้แต่ง
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="category_name"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{
                  type: "SelectFilter",
                  options: CategoryType,
                  placeholder: " ",
                }}
                dataFormat={enumFormatter}
                formatExtraData={CategoryType}
              >
                ประเภทหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="playlist_id"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                hidden={true}
              >
                Playlist ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="playlist_name"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                ชื่อ Playlist
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={this.btnFormatter.bind(this)}
                headerAlign="center"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>

            <Modal
              open={this.state.silverCoinModalOpen}
              toggle={this.toggleModal}
              book_title={this.state.book_title}
              playlist_id={this.state.playlist_id}
            >
              <ModalHeader>Add/Edit Playlist</ModalHeader>
              <ModalBody>
                <Formik
                  enableReinitialize
                  initialValues={{ playlist_id: this.state.playlist_id }}
                  render={({ values, handleChange, setFieldValue }) => (
                    <React.Fragment>
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>ชื่อหนังสือ</label>
                          <FormInput
                            name="book_title"
                            id="book_title"
                            onChange={handleChange}
                            value={this.state.book_title}
                            disabled={true}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Playlist Name</label>
                          {this.bindingPlaylistName({
                            values,
                            handleChange,
                          })}
                        </Col>
                      </Row>

                      <div className="text-right mt-3">
                        <button
                          type="button"
                          disabled={this.state.isAdding}
                          className="btn btn-primary pull-right"
                          onClick={() => {
                            this.addEditPlaylistItem(
                              this.state.book_id,
                              values.playlist_id
                            );
                          }}
                          style={{
                            marginRight: 10,
                            color: "white",
                            width: 100,
                          }}
                        >
                          บันทึก
                        </button>
                        <button
                          onClick={(e) => this.closeModal()}
                          type="Button"
                          className="btn btn-danger pull-right"
                          style={{ color: "white", width: 100 }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                />
              </ModalBody>
            </Modal>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 20 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1 text-accent">
                      รายการหนังสือใน Playlist2
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    playlist_id: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    if (values.playlist_id) {
                      value.playlistID = values.playlist_id;
                    }

                    this.props.getPlaylistItem(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col sm="5" className="form-group">
                            <label>ชื่อ Playlist</label>
                            {this.bindingPlaylistName({
                              values,
                              handleChange,
                            })}
                          </Col>

                          <Col sm="6">
                            <Row>
                              <Col sm="3" className="text-right">
                                <FormGroup>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                      marginLeft: 30,
                                      marginTop: 27,
                                      color: "white",
                                      width: 100,
                                    }}
                                  >
                                    Search
                                  </button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.renderPlaylistItem()}
              </CardBody>
            </Card>

            <Card className="mt-5">
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1 text-primary">
                      จัดการข้อมูลหนังสือใน Playlist
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                {this.renderBooklistItem()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  playlistItemReducer,
  playlistItemBookReducer,
  playlistReducer,
}) => ({
  playlistItemReducer,
  playlistItemBookReducer,
  playlistReducer,
});

const mapDispatchToProps = {
  getPlaylistItem,
  getBooklistItem,
  getPlaylist,
  deletePlaylistItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistItem);

import React, { Component } from "react";

import { Row, Col, Button, ButtonGroup, ListGroup } from "shards-react";
import { NavLink } from "react-router-dom";

export default class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountantReport: false,
      marketingReport: false,
    };
  }

  componentDidMount() {
    const userProfile = JSON.parse(localStorage.getItem("user_profile"));

    if (userProfile) {
      this.setState({
        accountantReport: userProfile.accountant_report === "Y",
        marketingReport: userProfile.marketing_report === "Y",
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.marketingReport && (
          <Row className="mt-4">
            <Col className="form-group">
              <ListGroup flush>
                <ButtonGroup>
                  <Row>
                    <Col>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/publisher"
                        size="sm"
                      >
                        ยอดขายสำนักพิมพ์/บรรณาธิการ
                      </Button>

                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/publish/book"
                        size="sm"
                      >
                        ประวัติการลงหนังสือ/ประวัติการถอนเงิน
                      </Button>

                    </Col>
                  </Row>
                </ButtonGroup>
              </ListGroup>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

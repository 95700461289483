import { httpClient } from "../utils/HttpClient";

import {
  HTTP_REPORT_PUBLISHER_SUCCESS,
  HTTP_REPORT_PUBLISHER_FETCHING,
  HTTP_REPORT_PUBLISHER_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateReportPublisherToSuccess = (payload) => ({
  type: HTTP_REPORT_PUBLISHER_SUCCESS,
  payload: payload,
});

const setStateReportPublisherToFetching = () => ({
  type: HTTP_REPORT_PUBLISHER_FETCHING,
});

const setStateReportPublisherToFailed = () => ({
  type: HTTP_REPORT_PUBLISHER_FAILED,
});

export const getReportPublisher = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportPublisherToFetching());
    await doGetReportPublisher(dispatch, value);
  };
};

const doGetReportPublisher = async (dispatch, value) => {
  try {
    let param = {};

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00:00";
      else value.start_date = "";

      if (value.end_date) value.end_date = value.end_date + " 23:59:59";
      else
        value.end = moment(new Date())
          .format("YYYY-MM-DD 23:59:59");

      param = value
    } else {
      param = {
        page: "",
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.REPORT_URL}/publisher`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    });
    dispatch(setStateReportPublisherToSuccess(result.data));
  } catch (err) {
    dispatch(setStateReportPublisherToFailed());
  }
};

import React, { Component } from "react";
import { Formik, Field } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";

import { httpClient } from "../../../utils/HttpClient";
import { getCampaignById } from "../../../actions/campaign.action";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import CampaignFrom from "../campaignForm";
import * as Yup from "yup";
import moment from "moment";

const campaignValidationSchema = Yup.object().shape({
  campaign_name: Yup.string().required("กรุณากรอกชื่อ Campaign"),
  campaign_desc: Yup.string().required("กรุณากรอกรายละเอียด Campaign"),
  campaign_coin: Yup.string().required("กรุณากรอกจำนวนเหรียญรางวัล"),
  member_silver_coin_expire: Yup.date().required('กรุณาเลือกวันที่หมดอายุ').nullable()
});

class EditCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = { img_campaign: null };
    this.change = this.change.bind(this);
  }

  change(img) {
    this.setState({ img_campaign: img });
  }

  componentDidMount() {
    let campaign_id = this.props.match.params.campaign_id;
    this.props.getCampaignById(campaign_id);
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 30 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">EDIT Campaign</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <CampaignFrom
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isDisableField={false}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      change={this.change}
                    ></CampaignFrom>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      Update
                    </button>
                    <a
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      Back
                    </a>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    const { result } = this.props.campaignReducer;

    return (
      <React.Fragment>
        {result ? (
          <Formik
            enableReinitialize
            initialValues={result ? result : {}}
            validationSchema={campaignValidationSchema}
            validate={(values, props) => {
              const errors = {};

              if (values.pushTarget === "checkin") {
                if (!values.days_goal) {
                  errors.days_goal = "กรุณากรอก วันที่ Check-in ติดต่อกัน";
                }
              }

              if (values.pushTarget === "chapter") {
                if (!values.chapter_goal) {
                  errors.chapter_goal = "กรุณากรอก จำนวน Chapter ที่ซื้อ";
                }
              }

              if (values.pushTarget === "topup") {
                if (!values.amount_goal) {
                  errors.amount_goal = "กรุณากรอก ยอดการเติมเงิน";
                }
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              let formData = new FormData();

              formData.append("campaign_name", values.campaign_name);

              if(this.state.img_campaign)
                formData.append("campaign_image", this.state.img_campaign);
                
                
              formData.append("campaign_desc", values.campaign_desc);

              var campaignCoin = Number(
                values.campaign_coin.toString().replace(/,/g, "")
              );
              var limitNo = Number(
                values.limit_no.toString().replace(/,/g, "")
              );
              var receiveCoin = Number(
                values.receive_coin.toString().replace(/,/g, "")
              );
              var chapterGoal = Number(
                values.chapter_goal.toString().replace(/,/g, "")
              );
              var dayGoal = Number(
                values.days_goal.toString().replace(/,/g, "")
              );
              var amountGoal = Number(
                values.amount_goal.toString().replace(/,/g, "")
              );

              var numRepeat = Number(
                values.number_repeat.toString().replace(/,/g, "")
              );

              var dailyCheckin = Number(
                values.daily_checkin_receive.toString().replace(/,/g, "")
              );

              formData.append("campaign_coin", campaignCoin);
              formData.append("limit_no", limitNo);
              formData.append("receive_coin", receiveCoin);

              let startDate = moment(values.start_date).format(
                "YYYY-MM-DD 00:00:00"
              );
              formData.append("start_date", startDate);

              let endDate = moment(values.end_date)
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss");

              formData.append("end_date", endDate);

              formData.append("campaign_repeat", values.campaign_repeat);
              formData.append("number_repeat", numRepeat);
              formData.append("is_visible", values.is_visible);

              if (values.pushTarget === "checkin") {
                formData.append("days_goal", dayGoal);
                formData.append("daily_checkin_receive", dailyCheckin);

                //clear other value equal 0
                formData.append("chapter_goal", "0");
                formData.append("book_id", "0");
                formData.append("book_title", "");
                formData.append("category_id", "0");
                formData.append("amount_goal", "0");
              } else if (values.pushTarget === "chapter") {
                formData.append("chapter_goal", chapterGoal);

                if (values.category_id)
                  formData.append(
                    "category_id",
                    Number(values.category_id.toString().replace(/,/g, ""))
                  );

                if (values.book_id)
                  formData.append(
                    "book_id",
                    Number(values.book_id.toString().replace(/,/g, ""))
                  );

                formData.append("book_title", values.book_title);

                //clear other value equal 0
                formData.append("days_goal", "0");
                formData.append("daily_checkin_receive", "0");
                formData.append("amount_goal", "0");
              } else if (values.pushTarget === "topup") {
                formData.append("amount_goal", amountGoal);

                //clear other value equal 0
                formData.append("days_goal", "0");
                formData.append("daily_checkin_receive", "0");

                formData.append("chapter_goal", "0");
                formData.append("book_id", "0");
                formData.append("book_title", "");
                formData.append("category_id", "0");
              }

              let username = "";
              var result = localStorage.getItem("user_profile");
              if (result) username = JSON.parse(result).user_username;
              formData.append("updated_by", username);

              var dateTime = new Date();
              dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
              formData.append("updated_datetime", dateTime);

              let coinExpire = moment(values.member_silver_coin_expire)
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss");

              formData.append("member_silver_coin_expire", coinExpire);

              var object = {};
              formData.forEach((value, key) => {
                object[key] = value;
              });
              var json = JSON.stringify(object);

              const token = localStorage.getItem("token");
              let response = await httpClient.patch(
                server.CAMPAIGN_URL + "/" + `${values.campaign_id}`,
                json,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Update Complete",
                  showConfirmButton: true,
                  timer: 10000,
                });

                setSubmitting(false);
                this.props.history.goBack();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  timer: 2500,
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ campaignReducer }) => ({ campaignReducer });

const mapDispatchToProps = {
  getCampaignById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);

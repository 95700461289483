import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../../constants";
import { Card, CardHeader, CardBody, Row, Col } from "shards-react";
import { httpClient } from "../../../../utils/HttpClient";
import Swal from "sweetalert2";
import BookForm from "../bookForm";
import * as Yup from "yup";
import moment from "moment";
import { getBookByID, getBookCategory } from "../../../../actions/book.action";
import { connect } from "react-redux";
import imageCompression from "browser-image-compression";
import { Button } from "antd";

const bookValidationSchema = Yup.object().shape({
  book_title: Yup.string().required("กรุณากรอก ชื่อ หนังสือ"),
});

class EditBook extends Component {
  constructor() {
    super();
    this.state = {
      book: "",
      img_book: null,
      img_cover: null,
      img_landscape: null,
      bookFile: null,
      bookCoverFile: null,
      booklandscapeFile: null,
      page: "",
    };
    this.change = this.change.bind(this);
    this.updateThumbnail = this.updateThumbnail.bind(this);
    this.removeThumbnail = this.removeThumbnail.bind(this);
  }

  async componentDidMount() {
    let book_id = this.props.match.params.book_id;

    if (this.props.location.page) {
      localStorage.setItem("book_current_page", this.props.location.page);
    }

    await this.props.getBookByID(book_id);

    if (this.props.bookReducer.book) {
      let book = this.props.bookReducer.book;
      let categories = [];
      let subCategories = [];

      //Book Category
      if (this.props.bookReducer.book.categories) {
        this.props.bookReducer.book.categories.map((item) => {
          const isExist =
            categories.filter((category) => {
              return category.value === item.category.category_id;
            }).length > 0;

          if (!isExist) {
            categories.push({
              value: item.category.category_id,
              label: item.category.category_name,
            });
          }

          if (item.sub_category) {
            subCategories.push({
              value: item.sub_category.sub_category_id,
              label: item.sub_category.sub_category_name,
              mainCategoryId: item.sub_category.category_id,
              bookCategoryId: item.book_category_id,
            });
          }
        });
      }

      book.categories = categories;
      book.sub_categories = subCategories;

      let writers = [];

      if (book.creators) {
        book.creators.map((writer) => {
          writers.push({
            value: writer.creator_id,
            label: writer.writer.member_name,
            income: writer.income_ratio,
          });
        });
      }

      book.creators = writers;

      if (book.book_image) {
        this.setState({ img_book: book.book_image });
      }

      if (book.book_cover) {
        this.setState({ img_cover: book.book_cover });
      }

      if (book.book_image_mobile) {
        this.setState({ img_landscape: book.book_image_mobile });
      }

      this.setState({ book });

      const { page } = this.props.location;
      this.setState({ page });
    }
  }

  change(img, type) {
    if (type === "book") {
      this.setState({ img_book: img });
    } else if (type === "cover") {
      this.setState({ img_cover: img });
    } else if (type === "landscape") {
      this.setState({ img_landscape: img });
    }
  }

  updateThumbnail(file, type) {
    if (type === "book") {
      this.setState({
        bookFile: file,
      });
    } else if (type === "cover") {
      this.setState({
        bookCoverFile: file,
      });
    } else if (type === "landscape") {
      this.setState({
        booklandscapeFile: file,
      });
    }
  }

  removeThumbnail(type) {
    let book = this.state.book;

    if (type === "book") {
      book.book_image = "";

      this.setState({
        bookFile: null,
      });
    } else if (type === "cover") {
      book.book_cover = "";

      this.setState({
        bookCoverFile: null,
      });
    } else if (type === "landscape") {
      book.book_image_mobile = "";

      this.setState({
        booklandscapeFile: null,
      });
    }

    this.setState({ book });
  }

  async uploadImage(image) {
    const options = {
      maxSizeMB: 0.1,
      useWebWorker: true,
      initialQuality: 0.5,
    };
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        const token = localStorage.getItem("token");
        formData.append("upload", imagefile);

        const response = await httpClient.post(server.IMG_URL, formData, {
          headers: {
            enctype: "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 201 || response.status === 200) {
          return response.data.url;
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง",
        timer: 3000,
      });
    }
  }

  async addBookWriter(book_id, creators) {
    if (creators.length > 0) {
      var i;
      for (i = 0; i < creators.length; i++) {
        let formData = new FormData();
        formData.append("book_id", book_id);
        formData.append("creator_id", creators[i].creator_id);
        formData.append("dbstatus", "A");

        let username = "";
        var result = localStorage.getItem("user_profile");
        if (result) username = JSON.parse(result).user_username;

        let dateTime = new Date();
        dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

        formData.append("created_by", username);
        formData.append("created_datetime", dateTime);
        formData.append("updated_by", username);
        formData.append("updated_datetime", dateTime);

        var object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        var json = JSON.stringify(object);

        const token = localStorage.getItem("token");
        let response = httpClient.post(`${server.BOOK_WRITER_URL}`, json, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
  }

  async addBookCategory(book_id, categorys) {
    if (categorys.length > 0) {
      var i;
      for (i = 0; i < categorys.length; i++) {
        let formData = new FormData();
        formData.append("book_id", book_id);
        formData.append("category_id", categorys[i].category_id);
        formData.append("sub_category_id", categorys[i].sub_category_id);

        formData.append("dbstatus", "A");

        let username = "";
        var result = localStorage.getItem("user_profile");
        if (result) username = JSON.parse(result).user_username;

        let dateTime = new Date();
        dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

        formData.append("created_by", username);
        formData.append("created_datetime", dateTime);
        formData.append("updated_by", username);
        formData.append("updated_datetime", dateTime);

        var object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        var json = JSON.stringify(object);

        const token = localStorage.getItem("token");
        let response = httpClient.post(`${server.BOOK_CATEGORY_URL}`, json, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      }
    }
  }

  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 40 }}>
        <Card>
          <CardHeader className="border-bottom">
            <h6 className="m-0">EDIT BOOK</h6>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <BookForm
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    value={this.state.value}
                    onUpdateThumbnail={this.updateThumbnail}
                    onRemoveThumbnail={this.removeThumbnail}
                    change={this.change}
                  ></BookForm>

                  <div className="text-right">
                    <Button
                      type="button"
                      loading={isSubmitting}
                      className="btn btn-primary"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      บันทึก
                    </Button>
                    <a
                      onClick={() =>
                        //this.props.history.push(`/book`)
                        this.props.history.push({
                          pathname: "/book",
                          page: this.state.page,
                        })
                      }
                      type="Button"
                      className="btn btn-danger"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </a>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  render() {
    let { book } = this.state;

    return (
      <React.Fragment>
        {book ? (
          <Formik
            enableReinitialize
            initialValues={book ? book : {}}
            validationSchema={bookValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              let username = "";
              var result = localStorage.getItem("user_profile");
              if (result) username = JSON.parse(result).user_username;

              let isFormValid = true;

              if (values.categories.length === 0) {
                Swal.fire({
                  icon: "error",
                  text: "กรุณาระบุ Category",
                });

                return;
              }

              if (values.sub_categories.length === 0) {
                Swal.fire({
                  icon: "error",
                  text: "กรุณาระบุ SubCategory",
                });

                return;
              }

              //Check if all main category has sub-category
              let missingSubCategory = "";

              values.categories.map((mainCategory) => {
                let isSubCategoryExist = false;

                values.sub_categories.map((subCategory) => {
                  if (subCategory.mainCategoryId === mainCategory.value) {
                    isSubCategoryExist = true;
                  }
                });

                if (!isSubCategoryExist) {
                  isFormValid = false;
                  missingSubCategory = mainCategory.label;
                }
              });

              if (!isFormValid) {
                Swal.fire({
                  icon: "error",
                  text: `กรุณาระบุหมวดหมู่ย่อยของ ${missingSubCategory}`,
                });

                return;
              }

              let bookCategories = [];

              values.sub_categories.map((category) => {
                bookCategories.push({
                  category_id: category.mainCategoryId,
                  sub_category_id: category.value,
                  created_by: username,
                });
              });

              let bookWriters = [];
              let totalIncomeRatio = 0;

              values.creators.map((creator) => {
                if (!creator.income) creator.income = 0;

                totalIncomeRatio += parseInt(creator.income);

                bookWriters.push({
                  book_id: values.book_id,
                  creator_id: creator.value,
                  income_ratio: creator.income,
                  created_by: username,
                });
              });

              let botnoi_ratio = "";
              if (values.bot_read == "Y") {
                if (values.botnoi_income_ratio) {
                  botnoi_ratio = Number(
                    values.botnoi_income_ratio.toString().replace(/,/g, "")
                  );
                } else botnoi_ratio = 0;
              } else botnoi_ratio = 0;

              if (bookWriters.length === 0) {
                Swal.fire({
                  icon: "error",
                  text: `กรุณากรอกข้อมูลนักเขียนให้ครบถ้วน`,
                });

                return;
              }

              if (totalIncomeRatio !== 100) {
                Swal.fire({
                  icon: "error",
                  text: "สัดส่วนรายได้ของนักเขียนต้องรวมกันได้ 100%",
                });
                return;
              }

              let updateDate = new Date();
              updateDate = moment(updateDate).format("YYYY-MM-DD HH:mm:ss");

              if (values.bookStart) {
                values.bookStart = new Date(values.bookStart);

                if (!values.startdate)
                  values.startdate = moment(values.bookStart).format(
                    "YYYY-MM-DD 00:00:00"
                  );

                values.startdate = new Date(values.startdate);

                const startDate = values.bookStart.getDate();
                const startMonth = values.bookStart.getMonth();
                const startYear = values.bookStart.getFullYear();

                values.startdate.setDate(startDate);
                values.startdate.setMonth(startMonth);
                values.startdate.setFullYear(startYear);

                values.startdate = moment(values.startdate).format(
                  "YYYY-MM-DD HH:mm:00"
                );
              } else values.startdate = null;

              if (!values.norder) values.norder = 0;

              if (this.state.bookFile) {
                values.book_image = await this.uploadImage(this.state.bookFile);
              }

              if (this.state.booklandscapeFile) {
                values.book_image_mobile = await this.uploadImage(
                  this.state.booklandscapeFile
                );
              }

              if (this.state.bookCoverFile) {
                values.book_cover = await this.uploadImage(
                  this.state.bookCoverFile
                );
              }

              let params = {
                book_id: values.book_id,
                book_title: values.book_title,
                book_type_id: values.book_type_id,
                book_sub_title: values.book_sub_title,
                book_desc: values.book_desc,
                categories: bookCategories,
                creators: bookWriters,
                translator: values.translator,
                book_image: values.book_image,
                book_cover: values.book_cover,
                book_image_mobile: values.book_image_mobile,
                book_tags: values.book_tags,
                stock_link: values.stock_link,
                book_status: values.book_status,
                is_finish: values.is_finish,
                norder: values.norder,
                banner: values.banner,
                all_chapters_discount: values.all_chapters_discount,
                updated_by: username,
                updated_datetime: updateDate,
                startdate: values.startdate,
                silver_coin_use: values.silver_coin_use,
                bot_read: values.bot_read,
                botnoi_income_ratio: botnoi_ratio,
                buy_whole_book: values.buy_whole_book,
                editor_income_ratio: values.editor_income_ratio,
                publisher_income_ratio: values.publisher_income_ratio,
                gold_coin_ratio: values.gold_coin_ratio,
                silver_coin_ratio: values.silver_coin_ratio
              };

              const json = JSON.stringify(params);

              const token = localStorage.getItem("token");
              let response = await httpClient.patch(
                server.BOOK_URL + "/" + `${values.book_id}`,
                json,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "หนังสือ ถูกบันทึกเรียบร้อยแล้ว",
                  showConfirmButton: false,
                  timer: 3000,
                });
                setSubmitting(false);
                //this.props.history.push(`/book`);
                this.props.history.push({
                  pathname: "/book",
                  page: this.state.page,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                  timer: 3000,
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookReducer }) => ({ bookReducer });

const mapDispatchToProps = {
  getBookByID,
  getBookCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBook);

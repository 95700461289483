import {
    HTTP_REPORT_INVITE_FETCHING,
    HTTP_REPORT_INVITE_SUCCESS,
    HTTP_REPORT_INVITE_FAILED,
  } from "../constants/report";
  
  const initialState = {
    result: null,
    isFetching: false,
    isError: false,  
    total: 0
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_REPORT_INVITE_SUCCESS:        
        return { 
          ...state, 
          result: payload.items, 
          total: payload.total,
          isFetching: false, 
          isError: false 
        };
      case HTTP_REPORT_INVITE_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case HTTP_REPORT_INVITE_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
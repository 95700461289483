import React, { Component } from "react";
import * as actions from "../../../actions/user.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
} from "shards-react";

import Swal from "sweetalert2";

class UserRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      loading: false
    };
  }

  async componentDidMount() {
    await this.getUserRole();
  }

  async getUserRole() {
    this.setState({ loading: true });

    await this.props.getAllUserRole()
    
    if (this.props.userReducer.roles) {
      this.setState({ roles: this.props.userReducer.roles });
    }
    this.setState({ loading: false });
  }

  async deleteRole(id)  {
    await this.props.deleteRole(id);
    await this.getUserRole()
  }

  renderTable = () => {
    return (
      <table className="table table-bordered mb-0">
        <thead>
          <tr>
            <th>Role Name</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {this.state.roles.map(role => {
            return (
              <tr key={role.role_id}>
                <td className="text-left align-middle">{role.role_name}</td>
                <td className="text-center align-middle" width="250">
                  <a
                    className="btn btn-sm btn-secondary mr-2"
                    href={`/editRole/${role.role_id}`}
                  >
                    แก้ไข
                  </a>

                  <button
                    onClick={() => {
                      Swal.fire({
                        text: `ยืนยันการลบ Role ${role.role_name}?`,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "ลบ",
                        cancelButtonText: "ยกเลิก",
                      }).then((result) => {
                        if (result.value) {
                          this.deleteRole(role.role_id);
                        }
                      });
                    }}
                    type="button"
                    className="btn btn-sm btn-danger"
                    style={{ color: "white" }}
                  >
                    ลบ
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <Container fluid>
        <div style={{ padding: 20 }}>
          <Card className="mb-3">
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Admin Role</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => this.props.history.push(`/createRole`)}
                    className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add Role
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ userReducer, appReducer }) => ({
  userReducer,
  appReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRole);

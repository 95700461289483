import React, { Component, useEffect, useRef } from "react";
import * as messageActions from "../../../actions/book_message.action";
import * as characterActions from "../../../actions/book_character.action";
import { connect } from "react-redux";
import { Menu, Dropdown, Upload, Modal, Button, Tooltip, Popover } from 'antd';
import Swal from "sweetalert2";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import BookCharacterGroup from './BookCharacterGroup';
import BookMessageItem from './BookMessageItem';
import BookMessageItemLoading from './BookMessageItemLoading';
import ReactResizeDetector from 'react-resize-detector';
import imageCompression from 'browser-image-compression';
import NumberFormat from "react-number-format";
import Picker from 'emoji-picker-react';
import { 
  SendOutlined, 
  CaretDownOutlined, 
  FontColorsOutlined, 
  PictureOutlined,
  CloseCircleFilled,
  LoadingOutlined,
  CloseOutlined,
  EditOutlined,
  PhoneOutlined,
  YoutubeOutlined,
  LinkOutlined,
  SmileOutlined
} from '@ant-design/icons';
import ChatPreview from "./ChatPreview";

class ChatChapterForm extends Component {
  constructor() {
    super();
    this.state = {
      characterImage: '',
      characterName: '',
      characterRole: '',
      characterDeleteId: '',
      characterModalOpen: false,
      previewModalOpen: false,
      emojiModalOpen: false,
      activeSpecialMessage: '',
      phoneCallHour: '',
      phoneCallMinute: '',
      phoneCallSecond: '',
      mainCharacter: null,
      currentCharacter: null,
      newMessages: [],
      messageValue: '',
      isNewImage: false,
      imagePreview: '',
      imageFile: null,
      imageUploadedUrl: '',
      targetMessageId: null,
      messageId: null,
      loading: false,
      preventScrollBottom: false,
      openChatOptions: false,
      messageType: '',
      youtubeDescription: '',
      youtubeThumbnail: '',
      youtubeTitle: '',
      isInputFocus: false,
      messagePosition: 'left',
      inputCursorPosition: 0
    }

    this.messageBody = React.createRef();
    this.messageBodyInner = React.createRef();
    this.onEmojiClick = this.onEmojiClick.bind(this);
  }

  async componentDidMount() {
    await this.props.getChapterCharacters(this.props.bookId, this.props.chapterId);
    this.props.getBookMessages(this.props.chapterId)
  }

  async uploadImage(image, type) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
      initialQuality: 0.8
    }
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, {type: image.type});

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        const token = localStorage.getItem("token");
        formData.append("upload", imagefile);
        
        const response = await httpClient.post(server.IMG_URL, formData, {
          headers: {
            enctype: "multipart/form-data",
            "Authorization": `Bearer ${token}`
          },
        });

        if (response.status === 201 || response.status === 200) {
          if (type === 'character') {
            this.setState({
              characterImage: response.data.url,
            })
          } else {
            this.setState({
              imageUploadedUrl: response.data.url,
            })
          }
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง",
        timer: 3000
      });
    }
  }

  async addCharacter()  {
    if (!this.state.characterImage) {
      Swal.fire({
        icon: "error",
        text: "กรุณาอัพโหลดรูปตัวละคร",
        timer: 3000
      });

      return
    }

    if (!this.state.characterName) {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอกชื่อตัวละคร",
        timer: 3000
      });

      return
    }

    const params = {
      book_id: this.props.bookId,
      name: this.state.characterName,
      role: this.state.characterRole,
      image: this.state.characterImage
    }

    await this.props.addBookCharacter(params)

    if(!this.props.bookCharacterReducer.isError)  {
      this.setState({
        characterModalOpen: false,
        characterImage: '',
        characterName: '',
        characterRole: ''
      })

      Swal.fire({
        icon: "success",
        title: "เพิ่มตัวละครเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "ไม่สามารถเพิ่มตัวละครได้",
        timer: 1500
      });
    }
  }

  changeCharacter(character) {
    if(character) {
      this.setState({
        currentCharacter: character
      })
    }
  }

  scrollToBottom()  {
    if(!this.state.preventScrollBottom && this.messageBody.current) {
        const scrollPosition =
        this.messageBody.current.scrollHeight -
        this.messageBody.current.clientHeight;
      this.messageBody.current.scrollTo(0, scrollPosition);
    }
  }

  handleKeyPress(event, currentCharacter) {
    if(event.key === 'Enter'){
      this.updateMessageList(currentCharacter)
    }
  }

  async updateMessageList(currentCharacter) {
    let isValid = true

    const { messages } = this.props.bookMessageReducer

    if(messages.length >= 1000 && !this.state.messageId) {
      Swal.fire({
        icon: "error",
        text: "ไม่สามารถเพิ่มแชทเกิน 1,000 แชทได้"
      })

      return false
    }
  
    if(!this.state.activeSpecialMessage)  {
      if(!this.state.imagePreview && !this.state.messageValue) {
        isValid = false
      }
    } else {
      if(this.state.activeSpecialMessage === 'link' || this.state.activeSpecialMessage === 'youtube') {
        if(!this.state.messageValue) {
          isValid = false
        }  
      } else if(this.state.activeSpecialMessage === 'phone') {
        if(!this.state.phoneCallHour && !this.state.phoneCallMinute && !this.state.phoneCallSecond) {
          isValid = false
        }
      }
    }

    if(isValid)  {
      const messages = this.props.bookMessageReducer.messages
      let messageOrder = messages.length + 1
      let activeMessage = null

      if(this.state.messageId)  {
        activeMessage = messages.filter(item => item.id === this.state.messageId)[0]
        messageOrder = activeMessage.order

        this.setState({
          preventScrollBottom: true
        })
      }

      if(this.state.targetMessageId)  {
        activeMessage = messages.filter(item => item.id === this.state.targetMessageId)[0]
      }

      this.setState({
        loading: true,
      })

      let messageType = 1
      let messageContent = this.state.messageValue
      
      if(this.state.imagePreview)  {
        messageType = 2
        
        if(this.state.isNewImage) {
          await this.uploadImage(this.state.imageFile, 'message')
          messageContent = this.state.imageUploadedUrl
        } else {
          messageContent = this.state.imagePreview
        }
      }

      if(currentCharacter.id === 0) {
        messageType = 3
      }

      if(this.state.activeSpecialMessage === 'phone') {
        messageType = 4
        let hour = ''
        let minute = ''
        let second = ''

        if(!this.state.phoneCallHour) {
          hour = '00'
        } else if(this.state.phoneCallHour < 10)  {
          hour = '0' + this.state.phoneCallHour.toString()
        } else {
          hour = this.state.phoneCallHour.toString()
        }

        if(!this.state.phoneCallMinute) {
          minute = '00'
        } else if(this.state.phoneCallMinute < 10)  {
          minute = '0' + this.state.phoneCallMinute.toString()
        } else {
          minute = this.state.phoneCallMinute.toString()
        }

        if(!this.state.phoneCallSecond) {
          second = '00'
        } else if(this.state.phoneCallSecond < 10)  {
          second = '0' + this.state.phoneCallSecond.toString()
        } else {
          second = this.state.phoneCallSecond.toString()
        }

        messageContent = `${hour}:${minute}:${second}`
      }

      if(this.state.activeSpecialMessage === 'phone-cancel') {
        messageType = 5
      }

      if(this.state.activeSpecialMessage === 'phone-miss') {
        messageType = 6
      }

      if(this.state.activeSpecialMessage === 'link') {
        messageType = 7
      }

      if(this.state.activeSpecialMessage === 'youtube') {
        messageType = 8
      }
        
      const params = {
        id: this.state.messageId,
        book_chapter_id: this.props.chapterId,
        message: messageContent,
        character_id: currentCharacter.id,
        type: messageType,
        message_order: messageOrder,
        message_position: activeMessage ? activeMessage.message_position : (currentCharacter.id === this.props.bookChapterReducer.mainCharacter ? 'right' : 'left')
      }

      if(!params.id) {
        if(this.state.targetMessageId)  {
          await this.updateMessageOrder(params)
        } else {
          await this.props.addBookMessage(params)

          this.setState({
            preventScrollBottom: false
          })
        }
      } else {
        await this.props.editBookMessage(params)
      }

      if(!this.props.bookMessageReducer.isError)  {
        this.setState({
          messageValue: '',
          imageUploadedUrl: '',
          imagePreview: '',
          imageFile: null,
          messageId: null,
          loading: false,
          activeSpecialMessage: '',
          youtubeThumbnail: '',
          youtubeTitle: '',
          youtubeDescription: '',
          isInputFocus: false
        })
      } else {
        this.setState({loading: false})

        Swal.fire({
          icon: "error",
          text: "เกิดข้อผิดพลาดในการแก้ไขข้อมูล",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอกข้อความ",
      });
    }
  }

  async updateMessageOrder(message) {
    let messages = this.props.bookMessageReducer.messages
    const targetMessage = messages.filter(item => item.id === this.state.targetMessageId)[0]

    const index = messages.indexOf(targetMessage)

    messages.splice(index, 0, message);

    messages = messages.map((item, index) => {
      return {
        ...item,
        order: index + 1
      }
    })

    this.setState({
      targetMessageId: null
    })

    await this.props.insertBookMessage(messages)
  }

  handleResize()  {
    this.scrollToBottom()
  }

  editMessage(message) {
    const { characters } = this.props.bookCharacterReducer

    let character = characters.filter(item => item.id === message.character_id)[0]
    let messageValue = message.message

    if(!character)  {
      character = {
        id: 0
      }
    }

    if(message.type === 2)  {
      messageValue = ""

      this.setState({
        imageUploadedUrl: message.message,
        imagePreview: message.message
      })
    }

    let specialType = ''

    if(message.type === 4)  {
      specialType = 'phone'
      const callTime = message.message.split(':')

      this.setState({
        phoneCallHour: callTime[0] || '0',
        phoneCallMinute: callTime[1] || '0',
        phoneCallSecond: callTime[2] || '0'
      })
    } else if(message.type === 5)  {
      specialType = 'phone-cancel'
    } else if(message.type === 6)  {
      specialType = 'phone-miss'
    } else if(message.type === 7)  {
      specialType = 'link'
    } else if(message.type === 8)  {
      specialType = 'youtube'
    }
    
    this.setState({
      preventScrollBottom: true,
      messageId: message.id,
      targetMessageId: null,
      currentCharacter: character,
      activeSpecialMessage: specialType,
      messagePosition: message.message_position,
      messageValue
    })
  }

  insertMessage(item, youtubeData) {
    this.setState({
      targetMessageId: item.id,
      messageId: null,
      messageValue: "",
      phoneCallHour: "",
      phoneCallMinute: "",
      phoneCallSecond: "",
      activeSpecialMessage: "",
      youtubeThumbnail: youtubeData ? youtubeData.youtubeThumbnail: "",
      youtubeTitle: youtubeData ? youtubeData.youtubeTitle: "",
      youtubeDescription: youtubeData ? youtubeData.youtubeDescription: "",
    })
  }

  async deleteMessage(id) {
    this.setState({loading: true})

    await this.props.deleteBookMessage(id)

    this.setState({loading: false})
  }

  getInsertBlock(item)  {
    const mainClass = item.character_id === this.props.mainCharacter ? 'item-reverse' : ''

    return (  
      <div className={`chat-message-item ${mainClass}`}>
        { item.type !== 3 && (
          <div className="message-avatar" style={{minWidth: 40}}>
            <img src={item.character_image} alt="" width="40" className="rounded-circle" />
          </div>
        )}

        { (item.type === 1 || item.type === 7) && (
          <div className="message-bubble">
            { item.type === 1 ?
              item.message : <span className="text-underline">{ item.message }</span>
            }
          </div>
        )}

        { item.type === 2 && (
          <div>
            <img 
              src={item.message} 
              className="img-fluid pointer" 
              width="150" 
              alt=""
            />
          </div>
        )}

        { item.type === 3 && (
          <div className="system-message-badge rounded-pill pointer align-self-center m-0" onClick={() => this.editMessage()}>
            { item.message }
          </div>
        )}

        { (item.type === 4 || item.type === 5 || item.type === 6) && (
          <div 
            className="message-phone message-content bg-grey px-5 py-4 d-flex flex-column align-items-center justify-content-center rounded"
            onClick={() => this.editMessage()} 
          >
            { item.type === 4 &&
              <>
                <div className="message-phone-icon">
                  <PhoneOutlined style={{fontSize: 24, marginLeft: 4}} />
                </div>
                <div className="mt-1">{item.message}</div>
              </>
            }
            { item.type === 5 &&
              <>
                <div className="message-phone-icon phone-cancel">
                  <PhoneOutlined style={{fontSize: 24, marginLeft: 4}} />
                </div>
                <div className="mt-1 font-12">ไม่ได้รับสาย</div>
              </>
            }
            { item.type === 6 &&
              <>
                <div className="message-phone-icon phone-miss">
                  <PhoneOutlined style={{fontSize: 24}} />
                </div>
                <div className="mt-1 font-12">ไม่มีการตอบรับ</div>
              </>
            }
          </div>
        )}

        { item.type === 8 && (
          <>
            <div className="message-video message-content" onClick={() => this.editMessage()} >
              <img src={this.state.youtubeThumbnail} alt="" className="img-fluid" />
              <div className="bg-grey p-2">
                <div className="font-12 font-weight-bold text-truncate">{this.state.youtubeTitle}</div>
                <div className="mt-1 message-video-desc">{this.state.youtubeDescription}</div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  onPositionChange(value) {
    this.setState({
      messagePosition: value ? 'right' : 'left'
    })
  }

  onEmojiClick(event, emojiObject) {
    const currentValue = this.state.messageValue
    const currentPosition = this.state.inputCursorPosition

    const value1 = currentValue.substring(0, currentPosition)
    const value2 = currentValue.substring(currentPosition, currentValue.length)

    this.setState({
      messageValue: value1 + emojiObject.emoji + value2
    })
  }

  saveCursorPosition(event) {
    this.setState({ inputCursorPosition: event.target.selectionStart})
  }

  render() {
    const {
      messages,
      isFetching
    } = this.props.bookMessageReducer

    const {
      characters
    } = this.props.bookCharacterReducer

    let targetMessage = null
    let mainCharacter

    const focusClass = this.state.isInputFocus ? 'input-focus' : ''
    
    if(this.state.targetMessageId)  {
      targetMessage = messages.filter(item => item.id === this.state.targetMessageId)[0]

      if(targetMessage) {
        const character = characters.filter(character => character.id === targetMessage.character_id)[0]

        if(character) {
          targetMessage.character_image = character.image
        }
      }
    }

    let characterMenu
    let currentCharacter = {
      id: 0
    }
    
    if(characters.length > 0)  {
      mainCharacter = characters.filter(item => item.is_main === 'Y')[0] ? characters.filter(item => item.is_main === 'Y')[0].id : characters[0].id
      currentCharacter = this.state.currentCharacter ? this.state.currentCharacter : characters[0]
    }

    characterMenu = 
      <Menu style={{width: 160}}>
        {
          characters.map(character => {
            return  (
              <Menu.Item key={character.id} onClick={() => this.changeCharacter(character)}>
                <div className="d-flex align-items-center">
                  <img src={character.image} alt={character.name} width="30" className="rounded-circle mr-2" />
                  { character.name }
                </div>
              </Menu.Item>
            )
          })
        }
        { !this.state.activeSpecialMessage && 
          <Menu.Item onClick={() => this.changeCharacter({id: 0})}>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-center rounded-circle border mr-2" style={{width:30, height:30}}><FontColorsOutlined /></div>
              ข้อความบรรยาย
            </div>
          </Menu.Item>
        }
      </Menu>
    
    const uploadProps = {
      accept: "image/png, image/jpeg, image/gif",
      showUploadList: false,
      beforeUpload: file => {
        const reader = new FileReader()
        
        reader.addEventListener("load", () =>
          this.setState({
            imageFile: file,
            isNewImage: true,
            imagePreview: [reader.result],
            messageValue: ''
          })
        )
        reader.readAsDataURL(file)
      }
    }

    const editClass = this.state.messageId || this.state.targetMessageId ? "message-edit-mode" : ""
    const loadingClass = this.state.loading ? "message-loading" : ""

    return (
      <>
        <BookCharacterGroup bookId={this.props.bookId} chapterId={this.props.chapterId} />

        <div className="chat-preview my-4">
          <div className="text-right mb-3 mx-auto" style={{maxWidth: 500}}>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={() => {
                this.setState({ previewModalOpen: true });
              }}
              style={{ width: 100 }}
            >
              ดูตัวอย่าง
            </button>
          </div>
          <div className="chat-container mx-auto">
            <ReactResizeDetector targetRef={this.messageBodyInner} onResize={() => this.handleResize()}>
              <div 
                className={`chat-message-group flex-fill ${editClass} ${loadingClass}`} 
                ref={this.messageBody}
              >
                <div ref={this.messageBodyInner}>
                {
                  isFetching ? 
                  <>
                    <BookMessageItemLoading mainCharacter={false}></BookMessageItemLoading>
                    <BookMessageItemLoading mainCharacter={true}></BookMessageItemLoading>
                  </>
                  : messages.map(item => {
                    const character = characters.filter(character => character.id === item.character_id)[0]
                  
                    if(character) {
                      item.character = character
                    } else {
                      item.character = characters[0] || {}
                    }

                    return (
                      <BookMessageItem
                        onEditMessage={(item) => this.editMessage(item)}
                        onInsertMessage={(item, youtubeData) => this.insertMessage(item, youtubeData)}
                        onDeleteMessage={(id) => this.deleteMessage(id)}
                        onHeightChange={this.scrollToBottom()}
                        editable={true}
                        item={item}
                        activeItem={this.state.messageId || this.state.targetMessageId}
                        key={item.id}
                      ></BookMessageItem>
                    )
                  })
                }
                </div>
              </div>
            </ReactResizeDetector>
            { targetMessage && 
              <div className="insert-message-block">
                <div className="mb-2 d-flex align-items-center">
                  แทรกข้อความด้านบน
                  <span 
                    className="ml-auto pointer" 
                    onClick={() => this.setState({
                      targetMessageId: null,
                      youtubeThumbnail: "",
                      youtubeTitle: "",
                      youtubeDescription: "",
                    })}
                  ><CloseOutlined /></span>
                </div>
                { this.getInsertBlock(targetMessage) }
              </div>
            }
            <div className="chat-footer">
              <div className="d-flex align-items-center">
                <div className={"position-relative input-chat flex-fill " + (this.state.activeSpecialMessage ? 'no-image' : '')}>
                  <div className="input-icon pointer">
                    <div className="d-flex align-items-center" style={{paddingTop: 5}}>
                      <Upload {...uploadProps}>
                        <PictureOutlined style={{ color: '#bbb', fontSize: 18}} />
                      </Upload>
                      <div className="ml-2" style={{ position: 'relative', top: -2 }} onClick={() => this.setState({ emojiModalOpen: true })}>
                        {/* <Popover
                          content={<Picker onEmojiClick={this.onEmojiClick}/>}
                          title={null}
                          trigger="click"
                          overlayClassName="popover-body-p-0"
                          // visible={visible}
                        > */}
                        <SmileOutlined style={{ color: '#bbb', fontSize: 18}} />
                        {/* </Popover> */}
                      </div>
                    </div>
                  </div>

                  { this.state.activeSpecialMessage === 'phone' ? 
                    <div className="d-flex align-items-center">
                      <input 
                        type="number" 
                        min="0"
                        max="59"
                        className="form-control rounded-pill border-0 text-center" 
                        placeholder="ชั่วโมง"
                        value={this.state.phoneCallHour}
                        onChange={(event) => this.setState({ phoneCallHour: event.target.value < 60 ? event.target.value : 60 })}
                        onKeyPress={(event) => this.handleKeyPress(event, currentCharacter)}
                      />
                      <div className="mx-2">:</div>
                      <input 
                        type="number" 
                        min="0"
                        max="59"
                        className="form-control rounded-pill border-0 text-center" 
                        placeholder="นาที"
                        value={this.state.phoneCallMinute}
                        onChange={(event) => this.setState({ phoneCallMinute: event.target.value < 60 ? event.target.value : 60 })}
                        onKeyPress={(event) => this.handleKeyPress(event, currentCharacter)}
                      />
                      <div className="mx-2">:</div>
                      <input 
                        type="number" 
                        min="0"
                        max="59"
                        className="form-control rounded-pill border-0 text-center" 
                        placeholder="วินาที"
                        value={this.state.phoneCallSecond}
                        onChange={(event) => this.setState({ phoneCallSecond: event.target.value < 60 ? event.target.value : 60 })}
                        onKeyPress={(event) => this.handleKeyPress(event, currentCharacter)}
                      />
                    </div>
                  :
                    <input 
                      type="text" 
                      className={`form-control rounded-pill ${focusClass}`}
                      readOnly={this.state.imageFile || this.state.imageUploadedUrl || this.state.activeSpecialMessage === 'phone-miss' || this.state.activeSpecialMessage === 'phone-cancel'}
                      value={this.state.messageValue}
                      placeholder={this.state.isInputFocus ? 'กรุณาวางลิงก์' : ''}
                      onChange={(event) => this.setState({ messageValue: event.target.value })}
                      onKeyPress={(event) => this.handleKeyPress(event, currentCharacter)}
                      onBlur={(event) => this.saveCursorPosition(event) }
                    />
                  }
                </div>
                <Dropdown 
                  overlay={characterMenu} 
                  trigger={['click']} 
                  placement="topRight"
                >
                  <div>
                    <div className="mx-2 d-flex align-items-center pointer">
                      { currentCharacter.id === 0 ?
                        <div className="d-flex align-items-center justify-content-center rounded-circle border mr-2" style={{width:35, height:35}}><FontColorsOutlined /></div>
                        :
                        <img 
                          src={currentCharacter.image} 
                          alt="" 
                          width="35" 
                          className="rounded-circle mr-1" 
                        />
                      }
                      <CaretDownOutlined />
                    </div>
                  </div>
                </Dropdown>
                <Tooltip title={this.state.messageId ? 'แก้ไข' : ''}>
                  <button 
                    type="button"
                    className="btn btn-chat btn-danger rounded-circle text-white font-12"
                    onClick={() => this.updateMessageList(currentCharacter)}
                  >
                    { this.state.loading ?
                      <LoadingOutlined style={{ fontSize: 24 }} spin /> :
                      this.state.messageId ? <EditOutlined /> : <SendOutlined />
                    }
                  </button>
                </Tooltip>
                { (this.state.messageId || this.state.activeSpecialMessage) &&
                  <Tooltip title="ยกเลิก">
                    <button 
                      type="button"
                      className="btn btn-chat btn-secondary rounded-circle text-white font-12 ml-2"
                      disabled={this.state.loading}
                      onClick={() => this.setState({
                        messageId: null,
                        messageValue: '',
                        imagePreview: '',
                        activeSpecialMessage: '',
                        isInputFocus: false
                      })}
                    >
                      <CloseOutlined />
                    </button>
                  </Tooltip>
                }
              </div>

              <div className="text-right justify-content-end chat-more-option">
                <span className="mr-2">จำนวนแชท</span>
                <b><NumberFormat value={ messages.length } displayType={'text'} thousandSeparator={true} renderText={value => <span className="mx-1 text-primary">{value}</span>} />/1,000</b>
                {/* <Switch
                  checkedChildren="ขวา"
                  unCheckedChildren="ซ้าย"
                  checked={this.state.messagePosition === 'right'}
                  onChange={(value) => this.onPositionChange(value)} 
                /> */}
              </div>

              { this.state.imagePreview && 
                <div className="mt-3" style={{padding: '0 30px 20px'}}>
                  <div className="position-relative d-inline-block">
                    <div 
                      className="position-absolute pointer text-muted" style={{top: -10, right: -8}}><CloseCircleFilled 
                      onClick={() => this.setState({
                        imagePreview: '',
                        imageUploadedUrl: '',
                        imageFile: null
                      })}
                    /></div>
                    <img src={this.state.imagePreview} width="100" className="rounded" alt="" />
                  </div>
                </div>
              }

              <div className="mb-2 pt-3 row message-option-group">
                <div className={"col text-center message-special-btn " + (this.state.activeSpecialMessage !== 'link' && this.state.activeSpecialMessage ? 'option-fade' : '') }>
                  <Button 
                    type="primary"
                    shape="circle" 
                    icon={<LinkOutlined />} 
                    size="large"
                    className="btn-info"
                    onClick={() => this.setState({
                      preventScrollBottom: true,
                      isInputFocus: true,
                      activeSpecialMessage: this.state.activeSpecialMessage !== 'link' ? 'link' : ''
                    })}
                  />
                  <div className="mt-1 font-12">ลิงก์</div>
                </div>
                <div className={"col text-center message-special-btn " + (this.state.activeSpecialMessage !== 'phone' && this.state.activeSpecialMessage ? 'option-fade' : '') }>
                  <Button 
                    shape="circle" 
                    icon={<PhoneOutlined />} 
                    className="btn-success"
                    size="large"
                    onClick={() => this.setState({
                      preventScrollBottom: true,
                      isInputFocus: false,
                      activeSpecialMessage: this.state.activeSpecialMessage !== 'phone' ? 'phone' : ''
                    })}
                  />
                  <div className="mt-1 font-12">โทร</div>
                </div>

                <div className={"col text-center message-special-btn " + (this.state.activeSpecialMessage !== 'phone-cancel' && this.state.activeSpecialMessage ? 'option-fade' : '') }>
                  <Button 
                    shape="circle" 
                    icon={<PhoneOutlined />} 
                    className="btn-danger message-special-btn phone-cancel position-relative"
                    size="large"
                    onClick={() => this.setState({
                      preventScrollBottom: true,
                      isInputFocus: false,
                      activeSpecialMessage: this.state.activeSpecialMessage !== 'phone-cancel' ? 'phone-cancel' : '',
                      messageValue: ''
                    })}
                  >
                    <CloseOutlined className="position-absolute" style={{top:5, right:7, fontSize: 12}} />
                  </Button>
                  <div className="mt-1 font-12">ไม่รับสาย</div>
                </div>

                <div className={"col text-center message-special-btn " + (this.state.activeSpecialMessage !== 'phone-miss' && this.state.activeSpecialMessage ? 'option-fade' : '') }>
                  <Button 
                    shape="circle" 
                    icon={<PhoneOutlined />} 
                    className="btn-secondary message-special-btn phone-miss"
                    size="large"
                    onClick={() => this.setState({
                      preventScrollBottom: true,
                      isInputFocus: false,
                      activeSpecialMessage: this.state.activeSpecialMessage !== 'phone-miss' ? 'phone-miss' : '',
                      messageValue: ''
                    })}
                  />
                  <div className="mt-1 font-12">ไม่ตอบรับ</div>
                </div>

                <div className={"col text-center message-special-btn " + (this.state.activeSpecialMessage !== 'youtube' && this.state.activeSpecialMessage ? 'option-fade' : '') }>
                  <Button 
                    shape="circle" 
                    icon={<YoutubeOutlined />} 
                    className="btn-danger message-special-btn"
                    size="large"
                    onClick={() => this.setState({
                      preventScrollBottom: true,
                      isInputFocus: true,
                      activeSpecialMessage: this.state.activeSpecialMessage !== 'youtube' ? 'youtube' : ''
                    })}
                  />

                  <div className="mt-1 font-12">Youtube</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal 
          title={null} 
          footer={null} 
          visible={this.state.previewModalOpen} 
          onCancel={() => this.setState({previewModalOpen: false})}
          width={1000}
        >
           <ChatPreview 
              messages={messages}
              characters={characters}
              mainCharacter={mainCharacter}
            />
        </Modal>

        <Modal 
          title={null} 
          footer={null} 
          width={278}
          closable={false}
          wrapClassName="modal-body-p-0"
          visible={this.state.emojiModalOpen}
          onCancel={() => this.setState({emojiModalOpen: false})}
        >
          <Picker onEmojiClick={this.onEmojiClick}/>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({ bookMessageReducer, bookCharacterReducer, bookChapterReducer, appReducer }) => ({
  bookMessageReducer,
  bookCharacterReducer,
  bookChapterReducer,
  appReducer,
});

const mapDispatchToProps = {
  ...messageActions,
  ...characterActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatChapterForm);

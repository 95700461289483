import React, { Component } from "react";
import * as actions from "../../actions/package.action";
import { connect } from "react-redux";
import { httpClient } from "../../utils/HttpClient";
import NumberFormat from "react-number-format";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  FormTextarea,
  FormSelect,
  FormCheckbox
} from "shards-react";
import PageTitle from "../../components/Layout/Common/PageTitle";
import Swal from "sweetalert2";
import moment from "moment";

class Packages extends Component {
  constructor() {
    super();

    this.state = {
      packageType: 'gold',
      promotionDesc: '',
      silverCoinExpiredDate: '',
      creditCardPackages: [],
      promptPayPackages: [],
      transferPackages: [],
      inAppPackages: [],
      tokenPackages: [],
      creditCardSilverPackages: [],
      promptPaySilverPackages: [],
      transferSilverPackages: [],
      inAppSilverPackages: [],
    }

    this.handleSwichCheckbox = this.handleSwichCheckbox.bind(this);
  }

  async componentDidMount() {
    await this.props.getPackages()
   
    this.setState({
      creditCardPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'omise' && item.package_coin_type === 'gold'
      ),
      promptPayPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'promptpay' && item.package_coin_type === 'gold'
      ),
      transferPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'transfer' && item.package_coin_type === 'gold'
      ),
      inAppPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'inapp' && item.package_coin_type === 'gold'
      ),
      tokenPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'token' && item.package_coin_type === 'gold'
      ),
      creditCardSilverPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'omise' && item.package_coin_type === 'silver'
      ),
      promptPaySilverPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'promptpay' && item.package_coin_type === 'silver'
      ),
      transferSilverPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'transfer' && item.package_coin_type === 'silver'
      ),
      inAppSilverPackages: this.props.packageReducer.packages.filter(item =>
        item.channel === 'inapp' && item.package_coin_type === 'silver'
      ),
    })
  }

  async handleSwichCheckbox(pacakges, updatedPackage, stateName) {
    pacakges.map((item) => {
      if (item.package_id === updatedPackage.package_id) {
        item.package_status = item.package_status === 'A' ? 'D' : 'A'
      }
    });

    const newState = {};
    await this.setState({ ...this.stateName, ...newState });

    this.updatedPackageStatus(updatedPackage)
  }

  async updatedPackageStatus(item)  {
    let formData = new FormData();
    let result = localStorage.getItem("user_profile");    
    let username
    if (result) username = JSON.parse(result).user_username;

    formData.append("package_status", item.package_status);
    formData.append("updated_datetime",  moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
    formData.append("updated_by", username);

    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    const token = localStorage.getItem("token")
    let response = await httpClient.put(`admin/package/${item.package_id}`, json, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (response.status !== 201 && response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
        timer: 1500,
      });
    }
  }

  renderPacakages(packages, stateName) {
    return packages.map((item, index) => (
      <ListGroupItem className={`px-4 border-top ${index === 0 ? ' rounded-0' : ''}`} key={item.package_id}>
        <div className="d-flex align-items-center">
          <div className="flex-fill">
            Package
            <NumberFormat value={item.package_price} displayType={'text'} thousandSeparator={true} renderText={value => <span className="mx-1 text-primary">{value}</span>} />
            { item.channel === 'token' ? 'S2M' : 'บาท' }
            { item.promotion_privileges ?
              <div className="font-12 text-muted mt-2">สิทธิพิเศษ: {item.promotion_privileges }</div>
              : null 
            } 
          </div>
          <div className="d-flex flex-column" style={{ width: '90px' }}>
            <div className="d-flex align-items-center">
              <img src={item.package_coin_type === 'gold' ? require('../../assets/img/coin.png') : require('../../assets/img/silver-coin.png')} alt="Pacakge Coin" width="25" className="mr-2" />
              <NumberFormat value={item.package_coin} displayType={'text'} thousandSeparator={true} renderText={value => value} />
            </div>
            <div className="d-flex font-12 mt-2">
              โบนัส: {item.coin_bonus || 0}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <FormCheckbox
              toggle
              checked={item.package_status === 'A' ? true : false}
              onChange={e => this.handleSwichCheckbox(packages, item, stateName)}
            />
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={() =>
                this.props.history.push(`/package/${item.package_id}`)
              }
            >แก้ไข</button>
          </div>
        </div>
      </ListGroupItem>
    ))
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Packages"
            className="text-sm-left mb-3"
          />
        </Row>

        <Row>
          <Col md="6">
            <ButtonGroup className="mb-3">
              <Button theme={this.state.packageType === 'gold' ? 'danger ' : 'white'} className="rounded-0" onClick={() => this.setState({ packageType: 'gold' })}>Gold</Button>
              <Button theme={this.state.packageType === 'silver' ? 'danger ' : 'white'} className="rounded-0" onClick={() => this.setState({ packageType: 'silver' })}>Silver</Button>
            </ButtonGroup>
          </Col>
          <Col md="6" className="text-right">
            <Button theme="danger" className="rounded-0" onClick={() => this.props.history.push('/package/create')}>เพิ่ม Package</Button>
          </Col>
        </Row>

        {this.state.packageType === 'gold' ?
          <React.Fragment>
            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">Credit/Debit Card</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.creditCardPackages, 'creditCardPackages')}
              </ListGroup>
            </Card>

            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">Promptpay</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.promptPayPackages, 'promptPayPackages')}
              </ListGroup>
            </Card>

            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">Transfer</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.transferPackages, 'transferPackages')}
              </ListGroup>
            </Card>

            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">In-App Purchase</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.inAppPackages, 'inAppPackages')}
              </ListGroup>
            </Card>

            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">S2M Token</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.tokenPackages, 'token')}
              </ListGroup>
            </Card>
          </React.Fragment> :

          <React.Fragment>
            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">Credit/Debit Card</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.creditCardSilverPackages, 'creditCardSilverPackages')}
              </ListGroup>
            </Card>

            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">Promptpay</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.promptPaySilverPackages, 'promptPaySilverPackages')}
              </ListGroup>
            </Card>

            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">Transfer</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.transferSilverPackages, 'transferSilverPackages')}
              </ListGroup>
            </Card>

            <Card small className="mb-4 mt-3">
              <CardHeader>
                <h6 className="m-0">In-App Purchase</h6>
              </CardHeader>
              <ListGroup flush>
                {this.renderPacakages(this.state.inAppSilverPackages, 'inAppSilverPackages')}
              </ListGroup>
            </Card>
          </React.Fragment>
        }
      </Container>
    )
  }
}

const mapStateToProps = ({ appReducer, packageReducer }) => ({
  appReducer,
  packageReducer
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Skeleton } from 'antd';

class BookMessageItemLoading extends Component {
  render() {
    const { mainCharacter } = this.props
    const mainClass = mainCharacter ? 'item-reverse' : ''
    const alignRightClass = mainCharacter ? 'text-right' : ''
    
    return <>
      <div 
        className={`chat-message-item ${mainClass}`}
      >
        <div className="message-avatar">
          <Skeleton.Avatar active={true} shape="circle" />
        </div>
        
        <div>
          <Skeleton.Button active={true} size="small" shape="round" className={`mt-1 ${alignRightClass}`} />
          <Skeleton.Button active={true} size="large" shape="default" className={`mt-1 ${alignRightClass}`} />
        </div>
      </div>
    </>
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(BookMessageItemLoading);

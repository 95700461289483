import React, { Component } from "react";
import * as actions from "../../../actions/book_chapter.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormCheckbox,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";
import { Formik } from "formik";

class ReportChapterHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book_id: "",
      chapters: [],
      currentPage: 0,
      currentBookPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
    };
  }

  async componentDidMount() {
    if (this.props.location.page)
      await this.setState({ currentPage: this.props.location.page });

    if (this.props.location.bookPage)
      await this.setState({ currentBookPage: this.props.location.bookPage });

    let bookID = this.props.match.params.book_id;
    await this.setState({ book_id: bookID });
    await this.getChapters(this.state.currentPage);
  }

  async deleteChapter(chaptor_id) {
    await this.props.deleteBookChapter(chaptor_id, this.state.book_id);
    setTimeout(() => {
      this.getChapters(this.state.currentPage);
    }, 100);
  }

  async getChapters(page) {
    this.setState({ loading: true });

    await this.props.getChapterByBookID(this.state.book_id, page,this.state.start_date,this.state.end_date);

    const { chapters, total } = this.props.bookChapterReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      chapters,
      totalPages,
      loading: false,
    });
  }

  async changeChapterStatus(chapter) {
    const newStatus = chapter.book_chaptor_status === "A" ? "I" : "A";
    let newChapters = this.state.chapters;

    newChapters.map((newChapter) => {
      if (newChapter.book_chaptor_id === chapter.book_chaptor_id) {
        chapter.book_chaptor_status = newStatus;
      }
    });

    this.setState({ chapters: newChapters });

    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let params = {
      book_chaptor_status: newStatus,
      updated_by: username,
      updated_datetime: dateTime,
    };

    const token = localStorage.getItem("token")
    let response = await httpClient.patch(
      server.BOOK_CHAPTER_URL + "/status/" + `${chapter.book_chaptor_id}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 201 && response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    } else {
      if (newStatus === "A") this.onSendNotification(chapter.book_chaptor_id);
    }
  }

  onSendNotification(chapterId) {
    Swal.fire({
      title: "Push notification",
      text: "ยืนยันการส่ง Push Notification ให้ User ที่ติดตามหนังสือเล่มนี้?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ส่ง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.sendNewChapterNotification(chapterId);
      }
    });
  }

  async sendNewChapterNotification(chapterId) {
    let params = {
      bookId: this.state.book_id,
      bookChapterId: chapterId,
    };

    const token = localStorage.getItem("token")
    let response = await httpClient.post(
      `admin/member/push-notification/new-chapter`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      }
    );
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatStatus(value) {
    if (value) {
      if (value === "A") return "แสดง";
      else return "ซ่อน";
    }
  }

  createTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">ลำดับแสดงผล</th>
            <th className="text-center">บท/ตอน</th>
            <th className="text-center">ชื่อตอน</th>
            <th className="text-center">วันที่เพิ่ม</th>
            <th className="text-center">เพิ่มโดย</th>
            <th className="text-center">วันที่แก้ไข</th>
            <th className="text-center">แก้ไขโดย</th>
            <th className="text-center">วันที่เผยแพร่</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {this.state.chapters.map((chapter, index) => {
            return (
              <tr key={chapter.book_chaptor_id}>
                <td className="text-center align-middle">{chapter.chaptor}</td>
                <td className="text-center align-middle">
                  {chapter.chaptor_title}
                </td>
                <td className="text-center align-middle">
                  {chapter.book_chaptor_name}
                </td>
                <td className="text-center align-middle">
                  {this.formatDateTime(chapter.created_datetime)}
                </td>
                <td className="text-center align-middle">
                  {chapter.created_by}
                </td>
                <td className="text-center align-middle">
                  {this.formatDateTime(chapter.updated_datetime)}
                </td>
                <td className="text-center align-middle">
                  {chapter.updated_by}
                </td>
                <td className="text-center align-middle">
                  {this.formatDateTime(chapter.published_date)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getChapters(page);
  }

  async exportData() {
    await this.getChapters("");

    let report = this.state.chapters.map((item, idx) => {
      return [
        item.chaptor_title,
        item.book_chaptor_name,
        this.formatDateTime(item.created_datetime),
        item.created_by,
        this.formatDateTime(item.updated_datetime),
        item.updated_by,
        this.formatDateTime(item.published_date),
      ];
    });

    report = [
      [
        "บท/ตอน",
        "ชื่อตอน",
        "วันที่เพิ่ม",
        "เพิ่มโดย",
        "วันที่แก้ไข",
        "แก้ไขโดย",
        "วันที่เผยแพร่",
      ],
      ...report,
    ];

    if (report) {
      this.downloadExportData(report);
      this.getChapters(0);
    }
  }

  downloadExportData(report_order) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report_order);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["book_chapter_history"]);
    XLSX.writeFile(workBook, `book_chapter_history.xlsx`);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row className="align-items-center">
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="m-0">ประวัติการเพิ่มตอนหนังสือ</h6>
                  </Col>
                  <Col className="text-right">
                    <Button
                      onClick={() => {
                        this.exportData();
                      }}
                      type="Button"
                      className="btn btn-danger mr-2"
                    >
                      Export
                    </Button>
                    <Button
                      onClick={() => {
                        this.props.history.push({
                          pathname: `/report/history`,
                          page: this.state.currentBookPage,
                        });
                      }}
                      className="btn btn-secondary"
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
{/* 
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    page: "",
                  }}
                  onSubmit={async (values) => {
                    let startDate = null;
                    let endDate = null;
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    this.setState({
                      start_date: startDate,
                      end_date: endDate,
                    });

                    this.getChapters(this.state.currentPage);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col sm="4" className="form-group">
                            <label>วันที่เพิ่มตอน เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>

                          <Col sm="6">
                            <Row>
                              <Col sm="3">
                                <FormGroup>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                      marginLeft: 30,
                                      marginTop: 27,
                                      color: "white",
                                      width: 100,
                                    }}
                                  >
                                    Search
                                  </button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
              </CardBody> */}

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.createTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.chapters.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookChapterReducer }) => ({
  bookChapterReducer,
});

const mapDispatchToProps = {
  ...actions,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportChapterHistory);

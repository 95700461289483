import React, { Component } from "react";
import {
  getStickerItem,
  deleteStickerItem,
} from "../../actions/stickerItem.action";
import { getStickerSet } from "../../actions/stickerSet.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  FormTextarea,
  FormGroup,
  FormSelect,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";
import * as Yup from "yup";
import MenuSticker from "../StickerSet/menuSticker";

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <img
        src={thumb}
        alt={file.name}
        className="img-thumbnail mt-2"
        width="100px"
        height="100px"
      />
    );
  }
}

class StickerItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      StickerSetModalOpen: false,
      sticker_id: "",
      sticker_name: "",
      files: {},
      set_id: "",
      add_update: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    this.props.getStickerItem();
    this.props.getStickerSet();
  }

  toggleModal(row, action) {
    if (action === "edit") {
      let stickerID = "";
      let stickerName = "";
      let stickerImage = "";
      let setID = "";

      if (row.sticker_id) stickerID = row.sticker_id;
      if (row.sticker_name) stickerName = row.sticker_name;
      if (row.sticker_image) stickerImage = row.sticker_image;
      if (row.set_id) setID = row.set_id;

      this.setState({
        StickerSetModalOpen: !this.state.StickerSetModalOpen,
        sticker_id: stickerID,
        sticker_name: stickerName,
        sticker_image: stickerImage,
        set_id: setID,
        add_update: action,
      });
    } else {
      this.setState({
        sticker_id: "",
        sticker_name: "",
        sticker_image: "",
        set_id: "",
        StickerSetModalOpen: !this.state.StickerSetModalOpen,
        add_update: action,
      });
    }
  }

  closeModal() {
    this.setState({
      StickerSetModalOpen: !this.state.StickerSetModalOpen,
    });
  }

  formatStickerImage(cell, row) {
    if (row.sticker_image) {
      return (
        <img
          id="sticker_image"
          name="sticker_image"
          src={row.sticker_image}
          width="50px"
          height="50px"
        />
      );
    }
  }

  formatCreateDateTime(cell, row) {
    if (row.created_datetime) {
      return moment(row.created_datetime)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatAction(cell, row) {
    let disabled = "";
    let username = "";

    if (row.order_status === "A") disabled = true;
    else disabled = false;

    var result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    return (
      <div>
        <button
          type="button"
          className="btn btn-xs btn-success text-white"
          onClick={(e) => this.toggleModal(row, "edit")}
        >
          Edit Sticker
        </button>

        <span style={{ color: "grey" }}> | </span>

        <button
          type="button"
          className="btn btn-xs btn-danger"
          onClick={() => {
            return Swal.fire({
              title: "Are you sure to delete?",
              text: "You won't be able to revert this!",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, cancel!",
            }).then((result) => {
              if (result.value) {
                this.props.deleteStickerItem(row.sticker_id);
              }
            });
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  async addEditSticker(sticker_id, set_id, sticker_name, thumbnail_image) {
    if (set_id) {
      // Call Api For Upload Image
      let img_url = "";
      if (thumbnail_image) {
        var formDataImg = new FormData();
        var imagefile = thumbnail_image;

        formDataImg.append("upload", imagefile);

        let response_img = await httpClient.post(server.IMG_URL, formDataImg, {
          headers: {
            processData: false,
            enctype: "multipart/form-data",
          },
        });

        if (response_img.status === 201 || response_img.status === 200) {
          if (response_img.data.uploaded === 1) {
            img_url = response_img.data.url;
          }
        }
      }

      let result = localStorage.getItem("user_profile");
      let username;
      if (result) username = JSON.parse(result).user_username;

      let dateTime = new Date();
      dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

      let formData = new FormData();
      if (this.state.add_update === "edit") {
        formData.append("sticker_id", sticker_id);
      }
      
      formData.append("set_id", set_id);
      formData.append("sticker_name", sticker_name);

      if (img_url) formData.append("sticker_image", img_url);

      formData.append("dbstatus", "A");
      formData.append("created_datetime", dateTime);
      formData.append("created_by", username);

      formData.append("updated_datetime", dateTime);
      formData.append("updated_by", username);

      let object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      let json = JSON.stringify(object);

      let response = "";
      
      if (this.state.add_update === "add") {
        const token = localStorage.getItem("token");
        response = await httpClient.post(`${server.STICKER_ITEM_URL}`, json, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
      } else if (this.state.add_update === "edit") {
        const token = localStorage.getItem("token");
        response = await httpClient.patch(
          `${server.STICKER_ITEM_URL}` + "/" + `${sticker_id}`,
          json,
          {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
      }

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          StickerSetModalOpen: false,
        });

        this.props.getStickerItem();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณาระบุ Sticker Set",
        timer: 1500,
      });
    }
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.stickerItemReducer;

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="sticker_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Sticker ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="set_id"
                width="60px"
                hidden={true}
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Set ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="set_name"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Sticker Set
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sticker_name"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Sticker Name
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sticker_image"
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatStickerImage.bind(this)}
              >
                Sticker Image
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_datetime"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCreateDateTime}
                csvFormat={this.formatCreateDateTime}
              >
                Create Date
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="updated_by"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Update By
              </TableHeaderColumn>

              <TableHeaderColumn
                width="40px"
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatAction.bind(this)}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>

            <Modal
              open={this.state.StickerSetModalOpen}
              toggle={this.toggleModal}
            >
              <ModalHeader>Add Sticker</ModalHeader>
              <ModalBody>
                <Formik
                  //enableReinitialize
                  initialValues={{
                    sticker_id: this.state.sticker_id,
                    set_id: this.state.set_id,
                    sticker_image: this.state.sticker_image,
                    sticker_name: this.state.sticker_name,
                  }}
                  render={({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    touched,
                  }) => (
                    <React.Fragment>
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Sticker Set</label>
                          {this.bindingStickerSet({
                            values,
                            handleChange,
                          })}
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Sticker Name (Optional)</label>
                          <FormInput
                            name="sticker_name"
                            id="sticker_name"
                            onChange={handleChange}
                            value={values.sticker_name}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col className="form-group">
                          <label>Sticker Image</label>
                          <input
                            id="file"
                            name="file"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "file",
                                event.currentTarget.files[0]
                              );
                            }}
                            className="form-control"
                          />

                          <Row form>
                            {values.sticker_image && (
                              <Col sm="6">
                                <React.Fragment>
                                  <label className="ml-2 text-accent">
                                    (OLD)
                                  </label>
                                  <img
                                    id="old_img"
                                    name="old_img"
                                    src={values.sticker_image}
                                    className="img-thumbnail mt-2"
                                    width="100px"
                                    height="100px"
                                  />
                                </React.Fragment>
                              </Col>
                            )}
                            <Col sm="6">
                              <React.Fragment>
                                {values.file && (
                                  <label className="ml-2 text-accent">
                                    (NEW)
                                  </label>
                                )}
                                <Thumb
                                  id="new_img"
                                  name="new_img"
                                  file={values.file}
                                />
                              </React.Fragment>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <div className="text-right">
                        <button
                          type="button"
                          className="btn btn-primary pull-right"
                          onClick={() => {
                            this.addEditSticker(
                              this.state.sticker_id,
                              values.set_id,
                              values.sticker_name,
                              values.file
                            );
                          }}
                          style={{
                            marginRight: 10,
                            color: "white",
                            width: 100,
                          }}
                        >
                          บันทึก
                        </button>
                        <button
                          onClick={(e) => this.closeModal()}
                          type="Button"
                          className="btn btn-danger pull-right"
                          style={{ color: "white", width: 100 }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                />
              </ModalBody>
            </Modal>
          </div>
        )
      );
    } catch (e) {}
  };

  bindingStickerSet = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.stickerSetReducer;
      const options = result.map((item) => (
        <option key={item.value} value={item.set_id}>
          {item.set_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="set_id"
            id="set_id"
            placeholder=""
            onChange={handleChange}
            value={values.set_id}
          >
            <option value="">Select Sticker Set</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 20 }}>
            <MenuSticker />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Sticker</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={(e) => this.toggleModal(null, "add")}
                      className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Add Sticker
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    set_id: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    if (values.set_id) {
                      value.setID = values.set_id;
                    }

                    this.props.getStickerItem(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col sm="5" className="form-group">
                            <label>Sticker Set</label>
                            {this.bindingStickerSet({
                              values,
                              handleChange,
                            })}
                          </Col>

                          <Col sm="6">
                            <Row>
                              <Col sm="3" className="text-right">
                                <FormGroup>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                      marginLeft: 30,
                                      marginTop: 27,
                                      color: "white",
                                      width: 100,
                                    }}
                                  >
                                    Search
                                  </button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ stickerItemReducer, stickerSetReducer }) => ({
  stickerItemReducer,
  stickerSetReducer,
});

const mapDispatchToProps = {
  getStickerItem,
  deleteStickerItem,
  getStickerSet,
};

export default connect(mapStateToProps, mapDispatchToProps)(StickerItem);

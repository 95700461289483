import { httpClient } from "../utils/HttpClient";
import { server } from "../constants";

import {
  HTTP_STICKER_SET_FETCHING,
  HTTP_STICKER_SET_SUCCESS,
  HTTP_STICKER_SET_FAILED,
} from "../constants/sticker";

const setStateStickerSetToSuccess = (payload) => ({
  type: HTTP_STICKER_SET_SUCCESS,
  payload: payload,
});

const setStateStickerSetToFetching = () => ({
  type: HTTP_STICKER_SET_FETCHING,
});

const setStateStickerSetToFailed = () => ({
  type: HTTP_STICKER_SET_FAILED,
});

/* Get Sticker Set */
export const getStickerSet = () => {
  return async (dispatch) => {
    dispatch(setStateStickerSetToFetching());
    await doGetSetStickerSet(dispatch);
  };
};

const doGetSetStickerSet = (dispatch) => {
  const token = localStorage.getItem("token");
  httpClient
    .get(`${server.STICKER_SET_URL}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateStickerSetToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateStickerSetToFailed());
    });
};

export const getStickerSetByID = (set_id) => {
  return async (dispatch) => {
    dispatch(setStateStickerSetToFetching());

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `${server.STICKER_SET_URL}/id/${set_id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let stickerSetResult = result.data;

      dispatch(setStateStickerSetToSuccess(stickerSetResult));
    } catch (err) {
      dispatch(setStateStickerSetToFailed());
    }
  };
};

export const deleteStickerSet = (set_id) => {
  return async (dispatch) => {
    dispatch(setStateStickerSetToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      `${server.STICKER_SET_URL}/${set_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result){
      let url = `${server.STICKER_SET_URL}/item/${set_id}`; //ลบใน sticker_item ทั้งหมดด้วย
      const result = await httpClient.delete(url,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        alert("Delete Complete");
        await doGetSetStickerSet(dispatch);
      }
    }
  };
};


import { httpClient } from "../utils/HttpClient";
import {server} from "../constants"

import {
    HTTP_SUB_CATEGORY_FETCHING,
    HTTP_SUB_CATEGORY_SUCCESS,
    HTTP_SUB_CATEGORY_FAILED,
} from "../constants/sub_category";

const setStateSubCategoryToSuccess = payload => ({
  type: HTTP_SUB_CATEGORY_SUCCESS,
  payload: payload
});

const setStateSubCategoryToFetching = () => ({
  type: HTTP_SUB_CATEGORY_FETCHING
});

const setStateSubCategoryToFailed = () => ({
  type: HTTP_SUB_CATEGORY_FAILED
});

/* Get Sub Category By Category ID */
export const getSubCategory = (category_id) => {
  return async (dispatch) => {
    dispatch(setStateSubCategoryToFetching());
    await doGetSubCategory(dispatch,category_id);
  };
};

const doGetSubCategory = (dispatch,category_id) => {
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.SUB_CATEGORY_URL}/${category_id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateSubCategoryToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateSubCategoryToFailed());
    });
};

export const getAllSubCategory = () => {
  return async (dispatch) => {
    dispatch(setStateSubCategoryToFetching());
    await doGetAllSubCategory(dispatch);
  };
};

const doGetAllSubCategory = (dispatch) => {
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.SUB_CATEGORY_URL}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateSubCategoryToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateSubCategoryToFailed());
    });
};

export const getSubCategoryByID = (sub_category_id) => {
  return async (dispatch) => {
    dispatch(setStateSubCategoryToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.SUB_CATEGORY_URL}/id/${sub_category_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      dispatch(setStateSubCategoryToSuccess(result.data));
    } catch(err)  {
      dispatch(setStateSubCategoryToFailed());
    }
  };
};

export const deleteSubCategory = (sub_category_id,category_id) => {
  return async (dispatch) => {
    dispatch(setStateSubCategoryToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      server.SUB_CATEGORY_URL + "/" + `${sub_category_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(result)
    {
      alert('Delete Complete')
      await doGetSubCategory(dispatch,category_id);
    }
  };
};



import { httpClient } from "../utils/HttpClient";

import {
  HTTP_REPORT_INVITE_SUCCESS,
  HTTP_REPORT_INVITE_FETCHING,
  HTTP_REPORT_INVITE_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateReportInviteToSuccess = (payload) => ({
  type: HTTP_REPORT_INVITE_SUCCESS,
  payload: payload,
});

const setStateReportInviteToFetching = () => ({
  type: HTTP_REPORT_INVITE_FETCHING,
});

const setStateReportInviteToFailed = () => ({
  type: HTTP_REPORT_INVITE_FAILED,
});

export const getInviteReport = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportInviteToFetching());
    await doGetInviteReport(dispatch,value);
  };
};

const doGetInviteReport = async (dispatch, value) => {
  try {
    let params = "";

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00:00";
        else value.start_date = "";

        if (value.end_date) value.end_date = value.end_date + " 23:59:59";
        else
          value.end_date = moment(new Date())
            .format("YYYY-MM-DD 23:59:59");

          params = value
    } else {
      params = {
        page: ""
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.REPORT_URL}/invite`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });
    dispatch(setStateReportInviteToSuccess(result.data));

  } catch (err) {
    dispatch(setStateReportInviteToFailed());
  }
};

import { httpClient } from "../utils/HttpClient";

import {
  HTTP_BOOK_CHARACTER_FETCHING,
  HTTP_BOOK_CHARACTER_SUCCESS,
  HTTP_BOOK_CHARACTER_FAILED,
  HTTP_BOOK_CHARACTER_ADDED,
  HTTP_BOOK_CHARACTER_UPDATED,
  HTTP_BOOK_CHARACTER_DELETED,
  HTTP_BOOK_CHARACTER_MAIN_UPDATED
} from "../constants/book";

import { server } from "../constants";

const setStateBookCharacterToSuccess = payload => ({
  type: HTTP_BOOK_CHARACTER_SUCCESS,
  payload: payload
});

const setStateBookCharacterToFetching = () => ({
  type: HTTP_BOOK_CHARACTER_FETCHING
});

const setStateBookCharacterToFail = () => ({
  type: HTTP_BOOK_CHARACTER_FAILED
});

const setStateBookCharacterToAdd = payload => ({
  type: HTTP_BOOK_CHARACTER_ADDED,
  payload: payload
});

const setStateBookCharacterToUpdate = payload => ({
  type: HTTP_BOOK_CHARACTER_UPDATED,
  payload: payload
});

const setStateBookCharacterToDelete = payload => ({
  type: HTTP_BOOK_CHARACTER_DELETED,
  payload: payload
});

export const getBookCharacters = (id) => {
  return async dispatch => {
    dispatch(setStateBookCharacterToFetching());

    try {
      const token = localStorage.getItem("token");
  
      const result = await httpClient.get(
        `admin/book/${id}/character`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookCharacterToSuccess(result.data));
    } catch (err) {
      dispatch(setStateBookCharacterToFail());
    }
  };
};

export const getChapterCharacters = (id, chapterId) => {
  return async dispatch => {
    dispatch(setStateBookCharacterToFetching());

    try {
      const token = localStorage.getItem("token");
  
      const result = await httpClient.get(
        `admin/book/${id}/chapter/${chapterId}/character`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookCharacterToSuccess(result.data));
    } catch (err) {
      dispatch(setStateBookCharacterToFail());
    }
  };
};

export const addBookCharacter = (character) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem("token");
      
      const result = await httpClient.post(
        `admin/book/character`, 
        character,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookCharacterToAdd(result.data));
    } catch (err) {
      dispatch(setStateBookCharacterToFail());
    }
  };
};

export const updateBookCharacter = (id, character) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem("token");
      
      const result = await httpClient.put(
        `admin/book/character/${id}`, 
        character,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookCharacterToUpdate(result.data));
    } catch (err) {
      dispatch(setStateBookCharacterToFail());
    }
  };
};

export const deleteBookCharacter = (id) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem("token");
      
      const result = await httpClient.delete(
        `admin/book/character/${id}`,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookCharacterToDelete(id));
    } catch (err) {
      dispatch(setStateBookCharacterToFail());
    }
  };
};
  
// Login Page
export const APP_INIT = "APP_INIT";

// Login Page
export const HTTP_LOGIN_FETCHING = "HTTP_LOGIN_FETCHING";
export const HTTP_LOGIN_SUCCESS = "HTTP_LOGIN_SUCCESS";
export const HTTP_LOGIN_FAILED = "HTTP_LOGIN_FAILED";

export const HTTP_PROFILE_FETCHING = "HTTP_PROFILE_FETCHING";
export const HTTP_PROFILE_SUCCESS = "HTTP_PROFILE_SUCCESS";
export const HTTP_PROFILE_FAILED = "HTTP_PROFILE_FAILED";


// Silver Campaign Page
export const HTTP_CAMPAIGNS_FETCHING = "HTTP_CAMPAIGNS_FETCHING";
export const HTTP_CAMPAIGNS_SUCCESS = "HTTP_CAMPAIGNS_SUCCESS";
export const HTTP_CAMPAIGNS_FAILED = "HTTP_CAMPAIGNS_FAILED";

export const HTTP_CAMPAIGN_FETCHING = "HTTP_CAMPAIGN_FETCHING";
export const HTTP_CAMPAIGN_SUCCESS = "HTTP_CAMPAIGN_SUCCESS";
export const HTTP_CAMPAIGN_FAILED = "HTTP_CAMPAIGN_FAILED";

// Redeem Code Page
export const HTTP_REDEEM_LIST_SUCCESS = "HTTP_REDEEM_LIST_SUCCESS";
export const HTTP_REDEEM_LIST_FETCHING = "HTTP_REDEEM_LIST_FETCHING";
export const HTTP_REDEEM_LIST_FAILED = "HTTP_REDEEM_LIST_FAILED";
export const HTTP_REDEEM_GROUP_SUCCESS = "HTTP_REDEEM_GROUP_SUCCESS";
export const HTTP_REDEEM_GROUP_FETCHING = "HTTP_REDEEM_GROUP_FETCHING";
export const HTTP_REDEEM_GROUP_FAILED = "HTTP_REDEEM_GROUP_FAILED";
export const HTTP_REDEEM_ITEM_LIST_SUCCESS = "HTTP_REDEEM_ITEM_LIST_SUCCESS";
export const HTTP_REDEEM_ITEM_LIST_FETCHING = "HTTP_REDEEM_ITEM_LIST_FETCHING";
export const HTTP_REDEEM_ITEM_LIST_FAILED = "HTTP_REDEEM_ITEM_LIST_FAILED";
export const HTTP_REDEEM_SEARCH_SUCCESS = "HTTP_REDEEM_SEARCH_SUCCESS";
export const HTTP_REDEEM_SEARCH_FETCHING = "HTTP_REDEEM_SEARCH_FETCHING";
export const HTTP_REDEEM_SEARCH_FAILED = "HTTP_REDEEM_SEARCH_FAILED";


// Report Purchase Page
export const HTTP_REPORT_PURCHASE_FETCHING = "HTTP_PURCHASE_FETCHING";
export const HTTP_REPORT_PURCHASE_SUCCESS = "HTTP_PURCHASE_SUCCESS";
export const HTTP_REPORT_PURCHASE_FAILED = "HTTP_PURCHASE_FAILED";

// Report Coin Sale By User Page
export const HTTP_REPORT_COIN_FETCHING = "HTTP_REPORT_COIN_FETCHING";
export const HTTP_REPORT_COIN_SUCCESS = "HTTP_REPORT_COIN_SUCCESS";
export const HTTP_REPORT_COIN_FAILED = "HTTP_REPORT_COIN_FAILED";

// Report Sale By 2Read Story 
export const HTTP_REPORT_STORY_FETCHING = "HTTP_REPORT_STORY_FETCHING";
export const HTTP_REPORT_STORY_SUCCESS = "HTTP_REPORT_STORY_SUCCESS";
export const HTTP_REPORT_STORY_FAILED = "HTTP_REPORT_STORY_FAILED";

// Report Sale By Chapter
export const HTTP_REPORT_CHAPTER_FETCHING = "HTTP_REPORT_CHAPTER_FETCHING";
export const HTTP_REPORT_CHAPTER_SUCCESS = "HTTP_REPORT_CHAPTER_SUCCESS";
export const HTTP_REPORT_CHAPTER_FAILED = "HTTP_REPORT_CHAPTER_FAILED";

// Report User Coin Spending
export const HTTP_REPORT_COINSPEND_FETCHING = "HTTP_REPORT_COINSPEND_FETCHING";
export const HTTP_REPORT_COINSPEND_SUCCESS = "HTTP_REPORT_COINSPEND_SUCCESS";
export const HTTP_REPORT_COINSPEND_FAILED = "HTTP_REPORT_COINSPEND_FAILED";

// Report Coin Remain
export const HTTP_REPORT_COINREMAIN_FETCHING = "HTTP_REPORT_COINREMAIN_FETCHING";
export const HTTP_REPORT_COINREMAIN_SUCCESS = "HTTP_REPORT_COINREMAIN_SUCCESS";
export const HTTP_REPORT_COINREMAIN_FAILED = "HTTP_REPORT_COINREMAIN_FAILED";

// Error Code
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";

//////////////// Localization Begin ////////////////
export const NETWORK_CONNECTION_MESSAGE =
  "Cannot connect to server, Please try again.";
export const NETWORK_TIMEOUT_MESSAGE =
  "A network timeout has occurred, Please try again.";

export const YES = "YES";
export const NO = "NO";

export const server = {
  USER_URL: `admin/user`,
  PROFILE_URL: `admin`,
  MEMBER_URL: `admin/member`,
  WRITER_URL: `admin/writer`,
  REPORT_URL: `admin/report`,
  LOGIN_URL: `admin/signin`,
  CAMPAIGN_URL: `admin/campaign`,
  REDEEM_URL: `admin/redeem`,
  LOGIN_PASSED: `yes`,
  SUMMARY_SILVER_URL: `admin/report/summary_silver`,
  SILVER_TRANS_URL: `admin/report/silver_coin_trans`,
  SUMMARY_SILVER_TRANS_URL: `admin/report/silver_coin_summary`,
  GET_CAMPAIGN_URL: `admin/report/get_campaign_name`,
  WRITER_NAME_URL: `admin/writer_name`,
  ORDER_URL: `admin/order`,
  IMG_URL: `https://api.2read.digital/api/image/upload`,
  CAMPAIGN_TRANS: `admin/get_campaign_trans`,
  CATEGORY_URL: `admin/category`,
  COMMENT_URL: `admin/comment`,
  PLAYLIST_URL: `admin/playlist`,
  PLAYLIST_ITEM_URL: `admin/playlist_item`,
  BOOKS_ITEM_URL: `admin/book_item`,
  STICKER_SET_URL: `admin/sticker_set`,
  STICKER_ITEM_URL: `admin/sticker_item`,
  BANNER_URL: `admin/banner`,
  SUB_CATEGORY_URL: `admin/sub_category`,
  BOOK_URL:`admin/book`,
  BOOK_WRITER_URL:`admin/book_writer`,
  BOOK_CATEGORY_URL:`admin/book_category`,
  BOOK_CHAPTER_URL:`admin/book_chapter`,
  WRITER_RECOMMEND_URL:`admin/writer_recommend`,
  EDITOR_URL:`admin/editor`,
  PUBLISHER_URL:`admin/publisher`,
  BOOK_LABEL_URL:`admin/book_label`,
  BOOK_MESSAGE_URL:`admin/book/message`,
  CHAPTER_AUDIO_URL:`admin/chapter_audio`,
};

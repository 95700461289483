import React, { Component } from "react";
import { getBookComment,changeStatus } from "../../../actions/comment.action";
import { getCategory } from "../../../actions/category.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
  FormSelect,
  FormCheckbox,
  Badge,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import MenuComment from "../menuComment";

class CommentBook extends Component {
  async componentDidMount() {
    this.props.getBookComment();
    this.props.getCategory();
  }

  formatDisplayComment(cell, row) {
    if (row.dbstatus) {
      if (row.dbstatus === "A")
        return (
          <Badge theme="success" className="font-12">
            Yes
          </Badge>
        );
      else
        return (
          <Badge theme="danger" className="font-12">
            No
          </Badge>
        );
    }
  }

  formatCommentRating(cell, row) {
    if (row.comment_rating) {
      switch (row.comment_rating) {
        case 5:
          return (
            <div>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
            </div>
          );
        case 4:
          return (
            <div>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
            </div>
          );
        case 3:
          return (
            <div>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
            </div>
          );
        case 2:
          return (
            <div>
              <i className="material-icons text-warning">grade</i>
              <i className="material-icons text-warning">grade</i>
            </div>
          );
        case 1:
          return (
            <div>
              <i className="material-icons text-warning">grade</i>
            </div>
          );
      }
    }
  }

  bindingCategoryName = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.categoryReducer;
      const options = result.map((item) => (
        <option key={item.value} value={item.category_name}>
          {item.category_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="category_name"
            id="category_name"
            placeholder=""
            onChange={handleChange}
            value={values.category_name}
          >
            <option value="">Select Category</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  formatAction(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-xs btn-warning font-12"
          onClick={() => {
            return Swal.fire({
              title: "Are you sure to change?",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
            }).then((result) => {
              if (result.value) {
                this.props.changeStatus(row,'book');
              }
            });
          }}
        >
          Change
        </button>
      </div>
    );
  }

  formatCommentDateTime(cell, row) {
    if (row.comment_datetime) {
      return moment(row.comment_datetime)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.commentReducer;

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="comment_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Comment ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="book_title"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                ชื่อหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="category_name"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                ประเภทหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="creator_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                ชื่อผู้แต่ง
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Member ที่ Comment
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="comment_content"
                width="120px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                เนื้อหาที่ Comment
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="comment_rating"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCommentRating}
              >
                Rating
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="comment_datetime"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCommentDateTime}
              >
                วันที่ Comment
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="dbstatus"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatDisplayComment}
              >
                แสดง Comment
              </TableHeaderColumn>

              <TableHeaderColumn
                width="100px"
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatAction.bind(this)}
              >
                Change Status
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuComment/>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Book Comment</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    category_name: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;

                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    value.start = startDate;
                    value.end = endDate;

                    if (values.category_name) {
                      value.categoryName = values.category_name;
                    }

                    this.props.getBookComment(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col sm="5" className="form-group">
                            <label>วันที่ Comment เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>

                          <Col sm="6">
                            <Row>
                              <Col sm="6">
                                <FormGroup>
                                  <label>Category</label>

                                  {this.bindingCategoryName({
                                    values,
                                    handleChange,
                                  })}
                                </FormGroup>
                              </Col>
                              <Col sm="3" className="text-right">
                                <FormGroup>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                      marginLeft: 30,
                                      marginTop: 27,
                                      color: "white",
                                      width: 100,
                                    }}
                                  >
                                    Search
                                  </button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ commentReducer, categoryReducer }) => ({
  commentReducer,
  categoryReducer,
});

const mapDispatchToProps = {
  getBookComment,
  changeStatus,
  getCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentBook);

import { httpClient } from "../utils/HttpClient";
import { server } from "../constants";

import {
  HTTP_PUBLISHER_SUCCESS,
  HTTP_PUBLISHER_FETCHING,
  HTTP_PUBLISHER_FAILED
} from "../constants/publisher";

const setStatePublisherToSuccess = payload => ({
  type: HTTP_PUBLISHER_SUCCESS,
  payload: payload
});

const setStatePublisherToFetching = () => ({
  type: HTTP_PUBLISHER_FETCHING
});

const setStatePublisherToFailed = () => ({
  type: HTTP_PUBLISHER_FAILED
});

export const getWriterByPublisherID = value => {
  return async dispatch => {
    dispatch(setStatePublisherToFetching());
    await doGetWriterByPublisherID(dispatch, value);
  };
};

const doGetWriterByPublisherID = async (dispatch, value) => {
  try {
    let param = {};

    if (value) {
      param = {
        publisher_id: value.publisher_id
      };
    } else {
      param = {
        page: ""
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.PUBLISHER_URL}/writer`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param
    });
    dispatch(setStatePublisherToSuccess(result.data));
  } catch (err) {
    dispatch(setStatePublisherToFailed());
  }
};

export const deleteWriter = value => {
  return async dispatch => {
    dispatch(setStatePublisherToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      `${server.PUBLISHER_URL}/${value.id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result) {
      alert("Delete Complete");
      await getWriterByPublisherID(dispatch, value);
    }
  };
};

import {
    HTTP_REPORT_PUBLISHER_SUCCESS,
    HTTP_REPORT_PUBLISHER_FETCHING,
    HTTP_REPORT_PUBLISHER_FAILED,
  } from "../constants/report";
  
  const initialState = {
    publishers: null,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_REPORT_PUBLISHER_SUCCESS:
        return {
          ...state,
          publishers: payload.publishers,
          total: payload.total,
          isFetching: false,
          isError: false,
        };
      case HTTP_REPORT_PUBLISHER_FAILED:
        return { ...state, publishers: null, isFetching: false, isError: true };
      case HTTP_REPORT_PUBLISHER_FETCHING:
        return { ...state, publishers: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
  
import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormTextarea,
  FormCheckbox
} from "shards-react";

import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";

export default class CategoryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img_src_web: "",
      img_src_mobile: "",
    };
    this.uploadImage = this.uploadImage.bind(this);
  }

  async uploadImage(image, type) {
    // Call Api For Upload Image
    if (image) {
      var formDataImg = new FormData();
      var imagefile = image;
      formDataImg.append("upload", imagefile);

      let response_img = await httpClient.post(server.IMG_URL, formDataImg, {
        headers: {
          processData: false,
          enctype: "multipart/form-data",
        },
      });
      
      if (response_img.status === 201 || response_img.status === 200) {
        if (response_img.data.uploaded === 1) {
          if (type === "web") {
            this.setState({
              img_src_web: response_img.data.url,
            });

            this.props.change(response_img.data.url, "web");
          }
          if (type === "mobile") {
            this.setState({
              img_src_mobile: response_img.data.url,
            });
            this.props.change(response_img.data.url, "mobile");
          }
        }
      }
    }
  }

  render() {
    const {
      values,
      handleChange,
      setFieldValue,
      errors,
      touched
    } = this.props;


    const ImageWeb = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="category_image"
            name="category_image"
            src={value}
            alt=""
          />
        );
      } else {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="category_image"
            name="category_image"
            src={this.state.img_src_web}
            alt=""
          />
        );
      }
    };

    const ImageMobile = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="category_image_mobile"
            name="category_image_mobile"
            src={value}
            alt=""
          />
        );
      } else {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="category_image_mobile"
            name="category_image_mobile"
            src={this.state.img_src_mobile}
            alt=""
          />
        );
      }
    };

    return (
      <React.Fragment>
        <fieldset>
          <Row form>
            <Col md="6" className="form-group">
              <label>Category Name</label>
              <FormInput
                name="category_name"
                id="category_name"
                placeholder=""
                onChange={handleChange}
                value={values.category_name}
              />
              {errors.category_name && touched.category_name ? (
                <div className="font-12 text-primary mt-1">
                  {errors.category_name}
                </div>
              ) : null}
            </Col>
          </Row>

          <Row form>
            <Col md="12" className="form-group">
              <label>รายละเอียด Banner</label>
              <FormTextarea
                rows="2"
                name="category_desc"
                id="category_desc"
                onChange={handleChange}
                value={values.category_desc}
              />
            </Col>
          </Row>

          <Row form>
            <Col className="form-group">
              <label>
                Category Image{" "}
                <span className=" text-danger">
                  {" "}
                  (ขนาดรูปที่แนะนำ 1920 x 400 px)
                </span>
              </label>
              <input
                id="img_web"
                name="img_web"
                type="file"
                onChange={(event) => {
                  values.category_image = "";
                  setFieldValue("img_web", event.currentTarget.files[0]);
                  this.uploadImage(event.currentTarget.files[0], "web");
                }}
                className="form-control"
              />
              <div sm="10" className="ml-2 mt-3 text-center">
                <ImageWeb value={values.category_image} />
              </div>
            </Col>
          </Row>

          <Row form>
            <Col className="form-group mb-0">
              <label>
                Category Image Mobile{" "}
                <span className=" text-danger">
                  {" "}
                  (ขนาดรูปที่แนะนำ 710 x 500 px)
                </span>
              </label>
              <input
                id="img_mobile"
                name="img_mobile"
                type="file"
                onChange={(event) => {
                  values.category_image_mobile = "";
                  setFieldValue("img_mobile", event.currentTarget.files[0]);
                  this.uploadImage(event.currentTarget.files[0], "mobile");
                }}
                className="form-control"
              />
              <div sm="10" className="ml-2 mt-3 text-center">
                <ImageMobile value={values.category_image_mobile} />
              </div>
            </Col>
          </Row>

          <div className="mb-5">
            <FormCheckbox
              toggle
              inline
              checked={values.ugc_category === "Y" ? true : false}
              onChange={(e) => {
                setFieldValue("ugc_category", values.ugc_category === 'Y' ? 'N' : 'Y');
              }}
              className="mr-0 ml-2"
            >
              หมวดหมู่สำหรับ UGC
            </FormCheckbox>
          </div>
        </fieldset>
      </React.Fragment>
    );
  }
}

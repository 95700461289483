import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import * as actions from "../../../actions/member.action";
import { connect } from "react-redux";
import { httpClient } from "../../../utils/HttpClient";
import Swal from "sweetalert2";
import MemberFrom from "../../Member/memberForm";
import moment from "moment";
import * as Yup from 'yup';

const memberValidationSchema = Yup.object().shape({
  member_name: Yup.string().required('กรุณากรอก Name'),
  gold_coin_earning_percentage: Yup.number().required('กรุณากรอกสัดส่วนรายได้เหรียญทอง'),
  silver_coin_earning_percentage: Yup.number().required('กรุณากรอกสัดส่วนรายได้เหรียญเงิน')     
});

class CreateWriter extends Component {
  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched
  }) => {
    return (
      <div style={{ padding: 20 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD WRITER</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <MemberFrom
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isDisableField={false}
                      isDiableGoldCoin={true}
                      isDiableSilverCoin={true}
                      laberImg={"Writer Image"}
                      errors={errors}
                      touched={touched}
                    ></MemberFrom>

                    <div className="text-right mt-4">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary mr-3"
                        style={{ width: 100 }}
                      >
                        Add
                      </button>
                      <button
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-secondary text-white"
                        style={{ width: 100 }}
                      >
                        Back
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            member_username: "",
            member_password: "",
            member_firstname: "",
            member_lastname: "",
            member_name: "",
            member_email: "",
            member_phone: "",
            member_coin: "0",
            member_silver_coin: "0",
            member_type: 2,
            gold_coin_earning_percentage: 50,
            silver_coin_earning_percentage: 10
          }}
          validationSchema={memberValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            //Call API For Upload Image
            let img_url = "";
            if (values.file) {
              var formDataImg = new FormData();
              var imagefile = values.file;

              formDataImg.append("upload", imagefile);

              let response_img = await httpClient.post(
                server.IMG_URL,
                formDataImg,
                {
                  headers: {
                    processData: false,
                    enctype: "multipart/form-data",
                  },
                }
              );

              if (response_img.status === 201 || response_img.status === 200) {
                if (response_img.data.uploaded === 1) {
                  img_url = response_img.data.url;
                }
              }
            }

            let formData = new FormData();
            if (values.member_username)
              formData.append("member_username", values.member_username);
            if (values.member_password)
              formData.append("member_password", values.member_password);
            if (values.member_name)
              formData.append("member_name", values.member_name);
            if (values.member_phone)
              formData.append("member_phone", values.member_phone);
            if (values.member_email)
              formData.append("member_email", values.member_email);
            if (values.member_history)
              formData.append("member_history", values.member_history);
            if (img_url) formData.append("member_image", img_url);
            formData.append("member_status", "A");
            formData.append("total_follower", 0);
            formData.append("dbstatus", "A");
            formData.append("member_type", 2); //Writer
            formData.append("member_coin", 0);
            formData.append("member_silver_coin", 0);
            formData.append("gold_coin_earning_percentage", values.gold_coin_earning_percentage);
            formData.append("silver_coin_earning_percentage", values.silver_coin_earning_percentage);
            
            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;

            let dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

            formData.append("created_by", username);
            formData.append("created_datetime", dateTime);

            formData.append("updated_by", username);
            formData.append("updated_datetime", dateTime);
            

            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            const token = localStorage.getItem("token")
            let response = await httpClient.post(server.MEMBER_URL, json, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              },
            });

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Add Complete",
                showConfirmButton: false,
                timer: 1500,
              });
              setSubmitting(false);
              this.props.history.push(`/writer`)
              // this.props.getAllMember(2); 
              // this.props.history.goBack();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ memberReducer }) => ({ memberReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateWriter);

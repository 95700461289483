import React, { Component } from "react";
import { getReportPublishBook } from "../../../actions/reportPublishBook.action";
import { getAllMember } from "../../../actions/member.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  InputGroupText,
  DatePicker,
  FormSelect,
  Badge,
  FormInput,
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";
import MenuReport from "./../reportPublisher/menu/menuReportPublisher";

class ReportPublishBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publishers: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 5,
      loading: false,
      start_date: "",
      end_date: "",
      publisher_id: "",
      member_type: "",
      writer_name: "",
    };
  }

  async componentDidMount() {
    await this.getReportPublishBook(this.state.currentPage);
    await this.getPublisherList(4);

    const { publishers } = this.props.reportPublishBookReducer;
  }

  async getPublisherList(type) {
    await this.props.getAllMember(type, "", "", "");
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  bindingPublisherName = ({ handleChange, values }) => {
    try {
      const { members, isFetching } = this.props.memberReducer;

      const options = members.map((item) => (
        <option key={item.value} value={item.member_id}>
          {item.member_name}
        </option>
      ));

      return (
        <FormSelect
          name="publisher_id"
          id="publisher_id"
          placeholder=""
          onChange={handleChange}
          value={values.publisher_id}
          disabled={isFetching}
        >
          <option value="">Select Publisher</option>
          {options}
        </FormSelect>
      );
    } catch (e) {}
  };

  async getReportPublishBook(page) {
    this.setState({ loading: true });

    const value = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      // publisher_id: this.state.publisher_id,
      writer_name: this.state.writer_name,
      member_type: this.state.member_type,
      page: page,
    };

    await this.props.getReportPublishBook(value);

    const { publishers, total } = this.props.reportPublishBookReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.reportPublishBookReducer.publishers) {
      this.setState({
        publishers,
        totalPages,
      });
    }
    this.setState({ loading: false });
  }

  formatCoin(item) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(item);
  }

  renderTable = () => {
    return (
      <React.Fragment>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">ชื่อสำนักพิมพ์/บรรณาธิการ</th>
              <th className="text-center">ชื่อนักเขียน</th>
              <th className="text-center">ชื่อหนังสือ</th>
              <th className="text-center">วันที่ลงหนังสือ</th>
              <th className="text-center">สถานะ</th>
              <th className="text-center">Update By</th>
              <th className="text-center">ประวัติถอนเงิน</th>
            </tr>
          </thead>
          <tbody>
            {this.state.publishers.map((item, index) => {
              return (
                <tr
                  key={item.publisher_id}
                  className={`${
                    item.type === "publisher" ? "bg-primary text-white" : ""
                  }`}
                >
                  <td className="align-middle">
                    <div
                      className={`${
                        item.type === "publisher" ? "font-weight-bold" : ""
                      }`}
                    >
                      {item.publisherName}
                    </div>
                  </td>
                  <td className="align-middle">{item.writerName}</td>
                  <td className="align-middle">{item.bookName}</td>
                  <td className="text-center align-middle">
                    {this.formatDateTime(item.createDate)}
                  </td>

                  <td className="align-middle text-center">
                    {item.status && (
                      <div>{item.status === "A" ? "เผยแพร่" : "ซ่อน"}</div>
                    )}
                  </td>
                  <td className="text-center align-middle">{item.updatedBy}</td>
                  <td className="text-center align-middle">
                    {item.type === "publisher" && (
                      <div className="mt-1">
                        <a
                          type="button"
                          className="btn btn-xs btn-warning"
                          href={"/withdraws/transaction/" + item.publisherId}
                        >
                          ประวัติถอนเงิน
                        </a>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getReportPublishBook(page);
  }

  async exportData() {
    await this.getReportPublishBook("");

    let report = this.state.publishers.map((item, idx) => {
      return [
        item.publisherName,
        item.writerName,
        item.bookName,
        this.formatDateTime(item.createDate),
      ];
    });

    report = [
      ["ชื่อสำนักพิมพ์", "ชื่อนักเขียน", "ชื่อหนังสือ", "วันที่ลงหนังสือ"],
      ...report,
    ];

    if (report) {
      this.downloadExportData(report);
    }
  }

  downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["report-publish-book"]);
    XLSX.writeFile(workBook, `report-publish-book.xlsx`);

    this.getReportPublishBook(0);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <Container fluid className="main-content-container px-4">
        <div style={{ padding: 10 }}>
          <MenuReport />
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">
                    ประวัติการลงหนังสือ/ประวัติการถอนเงิน
                  </h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.exportData();
                    }}
                    type="button"
                    className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Export
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ padding: "1rem" }}>
              <Formik
                initialValues={{
                  start_date: "",
                  end_date: "",
                  publisher_id: "",
                  member_type: "",
                  page: "",
                }}
                onSubmit={async (values) => {
                  let startDate = null;
                  let endDate = null;

                  if (values.start_date) {
                    startDate = moment(values.start_date).format("YYYY-MM-DD");
                  }

                  if (values.end_date) {
                    endDate = moment(values.end_date).format("YYYY-MM-DD");
                  }

                  this.setState({
                    start_date: startDate,
                    end_date: endDate,
                    writer_name: values.writer_name,
                    member_type: values.member_type,
                  });

                  this.getReportPublishBook(this.state.currentPage);
                }}
              >
                {(props) => {
                  const {
                    values,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    handleChange,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row form>
                        <Col sm="4" className="form-group">
                          <label>วันที่ลงหนังสือเริ่มต้น/สิ้นสุด</label>
                          <InputGroup
                            className={"d-flex my-auto date-range date-w-100"}
                          >
                            <DatePickerField
                              size="sm"
                              name="start_date"
                              onChange={setFieldValue}
                              value={values.start_date}
                              placeholder="Start Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <DatePickerField
                              size="sm"
                              name="end_date"
                              onChange={setFieldValue}
                              value={values.end_date}
                              placeholder="End Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <i className="material-icons">&#xE916;</i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label>ประเภท</label>

                            <FormSelect
                              name="member_type"
                              id="member_type"
                              placeholder=""
                              onChange={handleChange}
                              value={values.member_type}
                            >
                              <option value="">เลือกประเภท</option>
                              <option value={3}>บรรณาธิการ</option>
                              <option value={4}>สำนักพิมพ์</option>
                            </FormSelect>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label>ชื่อสำนักพิมพ์/บรรณาธิการ</label>
                            <FormInput
                              name="writer_name"
                              id="writer_name"
                              placeholder=""
                              onChange={handleChange}
                              value={values.writer_name}
                            />
                            {/* {this.bindingPublisherName({
                              values,
                              handleChange,
                            })} */}
                          </FormGroup>
                        </Col>
                        <Col sm="2">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary ml-2"
                            style={{
                              marginTop: 27,
                              color: "white",
                              width: 100,
                            }}
                          >
                            Search
                          </button>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </CardBody>

            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.publishers.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ reportPublishBookReducer, memberReducer }) => ({
  reportPublishBookReducer,
  memberReducer,
});

const mapDispatchToProps = {
  getReportPublishBook,
  getAllMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPublishBook);

import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import Swal from "sweetalert2";
import UserFrom from "../userForm";

class CreateUser extends Component {
  showForm = ({ values, handleChange, handleSubmit, isSubmitting }) => {
    return (
      <div style={{ padding: 30 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD USER</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <UserFrom
                      values={values}
                      handleChange={handleChange}
                      isDisableField={false}
                    ></UserFrom>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      Add
                    </button>
                    <a
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      Back
                    </a>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            user_username: "",
            user_password: "",
            user_firstname: "",
            user_lastname: "",
            user_email: "",
            user_phone: "",
            user_role: "1"
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;

            const params = {
              user_username: values.user_username,
              user_password: values.user_admin_password,
              user_firstname: values.user_firstname,
              user_lastname: values.user_lastname,
              user_email: values.user_email,
              user_phone: values.user_phone,
              user_role: values.user_role,
              created_by: username
            }

            
            const token = localStorage.getItem("token")
            let response = await httpClient.post(server.USER_URL, 
              JSON.stringify(params), {
              headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "บันทึกข้อมูลเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 2000,
              });
              setSubmitting(false);
              this.props.history.push(`/user`);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                timer: 2000,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

export default CreateUser;

import { httpClient } from "../utils/HttpClient";

import {
  HTTP_REDEEM_LIST_SUCCESS,
  HTTP_REDEEM_LIST_FETCHING,
  HTTP_REDEEM_LIST_FAILED,
  HTTP_REDEEM_GROUP_SUCCESS,
  HTTP_REDEEM_GROUP_FETCHING,
  HTTP_REDEEM_GROUP_FAILED,
  HTTP_REDEEM_ITEM_LIST_SUCCESS,
  HTTP_REDEEM_ITEM_LIST_FETCHING,
  HTTP_REDEEM_ITEM_LIST_FAILED,
  HTTP_REDEEM_SEARCH_SUCCESS,
  HTTP_REDEEM_SEARCH_FETCHING,
  HTTP_REDEEM_SEARCH_FAILED,
  server
} from "../constants";

const setStateRedeemListToSuccess = payload => ({
  type: HTTP_REDEEM_LIST_SUCCESS,
  payload: payload
});

const setStateRedeemListToFetching = () => ({
  type: HTTP_REDEEM_LIST_FETCHING
});

const setStateRedeemListToFailed = () => ({
  type: HTTP_REDEEM_LIST_FAILED
});

const setStateRedeemGroupToSuccess = payload => ({
  type: HTTP_REDEEM_GROUP_SUCCESS,
  payload: payload
});

const setStateRedeemGroupToFetching = () => ({
  type: HTTP_REDEEM_GROUP_FETCHING
});

const setStateRedeemGroupToFailed = () => ({
  type: HTTP_REDEEM_GROUP_FAILED
});

const setStateRedeemItemListToFetching = () => ({
  type: HTTP_REDEEM_ITEM_LIST_FETCHING,
});

const setStateRedeemItemListToSuccess = payload => ({
  type: HTTP_REDEEM_ITEM_LIST_SUCCESS,
  payload: payload
});

const setStateRedeemItemListToFailed = () => ({
  type: HTTP_REDEEM_ITEM_LIST_FAILED
});

const setStateRedeemSearchToFetching = () => ({
  type: HTTP_REDEEM_SEARCH_FETCHING,
});

const setStateRedeemSearchToSuccess = payload => ({
  type: HTTP_REDEEM_SEARCH_SUCCESS,
  payload: payload
});

const setStateRedeemSearchToFailed = () => ({
  type: HTTP_REDEEM_SEARCH_FAILED
});


export const getRedeemList = () => {
  return async dispatch => {
    dispatch(setStateRedeemListToFetching());

    const token = localStorage.getItem("token");

    httpClient.get(`${server.REDEEM_URL}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(result=>{
      dispatch(setStateRedeemListToSuccess(result.data))
    }).catch(error=>{
        alert(JSON.stringify(error))
        dispatch(setStateRedeemListToFailed())
    })
  };
};

export const getRedeemDetail = (id, type) => {
  return async dispatch => {
    dispatch(setStateRedeemGroupToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.REDEEM_URL}/${type}/${id}/`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      dispatch(setStateRedeemGroupToSuccess(result.data))
    } catch(error)  {
      alert(JSON.stringify(error))
      dispatch(setStateRedeemGroupToFailed())
    }
  };
};

export const getRedeemItemList = (groupId) => {
  return async dispatch => {
    dispatch(setStateRedeemItemListToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.REDEEM_URL}/group/${groupId}/list`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      dispatch(setStateRedeemItemListToSuccess(result.data))
    } catch(error)  {
      alert(JSON.stringify(error))
      dispatch(setStateRedeemItemListToFailed())
    }
  };
};


export const getRedeemByCode = (code) => {
  return async dispatch => {
    dispatch(setStateRedeemSearchToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.REDEEM_URL}/${code}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
   
      dispatch(setStateRedeemSearchToSuccess(result.data))
    } catch(error)  {
      alert(JSON.stringify(error))
      dispatch(setStateRedeemSearchToFailed())
    }
  };
};


import React, { Component } from "react";
import { server } from "../../constants";
import { connect } from "react-redux";


class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem(server.LOGIN_PASSED);
    localStorage.removeItem("user_profile");
    localStorage.removeItem("book_current_page");
    localStorage.removeItem("banner_current_page");
    localStorage.removeItem("active_member_page");
    localStorage.removeItem("member_current_page");
    localStorage.removeItem("chapter_current_page");
    localStorage.removeItem("topup_current_page");
    localStorage.removeItem("purchase_current_page");
    this.props.history.push(`/login`)
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = ({ appReducer }) => ({
    appReducer
  });
  
  const mapDispatchToProps = {
    
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Logout);
import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_SILVERTRANS_SUCCESS,
  HTTP_SILVERTRANS_FETCHING,
  HTTP_SILVERTRANS_FAILED,
  HTTP_SILVERTRANSUM_SUCCESS,
  HTTP_SILVERTRANSUM_FETCHING,
  HTTP_SILVERTRANSUM_FAILED,
  HTTP_CAMPAIGNID_SUCCESS,
  HTTP_CAMPAIGNID_FETCHING,
  HTTP_CAMPAIGNID_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateSilverTransToSuccess = (payload) => ({
  type: HTTP_SILVERTRANS_SUCCESS,
  payload: payload,
});

const setStateSilverTransToFetching = () => ({
  type: HTTP_SILVERTRANS_FETCHING,
});

const setStateSilverTransToFailed = () => ({
  type: HTTP_SILVERTRANS_FAILED,
});

export const getReportSilverTrans = (value) => {
  return async (dispatch) => {
    dispatch(setStateSilverTransToFetching());
    await doGetReportSilverTrans(dispatch, value);
  };
};

const doGetReportSilverTrans = async (dispatch, value) => {
  let param = {};

  if (value) {
    if (value.start) value.start = value.start + " 00:00.00";
    else value.start = "";

    if (value.end) value.end = value.end + " 23:59.00";
    else
      value.end = moment(new Date())
        .format("YYYY-MM-DD 23:59.00");

    param = {
      start_date: value.start,
      end_date: value.end,
      page: value.page,
      campaign_name: value.campaign_name,
      member_name:value.member_name
    };
  }
  else{
    param = {
      page: '',
    };
  }

  const token = localStorage.getItem("token");

  await httpClient
    .get(`${server.REPORT_URL}/silver_coin_trans` , {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    })
    .then((result) => {
      dispatch(setStateSilverTransToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateSilverTransToFailed());
    });
};

/* Summary */
const setStateSummarySilverTransToSuccess = (payload) => ({
  type: HTTP_SILVERTRANSUM_SUCCESS,
  payload: payload,
});

const setStateSummarySilverTransToFetching = () => ({
  type: HTTP_SILVERTRANSUM_FETCHING,
});

const setStateSummarySilverTransToFailed = () => ({
  type: HTTP_SILVERTRANSUM_FAILED,
});

export const getReportSummarySilverTrans = (value) => {
  return async (dispatch) => {
    dispatch(setStateSummarySilverTransToFetching());
    await doGetReportSummarySilverTrans(dispatch, value);
  };
};

const doGetReportSummarySilverTrans = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dateFilter += "?end_date=" + value.end + " 23:59.00";
      else dateFilter += "&end_date=" + value.end + " 23:59.00";
    }

    if (value.campaignName) {
      if (dateFilter.length > 0)
        dateFilter += "&campaign_name=" + value.campaignName;
      else dateFilter += "?campaign_name=" + value.campaignName;
    }
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.SUMMARY_SILVER_TRANS_URL}` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateSummarySilverTransToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateSummarySilverTransToFailed());
    });
};

/* Get Campaign Name */
const setStateCampaignNameToSuccess = (payload) => ({
  type: HTTP_CAMPAIGNID_SUCCESS,
  payload: payload,
});

const setStateCampaignNameToFetching = () => ({
  type: HTTP_CAMPAIGNID_FETCHING,
});

const setStateCampaignNameToFailed = () => ({
  type: HTTP_CAMPAIGNID_FAILED,
});

export const getCampaignName = () => {
  return async (dispatch) => {
    dispatch(setStateCampaignNameToFetching());
    await doGetCampaignName(dispatch);
  };
};

const doGetCampaignName = (dispatch) => {
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.GET_CAMPAIGN_URL}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateCampaignNameToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateCampaignNameToFailed());
    });
};

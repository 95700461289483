import React, { Component, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { Row, Col, FormInput, Card, CardHeader, CardBody } from "shards-react";
import {
  Table,
  Tag,
  Button,
  Skeleton,
  Modal,
  message,
  DatePicker,
  Input,
  Select,
  Spin,
  AutoComplete,
} from "antd";
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import XLSX from "xlsx";

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

export default function ReportEachChapter() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [dateStart, setDateStart] = useState(
    moment()
      .subtract(30, "day")
      .format("YYYY-MM-DD 00:00:00")
  );
  const [dateEnd, setDateEnd] = useState(
    moment().format("YYYY-MM-DD 23:59:59")
  );
  const [searchBook, setSearchBook] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [bookTitleOptions, setBookTitleOptions] = useState([]);
  const [chapterTitleOptions, setChapterTitleOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    start_date: moment()
      .subtract(30, "day")
      .format("YYYY-MM-DD 00:00:00"),
    end_date: moment().format("YYYY-MM-DD 23:59:59"),
    book_title: "",
    chaptor_title: "",
    sortingColumn: "",
    sortingOrder: "",
    page: 0,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });
  const [bookTitle, setBookTitle] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");

  const isFirstRun = useRef(true);

  useEffect(() => {
    localStorage.removeItem("topup_current_page");
    if (query.get("book_title")) {
      handleBookNameSelect(query.get("book_title"));
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem("topup_current_page");
    handleBookNameSearch("");
  }, [0]);

  useEffect(() => {
    if (isFirstRun.current && query.get("book_title")) {
      isFirstRun.current = false;
      return;
    }
    getReportEachChapter();
  }, [filter]);

  function formatAmount(amount) {
    if (amount) {
      return amount;
    } else return 0;
  }

  function formatNumber(number) {
    if (number) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(number);
    } else return 0;
  }

  const getReportEachChapter = async () => {
    setHistoryLoading(true);

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`admin/report/chapter_ranks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
      });

      if (result.data.total === 0) {
        Swal.fire({
          icon: "error",
          text: "ไม่มีข้อมูล",
          timer: 3000,
        });
      } else {
        const tableRows = result.data.chapter.map((item, index) => {
          return {
            key: item.chaptor_id,
            book_title: item.book_title,
            chaptor_title: item.chaptor_title,
            total_view: item.total_view,
            sum_gold: item.sum_gold,
            sum_silver: item.sum_silver,
            sum_botread_gold: item.sum_botread_gold,
            sum_botread_silver: item.sum_botread_silver,
            count_member: formatNumber(item.count_member),
          };
        });

        setTransactions(tableRows);
        setPagination({
          ...pagination,
          total: result.data.total,
        });
      }
      setHistoryLoading(false);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setHistoryLoading(false);
    }
  };

  function handleTableChange(pagination, filters, sorter) {
    setPagination(pagination);
    let sortingColumn = null;
    let sortingOrder = null;

    if (sorter.order) {
      sortingColumn = sorter.field;
      sortingOrder = sorter.order;
    }

    setFilter({
      ...filter,
      page: pagination.current - 1,
      sortingColumn: sortingColumn || "",
      sortingOrder: sortingOrder || "",
    });
  }

  const onDateChange = (e) => {
    let dateStart = "";
    let dateEnd = "";

    if (e) {
      setPagination({
        ...pagination,
        current: 1,
      });

      dateStart = moment(e[0]).format("YYYY-MM-DD 00:00:00");
      dateEnd = moment(e[1]).format("YYYY-MM-DD 23:59:59");

      setDateStart(dateStart);
      setDateEnd(dateEnd);

      setFilter({
        ...filter,
        page: 0,
        start_date: dateStart,
        end_date: dateEnd,
      });
    } else {
      setDateStart(null);
      setDateEnd(null);

      setFilter({
        ...filter,
        page: 0,
        start_date: null,
        end_date: null,
      });
    }
  };

  const handleBookNameSelect = async (title) => {
    if (title) {
      setPagination({
        ...pagination,
        current: 1,
      });
      setSearchSubCategory("");
      setFilter({
        ...filter,
        page: 0,
        book_title: title,
        chaptor_title: "",
      });
      handleChapterTitleSearch(title);
    } else {
      setFilter({
        ...filter,
        page: 0,
        book_title: "",
        chaptor_title: "",
      });
      await setChapterTitleOptions([]);
    }
  };

  const handleChapterTitleSelect = (chapter) => {
    setPagination({
      ...pagination,
      current: 1,
    });
    setSearchSubCategory(chapter);
    setFilter({
      ...filter,
      page: 0,
      chaptor_title: chapter,
    });
  };

  const handleBookNameSearch = async (name) => {
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get("admin/book/search/title", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          book_title: name,
        },
      });

      await setBookTitleOptions(
        result.data.map((item) => {
          return {
            label: item.book_title,
            value: item.book_title,
          };
        })
      );
    } catch (e) {
      message.open({ content: "โหลดข้อมูลชื่อหนังสือไม่สำเร็จ" });
    }
  };

  const handleChapterTitleSearch = async (name) => {
    if (name) {
      try {
        const token = localStorage.getItem("token");
        const result = await httpClient.get("admin/chapter/search/title", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            book_title: name,
          },
        });

        setChapterTitleOptions(
          result.data.map((item) => {
            return {
              label: item.chaptor_title,
              value: item.chaptor_title,
            };
          })
        );
      } catch (e) {
        message.open({ content: "โหลดข้อมูลชื่อตอนไม่สำเร็จ" });
      }
    }
  };

  async function exportChapterData() {
    if (!filter.book_title) {
      message.open({
        content: "กรุณาเลือกชื่อหนังสือก่อนทำการ export ข้อมูล",
        duration: 4,
      });
    } else if (!filter.start_date || !filter.end_date) {
      message.open({
        content: "กรุณาเลือกวันที่เริ่มต้นและสิ้นสุดก่อนทำการ export ข้อมูล",
        duration: 4,
      });
    } else if (moment(filter.end_date).diff(filter.start_date, "months") > 3) {
      message.open({
        content: "ไม่สามารถ export ข้อมูลมากกว่า 3 เดือนได้",
        duration: 4,
      });
    } else {
      setLoadingExport(true);

      try {
        const token = localStorage.getItem("token");
        const result = await httpClient.get(`admin/report/chapter_ranks`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            ...filter,
            page: null,
          },
        });

        let report = result.data.chapter.map((item, idx) => {
          return [
            item.book_title,
            item.chaptor_title,
            Number(item.total_view),
            Number(item.sum_gold),
            Number(item.sum_silver),
            Number(item.sum_botread_gold),
            Number(item.sum_botread_silver),
            Number(item.count_member),
          ];
        });

        report = [
          [
            "ชื่อหนังสือ",
            "ชื่อตอน",
            "จำนวนครั้งที่เปิดอ่าน",
            "เหรียญทอง",
            "เหรียญเงิน",
            "เหรียญทอง(หนังสือเสียง)",
            "เหรียญเงิน(หนังสือเสียง)",
            "จำนวน Member ที่ซื้อ",
          ],
          ...report,
        ];

        if (report) {
          await downloadExportData(report);
          setLoadingExport(false);
        }
      } catch (e) {
        message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
        setLoadingExport(false);
      }
    }
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["ReportChapter"]);
    XLSX.writeFile(workBook, `ReportChapter.xlsx`);
  }

  return (
    <div style={{ padding: 10 }}>
      <Card small>
        <CardHeader className="border-bottom">
          <h6 className="m-0">ยอดขายแต่ละบท</h6>
        </CardHeader>
        <CardBody>
          <div className="row mt-2">
            <div className="col-sm-4">
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => handleBookNameSelect(value, event)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="">ค้นหาด้วย ชื่อหนังสือทั้งหมด</Option>
                {bookTitleOptions &&
                  bookTitleOptions.map((item, index) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
              </Select>
              {/* <AutoComplete
                className="w-100"
                placeholder="ค้นหาด้วย ชื่อหนังสือ"
                onSearch={handleBookNameSearch}
                onSelect={handleBookNameSelect}
                options={bookTitleOptions}
                allowClear={true}       
              /> */}
            </div>
            <div className="col-sm-3">
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  handleChapterTitleSelect(value, event)
                }
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                value={searchSubCategory}
              >
                <Option value="">ค้นหาด้วย ชื่อตอนทั้งหมด</Option>
                {chapterTitleOptions &&
                  chapterTitleOptions.map((item, index) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
              </Select>
              {/* <AutoComplete
                className="w-100"
                placeholder="ค้นหาด้วย ชื่อตอน"
                value={searchSubCategory}
                onSelect={handleChapterTitleSelect}
                options={chapterTitleOptions}
              /> */}
            </div>
            <div className="col-sm-4">
              <RangePicker
                className="w-100"
                placeholder={["เริ่มต้น", "สิ้นสุด"]}
                format="DD/MM/YYYY"
                disabledDate={false}
                onChange={onDateChange}
                value={[
                  dateStart ? moment(dateStart) : null,
                  dateEnd ? moment(dateEnd) : null,
                ]}
              />
            </div>
            <div className="col-sm-1">
              <Spin spinning={historyLoading} className="mt-2" />
            </div>
          </div>

          {transactions.length != 0 && (
            <>
              <div className="mt-3 d-flex align-items-center">
                <div className="flex-fill">
                  จำนวนทั้งหมด{" "}
                  <b className="mx-1">
                    {pagination.total ? pagination.total.toLocaleString() : 0}
                  </b>{" "}
                  ตอน
                </div>
                <Button
                  className="btn-primary px-3"
                  onClick={exportChapterData}
                >
                  Export
                </Button>
                <Spin spinning={loadingExport} />
              </div>

              <div className="mt-2">
                <Table
                  dataSource={transactions}
                  loading={historyLoading}
                  pagination={pagination}
                  onChange={handleTableChange}
                >
                  <Column
                    title="ชื่อหนังสือ"
                    dataIndex="book_title"
                    key="book_title"
                    align="center"
                  />
                  <Column
                    title="ตอน"
                    dataIndex="chaptor_title"
                    key="chaptor_title"
                    align="center"
                    sorter={true}
                  />
                  <Column
                    title="จำนวนครั้งที่เปิดอ่าน"
                    dataIndex="total_view"
                    key="total_view"
                    align="center"
                    sorter={true}
                    render={(value) => {
                      return formatNumber(value);
                    }}
                    sortDirections={['descend','ascend','']}
                  />
                  <Column
                    title="เหรียญทอง"
                    dataIndex="sum_gold"
                    key="sum_gold"
                    sorter={true}
                    render={(value) => {
                      return value.toLocaleString();
                    }}
                    align="center"
                    sortDirections={['descend','ascend','']}
                  />
                  <Column
                    title="เหรียญเงิน"
                    dataIndex="sum_silver"
                    key="sum_silver"
                    sorter={true}
                    render={(value) => {
                      return value.toLocaleString();
                    }}
                    align="center"
                    sortDirections={['descend','ascend','']}
                  />
                  <Column
                    title="เหรียญทอง (หนังสือเสียง)"
                    dataIndex="sum_botread_gold"
                    key="sum_botread_gold"
                    sorter={true}
                    render={(value) => {
                      return value.toLocaleString();
                    }}
                    align="center"
                    sortDirections={['descend','ascend','']}
                  />
                  <Column
                    title="เหรียญเงิน (หนังสือเสียง)"
                    dataIndex="sum_botread_silver"
                    key="sum_botread_silver"
                    sorter={true}
                    render={(value) => {
                      return value.toLocaleString();
                    }}
                    align="center"
                    sortDirections={['descend','ascend','']}
                  />

                  <Column
                    title="จำนวน Member ที่ซื้อ"
                    dataIndex="count_member"
                    key="count_member"
                    sorter={true}
                    render={(value) => {
                      return formatNumber(value);
                    }}
                    align="center"
                    sortDirections={['descend','ascend','']}
                  />
                </Table>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormCheckbox
} from "shards-react";

export default class userRoleFrom extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  async componentDidMount() {
    await this.setState(this.props.values)
  }

  handleChange(e, name) {
    const newState = {};
    newState[name] = !this.state[name];
    this.setState({ ...this.state, ...newState });
    this.props.values[name] = !this.props.values[name]
  }

  render() {
    const { values, handleChange, isDisableField } = this.props;
    
    return (
      <React.Fragment>
        <fieldset disabled={isDisableField}>
          <Row form>
            <Col md="12" className="form-group">
              <label>Role Name</label>
              <FormInput
                name="role_name"
                id="role_name"
                onChange={handleChange}
                value={values.role_name}
              />
            </Col>
          </Row>

          <Row form className="mt-3">
            <Col md="12" className="form-group">
              <label className="h6">Content Management Privilege</label>
              <hr className="mt-2" />

              <Row form>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Dashboard</label>
                      <p className="font-11 text-muted mb-0">เข้าถึงข้อมูลภาพรวมของ 2read (ผู้ใช้งาน, ยอดเติมเงิน)</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.dashboard}
                      onChange={(e) => this.handleChange(e, 'dashboard')}>
                    </FormCheckbox>
                  </div>
                </Col>
              </Row>

              <Row form>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Book</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลหนังสือและตอนของหนังสือ</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.book}
                      onChange={(e) => this.handleChange(e, 'book')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Category</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลหมวดหมู่หนังสือ</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.category}
                      onChange={(e) => this.handleChange(e, 'category')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Comment</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลรีวิว, ความคิดเห็นของผู้ใช้งาน</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.comment}
                      onChange={(e) => this.handleChange(e, 'comment')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Package</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูล Package การเติมเงินซื้อเหรียญ</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.package}
                      onChange={(e) => this.handleChange(e, 'package')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Push Notification</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลการส่ง Push notification ให้ผู้ใช้งานผ่าน Mobile Application</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.push_notification}
                      onChange={(e) => this.handleChange(e, 'push_notification')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Banner</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูล Banner</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.banner}
                      onChange={(e) => this.handleChange(e, 'banner')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Playlist</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูล Playlist ที่ใช้แสดงบนเว็บไซต์ 2read</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.playlist}
                      onChange={(e) => this.handleChange(e, 'playlist')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Campaign</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลกิจกรรมแจกเหรียญเงิน</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.campaign}
                      onChange={(e) => this.handleChange(e, 'campaign')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Writer Recommend</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลนักเขียนแนะนำ</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.writer_recommend}
                      onChange={(e) => this.handleChange(e, 'writer_recommend')}>
                    </FormCheckbox>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row form className="mt-3">
            <Col md="12" className="form-group">
              <label className="h6">User Management Privilege</label>
              <hr className="mt-2" />

              <Row form>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Member</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลผู้ใช้งานทั่วไป (Reader)</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.member}
                      onChange={(e) => this.handleChange(e, 'member')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Writer</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลนักเขียน</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.writer}
                      onChange={(e) => this.handleChange(e, 'writer')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>User</label>
                      <p className="font-11 text-muted mb-0">จัดการข้อมูลผู้ใช้งานเว็บไซต์ Admin</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.user}
                      onChange={(e) => this.handleChange(e, 'user')}>
                    </FormCheckbox>
                  </div>
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Income Ratio</label>
                      <p className="font-11 text-muted mb-0">สัดส่วนรายได้ของนักเขียน</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.writer_income_ratio}
                      onChange={(e) => this.handleChange(e, 'writer_income_ratio')}>
                    </FormCheckbox>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row form className="mt-3">
            <Col md="12" className="form-group">
              <label className="h6">Report Privilege</label>
              <hr className="mt-2" />

              <Row form>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Marketing Report</label>
                      <p className="font-11 text-muted mb-0">การเข้าถึงข้อมูลรายงานการตลาด</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.marketing_report}
                      onChange={(e) => this.handleChange(e, 'marketing_report')}>
                    </FormCheckbox>
                  </div>
                </Col>
                <Col md="4" className="form-group">
                  <div className="d-flex align-items-center">
                    <div className="flex-fill">
                      <label className="mb-0" style={{position: 'relative', top: '-1px'}}>Accountant Report</label>
                      <p className="font-11 text-muted mb-0">การเข้าถึงข้อมูลรายงานบัญชี</p>
                    </div>
                    <FormCheckbox
                      toggle
                      checked={this.state.accountant_report}
                      onChange={(e) => this.handleChange(e, 'accountant_report')}>
                    </FormCheckbox>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </fieldset>
      </React.Fragment>
    );
  }
}

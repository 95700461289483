import {
  HTTP_USERS_SUCCESS,
  HTTP_USERS_FETCHING,
  HTTP_USERS_FAILED,
  HTTP_USER_SUCCESS,
  HTTP_USER_FETCHING,
  HTTP_USER_FAILED,
  HTTP_USER_ROLES_SUCCESS,
  HTTP_USER_ROLES_FETCHING,
  HTTP_USER_ROLES_FAILED,
  HTTP_USER_ROLE_SUCCESS,
  HTTP_USER_ROLE_FETCHING,
  HTTP_USER_ROLE_FAILED
} from "../constants/user";

const initialState = {
  users: null,
  result: null,
  roles: null,
  role: null,
  total: 0,
  isFetching: false,
  isError: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_USERS_SUCCESS:
      return {
        ...state,
        users: payload.users,
        total: payload.total,
        isFetching: false,
        isError: false
      };
    case HTTP_USERS_FAILED:
      return { ...state, users: null, isFetching: false, isError: true };
    case HTTP_USERS_FETCHING:
      return { ...state, users: null, isFetching: true, isError: false };
    case HTTP_USER_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    case HTTP_USER_FETCHING:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_USER_FAILED:
      return { ...state, result: null, isFetching: true, isError: false };
    case HTTP_USER_ROLES_SUCCESS:
      return { ...state, roles: payload, isFetching: false, isError: false };
    case HTTP_USER_ROLES_FETCHING:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_USER_ROLES_FAILED:
      return { ...state, result: null, isFetching: true, isError: false };
    case HTTP_USER_ROLE_SUCCESS:
      return { ...state, role: payload, isFetching: false, isError: false };
    case HTTP_USER_ROLE_FETCHING:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_USER_ROLE_FAILED:
      return { ...state, result: null, isFetching: true, isError: false };
    default:
      return state;
  }
};

import { httpClient } from "../utils/HttpClient";

import {
  HTTP_BOOK_PUBLISH_SUCCESS,
  HTTP_ฺฺBOOK_PUBLISH_FETCHING,
  HTTP_BOOK_PUBLISH_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateBookPublishToSuccess = (payload) => ({
  type: HTTP_BOOK_PUBLISH_SUCCESS,
  payload: payload,
});

const setStateBookPublishToFetching = () => ({
  type: HTTP_ฺฺBOOK_PUBLISH_FETCHING,
});

const setStateBookPublishToFailed = () => ({
  type: HTTP_BOOK_PUBLISH_FAILED,
});

export const getBookPublishTransaction = (value) => {
  return async (dispatch) => {
    dispatch(setStateBookPublishToFetching());
    await doGetBookPublishTransaction(dispatch, value);
  };
};

const doGetBookPublishTransaction = async (dispatch, value) => {
  try {
    let param = {};
    if (value) {
      if (value.start) value.start = value.start + " 00:00.00";
      else value.start = "";

      if (value.end) value.end = value.end + " 23:59.00";
      else value.end = moment(new Date()).format("YYYY-MM-DD 23:59.00");

      param = {
        page: value.page,
        member_id: value.member_id,
        start_date: value.start,
        end_date: value.end,
      };
    } else {
      param = {
        page: "",
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.REPORT_URL}/writer/publish`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    });
    dispatch(setStateBookPublishToSuccess(result.data));
  } catch (err) {
    dispatch(setStateBookPublishToFailed());
  }
};

import React, { Component } from "react";
import * as actions from "../../../actions/reportWriterDetail.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  Button
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";

class ReportWriterDetail extends Component {
  async componentDidMount() {
    let member_id = this.props.match.params.member_id;
    const value = {};
    value.writerID = member_id;
    this.props.getReportWriterDetail(value);
  }

  formatPrice(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.coin);
  }

  formatTransactionDate(cell, row) {
    if (row.created_datetime) {
      return moment(row.created_datetime).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportWriterDetailReducer;
      const csv_name = "ReportWriterDetail.csv";

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="transaction_id"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                isKey={true}
                hidden={true}
              >
                Transaction ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_datetime"
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatTransactionDate}
                csvFormat={this.formatTransactionDate}
                csvHeader="วันที่ทำรายการซื้อ"
              >
                วันที่ทำรายการซื้อ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="ชื่อนักเขียน"
              >
                ชื่อนักเขียน
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_title"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="ชื่อหนังสือ"
              >
                ชื่อหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="chaptor_title"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="บทที่"
              >
                บทที่
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_chaptor_name"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="ชื่อตอน"
              >
                ชื่อตอน
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="จำนวน Coin"
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatPrice}
                csvFormat={this.formatPrice}
                csvHeader="จำนวน Coin"
              >
                จำนวน Coin
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="coin_type"
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="ประเภทเหรียญที่ใช้"
              >
                ประเภทเหรียญที่ใช้
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const { result, isFetching } = this.props.reportWriterDetailReducer;

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 20 }}>
            <Card className="mt-2">
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Report Writer(Detail)</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      className=" btn btn-danger btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Back
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    value.start = startDate;
                    value.end = endDate;
                    value.writerID = this.props.match.params.member_id;

                    this.props.getReportWriterDetail(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col className="form-group">
                            <label>วันที่ทำรายการซื้อ เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>

                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary btn-sm ml-4"
                                style={{
                                  color: "white",
                                  width: 100,
                                }}
                              >
                                Search
                              </button>
                            </InputGroup>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}

                <div className="text-right">
                  <button
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                    type="Button"
                    className="btn btn-danger pull-right"
                    style={{ color: "white", width: 100 }}
                  >
                    Back
                  </button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportWriterDetailReducer }) => ({
  reportWriterDetailReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportWriterDetail);

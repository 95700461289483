import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_REPORT_COINREMAIN_SUCCESS,
  HTTP_REPORT_COINREMAIN_FETCHING,
  HTTP_REPORT_COINREMAIN_FAILED,
  server,
} from "../constants";

/* REPORT COIN REMAIN ACTION */
const setStateCoinRemainToSuccess = (payload) => ({
  type: HTTP_REPORT_COINREMAIN_SUCCESS,
  payload: payload,
});

const setStateCoinRemainToFetching = () => ({
  type: HTTP_REPORT_COINREMAIN_FETCHING,
});

const setStateCoinRemainToFailed = () => ({
  type: HTTP_REPORT_COINREMAIN_FAILED,
});

/* Get Report Coin Remain */
export const getReportCoinRemain = (value) => {
  return async (dispatch) => {
    dispatch(setStateCoinRemainToFetching());
    await doGetReportCoinRemain(dispatch, value);
  };
};

const doGetReportCoinRemain = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dateFilter += "?end_date=" + value.end + " 23:59.00";
      else dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/coin_remain` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateCoinRemainToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateCoinRemainToFailed());
    });
};

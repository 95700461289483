import React, { Component } from "react";
import * as actions from "../../../actions/member.action";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormInput,
  FormGroup,
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ButtonGroup,
} from "shards-react";
import Swal from "sweetalert2";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import { Tabs } from "antd";
import ActiveMember from "../activeMember";
import XLSX from "xlsx";
import { NavLink } from "react-router-dom";
import MenuMember from "../menuMember";

const isAllowAction = () => {
  var result = localStorage.getItem("user_profile");
  if (result) {
    const priv_allow = JSON.parse(result).member;

    if (priv_allow === "Y") return true;
    else {
      Swal.fire({
        icon: "error",
        text: "No privilege!",
      });
      return false;
    }
  }
};

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      name: "",
      email: "",
      start_date: null,
      end_date: null,
    };
  }

  async componentDidMount() {
    const current_page = localStorage.getItem("member_current_page");

    if (this.props.location.page)
      await this.setState({ currentPage: this.props.location.page });
    else if (current_page)
      await this.setState({ currentPage: Number(current_page) });

    await this.getMembers(this.state.currentPage); //get member_type =1 (1 equal Member,2 equal Writer)
    
    if (this.props.memberReducer.result) {
      this.setState({ members: this.props.memberReducer.result });
    }

    localStorage.removeItem("member_current_page");
    localStorage.removeItem("active_member_page");
    localStorage.removeItem("topup_current_page");
    localStorage.removeItem("purchase_current_page");
  }

  async getMembers(page) {
    this.setState({ loading: true });

    if (this.state.start_date) {
      await this.setState({
        start_date: moment(this.state.start_date).format("YYYY-MM-DD 00:00"),
      });
    }

    if (this.state.end_date) {
      await this.setState({
        end_date: moment(this.state.end_date).format("YYYY-MM-DD 23:59"),
      });
    }

    await this.props.getAllMember(
      1,
      this.state.name,
      this.state.email,
      this.state.start_date,
      this.state.end_date,
      page
    );

    const { members, total } = this.props.memberReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      members,
      totalPages,
      loading: false,
    });
  }

  btnFormatter(member_id) {
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-xs btn-info"
          onClick={() =>
            this.props.history.push(
              {
                pathname: "/viewMember/" + member_id + "/member",
                page: this.state.currentPage,
              },
              localStorage.setItem(
                "member_current_page",
                Number(this.state.currentPage)
              )
            )
          }
        >
          View
        </button>

        <span style={{ color: "grey" }}> | </span>

        <button
          type="button"
          className="btn btn-xs btn-success text-white"
          onClick={() =>
            this.props.history.push(
              {
                pathname: "/editMember/" + member_id + "/member",
                page: this.state.currentPage,
              },
              localStorage.setItem(
                "member_current_page",
                Number(this.state.currentPage)
              )
            )
          }
        >
          Edit
        </button>

        <span style={{ color: "grey" }}> | </span>

        <button
          onClick={() => {
            return (
              isAllowAction() &&
              Swal.fire({
                text: "Are you sure to delete?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
              }).then((result) => {
                if (result.value) {
                  this.deleteMember(member_id);
                }
              })
            );
          }}
          type="button"
          className="btn btn-xs btn-danger text-white"
        >
          Delete
        </button>
      </React.Fragment>
    );
  }

  async deleteMember(member_id) {
    await this.props.deleteMember(member_id, 1, this.state.currentPage); //1 Equal Member
    setTimeout(() => {
      this.getMembers(this.state.currentPage);
    }, 100);
  }

  formatCreateDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatGoldCoin(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatSilverCoin(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  async exportData() {
    if (!this.state.start_date || !this.state.end_date) {
      Swal.fire({
        icon: "error",
        text:
          "กรุณาเลือกวันที่สมัครสมาชิกเริ่มต้นและสิ้นสุดก่อนทำการ export ข้อมูล",
      });
      return;
    }

    await this.getMembers("");

    let report_member = this.state.members.map((item, idx) => {
      return [
        item.no,
        item.member_name,
        item.member_email,
        item.member_phone,
        item.member_username,
        Number(item.member_coin),
        Number(item.member_silver_coin),
        this.formatDateTime(item.created_datetime),
      ];
    });

    report_member = [
      [
        "ชื่อสมาชิก",
        "อีเมลล์",
        "เบอร์โทรศัพท์",
        "Username",
        "Gold Coin",
        "Silver Coin",
        "วันที่สมัครสมาชิก",
      ],
      ...report_member,
    ];

    if (report_member) {
      this.downloadExportData(report_member);
      this.getMembers(0);
    }
  }

  downloadExportData(report_order) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report_order);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["orders"]);
    XLSX.writeFile(workBook, `report-member.xlsx`);
  }

  renderTable = () => {
    return (
      <>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">No</th>
              <th className="text-center">Create Date</th>
              <th className="text-center">Member Name</th>
              <th className="text-center">Email</th>
              <th className="text-center">Phone</th>
              <th className="text-center">Username</th>
              <th className="text-center">Gold Coin</th>
              <th className="text-center">Silver Coin</th>
              <th className="text-center">Update By</th>
              <th className="text-center">Transaction History</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.members.map((member, index) => {
              return (
                <tr key={member.member_id}>
                  <td className="align-middle text-center">{member.no}</td>
                  <td className="text-center align-middle">
                    {this.formatCreateDateTime(member.created_datetime)}
                  </td>
                  <td className="align-middle">{member.member_name}</td>
                  <td className="align-middle">{member.member_email}</td>
                  <td className="text-center align-middle">
                    {member.member_phone}
                  </td>
                  <td className="text-center align-middle">
                    {member.member_username}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatGoldCoin(member.member_coin)}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatSilverCoin(member.member_silver_coin)}
                  </td>
                  <td className="text-center align-middle">
                    {member.updated_by}
                  </td>
                  <td className="text-center align-middle">
                    <button
                      type="button"
                      className="btn btn-xs btn-warning"
                      onClick={() =>
                        this.props.history.push(
                          {
                            pathname: `/member_books/${member.member_id}/${member.member_name}/${this.state.currentPage}/member`,
                          },
                          localStorage.setItem(
                            "member_current_page",
                            Number(this.state.currentPage)
                          )
                        )
                      }
                      // onClick={() =>
                      //   this.props.history.push({
                      //     pathname: `/member_transaction/${member.member_id}/${member.member_name}`,
                      //     memberPage: this.state.currentPage,
                      //   })
                      // }
                    >
                      Transaction History
                    </button>
                  </td>

                  <td className="text-center align-middle" width="180">
                    {this.btnFormatter(member.member_id)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page }, () => {
      this.getMembers(page);
    });

    localStorage.setItem("member_current_page", page);
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <MenuMember />
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Member</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      return (
                        isAllowAction() &&
                        this.props.history.push(`/createMember`)
                      );
                    }}
                    className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add Member
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody className="pb-2">
              <Row>
                <Col sm="4" className="pr-1">
                  <FormGroup>
                    <label>ชื่อ</label>
                    <FormInput
                      name="member_name"
                      id="member_name"
                      placeholder="Name"
                      onChange={(e) =>
                        this.setState({ name: e.target.value }, () => {
                          this.getMembers(this.state.currentPage);
                        })
                      }
                      value={this.state.name}
                    />
                  </FormGroup>
                </Col>
                <Col sm="4" className="pr-1">
                  <FormGroup>
                    <label>Email</label>
                    <FormInput
                      name="member_email"
                      id="member_email"
                      placeholder="Email"
                      onChange={(e) =>
                        this.setState({ email: e.target.value }, () => {
                          this.getMembers(this.state.currentPage);
                        })
                      }
                      value={this.state.email}
                    />
                  </FormGroup>
                </Col>
                <Col sm="4" className="form-group">
                  <label>วันที่สมัครสมาชิก เริ่มต้น/สิ้นสุด</label>
                  <InputGroup className={"d-flex my-auto date-range"}>
                    <DatePicker
                      selected={
                        (this.state.start_date &&
                          new Date(this.state.start_date)) ||
                        null
                      }
                      className="text-center"
                      name="start_date"
                      placeholder="Start Date"
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        this.setState({ start_date: val }, () => {
                          this.getMembers(this.state.currentPage);
                        })
                      }
                    />

                    <DatePicker
                      selected={
                        (this.state.end_date &&
                          new Date(this.state.end_date)) ||
                        null
                      }
                      className="text-center"
                      name="end_date"
                      placeholder="End Date"
                      dateFormat="dd/MM/yyyy"
                      onChange={(val) =>
                        this.setState({ end_date: val }, () => {
                          this.getMembers(this.state.currentPage);
                        })
                      }
                    />

                    <InputGroupAddon type="append">
                      <InputGroupText>
                        <i className="material-icons">&#xE916;</i>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </CardBody>

            <div className="row">
              <Button
                onClick={() => {
                  this.exportData();
                }}
                type="button"
                className="btn-secondary d-flex ml-auto  mb-2 mr-4"
              >
                Export
              </Button>
            </div>
            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.members.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>
            </CardBody>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ memberReducer }) => ({
  memberReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Member);

import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { Row, Col, FormInput, Card, CardHeader, CardBody } from "shards-react";
import {
  Table,
  Tag,
  Button,
  Skeleton,
  Modal,
  message,
  DatePicker,
  Input,
  Spin,
} from "antd";
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import XLSX from "xlsx";

const { RangePicker } = DatePicker;
const { Column } = Table;

export default function ReportTopUpTransaction() {
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchPackage, setSearchPackage] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });
  const [filter, setFilter] = useState({
    page: 0,
  });

  useEffect(() => {
    let pageCurrent = 0;
    const current = localStorage.getItem("topup_current_page");

    if (current) {
      pageCurrent = Number(current);
    }

    getTopUpTransaction(
      dateStart,
      dateEnd,
      searchName,
      searchPackage,
      pageCurrent
    );
    localStorage.removeItem("purchase_current_page");
  }, [filter]);

  const getTopUpTransaction = async (
    dateStart,
    dateEnd,
    searchName,
    searchPackage,
    page
  ) => {
    setHistoryLoading(true);
    const dataFilter = `?start_date=${dateStart}&end_date=${dateEnd}&member_name=${searchName}&package_name=${searchPackage}&page=${page}`;

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `admin/report/topup_transaction${dataFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await setPagination({
        ...pagination,
        current: page + 1,
        total: result.data.total,
      });

      const tableRows = result.data.orders.map((item, index) => {
        return {
          key: index.toString(),
          payment_datetime: item.payment_datetime,
          member_id: item.member_id,
          member_name: item.member_name,
          package_name: item.package_name,
          package_coin: item.package_coin,
          package_coin_type: item.package_coin_type,
          payment_type: item.payment_type,
          payment_result: item.payment_result,
        };
      });

      setTransactions(tableRows);
      setHistoryLoading(false);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setHistoryLoading(false);
    }
  };

  async function exportData() {
    setLoadingExport(true);
    const dataFilter = `?start_date=${dateStart}&end_date=${dateEnd}&member_name=${searchName}&package_name=${searchPackage}`;

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `admin/report/topup_transaction${dataFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      var data = result.data.orders;

      let report = data.map((item, idx) => {
        return [
          moment(item.payment_datetime).format("DD-MM-YYYY HH:mm"),
          item.member_name,
          item.package_name,
          item.package_coin,
          item.package_coin_type,
          item.payment_type,
          item.payment_result,
        ];
      });

      report = [
        [
          "Payment Date",
          "Name",
          "Package",
          "Coin",
          "Coin Type",
          "Payment Type",
          "Payment Result",
        ],
        ...report,
      ];

      if (report) {
        await downloadExportData(report);
        setLoadingExport(false);
      }
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setLoadingExport(false);
    }
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["ReportTopUp"]);
    XLSX.writeFile(workBook, `ReportTopUp.xlsx`);
  }

  function handleTableChange(pagination, filters, sorter) {
    setPagination(pagination);

    setFilter({
      ...filter,
      page: pagination.current - 1,
    });

    localStorage.setItem("topup_current_page", pagination.current - 1);
  }

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
    getTopUpTransaction(dateStart, dateEnd, searchName, searchPackage, 0);
  };

  const onChangeSearchPackage = (e) => {
    const searchPackage = e.target.value;
    setSearchPackage(searchPackage);
    getTopUpTransaction(dateStart, dateEnd, searchName, searchPackage, 0);
  };

  const onDateChange = (e) => {
    let dateStart = "";
    let dateEnd = "";

    if (e) {
      dateStart = moment(e[0]).format("YYYY-MM-DD 00:00:00");
      dateEnd = moment(e[1]).format("YYYY-MM-DD 23:59:59");

      setDateStart(dateStart);
      setDateEnd(dateEnd);
    }

    getTopUpTransaction(dateStart, dateEnd, searchName, searchPackage, 0);
  };

  return (
    <div style={{ padding: 10 }}>
      <Card small>
        <CardHeader className="border-bottom">
          <h6 className="m-0">Top up Transaction</h6>
        </CardHeader>
        <CardBody>
          <div className="row mt-2">
            <div className="col-sm-4">
              <RangePicker
                placeholder={["เริ่มต้น", "สิ้นสุด"]}
                format="DD/MM/YYYY"
                disabledDate={false}
                onChange={onDateChange}
                className="w-100"
              />
            </div>

            <div className="col-sm-4">
              <Input
                className="h-100"
                placeholder="ค้นหาด้วย ชื่อ"
                value={searchName}
                onChange={onChangeSearchName}
              />
            </div>

            <div className="col-sm-4">
              <Input
                className="h-100"
                placeholder="ค้นหาด้วย Package"
                value={searchPackage}
                onChange={onChangeSearchPackage}
              />
            </div>
          </div>

          <div className="mt-3 d-flex align-items-center">
            <div className="flex-fill">
              เติมเงินทั้งหมด{" "}
              <b className="mx-1">
                {pagination.total ? pagination.total.toLocaleString() : 0}
              </b>{" "}
              ครั้ง
            </div>
            <Button className="btn-primary px-3" onClick={exportData}>
              Export
            </Button>
            <Spin spinning={loadingExport} />
          </div>

          <div className="mt-2">
            <Table
              dataSource={transactions}
              loading={historyLoading}
              onChange={handleTableChange}
              pagination={pagination}
            >
              <Column
                title="Payment Date"
                dataIndex="payment_datetime"
                key="payment_datetime"
                align="center"
                sorter={(a, b) =>
                  new Date(a.payment_datetime) - new Date(b.payment_datetime)
                }
                render={(value) => {
                  return moment(value).format("DD-MM-YYYY HH:mm");
                }}
              />
              <Column
                title="Name"
                dataIndex="member_name"
                key="member_name"
                align="center"
                render={(text, record) => {
                  return (
                    <a
                      href={
                        "/member_books/" +
                        record.member_id +
                        "/" +
                        record.member_name +
                        "/0/member"
                      }
                    >
                      {text}
                    </a>
                  );
                }}
              />
              <Column
                title="Package"
                dataIndex="package_name"
                key="package_name"
                align="center"
                sorter={(a, b) => a.package_name.localeCompare(b.package_name)}
              />
              <Column
                title="Coin"
                dataIndex="package_coin"
                key="package_coin"
                align="center"
              />
              <Column
                title="Coin Type"
                dataIndex="package_coin_type"
                key="package_coin_type"
                sorter={(a, b) =>
                  a.package_coin_type.localeCompare(b.package_coin_type)
                }
                align="center"
              />
              <Column
                title="Payment Type"
                dataIndex="payment_type"
                key="payment_type"
                sorter={(a, b) => a.payment_type.localeCompare(b.payment_type)}
                align="center"
              />
              <Column
                title="Payment Result"
                dataIndex="payment_result"
                key="payment_result"
                align="center"
              />
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

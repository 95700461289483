import { httpClient } from "../utils/HttpClient";

import {
    HTTP_BUY_ALLBOOK_SUCCESS,
    HTTP_BUY_ALLBOOK_FETCHING,
    HTTP_BUY_ALLBOOK_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateBuyAllBookToSuccess = (payload) => ({
  type: HTTP_BUY_ALLBOOK_SUCCESS,
  payload: payload,
});

const setStateBuyAllBookToFetching = () => ({
  type: HTTP_BUY_ALLBOOK_FETCHING,
});

const setStateBuyAllBookToFailed = () => ({
  type: HTTP_BUY_ALLBOOK_FAILED,
});

export const getReportBuyAllBook = (value) => {
  return async (dispatch) => {
    dispatch(setStateBuyAllBookToFetching());
    await doGetBuyAllBook(dispatch, value);
  };
};

const doGetBuyAllBook = async (dispatch, value) => {
  try {
    let param = {};

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00.00";
      else value.start_date = "";

      if (value.end_date) value.end_date = value.end_date + " 23:59.00";
      else
        value.end = moment(new Date())
          .format("YYYY-MM-DD 23:59.00");
      
      param = {
        start_date: value.start_date,
        end_date: value.end_date,
        book_title: value.book_title,
      };
    } 

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.REPORT_URL}/transaction/all_chapter`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    });
    dispatch(setStateBuyAllBookToSuccess(result.data));
  } catch (err) {
    dispatch(setStateBuyAllBookToFailed());
  }
};

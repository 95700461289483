import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import * as actions from "../../../actions/playlist.action";
import { connect } from "react-redux";
import { httpClient } from "../../../utils/HttpClient";
import Swal from "sweetalert2";
import PlaylistForm from "../../Playlist/playlistForm";
import moment from "moment";
import * as Yup from "yup";

const playlistValidationSchema = Yup.object().shape({
  playlist_name: Yup.string().required("กรุณากรอกชื่อ Playlist"),
});

class CreatePlaylist extends Component {
  constructor() {
    super();
    this.state = { img_playlist: null }
    this.change = this.change.bind(this);
  }

  change(img) {
    this.setState({ img_playlist: img });
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 20 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD PLAYLIST</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <PlaylistForm
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      value={this.state.value}
                      change={this.change}
                    ></PlaylistForm>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      Add
                    </button>
                    <button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      Back
                    </button>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            playlist_name: "",
            playlist_desc: "",
            playlist_image: "",
            img_playlist: "",
            playlist_display:"Y"
          }}
          validationSchema={playlistValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            let formData = new FormData();

            if (values.playlist_name) {
              formData.append("playlist_name", values.playlist_name);
            }

            if (values.playlist_desc)
              formData.append("playlist_desc", values.playlist_desc);

            if(this.state.img_playlist)
              formData.append("playlist_image", this.state.img_playlist);

            if(values.playlist_display)
              formData.append("playlist_display", values.playlist_display);

            formData.append("playlist_status", "A");
            formData.append("dbstatus", "A");

            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;

            let dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

            formData.append("created_by", username);
            formData.append("created_datetime", dateTime);

            formData.append("updated_by", username);
            formData.append("updated_datetime", dateTime);

            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            const token = localStorage.getItem("token")
            let response = await httpClient.post(server.PLAYLIST_URL, json, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              },
            });

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Add Complete",
                showConfirmButton: false,
                timer: 1500,
              });
              setSubmitting(false);
              this.props.getPlaylist();
              this.props.history.goBack();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 1500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ playlistReducer }) => ({ playlistReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePlaylist);

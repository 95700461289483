import { httpClient } from "../utils/HttpClient";

import {
  HTTP_BOOKS_LABEL_FETCHING,
  HTTP_BOOKS_LABEL_SUCCESS,
  HTTP_BOOKS_LABEL_FAILED,
  HTTP_BOOK_LABEL_FETCHING,
  HTTP_BOOK_LABEL_SUCCESS,
  HTTP_BOOK_LABEL_FAILED
} from "../constants/book";

import { server } from "../constants";

const setStateBooksLabelToSuccess = payload => ({
  type: HTTP_BOOKS_LABEL_SUCCESS,
  payload: payload
});

const setStateBooksLabelToFetching = () => ({
  type: HTTP_BOOKS_LABEL_FETCHING
});

const setStateBooksLabelToFailed = () => ({
  type: HTTP_BOOKS_LABEL_FAILED
});

const setStateLabelToSuccess = payload => ({
  type: HTTP_BOOK_LABEL_SUCCESS,
  payload: payload
});

const setStateLabelToFetching = () => ({
  type: HTTP_BOOK_LABEL_FETCHING
});

const setStateLabelToFailed = () => ({
  type: HTTP_BOOK_LABEL_FAILED
});

export const getBookLabelByBookID = (book_id, page) => {
  return async dispatch => {
    dispatch(setStateBooksLabelToFetching());
    await doGetBookLabelByBookID(dispatch, book_id, page);
  };
};

const doGetBookLabelByBookID = async (dispatch, book_id, page) => {
  try {
    const token = localStorage.getItem("token");

    const result = await httpClient.get(
      `${server.BOOK_LABEL_URL}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          book_id,
          page
        }
      }
    );

    dispatch(setStateBooksLabelToSuccess(result.data));
  } catch (err) {
    dispatch(setStateBooksLabelToFailed());
  }
};

export const getLabelByID = (id) => {
    return async (dispatch) => {
      dispatch(setStateLabelToFetching());
  
      try {
        const token = localStorage.getItem("token");

        const result = await httpClient.get(`${server.BOOK_LABEL_URL}/id/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
  
        dispatch(setStateLabelToSuccess(result.data));
      } catch(err)  {
        dispatch(setStateLabelToFailed());
      }
    };
  };
  
  export const deleteBookLabel = (id,book_id) => {
    return async (dispatch) => {
      dispatch(setStateLabelToFetching());
      const token = localStorage.getItem("token");

      const result = await httpClient.delete(
        `${server.BOOK_LABEL_URL}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if(result)
      {
        await doGetBookLabelByBookID(dispatch,book_id);
      }
    };
  };
  

export const HTTP_BOOK_PURCHASE_FETCHING = "HTTP_BOOK_PURCHASE_FETCHING";
export const HTTP_BOOK_PURCHASE_SUCCESS = "HTTP_BOOK_PURCHASE_SUCCESS";
export const HTTP_BOOK_PURCHASE_FAILED = "HTTP_BOOK_PURCHASE_FAILED";

export const HTTP_VIEW_RANK_FETCHING = "HTTP_VIEW_RANK_FETCHING";
export const HTTP_VIEW_RANK_SUCCESS = "HTTP_VIEW_RANK_SUCCESS";
export const HTTP_VIEW_RANK_FAILED = "HTTP_VIEW_RANK_FAILED";

export const HTTP_CHAPTER_RANK_FETCHING = "HTTP_CHAPTER_RANK_FETCHING";
export const HTTP_CHAPTER_RANK_SUCCESS = "HTTP_CHAPTER_RANK_SUCCESS";
export const HTTP_CHAPTER_RANK_FAILED = "HTTP_CHAPTER_RANK_FAILED";

export const HTTP_MEMBER_PURCHASE_FETCHING = "HTTP_MEMBER_PURCHASE_FETCHING";
export const HTTP_MEMBER_PURCHASE_SUCCESS = "HTTP_MEMBER_PURCHASE_SUCCESS";
export const HTTP_MEMBER_PURCHASE_FAILED = "HTTP_MEMBER_PURCHASE_FAILED";

export const HTTP_MEMBER_TRANS_FETCHING = "HTTP_MEMBER_TRANS_FETCHING";
export const HTTP_MEMBER_TRANS_SUCCESS = "HTTP_MEMBER_TRANS_SUCCESS";
export const HTTP_MEMBER_TRANS_FAILED = "HTTP_MEMBER_TRANS_FAILED";

export const HTTP_TRANS_HISTORY_FETCHING = "HTTP_TRANS_HISTORY_FETCHING";
export const HTTP_TRANS_HISTORY_SUCCESS = "HTTP_TRANS_HISTORY_SUCCESS";
export const HTTP_TRANS_HISTORY_FAILED = "HTTP_TRANS_HISTORY_FAILED";

export const HTTP_REPORT_WRITER_FETCHING = "HTTP_REPORT_WRITER_FETCHING";
export const HTTP_REPORT_WRITER_SUCCESS = "HTTP_REPORT_WRITER_SUCCESS";
export const HTTP_REPORT_WRITER_FAILED = "HTTP_REPORT_WRITER_FAILED";

export const HTTP_HISTORY_FETCHING = "HTTP_HISTORY_FETCHING";
export const HTTP_HISTORY_SUCCESS = "HTTP_HISTORY_SUCCESS";
export const HTTP_HISTORY_FAILED = "HTTP_HISTORY_FAILED";

// Report Order Page
export const HTTP_REPORT_ORDER_FETCHING = "HTTP_REPORT_ORDER_FETCHING";
export const HTTP_REPORT_ORDER_SUCCESS = "HTTP_REPORT_ORDER_SUCCESS";
export const HTTP_REPORT_ORDER_FAILED = "HTTP_REPORT_ORDER_FAILED";

// Report Payment Page
export const HTTP_REPORT_PAYMENT_FETCHING = "HTTP_PAYMENT_FETCHING";
export const HTTP_REPORT_PAYMENT_SUCCESS = "HTTP_PAYMENT_SUCCESS";
export const HTTP_REPORT_PAYMENT_FAILED = "HTTP_PAYMENT_FAILED";


// Silver Coin Transaction Report
export const HTTP_SILVERTRANS_FETCHING = "HTTP_SILVERTRANS_FETCHING";
export const HTTP_SILVERTRANS_SUCCESS = "HTTP_SILVERTRANS_SUCCESS";
export const HTTP_SILVERTRANS_FAILED = "HTTP_SILVERTRANS_FAILED";

export const HTTP_SILVERTRANSUM_FETCHING = "HTTP_SILVERTRANSUM_FETCHING";
export const HTTP_SILVERTRANSUM_SUCCESS = "HTTP_SILVERTRANSUM_SUCCESS";
export const HTTP_SILVERTRANSUM_FAILED = "HTTP_SILVERTRANSUM_FAILED";

export const HTTP_CAMPAIGNID_FETCHING = "HTTP_CAMPAIGNID_FETCHING";
export const HTTP_CAMPAIGNID_SUCCESS = "HTTP_CAMPAIGNID_SUCCESS";
export const HTTP_CAMPAIGNID_FAILED = "HTTP_CAMPAIGNID_FAILED";

export const HTTP_UPD_ORDER_FETCHING = "HTTP_UPD_ORDER_FETCHING";
export const HTTP_UPD_ORDER_SUCCESS = "HTTP_UPD_ORDER_SUCCESS";
export const HTTP_UPD_ORDER_FAILED = "HTTP_UPD_ORDER_FAILED";

export const HTTP_SUMSILVER_FETCHING = "HTTP_SUMSILVER_FETCHING";
export const HTTP_SUMSILVER_SUCCESS = "HTTP_SUMSILVER_SUCCESS";
export const HTTP_SUMSILVER_FAILED = "HTTP_SUMSILVER_FAILED";

export const HTTP_REPORT_PUBLISHER_FETCHING = "HTTP_REPORT_PUBLISHER_FETCHING";
export const HTTP_REPORT_PUBLISHER_SUCCESS = "HTTP_REPORT_PUBLISHER_SUCCESS";
export const HTTP_REPORT_PUBLISHER_FAILED = "HTTP_REPORT_PUBLISHER_FAILED";

export const HTTP_REPORT_EDITOR_FETCHING = "HTTP_REPORT_EDITOR_FETCHING";
export const HTTP_REPORT_EDITOR_SUCCESS = "HTTP_REPORT_EDITOR_SUCCESS";
export const HTTP_REPORT_EDITOR_FAILED = "HTTP_REPORT_EDITOR_FAILED";

export const HTTP_WRITER_EDITOR_FETCHING = "HTTP_WRITER_EDITOR_FETCHING";
export const HTTP_WRITER_EDITOR_SUCCESS = "HTTP_WRITER_EDITOR_SUCCESS";
export const HTTP_WRITER_EDITOR_FAILED = "HTTP_WRITER_EDITOR_FAILED";

export const HTTP_BOOK_SALE_FETCHING = "HTTP_BOOK_SALE_FETCHING";
export const HTTP_BOOK_SALE_SUCCESS = "HTTP_BOOK_SALE_SUCCESS";
export const HTTP_BOOK_SALE_FAILED = "HTTP_BOOK_SALE_FAILED";

export const HTTP_BUY_ALLBOOK_FETCHING = "HTTP_BUY_ALLBOOK_FETCHING";
export const HTTP_BUY_ALLBOOK_SUCCESS = "HTTP_BUY_ALLBOOK_SUCCESS";
export const HTTP_BUY_ALLBOOK_FAILED = "HTTP_BUY_ALLBOOK_FAILED";

export const HTTP_WRITER_DETAIL_FETCHING = "HTTP_WRITER_DETAIL_FETCHING";
export const HTTP_WRITER_DETAIL_SUCCESS = "HTTP_WRITER_DETAIL_SUCCESS";
export const HTTP_WRITER_DETAIL_FAILED = "HTTP_WRITER_DETAIL_FAILED";

export const HTTP_WITHDRAW_TRANS_FETCHING = "HTTP_WITHDRAW_TRANS_FETCHING";
export const HTTP_WITHDRAW_TRANS_SUCCESS = "HTTP_WITHDRAW_TRANS_SUCCESS";
export const HTTP_WITHDRAW_TRANS_FAILED = "HTTP_WITHDRAW_TRANS_FAILED";

export const HTTP_ฺฺBOOK_PUBLISH_FETCHING = "HTTP_ฺฺBOOK_PUBLISH_FETCHING";
export const HTTP_BOOK_PUBLISH_SUCCESS = "HTTP_BOOK_PUBLISH_SUCCESS";
export const HTTP_BOOK_PUBLISH_FAILED = "HTTP_BOOK_PUBLISH_FAILED";

export const HTTP_ฺฺPUBLISH_FETCHING = "HTTP_ฺฺPUBLISH_FETCHING";
export const HTTP_PUBLISH_SUCCESS = "HTTP_PUBLISH_SUCCESS";
export const HTTP_PUBLISH_FAILED = "HTTP_PUBLISH_FAILED";

export const HTTP_REPORT_GIFT_SUCCESS = "HTTP_REPORT_GIFT_SUCCESS";
export const HTTP_REPORT_GIFT_FETCHING = "HTTP_REPORT_GIFT_FETCHING";
export const HTTP_REPORT_GIFT_FAILED = "HTTP_REPORT_GIFT_FAILED";

export const HTTP_REPORT_INVITE_SUCCESS = "HTTP_REPORT_INVITE_SUCCESS";
export const HTTP_REPORT_INVITE_FETCHING = "HTTP_REPORT_INVITE_FETCHING";
export const HTTP_REPORT_INVITE_FAILED = "HTTP_REPORT_INVITE_FAILED";

export const HTTP_REPORT_WHOLE_BOOK_SUCCESS = "HTTP_REPORT_WHOLE_BOOK_SUCCESS";
export const HTTP_REPORT_WHOLE_BOOK_FETCHING = "HTTP_REPORT_WHOLE_BOOK_FETCHING";
export const HTTP_REPORT_WHOLE_BOOK_FAILED = "HTTP_REPORT_WHOLE_BOOK_FAILED";

import { httpClient } from "../utils/HttpClient";

import {
    HTTP_PUBLISH_SUCCESS,
    HTTP_ฺฺPUBLISH_FETCHING,
    HTTP_PUBLISH_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateReportPublishBookToSuccess = (payload) => ({
  type: HTTP_PUBLISH_SUCCESS,
  payload: payload,
});

const setStateReportPublishBookToFetching = () => ({
  type: HTTP_ฺฺPUBLISH_FETCHING,
});

const setStateReportPublishBookToFailed = () => ({
  type: HTTP_PUBLISH_FAILED,
});

export const getReportPublishBook = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportPublishBookToFetching());
    await doGetReportPublishBook(dispatch, value);
  };
};

const doGetReportPublishBook = async (dispatch, value) => {
  try {
    let param = {};

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00:00";
      else value.start_date = "";

      if (value.end_date) value.end_date = value.end_date + " 23:59:59";
      else
        value.end = moment(new Date())
          .format("YYYY-MM-DD 23:59:59");

      param = value
    } else {
      param = {
        page: "",
      };
    }

    const token = localStorage.getItem("token");
    const result = await httpClient.get(`${server.REPORT_URL}/publish/book`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    });
    dispatch(setStateReportPublishBookToSuccess(result.data));
  } catch (err) {
    dispatch(setStateReportPublishBookToFailed());
  }
};

import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_CAMPAIGNS_SUCCESS,
  HTTP_CAMPAIGNS_FETCHING,
  HTTP_CAMPAIGNS_FAILED,

  HTTP_CAMPAIGN_SUCCESS,
  HTTP_CAMPAIGN_FETCHING,
  HTTP_CAMPAIGN_FAILED,
  server
} from "../constants";

const setStateCampaignsToSuccess = (payload) => ({
  type: HTTP_CAMPAIGNS_SUCCESS,
  payload: payload,
});

const setStateCampaignsToFetching = () => ({
  type: HTTP_CAMPAIGNS_FETCHING,
});

const setStateCampaignsToFailed = () => ({
  type: HTTP_CAMPAIGNS_FAILED,
});

const setStateCampaignToSuccess = payload => ({
  type: HTTP_CAMPAIGN_SUCCESS,
  payload: payload
});

const setStateCampaignToFetching = () => ({
  type: HTTP_CAMPAIGN_FETCHING
});

const setStateCampaignToFailed = () => ({
  type: HTTP_CAMPAIGN_FAILED
});


export const getAllCampaign = (page) => {
  return async dispatch => {
    dispatch(setStateCampaignsToFetching());
    await doGetCampaign(dispatch,page);
  };
};

const doGetCampaign = async (dispatch,page) => {
  try {
    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.CAMPAIGN_URL}`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page
      }
    })
    dispatch(setStateCampaignsToSuccess(result.data));
  } catch(err)  {
    dispatch(setStateCampaignsToFailed());
  }
};


export const getCampaignById = (campaign_id)=>{
  return dispatch=>{

    dispatch(setStateCampaignToFetching())
    const token = localStorage.getItem("token");

    httpClient
    .get(`${server.CAMPAIGN_URL}/id/${campaign_id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(result => {
      dispatch(setStateCampaignToSuccess(result.data));
    })
    .catch(err => {
      dispatch(setStateCampaignToFailed());
    });
  }
}

export const deleteCampaign = (campaign_id,page) => {
  return async dispatch => {
    dispatch(setStateCampaignToFetching());
    const token = localStorage.getItem("token");

    const response = await httpClient.delete(`${server.CAMPAIGN_URL}/${campaign_id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    if (response.status === 201 || response.status === 200) {
      alert('Delete Complete')
      await doGetCampaign(dispatch,page);
    }
  };
};

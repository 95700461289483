import React, { Component } from "react";
import { connect } from "react-redux";
import { Skeleton } from 'antd';

class BookCharacterItemLoading extends Component {
  render() {
    return (
      <>
        <div 
          className="card shadow-none border mr-3 chat-character-card pointer mb-3"
        >
          <div className="card-body py-2 px-3 d-flex align-items-center">
            <div className="mr-3">
              <Skeleton.Avatar active={true} shape="circle" />
            </div>
            
            <div className="flex-fill">
              <Skeleton.Button active={true} size="small" shape="default" />
              <Skeleton.Button active={true} size="small" shape="default" className="mt-2" style={{ width: 120 }}/>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = () => ({
  
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(BookCharacterItemLoading);

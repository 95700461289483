import React, { Component } from "react";
import * as actions from "../../../actions/reportOrder.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
  FormSelect,
  FormInput,
  Button,
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";

class ReportSilverCoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      start_date: "",
      end_date: "",
      payment_type: "",
      order_status: "",
      member_name: "",
      member_email: "",
    };
  }

  async componentDidMount() {
    await this.getReportSilverCoin(this.state.currentPage);
    if (this.props.reportOrderReducer.orders) {
      this.setState({ orders: this.props.reportOrderReducer.orders });
    }

    //this.props.getReportSummarySilverCoin();
    this.getReportSummarySilverCoin();
  }

  async getReportSilverCoin(page) {
    this.setState({ loading: true });

    const value = {};
    value.start = this.state.start_date;
    value.end = this.state.end_date;
    value.payment_type = this.state.payment_type;
    value.order_status = this.state.order_status;
    value.member_name = this.state.member_name;
    value.member_email = this.state.member_email;
    value.page = page;
    await this.props.getReportSilverCoin(value);

    const { orders, total, isFetching } = this.props.reportOrderReducer;
    if (orders && !isFetching) {
      const totalPages = Math.ceil(total / this.state.perPage);

      this.setState({
        orders,
        totalPages,
        loading: false,
      });
    }
  }

  async getReportSummarySilverCoin() {
    const value = {};
    value.start = this.state.start_date;
    value.end = this.state.end_date;
    value.payment_type = this.state.payment_type;
    value.order_status = this.state.order_status;
    value.member_name = this.state.member_name;
    value.member_email = this.state.member_email;

    await this.props.getReportSummarySilverCoin(value);
  }

  createSummary = () => {
    try {
      const {
        resultSummary,
        isFetchingSummary,
      } = this.props.reportSummaryReducer;
      return (
        !isFetchingSummary &&
        resultSummary != null &&
        resultSummary.map((item) => (
          <tr key={item.package_price}>
            <td className="text-center">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.package_price)}
            </td>
            <td className="text-center">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.quantity)}
            </td>
            <td className="text-center">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.amount)}
            </td>
          </tr>
        ))
      );
    } catch (e) {}
  };

  createSummaryFooter = () => { //Grand Total
    try {
      const {
        resultSummary,
        isFetchingSummary,
      } = this.props.reportSummaryReducer;

      const amountTotal = resultSummary.reduce(
        (totalAmount, data) => totalAmount + parseInt(data.amount),
        0
      );
      const itemTotal = resultSummary.reduce(
        (totalIten, data) => totalIten + parseInt(data.quantity),
        0
      );

      if (!isFetchingSummary && resultSummary != null) {
        return (
          <tr>
            <td className="text-center font-weight-bold">Grand Total</td>
            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(itemTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(amountTotal)}
            </td>
          </tr>
        );
      }
    } catch (e) {}
  };

  formatDecimal(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatOrderStatus(value) {
    if (value === "A") return "SUCCESS";
    else if (value === "W") return "WAITING";
  }

  formatDateTime(value) {
    if (value) {
      return moment(value)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  renderTable = () => {
    return (
      <React.Fragment>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">Name</th>
              <th className="text-center">Email</th>
              <th className="text-center">Phone</th>
              <th className="text-center">Facebook Email</th>
              <th className="text-center">Status</th>
              <th className="text-center">Charge</th>
              <th className="text-center">Coin</th>
              <th className="text-center">Type</th>
              <th className="text-center">Payment Date</th>
              <th className="text-center">Payment Result</th>
            </tr>
          </thead>
          <tbody>
            {this.state.orders.map((order, index) => {
              return (
                <tr key={order.order_id}>
                  <td className="align-middle">{order.member_name}</td>
                  <td className="align-middle">{order.member_email}</td>
                  <td className="text-center align-middle">
                    {order.member_phone}
                  </td>
                  <td className="align-middle">
                    {order.member_facebook_email}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatOrderStatus(order.order_status)}
                  </td>
                  <td className="text-center align-middle" width="120">
                    {this.formatDecimal(order.charge)}
                  </td>

                  <td className="text-center align-middle" width="120">
                    {this.formatDecimal(order.coin)}
                  </td>
                  <td className="text-center align-middle" width="100">
                    {order.payment_type}
                  </td>
                  <td className="text-center align-middle" width="120">
                    {this.formatDateTime(order.payment_datetime)}
                  </td>
                  <td className="text-center align-middle" width="80">
                    {order.payment_result}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Row className="ml-2">
          <Col sm="5" className="sm-5">
            <Card small className="lo-stats h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Summary</h6>
                <div className="block-handle" />
              </CardHeader>

              <CardBody className="p-0">
                <Container fluid className="px-0">
                  <table className="table mb-0 table-hover">
                    <thead className="py-2 text-semibold border-bottom">
                      <tr>
                        <th className="text-center">Price</th>
                        <th className="text-center">Items</th>
                        <th className="text-center">Total</th>
                      </tr>
                    </thead>
                    <tbody>{this.createSummary()}</tbody>
                    <tfoot>{this.createSummaryFooter()}</tfoot>
                  </table>
                </Container>
              </CardBody>
            </Card>
          </Col>
          <Col sm="5" className="sm-5"></Col>
        </Row>
      </React.Fragment>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getReportSilverCoin(page);
  }

  async exportData() {
    await this.getReportSilverCoin("");

    let report_silver_coin = this.state.orders.map((item, idx) => {
      return [
        item.order_id,
        item.order_code,
        item.member_name,
        item.member_email,
        item.member_phone,
        item.member_facebook_email,
        this.formatOrderStatus(item.order_status),
        this.formatDecimal(item.charge),
        this.formatDecimal(item.coin),
        item.payment_type,
        this.formatDateTime(item.payment_datetime),
        item.payment_result,
      ];
    });

    report_silver_coin = [
      [
        "Order ID",
        "Order Code",
        "Name",
        "Email",
        "Phone",
        "FB Email",
        "Status",
        "Package",
        "Charge",
        "Coin",
        "Payment Type",
        "Payment Date",
        "Result",
      ],
      ...report_silver_coin,
    ];

    if (report_silver_coin) {
      this.downloadExportData(report_silver_coin);
      this.getReportSilverCoin(0);
    }
  }

  downloadExportData(report_silver_coin) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report_silver_coin);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["silver-coin"]);
    XLSX.writeFile(workBook, `report-silver-coin.xlsx`);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Report Silver Coin</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        this.exportData();
                      }}
                      type="button"
                      className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    payment_type: "",
                    order_status: "",
                    member_name: "",
                    member_email: "",
                    page: "",
                  }}
                  onSubmit={async (values) => {
                    let startDate = null;
                    let endDate = null;
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    this.setState({
                      start_date: startDate,
                      end_date: endDate,
                      payment_type: values.payment_type,
                      order_status: values.order_status,
                      member_name: values.member_name,
                      member_email: values.member_email,
                    });

                    this.getReportSilverCoin(this.state.currentPage);
                    this.getReportSummarySilverCoin();
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col md="2" className="form-group">
                            <FormGroup>
                              <label>Payment Type</label>
                              <FormSelect
                                name="payment_type"
                                id="payment_type"
                                placeholder=""
                                onChange={handleChange}
                                value={values.payment_type}
                              >
                                <option value="">Select</option>
                                <option value="transfer">Transfer</option>
                                <option value="promptpay">Promptpay</option>
                                <option value="omise">omise</option>
                                <option value="ios">ios</option>
                                <option value="andriod">andriod</option>
                              </FormSelect>
                            </FormGroup>
                          </Col>
                          <Col md="2" className="form-group">
                            <FormGroup>
                              <label>Order Status</label>
                              <FormSelect
                                name="order_status"
                                id="order_status"
                                placeholder=""
                                onChange={handleChange}
                                value={values.order_status}
                              >
                                <option value="">Select</option>
                                <option value="W">WAITING</option>
                                <option value="A">SUCCESS</option>
                              </FormSelect>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4">
                            <FormInput
                              name="member_name"
                              id="member_name"
                              placeholder="Name"
                              onChange={handleChange}
                              value={values.member_name}
                            />
                          </Col>
                          <Col md="4">
                            <FormInput
                              name="member_email"
                              id="member_email"
                              placeholder="Email"
                              onChange={handleChange}
                              value={values.member_email}
                            />
                          </Col>
                          <Col md="2">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm ml-4"
                              style={{
                                marginRight: 10,
                                color: "white",
                                width: 100,
                              }}
                            >
                              Search
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
              </CardBody>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.orders.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportOrderReducer, reportSummaryReducer }) => ({
  reportOrderReducer,
  reportSummaryReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSilverCoin);

import React, { Component } from "react";
import { Row, Col, FormInput, FormGroup, FormTextarea,FormSelect } from "shards-react";
import { httpClient } from "../../utils/HttpClient";
import { server } from "../../constants";

export default class playlistFrom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img_playlist: "",
    };
    this.uploadImage = this.uploadImage.bind(this);
  }

  async uploadImage(image) {
    // Call Api For Upload Image
    if (image) {
      var formDataImg = new FormData();
      var imagefile = image;
      formDataImg.append("upload", imagefile);

      let response_img = await httpClient.post(server.IMG_URL, formDataImg, {
        headers: {
          processData: false,
          enctype: "multipart/form-data",
        },
      });

      if (response_img.status === 201 || response_img.status === 200) {
        if (response_img.data.uploaded === 1) {
          this.setState({
            img_playlist: response_img.data.url,
          });
          this.props.change(response_img.data.url);
        }
      }
    }
  }

  render() {
    const { values, handleChange, errors, touched, setFieldValue,handleBlur } = this.props;

    const ImagePlaylist = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="playlist_image"
            name="playlist_image"
            src={value}
          />
        );
      } else {
        return (
          <img
            style={{ maxWidth: 750 }}
            id="playlist_image"
            name="playlist_image"
            src={this.state.img_playlist}
          />
        );
      }
    };

    return (
      <React.Fragment>
        <fieldset>
          <Row form>
            <Col md="6" className="form-group">
              <label>ชื่อ Playlist</label>
              <FormInput
                placeholder=""
                name="playlist_name"
                id="playlist_name"
                placeholder="Name"
                onChange={handleChange}
                value={values.playlist_name}
              />
              {errors.playlist_name && touched.playlist_name ? (
                <div className="font-12 text-primary mt-1">
                  {errors.playlist_name}
                </div>
              ) : null}
            </Col>
          </Row>

          <FormGroup>
            <label>รายละเอียด Playlist</label>
            <FormTextarea
              rows="5"
              name="playlist_desc"
              id="playlist_desc"
              placeholder=""
              onChange={handleChange}
              value={values.playlist_desc}
            />
          </FormGroup>

          <Row form>
            <Col className="form-group">
              <label>
                Playlist Image <span className=" text-danger"> </span>
              </label>
              <input
                id="img_web"
                name="img_web"
                type="file"
                onChange={(event) => {
                  values.playlist_image = "";
                  setFieldValue("img_playlist", event.currentTarget.files[0]);
                  this.uploadImage(event.currentTarget.files[0]);
                }}
                className="form-control"
              />
              <div sm="10" className="ml-2 mt-3 text-center">
                <ImagePlaylist value={values.playlist_image} />
              </div>
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <FormGroup>
                <label>แสดงข้อมูลในส่วน Playlist</label>
                <FormSelect
                  id="playlist_display"
                  name="playlist_display"
                  value={values.playlist_display}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="Y" label="Yes" />
                  <option value="N" label="No" />
                </FormSelect>
              </FormGroup>
            </Col>
          </Row>
        </fieldset>
      </React.Fragment>
    );
  }
}

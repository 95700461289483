import React, { Component } from "react";
import * as actions from "../../actions/book.action";
import { getWriterName } from "../../actions/member.action";
import { getCategory } from "../../actions/category.action";
import { getChapterByBookID } from "../../actions/book_chapter.action";
import { Formik } from "formik";
import Autosuggest from 'react-autosuggest';
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { httpClient } from "../../utils/HttpClient";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormInput,
  FormTextarea,
  FormSelect,
  FormCheckbox,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker
} from "shards-react";
import { Select } from 'antd';
import TimePicker from "rc-time-picker";

const { Option } = Select;

class PushNotification extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      writers: [],
      suggestions: [],
      pushTitle: '',
      pushBody: '',
      pushAction: '1',
      pushTarget: 'all',
      bookId: '',
      bookTitle: '',
      writers: '',
      writerName: '',
      categoryId: '1',
      categories: [],
      chapters: [],
      chapterId: '',
      email: '',
      value: '',
      ios: true,
      android: true,
      website: true,
      sendDate: '',
      sendTime: '',
      goldCoinCondition: false,
      silverCoinCondition: false,
      bookShelfCondition: false,
      followCondition: false,
      bookReadCondition: false,
      chapterReadCondition: false,
      silverCoinExpiredCondition: false,
      silverCoinExpiredDay: '7',
      action: ''
    }

    this.handleTimePickerChange = this.handleTimePickerChange.bind(this);
  }

  async componentDidMount() {
    this.props.getBooks().then(() => {
      this.setState({
        books: this.props.bookReducer.books
      })
    })

    this.props.getWriterName().then(() => {
      this.setState({
        writers: this.props.memberReducer.writers
      })
    })

    this.props.getCategory().then(() => {
      this.setState({
        categories: this.props.categoryReducer.result
      })
    })
  }

  handleTimePickerChange(value) {
    this.setState({
      sendTime: value ? value.toDate() : ''
    })
  }

  handleActionChange(e, handleChange) {
    let suggestions = []

    if(e.target.value === '2') {
      suggestions = this.state.books
    } else if(e.target.value === '6') {
      suggestions = this.state.writers
    }

    this.setState({
      action: e.target.value,
      suggestions
    })
    
    handleChange(e)
  }

  renderTargetOption(action)  {
    let optionList = [
      {
        value: 'all',
        label: 'ทั้งหมด'
      },
      {
        value: 'condition',
        label: 'เงื่อนไข'
      },
      {
        value: 'manual',
        label: 'กำหนดเอง'
      }
    ]

    let optionElements = optionList.map((item, index) => {
      return <option value={item.value} key={index}>{item.label}</option>
    })

    return optionElements
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    if(this.state.action === '2') {
      this.props.getChapterByBookID(suggestion.book_id).then(() => {
        const chapters = this.props.bookChapterReducer.chapters.map(item => {
          return {
            label: `${item.chaptor_title} ${item.book_chaptor_name}`,
            value: item.book_chaptor_id.toString()
          }
        })
        
        this.setState({
          chapters
        })
      })

      this.setState({
        bookId: suggestion.book_id,
        bookTitle: suggestion.book_title
      })
    } else if(this.state.action === '6') {
      this.setState({
        writerName: suggestion.writer_name,
      })
    }
  }

  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    let result = []

    if(this.state.action === '2') {
      result = this.state.books.filter(book =>
        inputLength !== 0 ? book.book_title.toLowerCase().includes(inputValue) : true
      )
    } else if(this.state.action === '6') {
      result = this.state.writers.filter(writer =>
        inputLength !== 0 ? writer.writer_name.toLowerCase().includes(inputValue) : true
      )
    }

    if(inputLength === 0) {
      this.setState({
        bookId: '',
        bookTitle: '',
        writerName: ''
      })
    }
    
    return result
  }

  renderSuggestion = suggestion => {
    let name = ''

    if(this.state.action === '2') {
      name = suggestion.book_title 
    } else if(this.state.action === '6') {
      name = suggestion.writer_name
    }

    return (
      <div>
        { name }
      </div>
    ) 
  }

  shouldRenderSuggestions() {
    return true
  }

  onKeywordChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  getSuggestionValue = suggestion => {
    let value = ''

    if(this.state.action === '2') {
      value = suggestion.book_title
    } else if(this.state.action === '6') {
      value = suggestion.writer_name
    }

    return value
  }

  handleCheckbox(e, name) {
    const newState = {};
    newState[name] = !this.state[name];
    this.setState({ ...this.name, ...newState });
  }

  handleChapterChange(value) {
    this.setState({
      chapterId: value
    })
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onKeywordChange
    }

    const DatePickerField = ({ name, value, onChange }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };

    return (
      <Container fluid className="main-content-container px-4">
        <Card style={{marginTop: '30px'}}>
          <CardHeader className="border-bottom">
            <h6 className="m-0">Push Notification</h6>
          </CardHeader>
          <CardBody className="">
            <React.Fragment>
              <Formik
                initialValues={this.state}
                validate={(values, props) => {
                  const errors = {};
                
                  if (!values.pushTitle) {
                    errors.pushTitle = 'กรุณากรอกหัวข้อ';
                  } 

                  if (!values.pushBody) {
                    errors.pushBody = 'กรุณากรอกข้อความ';
                  } 

                  if(values.pushAction === '2')  { //Book Detail
                    if (!this.state.bookId) { 
                      errors.bookTitle = 'กรุณาเลือกหนังสือ';
                    } 
                  }

                  if(values.pushTarget === 'manual')  {
                    if (!values.email) {
                      errors.email = 'กรุณากรอกอีเมล';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                      errors.email = 'กรุณากรอกอีเมลให้ถูกต้อง';
                    }
                  }
                 
                  return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const confirmResult = await Swal.fire({
                    text: 'ยืนยันการส่งข้อความ',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ส่งเลย',
                    cancelButtonText: 'ยกเลิก',
                    width: 300
                  })

                  if(confirmResult.value) {
                    let formData = new FormData();
                    let categotyName = null
                    let sendTime = null
                    
                    if(values.pushAction === '2') {
                      values.bookId = this.state.bookId
                      values.bookTitle = this.state.bookTitle
                    } else if(values.pushAction === '6') {
                      values.writerName = this.state.writerName
                    } else if(values.pushAction === '7') {
                      categotyName = this.state.categories.filter(item => item.category_id == values.categoryId)[0].category_name
                    }

                    if(values.sendDate) {
                      sendTime = values.sendDate
                    }

                    if(this.state.sendTime) {
                      sendTime = this.state.sendTime
                      sendTime.setDate(values.sendDate.getDate())
                      sendTime.setMonth(values.sendDate.getMonth())
                      sendTime.setFullYear(values.sendDate.getFullYear())
                    }

                    //Set Checkbox Value
                    values.ios = this.state.ios
                    values.android = this.state.android
                    values.goldCoinCondition = this.state.goldCoinCondition
                    values.silverCoinCondition = this.state.silverCoinCondition
                    values.silverCoinExpiredCondition = this.state.silverCoinExpiredCondition
                    values.bookShelfCondition = this.state.bookShelfCondition
                    values.followCondition = this.state.followCondition
                    values.bookReadCondition = this.state.bookReadCondition
                    values.chapterReadCondition = this.state.chapterReadCondition
                    
                    formData.append("pushTitle", values.pushTitle);
                    formData.append("pushBody", values.pushBody);
                    formData.append("pushAction", values.pushAction);
                    formData.append("pushTarget", values.pushTarget);
                    formData.append("bookId", values.bookId);
                    formData.append("bookTitle", values.bookTitle);
                    formData.append("writerName", values.writerName);
                    formData.append("categoryId", values.categoryId);
                    formData.append("categoryName", categotyName);
                    formData.append("chapterId", this.state.chapterId);
                    formData.append("email", values.email);
                    formData.append("ios", values.ios ? 'Y' : 'N');
                    formData.append("android", values.android ? 'Y' : 'N');
                    formData.append("website", values.website ? 'Y' : 'N');
                    formData.append("goldCoinCondition", values.goldCoinCondition ? 'Y' : 'N');
                    formData.append("silverCoinCondition", values.silverCoinCondition ? 'Y' : 'N');
                    formData.append("bookShelfCondition", values.bookShelfCondition ? 'Y' : 'N');
                    formData.append("followCondition", values.followCondition ? 'Y' : 'N');
                    formData.append("bookReadCondition", values.bookReadCondition ? 'Y' : 'N');
                    formData.append("chapterReadCondition", values.chapterReadCondition ? 'Y' : 'N');
                    formData.append("silverCoinExpiredCondition", values.silverCoinExpiredCondition ? 'Y' : 'N');
                    formData.append("silverCoinExpiredDay", values.silverCoinExpiredDay ? 'Y' : 'N');
                    formData.append("sendTime", sendTime);
                    
                    var object = {};
                    formData.forEach((value, key) => {
                      object[key] = value;
                    });
                    var json = JSON.stringify(object);
                      
                    const token = localStorage.getItem("token")

                    if(sendTime) {
                      let response = await httpClient.post(`admin/member/push-notification/schedule`, json, {
                        headers: {
                          "Content-Type": "application/json",
                          "Authorization": `Bearer ${token}`,
                        },
                      });
                      
                      Swal.fire({
                        icon: "success",
                        title: "บันทึกเรียบร้อยแล้ว",
                        html: `<span style={{font-size:'14px'}}>จำนวน User ที่ได้รับ: <b>${response.data}</b> คน</span>`,
                        showConfirmButton: false,
                        timer: 3000,
                      });
                    } else {
                      let response = await httpClient.post(`admin/member/push-notification`, json, {
                        headers: {
                          "Content-Type": "application/json",
                          "Authorization": `Bearer ${token}`,
                        },
                      });

                      if (response.status === 201 || response.status === 200) {
                        Swal.fire({
                          icon: "success",
                          title: "ข้อความถูกส่งเรียบร้อยแล้ว",
                          html: `<span style={{font-size:'14px'}}>จำนวน User ที่ได้รับ: <b>${response.data}</b> คน</span>`,
                          showConfirmButton: false,
                          timer: 3000,
                        });
                        setSubmitting(false);
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "เกิดข้อผิดพลาดในการส่งข้อความ!",
                          timer: 1500,
                        });
                      }
                    }
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                  isSubmitting,
                  validateField,
                  errors,
                  touched
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label>หัวข้อ</label>
                        <FormInput
                          name="pushTitle"
                          id="pushTitle"
                          onChange={handleChange}
                          value={values.pushTitle}
                        />
                        {errors.pushTitle && touched.pushTitle ? (
                          <div className="font-12 text-primary mt-1">{errors.pushTitle}</div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="12" className="form-group">
                        <label>ข้อความ</label>
                        <FormTextarea
                          rows="3"
                          name="pushBody"
                          id="pushBody"
                          onChange={handleChange}
                          value={values.pushBody}
                        />
                        {errors.pushBody && touched.pushBody ? (
                          <div className="font-12 text-primary mt-1">{errors.pushBody}</div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="12" className="form-group">
                        <label>Action <span className="text-muted" style={{fontSize: 12}}>(หน้าที่จะแสดงหลังจาก User แตะที่ Push Notification)</span></label>
                        <FormSelect
                          name="pushAction"
                          id="pushAction" 
                          onChange={e => { this.handleActionChange(e, handleChange) }}                        
                          value={values.pushAction}
                        >
                          <option value="1">หน้าแรก</option>
                          <option value="2">หนังสือ</option>
                          <option value="3">เติมเงิน</option>
                          <option value="4">ชั้นหนังสือ</option>
                          <option value="5">โปรไฟล์</option>
                          <option value="6">นักเขียน</option>
                          <option value="7">หมวดหมู่</option>
                        </FormSelect>
                      </Col>
                    </Row>
                    
                    { values.pushAction === '2' &&
                      <>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label>ชื่อหนังสือ</label>
                            
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              shouldRenderSuggestions={this.shouldRenderSuggestions}
                              onSuggestionSelected={this.onSuggestionSelected}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                            />
                            {errors.bookTitle && touched.bookTitle ? (
                              <div className="font-12 text-primary mt-1">{errors.bookTitle}</div>
                            ) : null}
                          </Col>
                        </Row>

                        <Row form>
                          <Col md="12" className="form-group">
                            <label>ชื่อตอน</label>
                            <Select
                              name="chapterId"
                              id="chapterId" 
                              options={this.state.chapters}
                              className="w-100 font-12"
                              disabled={this.state.chapters.length === 0}
                              value={this.state.chapterId}
                              onChange={value => { this.handleChapterChange(value) }}
                            >
                              {
                                this.state.chapters.map(item => {
                                  return <Option value={item.book_chaptor_id} key={item.book_chaptor_id}>{ item.chaptor_title }</Option>
                                })
                              }
                            </Select>
                          </Col>
                        </Row>
                      </>
                    }

                    { values.pushAction === '6' &&
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>ชื่อนักเขียน</label>
                          
                          <Autosuggest
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                            onSuggestionSelected={this.onSuggestionSelected}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}
                          />
                          {errors.writerName && touched.writerName ? (
                            <div className="font-12 text-primary mt-1">{errors.writerName}</div>
                          ) : null}
                        </Col>
                      </Row>
                    }

                    { values.pushAction === '7' &&
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>หวมดหมู่</label>
                          <FormSelect
                            name="categoryId"
                            id="categoryId" 
                            onChange={handleChange}
                            value={values.categoryId}
                          >
                            { 
                              this.state.categories.map(item => {
                                return <option value={item.category_id} key={item.category_id}>{ item.category_name }</option>
                              }) 
                            }
                          </FormSelect>
                        </Col>
                      </Row>
                    }

                    <div className="form-group mt-2">
                      <label>วันที่และเวลาในการส่ง Push notification <span className="text-muted" style={{fontSize: 12}}>(ไม่ต้องกรอกหากต้องการส่งทันที)</span></label>
                      <Row form>
                        <Col md="3" className="form-group">
                          
                          <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                            <DatePickerField
                              size="sm"
                              name="sendDate"
                              onChange={setFieldValue}
                              value={values.sendDate}
                              dropdownMode="select"
                              className="text-center"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <i className="material-icons">&#xE916;</i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>

                        <Col md="3">
                          <TimePicker
                            showSecond={false}
                            name="sendTime"
                            format={"HH:mm"}
                            className="time-picker-block"
                            minuteStep={15}
                            onChange={this.handleTimePickerChange}
                          />
                        </Col>
                      </Row>
                    </div>

                    <hr className="mt-3 mb-4" style={{marginLeft: '-1.875rem', marginRight: '-1.875rem'}}/>

                    <Row form>
                      <Col md="12" className="form-group">
                        <label>Target User <span className="text-muted" style={{fontSize: 12}}>(User ที่จะได้รับ Push Notification)</span></label>
                        <FormSelect
                          name="pushTarget"
                          id="pushTarget" 
                          onChange={e => { handleChange(e) }}
                          value={values.pushTarget}
                        >
                          { this.renderTargetOption(values.pushAction) }
                        </FormSelect>
                      </Col>
                    </Row>

                    <Row form>
                      <Col md="12" className="form-group">
                        <label>Platform</label>
                        <div>
                          <FormCheckbox
                            inline
                            name="ios"
                            id="ios" 
                            checked={this.state.ios}
                            onChange={e => this.handleCheckbox(e, 'ios')}
                          >
                            IOS
                          </FormCheckbox>
                          <FormCheckbox
                            inline
                            name="android"
                            id="android" 
                            checked={this.state.android}
                            onChange={e => this.handleCheckbox(e, 'android')}
                          >
                            Android
                          </FormCheckbox>
                          <FormCheckbox
                            inline
                            name="android"
                            id="android" 
                            checked={this.state.website}
                            onChange={e => this.handleCheckbox(e, 'website')}
                          >
                            Website
                          </FormCheckbox>
                        </div>
                      </Col>
                    </Row>

                    { values.pushTarget === 'condition' ?
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>เงื่อนไข</label>

                          <FormCheckbox
                            name="goldCoinCondition"
                            id="goldCoinCondition" 
                            checked={this.state.goldCoinCondition}
                            onChange={e => this.handleCheckbox(e, 'goldCoinCondition')}
                          >
                            User ที่มีเหรียญทองแต่ไม่เคยซื้อ
                          </FormCheckbox>

                          <FormCheckbox
                            name="silverCoinCondition"
                            id="silverCoinCondition" 
                            checked={this.state.silverCoinCondition}
                            onChange={e => this.handleCheckbox(e, 'silverCoinCondition')}
                          >
                            User ที่มีเหรียญเงินแต่ไม่เคยซื้อ
                          </FormCheckbox>
                          
                          <div className="d-flex align-items-center">
                            <FormCheckbox
                              name="silverCoinExpiredCondition"
                              id="silverCoinExpiredCondition" 
                              checked={this.state.silverCoinExpiredCondition}
                              onChange={e => this.handleCheckbox(e, 'silverCoinExpiredCondition')}
                            >
                              User ที่เหรียญเงินจะหมดอายุใน
                            </FormCheckbox>
                            <FormSelect
                              name="silverCoinExpiredDay"
                              id="silverCoinExpiredDay"
                              placeholder=""
                              disabled={!this.state.silverCoinExpiredCondition}
                              onChange={handleChange}
                              value={values.silverCoinExpiredDay}
                              style={{ width: 100, marginLeft: 15, marginBottom: '0.5rem' }}
                            >
                              <option value="7">7 วัน</option>
                              <option value="15">15 วัน</option>
                              <option value="30">30 วัน</option>
                            </FormSelect>
                          </div>

                          { values.pushAction === '2' ?
                            <>
                              <FormCheckbox
                                name="bookShelfCondition"
                                id="bookShelfCondition" 
                                disabled={!this.state.bookId}
                                checked={this.state.bookShelfCondition}
                                onChange={e => this.handleCheckbox(e, 'bookShelfCondition')}
                              >
                                User ที่เพิ่มหนังสือเล่มนี้ไปที่ชั้นหนังสือ
                              </FormCheckbox>
                              <FormCheckbox
                                name="followCondition"
                                id="followCondition" 
                                disabled={!this.state.bookId}
                                checked={this.state.followCondition}
                                onChange={e => this.handleCheckbox(e, 'followCondition')}
                              >
                                User ที่ติดตามนักเขียนที่เขียนหนังสือเล่มนี้
                              </FormCheckbox>
                              <FormCheckbox
                                name="bookReadCondition"
                                id="bookReadCondition" 
                                disabled={!this.state.bookId}
                                checked={this.state.bookReadCondition}
                                onChange={e => this.handleCheckbox(e, 'bookReadCondition')}
                              >
                                User ที่เคยเปิดอ่านหนังสือเล่มนี้
                              </FormCheckbox>
                              <FormCheckbox
                                name="chapterReadCondition"
                                id="chapterReadCondition" 
                                disabled={!this.state.chapterId}
                                checked={this.state.chapterReadCondition}
                                onChange={e => this.handleCheckbox(e, 'chapterReadCondition')}
                              >
                                User ที่เคยเปิดอ่านหนังสือตอนนี้
                              </FormCheckbox>
                            </> : null
                          }
                        </Col>
                      </Row> : null
                    }

                    { values.pushTarget === 'manual' ?
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>User Email <span className="text-muted" style={{fontSize: 12}}>(ต้องเคย login ผ่าน mobile application อย่างน้อย 1 ครั้ง)</span></label>
                          <FormInput
                            name="email"
                            id="email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {errors.email && touched.email ? (
                          <div className="font-12 text-primary mt-1">{errors.email}</div>
                        ) : null}
                        </Col>
                      </Row> : null
                    }

                    <div className="text-right mt-5">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary"
                        style={{ color: "white", width: 100 }}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </React.Fragment>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = ({ bookReducer, appReducer, memberReducer, categoryReducer, bookChapterReducer  }) => ({
  appReducer,
  bookReducer,
  memberReducer,
  categoryReducer,
  bookChapterReducer
});

const mapDispatchToProps = {
  ...actions,
  getWriterName,
  getCategory,
  getChapterByBookID
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNotification);

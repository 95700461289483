import React, { Component } from "react";
import { getReportChapterRank } from "../../../actions/reportChapterRank.action";
import { getBooks } from "../../../actions/book.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import Autosuggest from "react-autosuggest";
import MenuReport from "../menuReport";
import ContentPagination from "../../Layout/Common/Pagination";

class reportChapterRank extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      chapters: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      value: "",
      currentPage: 0,
      currentFilter: null
    };
  }

  async componentDidMount() {
    this.getReportData({
      page: this.state.currentPage
    });

    await this.props.getBooks()

    this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  formatAmount(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatTotalView(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.total_view);
  }

  formatMemberView(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.member_view);
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportChapterRankReducer;
      const csv_name = "ChapterRankReport.csv";
      
      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="book_title"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="Book Title"
              >
                Book Title
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="chaptor_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Chapter ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="chaptor_title"
                width="60px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCoin}
                csvFormat={this.formatCoin}
                csvHeader="Chapter Title"
              >
                Chapter Title
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_chaptor_name"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="Chapter Name"
              >
                Chapter Name
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sum_gold"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatGold}
                csvFormat={this.formatGold}
                csvHeader="ทอง"
              >
                ทอง
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sum_silver"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatSilver}
                csvFormat={this.formatSilver}
                csvHeader="เงิน"
              >
                เงิน
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sum_botread_gold"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatBotReadGold}
                csvFormat={this.formatBotReadGold}
                csvHeader="ทอง (เสียง)"
              >
                ทอง (เสียง)
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sum_botread_silver"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatBotReadSilver}
                csvFormat={this.formatBotReadSilver}
                csvHeader="เงิน (เสียง)"
              >
                เงิน (เสียง)
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="total_view"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatTotalView}
                csvFormat={this.formatTotalView}
                csvHeader="ยอด View"
              >
                ยอด View
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_view"
                width="70px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatMemberView}
                csvFormat={this.formatMemberView}
                csvHeader="จำนวน Member ที่ View"
              >
                จำนวน Member ที่ View
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  async getReportData(filter) {
    await this.props.getReportChapterRank(filter)
    this.setState({ 
      chapters: this.props.reportChapterRankReducer.result,
      totalPages: Math.ceil(this.props.reportChapterRankReducer.total / 10) 
    })
  }

  changePage(page) {
    this.setState(
      { currentPage: page },
      () => {
        this.getReportData({
          ...this.state.currentFilter,
          page
        })
      }
    )
  }

  render() {
    const { value, suggestions } = this.state;
    const { isFetching, total } = this.props.reportChapterRankReducer;

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuReport />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">สรุปยอดขายแต่ละบทของแต่ละเล่ม</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    book_title: "",
                    page: 1
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;

                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    value.start = startDate;
                    value.end = endDate;

                    values.book_title = this.state.bookTitle;
                    if (values.book_title) {
                      value.bookTitle = values.book_title;
                    }

                    value.page = this.state.currentPage

                    this.setState({ currentFilter: value })

                    this.getReportData({
                      ...value,
                      page: 0
                    });
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="8" className="form-group">
                            <label>ชื่อหนังสือ</label>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                              }
                              shouldRenderSuggestions={
                                this.shouldRenderSuggestions
                              }
                              onSuggestionSelected={this.onSuggestionSelected}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                            />
                          </Col>
                          <Col sm="2" className="text-right">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm"
                              style={{
                                marginLeft: 30,
                                marginTop: 27,
                                color: "white",
                                width: 100,
                              }}
                            >
                              Search
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                
                <div
                  className={
                    "content-loading-wrapper " +
                    (isFetching ? "loading" : "")
                  }
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">ชื่อหนังสือ</th>
                        <th className="text-center">ตอน</th>
                        <th className="text-center">ชื่อตอน</th>
                        <th className="text-center">เหรียญทอง</th>
                        <th className="text-center">เหรียญเงิน</th>
                        <th className="text-center">เหรียญทอง (หนังสือเสียง)</th>
                        <th className="text-center">เหรียญเงิน (หนังสือเสียง)</th>
                        <th className="text-center">จำนวนครั้งที่เปิดอ่าน</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.chapters.map((item, index) => {
                        return (
                          <tr key={item.book_chaptor_id}>
                            <td className="align-middle">{item.book_title}</td>
                            <td className="align-middle">{item.chaptor_title}</td>
                            <td className="align-middle">{item.book_chaptor_name}</td>
                            <td className="text-center align-middle">
                              {this.formatAmount(item.sum_gold)}
                            </td>
                            <td className="text-center align-middle">
                              {this.formatAmount(item.sum_silver)}
                            </td>
                            <td className="text-center align-middle">
                              {this.formatAmount(item.sum_botread_gold)}
                            </td>
                            <td className="text-center align-middle">
                              {this.formatAmount(item.sum_botread_silver)}
                            </td>
                            <td className="text-center align-middle">
                              {this.formatAmount(item.total_view)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                
                <nav className="text-right">
                  {this.state.chapters.length > 0 && (  
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportChapterRankReducer, bookReducer }) => ({
  reportChapterRankReducer,
  bookReducer,
});

const mapDispatchToProps = {
  getReportChapterRank,
  getBooks,
};

export default connect(mapStateToProps, mapDispatchToProps)(reportChapterRank);

import { httpClient } from "../utils/HttpClient";
import { server } from "../constants";
import moment from "moment";

import {
  HTTP_COMMENT_FETCHING,
  HTTP_COMMENT_SUCCESS,
  HTTP_COMMENT_FAILED,
} from "../constants/comment";

const setStateCommentToSuccess = (payload) => ({
  type: HTTP_COMMENT_SUCCESS,
  payload: payload,
});

const setStateCommentToFetching = () => ({
  type: HTTP_COMMENT_FETCHING,
});

const setStateCommentToFailed = () => ({
  type: HTTP_COMMENT_FAILED,
});

/* Get Book Comment */
export const getBookComment = (value) => {
  return async (dispatch) => {
    dispatch(setStateCommentToFetching());
    await doGetBookComment(dispatch, value);
  };
};

const doGetBookComment = (dispatch, value) => {
  let dataFilter = "";

  if (value) {
    if (value.start) {
      dataFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dataFilter += "?end_date=" + value.end + " 23:59.00";
      else dataFilter += "&end_date=" + value.end + " 23:59.00";
    }

    if (value.categoryName) {
      if (dataFilter.length > 0)
        dataFilter += "&category_name=" + value.categoryName;
      else dataFilter += "?category_name=" + value.categoryName;
    }
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.COMMENT_URL}/book` + dataFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateCommentToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateCommentToFailed());
    });
};


/* Get Chapter Comment */
export const getChapterComment = (value) => {
  return async (dispatch) => {
    dispatch(setStateCommentToFetching());
    await doGetChapterComment(dispatch, value);
  };
};

const doGetChapterComment = (dispatch, value) => {
  let dataFilter = "";

  if (value) {
    if (value.start) {
      dataFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dataFilter += "?end_date=" + value.end + " 23:59.00";
      else dataFilter += "&end_date=" + value.end + " 23:59.00";
    }

    if (value.categoryName) {
      if (dataFilter.length > 0)
        dataFilter += "&category_name=" + value.categoryName;
      else dataFilter += "?category_name=" + value.categoryName;
    }
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.COMMENT_URL}/chapter` + dataFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateCommentToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateCommentToFailed());
    });
};

/* Get Writer Comment */
export const getWriterComment = (value) => {
  return async (dispatch) => {
    dispatch(setStateCommentToFetching());
    await doGetWriterComment(dispatch, value);
  };
};

const doGetWriterComment = (dispatch, value) => {
  let dataFilter = "";

  if (value) {
    if (value.start) {
      dataFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dataFilter += "?end_date=" + value.end + " 23:59.00";
      else dataFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.COMMENT_URL}/writer` + dataFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateCommentToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateCommentToFailed());
    });
};

export const changeStatus = (item, type) => {
  return async (dispatch) => {
    dispatch(setStateCommentToFetching());

    let formData = new FormData();
    let result = localStorage.getItem("user_profile");
    let username;
    if (result) username = JSON.parse(result).user_username;

    if (item.dbstatus === "A") formData.append("dbstatus", "D");
    else formData.append("dbstatus", "A");

    formData.append(
      "updated_datetime",
      moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    );
    formData.append("updated_by", username);

    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    const token = localStorage.getItem("token");

    let response = await httpClient.put(
      `admin/comment/${item.comment_id}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 201 || response.status === 200) {
      dispatch(setStateCommentToSuccess());
      if (type === "book") doGetBookComment(dispatch);
      else if (type === "chapter") doGetChapterComment(dispatch);
      else if (type === "writer") doGetWriterComment(dispatch);
    } else {
      alert("เกิดข้อผิดพลาดในการบันทึกข้อมูล");
      dispatch(setStateCommentToFailed());
    }
  };
};

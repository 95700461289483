import { httpClient } from "./../utils/HttpClient";

import {
    HTTP_REPORT_COINSPEND_SUCCESS,
    HTTP_REPORT_COINSPEND_FETCHING,
    HTTP_REPORT_COINSPEND_FAILED,

    server,
} from "../constants";

/* REPORT USER COIN SPENDING ACTION */
const setStateCoinSpendingToSuccess = (payload) => ({
  type: HTTP_REPORT_COINSPEND_SUCCESS,
  payload: payload,
});

const setStateCoinSpendingToFetching = () => ({
  type: HTTP_REPORT_COINSPEND_FETCHING,
});

const setStateCoinSpendingToFailed = () => ({
  type: HTTP_REPORT_COINSPEND_FAILED,
});


/* Get Report User Coin Spending */
export const getReportCoinSpending = (value) => {
  return async (dispatch) => {
    dispatch(setStateCoinSpendingToFetching());
    await doGetReportCoinSpending(dispatch,value);
  };
};

const doGetReportCoinSpending = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if(!value.start)
        dateFilter += "?end_date=" + value.end + " 23:59.00";
      else
        dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/user_coin_spending` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateCoinSpendingToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateCoinSpendingToFailed());
    });
};


import React, { Component } from "react";
import { Formik, Field } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";

import { httpClient } from "../../../utils/HttpClient";
import { getPlaylistById } from "../../../actions/playlist.action";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import PlaylistFrom from "../playlistForm";

class EditPlaylist extends Component {
  constructor() {
    super();
    this.state = { img_playlist: null };
    this.change = this.change.bind(this);
  }

  change(img) {
    this.setState({ img_playlist: img });
  }

  componentDidMount() {
    let playlist_id = this.props.match.params.playlist_id;
    this.props.getPlaylistById(playlist_id);
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 30 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">EDIT PLAYLIST</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <PlaylistFrom
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isDisableField={false}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      value={this.state.value}
                      change={this.change}
                    ></PlaylistFrom>

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      Update
                    </button>
                    <a
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      Back
                    </a>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    const { result } = this.props.playlistReducer;
    // alert(JSON.stringify(result))
    return (
      <React.Fragment>
        {result ? (
          <Formik
            enableReinitialize
            initialValues={result ? result : {}}
            onSubmit={async (values, { setSubmitting }) => {
              let formData = new FormData();

              if (values.playlist_name) {
                formData.append("playlist_name", values.playlist_name);
              }

              if (values.playlist_desc)
                formData.append("playlist_desc", values.playlist_desc);

              formData.append("playlist_status", "A");

              if (this.state.img_playlist)
                formData.append("playlist_image", this.state.img_playlist);

              if (values.playlist_display)
                formData.append("playlist_display", values.playlist_display);

              formData.append("dbstatus", "A");

              let username = "";
              var result = localStorage.getItem("user_profile");
              if (result) username = JSON.parse(result).user_username;

              let dateTime = new Date();
              dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

              formData.append("updated_by", username);
              formData.append("updated_datetime", dateTime);

              var object = {};
              formData.forEach((value, key) => {
                object[key] = value;
              });
              var json = JSON.stringify(object);

              const token = localStorage.getItem("token")
              let response = await httpClient.patch(
                server.PLAYLIST_URL + "/" + `${values.playlist_id}`,
                json,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                  },
                }
              );

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Update Complete",
                  showConfirmButton: true,
                  timer: 10000,
                });

                setSubmitting(false);
                this.props.history.goBack();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  timer: 2500,
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ playlistReducer }) => ({ playlistReducer });

const mapDispatchToProps = {
  getPlaylistById,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPlaylist);

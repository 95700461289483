import React, { Component } from "react";
import * as actions from "../../actions/reportOrder.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
  FormSelect,
  FormInput,
  Button,
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import Swal from "sweetalert2";
import ContentPagination from "../Layout/Common/Pagination";
import XLSX from "xlsx";

const isAllowAction = () => {
  return true
  // var result = localStorage.getItem("user_profile");
  // if (result) {
  //   const priv_allow = JSON.parse(result).priv_report_order;

  //   if (priv_allow === "Y") return true;
  //   else {
  //     Swal.fire({
  //       icon: "error",
  //       text: "No privilege!",
  //     });
  //     return false;
  //   }
  // }
};

class ReportOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      start_date: "",
      end_date: "",
      payment_type: "",
      package_coin_type: "",
      order_status: "",
      member_name: "",
      member_email: "",
    };
  }

  async componentDidMount() {
    localStorage.removeItem("topup_current_page");
    localStorage.removeItem("purchase_current_page");
    await this.getOrders(this.state.currentPage);
    if (this.props.reportOrderReducer.orders) {
      this.setState({ orders: this.props.reportOrderReducer.orders });
    }
  }

  async getOrders(page) {
    this.setState({ loading: true });

    const value = {};
    value.start = this.state.start_date;
    value.end = this.state.end_date;
    value.payment_type = this.state.payment_type;
    value.package_coin_type = this.state.package_coin_type;
    value.order_status = this.state.order_status;
    value.member_name = this.state.member_name;
    value.member_email = this.state.member_email;
    value.page = page;
    await this.props.getReportOrder(value);

    const { orders, total, isFetching } = this.props.reportOrderReducer;
    if (orders && !isFetching) {
      const totalPages = Math.ceil(total / this.state.perPage);

      this.setState({
        orders,
        totalPages,
        loading: false,
      });
    }
  }

  formatDecimal(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatOrderStatus(value) {
    if (value === "A") return "SUCCESS";
    else if (value === "W") return "WAITING";
  }

  formatDateTime(value) {
    if (value) {
      return moment(value)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatAction(row) {
    let disabled = "";
    let username = "";

    if (row.order_status === "A") disabled = true;
    else disabled = false;

    var result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    return (
      <div>
        <button
          disabled={disabled}
          type="button"
          className="btn btn-xs btn-success"
          onClick={() => {
            return (
              isAllowAction() &&
              Swal.fire({
                title: "ยืนยันการอนุมัติการโอนเงิน?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "อนุมัติ",
                cancelButtonText: "ยกเลิก",
              }).then((result) => {
                if (result.value) {
                  this.approveOrder(row, username);
                }
              })
            );
          }}
        >
          Approve
        </button>
        <span style={{ color: "grey" }}> | </span>
        <button
          type="button"
          className="btn btn-xs btn-danger"
          onClick={() => {
            return (
              isAllowAction() &&
              Swal.fire({
                title: "Are you sure to delete?",
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
              }).then((result) => {
                if (result.value) {
                  this.deleteOrder(row.order_id, username);
                }
              })
            );
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  async approveOrder(value, username) {
    await this.props.approveOrder(value, username);
    setTimeout(() => {

      this.props.getCampaignTransaction(value);
      
      this.getOrders(this.state.currentPage);
      
    }, 100);
  }

  async deleteOrder(order_id, username) {
    await this.props.deleteOrder(order_id, username);
    setTimeout(() => {
      this.getOrders(this.state.currentPage);
      
    }, 100);
  }

  async exportData() {
    await this.getOrders("");

    let report_order = this.state.orders.map((item, idx) => {
      return [
        item.order_id,
        item.order_code,
        item.payment_type,
        this.formatDateTime(item.created_datetime),
        item.member_name,
        item.member_email,
        item.charge,
        item.coin,
        item.package_coin_type,
        item.order_status,
        this.formatDateTime(item.updated_datetime),
        item.updated_by,
      ];
    });

    report_order = [
      [
        "Order ID",
        "Order Code",
        "Payment Type",
        "Create Date",
        "Name",
        "Email",
        "Charge",
        "Coin",
        "Coin Type",
        "Order Status",
        "Update Date",
        "Update By",
      ],
      ...report_order,
    ];

    if (report_order) {
      this.downloadExportData(report_order);
      this.getOrders(0);
    }
  }

  downloadExportData(report_order) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report_order);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["orders"]);
    XLSX.writeFile(workBook, `report-order.xlsx`);
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">Type</th>
            <th className="text-center">Order Date</th>
            <th className="text-center">Name</th>
            <th className="text-center">Email</th>
            <th className="text-center">Package</th>
            <th className="text-center">Charge</th>
            <th className="text-center">Coin</th>
            <th className="text-center">Coin Type</th>
            <th className="text-center">Status</th>
            <th className="text-center">Last Update</th>
            <th className="text-center">Update By</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {this.state.orders.map((order, index) => {
            return (
              <tr key={order.order_id}>
                <td className="text-center align-middle" width="80">
                  {order.payment_type}
                </td>
                <td className="text-center align-middle" width="80">
                  {this.formatDateTime(order.created_datetime)}
                </td>
                <td className="align-middle" width="150">
                  {order.member_name}
                </td>
                <td className="align-middle" width="150">
                  {order.member_email}
                </td>
                <td className="text-center align-middle" width="150">
                  {order.package_name}
                </td>

                <td className="text-center align-middle" width="120">
                  {this.formatDecimal(order.charge)}
                </td>

                <td className="text-center align-middle" width="120">
                  {this.formatDecimal(order.coin)}
                </td>

                <td className="text-center align-middle" width="100">
                  {order.package_coin_type}
                </td>
                <td className="text-center align-middle" width="100">
                  {this.formatOrderStatus(order.order_status)}
                </td>
                <td className="text-center align-middle" width="120">
                  {this.formatDateTime(order.updated_datetime)}
                </td>

                <td className="text-center align-middle" width="80">
                  {order.updated_by}
                </td>

                <td className="text-center align-middle" width="180">
                  {this.formatAction(order)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getOrders(page);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Order List</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        this.exportData();
                      }}
                      type="button"
                      className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    payment_type: "",
                    package_coin_type: "",
                    order_status: "",
                    member_name: "",
                    member_email: "",
                    page: "",
                  }}
                  onSubmit={async (values) => {
                    let startDate = null;
                    let endDate = null;
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    this.setState({
                      start_date: startDate,
                      end_date: endDate,
                      payment_type: values.payment_type,
                      package_coin_type: values.package_coin_type,
                      order_status: values.order_status,
                      member_name: values.member_name,
                      member_email: values.member_email,
                    });

                    this.getOrders(this.state.currentPage);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          {/* <Col md="2" className="form-group">
                            <FormGroup>
                              <label>Payment Type</label>
                              <FormSelect
                                name="payment_type"
                                id="payment_type"
                                placeholder=""
                                onChange={handleChange}
                                value={values.payment_type}
                              >
                                <option value="">Select</option>
                                <option value="transfer">Transfer</option>
                                <option value="promptpay">Promptpay</option>
                              </FormSelect>
                            </FormGroup>
                          </Col> */}
                          <Col md="2" className="form-group">
                            <FormGroup>
                              <label>Coin Type</label>
                              <FormSelect
                                name="package_coin_type"
                                id="package_coin_type"
                                placeholder=""
                                onChange={handleChange}
                                value={values.package_coin_type}
                              >
                                <option value="">Select</option>
                                <option value="gold">Gold</option>
                                <option value="silver">Silver</option>
                              </FormSelect>
                            </FormGroup>
                          </Col>
                          <Col md="2" className="form-group">
                            <FormGroup>
                              <label>Order Status</label>
                              <FormSelect
                                name="order_status"
                                id="order_status"
                                placeholder=""
                                onChange={handleChange}
                                value={values.order_status}
                              >
                                <option value="">Select</option>
                                <option value="W">WAITING</option>
                                <option value="A">SUCCESS</option>
                              </FormSelect>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md="4">
                            <FormInput
                              name="member_name"
                              id="member_name"
                              placeholder="Name"
                              onChange={handleChange}
                              value={values.member_name}
                            />
                          </Col>
                          <Col md="4">
                            <FormInput
                              name="member_email"
                              id="member_email"
                              placeholder="Email"
                              onChange={handleChange}
                              value={values.member_email}
                            />
                          </Col>
                          <Col md="2">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm ml-4"
                              style={{
                                marginRight: 10,
                                color: "white",
                                width: 100,
                              }}
                            >
                              Search
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
              </CardBody>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.orders.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportOrderReducer }) => ({
  reportOrderReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportOrder);

import { httpClient } from "../utils/HttpClient";

import {
  HTTP_BOOK_PURCHASE_FETCHING,
  HTTP_BOOK_PURCHASE_SUCCESS,
  HTTP_BOOK_PURCHASE_FAILED,
} from "../constants/report";

import {server} from "../constants"

import {
  HTTP_CATEGORY_FETCHING,
  HTTP_CATEGORY_SUCCESS,
  HTTP_CATEGORY_FAILED
} from "../constants/category";

const setStateBookPurchaseToSuccess = payload => ({
  type: HTTP_BOOK_PURCHASE_SUCCESS,
  payload: payload
});

const setStateBookPurchaseToFetching = () => ({
  type: HTTP_BOOK_PURCHASE_FETCHING
});

const setStateBookPurchaseToFailed = () => ({
  type: HTTP_BOOK_PURCHASE_FAILED
});

const setStateCategoryToSuccess = payload => ({
  type: HTTP_CATEGORY_SUCCESS,
  payload: payload
});

const setStateCategoryToFetching = () => ({
  type: HTTP_CATEGORY_FETCHING
});

const setStateCategoryToFailed = () => ({
  type: HTTP_CATEGORY_FAILED
});

/* Get Category of book */
export const getCategory = () => {
  return async dispatch => {
    dispatch(setStateCategoryToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.CATEGORY_URL}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      dispatch(setStateCategoryToSuccess(result.data))
    } catch(error)  {
      alert(JSON.stringify(error))
      dispatch(setStateCategoryToFailed())
    }
  };
};


/* Get Report Book Rank Purchase */
export const getReportBookRankPurchase = (value) => {
  return async (dispatch) => {
    dispatch(setStateBookPurchaseToFetching());
    await doGetReportBookRankPurchase(dispatch,value);
  };
};

const doGetReportBookRankPurchase = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if(!value.start)
        dateFilter += "?end_date=" + value.end + " 23:59.00";
      else
        dateFilter += "&end_date=" + value.end + " 23:59.00";
    }

    if (value.categoryName) {
      if (dateFilter.length > 0)
        dateFilter += "&category_name=" + value.categoryName;
      else dateFilter += "?category_name=" + value.categoryName;
    }

    if (value.botRead) {
      if (dateFilter.length > 0)
        dateFilter += "&bot_read=" + value.botRead;
      else dateFilter += "?bot_read=" + value.botRead;
    }

    if (value.bookType) {
      if (dateFilter.length > 0)
        dateFilter += "&book_type=" + value.bookType;
      else dateFilter += "?book_type=" + value.bookType;
    }
  }

  const token = localStorage.getItem("token");

  httpClient
  .get(`${server.REPORT_URL}/book_rank` + dateFilter, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then((result) => {
    dispatch(setStateBookPurchaseToSuccess(result.data))
  })
  .catch((error) => {
    alert(JSON.stringify(error));
    dispatch(setStateBookPurchaseToFailed());
  });
  
};


import { httpClient } from "../utils/HttpClient";
import {server} from "../constants"
import Swal from "sweetalert2";
import {
    HTTP_PLAYLISTS_FETCHING,
    HTTP_PLAYLISTS_SUCCESS,
    HTTP_PLAYLISTS_FAILED,

    HTTP_PLAYLIST_FETCHING,
    HTTP_PLAYLIST_SUCCESS,
    HTTP_PLAYLIST_FAILED,
  } from "../constants/playlist";

const setStatePlaylistsToSuccess = payload => ({
  type: HTTP_PLAYLISTS_SUCCESS,
  payload: payload
});

const setStatePlaylistsToFetching = () => ({
  type: HTTP_PLAYLISTS_FETCHING
});

const setStatePlaylistsToFailed = () => ({
  type: HTTP_PLAYLISTS_FAILED
});

////////////////////////////////////
const setStatePlaylistToSuccess = payload => ({
  type: HTTP_PLAYLIST_SUCCESS,
  payload: payload
});

const setStatePlaylistToFetching = () => ({
  type: HTTP_PLAYLIST_FETCHING
});

const setStatePlaylistToFailed = () => ({
  type: HTTP_PLAYLIST_FAILED
});

/* Get Playlist */
export const getPlaylist = (page) => {
  return async (dispatch) => {
    dispatch(setStatePlaylistsToFetching());
    await doGetPlaylist(dispatch,page);
  };
};

const doGetPlaylist = async (dispatch,page) => {
  try {
    const token = localStorage.getItem("token");

    const result = await httpClient.get(server.PLAYLIST_URL, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page
      }
    })
    dispatch(setStatePlaylistsToSuccess(result.data));
  } catch(err)  {
    dispatch(setStatePlaylistsToFailed());
  }
};

export const getPlaylistById = (playlist_id) => {
  return async (dispatch) => {
    dispatch(setStatePlaylistToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.PLAYLIST_URL}/id/${playlist_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      let playlistResult = result.data

      dispatch(setStatePlaylistToSuccess(playlistResult));
    } catch(err)  {
      dispatch(setStatePlaylistToFailed());
    }
  };
};

export const deletePlaylist = (playlist_id) => {
  return async (dispatch) => {
    dispatch(setStatePlaylistsToFetching());
    const token = localStorage.getItem("token");
    const result = await httpClient.delete(
      server.PLAYLIST_URL + "/" + `${playlist_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result){
      const token = localStorage.getItem("token");

      let url = `${server.PLAYLIST_URL}/item/${playlist_id}`; //ลบใน playlist_item ทั้งหมดด้วย
      const result = await httpClient.delete(url,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result) {
        alert("Delete Complete");
        await doGetPlaylist(dispatch);
      }
    }
  };
};



import React, { Component, useEffect, useState, useRef } from "react"
import { useParams } from 'react-router-dom'
import moment from "moment"
import { withRouter } from 'react-router-dom';
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { server } from "../../constants";
import { Row, Col, FormInput, FormSelect } from "shards-react";
import { Table, Tag, Button, Skeleton, Modal, Descriptions, message } from 'antd';
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

const financeSchema = Yup.object().shape({
  first_name: Yup.string().required('กรุณากรอกชื่อ'),
  last_name: Yup.string().required('กรุณากรอกนามสกุล'),
  email: Yup.string().email('อีเมลไม่ถูกต้อง').required('กรุณากรอกอีเมล'),
  phone: Yup.string().required('กรุณากรอกเบอร์โทรศัพท์').test('phoneLength', 'เบอร์โทรศัพท์ไม่ถูกต้อง', val => {
    let isValid = false

    if(val) {
      isValid = val.replace(/[^A-Z0-9]/ig, "").length === 10
    }

    return isValid
  }),
  id_card: Yup.string().required('กรุณากรอกเลขบัตรประจำตัวประชาชน').test('idLength', 'เลขบัตรประจำตัวประชาชนไม่ถูกต้อง', val => {
    let isValid = false

    if(val) {
      isValid = val.replace(/[^A-Z0-9]/ig, "").length === 13
    }

    return isValid
  }),
  bank_name: Yup.string().required('ชื่อบัญชี'),
  bank_number: Yup.string().required('กรุณากรอกเลขที่บัญชี').test('bankNumberLength', 'เลขที่บัญชีไม่ถูกต้อง', val => {
    let isValid = false

    if(val) {
      isValid = val.replace(/[^A-Z0-9]/ig, "").length === 10
    }

    return isValid
  })
})

export default function WriterFinanceInfo({ member }) {
  const fileRef = useRef()
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState({})
  const [saving, setSaving] = useState(false)
  const [data, setData] = useState({
    member_id: member.member_id,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    id_card: '',
    bank_name: '',
    bank_number: '',
    bank_company: '1',
    bank_type: '1'
  })
  const [isDataLoaded, setDataLoaded] = useState(false)
  const [idAttachment, setIdAttachments] = useState(null)
  const [bankAttachment, setBankAttachments] = useState(null)
  const [companyAttachment, setCompanyAttachments] = useState(null)
  const [tax20Attachment, setTax20Attachments] = useState(null)
  const [activeAttachment, setActiveAttachment] = useState(null)
  const [attachmentType, setAttachmentType] = useState(null)
  
  useEffect(() => {
    getFinanceInfo()
  }, [])

  const getFinanceInfo = async () => {
    setLoading(true)

    try {
      const token = localStorage.getItem("token");

      const response = await httpClient.get(
        `admin/member/${member.member_id}/finance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      )

      if(response.data.financeDetail) {
        setData(response.data.financeDetail)
      }

      for(const attachment of response.data.attachments) {
        if(attachment.type === '1') {
          setIdAttachments(attachment)
        } else if(attachment.type === '2') {
          setBankAttachments(attachment)
        } else if(attachment.type === '3') {
          setCompanyAttachments(attachment)
        } else if(attachment.type === '4') {
          setTax20Attachments(attachment)
        }
      }
      
      setDataLoaded(true)
      setLoading(false)
    } catch(e) {
      setLoading(false)
    }
  }

  const save = async (values) => {
    setSaving(true)

    try {
      const result = localStorage.getItem("user_profile");
      let username

      if (result) {
        username = JSON.parse(result).user_username;
      }

      const token = localStorage.getItem("token");

      const response = await httpClient.post(
        `admin/member/${member.member_id}/finance`,
        {
          ...values,
          id_card: values.id_card.replace(/[^A-Z0-9]/ig, ""),
          phone: values.phone.replace(/[^A-Z0-9]/ig, ""),
          bank_number: values.bank_number.replace(/[^A-Z0-9]/ig, ""),
          updated_by: username
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      )

      if(!data.id) {
        setData({
          id: response.data.id,
          ...data
        })
      }

      Swal.fire({
        icon: "success",
        title: "บันทึกข้อมูลสำเร็จ",
        showConfirmButton: false,
        timer: 1500
      })

      setSaving(false)
    } catch(e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "บันทึกข้อมูลไม่สำเร็จ",
        timer: 1500
      })
      setSaving(false)
    }
  }

  const selectFile = (attachment, type) => {
    setActiveAttachment(attachment)
    setAttachmentType(type)
    fileRef.current.click()
  }

  const uploadAttachment = async (file, type) => {
    if(file) {
      setUploading({
        [type]: true
      })

      try {
        const token = localStorage.getItem("token");
        const result = localStorage.getItem("user_profile");
        let username

        if (result) {
          username = JSON.parse(result).user_username;
        }

        const formData = new FormData();
        formData.append("upload", file);
        
        const uploadResponse = await httpClient.post(
          server.IMG_URL,
          formData,
          {
            headers: {
              processData: false,
              enctype: "multipart/form-data",
            }
          }
        )

        const attachmentParams = {
          id: activeAttachment ? activeAttachment.id : null,
          finance_id: data.id,
          name: file.name,
          url: uploadResponse.data.url,
          type: type,
          created_by: username
        }

        const response = await httpClient.post(
          `admin/member/${member.member_id}/finance/attachment`,
          attachmentParams,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        if(type === '1') {
          setIdAttachments(response.data)
        } else if(type === '2') {
          setBankAttachments(response.data)
        } else if(type === '3') {
          setCompanyAttachments(response.data)
        } else if(type === '4') {
          setTax20Attachments(response.data)
        }
        
        setUploading({
          [type]: false
        })

        message.open({
          content: 'อัพโหลดเอกสารแนบสำเร็จ'
        })
      } catch(e) {
        message.open({
          content: 'อัพโหลดเอกสารแนบไม่สำเร็จ'
        })
      }
    }
  }

  const deleteAttachment = async (id, type) => {
    let attachmentName = ''

    if(type === '1') {
      attachmentName = 'สำเนาบัตรประชาชน'
    } else if(type === '2') {
      attachmentName = 'สำเนาหน้าสมุดบัญชี'
    } else if(type === '3') {
      attachmentName = 'หนังสือรับรองบริษัท'
    } else if(type === '4') {
      attachmentName = 'สำเนา ภ.พ. 20'
    }

    Swal.fire({
      text: `ยืนยันการลบ${attachmentName}`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        const token = localStorage.getItem("token");

        httpClient.delete(
          `admin/member/finance/attachment/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        ).then((res) => {
          if(type === '1') {
            setIdAttachments(null)
          } else if(type === '2') {
            setBankAttachments(null)
          } else if(type === '3') {
            setCompanyAttachments(null)
          } else if(type === '4') {
            setTax20Attachments(null)
          }
        }).catch((e) => {
        })
      }
    });
  }

  return (
    <>
      {
        isDataLoaded &&
        <Formik
          initialValues={data}
          validationSchema={financeSchema}
          onSubmit={values => {
            save(values)
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form>
              <Row>
                <Col md="6" className="form-group">
                  <label>ชื่อ</label>
                  <FormInput
                    name="first_name"
                    id="first_name"
                    placeholder="ชื่อ"
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  { 
                    (errors.first_name && touched.first_name) && 
                    <div className="font-12 text-primary mt-1">
                      {errors.first_name}
                    </div>
                  }
                </Col>
                <Col md="6" className="form-group">
                  <label>นามสกุล</label>
                  <FormInput
                    name="last_name"
                    id="last_name"
                    placeholder="นามสกุล"
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  { 
                    (errors.last_name && touched.last_name) && 
                    <div className="font-12 text-primary mt-1">
                      {errors.last_name}
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col md="6" className="form-group">
                  <label>อีเมล</label>
                  <FormInput
                    name="email"
                    id="email"
                    placeholder="อีเมล"
                    value={values.email}
                    onChange={handleChange}
                  />
                  { 
                    (errors.email && touched.email) && 
                    <div className="font-12 text-primary mt-1">
                      {errors.email}
                    </div>
                  }
                </Col>
                <Col md="6" className="form-group">
                  <label>เบอร์โทรศัพท์</label>
                  <NumberFormat
                    customInput={FormInput}
                    name="phone"
                    id="phone"
                    placeholder="เบอร์โทรศัพท์"
                    format="###-###-####"
                    onChange={handleChange}
                    value={values.phone}
                  />
                  { 
                    (errors.phone && touched.phone) && 
                    <div className="font-12 text-primary mt-1">
                      {errors.phone}
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col md="6" className="form-group">
                  <label>เลขบัตรประจำตัวประชาชน</label>
                  <NumberFormat
                    customInput={FormInput}
                    name="id_card"
                    id="id_card"
                    placeholder="เลขบัตรประจำตัวประชาชน"
                    value={values.id_card}
                    format="#-####-#####-##-#"
                    onChange={handleChange}
                  />
                 { 
                    (errors.id_card && touched.id_card) && 
                    <div className="font-12 text-primary mt-1">
                      {errors.id_card}
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col md="6" className="form-group">
                  <label>ชื่อบัญชี</label>
                  <FormInput
                    name="bank_name"
                    id="bank_name"
                    placeholder="ชื่อบัญชี"
                    value={values.bank_name}
                    onChange={handleChange}
                  />
                  { 
                    (errors.bank_name && touched.bank_name) && 
                    <div className="font-12 text-primary mt-1">
                      {errors.bank_name}
                    </div>
                  }
                </Col>
                <Col md="6" className="form-group">
                  <label>เลขที่บัญชี</label>
                  <NumberFormat
                    customInput={FormInput}
                    name="bank_number"
                    id="bank_number"
                    placeholder="เลขที่บัญชี"
                    format="###-#-#####-#"
                    onChange={handleChange}
                    value={values.bank_number}
                  />
                  { 
                    (errors.bank_number && touched.bank_number) && 
                    <div className="font-12 text-primary mt-1">
                      {errors.bank_number}
                    </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col md="6" className="form-group">
                  <label>ธนาคาร</label>
                  <FormSelect
                    name="bank_company"
                    id="bank_company"
                    placeholder=""
                    onChange={handleChange}
                    value={values.bank_company}
                  >
                    <option value="1">ธนาคารกรุงเทพ</option>
                    <option value="2">ธนาคารกรุงศรีอยุธยา</option>
                    <option value="3">ธนาคารกรุงไทย</option>
                    <option value="4">ธนาคารกสิกรไทย</option>
                    <option value="5">ธนาคารไทยพาณิชย์</option>
                    <option value="6">ธนาคารทหารไทยธนชาต</option>
                    <option value="7">ธนาคารยูโอบี</option>
                  </FormSelect>
                </Col>
                <Col md="6" className="form-group">
                  <label>ประเภทบัญชี</label>
                  <FormSelect
                    name="bank_type"
                    id="bank_type"
                    placeholder=""
                    onChange={handleChange}
                    value={values.bank_type}
                  >
                    <option value="1">ออมทรัพย์</option>
                    <option value="2">กระแสเงินสด</option>
                  </FormSelect>
                </Col>
              </Row>
              
              { data.id &&
                <>
                  <h5 className="font-weight-bold mt-4">เอกสารแนบ</h5>

                  <input
                    ref={fileRef}
                    type="file"
                    className="d-none" 
                    onChange={(e) => uploadAttachment(e.target.files[0] ,attachmentType) }
                  />

                  <Descriptions bordered className="attachment-description">
                    <Descriptions.Item label="สำเนาบัตรประชาชน" span={3}>
                      <div className="d-flex align-items-center">
                        <div className="flex-fill">
                          { 
                            idAttachment && idAttachment.url ? 
                            <a href={idAttachment.url} target="_blank" rel="noopener noreferrer" className="text-primary">{ idAttachment.name }</a> :
                            <></>
                          }
                        </div>
                        { 
                          idAttachment && idAttachment.url &&
                          <Button
                            type="button"
                            className="btn btn-xs btn-danger px-4 mr-2"
                            onClick={() => deleteAttachment(idAttachment.id, '1')}
                          >
                            ลบ
                          </Button>
                        }
                        <Button
                          type="button"
                          loading={uploading['1']}
                          className="btn btn-xs btn-secondary px-3"
                          onClick={() => selectFile(idAttachment, '1')}
                        >
                          อัพโหลด
                        </Button>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="สำเนาหน้าสมุดบัญชี" span={3}>
                      <div className="d-flex align-items-center">
                        <div className="flex-fill">
                          { 
                            bankAttachment && bankAttachment.url ? 
                            <a href={bankAttachment.url} target="_blank" rel="noopener noreferrer" className="text-primary">{ bankAttachment.name }</a> :
                            <></>
                          }
                        </div>
                        { 
                          bankAttachment && bankAttachment.url &&
                          <Button
                            type="button"
                            className="btn btn-xs btn-danger px-4 mr-2"
                            onClick={() => deleteAttachment(bankAttachment.id, '2')}
                          >
                            ลบ
                          </Button>
                        }
                        <Button
                          type="button"
                          loading={uploading['2']}
                          className="btn btn-xs btn-secondary px-3"
                          onClick={() => selectFile(bankAttachment, '2')}
                        >
                          อัพโหลด
                        </Button>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="หนังสือรับรองบริษัท" span={3}>
                      <div className="d-flex align-items-center">
                        <div className="flex-fill">
                          { 
                            companyAttachment && companyAttachment.url ? 
                            <a href={companyAttachment.url} target="_blank" rel="noopener noreferrer" className="text-primary">{ companyAttachment.name }</a> :
                            <></>
                          }
                        </div>
                        { 
                          companyAttachment && companyAttachment.url &&
                          <Button
                            type="button"
                            className="btn btn-xs btn-danger px-4 mr-2"
                            onClick={() => deleteAttachment(companyAttachment.id, '3')}
                          >
                            ลบ
                          </Button>
                        }
                        <Button
                          type="button"
                          loading={uploading['3']}
                          className="btn btn-xs btn-secondary px-3"
                          onClick={() => selectFile(companyAttachment, '3')}
                        >
                          อัพโหลด
                        </Button>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="สำเนา ภ.พ. 20" span={3}>
                      <div className="d-flex align-items-center">
                        <div className="flex-fill">
                          { 
                            tax20Attachment && tax20Attachment.url ? 
                            <a href={tax20Attachment.url} target="_blank" rel="noopener noreferrer" className="text-primary">{ tax20Attachment.name }</a> :
                            <></>
                          }
                        </div>
                        { 
                          tax20Attachment && tax20Attachment.url &&
                          <Button
                            type="button"
                            className="btn btn-xs btn-danger px-4 mr-2"
                            onClick={() => deleteAttachment(tax20Attachment.id, '4')}
                          >
                            ลบ
                          </Button>
                        }
                        <Button
                          type="button"
                          loading={uploading['4']}
                          className="btn btn-xs btn-secondary px-3"
                          onClick={() => selectFile(tax20Attachment, '4')}
                        >
                          อัพโหลด
                        </Button>
                      </div>
                    </Descriptions.Item>
                    
                  </Descriptions>
                </>
              }

              <div className="my-4 text-right">
                <Button
                  htmlType="submit"
                  loading={saving}
                  className="btn btn-primary px-4"
                >
                  บันทึก
                </Button>
              </div>
            </Form>  
          )}
        </Formik> 
      }
    </>
  )
}
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import ReactResizeDetector from 'react-resize-detector';
import BookMessageItem from './BookMessageItem';
import { Menu, Dropdown, Modal } from 'antd';
import { 
  PlayCircleOutlined,
  ClockCircleOutlined,
  PauseCircleOutlined,
  SoundOutlined
} from '@ant-design/icons';

class ChatPreview extends Component {
  constructor() {
    super();
    this.state = { 
      messages: [],
      messageInterval: null,
      intervalTime: 2000,
      intervalSpeed: 1,
      intervalSpeedLabel: '1x',
      isPlaySound: true,
      characterModalOpen: false,
      character: null,
      speedList: [
        {
          label: '0.5x',
          value: 2
        },
        {
          label: '1x',
          value: 1
        },
        {
          label: '1.5x',
          value: 0.75
        },
        {
          label: '2x',
          value: 0.5
        }
      ],
      currentMessageIndex: 0,
      isMessageStart: false
    }

    this.messageBody = React.createRef();
    this.messageBodyInner = React.createRef();
  }

  componentDidMount() {
    
  }

  scrollToBottom()  {
    if(this.messageBody.current)  {
      const scrollPosition =
      this.messageBody.current.scrollHeight -
      this.messageBody.current.clientHeight;
      this.messageBody.current.scrollTo(0, scrollPosition);
    }
  }

  readAllMessages() {
    this.setState({
      messages: this.props.messages,
      currentMessageIndex: this.props.messages.length
    })
  }

  restartMessages() {
    this.setState({
      messages: [],
      currentMessageIndex: 0,
      isMessageStart: false
    }, () => {
      clearInterval(this.state.messageInterval)
      this.startReading()
    })
  }

  toggleMessage(action) {
    if(action === 'start')  {
      if(this.state.messages.length >= this.props.messages.length) {
        this.setState({
          messages: [],
          currentMessageIndex: 0
        })
      }

      this.startReading()
    } else {
      this.stopReading()
    }
  }

  startReading() {
    this.setState({
      isMessageStart: true
    }, () => {
      this.state.messageInterval = setInterval(() => {
        if(this.state.isMessageStart) {
          this.showNextMessage()
        }
      }, this.state.intervalTime * this.state.intervalSpeed)
    })
  }

  stopReading() {
    this.setState({
      isMessageStart: false
    }, () => {
      clearInterval(this.state.messageInterval)
    })
  }

  showNextMessage(action) {
    if(action === 'click')  {
      this.stopReading()
    }

    if(this.state.currentMessageIndex < this.props.messages.length)  {
      const nextMessage = this.props.messages[this.state.currentMessageIndex]
      const messages = this.state.messages

      this.setState({ 
        messages: [...messages, nextMessage],
        currentMessageIndex: this.state.currentMessageIndex += 1
      });

      this.playChatSound()
    } else {
      this.stopReading()
    }
  }

  playChatSound() {
    if(this.state.isPlaySound) {
      const soundFile = require("../../../assets/chat-sound.mp3")
      let audio = new Audio(soundFile);

      audio.play();
    }
  }

  changeMessageSpeed(speed)  {
    this.setState({ 
      intervalSpeed: speed.value,
      intervalSpeedLabel: speed.label
    })

    if(this.state.isMessageStart) {
      this.stopReading()
      this.startReading()
    }
  }

  toggleSound() {
    this.setState({
      isPlaySound: !this.state.isPlaySound
    })
  }

  openCharacterModal(character) {
    this.setState({
      characterModalOpen: true,
      character
    })
  }

  render() {
    const { characters, mainCharacter } = this.props

    const menu = (
      <Menu style={{width:100}}>
        {
          this.state.speedList.map(item => {
            return <Menu.Item key={item.value} onClick={() => this.changeMessageSpeed(item)}>{ item.label }</Menu.Item>    
          })
        }
      </Menu>
    );
    
    return (
      <>
        <div className="chat-preview my-4">
          <div className="chat-container mx-auto">
            <ReactResizeDetector targetRef={this.messageBodyInner} onResize={() => this.scrollToBottom()}>
              <div 
                className="chat-message-group flex-fill"
                ref={this.messageBody}
              >
                <div ref={this.messageBodyInner}>
                  {
                    this.state.messages.map(item => {
                      const character = characters.filter(character => character.id === item.character_id)[0]
                      
                      if(character) {
                        item.character_image = character.image
                      }

                      return (
                        <BookMessageItem
                          onHeightChange={this.scrollToBottom()}
                          onViewCharacter={(character) => this.openCharacterModal(character)}
                          editable={false}
                          item={item}
                          key={item.id}
                          preview={true}
                        ></BookMessageItem>
                      )
                    })
                  }
                </div>
              </div>
            </ReactResizeDetector>
            <div className="chat-toolbar d-flex align-items-center">
              <div 
                className="chat-toolbar-button first-item"
                onClick={() => this.toggleMessage(this.state.isMessageStart ? 'stop' : 'start')}
              >
                { this.state.isMessageStart ? <PauseCircleOutlined style={{ fontSize: 18 }} /> : <PlayCircleOutlined style={{ fontSize: 18 }} /> }
              </div>
              <Dropdown 
                overlay={menu} 
                trigger={['click']}
              >
                <div className="chat-toolbar-button">
                  <ClockCircleOutlined style={{ fontSize: 18 }} />
                  <div className="position-absolute font-11 text-muted" style={{bottom: 3, right: 10}}>{this.state.intervalSpeedLabel}</div>
                </div>
              </Dropdown>            
              <div className="d-flex flex-fill chat-toolbar-button-group">
                <div 
                  className="chat-toolbar-button flex-fill text-center"
                  onClick={() => this.showNextMessage('click')}
                >
                  คลิกอ่าน
                </div>
              </div>
              <div 
                className={`chat-toolbar-button last-item border-left ` + (!this.state.isPlaySound ? 'sound-disabled' : '')}
                onClick={() => this.toggleSound()}
              >
                <SoundOutlined style={{ fontSize: 18 }} />
              </div>
            </div>
          </div>
        </div>

        <Modal 
          title="ตัวละคร" 
          footer={null} 
          visible={this.state.characterModalOpen} 
          onCancel={() => this.setState({characterModalOpen: false})}
        >
          { this.state.character &&
            <div className="text-center">
              <img
                src={this.state.character.image}
                className="img-fluid rounded-circle mt-3"
                width="100"
              />

              <div className="font-16 font-weight-bold mt-3">
                { this.state.character.name }
              </div>
              { this.state.character.status ?
                <div className="text-muted">
                  { this.state.character.status }
                </div> : null
              }
              { this.state.character.role ?
                <div className="text-muted font-12">
                  ({ this.state.character.role })
                </div> : null
              }

              <hr className="mx-auto" style={{ width: 70 }} />

              <p className="mt-4">
                { this.state.character.bio }
              </p>
            </div>
          }
        </Modal>
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatPreview);

import React, { Component } from "react";
import { getBookCategory, deleteBook } from "../../../actions/book.action";
import { getCategory } from "../../../actions/category.action";
import { getAllSubCategory } from "../../../actions/sub_category.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormInput,
  FormGroup,
  FormSelect,
  FormCheckbox,
  Badge,
  ButtonGroup,
} from "shards-react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";

const animatedComponents = makeAnimated();

class Book extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      categories: [],
      currentPage: 0,
      selectedCategory: "",
      bot_read: "",
      keyword: "",
      totalPages: 1,
      perPage: 10,
      loading: false,
      sub_categories: [],
      subCategorySelect: [],
      selectedSubCategorie: "",
      selectedBookStatus: "",
      totalBook: 0,
    };
  }

  async componentDidMount() {
    this.props.getAllSubCategory();

    const current_page = localStorage.getItem("book_current_page");

    if (this.props.location.page)
      await this.setState({ currentPage: this.props.location.page });
    else if (current_page)
      await this.setState({ currentPage: Number(current_page) });

    await this.getBooks(this.state.currentPage);

    if (this.props.subCategoryReducer.result) {
      this.setState({ sub_categories: this.props.subCategoryReducer.result });
    }

    await this.props.getCategory();

    if (this.props.categoryReducer.result) {
      this.setState({ categories: this.props.categoryReducer.result });
    }

    localStorage.removeItem("book_current_page");
    localStorage.removeItem("chapter_current_page");
    localStorage.removeItem("topup_current_page");
    localStorage.removeItem("purchase_current_page");
  }

  async getBooks(page) {
    this.setState({
      loading: true,
      currentPage: Number(page),
    });

    await this.props.getBookCategory(
      this.state.keyword,
      this.state.selectedCategory,
      page,
      this.state.bot_read,
      this.state.selectedSubCategorie,
      this.state.selectedBookStatus
    );

    const { books, total } = this.props.bookReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      books,
      totalPages,
      loading: false,
      totalBook: total,
    });
  }

  formatBookCategory(value) {
    if (value) {
      let categories = [];
      let addedIdList = [];

      value.map((item) => {
        const isExist =
          addedIdList.filter((category) => {
            return category === item.category.category_id;
          }).length > 0;

        if (!isExist) {
          categories.push(
            <Badge
              className="mx-1 font-weight-bold"
              key={item.category.category_id}
            >
              {item.category.category_name}
            </Badge>
          );

          addedIdList.push(item.category.category_id);
        }
      });

      return categories;
    }
  }

  formatBookWriter(value) {
    if (value) {
      let writers = [];

      value.map((item) => {
        const isExist =
          writers.filter((writer) => {
            return writer === item.writer.member_name;
          }).length > 0;

        if (!isExist) {
          writers.push(item.writer.member_name);
        }
      });

      return writers.join(", ");
    }
  }

  formatImageBook(cell, row) {
    if (row.book_image) {
      return (
        <img
          id="book_image"
          name="book_image"
          src={row.book_image}
          width="50px"
          height="60px"
        />
      );
    }
  }

  formatNumber(number) {
    if (number) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(number);
    } else return 0;
  }

  async changeBookStatus(book) {
    const newStatus = book.book_status === "A" ? "I" : "A";
    let newBooks = this.state.books;

    newBooks.map((newBook) => {
      if (newBook.book_id === book.book_id) {
        newBook.book_status = newStatus;
      }
    });

    this.setState({ books: newBooks });

    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let params = {
      book_status: newStatus,
      updated_by: username,
      updated_datetime: dateTime,
    };

    const token = localStorage.getItem("token");
    let response = await httpClient.patch(
      server.BOOK_URL + "/status/" + `${book.book_id}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 201 && response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    }
  }

  async changeBuyWholeBookStatus(book) {
    const newStatus = book.buy_whole_book === "Y" ? "N" : "Y";
    let newBooks = this.state.books;

    newBooks.map((newBook) => {
      if (newBook.book_id === book.book_id) {
        newBook.buy_whole_book = newStatus;
      }
    });

    this.setState({ books: newBooks });

    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let params = {
      buy_whole_book: newStatus,
      updated_by: username,
      updated_datetime: dateTime,
    };

    const token = localStorage.getItem("token");
    let response = await httpClient.patch(
      server.BOOK_URL + "/whole_book/" + `${book.book_id}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 201 && response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    }
  }

  changePage(page) {
    this.setState({ currentPage: page });
    this.getBooks(page);

    localStorage.setItem("book_current_page", page);
  }

  async deleteBook(book) {
    await this.props.deleteBook(book.book_id);
    setTimeout(() => {
      this.getBooks(this.state.currentPage);
    }, 100);
  }

  async exportData() {
   // setLoadingExportChapter(true);
   await this.getBooks("");

    try {
      let report = this.state.books.map((item, idx) => {
        return [
          item.book_title,
          this.formatBookWriter(item.creators)
        ];
      });

      report = [
        [
          "ชื่อหนังสือ",
          "ชื่อผู้แต่ง/นามปากกา"
        ],
        ...report,
      ];

      if (report) {
        await this.downloadExport(report);
        await this.getBooks(0);
      }
    } catch (e) {
      await this.getBooks(0);
      //message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "โหลดข้อมูลไม่สำเร็จ",
        timer: 1500,
      });
    }
  }

  async  downloadExport(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["Book"]);
    XLSX.writeFile(workBook, `Book.xlsx`);
  }

  createTable = () => {
    return (
      <>
        <h5 className="text-primary ml-3">
          จำนวนหนังสือทั้งหมด {this.formatNumber(this.state.totalBook)} เล่ม
          <Button className="btn-primary px-3 mb-2 ml-2"  onClick={() => this.exportData()}>Export</Button>
        </h5>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ชื่อหนังสือ</th>
              <th>ชื่อผู้แต่ง/นามปากกา</th>
              <th className="text-center">หมวดหมู่</th>
              <th className="text-center">เผยแพร่</th>
              <th className="text-center">บังคับซื้อทั้งเล่ม</th>
              <th className="text-center">Label</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.books.map((book, index) => {
              return (
                <tr key={book.book_id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <img src={book.book_image} width="50" className="mr-3" />
                      <div>{book.book_title}</div>
                    </div>
                  </td>
                  <td className="align-middle">
                    {book.writer_pen_name
                      ? book.writer_pen_name.name
                      : this.formatBookWriter(book.creators)}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatBookCategory(book.categories)}
                  </td>
                  <td className="text-center align-middle">
                    <FormCheckbox
                      toggle
                      inline
                      checked={book.book_status === "A" ? true : false}
                      onChange={() => this.changeBookStatus(book)}
                      className="mr-0 ml-2"
                    />
                  </td>
                  <td className="text-center align-middle">
                    <FormCheckbox
                      toggle
                      inline
                      checked={book.buy_whole_book === "Y" ? true : false}
                      onChange={() => this.changeBuyWholeBookStatus(book)}
                      className="mr-0 ml-2"
                    />
                  </td>
                  <td className="text-center align-middle">
                    <a
                      type="button"
                      className="btn btn-xs btn-warning mr-2"
                      onClick={() =>
                        this.props.history.push(
                          {
                            pathname: `/book_label/${book.book_id}`,
                            bookPage: this.state.currentPage,
                          },
                          localStorage.setItem(
                            "book_current_page",
                            this.state.currentPage
                          )
                        )
                      }
                    >
                      Label
                    </a>
                  </td>
                  <td className="text-center align-middle" width="180">
                    <a
                      type="button"
                      className="btn btn-xs btn-primary text-white mr-2"
                      onClick={() =>
                        this.props.history.push(
                          {
                            pathname: `/book_chapter/${book.book_id}`,
                            bookPage: this.state.currentPage,
                          },
                          localStorage.setItem(
                            "book_current_page",
                            this.state.currentPage
                          )
                        )
                      }
                    >
                      Chapters
                    </a>
                    <a
                      type="button"
                      className="btn btn-xs btn-secondary text-white mr-2"
                      onClick={() =>
                        this.props.history.push(
                          {
                            pathname: "/books/edit/" + book.book_id,
                            page: this.state.currentPage,
                          },
                          localStorage.setItem(
                            "book_current_page",
                            this.state.currentPage
                          )
                        )
                      }
                      //href={"/books/edit/" + book.book_id}
                    >
                      Edit
                    </a>
                    <a
                      onClick={() => {
                        return Swal.fire({
                          text: "ยืนยันการลบหนังสือ?",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonText: "ลบ",
                          cancelButtonText: "ยกเลิก",
                        }).then((result) => {
                          if (result.value) {
                            this.deleteBook(book);
                          }
                        });
                      }}
                      type="button"
                      className="btn btn-xs btn-danger text-white"
                    >
                      Delete
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  async setBookType(type) {
    await localStorage.removeItem("book_current_page");
    await localStorage.removeItem("chapter_current_page");
    await this.setState({ currentPage: 0 });

    await this.setState({ bot_read: type });
    this.getBooks(this.state.currentPage);
  }

  async updateSubCategoryList(inputValue) {
    let subCategories = [];

    if (inputValue) {
      for (let i = 0; i < this.state.sub_categories.length; i++) {
        if (this.state.sub_categories[i].category_id == inputValue) {
          subCategories.push({
            label: this.state.sub_categories[i].sub_category_name,
            value: this.state.sub_categories[i].sub_category_id,
          });
        }
      }

      await this.setState({
        subCategorySelect: subCategories,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Row>
              <Col md="6">
                <ButtonGroup className="mb-3 mt-4">
                  <Button
                    theme={this.state.bot_read === "" ? "danger " : "white"}
                    className="rounded-0"
                    onClick={() => this.setBookType("")}
                  >
                    ทุกประเภทหนังสือ
                  </Button>
                  <Button
                    theme={this.state.bot_read === "N" ? "danger " : "white"}
                    className="rounded-0"
                    onClick={() => this.setBookType("N")}
                  >
                    หนังสืออ่านออนไลน์
                  </Button>
                  <Button
                    theme={this.state.bot_read === "Y" ? "danger " : "white"}
                    className="rounded-0"
                    onClick={() => this.setBookType("Y")}
                  >
                    หนังสือเสียง (Bot Read)
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>

            {/* <Row className="mt-4">
              <Col md="6" className="form-group">
                <label className="text-accent">ประเภทหนังสือ</label>
                <FormSelect
                  name="bot_read"
                  id="bot_read"
                  onChange={(e) =>
                    this.setState({ bot_read: e.target.value }, () => {
                      this.getBooks(this.state.currentPage);
                    })
                  }
                  value={this.state.bot_read}
                >
                  <option value="">ทุกประเภทหนังสือ</option>
                  <option value="N">หนังสืออ่านออนไลน์</option>
                  <option value="Y">หนังสือเสียง (Bot Read)</option>
                  })}
                </FormSelect>
              </Col>
            </Row> */}
            <Card className="mt-2">
              <CardHeader className="border-bottom">
                <Row className="align-items-center">
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="m-0">Book</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => this.props.history.push(`/books/create`)}
                      className=" btn btn-md btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Add Book
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="pb-2">
                <Row className="mt-2">
                  <Col sm="4" className="pr-1">
                    <FormInput
                      name="book_keyword"
                      id="book_keyword"
                      placeholder="ชื่อหนังสือ/ผู้แต่ง"
                      onChange={(e) =>
                        this.setState({ keyword: e.target.value }, () => {
                          this.getBooks(0);
                        })
                      }
                      value={this.state.keyword}
                    />
                  </Col>
                  <Col sm="3" className="pr-1">
                    <FormSelect
                      name="book_category"
                      id="book_category"
                      onChange={(e) =>
                        this.setState(
                          {
                            selectedCategory: e.target.value,
                            selectedSubCategorie: "",
                            subCategorySelect: [],
                          },
                          () => {
                            this.updateSubCategoryList(
                              this.state.selectedCategory
                            );
                            this.getBooks(0);
                          }
                        )
                      }
                      value={this.state.selectedCategory}
                    >
                      <option value="">ทุกหมวดหมู่</option>
                      {this.state.categories.map((category) => {
                        return (
                          <option
                            value={category.category_id}
                            key={category.category_id}
                          >
                            {category.category_name}
                          </option>
                        );
                      })}
                    </FormSelect>
                  </Col>
                  <Col md="3" className="form-group">
                    <FormSelect
                      name="sub_category"
                      id="sub_category"
                      onChange={(e) =>
                        this.setState(
                          { selectedSubCategorie: e.target.value },
                          () => {
                            this.getBooks(0);
                          }
                        )
                      }
                      value={this.state.selectedSubCategorie}
                    >
                      <option value="">หมวดหมู่ย่อย</option>
                      {this.state.subCategorySelect.map((sub_category) => {
                        return (
                          <option
                            value={sub_category.value}
                            key={sub_category.value}
                          >
                            {sub_category.label}
                          </option>
                        );
                      })}
                    </FormSelect>
                  </Col>
                  <Col md="2" className="form-group">
                    <FormSelect
                      name="ิbook_status"
                      id="ิbook_status"
                      onChange={(e) =>
                        this.setState(
                          { selectedBookStatus: e.target.value },
                          () => {
                            this.getBooks(0);
                          }
                        )
                      }
                      value={this.state.selectedBookStatus}
                    >
                      <option value="">เผยแพร่/ไม่เผยแพร่</option>
                      <option value="A">เผยแพร่</option>
                      <option value="I">ไม่เผยแพร่</option>
                    </FormSelect>
                  </Col>
                </Row>
              </CardBody>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.createTable()}
              </div>

              <CardBody className="pt-3">
                <nav className="text-right">
                  {this.state.books.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  bookReducer,
  categoryReducer,
  subCategoryReducer,
}) => ({
  bookReducer,
  categoryReducer,
  subCategoryReducer,
});

const mapDispatchToProps = {
  getBookCategory,
  getCategory,
  deleteBook,
  getAllSubCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Book);

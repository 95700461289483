import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_PROFILE_SUCCESS,
  HTTP_PROFILE_FETCHING,
  HTTP_PROFILE_FAILED,
  server
} from "../constants";

const setStateProfileToSuccess = payload => ({
  type: HTTP_PROFILE_SUCCESS,
  payload: payload
});

const setStateProfileToFetching = () => ({
  type: HTTP_PROFILE_FETCHING
});

const setStateProfileToFailed = () => ({
  type: HTTP_PROFILE_FAILED
});


export const getUserByUsername = (user_username) => {
  return async (dispatch, getState) => {
    dispatch(setStateProfileToFetching());
    try {

      var json = JSON.stringify({
        user_username: user_username
      });

      const token = localStorage.getItem("token");

      const result = await httpClient.post(server.PROFILE_URL, json, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      localStorage.setItem('user_profile',JSON.stringify(result.data));
      dispatch(setStateProfileToSuccess(result.data));
      getState().appReducer.app.forceUpdate();


    } catch (error) {
      dispatch(setStateProfileToFailed());
    }
  };
};


import {
  HTTP_LOGIN_FETCHING,
  HTTP_LOGIN_SUCCESS,
  HTTP_LOGIN_FAILED,
  server,
  YES,
} from "../constants";
import { httpClient } from "./../utils/HttpClient";
import Swal from "sweetalert2";
import "../actions/profile.action";
import { getUserByUsername} from "./profile.action";

export const setLoginStateToFetching = () => ({
  type: HTTP_LOGIN_FETCHING,
});

export const setLoginStateToSuccess = (payload) => ({
  type: HTTP_LOGIN_SUCCESS,
  payload,
});

export const setLoginStateToFailed = () => ({
  type: HTTP_LOGIN_FAILED,
});

export const autoLogin = (history) => {
  return () => {
    if (localStorage.getItem(server.LOGIN_PASSED) == YES) {
      setTimeout(() => history.push("/login"), 10000);
    }
  };
};

export const login = (history, credential) => {
  return async (dispatch, getState) => {
    dispatch(setLoginStateToFetching());
    try {
      const result = await httpClient.post(server.LOGIN_URL, JSON.stringify(credential), {
        headers: {
          "Content-Type": "application/json",
        },
      });

      localStorage.setItem(server.LOGIN_PASSED, YES);
      localStorage.setItem("token", result.data.accessToken);
      await dispatch(getUserByUsername(credential.user_username))
      getState().appReducer.app.forceUpdate();
      history.push("/dashboard");
      dispatch(setLoginStateToSuccess(result));
    } catch (error) {
      dispatch(setLoginStateToFailed());

      const errorMessage = error.response.data.message;
      Swal.fire({
        icon: "error",
        text: `${errorMessage}`,
        timer: 2000,
      });
    }
  };
};

import React, { Component } from "react";
import { getReportViewRank } from "../../../actions/reportViewRank.action";
import { getCategory } from "../../../actions/category.action";
import { getBooks } from "../../../actions/book.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
  FormSelect,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import MenuReport from "../menuReport";
import Autosuggest from "react-autosuggest";

class reportViewRank extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      value: ""
    };
  }

  async componentDidMount() {
    this.props.getReportViewRank();
    this.props.getCategory();

    await this.props.getBooks();
    this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
    });
  }

  formatTotalView(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.sum_total_view);
  }

  formatMemberView(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.member_total_view);
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY");
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  bindingCategoryName = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.categoryReducer;
      const options = result.map((item) => (
        <option key={item.category_id} value={item.category_name}>
          {item.category_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="category_name"
            id="category_name"
            placeholder=""
            onChange={handleChange}
            value={values.category_name}
          >
            <option value="">เลือกหมวดหมู่</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportViewRankReducer;
      const csv_name = "ViewRankReport.csv";

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="book_id"
                isKey={true}
                hidden={true}
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Book ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_title"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "left" }}
                csvHeader="ชื่อหนังสือ"
              >
                ชื่อหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sum_total_view"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatTotalView}
                csvFormat={this.formatTotalView}
                csvHeader="จำนวนครั้งที่เปิดอ่าน"
              >
                จำนวนครั้งที่เปิดอ่าน
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_total_view"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatMemberView}
                csvFormat={this.formatMemberView}
                csvHeader="เพิ่มเข้าชั้นหนังสือ"
              >
                เพิ่มเข้าชั้นหนังสือ
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="created_datetime"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatDateTime}
                csvFormat={this.formatDateTime}
                csvHeader="วันที่สร้าง"
              >
                วันที่สร้าง
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="updated_datetime"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatDateTime}
                csvFormat={this.formatDateTime}
                csvHeader="วันที่แก้ไขล่าสุด"
              >
                วันที่แก้ไขล่าสุด
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="updated_by"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="ชื่อผู้ทำการแก้ไขล่าสุด"
              >
                ชื่อผู้ทำการแก้ไขล่าสุด
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuReport />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">View Rank Report</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    category_name: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    if (values.category_name) {
                      value.categoryName = values.category_name;
                    }

                    value.bookTitle = this.state.bookTitle || this.state.value;

                    this.props.getReportViewRank(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col sm="5">
                            <FormGroup>
                              <label>หมวดหมู่</label>

                              {this.bindingCategoryName({
                                values,
                                handleChange,
                              })}
                            </FormGroup>
                          </Col>
                          <Col sm="5">
                            <FormGroup>
                              <label>ชื่อหนังสือ</label>

                              <div className="position-relative">
                                <Autosuggest
                                  suggestions={suggestions}
                                  onSuggestionsFetchRequested={
                                    this.onSuggestionsFetchRequested
                                  }
                                  onSuggestionsClearRequested={
                                    this.onSuggestionsClearRequested
                                  }
                                  shouldRenderSuggestions={
                                    this.shouldRenderSuggestions
                                  }
                                  onSuggestionSelected={this.onSuggestionSelected}
                                  getSuggestionValue={this.getSuggestionValue}
                                  renderSuggestion={this.renderSuggestion}
                                  inputProps={inputProps}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm="2">
                            <FormGroup>
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary"
                                style={{
                                  marginTop: 27
                                }}
                              >
                                Search
                              </button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportViewRankReducer, categoryReducer, bookReducer }) => ({
  reportViewRankReducer,
  categoryReducer,
  bookReducer
});

const mapDispatchToProps = {
  getReportViewRank,
  getCategory,
  getBooks
};

export default connect(mapStateToProps, mapDispatchToProps)(reportViewRank);

import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Row,
  Col,
  FormInput,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormSelect,
} from "shards-react";
import { Table, Tag, Button, Skeleton, Modal, DatePicker, message } from "antd";
import { httpClient } from "../../../utils/HttpClient";
import { LoadingOutlined, EditOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

export default function WriterWithdraw() {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [isModalVisible, setModalVisible] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const [approvedDate, setApprovedDate] = useState(null);

  useEffect(() => {
    getWithdrawTransaction();
  }, []);

  useEffect(() => {
    getWithdrawTransaction();
  }, [name, email, status, type, pagination.current]);

  const getWithdrawTransaction = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get("admin/member/withdraw", {
        params: {
          name: name,
          email: email,
          status: status,
          type: type,
          page: pagination.current - 1,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const transactions = result.data.transactions.map((item, index) => {
        return {
          key: item.id,
          member_id: item.member_id,
          name: item.member_name,
          email: item.member_email,
          goldCoin: item.gold_coin.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
          silverCoin: item.silver_coin.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
          amount: item.amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          }),
          createdDate: item.created_datetime,
          approvedDate: item.updated_datetime,
          approvedDateDefault: item.updated_datetime,
          status: item.status,
        };
      });

      setTransactions(transactions);
      setPagination({
        ...pagination,
        total: result.data.total,
      });
      setLoading(false);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setLoading(false);
    }
  };

  const updateFilter = (value, filterType) => {
    if (filterType === "name") {
      setName(value);
    } else if (filterType === "email") {
      setEmail(value);
    } else if (filterType === "status") {
      setStatus(value);
    } else if (filterType === "type") {
      setType(value);
    }
  };

  const updateConfirmation = (id, status) => {
    Swal.fire({
      text: `${status === "2" ? "ยืนยัน" : "ยกเลิก"}คำขอถอนเงิน?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        updateStatus(id, status);
      }
    });
  };

  const updateStatus = async (id, status) => {
    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    try {
      const token = localStorage.getItem("token");
      await httpClient.patch(
        `/admin/member/withdraw/${id}`,
        JSON.stringify({
          status: status,
          updated_by: username,
          updated_datetime: new Date(),
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      getWithdrawTransaction();
      message.open({ content: "บันทึกสำเร็จ" });
    } catch (err) {
      message.open({ content: "ไม่สามารถบันทึกข้อมูลได้" });
    }
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const editDate = (row) => {
    setActiveRecord(row);
    setApprovedDate(
      row.approvedDateDefault ? moment(row.approvedDateDefault) : null
    );
    openModal();
  };

  const updateApprovedDate = async () => {
    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    try {
      setUpdating(true);
      const token = localStorage.getItem("token");
      await httpClient.patch(
        `/admin/member/withdraw/${activeRecord.key}`,
        JSON.stringify({
          status: activeRecord.status,
          updated_by: username,
          updated_datetime: approvedDate,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      getWithdrawTransaction();
      setApprovedDate(null);
      setActiveRecord(null);
      setUpdating(false);
      closeModal();
      message.open({ content: "บันทึกสำเร็จ" });
    } catch (err) {
      setUpdating(false);
      message.open({ content: "ไม่สามารถบันทึกข้อมูลได้" });
    }
  };

  const handleDateChange = (value) => {
    setApprovedDate(value);
  };

  const columns = [
    {
      title: "ชื่อ",
      dataIndex: "name",
      key: "name",
      render: (_, row) => {
        return <a href={`/editWriter/${row.member_id}`}>{row.name}</a>;
      },
    },
    {
      title: "อีเมล",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "เหรียญทอง",
      dataIndex: "goldCoin",
      key: "goldCoin",
      align: "center",
    },
    {
      title: "เหรียญเงิน",
      dataIndex: "silverCoin",
      key: "silverCoin",
      align: "center",
    },
    {
      title: "คิดเป็นเงิน (บาท)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
    },
    {
      title: "วันที่ทำรายการ",
      dataIndex: "createdDate",
      key: "createdDate",
      align: "center",
      render: (_, row) => moment(row.created_datetime).format("DD/MM/YYYY"),
    },
    {
      title: "วันที่โอนเงิน",
      dataIndex: "approvedDate",
      key: "approvedDate",
      align: "center",
      render: (_, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-1">
              {moment(row.approvedDate).format("DD/MM/YYYY")}
            </span>
            <EditOutlined className="pointer" onClick={() => editDate(row)} />
          </div>
        );
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, { status }) => {
        let color;
        let text;

        if (status === "1") {
          color = "#ffb703";
          text = "รอการยืนยัน";
        } else if (status === "2") {
          color = "#87d068";
          text = "จ่ายแล้ว";
        } else if (status === "3") {
          color = "#c1121f";
          text = "ยกเลิก";
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, { status, key }) => (
        <div className="text-nowrap">
          <button
            type="button"
            className="btn btn-xs btn-secondary mr-2"
            onClick={() => updateConfirmation(key, "2")}
            disabled={status === "2" ? true : false}
          >
            ยืนยัน
          </button>
          <button
            type="button"
            className="btn btn-xs btn-danger"
            onClick={() => updateConfirmation(key, "3")}
            disabled={status === "3" ? true : false}
          >
            ยกเลิก
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid">
        <div style={{ padding: 10 }}>
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="mt-1">ประวัติการถอนเงิน</h6>
            </CardHeader>
            <CardBody className="p-3">
              <div className="row">
                <div className="col-md-3">
                  <FormGroup>
                    <label>ชื่อ</label>
                    <FormInput
                      placeholder="Name"
                      onChange={(e) => updateFilter(e.target.value, "name")}
                      value={name}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>อีเมล</label>
                    <FormInput
                      placeholder="Email"
                      onChange={(e) => updateFilter(e.target.value, "email")}
                      value={email}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>สถานะ</label>
                    <FormSelect
                      name="order_status"
                      id="order_status"
                      placeholder=""
                      onChange={(e) => updateFilter(e.target.value, "status")}
                    >
                      <option value="">ทั้งหมด</option>
                      <option value="1">รอการยืนยัน</option>
                      <option value="2">จ่ายแล้ว</option>
                      <option value="3">ยกเลิก</option>
                    </FormSelect>
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>ประเภท</label>
                    <FormSelect
                      name="order_status"
                      id="order_status"
                      placeholder=""
                      onChange={(e) => updateFilter(e.target.value, "type")}
                    >
                      <option value="">ทั้งหมด</option>
                      <option value="2">นักเขียน</option>
                      <option value="3">บรรณาธิการ</option>
                      <option value="4">สำนักพิมพ์</option>
                    </FormSelect>
                  </FormGroup>
                </div>
              </div>

              <Table
                columns={columns}
                dataSource={transactions}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
              />
            </CardBody>
          </Card>
        </div>
      </div>

      <Modal
        title={null}
        footer={null}
        width={400}
        visible={isModalVisible}
        onCancel={closeModal}
      >
        <FormGroup>
          <label>วันที่โอน</label>
          <DatePicker
            className="w-100 mt-2"
            placeholder="เลือกวันที่"
            format="DD/MM/YYYY"
            value={approvedDate}
            onChange={handleDateChange}
          />
        </FormGroup>

        <div className="mt-4 text-right">
          <Button
            className="btn btn-primary mr-3"
            loading={updating}
            onClick={() => updateApprovedDate()}
          >
            บันทึก
          </Button>
          <Button
            className="btn btn-secondary"
            disabled={updating}
            onClick={closeModal}
          >
            ยกเลิก
          </Button>
        </div>
      </Modal>
    </>
  );
}

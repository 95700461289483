import React, { Component } from "react";
import {
  getAllWriterRecommend,
  deleteWriterRecommend,
} from "../../../actions/writerRecommend.action";
import { getWriterName } from "../../../actions/member.action";

import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  Button,
  FormSelect,
  FormGroup
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import Swal from "sweetalert2";
import ContentPagination from "../../Layout/Common/Pagination";
import { server } from "../../../constants";
import { httpClient } from "../../../utils/HttpClient";
import NumberFormat from "react-number-format";

class WriterRecommend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      writers: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      ModalOpen: false,
      member_id: "",
      order: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      ModalOpen: !this.state.ModalOpen,
      member_id: "",
      order: "",
    });
  }

  closeModal() {
    this.setState({
      ModalOpen: !this.state.ModalOpen,
    });
  }

  async addWriterRecommend(member_id, order) {
    if(!member_id){
      Swal.fire({
        icon: "error",
        text: "กรุณาระบุ นักเขียน",
        timer: 1500,
      });
      return;
    }

    if(!order){
      Swal.fire({
        icon: "error",
        text: "กรุณาระบุ ลำดับการแสดงผล",
        timer: 1500,
      });
      return;
    }

    let result = localStorage.getItem("user_profile");
    let username;
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let formData = new FormData();

    formData.append("member_id", member_id);
    formData.append("order", order);
    formData.append("dbstatus", "A");
    formData.append("created_datetime", dateTime);
    formData.append("created_by", username);

    formData.append("updated_datetime", dateTime);
    formData.append("updated_by", username);

    let object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    let json = JSON.stringify(object);

    let response = "";

    const token = localStorage.getItem("token");
    response = await httpClient.post(`${server.WRITER_RECOMMEND_URL}`, json, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (response.status === 201 || response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });

      this.setState({
        ModalOpen: false,
      });

      this.getWriterRecommend(this.state.currentPage);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
        timer: 1500,
      });
    }
  }

  async componentDidMount() {
    await this.getWriterRecommend(this.state.currentPage);
    if (this.props.writerRecommendReducer.writers) {
      this.setState({ writers: this.props.writerRecommendReducer.writers });
    }
    this.props.getWriterName();
  }

  bindingWriterName = ({ handleChange, values }) => {
    try {
      const { writers, isFetching } = this.props.memberReducer;

      const options = writers.map((item) => (
        <option key={item.value} value={item.member_id}>
          {item.writer_name}
        </option>
      ));

      return (
        !isFetching &&
        writers != null && (
          <FormSelect
            name="member_id"
            id="member_id"
            placeholder=""
            onChange={handleChange}
            value={values.member_id}
          >
            <option value="">Select Writer</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  async getWriterRecommend(page) {
    this.setState({ loading: true });

    const value = {};
    value.page = page;
    await this.props.getAllWriterRecommend(value);

    const { writers, total, isFetching } = this.props.writerRecommendReducer;
    if (writers && !isFetching) {
      const totalPages = Math.ceil(total / this.state.perPage);

      this.setState({
        writers,
        totalPages,
        loading: false,
      });
    }
  }

  formatDateTime(value) {
    if (value) {
      return moment(value)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatAction(id) {
    let username = "";

    var result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    return (
      <div>
        <button
          type="button"
          className="btn btn-xs btn-danger"
          onClick={() => {
            return Swal.fire({
              title: "Are you sure to delete?",
              text: "You won't be able to revert this!",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, cancel!",
            }).then((result) => {
              if (result.value) {
                this.deleteWriterRecommend(id);
              }
            });
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  async deleteWriterRecommend(recommend_id) {
    await this.props.deleteWriterRecommend(
      this.state.currentPage,
      recommend_id
    );
    setTimeout(() => {
      this.getWriterRecommend(this.state.currentPage);
    }, 100);
  }

  renderTable = () => {
    return (
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">รูปนักเขียน</th>
              <th className="text-center">ชื่อนักเขียน</th>
              <th className="text-center">รายละเอียดนักเขียน</th>
              <th className="text-center">ลำดับการแสดงผล</th>
              <th className="text-center">Last Update</th>
              <th className="text-center">Update By</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.writers.map((writer, index) => {
              return (
                <tr key={writer.recommend_id}>
                  <td className="text-center align-middle" width="80">
                    <img
                      style={{ borderRadius: 50 }}
                      id="member_image"
                      name="member_image"
                      src={writer.member_image}
                      width="80px"
                      height="80px"
                    />
                  </td>

                  <td className="text-center align-middle" width="100">
                    {writer.member_name}
                  </td>

                  <td className="text-center align-middle" width="220">
                    {writer.member_history}
                  </td>

                  <td className="text-center align-middle" width="10">
                    {writer.order}
                  </td>

                  <td className="text-center align-middle" width="60">
                    {this.formatDateTime(writer.updated_datetime)}
                  </td>

                  <td className="text-center align-middle" width="80">
                    {writer.updated_by}
                  </td>

                  <td className="text-center align-middle" width="30">
                    {this.formatAction(writer.recommend_id)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Modal open={this.state.ModalOpen} toggle={this.toggleModal}>
          <ModalHeader>เพิ่มนักเขียนแนะนำ</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                member_id: "",
                order: "",
              }}
              render={({ values, handleChange, setFieldValue }) => (
                <React.Fragment>
                  <Row form>
                    <Col md="12" className="form-group">
                      <FormGroup>
                        <label>ผู้เขียน</label>

                        {this.bindingWriterName({
                          values,
                          handleChange,
                        })}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="12" className="form-group">
                      <label>ลำดับ</label>
                      <NumberFormat
                        customInput={FormInput}
                        thousandSeparator={true}
                        
                        inputMode="numeric"
                        name="order"
                        id="order"
                        placeholder=""
                        onChange={handleChange}
                        value={values.order}
                      />
                    </Col>
                  </Row>

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-primary pull-right"
                      onClick={() => {
                        this.addWriterRecommend(values.member_id, values.order);
                      }}
                      style={{
                        marginRight: 10,
                        color: "white",
                        width: 100,
                      }}
                    >
                      บันทึก
                    </button>
                    <button
                      onClick={(e) => this.closeModal()}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </React.Fragment>
              )}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getWriterRecommend(page);
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">นักเขียนแนะนำ</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={(e) => this.toggleModal()}
                      className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      เพิ่มนักเขียนแนะนำ
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.writers.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ writerRecommendReducer, memberReducer }) => ({
  writerRecommendReducer,
  memberReducer,
});

const mapDispatchToProps = {
  getAllWriterRecommend,
  deleteWriterRecommend,
  getWriterName,
};

export default connect(mapStateToProps, mapDispatchToProps)(WriterRecommend);

import React, { Component } from "react";
import { Nav, Row, Col, Container } from "shards-react";

import UserActions from "./UserAction";
import { connect } from "react-redux";
import { Dispatcher, Constants } from "../../../../../flux";

class NavbarNav extends Component {
  toggleSidebar() {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  render() {
    return (
      <Container fluid className="main-content-container">
        <Nav className="flex-row">
          <a href="#" onClick={this.toggleSidebar} className="nav-link nav-link-icon toggle-sidebar d-inline text-center">
            <i className="material-icons">&#xE5D2;</i>
          </a>
        </Nav>

        <Row>
          <Nav navbar className="border-left flex-row">
            <UserActions className="flex-end" />
          </Nav>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ appReducer }) => ({
  appReducer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarNav);

import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink, Collapse } from "shards-react";

class ContentPagination extends React.Component {
  constructor(props) {
    super(props)
  }

  async componentDidMount() {
    
  }

  render()  {
    const remainingPages = 5 - (this.props.currentPage%5)
    const prevPage = this.props.currentPage - remainingPages
    const nextPage = this.props.currentPage + remainingPages

    return (
      <ul className="pagination justify-content-end">
        { (this.props.currentPage !== 0 && this.props.totalPages >= 5) &&
          <li className="page-item">
            <a 
              className="page-link text-muted pointer" 
              aria-label="Previous"
              onClick={() => {
                this.props.onPageChange(0)
              }}
            >
              <span aria-hidden="true">หน้าแรก</span>
            </a>
          </li>
        }
        { prevPage >= 0 && 
          <li className="page-item">
            <a 
              className="page-link text-muted pointer" 
              aria-label="Previous"
              onClick={() => {
                this.props.onPageChange(prevPage)
              }}
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
        }
        {
          Array.from(Array(this.props.totalPages), (e, index) => {
            let pageShow = this.props.pageShow
            let isShown = false

            if(this.props.currentPage < pageShow)  {
              if(index < pageShow) isShown = true;
            } else if(this.props.currentPage >= this.props.totalPages - pageShow) {
              if(index >= this.props.totalPages - pageShow) isShown = true;
            } else {
              let pageListNumber = Math.ceil((this.props.currentPage + 1)/pageShow)
              if(pageListNumber == 0) pageListNumber = 1

              let pageStart = (pageListNumber - 1)*pageShow
              let pageEnd = (pageListNumber)*pageShow

              if(index >= pageStart && index < pageEnd) isShown = true;
            }

            if(isShown) {
              return (
                <li className={"page-item " + (this.props.currentPage == index ? "active" : "")} key={index}>
                  <a 
                    className="page-link pointer" 
                    onClick={() => {
                      this.props.onPageChange(index)
                    }}
                  >{index + 1}</a>
                </li>
              )
            }
          })
        }
        { 
          nextPage <= this.props.totalPages &&
            <li className="page-item">
              <a 
                className="page-link text-muted pointer" 
                aria-label="Next"
                onClick={() => {
                  this.props.onPageChange(nextPage)
                }}
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
        }
        { 
          (this.props.currentPage !== this.props.totalPages - 1 && this.props.totalPages >= 5) &&                
            <li className="page-item">
              <a 
                className="page-link text-muted pointer" 
                aria-label="Next"
                onClick={() => {
                  this.props.onPageChange(this.props.totalPages - 1)
                }}
              >
                <span aria-hidden="true">หน้าสุดท้าย</span>
              </a>
            </li>
        }
      </ul>
    )
  }
}

export default ContentPagination;

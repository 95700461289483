import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import Swal from "sweetalert2";
import CampaignFrom from "../campaignForm";
import * as Yup from "yup";
import moment from "moment";

const campaignValidationSchema = Yup.object().shape({
  campaign_name: Yup.string().required("กรุณากรอกชื่อ Campaign"),
  campaign_desc: Yup.string().required("กรุณากรอกรายละเอียด Campaign"),
  campaign_coin: Yup.string().required("กรุณากรอกจำนวนเหรียญรางวัล"),
  member_silver_coin_expire: Yup.date().required('กรุณาเลือกวันที่หมดอายุ').nullable()
});

class CreateCampaign extends Component {
  constructor() {
    super();
    this.state = { img_campaign: null };
    this.change = this.change.bind(this);
  }

  change(img) {
    this.setState({ img_campaign: img });
  }

  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 40 }}>
        <Card>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD CAMPAIGN</h6>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <CampaignFrom
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isDisableField={false}
                    errors={errors}
                    touched={touched}
                    change={this.change}
                  ></CampaignFrom>

                  <div className="text-right">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      บันทึก
                    </button>
                    <button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            campaign_name: "",
            campaign_desc: "",
            campaign_coin: "",
            limit_no: "",
            receive_coin: "",
            chapter_goal: "",
            days_goal: "",
            amount_goal: "",
            start_date: "",
            end_date: "",
            pushTarget: "",
            campaign_repeat: "N",
            number_repeat: "",
            daily_checkin_receive: "",
            category_id: "",
            book_id: "",
            book_title: "",
            is_visible: "N",
            img_campaign: "",
          }}
          validationSchema={campaignValidationSchema}
          validate={(values, props) => {
            const errors = {};

            if (values.pushTarget === "checkin") {
              if (!values.days_goal) {
                errors.days_goal = "กรุณากรอก วันที่ Check-in";
              }
            }

            if (values.pushTarget === "chapter") {
              if (!values.chapter_goal) {
                errors.chapter_goal = "กรุณากรอก จำนวน Chapter ที่ซื้อ";
              }
            }

            if (values.pushTarget === "topup") {
              if (!values.amount_goal) {
                errors.amount_goal = "กรุณากรอก ยอดการเติมเงิน";
              }
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let formData = new FormData();

            formData.append("campaign_name", values.campaign_name);

            if (this.state.img_campaign)
              formData.append("campaign_image", this.state.img_campaign);

            formData.append("campaign_desc", values.campaign_desc);

            var campaignCoin = Number(
              values.campaign_coin.toString().replace(/,/g, "")
            );
            var limitNo = Number(values.limit_no.toString().replace(/,/g, ""));
            var receiveCoin = Number(
              values.receive_coin.toString().replace(/,/g, "")
            );
            var chapterGoal = Number(
              values.chapter_goal.toString().replace(/,/g, "")
            );
            var dayGoal = Number(values.days_goal.toString().replace(/,/g, ""));
            var amountGoal = Number(
              values.amount_goal.toString().replace(/,/g, "")
            );

            var numRepeat = Number(
              values.number_repeat.toString().replace(/,/g, "")
            );

            var dailyCheckin = Number(
              values.daily_checkin_receive.toString().replace(/,/g, "")
            );

            formData.append("campaign_coin", campaignCoin);
            formData.append("limit_no", limitNo);
            formData.append("receive_coin", receiveCoin);
            formData.append("chapter_goal", chapterGoal);
            formData.append("days_goal", dayGoal);
            formData.append("amount_goal", amountGoal);

            let startDate = moment(values.start_date)
              .format("YYYY-MM-DD HH:mm:ss");
            formData.append("start_date", startDate);

            let endDate = moment(values.end_date)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");

            formData.append("end_date", endDate);

            formData.append("campaign_repeat", values.campaign_repeat);
            formData.append("number_repeat", numRepeat);
            formData.append("is_visible", values.is_visible);
            formData.append("daily_checkin_receive", dailyCheckin);

            if (values.category_id)
              formData.append(
                "category_id",
                Number(values.category_id.toString().replace(/,/g, ""))
              );

            if (values.book_id)
              formData.append(
                "book_id",
                Number(values.book_id.toString().replace(/,/g, ""))
              );

            formData.append("book_title", values.book_title);

            let coinExpire = moment(values.member_silver_coin_expire)
              .endOf("day")
              .format("YYYY-MM-DD HH:mm:ss");

            formData.append("member_silver_coin_expire", coinExpire);

            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;
            formData.append("created_by", username);

            var dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
            formData.append("created_datetime", dateTime);

            formData.append("campaign_status", "A");

            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            const token = localStorage.getItem("token");
            let response = await httpClient.post(server.CAMPAIGN_URL, json, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Campaign ถูกบันทึกเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 1500,
              });
              setSubmitting(false);
              this.props.history.goBack();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                timer: 1500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

export default CreateCampaign;

import { httpClient } from "../utils/HttpClient";
import { server } from "../constants";

import {
  HTTP_EDITOR_SUCCESS,
  HTTP_EDITOR_FETCHING,
  HTTP_EDITOR_FAILED,
} from "../constants/editor";

const setStateEditorToSuccess = (payload) => ({
  type: HTTP_EDITOR_SUCCESS,
  payload: payload,
});

const setStateEditorToFetching = () => ({
  type: HTTP_EDITOR_FETCHING,
});

const setStateEditorToFailed = () => ({
  type: HTTP_EDITOR_FAILED,
});

export const getWriterByEditorID = (value) => {
  return async (dispatch) => {
    dispatch(setStateEditorToFetching());
    await doGetWriterByEditorID(dispatch, value);
  };
};

const doGetWriterByEditorID = async (dispatch, value) => {
  try {
    let param = {};
    if (value) {
      param = {
        editor_id: value.editor_id,
        page: value.page,
      };
    } else {
      param = {
        page: "",
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.EDITOR_URL}/writer`, {
      params: param,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(setStateEditorToSuccess(result.data));
  } catch (err) {
    dispatch(setStateEditorToFailed());
  }
};

export const deleteWriter = (value) => {
  return async (dispatch) => {
    dispatch(setStateEditorToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      `${server.EDITOR_URL}/${value.id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result) {
      alert("Delete Complete");
      await doGetWriterByEditorID(dispatch, value);
    }
  };
};

import React, { Component } from "react";
import { getReportWriter } from "../../actions/reportWriter.action";
import { getBooks } from "../../actions/book.action";
import { getWriterName } from "../../actions/member.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormSelect,
  FormGroup,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import Autosuggest from "react-autosuggest";

class ReportWriters extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      value: "",
      start_date: "",
      end_date: "",
    };
  }

  bindingWriterName = ({ handleChange, values }) => {
    try {
      const { writers, isFetching } = this.props.memberReducer;

      const options = writers.map((item) => (
        <option key={item.value} value={item.writer_name}>
          {item.writer_name}
        </option>
      ));

      return (
        !isFetching &&
        writers != null && (
          <FormSelect
            name="writer_name"
            id="writer_name"
            placeholder=""
            onChange={handleChange}
            value={values.writer_name}
          >
            <option value="">Select Writer</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  async componentDidMount() {
    this.props.getReportWriter();
    this.props.getWriterName();

    await this.props.getBooks();
    this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  formatDecimal(row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row);
  }

  btnFormatter(cell, row) {
    return (
      <React.Fragment>
        <a
          type="button"
          className="btn btn-xs btn-success text-white"
          href={"/report/writers/detail/" + row.member_id}
        >
          View
        </a>
      </React.Fragment>
    );
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportWriterReducer;
      const csv_name = "WriterReport.csv";

      var data = [];
      if (result) {
        data = result;

        let date_now = moment(Date()).format("DD/MM/YY");

        let date = "";
        if (this.state.start_date) {
          date = this.state.start_date + " - " + date_now;
        }

        if (this.state.end_date) {
          if (this.state.start_date) {
            date = this.state.start_date + " - " + this.state.end_date;
          } else date = "XXX - " + this.state.end_date;
        }

        if (date === "") date = "XXX  -  " + date_now;

        data.forEach(function(e) {
          if (typeof e === "object") {
            e["date"] = date;
          }
        });


        var writer_result = data;
      }

      return (
        !isFetching &&
        writer_result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={writer_result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="date"
                width="120px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="วันที่"
              >
                วันที่
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_id"
                isKey={true}
                hidden={true}
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Member ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_name"
                width="130px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="ชื่อนักเขียน"
              >
                ชื่อนักเขียน
              </TableHeaderColumn>


              <TableHeaderColumn
                dataField="sum_gold_coin"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="Gold Revenue"
                dataFormat={this.formatDecimal}
                csvFormat={this.formatDecimal}
              >
                Gold Revenue
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sum_silver_coin"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="Silver Revenue"
                dataFormat={this.formatDecimal}
                csvFormat={this.formatDecimal}
              >
                Silver Revenue
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="total_receive_coin"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="Total Revenue"
                dataFormat={this.formatDecimal}
                csvFormat={this.formatDecimal}
              >
                Total Revenue
              </TableHeaderColumn>

              <TableHeaderColumn
                dataFormat={this.btnFormatter.bind(this)}
                headerAlign="center"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                export={false}
              >
                Detail
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">
                      Writer Sale Report (จำนวนเงินใน Wallet ของนักเขียน)
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    book_title: "",
                    writer_name: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;

                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );

                      let format_date = moment(values.start_date).format(
                        "DD/MM/YY"
                      );
                      this.setState({ start_date: format_date });
                    } else this.setState({ start_date: "" });

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");

                      let format_date = moment(endDate).format("DD/MM/YY");
                      this.setState({ end_date: format_date });
                    } else {
                      this.setState({ end_date: "" });
                      endDate = moment(new Date())
                        .format("YYYY-MM-DD");
                    }

                    value.start = startDate;
                    value.end = endDate;

                    // values.book_title = this.state.bookTitle;
                    // if (values.book_title) {
                    //   value.bookTitle = values.book_title;
                    // }

                    if (values.writer_name) {
                      value.writerName = values.writer_name;
                    }

                    this.props.getReportWriter(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col sm="4">
                            <FormGroup>
                              <label>ผู้เขียน</label>

                              {this.bindingWriterName({
                                values,
                                handleChange,
                              })}
                            </FormGroup>
                          </Col>

                          <Col sm="2" className="text-right">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm"
                              style={{
                                marginLeft: 30,
                                marginTop: 30,
                                color: "white",
                                width: 100,
                              }}
                            >
                              Search
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  reportWriterReducer,
  bookReducer,
  memberReducer,
}) => ({
  reportWriterReducer,
  bookReducer,
  memberReducer,
});

const mapDispatchToProps = {
  getReportWriter,
  getBooks,
  getWriterName,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportWriters);

export const HTTP_BOOKS_FETCHING = "HTTP_BOOKS_FETCHING";
export const HTTP_BOOKS_SUCCESS = "HTTP_BOOKS_SUCCESS";
export const HTTP_BOOKS_FAILED = "HTTP_BOOKS_FAILED";

export const HTTP_BOOK_FETCHING = "HTTP_BOOK_FETCHING";
export const HTTP_BOOK_SUCCESS = "HTTP_BOOK_SUCCESS";
export const HTTP_BOOK_FAILED = "HTTP_BOOK_FAILED";

export const HTTP_BOOKS_LABEL_FETCHING = "HTTP_BOOKS_LABEL_FETCHING";
export const HTTP_BOOKS_LABEL_SUCCESS = "HTTP_BOOKS_LABEL_SUCCESS";
export const HTTP_BOOKS_LABEL_FAILED = "HTTP_BOOKS_LABEL_FAILED";

export const HTTP_BOOK_LABEL_FETCHING = "HTTP_BOOK_LABEL_FETCHING";
export const HTTP_BOOK_LABEL_SUCCESS = "HTTP_BOOK_LABEL_SUCCESS";
export const HTTP_BOOK_LABEL_FAILED = "HTTP_BOOK_LABEL_FAILED";

export const HTTP_BOOK_MESSAGE_FETCHING = "HTTP_BOOK_MESSAGE_FETCHING";
export const HTTP_BOOK_MESSAGE_SUCCESS = "HTTP_BOOK_MESSAGE_SUCCESS";
export const HTTP_BOOK_MESSAGE_FAILED = "HTTP_BOOK_MESSAGE_FAILED";
export const HTTP_BOOK_MESSAGE_ADDED = "HTTP_BOOK_MESSAGE_ADDED";
export const HTTP_BOOK_MESSAGE_UPDATED = "HTTP_BOOK_MESSAGE_UPDATED";
export const HTTP_BOOK_MESSAGE_DELETED = "HTTP_BOOK_MESSAGE_DELETED";

export const HTTP_BOOK_CHARACTER_FETCHING = "HTTP_BOOK_CHARACTER_FETCHING";
export const HTTP_BOOK_CHARACTER_SUCCESS = "HTTP_BOOK_CHARACTER_SUCCESS";
export const HTTP_BOOK_CHARACTER_FAILED = "HTTP_BOOK_CHARACTER_FAILED";
export const HTTP_BOOK_CHARACTER_ADDED = "HTTP_BOOK_CHARACTER_ADDED";
export const HTTP_BOOK_CHARACTER_UPDATED = "HTTP_BOOK_CHARACTER_UPDATED";
export const HTTP_BOOK_CHARACTER_DELETED = "HTTP_BOOK_CHARACTER_DELETED";
export const HTTP_BOOK_CHARACTER_MAIN_UPDATED = "HTTP_BOOK_CHARACTER_MAIN_UPDATED";
import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../../constants";
import { Card, CardHeader, CardBody, Row, Col } from "shards-react";
import { httpClient } from "../../../../utils/HttpClient";
import Swal from "sweetalert2";
import BannerForm from "../bannerForm";
import * as Yup from "yup";
import moment from "moment";

const bannerValidationSchema = Yup.object().shape({
  pushTarget: Yup.string().required("กรุณากรอก ประเภท Banner"),
  banner_type: Yup.string().required("กรุณากรอก ตำแหน่ง Banner"),
  banner_status: Yup.string().required("กรุณากรอก แสดง/ไม่แสดง"),
  startdate: Yup.string().required("กรุณากรอก วันที่เริ่มต้น"),
  enddate: Yup.string().required("กรุณากรอก วันที่สิ้นสุด"),
});

class CreateBanner extends Component {
  constructor() {
    super();
    this.state = { img_web: null, img_mobile: null };
    this.change = this.change.bind(this);
  }

  change(img, type) {
    if (type === "web") {
      this.setState({ img_web: img });
    } else if (type === "mobile") {
      this.setState({ img_mobile: img });
    }
  }

  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 40 }}>
        <Card>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD BANNER</h6>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <BannerForm
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    value={this.state.value}
                    change={this.change}
                  ></BannerForm>

                  <div className="text-right">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      บันทึก
                    </button>
                    <button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            banner_name: "",
            banner_url: "",
            banner_type: "",
            banner_desc: "",
            img_web: "",
            banner_image_web: "",
            img_mobile: "",
            banner_image_mobile: "",
            startdate: "",
            enddate: "",
            banner_status: "",
            banner_image: "",
            book_id: "",
            book_title: "",
            action: "add",
          }}
          validationSchema={bannerValidationSchema}
          validate={(values, props) => {
            const errors = {};

            if (values.pushTarget === "book") {
              if (!values.book_id) {
                errors.book_id = "กรุณากรอก ชื่อหนังสือ";
              }
            }

            if (values.pushTarget === "other") {
              if (!values.banner_name) {
                errors.banner_name = "กรุณากรอก ชื่อ Banner";
              }

              if (!values.banner_url) {
                errors.banner_url = "กรุณากรอก ลิงค์แบนเนอร์";
              }
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let formData = new FormData();

            const bookId = values.pushTarget === 'book' ? values.book_id : -1
            const bannerUrl = values.pushTarget === 'other' ? values.banner_url : ''
            const mobileBannerUrl = values.pushTarget === 'other' ? values.banner_mobile_url : ''

            if (values.banner_name)
              formData.append("banner_name", values.banner_name);

            formData.append("book_id", bookId);

            if (values.norder)
              formData.append(
                "norder",
                Number(values.norder.toString().replace(/,/g, ""))
              );

            if (values.banner_desc)
              formData.append("banner_desc", values.banner_desc);
            else formData.append("banner_desc", "");

            formData.append("banner_url", bannerUrl);
            formData.append("banner_mobile_url", mobileBannerUrl || bannerUrl);

            formData.append("banner_status", values.banner_status);

            formData.append("banner_type", values.banner_type);

            formData.append("banner_image", this.state.img_mobile);

            formData.append("banner_image_mobile", this.state.img_mobile);

            if (values.startdate)
              formData.append(
                "startdate",
                moment(values.startdate).format("YYYY-MM-DD")
              );

            if (values.enddate)
              formData.append(
                "enddate",
                moment(values.enddate).format("YYYY-MM-DD")
              );

            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;
            formData.append("created_by", username);
            formData.append("updated_by", username);

            var dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
            formData.append("created_datetime", dateTime);
            formData.append("updated_datetime", dateTime);

            formData.append("dbstatus", "A");

            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            const token = localStorage.getItem("token");
            let response = await httpClient.post(server.BANNER_URL, json, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            });

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Banner ถูกบันทึกเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 1500,
              });
              setSubmitting(false);
              this.props.history.goBack();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                timer: 1500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

export default CreateBanner;

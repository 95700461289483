import React, { Component } from "react";
import * as actions from "../../../actions/campaign.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
} from "shards-react";

import moment from "moment";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import Swal from "sweetalert2";
import ContentPagination from "../../Layout/Common/Pagination";

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
    };
  }

  async componentDidMount() {
    await this.getAllCampaign(this.state.currentPage);
  }

  async getAllCampaign(page) {
    this.setState({ loading: true });

    await this.props.getAllCampaign(page);
    const { campaigns, total } = this.props.campaignReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.campaignReducer.campaigns) {
      this.setState({
        campaigns,
        totalPages,
      });
    }
    this.setState({ loading: false });
  }

  formatDate(date) {
    if (date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  async changeCampaignStatus(campaign) {
    const newStatus = campaign.campaign_status === "A" ? "I" : "A";
    let newCampaigns = this.state.campaigns.map((newCampaign) => {
      return {
        ...newCampaign,
        campaign_status: newCampaign.campaign_id === campaign.campaign_id ? newStatus : newCampaign.campaign_status
      }
    });

    this.setState({ campaigns: newCampaigns });

    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let params = {
      campaign_status: newStatus,
      updated_by: username,
      updated_datetime: dateTime,
    };

    const token = localStorage.getItem("token");
    let response = await httpClient.patch(
      `${server.CAMPAIGN_URL}/status/${campaign.campaign_id}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 201 && response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    }
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>ชื่อ Campaign</th>
            <th style={{ width: "20%" }}>รายละเอียด Campaign</th>
            <th style={{ textAlign: "center" }}>เหรียญรางวัล</th>
            <th style={{ textAlign: "center" }}>วันเริ่มต้น</th>
            <th style={{ textAlign: "center" }}>วันสิ้นสุด</th>
            <th style={{ textAlign: "center" }}>Active</th>
            <th style={{ textAlign: "center" }}></th>
          </tr>
        </thead>
        <tbody>
          {this.state.campaigns.map((item, index) => {
            return (
              <tr key={item.campaign_id}>
                <td>{item.campaign_name}</td>
                <td>{item.campaign_desc}</td>
                <td style={{ textAlign: "center" }}>
                  {new Intl.NumberFormat("en-GB", {
                    style: "decimal",
                  }).format(item.campaign_coin)}
                </td>

                <td style={{ textAlign: "center" }}>
                  {this.formatDate(item.start_date)}
                </td>

                <td style={{ textAlign: "center" }}>
                  {this.formatDate(item.end_date) || "-"}
                </td>

                <td className="text-center align-middle">
                  <FormCheckbox
                    toggle
                    inline
                    checked={item.campaign_status === "A" ? true : false}
                    onChange={() => this.changeCampaignStatus(item)}
                    className="mr-0 ml-2"
                  />
                </td>

                <td style={{ textAlign: "center" }}>
                  <button
                    type="button"
                    className="btn btn-sm btn-success mr-2"
                    onClick={() =>
                      this.props.history.push(
                        `/editCampaign/${item.campaign_id}`
                      )
                    }
                  >
                    Edit
                  </button>

                  {item.campaign_id !== "0" &&
                    item.campaign_id !== "1" &&
                    item.campaign_id !== "2" &&
                    item.campaign_id !== "3" && (
                      <button
                        onClick={() => {
                          Swal.fire({
                            title: "Are you sure to delete?",
                            text: "You won't be able to revert this!",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, delete it!",
                            cancelButtonText: "No, cancel!",
                          }).then((result) => {
                            if (result.value) {
                              this.deleteCampaign(item.campaign_id);
                            }
                          });
                        }}
                        type="button"
                        className="btn btn-sm  btn-danger"
                      >
                        Delete
                      </button>
                    )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  async deleteCampaign(campaign_id) {
    await this.props.deleteCampaign(campaign_id, this.state.currentPage);
    setTimeout(() => {
      this.getAllCampaign(this.state.currentPage);
    }, 100);
  }

  changePage(page) {
    this.setState({ currentPage: page });
    this.getAllCampaign(page);
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <div style={{ padding: 20 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Active Campaign</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => this.props.history.push(`/createCampaign`)}
                    className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add Campaign
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.campaigns.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ campaignReducer, appReducer }) => ({
  campaignReducer,
  appReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);

import React, { Component } from "react";
import * as actions from "../../../actions/reportPurchaseCoinRank.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import MenuReport from "../menuReport";

class ReportPurchaseCoinRank extends Component {
  async componentDidMount() {
    this.props.getReportPurchaseCoinRank();
  }

  formatCharge(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(row.charge);
  }

  formatGoldRemain(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.gold_remain);
  }

  formatSilverCoinRemain(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.silver_remain);
  }

  formatLastTopupCoin(cell, row) {
    return (
      new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.last_coin) +
      "  (" +
      row.last_package_coin_type +
      ")"
    );
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportPurchaseCoinRankReducer;
      const csv_name = "MemberPurchaseRank.csv";

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="member_id"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                isKey={true}
                hidden={true}
              >
                Member ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="member_username"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="Username"
              >
                Username
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="Name"
              >
                Name
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_email"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="Email"
              >
                Email
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="charge"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCharge}
                csvFormat={this.formatCharge}
                csvHeader="ยอดซื้อเหรียญสะสม(บาท)"
              >
                ยอดซื้อเหรียญสะสม(บาท)
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="gold_remain"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatGoldRemain}
                csvFormat={this.formatGoldRemain}
                csvHeader="เหรียญทองคงเหลือ(Coin)"
              >
                เหรียญทองคงเหลือ(Coin)
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="silver_remain"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatSilverCoinRemain}
                csvFormat={this.formatSilverCoinRemain}
                csvHeader="เหรียญเงินคงเหลือ(Coin)"
              >
                เหรียญเงินคงเหลือ(Coin)
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="last_coin"
                width="90px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatLastTopupCoin}
                csvFormat={this.formatLastTopupCoin}
                csvHeader="จำนวนเหรียญที่เติมล่าสุด"
              >
                จำนวนเหรียญที่เติมล่าสุด
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuReport />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">
                      อันดับ Member ที่มียอดเงินซื้อเหรียญสูงสุด
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    value.start = startDate;
                    value.end = endDate;

                    this.props.getReportPurchaseCoinRank(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>

                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary btn-sm ml-4"
                                style={{
                                  color: "white",
                                  width: 100,
                                }}
                              >
                                Search
                              </button>
                            </InputGroup>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportPurchaseCoinRankReducer }) => ({
  reportPurchaseCoinRankReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportPurchaseCoinRank);

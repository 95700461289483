import React, { Component } from "react";
import { Container, Navbar, Nav, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import NavbarNav from "./NavbarNav/NavbarNav";

class MainNavbar extends Component {
  render() {
    return (
      <div className="main-navbar bg-white p-0 pr-4">
        <Navbar
          type="light"
          className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light"
        >
          <NavbarNav />
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = ({ appReducer }) => ({
  appReducer,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainNavbar));

import {
  HTTP_BOOKS_FETCHING,
  HTTP_BOOKS_SUCCESS,
  HTTP_BOOKS_FAILED,
  HTTP_BOOK_FETCHING,
  HTTP_BOOK_SUCCESS,
  HTTP_BOOK_FAILED,
} from "../constants/book";

const initialState = {
  books: [],
  book: null,
  total: 0,
  isFetching: false,
  isError: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_BOOKS_SUCCESS:
      return { 
        ...state, 
        books: payload.books, 
        total: payload.total, 
        isFetching: false, 
        isError: false 
    };
    case HTTP_BOOKS_FAILED:
      return { ...state, books: [], isFetching: false, isError: true };
    case HTTP_BOOKS_FETCHING:
      return { ...state, books: [], isFetching: true, isError: false };
    case HTTP_BOOK_SUCCESS:
      return { ...state, book: payload, isFetching: false, isError: false };
    case HTTP_BOOK_FAILED:
      return { ...state, book: [], isFetching: false, isError: true };
    case HTTP_BOOK_FETCHING:
      return { ...state, book: [], isFetching: true, isError: false };
    default:
      return state;
  }
};

import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormGroup,
  FormTextarea,
  Modal,
  ModalBody,
  ModalHeader,
  DatePicker,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,
  Badge
} from "shards-react";
import NumberFormat from "react-number-format";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { httpClient } from "../../utils/HttpClient";
import moment from "moment";
import Swal from "sweetalert2";
import { Descriptions, Empty, Tabs } from 'antd';
import { server } from "../../constants";
import WriterIncome from "../Writer/writerIncome";
import WriterFinanceInfo from "../Writer/writerFinanceInfo";
import WriterWithdraw from "../Writer/writerWithdraw";
import { ThemeProvider } from "@material-ui/core";

const { TabPane } = Tabs;

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <img
        src={thumb}
        alt={file.name}
        className="img-thumbnail mt-2"
        width="150px"
        height="150px"
      />
    );
  }
}

export default class memberFrom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      silverCoinModalOpen: false,
      withdrawModalOpen: false,
      isAdding: false,
      channel: "",
      incomeRatio: false,
      taxUploading: false,
      selectedWithdrawTransaction: null,
      books: [],
      goldCoinRemain: 0,
      silverCoinRemain: 0,
      goldCoinWithdraw: 0,
      silverCoinWithdraw: 0,
      totalShareGoldBaht: 0,
      totalShareSilverBaht: 0,
      withdrawHistory: []
    };

    this.toggleModal = this.toggleModal.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
  }

  componentDidMount() {
    const userProfile = JSON.parse(localStorage.getItem("user_profile"));

    if (userProfile) {
      this.setState({
        incomeRatio: userProfile.writer_income_ratio === "Y",
      })
    }
  }

  toggleModal(name) {
    if (name === "silver") {
      this.setState({
        silverCoinModalOpen: !this.state.silverCoinModalOpen,
      });
    } else if (name === "withdraw") {
      this.setState({
        withdrawModalOpen: !this.state.withdrawModalOpen,
      });
    }
  }

  async addSilverCoin(memberId, coin, expiredDate) {
    let totalCoin = Number(coin.replace(/,/g, ""));

    if (totalCoin > 0) {
      let result = localStorage.getItem("user_profile");
      let username;
      if (result) username = JSON.parse(result).user_username;

      let formData = new FormData();

      formData.append("member_id", memberId);
      formData.append("coin_received", totalCoin);
      formData.append("coin_remains", totalCoin);
      formData.append("expired_date", moment(expiredDate).endOf("day").format("YYYY-MM-DD HH:mm:ss"))
      formData.append("created_by", username);

      let object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      let json = JSON.stringify(object);

      const token = localStorage.getItem("token")
      let response = await httpClient.post(`admin/silver-coin/add`, json, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          silverCoinModalOpen: false,
        });

        this.props.getMemberInfo();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอกจำนวนเหรียญ",
        timer: 1500,
      });
    }
  }

  async withdrawMoney(totalAmount) {
    if (this.state.goldCoinWithdraw > 0 || this.state.silverCoinWithdraw > 0) {
      const result = localStorage.getItem("user_profile");
      let username

      if (result) {
        username = JSON.parse(result).user_username;
      }

      const params = {
        member_id: this.props.values.member_id,
        gold_coin: this.state.goldCoinWithdraw,
        silver_coin: this.state.silverCoinWithdraw,
        amount: totalAmount,
        status: '2',
        created_by: username
      }

      const token = localStorage.getItem("token")
      let response = await httpClient.post(`admin/member/withdraw`, params, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          withdrawModalOpen: false,
        });

        this.props.getMemberInfo();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอกจำนวนเหรียญที่ต้องการถอน",
        timer: 1500,
      });
    }
  }

  formatCommentDateTime(cell, row) {
    if (row.created_datetime) {
      return moment(row.created_datetime)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatNumber(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(cell);
  }

  formatStatus(cell, row) {
    let statusBadge = ''

    if(row.status === '1')  {
      statusBadge = (
        <Badge theme="secondary" className="py-1">
          รอการยืนยัน
        </Badge>
      )
    } else if(row.status === '2')  {
      statusBadge = (
        <Badge theme="success" className="py-1">
          จ่ายแล้ว
        </Badge>
      )
    } else if(row.status === '3')  {
      statusBadge = (
        <Badge theme="danger" className="py-1">
          ยกเลิก
        </Badge>
      )
    }

    return statusBadge
  }

  formatTaxButton(cell, row) {
    return (
      <div className="position-relative" onClick={() => this.setState({selectedWithdrawTransaction: row})}>
        <button type="button" className="btn btn-danger btn-xs">อัพโหลดใบหักภาษี</button>
        <input type="file" className="pointer" style={{position:'absolute', top:0, left:0, right:0, bottom:0, opacity:0, zIndex:2}} onChange={(e) => this.handleTaxUpload(e.target.files[0])} />
      </div>
    )
  }

  formatTaxDocument(cell, row) {
    return row.tax_document_url ? 
     <a href={row.tax_document_url} target="_blank" rel="noopener noreferrer"><Badge theme="success" className="py-1">เปิดไฟล์</Badge></a> :
     <Badge theme="secondary" className="py-1">ไม่มีเอกสาร</Badge>
  }
  
  async handleTaxUpload(file) {
    this.setState({taxUploading: true})

    try {
      let formData = new FormData();
      formData.append("upload", file);

      const response = await httpClient.post(server.IMG_URL, formData, {
        headers: {
          processData: false,
          enctype: "multipart/form-data",
        },
      });

      await this.updateWithdrawTax(this.state.selectedWithdrawTransaction.id, response.data.url)
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดไฟล์ กรุณาลองใหม่อีกครั้ง",
        timer: 3000,
      });
    }

    this.setState({taxUploading: false})
  }

  async updateWithdrawTax(id, url)  {
    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    try {
      const token = localStorage.getItem("token")
      await httpClient.patch(
        `/admin/member/withdraw/${id}/tax`,
        JSON.stringify({
          tax_document_url: url,
          updated_by: username,
          updated_datetime: dateTime
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );

      let withdrawHistory = this.state.withdrawHistory

      for(const item of withdrawHistory)  {
        if(item.id === this.state.selectedWithdrawTransaction.id)  {
          item.tax_document_url = url
        }
      }

      this.setState({withdrawHistory})

    } catch(err)  {

    }
  }

  getSharedIncome(percentage, coinType) {
    let sharedIncome = 0
    let totalWithDraw = 0
    let fixedSharedIncome = 0

    if(coinType === 'gold') {
      totalWithDraw = this.state.goldCoinWithdraw
      fixedSharedIncome = this.state.totalShareGoldBaht
    } else {
      totalWithDraw = this.state.silverCoinWithdraw
      fixedSharedIncome = this.state.totalShareSilverBaht
    }

    if(this.props.values.member_type === 2) {
      sharedIncome = (totalWithDraw*(percentage/100)).toFixed(2)
    } else {
      sharedIncome = fixedSharedIncome
    }

    return sharedIncome
  }

  handleTabChange(key) {
    this.props.onTabChange(key);
  }
  
  render() {
    const {
      values,
      handleChange,
      isDisableField,
      laberImg,
      setFieldValue,
      isDiableGoldCoin,
      isDiableSilverCoin,
      isDiableUsername,
      errors,
      touched,
      isVisibleSignUpChannel,
      isMember
    } = this.props;

    const getSignUpChannel = () => {
      let temp_channel = "WEB";
      if (values) {
        if (values.facebook_id) temp_channel = "FACEBOOK";
        else if (values.google_id) temp_channel = "GOOGLE";
        else if (values.line_id) temp_channel = "LINE";
        else if (values.apple_id) temp_channel = "APPLE";
        return temp_channel;
      }
    };

    const haveOldImg = () => {
      if (values.member_image)
        return (
          <React.Fragment>
            <label className="ml-2 text-accent">(Old Image)</label>
            <div className="ml-2">
              <img
                id="old_img"
                name="old_img"
                src={values.member_image}
                width="150px"
                height="150px"
                alt=""
              />
            </div>
          </React.Fragment>
        );
    };

    const haveNewImg = () => {
      if (values.file)
        return <label className="ml-2 mt-2 text-success">(New Image)</label>;
    };

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };

    return (
      <React.Fragment>
        <Tabs defaultActiveKey="1" animated={{ tabPane: false }} onChange={this.handleTabChange}>
          <TabPane tab="ข้อมูลทั่วไป" key="1" disabled={!values.member_id}>
            <fieldset disabled={isDisableField} className="mt-2">
              <Row form>
                <Col md="6" className="form-group">
                  <label>Username</label>
                  <FormInput
                    name="member_username"
                    id="member_username"
                    placeholder="Username"
                    onChange={handleChange}
                    value={values.member_username}
                    disabled={isDiableUsername}
                  />
                </Col>
                <Col md="6">
                  <label>Password</label>
                  <FormInput
                    type="password"
                    name="member_password"
                    id="member_password"
                    placeholder="password"
                    onChange={handleChange}
                    value={values.member_password}
                  />
                </Col>
              </Row>

              <Row form>
                <Col md="12" className="form-group">
                  <label>Name</label>
                  <FormInput
                    placeholder="Name"
                    name="member_name"
                    id="member_name"
                    onChange={handleChange}
                    value={values.member_name}
                  />
                  {!isDisableField && errors.member_name && touched.member_name ? (
                    <div className="font-12 text-primary mt-1">
                      {errors.member_name}
                    </div>
                  ) : null}
                </Col>
              </Row>

              <Row form>
                <Col md="6" className="form-group">
                  <label hidden={!isVisibleSignUpChannel}>Sign Up Channel</label>
                  <FormInput
                    disabled={true}
                    hidden={!isVisibleSignUpChannel}
                    value={getSignUpChannel()}
                  />
                </Col>
              </Row>

              <Row form>
                <Col md="6" className="form-group">
                  <label>Email</label>
                  <FormInput
                    name="member_email"
                    id="member_email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.member_email}
                  />
                </Col>
                <Col md="6">
                  <label>Phone</label>
                  <FormInput
                    name="member_phone"
                    id="member_phone"
                    placeholder="Phone"
                    onChange={handleChange}
                    value={values.member_phone}
                  />
                </Col>
              </Row>

              <FormGroup>
                <label>Detail</label>
                <FormTextarea
                  rows="5"
                  name="member_history"
                  id="member_history"
                  placeholder="Detail"
                  onChange={handleChange}
                  value={values.member_history}
                />
              </FormGroup>

              <Row form>
                <Col md="12">
                  <div className="form-group">
                    <label htmlFor="old_img">{laberImg}</label>
                    <div>{haveOldImg()}</div>
                  </div>
                </Col>
              </Row>

              <Row form>
                <Col md="12">
                  <div className="form-group">
                    <input
                      id="file"
                      name="file"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      className="form-control"
                    />
                    <div>{haveNewImg()}</div>
                    <div className="ml-2">
                      <Thumb id="new_img" name="new_img" file={values.file} />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row form>
                <Col md="6" className="form-group">
                  <label hidden={!isMember}>User Roles</label>
                  <FormSelect
                    name="unlimited"
                    id="unlimited"
                    placeholder=""
                    onChange={handleChange}
                    value={values.unlimited}
                    hidden={!isMember}
                  >
                    <option value="Y">Admin</option>
                    <option value="N">Normal</option>
                  </FormSelect>
                </Col>
              </Row>
              <hr />

              <Row form>
                <Col md="6" className="form-group">
                  <label>Gold Coin</label>
                  <NumberFormat
                    disabled={isDiableGoldCoin}
                    customInput={FormInput}
                    thousandSeparator={true}
                    
                    inputMode="numeric"
                    name="member_coin"
                    id="member_coin"
                    placeholder=""
                    onChange={handleChange}
                    value={values.member_coin}
                  />
                </Col>
                <Col md="6">
                  <label className="d-flex align-items-center">
                    Silver Coin
                    {!isDiableSilverCoin ? (
                      <i
                        className="material-icons ml-1"
                        style={{ fontSize: "18px" }}
                        onClick={(e) => this.toggleModal("silver")}
                      >
                        add_circle
                      </i>
                    ) : null}
                  </label>
                  <NumberFormat
                    disabled={isDiableSilverCoin}
                    customInput={FormInput}
                    thousandSeparator={true}
                    inputMode="numeric"
                    name="member_silver_coin"
                    id="member_silver_coin"
                    placeholder=""
                    readOnly={true}
                    onChange={handleChange}
                    value={values.member_silver_coin}
                  />
                </Col>
              </Row>

              {/* {values.member_type === 2 || values.member_type === 3|| values.member_type === 4 || values.member_type === 5 && (
                <React.Fragment>
                  {this.state.incomeRatio && (
                    <Row form>
                      <Col md="6" className="form-group">
                        <label>Gold Revenue Ratio</label>
                        <NumberFormat
                          customInput={FormInput}
                          thousandSeparator={true}
                          
                          inputMode="numeric"
                          name="gold_coin_earning_percentage"
                          id="gold_coin_earning_percentage"
                          placeholder=""
                          onChange={handleChange}
                          value={values.gold_coin_earning_percentage}
                        />

                        {!isDisableField &&
                        errors.gold_coin_earning_percentage &&
                        touched.gold_coin_earning_percentage ? (
                          <div className="font-12 text-primary mt-1">
                            {errors.gold_coin_earning_percentage}
                          </div>
                        ) : null}
                      </Col>
                      <Col md="6" className="form-group">
                        <label>Silver Revenue Ratio</label>
                        <NumberFormat
                          customInput={FormInput}
                          thousandSeparator={true}
                          
                          inputMode="numeric"
                          name="silver_coin_earning_percentage"
                          id="silver_coin_earning_percentage"
                          placeholder=""
                          onChange={handleChange}
                          value={values.silver_coin_earning_percentage}
                        />

                        {!isDisableField &&
                        errors.silver_coin_earning_percentage &&
                        touched.silver_coin_earning_percentage ? (
                          <div className="font-12 text-primary mt-1">
                            {errors.silver_coin_earning_percentage}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              )} */}
            </fieldset>
          </TabPane>
          <TabPane tab="หนังสือ" key="2" disabled={!values.member_id || values.member_type === 1}>
            <WriterIncome member={values} />
          </TabPane>
          <TabPane tab="ข้อมูลการเงิน" key="3" disabled={!values.member_id || values.member_type === 1}>
            <WriterFinanceInfo member={values} />
          </TabPane>
          <TabPane tab="ข้อมูลการถอนเงิน" key="4" disabled={!values.member_id || values.member_type === 1}>
            <WriterWithdraw member={values} />
          </TabPane>
        </Tabs>

        <Modal
          open={this.state.silverCoinModalOpen}
          toggle={() => this.toggleModal("silver")}
        >
          <ModalHeader>เพิ่มเหรียญเงิน</ModalHeader>
          <ModalBody>
            <Row form>
              <Col md="12" className="form-group">
                <label>จำนวนเหรียญ</label>
                <NumberFormat
                  customInput={FormInput}
                  thousandSeparator={true}
                  inputMode="numeric"
                  name="silver_coin_added"
                  id="silver_coin_added"
                  onChange={handleChange}
                  value={values.silver_coin_added}
                />
              </Col>
            </Row>
            <Row form>
              <Col md="12" className="form-group">
                <label>วันหมดอายุ</label>
                <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                  <DatePickerField
                    size="sm"
                    name="silver_coin_expired_date"
                    onChange={setFieldValue}
                    value={values.silver_coin_expired_date}
                    dropdownMode="select"
                  />
                  <InputGroupAddon type="append">
                    <InputGroupText>
                      <i className="material-icons">&#xE916;</i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>

            <div className="text-right mt-3">
              <button
                type="button"
                disabled={this.state.isAdding}
                className="btn btn-primary pull-right"
                onClick={() => {
                  this.addSilverCoin(
                    values.member_id,
                    values.silver_coin_added,
                    values.silver_coin_expired_date
                  );
                }}
                style={{ marginRight: 10, color: "white", width: 100 }}
              >
                บันทึก
              </button>
              <button
                onClick={(e) => this.toggleModal("silver")}
                type="Button"
                className="btn btn-danger pull-right"
                style={{ color: "white", width: 100 }}
              >
                ย้อนกลับ
              </button>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

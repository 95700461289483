import { httpClient } from "../utils/HttpClient";

import {
  HTTP_BOOK_MESSAGE_FETCHING,
  HTTP_BOOK_MESSAGE_SUCCESS,
  HTTP_BOOK_MESSAGE_FAILED,
  HTTP_BOOK_MESSAGE_ADDED,
  HTTP_BOOK_MESSAGE_UPDATED,
  HTTP_BOOK_MESSAGE_DELETED,
} from "../constants/book";

import { server } from "../constants";

const setStateBookMessageToSuccess = payload => ({
  type: HTTP_BOOK_MESSAGE_SUCCESS,
  payload: payload
});

const setStateBookMessageToFetching = () => ({
  type: HTTP_BOOK_MESSAGE_FETCHING
});

const setStateBookMessageToFail = () => ({
  type: HTTP_BOOK_MESSAGE_FAILED
});

const setStateBookMessageToAdd = payload => ({
  type: HTTP_BOOK_MESSAGE_ADDED,
  payload: payload
});

const setStateBookMessageToUpdate = payload => ({
  type: HTTP_BOOK_MESSAGE_UPDATED,
  payload: payload
});

const setStateBookMessageToDelete = payload => ({
  type: HTTP_BOOK_MESSAGE_DELETED,
  payload: payload
});

export const getBookMessages = (id) => {
  return async dispatch => {
    dispatch(setStateBookMessageToFetching());

    try {
      const token = localStorage.getItem("token");
  
      const result = await httpClient.get(
        `${server.BOOK_MESSAGE_URL}/${id}`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );
      
      dispatch(setStateBookMessageToSuccess(result.data));
    } catch (err) {
      dispatch(setStateBookMessageToFail());
    }
  };
};

export const addBookMessage = (message) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem("token");
      
      const result = await httpClient.post(
        `${server.BOOK_MESSAGE_URL}`, 
        message,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookMessageToAdd(result.data));
    } catch (err) {
      dispatch(setStateBookMessageToFail());
    }
  };
};

export const editBookMessage = (message) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.put(
        `${server.BOOK_MESSAGE_URL}/${message.id}`, 
        message,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookMessageToUpdate(result.data))
    } catch (err) {
      dispatch(setStateBookMessageToFail());
    }
  };
};

export const deleteBookMessage = (id) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem("token");
      
      await httpClient.delete(
        `${server.BOOK_MESSAGE_URL}/${id}`,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookMessageToDelete(id));
    } catch (err) {
      dispatch(setStateBookMessageToFail());
    }
  };
};
  
export const insertBookMessage = (messages) => {
  return async dispatch => {
    try {
      const token = localStorage.getItem("token");
      
      const result = await httpClient.put(
        `${server.BOOK_MESSAGE_URL}/insert`, 
        messages,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      );

      dispatch(setStateBookMessageToSuccess(result.data));
    } catch (err) {
      dispatch(setStateBookMessageToFail());
    }
  };
};

import {
  HTTP_REPORT_WRITER_SUCCESS,
  HTTP_REPORT_WRITER_FETCHING,
  HTTP_REPORT_WRITER_FAILED,
} from "../constants/report";
  
const initialState = {
  result: null,
  isFetching: false,
  isError: false,  
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_REPORT_WRITER_SUCCESS:        
      return { ...state, result: payload, isFetching: false, isError: false };
    case HTTP_REPORT_WRITER_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_REPORT_WRITER_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    default:
      return state;
  }
};
import React, { Component } from "react";
import * as actions from "../../../actions/reportSilverTrans.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
  FormSelect,
  Button,
  FormInput,
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";

class ReportSilverCoinTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      start_date: "",
      end_date: "",
      member_name: "",
      campaign_name: "",
    };
  }

  async componentDidMount() {
    await this.getSilverCoinReport(this.state.currentPage);
    if (this.props.reportSilverTransReducer.orders) {
      this.setState({ orders: this.props.reportSilverTransReducer.orders });
    }

    this.props.getReportSummarySilverTrans();
    this.props.getCampaignName();
  }

  async getSilverCoinReport(page) {
    this.setState({ loading: true });

    const value = {};
    value.start = this.state.start_date;
    value.end = this.state.end_date;
    value.member_name = this.state.member_name;
    value.campaign_name = this.state.campaign_name;
    value.page = page;
    await this.props.getReportSilverTrans(value);

    const { orders, total, isFetching } = this.props.reportSilverTransReducer;
    if (orders && !isFetching) {
      const totalPages = Math.ceil(total / this.state.perPage);

      this.setState({
        orders,
        totalPages,
        loading: false,
      });
    }
  }

  formatDecimal(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatExpireDate(value) {
    if (value) {
      return moment(value)
        .locale("th")
        .format("DD/MM/YYYY");
    }
  }

  bindingCampaignName = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.getCampaignNameReducer;

      const options = result.map((item) => (
        <option key={item.value} value={item.campaign_name}>
          {item.campaign_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="campaign_name"
            id="campaign_name"
            placeholder=""
            onChange={handleChange}
            value={values.campaign_name}
          >
            <option value="">Select Package</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  renderTable = () => {
    return (
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">Transaction Date</th>
              <th className="text-center">Member Name</th>
              <th className="text-center">Campaign Name</th>
              <th className="text-center">จำนวน Coin ที่ได้รับ</th>
              <th className="text-center">จำนวน Coin คงเหลือ</th>
              <th className="text-center">วันที่ Coin หมดอายุ</th>
              <th className="text-center">Progress</th>
            </tr>
          </thead>
          <tbody>
            {this.state.orders.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td className="text-center align-middle">
                    {this.formatDateTime(item.transaction_date)}
                  </td>
                  <td className="align-middle">{item.member_name}</td>
                  <td className="align-middle">{item.campaign_name}</td>
                  <td className="text-center align-middle">
                    {this.formatDecimal(item.coin_received)}
                  </td>

                  <td className="text-center align-middle">
                    {this.formatDecimal(item.coin_remains)}
                  </td>

                  <td className="text-center align-middle">
                    {this.formatExpireDate(item.expired_date)}
                  </td>
                  <td className="text-center align-middle">
                    {item.achievement}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Row>
          <Col sm="7" className="sm-7">
            <Card small className="lo-stats h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Summary</h6>
                <div className="block-handle" />
              </CardHeader>

              <CardBody className="p-0">
                <Container fluid className="px-0">
                  <table className="table mb-0 table-striped table-hover table-fixed">
                    <thead className="py-2 text-semibold border-bottom">
                      <tr>
                        <th className="text-center">Campaign Name</th>
                        <th className="text-center">Items</th>
                        <th className="text-center">จำนวน Coin ทั้งหมด</th>
                        <th className="text-center">จำนวน Coin ที่ใช้ไป</th>
                        <th className="text-center">จำนวน Coin คงเหลือ</th>
                      </tr>
                    </thead>
                    <tbody>{this.createSummary()}</tbody>
                    <tfoot>{this.createSummaryFooter()}</tfoot>
                  </table>
                </Container>
              </CardBody>
            </Card>
          </Col>
          <Col sm="5" className="sm-5"></Col>
        </Row>
      </div>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getSilverCoinReport(page);
  }

  formatCampaingName(value) {
    if (value) return value;
    else return "Added by admin";
  }

  createSummary = () => {
    try {
      const {
        resultSummary,
        isFetchingSummary,
      } = this.props.reportSilverTranSumReducer;
      return (
        !isFetchingSummary &&
        resultSummary != null &&
        resultSummary.map((item) => (
          <tr key={item.campaign_name}>
            <td>
              {this.formatCampaingName(item.campaign_name)}
            </td>
    
            <td className="text-center">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.quantity)}
            </td>

            <td className="text-center">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.receive)}
            </td>
            <td className="text-center">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.use)}
            </td>
            <td className="text-center">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.remain)}
            </td>
          </tr>
        ))
      );
    } catch (e) {}
  };

  createSummaryFooter = () => {
    try {
      const {
        resultSummary,
        isFetchingSummary,
      } = this.props.reportSilverTranSumReducer;

      const itemTotal = resultSummary.reduce(
        (totalIten, data) => totalIten + parseInt(data.quantity),
        0
      );

      const receiveTotal = resultSummary.reduce(
        (receive, data) => receive + parseInt(data.receive),
        0
      );

      const useTotal = resultSummary.reduce(
        (use, data) => use + parseInt(data.use),
        0
      );

      const remainTotal = resultSummary.reduce(
        (remain, data) => remain + parseInt(data.remain),
        0
      );

      if (!isFetchingSummary && resultSummary != null) {
        return (
          <tr>
            <td className="text-center font-weight-bold">Grand Total</td>
            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(itemTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(receiveTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(useTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(remainTotal)}
            </td>
          </tr>
        );
      }
    } catch (e) {}
  };

  async exportData() {
    await this.getSilverCoinReport("");

    let report = this.state.orders.map((item, idx) => {
      return [
        this.formatDateTime(item.transaction_date),
        item.member_name,
        item.campaign_name,
        this.formatDecimal(item.coin_received),
        this.formatDecimal(item.coin_remains),
        this.formatExpireDate(item.expired_date),
        item.achievement,
      ];
    });

    report = [
      [
        "Transaction Date",
        "Name",
        "Campaign Name",
        "จำนวน Coin ที่ได้รับ",
        "จำนวน Coin คงเหลือ",
        "วันที่ Coin หมดอายุ",
        "Progress",
      ],
      ...report,
    ];

    if (report) {
      this.downloadExportData(report);
    }
  }

  downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["silver-transaction"]);
    XLSX.writeFile(workBook, `silver-transaction.xlsx`);

    this.getSilverCoinReport(0);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Silver Coin Transaction Report</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        this.exportData();
                      }}
                      type="button"
                      className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    campaign_name: "",
                    member_name: "",
                    page: "",
                  }}
                  onSubmit={async (values) => {
                    let startDate = null;
                    let endDate = null;
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    this.setState({
                      start_date: startDate,
                      end_date: endDate,
                      member_name: values.member_name,
                      campaign_name: values.campaign_name,
                    });

                    this.getSilverCoinReport(this.state.currentPage);

                    const value = {};
                    value.start = startDate;
                    value.end = endDate;

                    this.props.getReportSummarySilverTrans(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col sm="4" className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>

                          <Col sm="6">
                            <Row>
                              <Col sm="5">
                                <FormGroup>
                                  <label>Campaign Name</label>

                                  {this.bindingCampaignName({
                                    values,
                                    handleChange,
                                  })}
                                </FormGroup>
                              </Col>
                              <Col md="5">
                                <FormGroup>
                                  <label>Name</label>
                                  <FormInput
                                    name="member_name"
                                    id="member_name"
                                    placeholder="Name"
                                    onChange={handleChange}
                                    value={values.member_name}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm="2">
                                <FormGroup>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                      marginLeft: 30,
                                      marginTop: 27,
                                      color: "white",
                                      width: 100,
                                    }}
                                  >
                                    Search
                                  </button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
              </CardBody>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.orders.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  reportSilverTransReducer,
  reportSilverTranSumReducer,
  getCampaignNameReducer,
}) => ({
  reportSilverTransReducer,
  reportSilverTranSumReducer,
  getCampaignNameReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSilverCoinTransaction);

import React, { Component, useEffect, useState, useCallback } from "react"
import { useParams } from 'react-router-dom'
import moment from "moment"
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, FormInput, Card, CardHeader, CardBody, FormGroup, FormSelect } from "shards-react";
import { Table, Tag, Button, Skeleton, Modal, DatePicker, message } from 'antd';
import { httpClient } from "../../../utils/HttpClient";
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

const { Column } = Table;

export default function UgcWriter() {
  const [loading, setLoading] = useState(false)
  const [writers, setWriters] = useState([])
  const [transactions, setTransactions] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  })
  const [isModalVisible, setModalVisible] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [activeRecord, setActiveRecord] = useState(null)
  const [approvedDate, setApprovedDate] = useState(null)
  
  const getUgcDocuments = useCallback(async () => {
    setLoading(true)

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get("admin/writer/ugc", {
        params: {
          name: name,
          email: email,
          status: status,
          page: (pagination.current) - 1
        },
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })

      setWriters(result.data.writers)
      setPagination({
        ...pagination,
        total: result.data.total
      })
    } catch(e) {
      message.open({ content: 'โหลดข้อมูลไม่สำเร็จ' })
    } finally {
      setLoading(false)
    }
  }, [name, email, status, pagination.current])

  useEffect(() => {
    getUgcDocuments()
  }, [getUgcDocuments])

  useEffect(() => {
    getUgcDocuments()
  }, [name, email, status, pagination.current, getUgcDocuments])

  const updateFilter = (value, filterType) => {
    if(filterType === 'name') {
      setName(value)
    } else if(filterType === 'email') {
      setEmail(value)
    } else if(filterType === 'status') {
      setStatus(value)
    }
  }

  const approveConfirmation = (data, status) => {
    Swal.fire({
      text: status === 'Y' ? 'ยืนยันการอนุมัติเอกสารนักเขียน' : 'ยืนยันการยกเลิกการเป็นนักเขียน',
      showCancelButton: true,
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        updateStatus(data, status)
      }
    })
  }

  const updateStatus = async (data, status) => {
    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    try {
      const token = localStorage.getItem("token")
      
      let response = await httpClient.patch(
        `/admin/writer/ugc/${data.ugc_id}/approve`,
        { 
          memberId: data.id,
          status 
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      getUgcDocuments()
      message.open({ content: status === 'Y' ? 'อนุมัติเอกสารเรียบร้อยแล้ว' : 'ยกเลิกการเป็นนักเขียนเรียบร้อยแล้ว' })
    } catch(err)  {
      message.open({ content: 'ไม่สามารถทำรายการได้' })
    }
  }

  const handleTableChange = (newPagination) => {
    setPagination(newPagination)
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const handleDateChange = (value) => {
    setApprovedDate(value)
  }

  return (
    <>
      <div className="container-fluid">
        <div style={{ padding: 10 }}>
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="mt-1">UGC Writer</h6>
            </CardHeader>
            <CardBody className="p-3">
              <div className="row">
                <div className="col-md-3">
                  <FormGroup>
                    <label>ชื่อ</label>
                    <FormInput
                      placeholder="Name"
                      onChange={(e) => updateFilter(e.target.value, 'name')}
                      value={name}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>อีเมล</label>
                    <FormInput
                      placeholder="Email"
                      onChange={(e) => updateFilter(e.target.value, 'email')}
                      value={email}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-3">
                  <FormGroup>
                    <label>สถานะ</label>
                    <FormSelect
                      name="order_status"
                      id="order_status"
                      placeholder=""
                      onChange={(e) => updateFilter(e.target.value, 'status')}
                    >
                      <option value="">ทั้งหมด</option>
                      <option value="N">รอการอนุมัติ</option>
                      <option value="Y">อนุมัติแล้ว</option>                      
                    </FormSelect>
                  </FormGroup>
                </div>
              </div>

              <Table
                dataSource={writers}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
              >
                 <Column
                  title="ชื่อ"
                  key="name"
                  sorter={true}
                  render={(row) => {
                    return <a href={`/editWriter/${row.id}`}>{ row.first_name ? `${row.first_name} ${row.last_name}` : row.display_name }</a>
                  }}
                />
                <Column
                  title="อีเมล"
                  key="email"
                  dataIndex="email"
                  sorter={true}
                />
                <Column
                  title="วันที่สมัคร"
                  key="register_date"
                  align="center"
                  render={(row) => {
                    return row.register_date ? moment(row.register_date).format('DD/MM/YYYY') : null
                  }}
                />
                <Column
                  title="เอกสาร"
                  key="documents"
                  render={(row) => {
                    return (
                      <ul className="list-unstyled">
                        {
                          row.documents.map((document, index) => {
                           return (
                            <li className={`${index > 0 ? 'mt-2' : ''}`}>
                              <div className="mr-3">{ document.type === '1' ? 'สำเนาบัตรประชาชน' : 'หน้าสมุดบัญชี' }</div>
                              <a href={document.url} target="_blank">
                                { document.name }
                              </a>
                            </li>
                           ) 
                          })
                        }
                      </ul>
                    )
                  }}
                />
                <Column
                  title="สถานะ"
                  key="status"
                  render={(row) => {
                    let color
                    let text 
              
                    if(row.document_approved === 'N') {
                      color = '#ffb703'
                      text = 'รอการอนุมัติ'
                    } else if(row.document_approved === 'Y') {
                      color = '#87d068'
                      text = 'อนุมัติแล้ว'
                    }

                    return <Tag color={color}>{ text }</Tag>
                  }}
                />

                <Column
                  title=""
                  key="status"
                  render={(row) => {
                    return (
                      <>
                        { row.document_approved === 'N' ? 
                          <button
                            type="button"
                            className="btn btn-sm btn-primary text-nowrap"
                            onClick={() => approveConfirmation(row, 'Y')}
                          >
                            อนุมัติเอกสาร
                          </button> :
                          <button
                            type="button"
                            className="btn btn-sm btn-danger text-nowrap"
                            onClick={() => approveConfirmation(row, 'N')}
                          >
                            ยกเลิก
                          </button>
                        }
                      </>
                    )
                  }}
                />
              </Table>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}
import React, { Component } from "react";
import { getReportGift } from "../../../actions/reportGift.action";
import { getBooks } from "../../../actions/book.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  Button
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import Autosuggest from "react-autosuggest";
import MenuReport from "../menuReport";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";

class reportGift extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      value: "",
      loading: false,
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
    };
  }

  async componentDidMount() {
    this.getReportGiftHistory(this.state.currentPage);
    await this.props.getBooks();

    this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
    });
  }

  async getReportGiftHistory(page) {
    this.setState({ loading: true });

    const filter = {};
    filter.book_title = this.state.bookTitle
    filter.page = page;
    filter.start_date = this.state.start_date;
    filter.end_date = this.state.end_date;

    await this.props.getReportGift(filter);

    const { result, total } = this.props.reportGiftReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({ 
      loading: false,
      gifts: result,
      totalPages
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY");
    } else return "";
  }

  changePage(page) {
    this.setState({ currentPage: page });
    this.getReportGiftHistory(page);
  }

  async exportData() {
    await this.getReportGiftHistory("");

    let report = this.state.gifts.map(item => {
      return [
        item.sender_name,
        item.receiver_name,
        item.book_title,
        item.message,
        this.formatDateTime(item.created_datetime),
        item.status === 'Y' ? 'ได้รับแล้ว' : 'ยังไม่ได้รับ'
      ];
    });

    report = [
      [
        "ชื่อผู้ส่ง",
        "ชื่อผู้รับ",
        "ชื่อหนังสือ",
        "ข้อความ",
        "วันที่ส่ง",
        "สถานะ",
      ],
      ...report,
    ];

    if (report) {
      this.downloadExportData(report);
    }
  }

  downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["gift-history"]);
    XLSX.writeFile(workBook, `gift-history.xlsx`);
  }

  render() {
    const { value, suggestions } = this.state;
    const { result } = this.props.reportGiftReducer

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuReport />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex align-items-center mb-sm-0">
                    <h6 className="m-0">ประวัติการส่งของขวัญ</h6>
                  </Col>
                  <Col sm="6">
                    <Button
                      onClick={() => {
                        this.exportData();
                      }}
                      type="button"
                      className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Export
                    </Button>
                  </Col>
                  
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    book_title: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;
                    
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    this.setState({
                      start_date: startDate,
                      end_date: endDate,
                      currentPage: 0
                    });

                    this.getReportGiftHistory(this.state.currentPage);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="12" className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="8" className="form-group">
                            <label>ชื่อหนังสือ</label>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                              }
                              shouldRenderSuggestions={
                                this.shouldRenderSuggestions
                              }
                              onSuggestionSelected={this.onSuggestionSelected}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                            />
                          </Col>
                          <Col sm="2" className="text-right">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm"
                              style={{
                                marginLeft: 30,
                                marginTop: 27,
                                color: "white",
                                width: 100,
                              }}
                            >
                              ค้นหา
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>

                <div
                  className={
                    "content-loading-wrapper " +
                    (this.state.loading ? "loading" : "")
                  }
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">ชื่อผู้ส่ง</th>
                        <th className="text-center">ชื่อผู้รับ</th>
                        <th>ชื่อหนังสือ</th>
                        <th>ข้อความ</th>
                        <th className="text-center">ราคา (เหรียญ)</th>
                        <th className="text-center">วันที่ส่ง</th>
                        <th className="text-center" width="100">สถานะ</th>
                      </tr>
                    </thead>
                    <tbody>
                      { result && result.map(gift => {
                        return (
                          <tr key={gift.id}>
                            <td className="text-center">{ gift.sender_name }</td>
                            <td className="text-center">{ gift.receiver_name }</td>
                            <td>{ gift.book_title }</td>
                            <td>{ gift.message }</td>
                            <td className="text-center">{ gift.price }</td>
                            <td className="text-center">{ this.formatDateTime(gift.created_datetime) }</td>
                            <td className="text-center">{ gift.status === 'Y' ? 'ได้รับแล้ว' : 'ยังไม่ได้รับ'}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <nav className="text-right">
                  {this.state.books.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>

              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportGiftReducer, bookReducer }) => ({
  reportGiftReducer,
  bookReducer,
});

const mapDispatchToProps = {
  getReportGift,
  getBooks,
};

export default connect(mapStateToProps, mapDispatchToProps)(reportGift);

import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import Swal from "sweetalert2"
import { Table, Modal, Select, message, Button } from 'antd'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  FormInput,
  FormGroup,
} from "shards-react"
import { Formik } from "formik"
import moment from "moment"
import { httpClient } from "../../utils/HttpClient"
import { server } from "../../constants"
import { arrayMoveImmutable } from 'array-move'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import Autosuggest from "react-autosuggest"

const { Option } = Select

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)

export default function PlaylistDetail() {
  const [loading, setLoading] = useState(false)
  const [adding, setAdding] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [id, setId] = useState(null)
  const [books, setBooks] = useState([])
  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState('')
  const [value, setValue] = useState(null)
  const [isModalVisible, setModalVisible] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const params = useParams()

  const columns = [
    {
      title: '',
      dataIndex: 'bookImage',
      key: 'bookImage',
      render: (_, { book_image }) => (
        <img src={book_image} alt="" width="50" />
      )
    },
    {
      title: 'ชื่อหนังสือ',
      dataIndex: 'bookTitle',
      key: 'bookTitle'
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createdDate',
      key: 'createdDate'
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_,  record) => (
        <a
          onClick={() => {
            return Swal.fire({
              text: "ยืนยันการลบหนังสือออกจาก Playlist?",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "ลบ",
              cancelButtonText: "ยกเลิก",
            }).then((result) => {
              if (result.value) {
                deleteItem(record.item_id)
              }
            });
          }}
          type="button"
          className="btn btn-danger btn-sm text-white px-4"
        >
          ลบ
        </a>
      )
    }
  ]

  useEffect(() => {
    if(params.playlist_id) {
      setId(params.playlist_id)
      getPlaylistItems()
    }
  }, [params])

  useEffect(() => {
    if(id) {
      getPlaylistItems()
    }
  }, [id])

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(data.slice(), oldIndex, newIndex).filter(
        (el) => !!el,
      )

      const newOrder = newData.map((item, index) => {
        return {
          ...item,
          order: index + 1
        }
      })

      setData(newOrder)

      const params = newOrder.map((item, index) => {
        return {
          item_id: item.item_id,
          playlist_id: item.playlist_id,
          book_id: item.book_id,
          dbstatus: item.dbstatus,
          created_by: item.created_by,
          created_datetime: item.created_datetime,
          updated_by: item.updated_by,
          updated_datetime: item.updated_datetime,
          order: item.order
        }
      })
        
      try {
        const token = localStorage.getItem("token")

        const response = await httpClient.put(`${server.PLAYLIST_ITEM_URL}/order/sort`, params, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        })

        message.success('บันทึกข้อมูลเรียบร้อยแล้ว');
      } catch(e) {
        message.error('บันทึกข้อมูลไม่สำเร็จ');
      }
    }
  }

  const DraggableContainer = (props) => (
    <SortableBody
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.index === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const getPlaylistItems = () => {
    setLoading(true)
    
    const token = localStorage.getItem("token")

    httpClient
      .get(`admin/playlist_item?playlist_id=${params.playlist_id}` ,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        if(result.data.length > 0) {
          const data = result.data.map((item, index) => {
            return {
              key: index.toString(),
              index,
              ...item,
              bookTitle: item.book_title,
              createdDate: moment(item.created_datetime).format("DD/MM/YYYY")
            }
          }) 
          
          setData(data)
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      });
  }

  const handleBookSearch = async (newValue) => {
    if (newValue) {
      try {
        const token = localStorage.getItem("token");
    
        const result = await httpClient.get(`admin/book/search/title?book_title=${newValue}`,{
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        
        setBooks(result.data)
      } catch(e) {
      }
    } else {
      setBooks([]);
    }
  }

  const handleBookChange = (newValue) => {
    setValue(newValue)
  }

  const addPlaylistItem = async () => {
    if (value) {
      setAdding(true)
      const result = localStorage.getItem("user_profile")
      let username

      if (result) {
        username = JSON.parse(result).user_username
      }

      let dateTime = new Date();
      dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
      
      const params = {
        book_id: value,
        playlist_id: id,
        created_by: username,
        order: data.length + 1
      }
     
      const token = localStorage.getItem("token")

      try {
        const response = await httpClient.post(`${server.PLAYLIST_ITEM_URL}`, params, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          }
        })

        getPlaylistItems()
        
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        setValue(null)
        setAdding(false)
        setModalVisible(false)
      } catch(e) {
        setAdding(false)

        Swal.fire({
          icon: "error",
          text: "บันทึกข้อมูลไม่สำเร็จ",
          timer: 1500
        })
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณาระบุ ชื่อหนังสือ",
        timer: 1500
      })
    }
  }

  const deleteItem = async (id) => {
    setDeleting(true)
    
    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.delete(
        server.PLAYLIST_ITEM_URL + "/" + `${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      )

      setTimeout(() => {
        getPlaylistItems()
      }, 1000)

      setDeleting(false)

      Swal.fire({
        icon: "success",
        title: "ลบข้อมูลเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch(e) {
      setDeleting(false)

      Swal.fire({
        icon: "success",
        title: "ลบข้อมูลไม่สำเร็จ",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  const options = books.map(book => <Option key={book.book_id}>{book.book_title}</Option>);
  
  return (
    <>
      <Container fluid>
        <div style={{ padding: 20 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">
                    รายการหนังสือใน Playlist
                  </h6>
                </Col>
                <Col className="text-right">
                  <Button
                    onClick={() => {
                      this.props.history.push("/playlist");
                    }}
                    type="Button"
                    className="btn btn-danger mr-2"
                  >
                    Back
                  </Button>
                  <Button
                    onClick={openModal}
                    className="btn btn-secondary"
                  >
                    เพิ่มหนังสือใน Playlist
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody style={{ padding: "1rem" }}>
              <Table
                columns={columns}
                dataSource={data} 
                pagination={false}
                rowKey="index"
                loading={loading}
                components={{
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </Container>

      <Modal 
        title={null} 
        footer={null} 
        visible={isModalVisible} 
        onCancel={closeModal}
        closable={false}
        width={600}
      >
        <Formik
          render={({ values, handleChange, setFieldValue }) => (
            <React.Fragment>
              <Row form>
                <Col md="12" className="form-group">
                  <label>ชื่อหนังสือ</label>

                  <Select
                    showSearch
                    value={keyword}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    value={value}
                    onSearch={handleBookSearch}
                    onChange={handleBookChange}
                    notFoundContent={null}
                    className="w-100"
                  >
                    {options}
                  </Select>
                </Col>
              </Row>

              <div className="text-right mt-3">
                <Button
                  type="button"
                  className="btn btn-primary"
                  onClick={addPlaylistItem}
                  loading={adding}
                  style={{
                    marginRight: 10,
                    color: "white",
                    width: 100,
                  }}
                >
                  บันทึก
                </Button>
                <button
                  onClick={(e) => this.closeModal()}
                  type="Button"
                  className="btn btn-danger"
                  style={{ color: "white", width: 100 }}
                >
                  ยกเลิก
                </button>
              </div>
            </React.Fragment>
          )}
        />
      </Modal>
    </>
  )
}


import { httpClient } from "../utils/HttpClient";

import {
    HTTP_CHAPTER_RANK_FETCHING,
    HTTP_CHAPTER_RANK_SUCCESS,
    HTTP_CHAPTER_RANK_FAILED,
  } from "../constants/report";
  

import {server} from "../constants"
import moment from "moment";

const setStateChapterRankToSuccess = payload => ({
    type: HTTP_CHAPTER_RANK_SUCCESS,
    payload: payload
  });
  
  const setStateChapterRankToFetching = () => ({
    type: HTTP_CHAPTER_RANK_FETCHING
  });
  
  const setStateChapterRankToFailed = () => ({
    type: HTTP_CHAPTER_RANK_FAILED
  });

  /* Get Report Chapter Rank */
export const getReportChapterBotRead = (value) => {
    return async (dispatch) => {
      dispatch(setStateChapterRankToFetching());
      await doGetReportChapterBotRead(dispatch,value);
    };
  };
  
  const doGetReportChapterBotRead = (dispatch, value) => {
    let dataFilter = "";
  
    if (value) {
      if (value.start) {
        dataFilter = "?start_date=" + value.start + " 00:00.00";
      }
  
      if (value.end) {
        if(!value.start)
        dataFilter += "?end_date=" + value.end + " 23:59.00";
        else
        dataFilter += "&end_date=" + value.end + " 23:59.00";
      }
  
      if (value.bookTitle) {
        if (dataFilter.length > 0)
        dataFilter += "&book_title=" + value.bookTitle;
        else dataFilter += "?book_title=" + value.bookTitle;
      }
    }
    else 
      dataFilter = "?end_date=" + moment(new Date()).format("YYYY-MM-DD 23:59.00");
  
    const token = localStorage.getItem("token");

    httpClient
    .get(`${server.REPORT_URL}/chapter_rank/botread` + dataFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateChapterRankToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateChapterRankToFailed());
    });
    
  };
  
  
import {
  HTTP_REDEEM_LIST_SUCCESS,
  HTTP_REDEEM_LIST_FETCHING,
  HTTP_REDEEM_LIST_FAILED,
  HTTP_REDEEM_GROUP_SUCCESS,
  HTTP_REDEEM_GROUP_FETCHING,
  HTTP_REDEEM_GROUP_FAILED,
  HTTP_REDEEM_ITEM_LIST_SUCCESS,
  HTTP_REDEEM_ITEM_LIST_FETCHING,
  HTTP_REDEEM_ITEM_LIST_FAILED,
  HTTP_REDEEM_SEARCH_SUCCESS,
  HTTP_REDEEM_SEARCH_FETCHING,
  HTTP_REDEEM_SEARCH_FAILED,
} from "../constants";

const initialState = {
  result: null,
  groupData: null,
  itemList: null,
  item: null,
  isFetching: false,
  isGroupFetching: false,
  isItemListFetching: false,
  isSearching: false,
  isError: false,
  isGroupError: false,
  isItemListError: false,
  isSearchError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_REDEEM_LIST_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    case HTTP_REDEEM_LIST_FETCHING:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_REDEEM_LIST_FAILED:
      return { ...state, result: null, isFetching: true, isError: false };
    case HTTP_REDEEM_GROUP_SUCCESS:
      return { ...state, groupData: payload, isGroupFetching: false, isGroupError: false };
    case HTTP_REDEEM_GROUP_FETCHING:
      return { ...state, groupData: null, isGroupFetching: false, isGroupError: true };
    case HTTP_REDEEM_GROUP_FAILED:
      return { ...state, groupData: null, isGroupFetching: true, isGroupError: false };
    case HTTP_REDEEM_ITEM_LIST_SUCCESS: 
      return { ...state, itemList: payload, isItemListFetching: false, isItemListError: false };
    case HTTP_REDEEM_ITEM_LIST_FETCHING:
      return { ...state, itemList: null, isItemListFetching: false, isItemListError: true };
    case HTTP_REDEEM_ITEM_LIST_FAILED:
      return { ...state, itemList: null, isItemListFetching: true, isItemListError: false };
    case HTTP_REDEEM_SEARCH_SUCCESS: 
      return { ...state, item: payload, isSearching: false, isSearchError: false };
    case HTTP_REDEEM_SEARCH_FETCHING:
      return { ...state, item: null, isSearching: false, isSearchError: true };
    case HTTP_REDEEM_SEARCH_FAILED:
      return { ...state, item: null, isSearching: true, isSearchError: false };
    default:
      return state;
  }
};

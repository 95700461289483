import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormSelect,
  DatePicker,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import NumberFormat from "react-number-format";

export default class RedeemCodeFrom extends Component {
  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            if(!val) {
              val = ''
            }
            
            onChange(name, val);
          }}
        />
      );
    };
    const { values, handleChange, isDisableField, setFieldValue, errors, touched } = this.props;
    
    return (
      <React.Fragment>
        <fieldset disabled={isDisableField}>
          <Row form>
            {/* <Col md="4" className="form-group">
              <label>Partner Company</label>
              <FormSelect
                name="partner"
                id="partner"  
                disabled={values.isEdit}
                onChange={handleChange}
                value={values.partner}
              >
                <option value="1">PT</option>
              </FormSelect>
            </Col> */}
            <Col md="6" className="form-group">
              <label>Campaign Name</label>
              <FormInput
                name="name"
                id="name"
                onChange={handleChange}
                value={values.name}
              />
              {errors.name && touched.name ? (
                <div className="font-12 text-primary mt-1">{errors.name}</div>
              ) : null}
            </Col>
            <Col md="6" className="form-group">
            <label>Code</label>
              <FormInput
                name="code"
                id="code"
                onChange={handleChange}
                value={values.code}
                disabled={values.isEdit && !values.code}
              />
              {errors.code && touched.code ? (
                <div className="font-12 text-primary mt-1">{errors.code}</div>
              ) : null}
            </Col>
          </Row>
          <Row form>
            <Col md="4" className="form-group">
              <label>จำนวนเหรียญที่จะได้รับ</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="coin"
                id="coin"
                placeholder=""
                onChange={handleChange}
                value={values.coin}
              />

              {errors.coin && touched.coin ? (
                <div className="font-12 text-primary mt-1">{errors.coin}</div>
              ) : null}
            </Col>
            <Col md="4" className="form-group">
              <label>จำนวนหลัก</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="digit"
                id="digit"
                placeholder=""
                readOnly={values.isEdit || values.code}
                onChange={handleChange}
                value={values.digit}
              />

              {errors.digit && touched.digit ? (
                <div className="font-12 text-primary mt-1">{errors.digit}</div>
              ) : null}
            </Col>
            <Col md="4" className="form-group">
              <label>จำนวน code</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="quantity"
                id="quantity"
                placeholder=""
                readOnly={values.isEdit || values.code}
                onChange={handleChange}
                value={values.quantity}
              />

              {errors.quantity && touched.quantity ? (
                <div className="font-12 text-primary mt-1">{errors.quantity}</div>
              ) : null}
            </Col>
          </Row>

          <Row form>
            <Col md="4" className="form-group">
              <label>วันที่ code หมดอายุ</label>
              <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                <DatePickerField
                  size="sm"
                  name="validDate"
                  onChange={setFieldValue}
                  value={values.validDate}
                  dropdownMode="select"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

              {errors.validDate && touched.validDate ? (
                <div className="font-12 text-primary mt-1">{errors.validDate}</div>
              ) : null}
            </Col>

            <Col md="4" className="form-group">
              <label>วันที่เหรียญหมดอายุ</label>
              <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                <DatePickerField
                  size="sm"
                  name="coinExpiredDate"
                  onChange={setFieldValue}
                  value={values.coinExpiredDate}
                  dropdownMode="select"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>

              {errors.coinExpiredDate && touched.coinExpiredDate ? (
                <div className="font-12 text-primary mt-1">{errors.coinExpiredDate}</div>
              ) : null}
            </Col>
            <Col md="4" className="form-group">
              <label>สถานะ</label>
              <FormSelect
                name="status"
                id="status"  
                onChange={handleChange}
                value={values.status}
              >
                <option value="1">ใช้งานได้</option>
                <option value="2">ใช้แล้ว</option>
                <option value="3">ปิดการใช้งาน</option>
                <option value="4">หมดอายุ</option>
              </FormSelect>
            </Col>
          </Row>
          <Row form>
            <Col md="4" className="form-group">
              <label>จำนวนครั้งที่สามารถใช้ได้</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="limit"
                id="limit"
                placeholder=""
                onChange={handleChange}
                value={values.limit}
                disabled={!values.code}
              />

              {errors.limit && touched.limit ? (
                <div className="font-12 text-primary mt-1">{errors.limit}</div>
              ) : null}
            </Col>
          </Row>
        </fieldset>
      </React.Fragment>
    );
  }
}

import { httpClient } from "../utils/HttpClient";
import {server} from "../constants"

import {
    HTTP_CHAPTERS_SUCCESS,
    HTTP_CHAPTERS_FETCHING,
    HTTP_CHAPTERS_FAILED,
    HTTP_CHAPTER_SUCCESS,
    HTTP_CHAPTER_FETCHING,
    HTTP_CHAPTER_FAILED,
    HTTP_CHAPTER_MAIN_CHARACTER
  } from "../constants/book_chapter";

const setStateChaptersToSuccess = payload => ({
  type: HTTP_CHAPTERS_SUCCESS,
  payload: payload
});

const setStateChaptersToFetching = () => ({
  type: HTTP_CHAPTERS_FETCHING
});

const setStateChaptersToFailed = () => ({
  type: HTTP_CHAPTERS_FAILED
});

const setStateChapterToSuccess = payload => ({
  type: HTTP_CHAPTER_SUCCESS,
  payload: payload
});

const setStateChapterToFetching = () => ({
  type: HTTP_CHAPTER_FETCHING
});

const setStateChapterToFailed = () => ({
  type: HTTP_CHAPTER_FAILED
});

const setStateChapterMainCharacter = payload => ({
  type: HTTP_CHAPTER_MAIN_CHARACTER,
  payload: payload
});

export const getChapterByBookID = (book_id, page) => {
  return async (dispatch) => {
    dispatch(setStateChaptersToFetching());
    await doGetChapterByBookID(dispatch, book_id, page);
  };
};

const doGetChapterByBookID = async (dispatch, book_id, page) => {
  try {
    const token = localStorage.getItem("token");
    const result = await httpClient.get(`${server.BOOK_CHAPTER_URL}/book/${book_id}`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: page
      }
    })

    dispatch(setStateChaptersToSuccess(result.data));
  } catch(err)  {
    dispatch(setStateChaptersToFailed());
  }
};
  
export const getChapterByID = (book_chaptor_id) => {
  return async (dispatch) => {
    dispatch(setStateChapterToFetching());

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`${server.BOOK_CHAPTER_URL}/id/${book_chaptor_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      
      dispatch(setStateChapterToSuccess(result.data));
    } catch(err)  {
      dispatch(setStateChapterToFailed());
    }
  };
};

export const deleteBookChapter = (book_chaptor_id,book_id) => {
  return async (dispatch) => {
    dispatch(setStateChapterToFetching());
    const token = localStorage.getItem("token");
    await httpClient.delete(
      `${server.BOOK_CHAPTER_URL}/${book_chaptor_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
};

export const setMainCharacter = (characterId) => {
  return async dispatch => {
    try {
      // const result = await httpClient.put(
      //   `admin/book_chapter/${chapterId}/main-character`, 
      //   { characterId: characterId },
      //   {
      //     headers: {
      //       "Authorization": `Bearer ${token}`
      //     }
      //   }
      // );

      dispatch(setStateChapterMainCharacter(characterId))
    } catch (err) {
      dispatch(setStateChapterToFailed());
    }
  };
};


import React, { Component } from "react";
import { Formik } from "formik";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";

import { getMemberById } from "../../../actions/member.action";
import { connect } from "react-redux";
import MemberFrom from "../../Member/memberForm";

class ViewMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      from_page: this.props.match.params.from_page,
    };
  }

  componentDidMount() {
    let member_id = this.props.match.params.member_id;
    this.props.getMemberById(member_id);

    const { page } = this.props.location;
    this.setState({ page });
  }

  showForm = ({ values, handleChange }) => {
    return (
      <div style={{ padding: 20 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">VIEW MEMBER</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal">
                    <MemberFrom
                      values={values}
                      handleChange={handleChange}
                      isDisableField={true}
                      laberImg={"Member Image"}
                      isVisibleSignUpChannel={true}
                    ></MemberFrom>

                    {this.state.from_page === "member" && (
                      <button
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/member",
                            page: this.state.page,
                          })
                        }
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ย้อนกลับ
                      </button>
                    )}
                    {this.state.from_page === "active_member" && (
                      <button
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/active_member",
                            page: this.state.page,
                          })
                        }
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ย้อนกลับ
                      </button>
                    )}
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    const { result } = this.props.memberReducer;

    return (
      <React.Fragment>
        <Formik enableReinitialize initialValues={result ? result : {}}>
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ memberReducer }) => ({ memberReducer });

const mapDispatchToProps = {
  getMemberById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewMember);

import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormSelect,
  FormTextarea,
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText,
} from "shards-react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { getCategory } from "../../../actions/category.action";
import { getWriterName, getAllMember } from "../../../actions/member.action";
import { getAllSubCategory } from "../../../actions/sub_category.action";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Modal, Button } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import "moment/locale/th";
import BookCharacterGroup from "../BookChapter/BookCharacterGroup";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const animatedComponents = makeAnimated();

class BookForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      writers: "",
      creators: [
        {
          value: "",
          label: "",
          income: 100,
        },
      ],
      img_src_book: "",
      img_src_cover: "",
      img_src_landscape: "",
      category_id: "",
      categories: [],
      subCategories: [],
      selectedSubCategories: [],
      isChatBook: false,
      cropModalOpen: false,
      bookImage: "",
      bookImageLandscape: "",
      bookCover: "",
      imageUrl: "",
      imageFile: "",
      imageType: "",
    };

    this.cropperRef = React.createRef();

    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);

    if (this.props.values.startdate) {
      this.props.values.startdate = moment(this.props.values.startdate).format(
        "YYYY-MM-DD HH:mm:00"
      );
      this.props.values.bookStart = this.props.values.startdate;
    }
  }

  handleStartTimeChange(value) {
    if (value) {
      const startTime = value.toDate();
      this.props.values.startdate = startTime;
    } else this.props.values.startdate = "";
  }

  async componentDidMount() {
    this.setState({
      bookImage: this.props.values.book_image,
      bookImageLandscape: this.props.values.book_image_mobile,
      bookCover: this.props.values.book_cover,
      buy_whole_book_checked:
        this.props.values.buy_whole_book === "Y" ? true : false,
    });

    this.props.getAllSubCategory();

    await this.props.getWriterName();
    await this.props.getCategory();

    const categoryResult = this.props.categoryReducer.result;

    this.checkChatBook(this.props.values.categories);

    if (categoryResult) {
      const categories = categoryResult.map((item) => {
        return {
          value: item.category_id,
          label: item.category_name,
        };
      });

      this.setState({ categories });
    }

    this.updateSubCategoryList(this.props.values.categories);

    if (this.props.memberReducer.writers) {
      const writers = this.props.memberReducer.writers.map((writer) => {
        return {
          value: writer.member_id,
          label: writer.writer_name,
        };
      });

      this.setState({ writers: writers });
    }

    if (this.props.values.creators.length > 0) {
      await this.setState({ creators: this.props.values.creators });
    }
  }

  onSelectFile(e, type) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          imageUrl: reader.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);

      this.setState({
        imageType: type,
        cropModalOpen: true,
        imageFile: e.target.files[0],
      });
    }
  }

  cropImage() {
    const cropper = this.cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = this.state.imageFile.name;
      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: this.state.imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      this.props.onUpdateThumbnail(file, this.state.imageType);

      if (this.state.imageType === "book") {
        this.setState({ bookImage: cropppedPreview });
      } else if (this.state.imageType === "landscape") {
        this.setState({ bookImageLandscape: cropppedPreview });
      } else if (this.state.imageType === "cover") {
        this.setState({ bookCover: cropppedPreview });
      }

      this.setState({ imageType: "" });

      this.closeCropModal();
    });
  }

  removeImage(type) {
    this.props.onRemoveThumbnail(type);

    if (type === "book") {
      this.setState({ bookImage: "" });
    } else if (type === "landscape") {
      this.setState({ bookImageLandscape: "" });
    } else if (type === "cover") {
      this.setState({ bookCover: "" });
    }
  }

  closeCropModal() {
    this.setState({ cropModalOpen: false });
  }

  onCategorySelected = (inputValue, { action }) => {
    this.props.values.categories = inputValue;

    this.checkChatBook(this.props.values.categories);

    this.updateSubCategoryList(inputValue);
  };

  checkChatBook(categories) {
    const isChatBook =
      categories.filter((item) => item.value === 12).length > 0;
    this.setState({ isChatBook });
  }

  async updateSubCategoryList(inputValue) {
    let subCategories = [];

    if (inputValue) {
      const categoryList = inputValue.map((item) => {
        return {
          id: item.value,
          name: item.label,
        };
      });

      if (categoryList.length > 0) {
        categoryList.map((mainCategory) => {
          const subCategoryOptions = this.props.subCategoryReducer.result
            .filter((item) => {
              return item.category_id === mainCategory.id;
            })
            .map((item) => {
              return {
                value: item.sub_category_id,
                label: item.sub_category_name,
                mainCategoryId: mainCategory.id,
              };
            });

          subCategories.push({
            label: mainCategory.name,
            options: subCategoryOptions,
          });
        });
      }

      // Remove sub-category under removed main category
      const categoryIdList = categoryList.map((item) => {
        return item.id;
      });

      this.props.values.sub_categories =
        this.props.values.sub_categories.filter((item) => {
          return categoryIdList.includes(item.mainCategoryId);
        });
    } else {
      this.props.values.categories = [];
      this.props.values.sub_categories = [];
    }

    await this.setState({
      subCategories,
      selectedSubCategories: this.props.values.sub_categories,
    });
  }

  onSubCategorySelected = (inputValue, { action }) => {
    this.props.values.sub_categories = inputValue;

    if (!inputValue) this.props.values.sub_categories = [];

    this.setState({
      selectedSubCategories: inputValue,
    });
  };

  addWriter() {
    this.setState((prevState) => ({
      creators: [
        ...prevState.creators,
        {
          value: "",
          label: "",
          income: 0,
        },
      ],
    }));
  }

  setCreatorsValue(value) {
    this.setState({ creators: value });
    this.props.values.creators = value;
  }

  removeWriter(index) {
    let newCreators = this.state.creators;
    newCreators.splice(index, 1);

    this.setCreatorsValue(newCreators);
  }

  loadWriterOptions = (inputValue, callback) => {
    callback(this.filterWriters(inputValue));
  };

  filterWriters(inputValue) {
    let writers = this.state.writers;

    if (writers) {
      return writers.filter((writer) =>
        writer.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  }

  handleWriterChange(inputValue, index) {
    let creators = this.state.creators;
    creators[index].value = inputValue.value;
    creators[index].label = inputValue.label;
    this.setCreatorsValue(creators);
  }

  async handleIncomeChange(e, index) {
    let creators = this.state.creators;
    creators[index].income = e.target.value;
    this.setCreatorsValue(creators);
  }

  formatTime(value) {
    if (value) {
      return moment(value);
    }
  }

  formatDate(value) {
    if (value) {
      var startDate = moment(value, "YYYY-MM-DD HH:mm").toDate();
      return startDate;
    }
  }

  render() {
    const {
      values,
      handleChange,
      handleBlur,
      setFieldValue,
      errors,
      touched,
      value,
    } = this.props;

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };

    const ImageCover = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            id="book_cover"
            name="book_cover"
            src={value}
            className="img-fluid"
            width="400"
          />
        );
      } else {
        return (
          <img
            id="book_cover"
            name="book_cover"
            src={this.state.img_src_cover}
            className="img-fluid"
            width="400"
          />
        );
      }
    };

    const ImageLandscape = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            id="book_image_mobile"
            name="book_image_mobile"
            src={value}
            width="400"
            className="img-fluid"
          />
        );
      } else {
        return (
          <img
            id="book_image_mobile"
            name="book_image_mobile"
            src={this.state.img_src_landscape}
            width="400"
            className="img-fluid"
          />
        );
      }
    };

    return (
      <React.Fragment>
        <fieldset>
          <Row form>
            <Col md="6" className="form-group">
              <label>ประเภทหนังสือ</label>
              <FormSelect
                name="bot_read"
                id="bot_read"
                onChange={handleChange}
                value={values.bot_read}
              >
                <option value="N">หนังสืออ่านออนไลน์</option>
                <option value="Y">หนังสือเสียง(Bot Read)</option>
                })}
              </FormSelect>
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>Category</label>
              <Select
                components={animatedComponents}
                defaultValue={values.categories}
                onChange={this.onCategorySelected}
                isMulti
                options={this.state.categories}
                placeholder="เลือกหมวดหมู่"
              />
            </Col>
            <Col md="6" className="form-group">
              <label>Subcategory</label>
              <Select
                components={animatedComponents}
                defaultValue={values.sub_categories}
                value={this.state.selectedSubCategories}
                onChange={this.onSubCategorySelected}
                isMulti
                options={this.state.subCategories}
                placeholder="เลือกหมวดหมู่ย่อย"
                isDisabled={this.state.subCategories.length === 0}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>Book Type</label>
              <FormSelect
                name="book_type_id"
                id="book_type_id"
                placeholder=""
                onChange={handleChange}
                value={values.book_type_id}
              >
                <option value="2">HTML</option>
              </FormSelect>
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>Book Title</label>
              <FormInput
                name="book_title"
                id="book_title"
                placeholder=""
                onChange={handleChange}
                value={values.book_title}
              />
              {errors.book_title && touched.book_title ? (
                <div className="font-12 text-primary mt-1">
                  {errors.book_title}
                </div>
              ) : null}
            </Col>
            <Col md="6" className="form-group">
              <label>Book Preface</label>
              <FormInput
                name="book_sub_title"
                id="book_sub_title"
                placeholder=""
                onChange={handleChange}
                value={values.book_sub_title}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="12" className="form-group">
              <label>Book Detail</label>
              <FormTextarea
                rows="5"
                name="book_desc"
                id="book_desc"
                onChange={handleChange}
                value={values.book_desc}
              />
            </Col>
          </Row>

          <Row>
            <div className="d-flex align-items-center mt-3">
              <div className="flex-fill mr-3">
                <h6 className="m-0 ml-2 text-accent">Creator</h6>
              </div>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.addWriter()}
              >
                Add Creator
              </button>
            </div>

            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th>Creator Name</th>
                  <th>Revenue Ratio (%)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.creators.map((creator, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <AsyncSelect
                          cacheOptions
                          loadOptions={this.loadWriterOptions}
                          defaultOptions={this.state.writers}
                          value={this.state.creators[index]}
                          onChange={(inputValue, { action }) =>
                            this.handleWriterChange(inputValue, index)
                          }
                        />
                      </td>
                      <td width="150">
                        <FormInput
                          placeholder=""
                          type="number"
                          name={`income-${index}`}
                          id={`income-${index}`}
                          onChange={(e) => this.handleIncomeChange(e, index)}
                          value={this.state.creators[index].income}
                          className="text-center"
                        />
                      </td>
                      <td width="100">
                        {index > 0 ? (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => this.removeWriter(index)}
                          >
                            Remove
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Row>

          {values.bot_read == "Y" ? (
            <Row form className="mt-3">
              <Col md="3" className="form-group">
                <label className="text-accent">BotNoi Revenue Ratio (%)</label>
                <FormInput
                  placeholder=""
                  type="number"
                  name="botnoi_income_ratio"
                  id="botnoi_income_ratio"
                  onChange={handleChange}
                  value={values.botnoi_income_ratio || ""}
                  className="text-center"
                />
              </Col>
            </Row>
          ) : null}

          <Row form className="mt-3">
            <Col md="12" className="form-group">
              <label>Translator</label>
              <FormInput
                name="translator"
                id="translator"
                placeholder=""
                onChange={handleChange}
                value={values.translator || ""}
              />
            </Col>
          </Row>

          <Row form>
            <Col className="form-group">
              <label>
                Book Image{" "}
                <span className=" text-danger">
                  {" "}
                  (ขนาดรูปที่แนะนำ 540 x 765 px)
                </span>
              </label>

              <div style={{ width: 200 }}>
                <div className="position-relative my-2">
                  {this.state.bookImage ? (
                    <img
                      src={this.state.bookImage}
                      className="img-fluid"
                      width="200"
                    />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center"
                      style={{ width: 200, height: 283 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      this.onSelectFile(event, "book");
                    }}
                  />
                </div>

                {this.state.bookImage && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => this.removeImage("book")}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row form>
            <Col className="form-group">
              <label>
                Book Cover Image{" "}
                <span className=" text-danger">
                  {" "}
                  (ขนาดรูปที่แนะนำ 1170 x 248 px)
                </span>
              </label>
              <div style={{ maxWidth: 400 }}>
                <div className="position-relative my-2">
                  {this.state.bookCover ? (
                    <img
                      src={this.state.bookCover}
                      className="img-fluid"
                      width="400"
                    />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center"
                      style={{ width: 400, height: 86 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      this.onSelectFile(event, "cover");
                    }}
                  />
                </div>

                {this.state.bookCover && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => this.removeImage("cover")}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row form>
            <Col className="form-group">
              <label>
                Book Image Landscape{" "}
                <span className=" text-danger">
                  {" "}
                  (ขนาดรูปที่แนะนำ 1200 X 630 px)
                </span>
              </label>
              <div style={{ maxWidth: 400 }}>
                <div className="position-relative my-2">
                  {this.state.bookImageLandscape ? (
                    <img
                      src={this.state.bookImageLandscape}
                      className="img-fluid"
                      width="400"
                    />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center"
                      style={{ width: 400, height: 210 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      this.onSelectFile(event, "landscape");
                    }}
                  />
                </div>

                {this.state.bookImageLandscape && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => this.removeImage("landscape")}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          {/* <Row form>
            <Col md="6" className="form-group">
              <label>Tags</label>
              <FormInput
                name="book_tags"
                id="book_tags"
                placeholder=""
                onChange={handleChange}
                value={values.book_tags}
              />
            </Col>
            <Col md="6" className="form-group">
              <label>LINK stock2morrow</label>
              <FormInput
                name="stock_link"
                id="stock_link"
                placeholder=""
                onChange={handleChange}
                value={values.stock_link || ""}
              />
            </Col>
          </Row> */}

          <Row form>
            <Col md="6" className="form-group">
              <label>Status</label>
              <FormSelect
                name="book_status"
                id="book_status"
                placeholder=""
                onChange={handleChange}
                value={values.book_status}
              >
                <option value="">Select Book Status</option>
                <option value="A">Enable</option>
                <option value="I">Disable</option>
                <option value="W">Waiting</option>
              </FormSelect>
            </Col>
            <Col md="6" className="form-group">
              <label>Finish</label>
              <FormSelect
                name="is_finish"
                id="is_finish"
                placeholder=""
                onChange={handleChange}
                value={values.is_finish}
              >
                <option value="">Select</option>
                <option value="YES">จบแล้ว</option>
                <option value="NO">ยังไม่จบ</option>
              </FormSelect>
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>Order</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="norder"
                id="norder"
                placeholder=""
                onChange={handleChange}
                value={values.norder}
              />
              {errors.norder && touched.norder ? (
                <div className="font-12 text-primary mt-1">{errors.norder}</div>
              ) : null}
            </Col>
            <Col md="6" className="form-group">
              <label>ส่วนลดเมื่อซื้อทั้งเล่ม (%)</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="all_chapters_discount"
                id="all_chapters_discount"
                placeholder=""
                onChange={handleChange}
                value={values.all_chapters_discount}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>สัดส่วนรายได้เหรียญทอง</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="gold_coin_ratio"
                id="gold_coin_ratio"
                placeholder=""
                onChange={handleChange}
                value={values.gold_coin_ratio}
              />
            </Col>
            <Col md="6" className="form-group">
              <label>สัดส่วนรายได้เหรียญเงิน</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="silver_coin_ratio"
                id="silver_coin_ratio"
                placeholder=""
                onChange={handleChange}
                value={values.silver_coin_ratio}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="6" className="form-group">
              <label>สัดส่วนรายได้บรรณาธิการ</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="editor_income_ratio"
                id="editor_income_ratio"
                placeholder=""
                onChange={handleChange}
                value={values.editor_income_ratio}
              />
            </Col>
            <Col md="6" className="form-group">
              <label>สัดส่วนรายได้สำนักพิมพ์</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="publisher_income_ratio"
                id="publisher_income_ratio"
                placeholder=""
                onChange={handleChange}
                value={values.publisher_income_ratio}
              />
            </Col>
          </Row>

          <Row form>
            <Col md="3" className="form-group">
              <label>วันที่เผยแพร่เริ่มต้น</label>
              <InputGroup className={"d-flex my-auto date-range date-w-100"}>
                <DatePickerField
                  size="sm"
                  name="bookStart"
                  onChange={setFieldValue}
                  value={this.formatDate(values.bookStart)}
                  dropdownMode="select"
                  className="text-center"
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i className="material-icons">&#xE916;</i>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>

            <Col md="3">
              <label>เวลา</label>
              <TimePicker
                defaultValue={this.formatTime(this.props.values.startdate)}
                showSecond={false}
                format={"HH:mm"}
                className="time-picker-block"
                minuteStep={5}
                onChange={this.handleStartTimeChange}
              />
            </Col>

            <Col md="6" className="form-group">
              <label>สามารถใช้เหรียญเงินซื้อได้</label>
              <FormSelect
                name="silver_coin_use"
                id="silver_coin_use"
                placeholder=""
                onChange={handleChange}
                value={values.silver_coin_use}
              >
                <option value="Y">ได้</option>
                <option value="N">ไม่ได้</option>
              </FormSelect>
            </Col>
          </Row>

          <Row>
            <Col md="6" className="form-group">
              <label>
                บังคับซื้อหนังสือทั้งเล่ม (ไม่สามารถซื้อหนังสือแต่ละตอนได้)
              </label>
              <FormSelect
                name="buy_whole_book"
                id="buy_whole_book"
                placeholder=""
                onChange={handleChange}
                value={values.buy_whole_book}
              >
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </FormSelect>
            </Col>
          </Row>

          {this.state.isChatBook && values.book_id && (
            <BookCharacterGroup bookId={values.book_id} />
          )}
        </fieldset>

        <Modal
          title={null}
          footer={null}
          width={800}
          maskClosable={false}
          visible={this.state.cropModalOpen}
          onCancel={() => this.setState({ cropModalOpen: false })}
        >
          <div className="d-flex">
            <div className="flex-fill thumbnail-crop-area">
              {this.state.imageType === "book" && (
                <Cropper
                  src={this.state.imageUrl}
                  aspectRatio={540 / 765}
                  autoCropArea={1}
                  preview=".book-preview"
                  viewMode={3}
                  ref={this.cropperRef}
                />
              )}

              {this.state.imageType === "landscape" && (
                <Cropper
                  src={this.state.imageUrl}
                  aspectRatio={1200 / 630}
                  autoCropArea={1}
                  preview=".book-landscape-preview"
                  viewMode={3}
                  ref={this.cropperRef}
                />
              )}

              {this.state.imageType === "cover" && (
                <Cropper
                  src={this.state.imageUrl}
                  aspectRatio={1170 / 248}
                  autoCropArea={1}
                  preview=".book-cover-preview"
                  viewMode={3}
                  ref={this.cropperRef}
                />
              )}
            </div>

            <div className="mt-4 book-preview-area ml-4">
              <div className="font-weight-bold text-center mb-2">
                ตัวอย่างรูป
              </div>
              {this.state.imageType === "book" && (
                <div className="book-preview" />
              )}

              {this.state.imageType === "landscape" && (
                <div className="book-landscape-preview" />
              )}

              {this.state.imageType === "cover" && (
                <div className="book-cover-preview" />
              )}
            </div>
          </div>

          <div className="mt-5 text-center">
            <button
              type="button"
              onClick={() => {
                this.cropImage();
              }}
              className="btn btn-primary mr-2"
            >
              ตกลง
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                this.setState({ cropModalOpen: false });
              }}
            >
              ยกเลิก
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  categoryReducer,
  memberReducer,
  subCategoryReducer,
  bookCharacterReducer,
  bookReducer,
}) => ({
  categoryReducer,
  memberReducer,
  subCategoryReducer,
  bookCharacterReducer,
  bookReducer,
});

const mapDispatchToProps = {
  getCategory,
  getWriterName,
  getAllSubCategory,
  getAllMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookForm);

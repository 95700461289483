import React, { Component } from "react";
import { getReportBuyAllBook } from "../../../actions/reportBuyAllBook.action";
import { getBooks } from "../../../actions/book.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import Autosuggest from "react-autosuggest";
import MenuReport from "../menuReport";

class ReportBuyAllBook extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      value: "",
      start_date: "",
      end_date: "",
    };
  }

  async componentDidMount() {
    await this.props.getReportBuyAllBook();
    await this.props.getBooks();
    this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  formatDecimal(row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row);
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportBuyAllBookReducer;
      const csv_name = "ReportBuyAllBook.csv";

      var data = [];
      if (result) {
        data = result;

        let date_now = moment(Date()).format("DD/MM/YY");

        let date = "";
        if (this.state.start_date) {
          date = this.state.start_date + " - " + date_now;
        }

        if (this.state.end_date) {
          if (this.state.start_date) {
            date = this.state.start_date + " - " + this.state.end_date;
          } else date = "XXX - " + this.state.end_date;
        }

        if (date === "") date = "XXX  -  " + date_now;

        data.forEach(function(e) {
          if (typeof e === "object") {
            e["date"] = date;
          }
        });

        var book_result = data.filter(function(d) {
          return d.book_title != null;
        });
      }

      return (
        !isFetching &&
        book_result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={book_result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="date"
                width="120px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="วันที่"
              >
                วันที่ทำรายการซื้อ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_title"
                width="180px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="ชื่อหนังสือที่ซื้อครบทุกตอน"
              >
                ชื่อหนังสือที่ซื้อครบทุกตอน
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_id"
                isKey={true}
                hidden={true}
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Book ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_name"
                width="130px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="ชื่อสมาชิก"
              >
                ชื่อสมาชิก
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="member_email"
                width="130px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="อีเมลล์"
              >
                อีเมลล์
              </TableHeaderColumn>
              
              <TableHeaderColumn
                dataField="member_phone"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="เบอร์โทรศัพท์"
              >
                เบอร์โทรศัพท์
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuReport />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">
                      Report Buy All Book (สมาชิกที่ซื้อหนังสือครบทุกตอน)
                    </h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    book_title: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;

                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );

                      let format_date = moment(values.start_date).format(
                        "DD/MM/YY"
                      );
                      this.setState({ start_date: format_date });
                    } else this.setState({ start_date: "" });

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");

                      let format_date = moment(endDate).format("DD/MM/YY");
                      this.setState({ end_date: format_date });
                    } else {
                      this.setState({ end_date: "" });
                      endDate = moment(new Date())
                        .format("YYYY-MM-DD");
                    }

                    value.start_date = startDate;
                    value.end_date = endDate;

                    values.book_title = this.state.bookTitle;

                    if (values.book_title) {
                      value.book_title = values.book_title;
                    }

                    this.props.getReportBuyAllBook(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="6" className="form-group">
                            <label>วันที่ทำรายการซื้อ เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm="8" className="form-group">
                            <label>ชื่อหนังสือ</label>
                            <Autosuggest
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={
                                this.onSuggestionsFetchRequested
                              }
                              onSuggestionsClearRequested={
                                this.onSuggestionsClearRequested
                              }
                              shouldRenderSuggestions={
                                this.shouldRenderSuggestions
                              }
                              onSuggestionSelected={this.onSuggestionSelected}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                            />
                          </Col>
                          <Col sm="2" className="text-right">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm"
                              style={{
                                marginLeft: 30,
                                marginTop: 27,
                                color: "white",
                                width: 100,
                              }}
                            >
                              Search
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportBuyAllBookReducer, bookReducer }) => ({
  reportBuyAllBookReducer,
  bookReducer,
});

const mapDispatchToProps = {
  getReportBuyAllBook,
  getBooks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuyAllBook);

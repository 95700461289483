import React, { Component } from "react";
import { Formik } from "formik";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";

import { getUserById } from "../../../actions/user.action";
import { connect } from "react-redux";
import UserFrom from "../userForm";

class ViewUser extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    let user_id = this.props.match.params.user_id;
    this.props.getUserById(user_id);
  }

  showForm = ({ values, handleChange }) => {
    return (
      <React.Fragment>
        <div style={{ padding: 30 }}>
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">VIEW USER</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <form className="form-horizontal">
                      <UserFrom
                        values={values}
                        handleChange={handleChange}
                        isDisableField={true}
                      ></UserFrom>

                      <a
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        Back
                      </a>
                    </form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { result } = this.props.userReducer;
    return (
      <React.Fragment>
        <Formik enableReinitialize initialValues={result ? result : {}}>
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer }) => ({ userReducer });

const mapDispatchToProps = {
  getUserById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);

import { httpClient } from "../utils/HttpClient";

import {
  HTTP_BOOKS_FETCHING,
  HTTP_BOOKS_SUCCESS,
  HTTP_BOOKS_FAILED,
  HTTP_BOOK_FETCHING,
  HTTP_BOOK_SUCCESS,
  HTTP_BOOK_FAILED,
} from "../constants/book";

import {
  server
} from "../constants";

const setStateBooksToSuccess = payload => ({
  type: HTTP_BOOKS_SUCCESS,
  payload: payload
});

const setStateBooksToFetching = () => ({
  type: HTTP_BOOKS_FETCHING
});

const setStateBooksToFailed = () => ({
  type: HTTP_BOOKS_FAILED
});

const setStateBookToSuccess = payload => ({
  type: HTTP_BOOK_SUCCESS,
  payload: payload
});

const setStateBookToFetching = () => ({
  type: HTTP_BOOK_FETCHING
});

const setStateBookToFailed = () => ({
  type: HTTP_BOOK_FAILED
});

export const getBooks = () => {
  return async dispatch => {
    dispatch(setStateBooksToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`admin/books`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      dispatch(setStateBooksToSuccess(result.data))
    } catch(error)  {
      alert(JSON.stringify(error))
      dispatch(setStateBooksToFailed())
    }
  };
};

export const getBookCategory = (keyword, category, page, bot_read,sub_category,book_status) => {
  return async (dispatch) => {
    dispatch(setStateBooksToFetching());
    await doGetBookCategory(dispatch, keyword, category, page, bot_read,sub_category,book_status);
  };
};

const doGetBookCategory = async (dispatch, keyword, category, page,bot_read,sub_category,book_status) => {
  try {
    const token = localStorage.getItem("token");

    const result = await httpClient.get(`/admin/book_category`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        keyword: keyword,
        category: category,
        page: page,
        bot_read: bot_read,
        sub_category:sub_category,
        book_status:book_status
      }
    })
    dispatch(setStateBooksToSuccess(result.data));
  } catch(err)  {
    dispatch(setStateBooksToFailed());
  }
};

export const getBookByID = (book_id) => {
  return async (dispatch) => {
    dispatch(setStateBookToSuccess());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.BOOK_URL}/${book_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      dispatch(setStateBookToSuccess(result.data));
    } catch(err)  {
      dispatch(setStateBookToFailed());
    }
  };
};

export const deleteBook = (book_id) => {
  return async (dispatch) => {
    dispatch(setStateBooksToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      server.BOOK_URL + "/" + `${book_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
};


import React, { Component } from "react";
import { Formik } from "formik";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";

import { getCampaignById } from "../../../actions/campaign.action";
import { connect } from "react-redux";
import CampaignFrom from "../campaignForm";

class ViewCampaign extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let campaign_id = this.props.match.params.campaign_id;
    this.props.getCampaignById(campaign_id);
  }

  showForm = ({ values, handleChange, setFieldValue }) => {
    return (
      <React.Fragment>
        <div style={{ padding: 40 }}>
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">VIEW CAMPAIGN</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <form className="form-horizontal">
                      <CampaignFrom
                        values={values}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        isDisableField={true}
                      ></CampaignFrom>

                      <a
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        Back
                      </a>
                    </form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { result } = this.props.campaignReducer;

    return (
      <React.Fragment>
        <Formik enableReinitialize initialValues={result ? result : {}}>
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ campaignReducer }) => ({ campaignReducer });

const mapDispatchToProps = {
  getCampaignById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCampaign);

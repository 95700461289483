import {
    HTTP_CHAPTER_RANK_FETCHING,
    HTTP_CHAPTER_RANK_SUCCESS,
    HTTP_CHAPTER_RANK_FAILED,
  } from "../constants/report";
  
  const initialState = {
    result: [],
    total: 0,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_CHAPTER_RANK_SUCCESS:        
        return { ...state, result: payload.data, total: payload.total, isFetching: false, isError: false };
      case HTTP_CHAPTER_RANK_FAILED:
        return { ...state, result: [], isFetching: false, isError: true };
      case HTTP_CHAPTER_RANK_FETCHING:
        return { ...state, result: [], isFetching: true, isError: false };
      default:
        return state;
    }
  };
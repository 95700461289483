import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormSelect,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  number
} from "shards-react";
import { Tabs, Modal, Button, Switch, message } from "antd";
import { PictureOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
// import arrayMove from 'array-move'
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "2read-ckeditor5-custom-build";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import imageCompression from "browser-image-compression";
import "moment/locale/th";
import ReactAudioPlayer from "react-audio-player";
import Swal from "sweetalert2";
import ChatChapterForm from "./ChatChapterForm";
import { websiteUrl } from "../../../config";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { getChapterByID } from "../../../actions/book_chapter.action";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { withStyles, makeStyles, duration } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReactDOM from "react-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const { TabPane } = Tabs;

class MyUploadAdapter {
  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest();
          this._initListeners(resolve, reject, file);
          this._sendRequest(file);
        })
    );
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open("POST", "https://api.2read.digital/api/image/upload", true);
    xhr.responseType = "json";
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;

    xhr.addEventListener("error", () => reject(genericErrorText));
    xhr.addEventListener("abort", () => reject());
    xhr.addEventListener("load", () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      resolve({
        default: response.url,
      });
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener("progress", (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  async _sendRequest(file) {
    const options = {
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);
    const newFile = new File([compressedFile], file.name, { type: file.type });

    const data = new FormData();
    data.append("upload", newFile);
    this.xhr.send(data);
  }
}

function customUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}

const getVideoDuration = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = error => reject(error);
  });

class ChapterForm extends Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      uploadModalOpen: false,
      previewModalOpen: false,
      cropModalOpen: false,
      colorOpen: false,
      currentCursor: "",
      files: [],
      currentColor: "",
      backgroundColor: "",
      colorType: "",
      startTime: 0,
      freeOnlyMember: false,
      audioFile: "",
      audioDuration: "",
      actualUrl: "",
      contentType: "normal",
      chapterThumbnail: "",
      imageUrl: "",
      imageFile: "",
      audioID: "",
      audioFileName: "",
      audioFileOrder: "",
      audio_action: "",
      audios: [],
      book_id: "",
      bot_read: "",
      duration: "",
      darkMode: false,
      videoTag: ""
    };
    this.onDragEnd = this.onDragEnd.bind(this);

    this.cropperRef = React.createRef();

    //Set file upload
    this.onDrop = (files) => {
      files.map((file) => {
        file.preview = URL.createObjectURL(file);
      });

      let newFiles = [...this.state.files, ...files];

      newFiles.map((file, index) => {
        file.index = index;
      });

      this.setState({ files: newFiles });
    };

    this.onColorClose = this.onColorClose.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
  }

  async componentDidMount() {
    
    const url = `${websiteUrl}/chapter/${this.props.values.book_chaptor_id}/${this.props.values.chaptor_title.replace(/ /g, '-').replace(/&/g, '-').replace(/\//g, '-')}`;

    this.setState({
      actualUrl: url,
      chapterThumbnail: this.props.values.chapter_thumbnail,
      chapterContent: this.props.values.chaptor_content,
      book_id: this.props.values.book_id,
    });

    await this.getAudioFile(this.props.values.book_chaptor_id);
    await this.checkBookIsBotRead();
  }

  async checkBookIsBotRead() {
    try {
      const token = localStorage.getItem("token");
      const response = await httpClient.get(
        `admin/book/check_botread/${this.state.book_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        const { bot_read } = response.data.bot_read;

        await this.setState({
          bot_read,
        });
      }
    } catch (err) {
    }
  }

  async uploadAudio(file) {
    if (file) {
      await this.setState({
        uploading: true
      });

      const duration = await getVideoDuration(file);
      
      var formDataAudio = new FormData();
      var audioFile = file;
      formDataAudio.append("upload", audioFile);

      let response = await httpClient.post('https://api.2read.digital/api/image/upload/audio', formDataAudio, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      });
      
      if (response.status === 201 || response.status === 200) {
        if (response.data.uploaded === 1) {
          this.props.values.chapter_audio_file = response.data.url;

          this.setState({
            audioFile: response.data.url,
            audioDuration : duration,
          });

          this.props.onUpdateAudio(response.data.url);
          Swal.fire({
            icon: "success",
            title: "Upload Audio File Complete",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }

      await this.setState({
        uploading: false,
      });
    }
  }

  removeAudio() {
    this.setState({
      audioFile: null,
      audioDuration: null
    });
    this.props.values.chapter_audio_file = null;
    this.props.onUpdateAudio("");
  }

  async uploadVideo(file) {
    if (file) {
      await this.setState({
        uploading: true
      })

      const formData = new FormData();
      var audioFile = file;
      formData.append("upload", file);

      let response = await httpClient.post('https://api.2read.digital/api/image/upload/audio', formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      });
      
      if (response.status === 201 || response.status === 200) {
        if (response.data.uploaded === 1) {

          const videoTag = `<video id='video' controls="controls" preload='none' width="600"><source id='mp4' src="${response.data.url}" type='video/mp4' /></video>`

          this.setState({
            videoTag
          })
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "อัพโหลดวิดีโอไม่สำเร็จ",
          timer: 3000,
        });
      }

      this.setState({
        uploading: false
      })
    }
  }

  removeVideoTag() {
    this.setState({
      videoTag: ''
    })
  }

  onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          imageUrl: reader.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);

      this.setState({
        cropModalOpen: true,
        imageFile: e.target.files[0],
      });
    }
  }

  cropImage() {
    const cropper = this.cropperRef.current.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const fileName = this.state.imageFile.name;
      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: this.state.imageFile.type,
      });
      const cropppedPreview = cropper.getCroppedCanvas().toDataURL();
      this.props.onUpdateThumbnail(cropppedPreview, file);

      this.setState({
        chapterThumbnail: cropppedPreview,
      });

      this.closeCropModal();
    });
  }

  removeImage() {
    this.setState({ chapterThumbnail: "" });
    this.props.onRemoveThumbnail();
  }

  closeCropModal() {
    this.setState({ cropModalOpen: false });
  }

  handleStartTimeChange(value) {
    const startTime = value ? value.toDate() : null;
    this.props.onTimeChange(startTime);
  }

  handleCheckbox(e, name) {
    const newState = {};
    newState[name] = !this.state[name];
    this.setState({ ...this.name, ...newState });
  }

  formatTime(value) {
    if (value) {
      return moment(value);
    }
  }

  handleEditorChange(html) {
    this.setState({
      chapterContent: html
    })
    this.props.onContentChange(html);
    this.props.values.chaptor_content = html;
  }

  onColorClose() {
    let cuurentColorList = this.state.colorList;

    if (!cuurentColorList.includes(this.state.currentColor)) {
      cuurentColorList.unshift(this.state.currentColor);
    }

    this.setState({
      colorOpen: false,
      currentColors: cuurentColorList,
    });
  }

  onImageSortEnd = ({ oldIndex, newIndex }) => {
    // this.setState(({ files }) => ({
    //   files: arrayMove(files, oldIndex, newIndex),
    // }));
  };

  onContentTypeChange(e) {
    this.setState({
      contentType: e.target.value,
    });
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatAction(value) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-success mr-2"
          onClick={() => this.updateAudioFile(value)}
        >
          Edit
        </button>

        <button
          type="button"
          className="btn btn-sm btn-danger"
          onClick={() => {
            return Swal.fire({
              title: "Are you sure to delete?",
              text: "You won't be able to revert this!",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, cancel!",
            }).then((result) => {
              if (result.value) {
                this.deleteAudioFile(value.id);
              }
            });
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  async getAudioFile(chapter_id) {
    if (chapter_id != null) {
      await this.props.getChapterByID(chapter_id);
      const chapter = this.props.bookChapterReducer.chapter;

      await this.setState({
        audios: chapter.audios,
      });
    }
  }

  async clearUpdateAudio() {
    await this.setState({
      audioFile: "",
      audioDuration: "",
      audioFileName: "",
      audioID: "",
      audioFileOrder: "",
      audio_action: "",
      uploading: false,
    });
    document.getElementById("chapter_audio_file").value = "";
  }

  async ReOrderAudio(audios) {
    const token = localStorage.getItem("token");
    const response = await httpClient.put(
      `${server.CHAPTER_AUDIO_URL}/reorder`,
      audios,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 201 || response.status === 200) {
      //await this.getAudioFile(this.props.values.book_chaptor_id);
    }
  }

  async MaintainAudioFile(chapter_id, action) {
    let order_no;
    if (action == "add") {
      if (this.state.audios.length > 0) {
        let max_order_no;
        for (let i = 0; i < this.state.audios.length; i++) {
          max_order_no = this.state.audios[i].order;
        }
        order_no = max_order_no + 1;
      } else order_no = 1;
    }

    if (this.state.uploading) {
      Swal.fire({
        icon: "error",
        text: "อยู่ระหว่าง Upload ไฟล์เสียง กรุณารอสักครู่",
        timer: 3000,
      });

      return;
    }

    if (!this.state.audioFile) {
      Swal.fire({
        icon: "error",
        text: "กรุณาอัพโหลดไฟล์เสียง",
        timer: 3000,
      });

      return;
    }

    let formData = new FormData();

    formData.append("book_chaptor_id", chapter_id);
    formData.append("file_name", this.state.audioFileName);

    if (action == "add") formData.append("order", order_no);
    else formData.append("order", this.state.audioFileOrder);

    formData.append("chapter_audio_file", this.state.audioFile);
    formData.append("audio_duration", this.state.audioDuration);
    formData.append("dbstatus", "A");

    let username = "";
    var result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    if (action == "add") {
      formData.append("created_by", username);
      formData.append("created_datetime", dateTime);
    }
    formData.append("updated_by", username);
    formData.append("updated_datetime", dateTime);

    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    const token = localStorage.getItem("token");

    let response;
    if (action == "add") {
      response = await httpClient.post(`${server.CHAPTER_AUDIO_URL}`, json, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } else if (action == "update") {
      response = await httpClient.patch(
        server.CHAPTER_AUDIO_URL + "/" + `${this.state.audioID}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    if (response.status === 201 || response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "ไฟล์เสียง ถูกบันทึกเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
      await this.getAudioFile(this.props.values.book_chaptor_id);
      await this.setState({
        audioFile: "",
        audioDuration: "",
        audioFileName: "",
        audioID: "",
        audioFileOrder: "",
        audio_action: "",
      });
      document.getElementById("chapter_audio_file").value = "";
      this.props.values.chapter_audio_file = null;
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    }
  }

  updateAudioFile(value) {
    if (value) {
      this.setState({
        audioID: value.id,
        audioFileName: value.file_name,
        audioFileOrder: value.order,
        audioFile: value.chapter_audio_file,
        audioDuration: value.audio_duration,
        audio_action: "update",
      });
    }
  }

  async deleteAudioFile(id) {
    const token = localStorage.getItem("token");
    let response = await httpClient.delete(
      `${server.CHAPTER_AUDIO_URL}/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 201 || response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "ไฟล์เสียง ถูกลบเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });

      await this.getAudioFile(this.props.values.book_chaptor_id);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    }
  }

  //Reorder Table
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  async onDragEnd(result) {
    let i = 0;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const audios = this.reorder(
      this.state.audios,
      result.source.index,
      result.destination.index
    );

    let tempOrder;

    tempOrder = audios.map((item, index) => {
      return {
        ...item,
        order: index + 1,
      };
    });

    await this.setState({
      audios: tempOrder,
    });
    
    await this.ReOrderAudio(this.state.audios);
  }

  DraggableComponent = (id, index) => (props) => {
    return (
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={this.getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            {...props}
          >
            {props.children}
          </TableRow>
        )}
      </Draggable>
    );
  };

  DroppableComponent = (onDragEnd: (result, provided) => void) => (props) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"1"} direction="vertical">
          {(provided) => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };
  //

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            if (!val) {
              val = "";
            }

            onChange(name, val);
          }}
        />
      );
    };

    const { values, handleChange, setFieldValue, errors, touched } = this.props;

    return (
      <React.Fragment>
        <Tabs defaultActiveKey="1" animated={{ tabPane: false }}>
          <TabPane tab="รายละเอียด" key="1">
            <div className="d-lg-flex">
              <div className="chapter-thumbnail-container mb-5">
                <div className="position-relative">
                  {this.state.chapterThumbnail ? (
                    <img
                      src={this.state.chapterThumbnail}
                      className="img-fluid"
                      width="150"
                    />
                  ) : (
                    <div
                      className="image-placeholder d-flex flex-column align-items-center justify-content-center rounded-circle"
                      style={{ width: 150, height: 150 }}
                    >
                      <div>
                        <PictureOutlined
                          style={{ fontSize: 30, color: "#bbb" }}
                        />
                      </div>
                      <div className="font-12">อัพโหลดรูป</div>
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="input-file-hidden"
                    onChange={(event) => {
                      this.onSelectFile(event);
                    }}
                  />
                </div>

                {this.state.chapterThumbnail && (
                  <div className="text-right mt-2">
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => this.removeImage()}
                    >
                      ลบ
                    </Button>
                  </div>
                )}
              </div>
              <div className="flex-fill">
                {this.state.bot_read == "Y" && (
                  <span className="text-danger mb-4">*** ตอนนี้อยู่ในประเภทหนังสือเสียง (Bot Read)</span>
                )}

                <fieldset className="mt-3">

                  <Row form>
                    <Col md="6" className="form-group">
                      <label>ลำดับแสดงผล</label>
                      <span className="text-danger ml-1">*</span>
                      <FormInput
                        name="chaptor"
                        id="chaptor"
                        placeholder=""
                        onChange={handleChange}
                        value={values.chaptor}
                      />
                      {errors.chaptor && touched.chaptor ? (
                        <div className="font-12 text-primary mt-1">
                          {errors.chaptor}
                        </div>
                      ) : null}
                    </Col>
                    <Col md="6" className="form-group">
                      <label>บท/ตอน</label>
                      <span className="text-danger ml-1">*</span>
                      <FormInput
                        name="chaptor_title"
                        id="chaptor_title"
                        placeholder=""
                        onChange={handleChange}
                        value={values.chaptor_title}
                      />
                      {errors.chaptor_title && touched.chaptor_title ? (
                        <div className="font-12 text-primary mt-1">
                          {errors.chaptor_title}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="12" className="form-group">
                      <label>ชื่อตอน</label>
                      <FormInput
                        name="book_chaptor_name"
                        id="book_chaptor_name"
                        placeholder=""
                        onChange={handleChange}
                        value={values.book_chaptor_name}
                      />
                      {errors.book_chaptor_name && touched.book_chaptor_name ? (
                        <div className="font-12 text-primary mt-1">
                          {errors.book_chaptor_name}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  <Row form>
                    <Col md="6" className="form-group">
                      <label>ราคา (เหรียญทอง)</label>
                      <span className="text-danger ml-1">*</span>
                      <NumberFormat
                        customInput={FormInput}
                        thousandSeparator={true}
                        
                        inputMode="numeric"
                        name="chaptor_price"
                        id="chaptor_price"
                        placeholder=""
                        onChange={handleChange}
                        value={values.chaptor_price}
                      />

                      <label className="mt-2 custom-control custom-checkbox">
                        <input
                          className="free_only_member_checked"
                          id="free_only_member_checked"
                          type="checkbox"
                          className="custom-control-input"
                          checked={values.free_only_member_checked}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="free_only_member_checked"
                          className="custom-control-label"
                          aria-hidden="true"
                        ></label>
                        <span className="custom-control-description">
                          อ่านฟรีเฉพาะสมาชิก
                        </span>
                      </label>

                      {errors.chaptor_price && touched.chaptor_price ? (
                        <div className="font-12 text-primary mt-1">
                          {errors.chaptor_price}
                        </div>
                      ) : null}
                    </Col>

                    <Col md="6" className="form-group">
                      <label>ราคา (เหรียญเงิน)</label>

                      {values.silver_coin_use_checked && (
                        <span className="text-danger ml-1">*</span>
                      )}
                      <NumberFormat
                        customInput={FormInput}
                        thousandSeparator={true}
                        
                        inputMode="numeric"
                        name="chaptor_silver_price"
                        id="chaptor_silver_price"
                        placeholder=""
                        disabled={!values.silver_coin_use_checked}
                        onChange={handleChange}
                        value={values.chaptor_silver_price}
                      />

                      <label className="mt-2 custom-control custom-checkbox">
                        <input
                          className="silver_coin_use_checked"
                          id="silver_coin_use_checked"
                          name="silver_coin_use_checked"
                          type="checkbox"
                          className="custom-control-input"
                          checked={values.silver_coin_use_checked}
                          onChange={handleChange}
                        />
                        <label
                          htmlFor="silver_coin_use_checked"
                          className="custom-control-label"
                          aria-hidden="true"
                        ></label>
                        <span className="custom-control-description">
                          ใช้เหรียญเงินซื้อได้
                        </span>
                      </label>

                      {errors.chaptor_silver_price &&
                      touched.chaptor_silver_price ? (
                        <div className="font-12 text-primary mt-1">
                          {errors.chaptor_silver_price}
                        </div>
                      ) : null}
                    </Col>
                  </Row>

                  {this.state.bot_read == "Y" && (
                    <div> 
                      <Row form>
                        <Col md="6" className="form-group">
                          <label className="text-accent">ราคาแพ็คหนังสืออ่านออนไลน์และหนังสือเสียง(เหรียญทอง)</label>
                          <span className="text-danger ml-1">*</span>
                          <NumberFormat
                            customInput={FormInput}
                            thousandSeparator={true}
                            
                            inputMode="numeric"
                            name="package_gold_price"
                            id="package_gold_price"
                            placeholder=""
                            onChange={handleChange}
                            value={values.package_gold_price}
                          />

                          {errors.chaptor_price && touched.chaptor_price ? (
                            <div className="font-12 text-primary mt-1">
                              {errors.package_gold_price}
                            </div>
                          ) : null}
                        </Col>

                        <Col md="6" className="form-group">
                          <label className="text-accent">ราคาแพ็คหนังสืออ่านออนไลน์และหนังสือเสียง(เหรียญเงิน)</label>

                          {values.silver_coin_use_checked && (
                            <span className="text-danger ml-1">*</span>
                          )}
                          <NumberFormat
                            customInput={FormInput}
                            thousandSeparator={true}
                            
                            inputMode="numeric"
                            name="package_silver_price"
                            id="package_silver_price"
                            placeholder=""
                            disabled={!values.silver_coin_use_checked}
                            onChange={handleChange}
                            value={values.package_silver_price}
                          />

                          {errors.package_silver_price &&
                          touched.package_silver_price ? (
                            <div className="font-12 text-primary mt-1">
                              {errors.package_silver_price}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                  </div>
                  )}

                  {this.state.bot_read == "Y" && (
                    <div>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label className="text-accent">ราคาหนังสือเสียง (เหรียญทอง)</label>
                          <NumberFormat
                            customInput={FormInput}
                            thousandSeparator={true}
                            
                            inputMode="numeric"
                            name="audio_gold_price"
                            id="audio_gold_price"
                            placeholder=""
                            onChange={handleChange}
                            value={values.audio_gold_price}
                          />
                        </Col>

                        <Col md="6" className="form-group">
                          <label className="text-accent">ราคาหนังสือเสียง (เหรียญเงิน)</label>
                          <NumberFormat
                            customInput={FormInput}
                            thousandSeparator={true}
                            
                            inputMode="numeric"
                            name="audio_silver_price"
                            id="audio_silver_price"
                            placeholder=""
                            onChange={handleChange}
                            value={values.audio_silver_price}
                            disabled={!values.silver_coin_use_checked}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}

                  <Row form>
                    <Col md="3" className="form-group">
                      <label>วันที่เผยแพร่</label>
                      <span className="text-danger ml-1">*</span>
                      <InputGroup
                        className={"d-flex my-auto date-range date-w-100"}
                      >
                        <DatePickerField
                          size="sm"
                          name="published_date"
                          onChange={setFieldValue}
                          value={values.published_date}
                          dropdownMode="select"
                        />
                        <InputGroupAddon type="append">
                          <InputGroupText>
                            <i className="material-icons">&#xE916;</i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      {errors.published_date && touched.published_date ? (
                        <div className="font-12 text-primary mt-1">
                          {errors.published_date}
                        </div>
                      ) : null}
                    </Col>

                    <Col md="3">
                      <label>เวลา</label>
                      <TimePicker
                        defaultValue={this.formatTime(
                          this.props.values.published_date
                        )}
                        showSecond={false}
                        format={"HH:mm"}
                        className="time-picker-block"
                        minuteStep={5}
                        onChange={this.handleStartTimeChange}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label>แสดง/ซ่อน</label>
                      <span className="text-danger ml-1">*</span>
                      <FormSelect
                        name="book_chaptor_status"
                        id="book_chaptor_status"
                        placeholder=""
                        onChange={handleChange}
                        value={values.book_chaptor_status}
                      >
                        <option value="A">เผยแพร่</option>
                        <option value="I">ซ่อน</option>
                      </FormSelect>
                    </Col>
                  </Row>

                  {values.book_chaptor_id && (
                    <>
                      {/* <Row form>
                        <Col className="form-group">
                          <label>
                            อัพโหลดไฟล์เสียง{" "}
                            <span className=" text-danger"> </span>
                          </label>
                          {this.state.audioFile ? (
                            <button
                              type="button"
                              onClick={() => {
                                this.removeAudio();
                              }}
                              className="btn btn-sm btn-warning pull-right ml-2"
                            >
                              ลบไฟล์เสียง
                            </button>
                          ) : null}
                          <input
                            id="chapter_audio_file"
                            name="chapter_audio_file"
                            type="file"
                            accept="audio/*"
                            onChange={(event) => {
                              values.chapter_audio_file = null;
                              this.setState({
                                audioFile: null,
                              });
                              this.uploadAudio(event.currentTarget.files[0]);
                            }}
                            className="form-control mt-2"
                          />

                          <div sm="6" className="ml-2 mt-3 text-center">
                            {this.state.audioFile ? (
                              <ReactAudioPlayer
                                src={this.state.audioFile}
                                controls
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row> */}

                      <Row form>
                        <Col md="12" className="form-group">
                          <label>ลิงก์สำหรับอ่าน</label>
                          <FormInput readOnly value={this.state.actualUrl} />
                          {errors.book_chaptor_name &&
                          touched.book_chaptor_name ? (
                            <div className="font-12 text-primary mt-1">
                              {errors.book_chaptor_name}
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </>
                  )}
                </fieldset>
              </div>
            </div>
          </TabPane>
          {values.book_chaptor_id && (
            <TabPane tab="เนื้อหา" key="2">
              <Row form>
                <Col md="4" className="form-group">
                  <label>ประเภทของเนื้อหา</label>
                  <FormSelect
                    onChange={(e) => this.onContentTypeChange(e)}
                    value={this.state.contentType}
                    name="chapter_content_type"
                    id="chapter_content_type"
                    onChange={handleChange}
                    value={values.chapter_content_type}
                  >
                    <option value={1}>ทั่วไป</option>
                    <option value={2}>แชท</option>
                  </FormSelect>
                </Col>
              </Row>

              {this.props.values.book_chaptor_id &&
                (this.props.values.chapter_content_type == "1" ? (
                  <div className={`chapter-content-wrapper ${this.state.darkMode ? 'content-dark-mode' : ''}`}>
                    <Row form className="mt-3">
                      <Col md="12" className="form-group">
                        <div className="d-flex align-items-center">
                          <span className="mr-2">Dark Mode</span>
                          <Switch
                            checked={this.state.darkMode}
                            onChange={(value) => this.setState({ darkMode: value })}
                          />
                          <div className="ml-auto d-flex">
                            <div className="position-relative mr-2">
                              <Button
                                type="button"
                                loading={this.state.uploading}
                                onClick={() => {
                                  this.setState({ previewModalOpen: true });
                                }}
                                className="btn btn-primary"
                              >
                                อัพโหลดวีดีโอ
                              </Button>

                              <input 
                                type="file" 
                                accept="video/mp4" 
                                className="input-file-hidden" 
                                onChange={(event) => {
                                  this.uploadVideo(event.currentTarget.files[0])
                                }}
                              />  
                            </div>
                            <button
                              type="button"
                              onClick={() => {
                                this.setState({ previewModalOpen: true });
                              }}
                              className="btn btn-danger"
                            >
                              ตัวอย่าง
                            </button>
                          </div>
                        </div>
                        
                        { this.state.videoTag && 
                          <div className="card shadow-none border bg-grey mt-3 position-releative">
                            <div className="card-body p-3">
                              <code>
                                { this.state.videoTag }
                              </code>
                            </div>

                            <div className="position-absolute d-flex align-items-center font-16" style={{bottom: 10, right: 10}}>
                              <CopyToClipboard 
                                text={this.state.videoTag}
                                onCopy={() => message.open({ content: 'คัดลอกวีดีโอเรียบร้อยแล้ว'})}>
                                <div className="mr-2 pointer"><CopyOutlined /></div>
                              </CopyToClipboard>
                              <div className="pointer" onClick={() => this.removeVideoTag()}><DeleteOutlined /></div>
                            </div>
                          </div>
                        }
                        
                        <div className="chapter-editor-wrapper mt-3">
                          <CKEditor
                            editor={ClassicEditor}
                            data={this.state.chapterContent}
                            config={{
                              extraPlugins: [customUploadAdapterPlugin],
                              indentBlock: {
                                offset: 1,
                                unit: "em",
                              },
                              image: {
                                resize: true,
                                toolbar: [
                                  "linkImage",
                                  "|",
                                  "imageTextAlternative",
                                  "|",
                                  "imageStyle:full",
                                  "imageStyle:alignLeft",
                                  "imageStyle:alignCenter",
                                  "imageStyle:alignRight",
                                  "removeFormat"
                                ],
                                styles: [
                                  "full",
                                  "alignCenter",
                                  "alignLeft",
                                  "alignRight",
                                ],
                              },
                            }}
                            onInit={(editor) => {}}
                            onChange={(event, editor) => {
                              const data = editor.getData()
                              this.handleEditorChange(data)
                            }}
                            onBlur={(event, editor) => {}}
                            onFocus={(event, editor) => {}}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <ChatChapterForm
                    bookId={values.book_id}
                    chapterId={values.book_chaptor_id}
                    characters={values.characters}
                  />
                ))}
            </TabPane>
          )}

          {values.book_chaptor_id && (
            <TabPane tab="ไฟล์เสียง" key="3">
              <fieldset className="mt-2 ml-3">
                <Row form>
                  <Col md="6" className="form-group">
                    <FormGroup>
                      <label>File Name</label>
                      <FormInput
                        name="audioFileName"
                        id="audioFileName"
                        placeholder="File Name"
                        onChange={(e) =>
                          this.setState({ audioFileName: e.target.value })
                        }
                        value={this.state.audioFileName}
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" className="form-group">
                    <FormGroup>
                      <label>ลำดับที่</label>
                      <FormInput
                        name="audioFileOrder"
                        readOnly
                        id="audioFileOrder"
                        onChange={(e) =>
                          this.setState({ audioFileOrder: e.target.value })
                        }
                        value={this.state.audioFileOrder}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col md="6" className="form-group">
                    <label>
                      อัพโหลดไฟล์เสียง <span className=" text-danger"> </span>
                    </label>
                    {this.state.audioFile ? (
                      <button
                        type="button"
                        onClick={() => {
                          this.removeAudio();
                        }}
                        className="btn btn-sm btn-warning pull-right ml-2"
                      >
                        ลบไฟล์เสียง
                      </button>
                    ) : null}
                    <input
                      id="chapter_audio_file"
                      name="chapter_audio_file"
                      type="file"
                      accept="audio/*"
                      onChange={(event) => {
                        values.chapter_audio_file = null;
                        this.setState({
                          audioFile: null,
                        });
                        this.uploadAudio(event.currentTarget.files[0]);
                      }}
                      className="form-control mt-2"
                    />

                    <div className="ml-2 mt-3 text-center">
                      {this.state.audioFile ? (
                        <ReactAudioPlayer id="audio_player" src={this.state.audioFile}  controls />
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </fieldset>

              <Row>
                <Col>
                  <div className="text-center inline-block">
                    {this.state.audio_action != "update" && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{
                          marginRight: 10,
                          color: "white",
                          width: 100,
                        }}
                        onClick={() => {
                          this.MaintainAudioFile(values.book_chaptor_id, "add");
                        }}
                      >
                        เพิ่ม
                      </button>
                    )}

                    {this.state.audio_action == "update" && (
                      <button
                        type="button"
                        className="btn btn-warning"
                        style={{
                          marginRight: 10,
                          color: "white",
                          width: 100,
                        }}
                        onClick={() => {
                          this.MaintainAudioFile(
                            values.book_chaptor_id,
                            "update"
                          );
                        }}
                      >
                        แก้ไข
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{
                        marginRight: 10,
                        color: "white",
                        width: 100,
                      }}
                      onClick={() => {
                        this.clearUpdateAudio();
                      }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </Col>
              </Row>

              <div className="mt-4">
                {this.state.audios.length > 1 && (
                  <span className="text-danger ml-1">
                    *สามารถเปลี่ยนลำดับที่โดยการเลื่อนขึ้น-ลงข้อมูลในตาราง
                  </span>
                )}

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          ลำดับที่
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          ชื่อไฟล์
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          ไฟล์เสียง
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          แก้ไขโดย
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          วันที่แก้ไข
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          แก้ไข / ลบ
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      component={this.DroppableComponent(this.onDragEnd)}
                    >
                      {this.state.audios.map((audio, index) => (
                        <TableRow
                          component={this.DraggableComponent(
                            audio.created_datetime,
                            index
                          )}
                          key={index}
                        >
                          <StyledTableCell align="center" scope="row">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {audio.file_name ? audio.file_name : null}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {audio.chapter_audio_file ? (
                              <ReactAudioPlayer
                                src={audio.chapter_audio_file}
                                controls
                              />
                            ) : null}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {audio.updated_by}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {this.formatDateTime(audio.updated_datetime)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {this.formatAction(audio)}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </TabPane>
          )}
        </Tabs>

        <Modal
          visible={this.state.previewModalOpen}
          title={null}
          footer={null}
          onCancel={() => this.setState({ previewModalOpen: false })}
          width={1000}
        >
          <div className="text-center">
            <div className="text-muted">
              ตอนที่: {this.props.values.chaptor}
            </div>
            <div className="h5">{this.props.values.chaptor_title}</div>
            <hr style={{ borderStyle: "dashed" }} />
          </div>

          <div className={`chapter-content-wrapper ${this.state.darkMode ? 'content-dark-mode' : ''}`}>
            <div
              className="preview-container ck-content"
              dangerouslySetInnerHTML={{
                __html: this.props.values.chaptor_content,
              }}
            />
          </div>
        </Modal>

        <Modal
          title={null}
          footer={null}
          width={600}
          maskClosable={false}
          visible={this.state.cropModalOpen}
          onCancel={() => this.setState({ cropModalOpen: false })}
        >
          <div className="d-flex">
            <div className="flex-fill thumbnail-crop-area">
              <Cropper
                src={this.state.imageUrl}
                aspectRatio={1200 / 630}
                autoCropArea={1}
                preview=".thumbnail-preview"
                viewMode={3}
                ref={this.cropperRef}
              />
            </div>

            <div className="mt-4 thumbnail-preview-area ml-4">
              <div className="font-weight-bold text-center mb-2">
                ตัวอย่างรูป
              </div>
              <div className="thumbnail-preview" />
            </div>
          </div>

          <div className="mt-5 text-center">
            <button
              type="button"
              onClick={() => {
                this.cropImage();
              }}
              className="btn btn-primary mr-2"
            >
              ตกลง
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                this.setState({ cropModalOpen: false });
              }}
            >
              ยกเลิก
            </button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ bookChapterReducer }) => ({ bookChapterReducer });

const mapDispatchToProps = {
  getChapterByID,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterForm);

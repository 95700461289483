import React, { Component } from "react";
import {
  Row,
  Col,
  FormInput,
  FormTextarea,
  FormSelect
} from "shards-react";
import NumberFormat from "react-number-format";

export default class PackageFrom extends Component {
  render() {
    // const DatePickerField = ({ name, value, onChange, placeholder }) => {
    //   return (
    //     <DatePicker
    //       selected={(value && new Date(value)) || null}
    //       placeholderText={placeholder}
    //       dateFormat="dd/MM/yyyy"
    //       onChange={(val) => {
    //         onChange(name, val);
    //       }}
    //     />
    //   );
    // };

    const { values, handleChange, isDisableField, errors, touched } = this.props;

    return (
      <React.Fragment>
        <fieldset disabled={isDisableField}>
          <Row form>
            <Col md="12" className="form-group">
              <label>ชื่อแพ็กเกจ</label>
              <FormInput
                name="packageName"
                id="packageName"
                onChange={handleChange}
                value={values.packageName}
              />
              {errors.packageName && touched.packageName ? (
                <div className="font-12 text-primary mt-1">{errors.packageName}</div>
              ) : null}
            </Col>
          </Row>
          <Row form>
            <Col md="12" className="form-group">
              <label>รายละเอียดแพ็กเกจ</label>
              <FormTextarea
                rows="3"
                name="packageDesc"
                id="packageDesc"
                onChange={handleChange}
                value={values.packageDesc}
              />
              {errors.packageDesc && touched.packageDesc ? (
                <div className="font-12 text-primary mt-1">{errors.packageDesc}</div>
              ) : null}
            </Col>
          </Row>
          <Row form>
            <Col md="4" className="form-group">
              <label>จำนวนเหรียญ</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="packageCoin"
                id="packageCoin"
                placeholder=""
                onChange={handleChange}
                value={values.packageCoin}
              />

              {errors.packageCoin && touched.packageCoin ? (
                <div className="font-12 text-primary mt-1">{errors.packageCoin}</div>
              ) : null}
            </Col>
            <Col md="4" className="form-group">
              <label>โบนัส</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="coinBonus"
                id="coinBonus"
                placeholder=""
                onChange={handleChange}
                value={values.coinBonus}
              />

              {errors.coinBonus && touched.coinBonus ? (
                <div className="font-12 text-primary mt-1">{errors.coinBonus}</div>
              ) : null}
            </Col>
            <Col md="4" className="form-group">
              <label>ราคา</label>
              <NumberFormat
                customInput={FormInput}
                thousandSeparator={true}
                inputMode="numeric"
                name="packagePrice"
                id="packagePrice"
                placeholder=""
                onChange={handleChange}
                value={values.packagePrice}
              />

              {errors.packagePrice && touched.packagePrice ? (
                <div className="font-12 text-primary mt-1">{errors.packagePrice}</div>
              ) : null}
            </Col>
          </Row>

          <Row form>
            <Col md="4" className="form-group">
              <label>ช่องทางการเติมเงิน <span className="text-muted" style={{fontSize: 12}}>(Channel)</span></label>
              <FormSelect
                name="channel"
                id="channel"  
                onChange={handleChange}
                value={values.channel}
              >
                <option value="omise">Credit/Debit Card</option>
                <option value="promptpay">Promptpay</option>
                <option value="transfer">Transfer</option>
                <option value="inapp">In-App Purchase</option>
              </FormSelect>
            </Col>
            <Col md="4" className="form-group">
              <label>สถานะ</label>
              <FormSelect
                name="packageStatus"
                id="packageStatus"  
                onChange={handleChange}
                value={values.packageStatus}
              >
                <option value="A">เปิดใช้งาน</option>
                <option value="D">ปิดการใช้งาน</option>
              </FormSelect>
            </Col>
            <Col md="4" className="form-group">
              <label>ประเภทของเหรียญ</label>
              <FormSelect
                name="packageCoinType"
                id="packageCoinType"  
                onChange={handleChange}
                value={values.packageCoinType}
              >
                <option value="gold">Gold</option>
                <option value="silver">Silver</option>
              </FormSelect>
            </Col>
          </Row>
          { values.channel === 'inapp' ?
            <Row form>
              <Col md="12" className="form-group">
                <label>App store/Play store Package Id</label>
                <FormInput
                  name="inAppPackageId"
                  id="inAppPackageId"
                  onChange={handleChange}
                  value={values.inAppPackageId}
                />
                {errors.inAppPackageId && touched.inAppPackageId ? (
                  <div className="font-12 text-primary mt-1">{errors.inAppPackageId}</div>
                ) : null}
              </Col>
            </Row> : null
          }
          <Row form>
            <Col md="12" className="form-group">
              <label>สิทธิพิเศษ</label>
              <FormTextarea
                rows="3"
                name="promotionPrivileges"
                id="promotionPrivileges"
                onChange={handleChange}
                value={values.promotionPrivileges}
              />
            </Col>
          </Row>
        </fieldset>
      </React.Fragment>
    );
  }
}

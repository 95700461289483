import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_MEMBERS_SUCCESS,
  HTTP_MEMBERS_FETCHING,
  HTTP_MEMBERS_FAILED,
  HTTP_MEMBER_SUCCESS,
  HTTP_MEMBER_FETCHING,
  HTTP_MEMBER_FAILED,
  HTTP_WRITERS_SUCCESS,
  HTTP_WRITERS_FETCHING,
  HTTP_WRITERS_FAILED,
  HTTP_WITHDRAW_TRANSACTIONS_SUCCESS,
  HTTP_WITHDRAW_TRANSACTIONS_FETCHING,
  HTTP_WITHDRAW_TRANSACTIONS_FAILED
} from "../constants/member";

import { server } from "../constants";

import {
  HTTP_TRANS_HISTORY_SUCCESS,
  HTTP_TRANS_HISTORY_FETCHING,
  HTTP_TRANS_HISTORY_FAILED,
} from "../constants/report";

import moment from "moment";

const setStateMembersToSuccess = (payload) => ({
  type: HTTP_MEMBERS_SUCCESS,
  payload: payload,
});

const setStateMembersToFetching = () => ({
  type: HTTP_MEMBERS_FETCHING,
});

const setStateMembersToFailed = () => ({
  type: HTTP_MEMBERS_FAILED,
});

const setStateMemberToSuccess = (payload) => ({
  type: HTTP_MEMBER_SUCCESS,
  payload: payload,
});

const setStateMemberToFetching = () => ({
  type: HTTP_MEMBER_FETCHING,
});

const setStateMemberToFailed = () => ({
  type: HTTP_MEMBER_FAILED,
});

const setStateMemberTransToSuccess = (payload) => ({
  type: HTTP_TRANS_HISTORY_SUCCESS,
  payload: payload,
});

const setStateMemberTransToFetching = () => ({
  type: HTTP_TRANS_HISTORY_FETCHING,
});

const setStateMemberTransToFailed = () => ({
  type: HTTP_TRANS_HISTORY_FAILED,
});

const setStateWritersToSuccess = (payload) => ({
  type: HTTP_WRITERS_SUCCESS,
  payload: payload,
});

const setStateWritersToFailed = () => ({
  type: HTTP_WRITERS_FAILED,
});

const setStateWithdrawTransactionToSuccess = (payload) => ({
  type: HTTP_WITHDRAW_TRANSACTIONS_SUCCESS,
  payload: payload,
});

const setStateWithdrawTransactionToFetching = () => ({
  type: HTTP_WITHDRAW_TRANSACTIONS_FETCHING,
});

const setStateWithdrawTransactionToFailed = () => ({
  type: HTTP_WITHDRAW_TRANSACTIONS_FAILED,
});

export const getAllMember = (member_type, name, email,start_date,end_date, page) => {
  return async (dispatch) => {
    dispatch(setStateMembersToFetching());
    await doGetMember(dispatch, member_type, name, email,start_date,end_date, page);
  };
};

const doGetMember = async (dispatch, member_type, name, email,start_date,end_date, page) => {
  let url = "";

  if (member_type === 1) url = `${server.MEMBER_URL}`;
  else if (member_type === 2) url = `${server.WRITER_URL}`;
  else if (member_type === 3) url = `${server.EDITOR_URL}`;
  else if (member_type === 4) url = `${server.PUBLISHER_URL}`;

  try {
    const token = localStorage.getItem("token");
    const result = await httpClient.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        name: name,
        email: email,
        page: page,
        start_date,
        end_date
      },
    });

    dispatch(setStateMembersToSuccess(result.data));
  } catch (err) {
    dispatch(setStateMembersToFailed());
  }
};

export const getWriterName = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`${server.WRITER_NAME_URL}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setStateWritersToSuccess(result.data));
    } catch (err) {
      dispatch(setStateWritersToFailed());
    }
  };
};

export const getMemberById = (member_id) => {
  return async (dispatch) => {
    dispatch(setStateMemberToFetching());

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `${server.MEMBER_URL}/id/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const silverCoinResult = await httpClient.get(
        `admin/silver_coin/${member_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      const financeResult = await httpClient.get(
        `admin/member/${member_id}/finance`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      let memberResult = result.data;
      memberResult.member_silver_coin =
        parseInt(memberResult.member_silver_coin || 0) +
        parseInt(silverCoinResult.data.coin_remains || 0);
      memberResult.financeDetail = financeResult.data.financeDetail;
      memberResult.financeAttachments = financeResult.data.attachments;

      dispatch(setStateMemberToSuccess(memberResult));
    } catch (err) {
      dispatch(setStateMemberToFailed());
    }
  };
};

export const deleteMember = (member_id, member_type, page) => {
  return async (dispatch) => {
    dispatch(setStateMemberToFetching());
    const token = localStorage.getItem("token")
    await httpClient.delete(
      `${server.MEMBER_URL}/${member_id}`,    {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    alert("Delete Complete");
    await doGetMember(dispatch, member_type, page);
  };
};

export const resetGoldWallet = (member_id, member_type, updated_by) => {
  return async (dispatch) => {
    dispatch(setStateMemberToFetching());

    let formData = new FormData();
    formData.append("updated_by", updated_by);
    var dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    formData.append("updated_datetime", dateTime);

    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    const token = localStorage.getItem("token")
    await httpClient.patch(
      `${server.MEMBER_URL}/reset_wallet/${member_id}`,
      json,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    await doGetMember(dispatch, member_type);
  };
};

export const resetSilverWallet = (member_id, member_type, updated_by) => {
  return async (dispatch) => {
    dispatch(setStateMemberToFetching());

    let formData = new FormData();
    formData.append("updated_by", updated_by);
    var dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    formData.append("updated_datetime", dateTime);

    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    const token = localStorage.getItem("token")
    await httpClient.patch(
      `${server.MEMBER_URL}/reset_silver_wallet/${member_id}`,
      json,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    await doGetMember(dispatch, member_type);
  };
};

export const getMemberTransactionById = (member_id, value) => {
  let dateFilter = "";
  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dateFilter += "?end_date=" + value.end + " 23:59.00";
      else dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }

  return (dispatch) => {
    dispatch(setStateMemberTransToFetching());
    const token = localStorage.getItem("token");

    httpClient
      .get(
        `${server.REPORT_URL}/member_trans_list/id/${member_id}` + dateFilter,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        dispatch(setStateMemberTransToSuccess(result.data));
      })
      .catch((err) => {
        dispatch(setStateMemberTransToFailed());
      });
  };
};

export const getWithdrawTransactions = (filter) => {
  return async (dispatch) => {
    dispatch(setStateWithdrawTransactionToFetching());

    try {
      const token = localStorage.getItem("token")
      const result = await httpClient.get("admin/member/withdraw", {
        params: {
          name: filter.name,
          email: filter.email,
          status: filter.status,
          page: filter.page,
        },
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      dispatch(setStateWithdrawTransactionToSuccess(result.data));
    } catch (err) {
      dispatch(setStateWithdrawTransactionToFailed());
    }
  };
};

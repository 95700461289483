import {
    HTTP_SILVERTRANSUM_SUCCESS,
    HTTP_SILVERTRANSUM_FETCHING,
    HTTP_SILVERTRANSUM_FAILED,
  } from "../constants/report";
  
  const initialState = {
    resultSummary: null,
    isFetchingSummary: false,
    isErrorSummary: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_SILVERTRANSUM_SUCCESS:        
        return { ...state, resultSummary: payload, isFetchingSummary: false, isErrorSummary: false };
      case HTTP_SILVERTRANSUM_FAILED:
        return { ...state, resultSummary: null, isFetchingSummary: false, isErrorSummary: true };
      case HTTP_SILVERTRANSUM_FETCHING:
        return { ...state, resultSummary: null, isFetchingSummary: true, isErrorSummary: false };
      default:
        return state;
    }
  };
  
import {
    HTTP_REPORT_EDITOR_SUCCESS,
    HTTP_REPORT_EDITOR_FETCHING,
    HTTP_REPORT_EDITOR_FAILED,
  } from "../constants/report";
  
  const initialState = {
    editors: null,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_REPORT_EDITOR_SUCCESS:
        return {
          ...state,
          editors: payload.editors,
          total: payload.total,
          isFetching: false,
          isError: false,
        };
      case HTTP_REPORT_EDITOR_FAILED:
        return { ...state, editors: null, isFetching: false, isError: true };
      case HTTP_REPORT_EDITOR_FETCHING:
        return { ...state, editors: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
  
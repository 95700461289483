// User Page
export const HTTP_USERS_FETCHING = "HTTP_USERS_FETCHING";
export const HTTP_USERS_SUCCESS = "HTTP_USERS_SUCCESS";
export const HTTP_USERS_FAILED = "HTTP_USERS_FAILED";

export const HTTP_USER_FETCHING = "HTTP_USER_FETCHING";
export const HTTP_USER_SUCCESS = "HTTP_USER_SUCCESS";
export const HTTP_USER_FAILED = "HTTP_USER_FAILED";

export const HTTP_USER_ROLES_FETCHING = "HTTP_USER_ROLES_FETCHING";
export const HTTP_USER_ROLES_SUCCESS = "HTTP_USER_ROLES_SUCCESS";
export const HTTP_USER_ROLES_FAILED = "HTTP_USER_ROLES_FAILED";

export const HTTP_USER_ROLE_FETCHING = "HTTP_USER_ROLE_FETCHING";
export const HTTP_USER_ROLE_SUCCESS = "HTTP_USER_ROLE_SUCCESS";
export const HTTP_USER_ROLE_FAILED = "HTTP_USER_ROLE_FAILED";

import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_REPORT_WRITER_SUCCESS,
  HTTP_REPORT_WRITER_FETCHING,
  HTTP_REPORT_WRITER_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

/* REPORT WRITER ACTION */
const setStateReportWriterToSuccess = (payload) => ({
  type: HTTP_REPORT_WRITER_SUCCESS,
  payload: payload,
});

const setStateReportWriterToFetching = () => ({
  type: HTTP_REPORT_WRITER_FETCHING,
});

const setStateReportWriterToFailed = () => ({
  type: HTTP_REPORT_WRITER_FAILED,
});

/* Get Report Writer */
export const getReportWriter = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportWriterToFetching());
    await doGetReportWriter(dispatch, value);
  };
};

const doGetReportWriter = (dispatch, value) => {
  let dataFilter = "";

  if (value) {
    if (value.start) {
      dataFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dataFilter += "?end_date=" + value.end + " 23:59.00";
      else dataFilter += "&end_date=" + value.end + " 23:59.00";
    }

    if (value.bookTitle) {
      if (dataFilter.length > 0) dataFilter += "&book_title=" + value.bookTitle;
      else dataFilter += "?book_title=" + value.bookTitle;
    }

    if (value.writerName) {
      if (dataFilter.length > 0)
        dataFilter += "&writer_name=" + value.writerName;
      else dataFilter += "?writer_name=" + value.writerName;
    }
  } else
    dataFilter =
      "?end_date=" + moment(new Date()).format("YYYY-MM-DD 23:59.00");

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/writer` + dataFilter, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateReportWriterToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateReportWriterToFailed());
    });
};

import {
    HTTP_REPORT_ORDER_SUCCESS,
    HTTP_REPORT_ORDER_FETCHING,
    HTTP_REPORT_ORDER_FAILED,
  } from "../constants/report";
  
  const initialState = {
    orders: null,
    total: 0,
    isFetching: false,
    isError: false
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_REPORT_ORDER_SUCCESS:
        return {
          ...state,
          orders: payload.orders,
          total: payload.total,
          isFetching: false,
          isError: false
        };
      case HTTP_REPORT_ORDER_FAILED:
        return { ...state, orders: null, isFetching: false, isError: true };
      case HTTP_REPORT_ORDER_FETCHING:
        return { ...state, orders: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
  
import { httpClient } from "../utils/HttpClient";
import { server } from "../constants";

import {
    HTTP_STICKER_ITEM_FETCHING,
    HTTP_STICKER_ITEM_SUCCESS,
    HTTP_STICKER_ITEM_FAILED,
  } from "../constants/sticker";

const setStateStickerItemToSuccess = (payload) => ({
  type: HTTP_STICKER_ITEM_SUCCESS,
  payload: payload,
});

const setStateStickerItemToFetching = () => ({
  type: HTTP_STICKER_ITEM_FETCHING,
});

const setStateStickerItemToFailed = () => ({
  type: HTTP_STICKER_ITEM_FAILED,
});

/* Get Sticker Item */
export const getStickerItem = (value) => {
  return async (dispatch) => {
    dispatch(setStateStickerItemToFetching());
    await doGetStickerItem(dispatch, value);
  };
};

const doGetStickerItem = (dispatch, value) => {
  let dataFilter = "";

  if (value) {
    if (value.setID) dataFilter = "?set_id=" + value.setID;
  }
  
  const token = localStorage.getItem("token");
  httpClient
    .get(`${server.STICKER_ITEM_URL}` + dataFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateStickerItemToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateStickerItemToFailed());
    });
};

export const deleteStickerItem = (sticker_id) => {
  return async (dispatch) => {
    dispatch(setStateStickerItemToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      `${server.STICKER_ITEM_URL}/${sticker_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result) {
      alert("Delete Complete");
      await doGetStickerItem(dispatch);
    }
  };
};


import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_WRITER_RECOMMEND_SUCCESS,
  HTTP_WRITER_RECOMMEND_FETCHING,
  HTTP_WRITER_RECOMMEND_FAILED,
} from "../constants/writer_recommend";

import {
  server
} from "../constants";


const setStateWriterRecommendToSuccess = (payload) => ({
  type: HTTP_WRITER_RECOMMEND_SUCCESS,
  payload: payload,
});

const setStateWriterRecommendToFetching = () => ({
  type: HTTP_WRITER_RECOMMEND_FETCHING,
});

const setStateWriterRecommendToFailed = () => ({
  type: HTTP_WRITER_RECOMMEND_FAILED,
});


export const getAllWriterRecommend = (value) => {
  return async (dispatch) => {
    dispatch(setStateWriterRecommendToFetching());
    await doGetWriterRecommend(dispatch, value);
  };
};

const doGetWriterRecommend = async (dispatch,value) => {
  try {
    const token = localStorage.getItem("token");
    const result = await httpClient.get(`${server.WRITER_RECOMMEND_URL}`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: value.page
      }
    })
    
    dispatch(setStateWriterRecommendToSuccess(result.data));
  } catch(err)  {
    dispatch(setStateWriterRecommendToFailed());
  }
};

export const deleteWriterRecommend = (page,recommend_id) => {
  return async (dispatch) => {
    dispatch(setStateWriterRecommendToFetching());
    const token = localStorage.getItem("token");
    await httpClient.delete(
      `${server.WRITER_RECOMMEND_URL}/${recommend_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    alert('Delete Complete')
    await doGetWriterRecommend(dispatch,page);
  };
};


import {
    HTTP_CHAPTERS_SUCCESS,
    HTTP_CHAPTERS_FETCHING,
    HTTP_CHAPTERS_FAILED,
    HTTP_CHAPTER_SUCCESS,
    HTTP_CHAPTER_FETCHING,
    HTTP_CHAPTER_FAILED,
    HTTP_CHAPTER_MAIN_CHARACTER
  } from "../constants/book_chapter";
  
  const initialState = {
    chapters: null,
    chapter: null,
    mainCharacter: null,
    total: 0,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_CHAPTERS_SUCCESS:        
        return { 
          ...state, 
          chapters: payload.chapters, 
          total: payload.total,
          isFetching: false, 
          isError: false 
      };
      case HTTP_CHAPTERS_FAILED:
        return { ...state, chapters: null, isFetching: false, isError: true };
      case HTTP_CHAPTERS_FETCHING:
        return { ...state, chapters: null, isFetching: true, isError: false };
      case HTTP_CHAPTER_SUCCESS:        
        return { ...state, chapter: payload, isFetching: false, isError: false };
      case HTTP_CHAPTER_FAILED:
        return { ...state, chapter: null, isFetching: false, isError: true };
      case HTTP_CHAPTER_FETCHING:
        return { ...state, chapter: null, isFetching: true, isError: false };
      case HTTP_CHAPTER_MAIN_CHARACTER: {
        return { ...state, mainCharacter: payload };
      }
      default:
        return state;
    }
  };
  
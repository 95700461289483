import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Menu, Dropdown } from 'antd';
import { httpClient } from "../../../utils/HttpClient";
import { 
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
  PhoneOutlined,
  ToTopOutlined,
  SwapOutlined
} from '@ant-design/icons';
import { editBookMessage } from "../../../actions/book_message.action";

class BookMessageItem extends Component {
  constructor() {
    super();
    this.state = { 
      youtubeThumbnail: '',
      youtubeTitle: '',
      youtubeDescription: '',
    }
  }

  componentDidMount() {
    if(this.props.item.type === 8) {
      this.getYoutubeData()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.item.message !== this.props.item.message)  {
      if(this.props.item.type === 8) {
        this.getYoutubeData()
      }
    }
  }

  insertMessage() {
    this.props.onInsertMessage(this.props.item, {
      youtubeThumbnail: this.state.youtubeThumbnail,
      youtubeTitle: this.state.youtubeTitle,
      youtubeDescription: this.state.youtubeDescription,
    })
  }

  editMessage() {
    if(this.props.preview)  {
      if(this.props.item.type === 7 || this.props.item.type === 8)  {
        window.open(this.props.item.message)
      }
    } else {
      this.props.onEditMessage(this.props.item)
    }
  }

  async changePosition() {
    await this.props.editBookMessage({
      ...this.props.item,
      message_position: this.props.item.message_position === 'left' ? 'right' : 'left'
    })
  }

  deleteMessage() {
    Swal.fire({
      text: "ยืนยันการลบข้อมูล",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.props.onDeleteMessage(this.props.item.id)
      }
    });
  }

  getYoutubeData() {
    const item = this.props.item
    const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
    const data = item.message.match(regex);
    let youtubeId

    if(data)  {
      youtubeId = data[1]
    }

    httpClient
      .get(`https://www.googleapis.com/youtube/v3/videos?part=id%2Csnippet&id=${youtubeId}&key=AIzaSyAl-9nzqPhrfbtrKuYKXiygBMBaZlJwzFY`)
      .then((result) => {
        const video = result.data.items[0]
        
        this.setState({
          youtubeThumbnail: video.snippet.thumbnails.high.url,
          youtubeTitle: video.snippet.title,
          youtubeDescription: video.snippet.description,
        })
      })
      .catch((error) => {
      });
  }

  viewCharacter() {
    if(this.props.preview) {
      this.props.onViewCharacter(this.props.item.character)
    }
  }

  render() {
    const { item, activeItem } = this.props
    const { mainCharacter } = this.props.bookChapterReducer

    const editClass = activeItem === item.id ? 'item-editing' : ''
    const mainClass = item.message_position === 'right' ? 'item-reverse' : ''
    const centerClass = item.type === 3 ? 'justify-content-center' : ''
    const systemTextClass = item.character_id === 0 ? 'system-message' : ''
    
    const menu = (  
      <Menu>
        <Menu.Item onClick={() => this.changePosition()}>
          <div className="d-flex align-items-center font-12">
            <SwapOutlined className="mr-2" style={{marginTop: 2}} />
            สลับตำแหน่ง
          </div>
        </Menu.Item>
        <Menu.Item onClick={() => this.editMessage()}>
          <div className="d-flex align-items-center font-12">
            <EditOutlined className="mr-2" style={{marginTop: 2}} />
            แก้ไขข้อความ
          </div>
        </Menu.Item>
        <Menu.Item onClick={() => this.insertMessage()}>
          <div className="d-flex align-items-center font-12">
            <ToTopOutlined className="mr-2" style={{marginTop: 2}}/>
            แทรกข้อความด้านบน
          </div>
        </Menu.Item>
        <Menu.Item onClick={() => this.deleteMessage()}>
          <div className="d-flex align-items-center font-12">
            <DeleteOutlined className="mr-2" style={{marginTop: 2}} />
            ลบ
          </div>
        </Menu.Item>
      </Menu>
    )

    return <>
      <div 
        className={`chat-message-item ${editClass} ${mainClass} ${centerClass} ${systemTextClass}`}
        key={item.id}
      >
        { item.type !== 3 && (
          <div className="message-avatar" onClick={() => this.viewCharacter()}>
            <img src={item.character.image} alt="" width="50" className="rounded-circle" />
          </div>
        )}
        
        <div>
          { item.character_id !== 0 && <div className="font-12 message-name text-muted mb-1">{ item.character.name }</div> }
          <div className="d-flex align-items-center">
            { (item.type === 1 || item.type === 7) && (
              <div className="message-bubble message-content pointer" onClick={() => this.editMessage()}>
                { item.type === 1 ?
                  item.message : <span className="text-underline">{ item.message }</span>
                }
              </div>
            )}
            { item.type === 2 && (
              <div className={(item.character_id === mainCharacter ? 'text-right' : '')}>
                <img 
                  src={item.message} 
                  className="img-fluid pointer message-content" 
                  width="150" 
                  alt=""
                  onLoad={this.props.onHeightChange}
                  onClick={() => this.editMessage()} 
                />
              </div>
            )}
            { (item.type === 4 || item.type === 5 || item.type === 6) && (
              <div 
                className="message-phone message-content bg-grey px-5 py-4 d-flex flex-column align-items-center justify-content-center rounded"
                onClick={() => this.editMessage()} 
              >
                { item.type === 4 &&
                  <>
                    <div className="message-phone-icon">
                      <PhoneOutlined style={{fontSize: 24, marginLeft: 4}} />
                    </div>
                    <div className="mt-1">{item.message}</div>
                  </>
                }
                { item.type === 5 &&
                  <>
                    <div className="message-phone-icon phone-cancel">
                      <PhoneOutlined style={{fontSize: 24, marginLeft: 4}} />
                    </div>
                    <div className="mt-1 font-12">ไม่ได้รับสาย</div>
                  </>
                }
                { item.type === 6 &&
                  <>
                    <div className="message-phone-icon phone-miss">
                      <PhoneOutlined style={{fontSize: 24}} />
                    </div>
                    <div className="mt-1 font-12">ไม่มีการตอบรับ</div>
                  </>
                }
              </div>
            )}

            { item.type === 8 && (
              <>
                <div className="message-video message-content" onClick={() => this.editMessage()} >
                  { this.state.youtubeThumbnail ?
                    <React.Fragment>
                      <img src={this.state.youtubeThumbnail} alt="" className="img-fluid" />
                      <div className="bg-grey p-2">
                        <div className="font-12 font-weight-bold text-truncate">{this.state.youtubeTitle}</div>
                        <div className="mt-1 message-video-desc">{this.state.youtubeDescription}</div>
                      </div>
                    </React.Fragment>
                    : <div className="bg-grey d-flex align-items-center justify-content-center" style={{ height:100, width:170 }}>ไม่พบวีดีโอ</div>
                  }
                </div>
              </>
            )}

            { this.props.editable && (
              <div className="message-options">
                <Dropdown 
                  overlay={menu} 
                  trigger={['click']} 
                  placement={mainClass ? 'bottomRight' : 'bottomLeft'}
                >
                  <MoreOutlined style={{ fontSize: 20, color: '#bbb' }} />
                </Dropdown>
              </div>
            )}
          </div>
        </div>


        { item.type === 3 && (
          <div className="system-message-badge rounded-pill pointer align-self-center" onClick={() => this.editMessage()}>
            { item.message }
          </div>
        )}
      </div>
    </>
  }
}

const mapStateToProps = ({ bookChapterReducer }) => ({
  bookChapterReducer
});

const mapDispatchToProps = {
  editBookMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(BookMessageItem);

import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../utils/HttpClient";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import MemberFrom from "../../components/Member/memberForm";
import moment from "moment";
import * as Yup from "yup";

const memberValidationSchema = Yup.object().shape({
  member_name: Yup.string().required("กรุณากรอก Name"),
});

class Botnoi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page:""
    };
    
    this.getMemberInfo = this.getMemberInfo.bind(this);
  }

  componentDidMount() {
    this.getMemberInfo()

    const { page } = this.props.location
    this.setState({ page});
  }

  getMemberInfo() {
    let botnoi_member_id = '109828';
    this.props.getMemberById(botnoi_member_id);
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 20 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">UPDATE BOTNOI</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <MemberFrom
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isDisableField={false}
                      isDiableUsername={false}
                      laberImg={"BotNoi Image"}
                      errors={errors}
                      touched={touched}
                      getMemberInfo={this.getMemberInfo}
                    ></MemberFrom>

                    <div className="mt-5 text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary"
                        style={{ marginRight: 10, color: "white", width: 100 }}
                      >
                        บันทึก
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    const { result } = this.props.memberReducer;
    if (result) {
      result["member_password"] = "";
      result["gold_coin_withdraw"] = result["member_wallet"];
      result["silver_coin_withdraw"] = result["member_silver_wallet"];
    }
    return (
      <React.Fragment>
        { result && 
          <Formik
            enableReinitialize
            initialValues={result}
            validationSchema={memberValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              //Call API For Upload Image
              let img_url = "";
              if (values.file) {
                var formDataImg = new FormData();
                var imagefile = values.file;

                formDataImg.append("upload", imagefile);

                let response_img = await httpClient.post(
                  server.IMG_URL,
                  formDataImg,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "Content-Type": false,
                      processData: false,
                      enctype: "multipart/form-data",
                    },
                  }
                );

                if (response_img.status === 201 || response_img.status === 200) {
                  if (response_img.data.uploaded === 1) {
                    img_url = response_img.data.url;
                  }
                }
              }

              let goldCoin = 0;

              if (values.member_coin) {
                goldCoin = Number(
                  values.member_coin.toString().replace(/,/g, "")
                );
              }

              let formData = new FormData();
              
              let username = "";
              var result = localStorage.getItem("user_profile");
              if (result) username = JSON.parse(result).user_username;

              let dateTime = new Date();
              dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
              formData.append("updated_datetime", dateTime);
            
              formData.append("member_id", values.member_id);
              if (values.member_username)
                formData.append("member_username", values.member_username);

              if (values.member_password) {
                formData.append("member_password", values.member_password);
              }
              if (values.member_name)
                formData.append("member_name", values.member_name);
              if (values.member_phone)
                formData.append("member_phone", values.member_phone);
              if (values.member_email)
                formData.append("member_email", values.member_email);
              if (values.member_history)
                formData.append("member_history", values.member_history);
              if (img_url) formData.append("member_image", img_url);
              formData.append("member_coin", goldCoin);
              formData.append("gold_coin_earning_percentage", values.gold_coin_earning_percentage);
              formData.append("silver_coin_earning_percentage", values.silver_coin_earning_percentage);
              formData.append("updated_by", username);
              var object = {};
              formData.forEach((value, key) => {
                object[key] = value;
              });
              var json = JSON.stringify(object);
              const token = localStorage.getItem("token")

              let response = await httpClient.patch(
                server.MEMBER_URL + "/" + `${values.member_id}`,
                json,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                  },
                }
              );

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  text: "Update Complete",
                  timer: 3000
                });
                this.getMemberInfo()
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Update Fail",
                  timer: 3000
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ memberReducer }) => ({ memberReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Botnoi);

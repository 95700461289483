import React, { Component } from "react";
import { getReportBuyWholeBook } from "../../actions/reportBuyWholeBook.action";
import { getCategory } from "../../actions/category.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
  FormGroup,
  FormSelect,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";

class ReportBuyWholeBook extends Component {
  async componentDidMount() {
    localStorage.removeItem("topup_current_page");
    localStorage.removeItem("purchase_current_page");
    this.props.getReportBuyWholeBook();
    this.props.getCategory();
  }

  formatSumPrice(cell, row) {
    if (row.bot_read === "N") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.gold_price + row.silver_price);
    } else if (row.bot_read === "Y") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.audio_gold_price + row.audio_silver_price);
    }
  }

  formatGoldPrice(cell, row) {
    if (row.bot_read === "N") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.gold_price);
    } else if (row.bot_read === "Y") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.audio_gold_price);
    }
  }

  formatSilverPrice(cell, row) {
    if (row.bot_read === "N") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.silver_price);
    } else if (row.bot_read === "Y") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.audio_silver_price);
    }
  }


  formatCountChapter(cell, row) {
    if (row.bot_read === "N") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.count_chapter_book);
    } else if (row.bot_read === "Y") {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(row.count_chapter_audio);
    }
  }

  formatCountMember(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.count_member);
  }

  formatCountMemberAll(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.count_member_all);
  }

  formatTotalView(cell, row) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(row.total_view);
  }

  bindingCategoryName = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.categoryReducer;
      const options = result.map((item) => (
        <option key={item.value} value={item.category_name}>
          {item.category_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="category_name"
            id="category_name"
            placeholder=""
            onChange={handleChange}
            value={values.category_name}
          >
            <option value="">เลือกหมวดหมู่</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  bindingBookType = ({ handleChange, values }) => {
    return (
      <FormSelect
        name="bot_read"
        id="bot_read"
        placeholder=""
        onChange={handleChange}
        value={values.bot_read}
      >
        <option value="">ทั้งหมด</option>
        <option value="N">หนังสือ</option>
        <option value="Y">หนังสือเสียง</option>
      </FormSelect>
    );
  };

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportBuyWholeBookReducer;
      
      const csv_name = "BuyWholeBookReport.csv";

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="book_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Book ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="book_title"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
                csvHeader="ชื่อหนังสือ"
              >
                ชื่อหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="count_member"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
     
                csvFormat={this.formatCountMember}
                csvHeader="จำนวนครั้งที่ซื้อทั้งเล่ม ลด10%"
                dataSort={ true }
              >
                จำนวนครั้งที่ซื้อทั้งเล่ม ลด10%
              </TableHeaderColumn>


              <TableHeaderColumn
                dataField="count_member_all"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCountMemberAll}
                csvFormat={this.formatCountMemberAll}
                csvHeader="จำนวน Member ที่ซื้อ"
                dataSort={ true }
              >
                จำนวน Member ที่ซื้อ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="gold_price"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatGoldPrice}
                csvFormat={this.formatGoldPrice}
                csvHeader="เหรียญทอง"
                dataSort={ true }
              >
                เหรียญทอง
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="silver_price"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatSilverPrice}
                csvFormat={this.formatSilverPrice}
                csvHeader="เหรียญเงิน"
                dataSort={ true }
              >
                เหรียญเงิน
              </TableHeaderColumn>

              {/* <TableHeaderColumn
                dataField="count_chapter"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCountChapter}
                csvFormat={this.formatCountChapter}
                csvHeader="จำนวน Chapter ที่ซื้อ"
                dataSort={ true }
              >
                จำนวน Chapter ที่ซื้อ
              </TableHeaderColumn> */}


{/* 
              <TableHeaderColumn
                dataField="total_view"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatTotalView}
                csvFormat={this.formatTotalView}
                csvHeader="Total View"
                dataSort={ true }
              >
                Total View
              </TableHeaderColumn> */}
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid className="ml-2">
          <div>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Buy Whole Book Report (ยอดการซื้อหนังสือทั้งเล่ม)</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                    category_name: "",
                    bot_read: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    let startDate = null;
                    let endDate = null;

                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    value.start = startDate;
                    value.end = endDate;

                    if (values.category_name) {
                      value.categoryName = values.category_name;
                    }

                    if (values.bot_read) {
                      value.botRead = values.bot_read;
                    }

                    this.props.getReportBuyWholeBook(value);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col sm="5" className="form-group">
                            <label>วันที่เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>

                          <Col sm="7">
                            <Row>
                              <Col sm="4">
                                <FormGroup>
                                  <label>หมวดหมู่</label>

                                  {this.bindingCategoryName({
                                    values,
                                    handleChange,
                                  })}
                                </FormGroup>
                              </Col>
                              <Col sm="4">
                                <FormGroup>
                                  <label>ประเภท</label>

                                  {this.bindingBookType({
                                    values,
                                    handleChange,
                                  })}
                                </FormGroup>
                              </Col>
                              <Col sm="3" className="text-right">
                                <FormGroup>
                                  <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="btn btn-primary btn-sm"
                                    style={{
                                      marginLeft: 30,
                                      marginTop: 27,
                                      color: "white",
                                      width: 100,
                                    }}
                                  >
                                    Search
                                  </button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
                {this.createTable()}
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ reportBuyWholeBookReducer, categoryReducer }) => ({
  reportBuyWholeBookReducer,
  categoryReducer,
});

const mapDispatchToProps = {
  getReportBuyWholeBook,
  getCategory,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuyWholeBook);


import { httpClient } from "../utils/HttpClient";

import {
    HTTP_VIEW_RANK_FETCHING,
    HTTP_VIEW_RANK_SUCCESS,
    HTTP_VIEW_RANK_FAILED,
  } from "../constants/report";

import {server} from "../constants"

const setStateViewRankToSuccess = payload => ({
    type: HTTP_VIEW_RANK_SUCCESS,
    payload: payload
  });
  
  const setStateViewRankToFetching = () => ({
    type: HTTP_VIEW_RANK_FETCHING
  });
  
  const setStateViewRankToFailed = () => ({
    type: HTTP_VIEW_RANK_FAILED
  });

  /* Get Report View Rank */
export const getReportViewRank = (value) => {
    return async (dispatch) => {
      dispatch(setStateViewRankToFetching());
      await doGetReportViewRank(dispatch,value);
    };
  };
  
  const doGetReportViewRank = (dispatch, value) => {
    let dataFilter = "";
  
    if (value) {
      if (value.categoryName) {
        dataFilter += "?category_name=" + value.categoryName;
      }

      if (value.bookTitle) {
        dataFilter += "?book_title=" + value.bookTitle;
      }
    }
  
    const token = localStorage.getItem("token");

    const params = {
      category_name: value ? value.categoryName : "",
      book_title: value ? value.bookTitle : ""
    }

    httpClient
    .get(`${server.REPORT_URL}/view_rank`,  {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    })
    .then((result) => {
      dispatch(setStateViewRankToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateViewRankToFailed());
    });
    
  };
  
  
import React, { Component } from "react";

import { Row, Col, Button, ButtonGroup, ListGroup } from "shards-react";
import { NavLink } from "react-router-dom";

export default class MenuMember extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className="mt-3">
          <Col className="form-group">
            <ListGroup flush>
              <ButtonGroup>
                <Row>
                  <Col>
                  <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/member"
                      size="sm"
                    >
                      Member
                    </Button>
                    <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/active_member"
                      size="sm"
                    >
                      Active Member
                    </Button>
                  </Col>
                </Row>
              </ButtonGroup>
            </ListGroup>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

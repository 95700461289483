import React, { Component, useEffect, useState } from "react";
import { useParams, useHistory, NavLink } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "shards-react";
import { Descriptions, Empty, Tabs, Radio } from "antd";

const { TabPane } = Tabs;

export default function AccountantReports() {
  const history = useHistory();
  const [accountantReport, setAccountantReport] = useState(false);

  useEffect(() => {
    localStorage.removeItem("topup_current_page");
    const userProfile = JSON.parse(localStorage.getItem("user_profile"));

    if (userProfile) {
      if (userProfile.accountant_report === "Y") setAccountantReport(true);
    }
  }, []);

  return (
    <React.Fragment>
      <Row className="mt-4 ml-1">
        <Col className="form-group">
          <ListGroup flush>
            <ButtonGroup>
              {accountantReport && (
                <Row>
                  <Col>
                  <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/accountant_reports/orders"
                    >
                      Order
                    </Button>
                    <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/accountant_reports/book_rank"
                    >
                      ยอดการซื้อหนังสือ
                    </Button>
                    <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/accountant_reports/payments"
                    >
                      Payment
                    </Button>
                    <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/accountant_reports/purchases"
                    >
                      Purchase History
                    </Button>
                    <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/accountant_reports/silver_coins"
                    >
                      Silver Coin
                    </Button>
                    <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/accountant_reports/silver_coins_transaction"
                    >
                      Silver Coin Transaction
                    </Button>
                    <Button
                      squared
                      theme="white"
                      tag={NavLink}
                      to="/accountant_reports/writers"
                    >
                      Writer Sale Report
                    </Button>
                  </Col>
                </Row>
              )}
            </ButtonGroup>
          </ListGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

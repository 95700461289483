import { httpClient } from "./../utils/HttpClient";

import {
    HTTP_REPORT_STORY_SUCCESS,
    HTTP_REPORT_STORY_FETCHING,
    HTTP_REPORT_STORY_FAILED,

    server,
} from "../constants";

/* REPORT SALE BY STORY ACTION */
const setStateReportSaleStoryToSuccess = (payload) => ({
  type: HTTP_REPORT_STORY_SUCCESS,
  payload: payload,
});

const setStateReportSaleStoryToFetching = () => ({
  type: HTTP_REPORT_STORY_FETCHING,
});

const setStateReportSaleStoryToFailed = () => ({
  type: HTTP_REPORT_STORY_FAILED,
});


/* Get Report Sale By Story */
export const getReportSaleByStory = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportSaleStoryToFetching(value));
    await doGetReportSaleStory(dispatch,value);
  };
};

const doGetReportSaleStory = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if(!value.start)
        dateFilter += "?end_date=" + value.end + " 23:59.00";
      else
        dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/sale_by_story` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateReportSaleStoryToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateReportSaleStoryToFailed());
    });
};


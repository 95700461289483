import { httpClient } from "../utils/HttpClient";

import {
    HTTP_MEMBER_PURCHASE_FETCHING,
    HTTP_MEMBER_PURCHASE_SUCCESS,
    HTTP_MEMBER_PURCHASE_FAILED,
} from "../constants/report";

import { server } from "../constants";

const setStatePurchaseToSuccess = (payload) => ({
  type: HTTP_MEMBER_PURCHASE_SUCCESS,
  payload: payload,
});

const setStatePurchaseToFetching = () => ({
  type: HTTP_MEMBER_PURCHASE_FETCHING,
});

const setStatePurchaseToFailed = () => ({
    type: HTTP_MEMBER_PURCHASE_FAILED,
  });

/* Get Report Purchase Coin Rank*/
export const getReportPurchaseCoinRank = (value) => {
  return async (dispatch) => {
    dispatch(setStatePurchaseToFetching());
    await doGetReportPurchaseCoinRank(dispatch, value);
  };
};

const doGetReportPurchaseCoinRank = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dateFilter += "?end_date=" + value.end + " 23:59.00";
      else dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/member_coin` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStatePurchaseToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStatePurchaseToFailed());
    });
};

import { httpClient } from "../utils/HttpClient";

import {
  HTTP_PACKAGES_FETCHING,
  HTTP_PACKAGES_SUCCESS,
  HTTP_PACKAGES_FAILED,
  HTTP_SINGLE_PACKAGE_FETCHING,
  HTTP_SINGLE_PACKAGE_SUCCESS,
  HTTP_SINGLE_PACKAGE_FAILED,
} from "../constants/package";

const setStatePackagesToSuccess = payload => ({
  type: HTTP_PACKAGES_SUCCESS,
  payload: payload
});

const setStatePackagesToFetching = () => ({
  type: HTTP_PACKAGES_FETCHING
});

const setStatePackagesToFailed = () => ({
  type: HTTP_PACKAGES_FAILED,
});

const setStateSinglePackageToSuccess = payload => ({
  type: HTTP_SINGLE_PACKAGE_SUCCESS,
  payload: payload
});

const setStateSinglePackageToFetching = () => ({
  type: HTTP_SINGLE_PACKAGE_FETCHING
});

const setStateSinglePackageToFailed = () => ({
  type: HTTP_SINGLE_PACKAGE_FAILED
});

export const getPackages = () => {
  return async dispatch => {
    dispatch(setStatePackagesToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`admin/packages`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      dispatch(setStatePackagesToSuccess(result.data))
    } catch(error)  {
      alert(JSON.stringify(error))
      dispatch(setStatePackagesToFailed())
    }
  };
};

export const getPackageById = (id) => {
  return async dispatch => {
    dispatch(setStateSinglePackageToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`admin/package/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      dispatch(setStateSinglePackageToSuccess(result.data))
    } catch(error)  {
      alert(JSON.stringify(error))
      dispatch(setStateSinglePackageToFailed())
    }
  };
};


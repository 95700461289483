import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import Swal from "sweetalert2";
import UserRoleForm from "./userRoleForm";

class CreateRole extends Component {
  showForm = ({ values, handleChange, handleSubmit, isSubmitting }) => {
    return (
      <div style={{ padding: 30 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD ROLE</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <UserRoleForm
                      values={values}
                      handleChange={handleChange}
                      isDisableField={false}
                    ></UserRoleForm>

                    <div className="mt-3 text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary pull-right"
                        style={{ marginRight: 10, color: "white", width: 100 }}
                      >
                        บันทึก
                      </button>
                      <a
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ยกเลิก
                      </a>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            role_name: "",
            dashboard: true,
            campaign: true,
            package: true,
            push_notification: true,
            banner: true,
            book: true,
            category: true,
            comment: true,
            member: true,
            playlist: true,
            writer: true,
            user: true,
            marketing_report: true,
            accountant_report: true,
            writer_recommend:true,
            writer_income_ratio: true
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const params = {
              role_name: values.role_name,
              dashboard: values.dashboard ? 'Y' : 'N',
              campaign: values.campaign ? 'Y' : 'N',
              package: values.package ? 'Y' : 'N',
              push_notification: values.push_notification ? 'Y' : 'N',
              banner: values.banner ? 'Y' : 'N',
              book: values.book ? 'Y' : 'N',
              category: values.category ? 'Y' : 'N',
              comment: values.comment ? 'Y' : 'N',
              member: values.member ? 'Y' : 'N',
              playlist: values.playlist ? 'Y' : 'N',
              writer: values.writer ? 'Y' : 'N',
              user: values.user ? 'Y' : 'N',
              marketing_report: values.marketing_report ? 'Y' : 'N',
              accountant_report: values.accountant_report ? 'Y' : 'N',
              writer_recommend: values.writer_recommend ? 'Y' : 'N',
              writer_income_ratio: values.writer_income_ratio ? 'Y' : 'N',
            }
            
            
            const token = localStorage.getItem("token");
            const response = await httpClient.post('admin/user-role', 
              JSON.stringify(params), {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 3000,
              });
              setSubmitting(false);
              this.props.history.push(`/role`);
            } else {
              Swal.fire({
                icon: "error",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                timer: 3000,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

export default CreateRole;

import React, { Component } from "react";
import { getAllBannerView } from "../../../actions/banner_view.action";
import { deleteBanner } from "../../../actions/banner.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  InputGroupText,
  DatePicker,
  FormSelect,
  Badge,
  FormInput,
} from "shards-react";
import { Formik } from "formik";

import moment from "moment";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import Swal from "sweetalert2";
import ContentPagination from "../../Layout/Common/Pagination";

class BannerView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      start_date: "",
      end_date: "",
      banner_type: "",
      banner_name: "",
    };
  }

  async componentDidMount() {
    const current_page = localStorage.getItem("banner_current_page");

    if (this.props.location.page)
      await this.setState({ currentPage: this.props.location.page });
    else if (current_page) await this.setState({ currentPage: Number(current_page) });

    await this.getAllBannerView(this.state.currentPage);

    localStorage.removeItem("banner_current_page");
  }

  formatDate(date) {
    if (date) return new Date(date).toLocaleDateString("en-GB");
  }

  formatBannerType(banner_type) {
    if (banner_type) {
      if (banner_type === "TOP")
        return (
          <Badge theme="success" className="font-12">
            ด้านบน
          </Badge>
        );
      else if (banner_type === "BOTTOM")
        return (
          <Badge theme="warning" className="font-12">
            ด้านล่าง
          </Badge>
        );
      else if (banner_type === "POPUP")
        return (
          <Badge theme="info" className="font-12">
            Popup
          </Badge>
        );
    }
  }

  formatImage(item) {
    if (item) {
      if (!item.banner_image_mobile)
        return (
          <img
            id="book_image_mobile"
            name="banner_image_mobile"
            src={item.book_image_mobile}
            width="180px"
            alt=""
          />
        );
      else
        return (
          <img
            id="banner_image_mobile"
            name="banner_image_mobile"
            src={item.banner_image_mobile}
            width="180px"
            alt=""
          />
        );
    }
  }

  formatBannerName(item) {
    let banner_name = "";

    if (item) {
      if (item.book_title) banner_name = item.book_title;
      else banner_name = item.banner_name;
    }
    return banner_name;
  }

  async getAllBannerView(page) {
    this.setState({ loading: true });

    const value = {};
    value.start_date = this.state.start_date;
    value.end_date = this.state.end_date;
    value.banner_type = this.state.banner_type;
    value.banner_name = this.state.banner_name;

    value.page = page;
    await this.props.getAllBannerView(value);

    const { banners, total } = this.props.bannerViewReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.bannerViewReducer.banners) {
      this.setState({
        banners,
        totalPages,
      });
    }
    this.setState({ loading: false });
  }

  async changeBannerStatus(banner) {
    const newStatus = banner.banner_status === "A" ? "I" : "A";
    let newBanners = this.state.banners;

    newBanners.map((newBanner) => {
      if (newBanner.banner_id === banner.banner_id) {
        newBanner.banner_status = newStatus;
      }
    });

    this.setState({ banners: newBanners });

    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let params = {
      banner_status: newStatus,
      updated_by: username,
      updated_datetime: dateTime,
    };

    const token = localStorage.getItem("token");
    let response = await httpClient.patch(
      `${server.BANNER_URL}/status/${banner.banner_id}`,
      JSON.stringify(params),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 201 && response.status !== 200) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
        timer: 1500,
      });
    }
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>รูป Banner</th>
            <th style={{ textAlign: "center" }}>ชื่อ Banner/ชื่อ Book</th>
            <th style={{ textAlign: "center" }}>ตำแหน่ง Banner</th>
            <th style={{ textAlign: "center" }}>ยอดคลิก</th>
            <th style={{ textAlign: "center" }}>วันที่เริ่มต้น</th>
            <th style={{ textAlign: "center" }}>วันที่สิ้นสุด</th>
            <th style={{ textAlign: "center" }}>เผยแพร่</th>
            <th style={{ textAlign: "center" }}>แก้ไข/ลบ</th>
          </tr>
        </thead>
        <tbody>
          {this.state.banners.map((item, index) => {
            return (
              <tr>
                <td className="text-center align-middle">
                  {" "}
                  {this.formatImage(item)}
                </td>

                <td className="text-center align-middle">
                  {" "}
                  {this.formatBannerName(item)}
                </td>

                <td className="text-center align-middle">
                  {" "}
                  {this.formatBannerType(item.banner_type)}
                </td>
                <td className="text-center align-middle">{item.total_view}</td>
                <td className="text-center align-middle">
                  {this.formatDate(item.startdate)}
                </td>

                <td className="text-center align-middle">
                  {this.formatDate(item.enddate)}
                </td>

                <td className="text-center align-middle">
                  <FormCheckbox
                    toggle
                    inline
                    checked={item.banner_status === "A" ? true : false}
                    onChange={() => this.changeBannerStatus(item)}
                    className="mr-0 ml-2"
                  />
                </td>

                <td className="text-center align-middle">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary mr-2"
                    onClick={() =>
                      this.props.history.push(
                        {
                          pathname: "/banners/edit/" + item.banner_id,
                          page: this.state.currentPage,
                        },
                        localStorage.setItem(
                          "banner_current_page",
                          this.state.currentPage
                        )
                      )
                    }
                  >
                    Edit
                  </button>

                  <button
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure to delete?",
                        text: "You won't be able to revert this!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes, delete it!",
                        cancelButtonText: "No, cancel!",
                      }).then((result) => {
                        if (result.value) {
                          this.deleteBanner(item.banner_id);
                        }
                      });
                    }}
                    type="button"
                    className="btn btn-sm  btn-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  async deleteBanner(banner_id) {
    await this.props.deleteBanner(banner_id);
    setTimeout(() => {
      this.getAllBannerView(this.state.currentPage);
    }, 100);
  }

  changePage(page) {
    this.setState({ currentPage: page });
    this.getAllBannerView(page);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <Container fluid className="main-content-container px-4">
        <div style={{ padding: 20 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Banner</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => this.props.history.push(`/banners/create`)}
                    className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add Banner
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ padding: "1rem" }}>
              <Formik
                initialValues={{
                  start_date: "",
                  end_date: "",
                  banner_type: "",
                  banner_name: "",
                  page: "",
                }}
                onSubmit={async (values) => {
                  let startDate = null;
                  let endDate = null;
                  let bannerType = null;
                  let bannerName = null;

                  if (values.start_date) {
                    startDate = moment(values.start_date).format("YYYY-MM-DD");
                  }

                  if (values.end_date) {
                    endDate = moment(values.end_date).format("YYYY-MM-DD");
                  }

                  if (values.banner_type) bannerType = values.banner_type;
                  if (values.banner_name) bannerName = values.banner_name;

                  this.setState({
                    start_date: startDate,
                    end_date: endDate,
                    banner_type: bannerType,
                    banner_name: bannerName,
                  });

                  this.getAllBannerView(0);
                }}
              >
                {(props) => {
                  const {
                    values,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    handleChange,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row form>
                        <Col sm="4" className="form-group">
                          <label>วันที่เริ่มต้น/สิ้นสุด (จำนวนยอด View)</label>
                          <InputGroup className={"d-flex my-auto date-range"}>
                            <DatePickerField
                              size="sm"
                              name="start_date"
                              onChange={setFieldValue}
                              value={values.start_date}
                              placeholder="Start Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <DatePickerField
                              size="sm"
                              name="end_date"
                              onChange={setFieldValue}
                              value={values.end_date}
                              placeholder="End Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <i className="material-icons">&#xE916;</i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>

                        <Col sm="3" className="form-group">
                          <label>ชื่อ Banner</label>
                          <FormInput
                            name="banner_name"
                            id="banner_name"
                            onChange={handleChange}
                            value={values.banner_name}
                          />
                        </Col>

                        <Col sm="3">
                          <FormGroup>
                            <label>ตำแหน่ง Banner</label>
                            <FormSelect
                              id="banner_type"
                              name="banner_type"
                              value={values.banner_type}
                              onChange={handleChange}
                            >
                              <option value="" label="Select" />
                              <option value="TOP" label="ด้านบน" />
                              <option value="BOTTOM" label="ด้านล่าง" />
                              <option
                                value="POPUP"
                                label="Popup ก่อนเข้าหน้าเวปไซต์/Application"
                              />
                            </FormSelect>
                          </FormGroup>
                        </Col>
                        <Col sm="2" className="text-right">
                          <FormGroup>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm"
                              style={{
                                marginLeft: 30,
                                marginTop: 30,
                                color: "white",
                                width: 100,
                              }}
                            >
                              Search
                            </button>
                          </FormGroup>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </CardBody>

            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.banners.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ bannerViewReducer }) => ({
  bannerViewReducer,
});

const mapDispatchToProps = {
  getAllBannerView,
  deleteBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerView);

import React, { Component } from "react";
import { httpClient } from "../../utils/HttpClient";
import { Link } from "react-router-dom";
import moment from "moment";
import { DatePicker, Descriptions, Spin, Radio } from 'antd';
import NumberFormat from "react-number-format";
import 'antd/dist/antd.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Pie, Line } from 'react-chartjs-2';

const { RangePicker } = DatePicker;

const options = [
  {
    label: 'Baht',
    value: '1',
  },
  {
    label: 'Coin',
    value: '2',
  }
];

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalMembers: 0,
      totalWriters: 0,
      totalUgcWriters: 0,
      totalBooks: 0,
      totalUgcBooks: 0,
      newMembers: 0,
      newWriters: 0,
      dateStart: moment().subtract(6, 'day'),
      dateEnd: moment(),
      topupAmount: null,
      totalAmount: 0,
      totalTransactions: 0,
      transactionByChannel: 0,
      membersByAge: null,
      membersByGender: null,
      amountByChannel: 0,
      pieChartData: null,
      lineChartData: null,
      coinUsageData: null,
      goldCoinRemainData: null,
      silverCoinRemainData: null,
      membersByAgeData: null,
      membersByGenderData: null,
      overviewLoading: false,
      topupLoading: false,
      coinLoading: false,
      memberLoading: false,
      topupTab: '1'
    }

    this.onDateChange = this.onDateChange.bind(this)
  }

  async componentDidMount() {
    const userProfile = JSON.parse(localStorage.getItem("user_profile"));

    if (userProfile) {
      this.setState({
        permission: userProfile.dashboard === "Y",
      });
    }

    this.getDashboardInfo()
    this.getTransactionInfo()
    this.getCoinStat()
    this.getMemberStat()
  }

  async getDashboardInfo()  {
    this.setState({overviewLoading: true})

    try {
      const token = localStorage.getItem("token");
      const response = await httpClient.get('admin/dashboard/overview', 
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })

      this.setState({
        totalBooks: response.data.totalBooks,
        totalUgcBooks: response.data.totalUgcBooks,
        totalMembers: response.data.totalMembers,
        totalWriters: response.data.totalWriters,
        totalUgcWriters: response.data.totalUgcWriters,
        newMembers: response.data.newMembers,
        newWriters: response.data.newWriters
      })
    } catch(err)  {
    }

    this.setState({overviewLoading: false})
  }

  async getTransactionInfo()  {
    this.setState({topupLoading: true})

    const dateStart = this.state.dateStart.format('YYYY-MM-DD 00:00:00')
    const dateEnd = this.state.dateEnd.format('YYYY-MM-DD 23:59:59')

    try {
      const token = localStorage.getItem("token");
      const response = await httpClient.get(`admin/dashboard/topup?dateStart=${dateStart}&dateEnd=${dateEnd}`, 
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })

      this.setState({ topupAmount: response.data.topupAmount })
      this.setState({transactionByChannel: response.data.transactionByChannel})
      this.setPieChartData(response.data.transactionByChannel)
      this.setLineChartData(response.data.topupAmount)
    } catch(err)  {
    }

    this.setState({topupLoading: false})
  }

  async getCoinStat() {
    this.setState({coinLoading: true})

    const dateStart = this.state.dateStart.format('YYYY-MM-DD 00:00:00')
    const dateEnd = this.state.dateEnd.format('YYYY-MM-DD 23:59:59')

    try {
      const token = localStorage.getItem("token");
      const response = await httpClient.get(`admin/dashboard/coin?dateStart=${dateStart}&dateEnd=${dateEnd}`, 
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })

      this.setCoinUsageChartData(response.data.totalCoinUsage)
      this.setCoinRemainChartData(response.data.totalCoinRemain)
    } catch(err)  {
    }

    this.setState({coinLoading: false})
  }

  async getMemberStat() {
    this.setState({memberLoading: true})

    try {
      const token = localStorage.getItem("token");
      const response = await httpClient.get(`admin/dashboard/member`, 
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      })

      this.setMemberAgeData(response.data.membersByAge)
      this.setMemberGenderData(response.data.membersByGender)
    } catch(err)  {
    }

    this.setState({memberLoading: false})
  }

  setMemberAgeData(data) {
    this.setState({membersByAge: data})

    const membersByAgeData = {
      labels: [
        'น้อยกว่า 18 ปี', 
        '18-25 ปี', 
        '26-35 ปี', 
        '36-45 ปี', 
        'มากกว่า 45 ปี'
      ],
      datasets: [{
        data: [
          data.group2, 
          data.group3,
          data.group4,
          data.group5,
          data.group6
        ],
        backgroundColor: [
          "#4f000b",
          "#720026",
          "#ce4257",
          "#ff7f51",
          "#ff9b54"
        ],
        hoverBackgroundColor: [
          "#4f000b",
          "#720026",
          "#ce4257",
          "#ff7f51",
          "#ff9b54"
        ]
      }]
    }

    this.setState({membersByAgeData})
  }

  setMemberGenderData(data) {
    this.setState({membersByGender: data})

    const membersByGenderData = {
      labels: [
        'ชาย', 
        'หญิง', 
        'ไม่ระบุ', 
      ],
      datasets: [{
        data: [
          data.male, 
          data.female,
          data.others,
        ],
        backgroundColor: [
          "#a2d2ff",
          "#ffafcc",
          "#cdb4db",
        ],
        hoverBackgroundColor: [
          "#a2d2ff",
          "#ffafcc",
          "#cdb4db",
        ]
      }]
    }

    this.setState({membersByGenderData})
  }

  async onDateChange(value, dateString) {
    await this.setState({
      dateStart: value[0],
      dateEnd: value[1]
    })

    this.getTransactionInfo()
    this.getCoinStat()
  }

  setPieChartData(data) {
    const pieChartData = {
      labels: [
        'ios', 
        'android', 
        'Credit/Debit Card', 
        'Transfer', 
        'Qr Code',
        'S2M Token'
      ],
      datasets: [{
        data: [
          this.state.topupTab === '1' ? data.ios.amount : data.ios.coins,  
          this.state.topupTab === '1' ? data.android.amount : data.android.coins,
          this.state.topupTab === '1' ? data.creditCard.amount : data.creditCard.coins,
          this.state.topupTab === '1' ? data.transfer.amount : data.transfer.coins,
          this.state.topupTab === '1' ? data.qrCode.amount : data.qrCode.coins,
          this.state.topupTab === '1' ? data.token.amount : data.token.coins
        ],
        backgroundColor: [
          "#4f000b",
          "#720026",
          "#ce4257",
          "#ff7f51",
          "#ff9b54",
          "#fcca17"
        ],
        hoverBackgroundColor: [
          "#4f000b",
          "#720026",
          "#ce4257",
          "#ff7f51",
          "#ff9b54",
          "#fcca17"
        ]
      }]
    }

    this.setState({pieChartData})
  }

  setLineChartData(topupAmount)  {
    let totalAmount = 0
    let totalTransactions = 0
    let amountWebsiteArray = []
    let amountMobileArray = []
    let amountTokenArray = []
    let labelArray = []

    for(const item of topupAmount)  {
      totalAmount += item.amount - item.amountToken
      totalTransactions += item.transactions

      amountWebsiteArray.push(this.state.topupTab === '1' ? item.amountWebsite : item.coinWebsite)
      amountMobileArray.push(this.state.topupTab === '1' ? item.amountMobile : item.coinMobile)
      amountTokenArray.push(this.state.topupTab === '1' ? item.amountToken : item.coinToken)
      labelArray.push(moment(item.date).format('DD/MM'))
    }

    this.setState({
      totalAmount: Math.round(totalAmount),
      totalTransactions
    })

    const lineChartData = {
      labels: labelArray,
      datasets: [
        {
          label: 'Website',
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(206,66,87,0.2)',
          borderColor: 'rgba(206,66,87,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(206,66,87,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(206,66,87,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: amountWebsiteArray
        },
        {
          label: 'Mobile',
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(255,155,84,0.2)',
          borderColor: 'rgba(255,155,84,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(255,155,84,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(255,155,84,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: amountMobileArray
        },
        {
          label: 'S2M Token',
          fill: true,
          lineTension: 0.1,
          backgroundColor: 'rgba(252,202,23,0.2)',
          borderColor: 'rgba(252,202,23,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(252,202,23,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(252,202,23,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: amountTokenArray
        }
      ]
    };

    this.setState({lineChartData})
  }

  changeTopupType(value) {
    this.setState({ topupTab: value }, this.getTransactionInfo)
  }

  setCoinUsageChartData(data) {
    let goldCoinUsageArray = []
    let silverCoinUsageArray = []
    let labelArray = []

    for(const item of data)  {
      goldCoinUsageArray.push(item.goldCoinUsage)
      silverCoinUsageArray.push(item.silverCoinUsage)
      labelArray.push(moment(item.date).format('DD/MM'))
    }

    const coinUsageData = {
      labels: labelArray,
      datasets: [
        {
          label: 'Gold Coin',
          backgroundColor: 'rgba(246,189,3,0.2)',
          borderColor: 'rgba(246,189,3,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(246,189,3,0.4)',
          hoverBorderColor: 'rgba(246,189,3,1)',
          data: goldCoinUsageArray
        },
        {
          label: 'Silver Coin',
          backgroundColor: 'rgba(201,201,201,0.2)',
          borderColor: 'rgba(201,201,201,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(201,201,201,0.4)',
          hoverBorderColor: 'rgba(201,201,201,1)',
          data: silverCoinUsageArray
        }
      ]
    };

    this.setState({
      coinUsageData
    })
  }

  setCoinRemainChartData(data) {
    let goldCoinRemainArray = []
    let silverCoinRemainArray = []
    let labelArray = []

    for(const item of data)  {
      goldCoinRemainArray.push(item.goldCoinRemain)
      silverCoinRemainArray.push(item.silverCoinRemain)
      labelArray.push(moment(item.date).format('DD/MM'))
    }

    const goldCoinRemainData = {
      labels: labelArray,
      datasets: [
        {
          label: 'Gold Coin',
          backgroundColor: 'rgba(246,189,3,0.2)',
          borderColor: 'rgba(246,189,3,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(246,189,3,0.4)',
          hoverBorderColor: 'rgba(246,189,3,1)',
          data: goldCoinRemainArray
        }
      ]
    };

    const silverCoinRemainData = {
      labels: labelArray,
      datasets: [
        {
          label: 'Silver Coin',
          backgroundColor: 'rgba(201,201,201,0.2)',
          borderColor: 'rgba(201,201,201,1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(201,201,201,0.4)',
          hoverBorderColor: 'rgba(201,201,201,1)',
          data: silverCoinRemainArray
        }
      ]
    };

    this.setState({
      goldCoinRemainData,
      silverCoinRemainData
    })
  }

  render()  {
    return (
      <React.Fragment>
        { this.state.permission ?
          <div className="container-fluid p-4">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-4 mb-4">
                <Spin 
                  spinning={this.state.overviewLoading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                >
                  <Link to="/member" className="stats-small stats-small--1 card card-small text-decoration-none">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">Total Members</span>
                          <h6 className="stats-small__value count my-3">
                            <NumberFormat 
                              value={this.state.totalMembers} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => value} 
                            />
                          </h6>
                          <span className={'stats-small__percentage ' + (this.state.newMembers > 0 ? 'stats-small__percentage--increase' : 'p-0')}>
                            { this.state.newMembers > 0 ? this.state.newMembers : <b className="text-primary">-</b>}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Spin>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-4 mb-4">
                <Spin 
                  spinning={this.state.topupLoading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                >
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">Top up transaction</span>
                          <h6 className="stats-small__value count my-3">
                            <NumberFormat 
                              value={this.state.totalTransactions} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => value} 
                            />
                          </h6>
                          <span className="stats-small__percentage p-0 text-muted">
                            { moment(this.state.dateStart).format('DD/MM') } - { moment(this.state.dateEnd).format('DD/MM') }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-4 mb-4">
                <Spin 
                  spinning={this.state.topupLoading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                >
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">Top up amount</span>
                          <h6 className="stats-small__value count my-3">
                            <NumberFormat 
                              value={this.state.totalAmount} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => value} 
                            />
                          </h6>
                          <span className="stats-small__percentage p-0 text-muted">
                            { moment(this.state.dateStart).format('DD/MM') } - { moment(this.state.dateEnd).format('DD/MM') }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-3 mb-4">
                <Spin 
                  spinning={this.state.overviewLoading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                >
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">Total Writers</span>
                          <h6 className="stats-small__value count my-3">
                            <NumberFormat 
                              value={this.state.totalWriters} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => value} 
                            />
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-3 mb-4">
                <Spin 
                  spinning={this.state.overviewLoading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                >
                  <Link to="/book" className="stats-small stats-small--1 card card-small text-decoration-none">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">Total UGC Writers</span>
                          <h6 className="stats-small__value count my-3">{ this.state.totalUgcWriters }</h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Spin>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-3 mb-4">
                <Spin 
                  spinning={this.state.overviewLoading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                >
                  <div className="stats-small stats-small--1 card card-small">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">Total Books</span>
                          <h6 className="stats-small__value count my-3">
                            <NumberFormat 
                              value={this.state.totalBooks} 
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => value} 
                            />
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </Spin>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-3 mb-4">
                <Spin 
                  spinning={this.state.overviewLoading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                >
                  <Link to="/book" className="stats-small stats-small--1 card card-small text-decoration-none">
                    <div className="card-body p-0 d-flex">
                      <div className="d-flex flex-column m-auto">
                        <div className="stats-small__data text-center">
                          <span className="stats-small__label text-uppercase">Total UGC Books</span>
                          <h6 className="stats-small__value count my-3">{ this.state.totalUgcBooks }</h6>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Spin>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12 mb-4">
                <div className="card card-small">
                  <div className="card-header border-bottom d-flex align-items-center">
                    <h6 className="m-0 flex-fill">Top up Amount</h6>

                    <Radio.Group
                      options={options}
                      onChange={(e) => {
                        this.changeTopupType(e.target.value)
                      }}
                      value={this.state.topupTab}
                    />
                  </div>
                  <div className="card-body pt-0">
                    <div className="row border-bottom py-2 bg-light">
                      <div className="col-12 col-sm-6">
                        <RangePicker 
                          placeholder={['เริ่มต้น', 'สิ้นสุด']} 
                          format="DD/MM/YYYY"
                          disabledDate={false}
                          onChange={this.onDateChange}
                          value={[moment(this.state.dateStart), moment(this.state.dateEnd)]}
                        />
                      </div>
                      <div className="col-12 col-sm-6 d-flex mb-2 mb-sm-0">
                        <a 
                          type="button" 
                          className="btn btn-sm btn-white ml-auto mr-auto ml-sm-auto mr-sm-0 mt-3 mt-sm-0 d-flex align-items-center"
                          href={"/marketing_reports/topup"}
                        >
                          View Full Report &rarr;
                        </a>
                      </div>
                    </div>
                    
                    <div className="text-center">
                      <Spin 
                        spinning={this.state.topupLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                        className="my-5"
                      >
                        { this.state.lineChartData && 
                          <Line 
                            data={this.state.lineChartData}
                            options={{ 
                              maintainAspectRatio: true
                            }}
                          /> 
                        }
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card card-small h-100">
                  <div className="card-header border-bottom">
                    <h6 className="m-0">Top up by channel</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <Spin 
                        spinning={this.state.topupLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                        className="my-5"
                      >
                        { this.state.pieChartData && (
                          <Pie
                            data={this.state.pieChartData}
                            width={170}
                            legend={{position: 'bottom'}}
                            options={{ 
                              maintainAspectRatio: true
                            }}
                          />
                        )}
                      </Spin>
                    </div>

                    { this.state.pieChartData && 
                      <div className="channel-summary font-12 mt-2">
                        <div className="font-weight-bold text-uppercase font-11 mb-2">Total Transaction</div>
                        <Descriptions
                          title=""
                          bordered
                          size="small"
                          column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                          <Descriptions.Item label="IOS">{ this.state.transactionByChannel.ios.total}</Descriptions.Item>
                          <Descriptions.Item label="Android">{ this.state.transactionByChannel.android.total}</Descriptions.Item>
                          <Descriptions.Item label="Qr Code">{ this.state.transactionByChannel.qrCode.total}</Descriptions.Item>
                          <Descriptions.Item label="Credit Card">{ this.state.transactionByChannel.creditCard.total}</Descriptions.Item>
                          <Descriptions.Item label="Transfer">{ this.state.transactionByChannel.transfer.total}</Descriptions.Item> 
                          <Descriptions.Item label="S2M Token">{ this.state.transactionByChannel.token.total}</Descriptions.Item>
                        </Descriptions>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-4">
                <div className="card card-small h-100">
                  <div className="card-header border-bottom">
                    <h6 className="m-0">Coin Usage</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <Spin 
                        spinning={this.state.coinLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                        className="my-5"
                      >
                        { this.state.coinUsageData &&
                          <Line 
                            data={this.state.coinUsageData}
                            height={300}
                            options={{
                              maintainAspectRatio: false
                            }}
                          />
                        }
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-4">
                <div className="card card-small h-100">
                  <div className="card-header border-bottom">
                    <h6 className="m-0">Gold Coin Remains</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <Spin 
                        spinning={this.state.coinLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                        className="my-5"
                      >
                        { this.state.goldCoinRemainData &&
                          <Line 
                            data={this.state.goldCoinRemainData}
                            height={300}
                            options={{
                              maintainAspectRatio: false
                            }}
                          />
                        }
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-4">
                <div className="card card-small h-100">
                  <div className="card-header border-bottom">
                    <h6 className="m-0">Silver Coin Remains</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <Spin 
                        spinning={this.state.coinLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                        className="my-5"
                      >
                        { this.state.silverCoinRemainData &&
                          <Line 
                            data={this.state.silverCoinRemainData}
                            height={300}
                            options={{
                              maintainAspectRatio: false
                            }}
                          />
                        }
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="card card-small h-100">
                  <div className="card-header border-bottom">
                    <h6 className="m-0">Member Age</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <Spin 
                        spinning={this.state.memberLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                        className="my-5"
                      >
                        { this.state.membersByAgeData && (
                          <Pie
                            data={this.state.membersByAgeData}
                            width={250}
                            legend={{position: 'bottom'}}
                            options={{ 
                              maintainAspectRatio: true
                            }}
                          />
                        )}
                      </Spin>

                      { this.state.membersByAge &&
                        <>
                          <Descriptions
                            title=""
                            bordered
                            size="small"
                            column={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                            className="mt-3"
                          >
                            <Descriptions.Item label="น้อยกว่า 18 ปี">{ ((this.state.membersByAge.group2/this.state.membersByAge.totalWithData)*100).toFixed(2) }%</Descriptions.Item>
                            <Descriptions.Item label="18-25 ปี">{ ((this.state.membersByAge.group3/this.state.membersByAge.totalWithData)*100).toFixed(2) }%</Descriptions.Item>
                            <Descriptions.Item label="26-35 ปี">{ ((this.state.membersByAge.group4/this.state.membersByAge.totalWithData)*100).toFixed(2) }%</Descriptions.Item>
                            <Descriptions.Item label="36-45 ปี">{ ((this.state.membersByAge.group5/this.state.membersByAge.totalWithData)*100).toFixed(2) }%</Descriptions.Item>
                            <Descriptions.Item label="มากกว่า 45 ปี">{ ((this.state.membersByAge.group6/this.state.membersByAge.totalWithData)*100).toFixed(2) }%</Descriptions.Item> 
                          </Descriptions>

                          <p className="text-muted font-12 mt-3">
                            *หมายเหตุ: ข้อมูลที่แสดงในกราฟคำนวณจากผู้ใช้งานจำนวน
                            <NumberFormat 
                              value={ this.state.membersByAge.totalWithData }
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <span className="font-weight-bold text-dark mx-1">{ value }</span> } 
                            />
                            จากทั้งหมด 
                            <NumberFormat 
                              value={ 
                                parseInt(this.state.membersByAge.group1) + 
                                parseInt(this.state.membersByAge.group2) + 
                                parseInt(this.state.membersByAge.group3) +
                                parseInt(this.state.membersByAge.group4) +
                                parseInt(this.state.membersByAge.group5) +
                                parseInt(this.state.membersByAge.group6) 
                              }
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <span className="font-weight-bold text-dark mx-1">{ value }</span> } 
                            />
                            คน
                          </p>
                        </>
                      }

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-12">
                <div className="card card-small h-100">
                  <div className="card-header border-bottom">
                    <h6 className="m-0">Member Gender</h6>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <Spin 
                        spinning={this.state.memberLoading}
                        indicator={<LoadingOutlined style={{ fontSize: 24, color: '#fa2560' }} spin />}
                        className="my-5"
                      >
                        { this.state.membersByGenderData && (
                          <Pie
                            data={this.state.membersByGenderData}
                            width={250}
                            legend={{position: 'bottom'}}
                            options={{ 
                              maintainAspectRatio: true
                            }}
                          />
                        )}
                      </Spin>
                      
                      { this.state.membersByGender &&
                        <>
                          <Descriptions
                            className="mt-5"
                            title=""
                            bordered
                            size="small"
                            column={{ xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                          >
                            <Descriptions.Item label="ชาย">{ ((this.state.membersByGender.male/this.state.membersByGender.totalWithData)*100).toFixed(2) }%</Descriptions.Item>
                            <Descriptions.Item label="หญิง">{ ((this.state.membersByGender.female/this.state.membersByGender.totalWithData)*100).toFixed(2) }%</Descriptions.Item>
                            <Descriptions.Item label="ไม่ระบุ">{ ((this.state.membersByGender.others/this.state.membersByGender.totalWithData)*100).toFixed(2) }%</Descriptions.Item>
                          </Descriptions>

                          <p className="text-muted font-12 mt-3">
                            *หมายเหตุ: ข้อมูลที่แสดงในกราฟคำนวณจากผู้ใช้งานจำนวน
                            <NumberFormat 
                              value={ this.state.membersByGender.totalWithData }
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <span className="font-weight-bold text-dark mx-1">{ value }</span> } 
                            />
                            จากทั้งหมด 
                            <NumberFormat 
                              value={ 
                                parseInt(this.state.membersByGender.male) + 
                                parseInt(this.state.membersByGender.female) + 
                                parseInt(this.state.membersByGender.others) +
                                parseInt(this.state.membersByGender.empty) 
                              }
                              displayType={'text'} 
                              thousandSeparator={true} 
                              renderText={value => <span className="font-weight-bold text-dark mx-1">{ value }</span> } 
                            />
                            คน
                          </p>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> : null
      }
          
      </React.Fragment>
    )
  }
}

export default Dashboard;
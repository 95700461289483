import React, { Component } from "react";
import { Formik } from "formik";
import * as actions from "../../actions/package.action";
import { connect } from "react-redux";
import moment from "moment";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../utils/HttpClient";
import Swal from "sweetalert2";
import PackageForm from "./packageForm";

class EditPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: ''
    }
  }

  async componentDidMount() {
    let packageId = this.props.match.params.id;
    await this.props.getPackageById(packageId);
    this.setState({data: this.props.packageReducer.item})
  }

  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched
  }) => {
    return (
      <div style={{ padding: 40 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0 text-upper">Create Package</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <PackageForm
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      isDisableField={false}
                    ></PackageForm>

                    <div className="mt-3 text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary pull-right"
                        style={{ marginRight: 10, color: "white", width: 100 }}
                      >
                        บันทึก
                      </button>
                      <button
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (
      this.state.data ?
      <React.Fragment>
        <Formik
          initialValues={{
            packageName: this.state.data.package_name,
            packageDesc: this.state.data.package_desc,
            packageCoin: this.state.data.package_coin,
            packagePrice: this.state.data.package_price,
            channel: this.state.data.channel,
            packageStatus: this.state.data.package_status,
            packageCoinType: this.state.data.package_coin_type,
            coinBonus: this.state.data.coin_bonus,
            inAppPackageId: this.state.data.inapp_package_id,
            promotionPrivileges: this.state.data.promotion_privileges,
          }}
          validate={(values, props) => {
            const errors = {};
          
            if (!values.packageName) {
              errors.packageName = 'กรุณากรอกชื่อแพ็กเกจ';
            } 

            if (!values.packageDesc) {
              errors.packageDesc = 'กรุณากรอกรายละเอียดแพ็กเกจ';
            } 

            if (!values.packageCoin) {
              errors.packageCoin = 'กรุณากรอกจำนวนเหรียญ';
            } 

            if (!values.packagePrice) {
              errors.packagePrice = 'กรุณากรอกราคา';
            } 

            if(values.channel === 'inapp')  {
              if (!values.inAppPackageId) { 
                errors.inAppPackageId = 'กรุณากรอกรหัสแพ็กเกจ';
              } 
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let formData = new FormData();
            let result = localStorage.getItem("user_profile");    
            let username
            if (result) username = JSON.parse(result).user_username;
            
            formData.append("package_name", values.packageName);
            formData.append("package_desc", values.packageDesc);
            formData.append("package_coin", Number(values.packageCoin.toString().replace(/,/g, '')));
            formData.append("package_price", Number(values.packagePrice.toString().replace(/,/g, '')));
            formData.append("channel", values.channel);
            formData.append("package_status", values.packageStatus);
            formData.append("package_coin_type", values.packageCoinType);
            formData.append("coin_bonus", Number(values.coinBonus.toString().replace(/,/g, '')));
            formData.append("inapp_package_id", values.inAppPackageId);
            formData.append("promotion_privileges", values.promotionPrivileges);
            formData.append("updated_datetime",  moment(new Date()).format("YYYY-MM-DD HH:mm:ss"));
            formData.append("updated_by", username);
            
            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            const token = localStorage.getItem("token")
            let response = await httpClient.put(`admin/package/${this.props.match.params.id}`, json, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              },
            });

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 1500,
              });
              setSubmitting(false);
              this.props.history.goBack();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
                timer: 1500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment> : null
    );
  }
}

const mapStateToProps = ({ packageReducer }) => ({ packageReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPackage);


import { httpClient } from "../utils/HttpClient";

import {
    HTTP_BOOK_SALE_SUCCESS,
    HTTP_BOOK_SALE_FETCHING,
    HTTP_BOOK_SALE_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateBookSaleToSuccess = (payload) => ({
  type: HTTP_BOOK_SALE_SUCCESS,
  payload: payload,
});

const setStateReportBookSaleToFetching = () => ({
  type: HTTP_BOOK_SALE_FETCHING,
});

const setStateReportBookSaleToFailed = () => ({
  type: HTTP_BOOK_SALE_FAILED,
});

export const getReportBookSale = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportBookSaleToFetching());
    await doGetReportBookSale(dispatch, value);
  };
};

const doGetReportBookSale = async (dispatch, value) => {
  try {
    let param = {};

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00.00";
      else value.start_date = "";

      if (value.end_date) value.end_date = value.end_date + " 23:59.00";
      else
        value.end = moment(new Date())
          .format("YYYY-MM-DD 23:59.00");

      param = {
        start_date: value.start_date,
        end_date: value.end_date,
        page: value.page,
        writer_id: value.writer_id,
        book_id: value.book_id
      };
    } else {
      param = {
        page: "",
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.REPORT_URL}/editor/book`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    });
    dispatch(setStateBookSaleToSuccess(result.data));
  } catch (err) {
    dispatch(setStateReportBookSaleToFailed());
  }
};

import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_REPORT_ORDER_SUCCESS,
  HTTP_REPORT_ORDER_FETCHING,
  HTTP_REPORT_ORDER_FAILED,
  HTTP_SILVERTRANS_FETCHING,
  HTTP_UPD_ORDER_SUCCESS,
  HTTP_UPD_ORDER_FETCHING,
  HTTP_UPD_ORDER_FAILED,
  HTTP_SUMSILVER_SUCCESS,
  HTTP_SUMSILVER_FETCHING,
  HTTP_SUMSILVER_FAILED,
} from "../constants/report";

import {
  server,
  HTTP_CAMPAIGN_SUCCESS,
} from "../constants";

import moment from "moment";

const setStateReportOrderToSuccess = (payload) => ({
  type: HTTP_REPORT_ORDER_SUCCESS,
  payload: payload,
});

const setStateReportOrderToFetching = () => ({
  type: HTTP_REPORT_ORDER_FETCHING,
});

const setStateReportOrderToFailed = () => ({
  type: HTTP_REPORT_ORDER_FAILED,
});

const setStateOrderToSuccess = (payload) => ({
  type: HTTP_UPD_ORDER_SUCCESS,
  payload: payload,
});

const setStateOrderToFetching = () => ({
  type: HTTP_UPD_ORDER_FETCHING,
});

const setStateOrderToFailed = () => ({
  type: HTTP_UPD_ORDER_FAILED,
});

const setStateSummarySilverToSuccess = (payload) => ({
  type: HTTP_SUMSILVER_SUCCESS,
  payload: payload,
});

const setStateSummarySilverToFetching = () => ({
  type: HTTP_SUMSILVER_FETCHING,
});

const setStateSummarySilverToFailed = () => ({
  type: HTTP_SUMSILVER_FAILED,
});

const setStateCampaignToSuccess = (payload) => ({
  type: HTTP_CAMPAIGN_SUCCESS,
  payload: payload,
});

const setStateSilverTransToFetching = () => ({
  type: HTTP_SILVERTRANS_FETCHING,
});

export const getReportOrder = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportOrderToFetching());
    await doGetReportOrder(dispatch, value);
  };
};

export const getReportSilverCoin = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportOrderToFetching());
    await doGetReportSilverCoin(dispatch, value);
  };
};

export const getReportSummarySilverCoin = (value) => {
  return async (dispatch) => {
    dispatch(setStateSummarySilverToFetching());
    await doGetReportSummarySilver(dispatch, value);
  };
};

const doGetReportOrder = async (dispatch, value) => {
  let param = {};

  if (value) {
    if (value.start) value.start = value.start + " 00:00.00";
    else value.start = "";

    if (value.end) value.end = value.end + " 23:59.00";
    else
      value.end = moment(new Date())
        .format("YYYY-MM-DD 23:59.00");

    param = {
      start_date: value.start,
      end_date: value.end,
      page: value.page,
      payment_type: value.payment_type,
      package_coin_type: value.package_coin_type,
      order_status: value.order_status,
      member_name: value.member_name,
      member_email: value.member_email,
    };
  }
  else{
    param = {
      page: '',
    };
  }

  const token = localStorage.getItem("token");

  await httpClient
    .get(`${server.REPORT_URL}/order`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    })
    .then((result) => {
      dispatch(setStateReportOrderToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateReportOrderToFailed());
    });
};

const doGetReportSilverCoin = async (dispatch, value) => {
  let param = {};

  if (value) {
    if (value.start) value.start = value.start + " 00:00.00";
    else value.start = "";

    if (value.end) value.end = value.end + " 23:59.00";
    else
      value.end = moment(new Date())
        .format("YYYY-MM-DD 23:59.00");

    param = {
      start_date: value.start,
      end_date: value.end,
      page: value.page,
      payment_type: value.payment_type,
      order_status: value.order_status,
      member_name: value.member_name,
      member_email: value.member_email,
    };
  }
  else{
    param = {
      page: '',
    };
  }

  const token = localStorage.getItem("token");

  await httpClient
    .get(`${server.REPORT_URL}/silver_coin` , {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    })
    .then((result) => {
      dispatch(setStateReportOrderToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateReportOrderToFailed());
    });
};

const doGetReportSummarySilver = (dispatch, value) => {
  let param = {};

  if (value) {
    if (value.start) value.start = value.start + " 00:00.00";
    else value.start = "";

    if (value.end) value.end = value.end + " 23:59.00";
    else
      value.end = moment(new Date())
        .format("YYYY-MM-DD 23:59.00");

    param = {
      start_date: value.start,
      end_date: value.end,
      page: value.page,
      payment_type: value.payment_type,
      order_status: value.order_status,
      member_name: value.member_name,
      member_email: value.member_email,
    };
  }

  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.SUMMARY_SILVER_URL}` , {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    })
    .then((result) => {
      dispatch(setStateSummarySilverToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateSummarySilverToFailed());
    });
};

export const deleteOrder = (order_id, updated_by) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());
    let formData = new FormData();
    formData.append("updated_by", updated_by);

    var dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    formData.append("updated_datetime", dateTime);

    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });

    var json = JSON.stringify(object);
    const token = localStorage.getItem("token");

    const response = await httpClient.patch(
      server.ORDER_URL + "/" + `${order_id}`,
      json,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 201 || response.status === 200) {
      dispatch(setStateOrderToSuccess(response.data));
      await doGetReportOrder(dispatch);
      alert("Delete Complete");
    }
  };
};

export const approveOrder = (item, updated_by) => {
  return async (dispatch) => {
    dispatch(setStateOrderToFetching());

    let formData = new FormData();
    formData.append("updated_by", updated_by);
    var dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    formData.append("updated_datetime", dateTime);

    var object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    var json = JSON.stringify(object);

    const token = localStorage.getItem("token");

    const response = await httpClient.patch(
      `${server.ORDER_URL}/approve` + "/" + `${item.order_id}`,
      json,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ); //update table order

    if (response.status === 201 || response.status === 200) {
      
      if (item.package_coin_type.toLowerCase() === "silver") {
        let formData = new FormData();
        const token = localStorage.getItem("token");

        httpClient
          .get(`${server.CAMPAIGN_URL}/purcahse_expired`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            let resultCampaign = result.data;
            let expireDate = resultCampaign.map((item) => {
              return item.member_silver_coin_expire;
            });

            if (expireDate) {
              formData.append("expired_date", expireDate);
            }

            formData.append("member_id", item.member_id);
            formData.append("campaign_id", 0); // 0 equal purchase
            formData.append("coin_received", item.coin);
            formData.append("coin_remains", item.coin);

            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;

            let dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

            formData.append("created_by", username);
            formData.append("created_datetime", dateTime);

            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            //insert tbl_silver_coin
            httpClient
              .post(`${server.REPORT_URL}/add_campaign_trans`, json, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                dispatch(setStateOrderToSuccess(response.data));
                if (response.status === 201 || response.status === 200) {
                  alert("Approve Complete");
                  //doGetReportOrder(dispatch);
                }
              })
              .catch((error) => {
                alert(JSON.stringify(error));
                dispatch(setStateOrderToFailed());
              });
          });
      } else {
        //update coin table member
        let formData = new FormData();
        formData.append("member_coin", item.member_coin + item.coin); //เอาของเดิมที่มีอยู่บวกเข้าไป
        formData.append("updated_by", updated_by);
        formData.append("updated_datetime", dateTime);

        var object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        var json = JSON.stringify(object);

        //update table member
        httpClient
          .patch(
            `${server.MEMBER_URL}/coin` + "/" + `${item.member_id}`,
            json,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            dispatch(setStateOrderToSuccess(response.data));
            if (response.status === 201 || response.status === 200) {
              alert("Approve Complete");
              //doGetReportOrder(dispatch);
            }
          })
          .catch((error) => {
            alert(JSON.stringify(error));
            dispatch(setStateOrderToFailed());
          });
      }
    }
  };
};

export const getCampaignTransaction = (item) => {
  return async (dispatch) => {
    dispatch(setStateSilverTransToFetching());
    await doGetCampaignTransaction(dispatch, item);
  };
};

const doGetCampaignTransaction = (dispatch, row) => {
  return new Promise(async (resolve) => {
    //Get all active campaigns that have amount as a condition
    const token = localStorage.getItem("token");

    httpClient.get(`${server.CAMPAIGN_URL}/goal`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((result) => {
      dispatch(setStateCampaignToSuccess(result.data));

      let resultCampaignList = result.data;

      //Create an array of campaign id for query
      let campaignIdArray = resultCampaignList.map((item) => {
        return item.campaign_id;
      });

      //Total silver coin that user will get from all campaign inclucing the repeated campaign
      let totalCoinReceived = 0;

      //Name of the finished campaign
      let successCampaignName = "";

      let campaignIdParams = new Array(campaignIdArray.length)
        .fill("?")
        .join(",");

      let dataFilter = "";

      dataFilter = "?member_id=" + row.member_id;

      if (campaignIdArray.length > 0) {
        httpClient
          .get(`${server.REPORT_URL}/get_campaign_trans` + dataFilter,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            let campaignTransactionResult = result.data;

            let silverCoinCampaignInsertValues = [];
            let isCampaignSuccess = false;

            for (const campaign of resultCampaignList) {
              let campaignGoal = campaign.amount_goal;
              let campaignCoin = campaign.campaign_coin;
              let campaignSuccessCount = 0;
              let nextAchievement = 0;
              let exceedAchievement = 0; //Remaining amount

              let transactions = campaignTransactionResult.filter(function(
                transaction
              ) {
                return transaction.campaign_id === campaign.campaign_id;
              });

              let currentAchievement =
                transactions.length > 0 ? transactions[0].achievement : 0;

              //Check number of times user finish the campaign
              transactions.map((transaction) => {
                if (transaction.is_success === "Y") {
                  campaignSuccessCount++;
                }
              });

              if (campaignGoal > 0) {
                if (
                  currentAchievement <= campaignGoal &&
                  (campaignSuccessCount === 0 ||
                    (campaign.campaign_repeat === "Y" &&
                      campaignSuccessCount < campaign.number_repeat) ||
                    campaign.number_repeat === 0)
                ) {
                  if (currentAchievement == campaignGoal)
                    currentAchievement = 0;

                  nextAchievement =
                    parseInt(currentAchievement) + parseInt(row.package_price);

                  if (nextAchievement >= campaignGoal) {
                    isCampaignSuccess = true;

                    if (successCampaignName != "") {
                      successCampaignName += "//";
                    }

                    successCampaignName += campaign.campaign_name;
                    exceedAchievement = nextAchievement - campaignGoal;
                    nextAchievement = campaignGoal;
                    totalCoinReceived += campaignCoin;
                  }

                  silverCoinCampaignInsertValues.push([
                    row.member_id,
                    campaign.campaign_id,
                    isCampaignSuccess ? campaignCoin : 0,
                    isCampaignSuccess ? campaignCoin : 0,
                    isCampaignSuccess
                      ? campaign.member_silver_coin_expire || null
                      : null,
                    nextAchievement,
                    isCampaignSuccess ? "Y" : "N",
                    moment().format("YYYY-MM-DD HH:mm:ss"),
                    "TOPUP",
                  ]);

                  let secondAdd = 0;

                  //if the remaining amount exceed the goal then keep adding silver coin record until it's less than campaign's goal
                  if (campaign.campaign_repeat === "Y" && isCampaignSuccess) {
                    while (exceedAchievement >= campaignGoal) {
                      secondAdd++;
                      campaignSuccessCount++;
                      nextAchievement = campaignGoal;

                      if (
                        campaignSuccessCount < campaign.number_repeat ||
                        campaign.number_repeat === 0
                      ) {
                        silverCoinCampaignInsertValues.push([
                          row.member_id,
                          campaign.campaign_id,
                          campaignCoin,
                          campaignCoin,
                          campaign.member_silver_coin_expire,
                          nextAchievement,
                          "Y",
                          moment()
                            .add(secondAdd, "second")
                            .format("YYYY-MM-DD HH:mm:ss"),
                          "TOPUP",
                        ]);

                        totalCoinReceived += campaignCoin;
                        exceedAchievement -= campaignGoal;
                      }
                    }

                    //The rest of the amount record will be added if user hasn't reach the limit
                    if (exceedAchievement > 0) {
                      secondAdd++;

                      silverCoinCampaignInsertValues.push([
                        row.member_id,
                        campaign.campaign_id,
                        0,
                        0,
                        null,
                        exceedAchievement,
                        "N",
                        moment()
                          .add(secondAdd, "second")
                          .format("YYYY-MM-DD HH:mm:ss"),
                        "TOPUP",
                      ]);
                    }
                  }
                }
              }
            }
            //insert table silver_coin
            if (silverCoinCampaignInsertValues.length > 0) {
              var i;
              for (i = 0; i < silverCoinCampaignInsertValues.length; i++) {
                let formData = new FormData();
                formData.append(
                  "member_id",
                  silverCoinCampaignInsertValues[i][0]
                );
                formData.append(
                  "campaign_id",
                  silverCoinCampaignInsertValues[i][1]
                );
                formData.append(
                  "coin_received",
                  Number(silverCoinCampaignInsertValues[i][2])
                );
                formData.append(
                  "coin_remains",
                  Number(silverCoinCampaignInsertValues[i][3])
                );
                if (silverCoinCampaignInsertValues[i][4])
                  formData.append(
                    "expired_date",
                    silverCoinCampaignInsertValues[i][4]
                  );

                formData.append(
                  "achievement",
                  Number(silverCoinCampaignInsertValues[i][5])
                );

                formData.append(
                  "is_success",
                  silverCoinCampaignInsertValues[i][6]
                );

                let username = "";
                var result = localStorage.getItem("user_profile");
                if (result) username = JSON.parse(result).user_username;

                let dateTime = new Date();
                dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

                formData.append("created_by", username);
                formData.append("created_datetime", dateTime);

                var object = {};
                formData.forEach((value, key) => {
                  object[key] = value;
                });
                var json = JSON.stringify(object);

                let response = httpClient.post(
                  `${server.REPORT_URL}/add_campaign_trans`,
                  json,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                
              }
            }
          });
        resolve({
          isCampaignSuccess: totalCoinReceived > 0 ? true : false,
          coinReceived: totalCoinReceived,
          successCampaignName: successCampaignName,
        });
      }
    });
  });
};

import { httpClient } from "../utils/HttpClient";

import {
    HTTP_REPORT_EDITOR_SUCCESS,
    HTTP_REPORT_EDITOR_FETCHING,
    HTTP_REPORT_EDITOR_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateReportEditorToSuccess = (payload) => ({
  type: HTTP_REPORT_EDITOR_SUCCESS,
  payload: payload,
});

const setStateReportEditorToFetching = () => ({
  type: HTTP_REPORT_EDITOR_FETCHING,
});

const setStateReportEditorToFailed = () => ({
  type: HTTP_REPORT_EDITOR_FAILED,
});

export const getReportEditor = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportEditorToFetching());
    await doGetReportEditor(dispatch, value);
  };
};

const doGetReportEditor = async (dispatch, value) => {
  try {
    let param = {};

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00.00";
      else value.start_date = "";

      if (value.end_date) value.end_date = value.end_date + " 23:59.00";
      else
        value.end = moment(new Date())
          .format("YYYY-MM-DD 23:59.00");

      param = {
        start_date: value.start_date,
        end_date: value.end_date,
        page: value.page,
        publisher_id: value.publisher_id,
        editor_id: value.editor_id
      };
    } else {
      param = {
        page: "",
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.REPORT_URL}/editor`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    });
    dispatch(setStateReportEditorToSuccess(result.data));
  } catch (err) {
    dispatch(setStateReportEditorToFailed());
  }
};

import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_BANNER_VIEW_SUCCESS,
  HTTP_BANNER_VIEW_FETCHING,
  HTTP_BANNER_VIEW_FAILED,
} from "../constants/banner";

import { server } from "../constants";
import moment from "moment";

const setStateBannerViewsToSuccess = (payload) => ({
  type: HTTP_BANNER_VIEW_SUCCESS,
  payload: payload,
});

const setStateBannerViewsToFetching = () => ({
  type: HTTP_BANNER_VIEW_FETCHING,
});

const setStateBannerViewsToFailed = () => ({
  type: HTTP_BANNER_VIEW_FAILED,
});

export const getAllBannerView = (value) => {
  return async (dispatch) => {
    dispatch(setStateBannerViewsToFetching());
    await doGetBannerView(dispatch, value);
  };
};

const doGetBannerView = async (dispatch, value) => {
  try {
    let param = {};

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00.00";
      else value.start_date = "";

      if (value.end_date) value.end_date = value.end_date + " 23:59.00";
      else
        value.end = moment(new Date())
          .format("YYYY-MM-DD 23:59.00");

      param = {
        start_date: value.start_date,
        end_date: value.end_date,
        page: value.page,
        banner_type: value.banner_type,
        banner_name: value.banner_name
      };
    } else {
      param = {
        page: "",
      };
    }
    
    const token = localStorage.getItem("token");
    const result = await httpClient.get(`${server.BANNER_URL}/view`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    });

    dispatch(setStateBannerViewsToSuccess(result.data));
  } catch (err) {
    dispatch(setStateBannerViewsToFailed());
  }
};

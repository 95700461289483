import {
  HTTP_PACKAGES_FETCHING,
  HTTP_PACKAGES_SUCCESS,
  HTTP_PACKAGES_FAILED,
  HTTP_SINGLE_PACKAGE_FETCHING,
  HTTP_SINGLE_PACKAGE_SUCCESS,
  HTTP_SINGLE_PACKAGE_FAILED,
} from "../constants/package";

const initialState = {
  packages: [],
  item: null,
  isFetching: false,
  isError: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_PACKAGES_SUCCESS:
      return { ...state, packages: payload, isFetching: false, isError: false };
    case HTTP_PACKAGES_FETCHING:
      return { ...state, packages: [], isFetching: false, isError: true };
    case HTTP_PACKAGES_FAILED:
      return { ...state, packages: [], isFetching: true, isError: false };
    case HTTP_SINGLE_PACKAGE_SUCCESS:
      return { ...state, item: payload, isFetching: false, isError: false };
    case HTTP_SINGLE_PACKAGE_FETCHING:
      return { ...state, item: null, isFetching: false, isError: true };
    case HTTP_SINGLE_PACKAGE_FAILED:
      return { ...state, item: null, isFetching: true, isError: false };
    default:
      return state;
  }
};

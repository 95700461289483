import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { Row, Col, FormInput, Card, CardHeader, CardBody } from "shards-react";
import {
  Table,
  Tag,
  Button,
  Skeleton,
  Modal,
  message,
  DatePicker,
  Input,
  Select,
  Spin,
  AutoComplete,
} from "antd";
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import XLSX from "xlsx";

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

export default function ReportBooksRank() {
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [bookCategory, setBookCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [subCategorySelect, setSubCategorySelect] = useState([]);
  const [dateStart, setDateStart] = useState(
    moment()
      .subtract(30, "day")
      .format("YYYY-MM-DD 00:00:00")
  );
  const [dateEnd, setDateEnd] = useState(
    moment().format("YYYY-MM-DD 23:59:59")
  );
  const [searchBook, setSearchBook] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [searchBookType, setSearchBookType] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingExportChpater, setLoadingExportChapter] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [loadingChapter, setLoadingChapter] = useState(false);
  const [page, setPage] = useState(0);
  const [bookTitleOptions, setBookTitleOptions] = useState([]);
  const [filter, setFilter] = useState({
    start_date: moment()
      .subtract(30, "day")
      .format("YYYY-MM-DD 00:00:00"),
    end_date: moment().format("YYYY-MM-DD 23:59:59"),
    category_id: "",
    sub_category_id: "",
    book_title: "",
    book_type: "",
    page: 0,
    sortingColumn: "",
    sortingOrder: "",
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [selectBookTitle, setSelectBookTitle] = useState("");

  useEffect(() => {
    getReportBookRank();
  }, [filter]);

  useEffect(() => {
    localStorage.removeItem("topup_current_page");
    localStorage.removeItem("purchase_current_page");
    getBookCategory();
    getAllSubCategory();
  }, []);

  useEffect(() => {
    handleBookNameSearch("");
  }, [0]);

  function formatAmount(amount) {
    if (amount) {
      return amount;
    } else return 0;
  }

  function formatNumber(number) {
    if (number) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(number);
    } else return 0;
  }

  const getBookCategory = async () => {
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`admin/category`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBookCategory(result.data);
    } catch (e) {
    }
  };

  const getAllSubCategory = async () => {
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`admin/sub_category`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSubCategory(result.data);
    } catch (e) {
    }
  };

  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }

  async function exportBookData() {
    setLoadingExport(true);
    filter.page = "";
    setFilter(filter);

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`admin/report/books_rank`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: null,
        },
      });
      var data = getUnique(result.data.book_rank, "book_title");
      //var data = result.data.book_rank;

      let report = data.map((item, idx) => {
        return [
          item.book_title,
          Number(item.sum_price),
          Number(item.gold_price),
          Number(item.silver_price),
          Number(item.audio_gold_price),
          Number(item.audio_silver_price),
          Number(item.count_book_shelf),
          Number(item.count_member_buy_whole_book),
          Number(item.count_member),
          Number(item.total_view),
        ];
      });

      report = [
        [
          "ชื่อหนังสือ",
          "ทั้งหมด",
          "เหรียญทอง(หนังสือ)",
          "เหรียญเงิน(หนังสือ)",
          "เหรียญทอง(หนังสือเสียง)",
          "เหรียญเงิน(หนังสือเสียง)",
          "เพิ่มเข้าชั้นหนังสือ",
          "จำนวน Member ที่ซื้อทั้งเล่ม",
          "จำนวน Member ที่ซื้อ",
          "จำนวนครั้งที่เปิดอ่าน",
        ],
        ...report,
      ];

      if (report) {
        await downloadExportData(report);
        setLoadingExport(false);
      }
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setLoadingExport(false);
    }
  }

  async function exportChapterData() {
    setLoadingExportChapter(true);

    try {
      let report = chapters.map((item, idx) => {
        return [
          selectBookTitle,
          item.chaptor_title,
          Number(item.total_view),
          Number(item.sum_gold),
          Number(item.sum_silver),
          Number(item.sum_botread_gold),
          Number(item.sum_botread_silver),
          Number(item.count_member),
        ];
      });

      report = [
        [
          "ชื่อหนังสือ",
          "ชื่อตอน",
          "จำนวนครั้งที่เปิดอ่าน",
          "เหรียญทอง",
          "เหรียญเงิน",
          "เหรียญทอง(หนังสือเสียง)",
          "เหรียญเงิน(หนังสือเสียง)",
          "จำนวน Member ที่ซื้อ",
        ],
        ...report,
      ];

      if (report) {
        await downloadExportChapterData(report);
        setLoadingExportChapter(false);
      }
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setLoadingExportChapter(false);
    }
  }

  async function downloadExportChapterData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["ReportChapter"]);
    XLSX.writeFile(workBook, `ReportChapter.xlsx`);
  }

  async function downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["ReportBookRank"]);
    XLSX.writeFile(workBook, `ReportBookRank.xlsx`);
  }

  const getReportBookRank = async () => {
    setHistoryLoading(true);

    setExpandedRowKeys([]);

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`admin/report/books_rank`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
      });

      var data = getUnique(result.data.book_rank, "book_title");

      const tableRows = data.map((item, index) => {
        return {
          key: item.book_id.toString(),
          book_title: item.book_title,
          sum_price: item.sum_price,
          gold_price: formatAmount(item.gold_price),
          silver_price: formatAmount(item.silver_price),
          audio_gold_price: formatAmount(item.audio_gold_price),
          audio_silver_price: formatAmount(item.audio_silver_price),
          count_book_shelf: formatAmount(item.count_book_shelf),
          count_member_buy_whole_book: formatAmount(
            item.count_member_buy_whole_book
          ),
          count_member: formatAmount(item.count_member),
          total_view: item.total_view,
        };
      });

      setTransactions(tableRows);
      setPagination({
        ...pagination,
        total: result.data.total,
      });
      setHistoryLoading(false);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setHistoryLoading(false);
    }
  };

  const onChangeSearchBook = (e) => {
    const searchBook = e.target.value;
    setFilter({
      ...filter,
      book_title: searchBook,
    });
  };

  const onChangeSearchCategory = async (category, e) => {
    try {
      setPagination({
        ...pagination,
        current: 1,
      });
      await setSearchSubCategory("");
      await setSubCategorySelect([]);

      setSearchCategory(category);

      setFilter({
        ...filter,
        page: 0,
        category_id: category,
        sub_category_id: "",
      });

      updateSubCategoryList(category);
    } catch (e) {
    }
  };

  const onChangeSearchSubCategory = (sub_category_id, e) => {
    setPagination({
      ...pagination,
      current: 1,
    });
    setSearchSubCategory(sub_category_id);
    setFilter({
      ...filter,
      page: 0,
      sub_category_id,
    });
  };

  const onChangeSearchBookType = (book_type, e) => {
    setSearchBookType(book_type);
    setPagination({
      ...pagination,
      current: 1,
    });
    setFilter({
      ...filter,
      page: 0,
      book_type,
    });
  };

  const handleBookNameSelect = (title) => {
    if (!title) title = "";
    setPagination({
      ...pagination,
      current: 1,
    });
    setFilter({
      ...filter,
      page: 0,
      book_title: title,
    });
  };

  const handleBookNameSearch = async (name) => {
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get("admin/book/search/title", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          book_title: name,
        },
      });

      setBookTitleOptions(
        result.data.map((item) => {
          return {
            label: item.book_title,
            value: item.book_title,
          };
        })
      );
    } catch (e) {
      message.open({ content: "โหลดข้อมูลชื่อหนังสือไม่สำเร็จ" });
    }
  };

  async function updateSubCategoryList(inputValue) {
    let subCategories = [];

    if (inputValue) {
      for (let i = 0; i < subCategory.length; i++) {
        if (subCategory[i].category_id === inputValue) {
          subCategories.push({
            label: subCategory[i].sub_category_name,
            value: subCategory[i].sub_category_id,
          });
        }
      }
      await setSubCategorySelect(subCategories);
    }
  }

  const onDateChange = (e) => {
    let dateStart = "";
    let dateEnd = "";
    if (e) {
      dateStart = moment(e[0]).format("YYYY-MM-DD 00:00:00");
      dateEnd = moment(e[1]).format("YYYY-MM-DD 23:59:59");

      if (dateStart) setDateStart(dateStart);
      else setDateStart(moment(new Date()).format("2009-01-01 23:59.00"));

      setDateEnd(dateEnd);

      setPagination({
        ...pagination,
        current: 1,
      });

      setFilter({
        ...filter,
        page: 0,
        start_date: dateStart,
        end_date: dateEnd,
      });
    } else {
      setDateStart(null);
      setDateEnd(null);

      setPagination({
        ...pagination,
        current: 1,
      });

      if (dateStart) {
        setFilter({
          ...filter,
          page: 0,
          start_date: null,
          end_date: null,
        });
      }
      else{
        setFilter({
          ...filter,
          page: 0,
          start_date: moment(new Date()).format('2008-01-01 23:59.00'),
          end_date: null,
        });
      }
    }
  };

  function handleTableChange(pagination, filters, sorter) {
    setPagination(pagination);

    let sortingColumn = null;
    let sortingOrder = null;

    if (sorter.order) {
      sortingColumn = sorter.field;
      sortingOrder = sorter.order;
    }

    setFilter({
      ...filter,
      page: pagination.current - 1,
      sortingColumn: sortingColumn || "",
      sortingOrder: sortingOrder || "",
    });
  }

  const handleRowExpand = (expanded, record) => {
    const currentExpandedRows = expandedRowKeys;

    if (currentExpandedRows.includes(record.key)) {
      const index = currentExpandedRows.indexOf(record.key);
      currentExpandedRows.splice(index, 1);
    } else {
      currentExpandedRows.push(record.key);
    }

    setExpandedRowKeys(currentExpandedRows);

    if (expanded) {
      getReportEachChapter(record.book_title);
    }
  };

  const getReportEachChapter = async (searchBook) => {
    setSelectBookTitle(searchBook);
    setLoadingChapter(true);

    const dataFilter = `?&book_title=${searchBook}?&start_date=${filter.start_date}?&enddate=${filter.end_date}`;
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `admin/report/each_chapter${dataFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const chapter = result.data.chapter.map((item, index) => {
        return {
          key: item.chaptor_id,
          chaptor_title: item.chaptor_title,
          total_view: item.total_view,
          sum_gold: item.sum_gold,
          sum_silver: item.sum_silver,
          sum_botread_gold: item.sum_botread_gold,
          sum_botread_silver: item.sum_botread_silver,
          count_member: item.count_member,
        };
      });

      setChapters(chapter);
      setLoadingChapter(false);
    } catch (e) {
      setLoadingChapter(false);
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
    }
  };

  const expandedRowRender = (e) => {
    return (
      <>
        <div className="mt-1 d-flex align-items-center">
          <div className="flex-fill"></div>
          <Button className="btn-primary px-3 mb-2" onClick={exportChapterData}>
            Export ยอดขายแต่ละบท
          </Button>
          <Spin spinning={loadingExport} />
        </div>
        <Table
          className="mt-1"
          dataSource={chapters}
          pagination={true}
          loading={loadingChapter}
        >
          <Column
            title="ชื่อตอน"
            dataIndex="chaptor_title"
            key="chaptor_title"
            align="center"
          />
          <Column
            title="จำนวนครั้งที่เปิดอ่าน"
            dataIndex="total_view"
            key="total_view"
            align="center"
            render={(value) => {
              return formatNumber(value);
            }}
          />
          <Column
            title="เหรียญทอง"
            dataIndex="sum_gold"
            key="sum_gold"
            render={(value) => {
              return value.toLocaleString();
            }}
            align="center"
          />
          <Column
            title="เหรียญเงิน"
            dataIndex="sum_silver"
            key="sum_silver"
            render={(value) => {
              return value.toLocaleString();
            }}
            align="center"
          />
          <Column
            title="เหรียญทอง (หนังสือเสียง)"
            dataIndex="sum_botread_gold"
            key="sum_botread_gold"
            render={(value) => {
              return value.toLocaleString();
            }}
            align="center"
          />
          <Column
            title="เหรียญเงิน (หนังสือเสียง)"
            dataIndex="sum_botread_silver"
            key="sum_botread_silver"
            render={(value) => {
              return value.toLocaleString();
            }}
            align="center"
          />

          <Column
            title="จำนวน Member ที่ซื้อ"
            dataIndex="count_member"
            key="count_member"
            render={(value) => {
              return formatNumber(value);
            }}
            align="center"
          />
        </Table>
      </>
    );
  };

  return (
    <div style={{ padding: 10 }}>
      <Card small>
        <CardHeader className="border-bottom">
          <h6 className="m-0">ยอดการซื้อหนังสือ</h6>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-sm-4">
              <RangePicker
                className="w-100"
                placeholder={["เริ่มต้น", "สิ้นสุด"]}
                format="DD/MM/YYYY"
                disabledDate={false}
                onChange={onDateChange}
                value={[
                  dateStart ? moment(dateStart) : null,
                  dateEnd ? moment(dateEnd) : null,
                ]}
              />
            </div>

            <div className="col-sm-4">
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) => handleBookNameSelect(value, event)}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="">ค้นหาด้วย ชื่อหนังสือทั้งหมด</Option>
                {bookTitleOptions &&
                  bookTitleOptions.map((item, index) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
              </Select>
              {/* <AutoComplete
                className="w-100"
                placeholder="ชื่อหนังสือ"
                onSearch={handleBookNameSearch}
                onSelect={handleBookNameSelect}
                options={bookTitleOptions}
                allowClear={true}
              /> */}
            </div>
            <div className="col-sm-4">
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchBookType(value, event)
                }
              >
                <Option value="">ประเภทหนังสือทั้งหมด</Option>
                <Option value="book">ประเภทหนังสือ</Option>
                <Option value="audio">ประเภทหนังสือเสียง</Option>
                <Option value="ugc">UGC</Option>
              </Select>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-4">
              <Select
                defaultValue=""
                className="w-100"
                onSelect={(value, event) =>
                  onChangeSearchCategory(value, event)
                }
              >
                <Option value="">เลือกหมวดหมู่หลัก</Option>
                {bookCategory &&
                  bookCategory.map((item, index) => {
                    return (
                      <Option value={item.category_id} key={item.category_id}>
                        {item.category_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div className="col-sm-4">
              <Select
                defaultValue=""
                className="w-100"
                value={searchSubCategory}
                onSelect={(value, event) =>
                  onChangeSearchSubCategory(value, event)
                }
              >
                <Option value="">เลือกหมวดหมู่ย่อย</Option>
                {subCategorySelect &&
                  subCategorySelect.map((item, index) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.label}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="mt-3 d-flex align-items-center">
            <div className="flex-fill">
              จำนวนทั้งหมด{" "}
              <b className="mx-1">
                {pagination.total ? pagination.total.toLocaleString() : 0}
              </b>{" "}
              เล่ม
            </div>
            <Button className="btn-primary px-3" onClick={exportBookData}>
              Export Book
            </Button>
            <Spin spinning={loadingExport} />
          </div>

          <div className="mt-2">
            <Table
              dataSource={transactions}
              loading={historyLoading}
              onChange={handleTableChange}
              expandable={{
                expandedRowRender,
                expandedRowKeys: expandedRowKeys,
                onExpand: handleRowExpand,
              }}
              pagination={pagination}
              scroll={{ x: 400 }}
            >
              <Column
                title="ชื่อหนังสือ"
                dataIndex="book_title"
                key="book_title"
                align="center"
              />
              <Column
                title="ทั้งหมด"
                dataIndex="sum_price"
                key="sum_price"
                sorter={true}
                render={(value) => {
                  return value.toLocaleString();
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="เหรียญทอง (หนังสือ)"
                dataIndex="gold_price"
                key="gold_price"
                sorter={true}
                render={(value) => {
                  return value.toLocaleString();
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="เหรียญเงิน (หนังสือ)"
                dataIndex="silver_price"
                key="silver_price"
                sorter={true}
                render={(value) => {
                  return value.toLocaleString();
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="เหรียญทอง (หนังสือเสียง)"
                dataIndex="audio_gold_price"
                key="audio_gold_price"
                sorter={true}
                render={(value) => {
                  return value.toLocaleString();
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="เหรียญเงิน (หนังสือเสียง)"
                dataIndex="audio_silver_price"
                key="audio_silver_price"
                sorter={true}
                render={(value) => {
                  return value.toLocaleString();
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="เพิ่มเข้าชั้นหนังสือ"
                dataIndex="count_book_shelf"
                key="count_book_shelf"
                sorter={true}
                render={(value) => {
                  return formatNumber(value);
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="จำนวน Member ซื้อทั้งเล่ม"
                dataIndex="count_member_buy_whole_book"
                key="count_member_buy_whole_book"
                sorter={true}
                render={(value) => {
                  return formatNumber(value);
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="จำนวน Member ที่ซื้อ"
                dataIndex="count_member"
                key="count_member"
                sorter={true}
                render={(value) => {
                  return formatNumber(value);
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
              <Column
                title="จำนวนครั้งที่เปิดอ่าน"
                dataIndex="total_view"
                key="total_view"
                sorter={true}
                render={(value) => {
                  return formatNumber(value);
                }}
                align="center"
                sortDirections={["descend", "ascend", ""]}
              />
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

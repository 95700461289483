import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import * as actions from "../../../actions/user.action";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import UserRoleForm from "./userRoleForm";

class EditRole extends Component {
  constructor(props) {
    super(props);

    this.state = {
      role: null
    }
  }

  async componentDidMount() {
    await this.props.getRoleById(this.props.match.params.id);
    const role = this.props.userReducer.role
    
    if(role) {
      this.setState({ role: role })
    }
  }

  showForm = ({ values, handleChange, handleSubmit, isSubmitting }) => {
    return (
      <div style={{ padding: 30 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD ROLE</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <UserRoleForm
                      values={values}
                      handleChange={handleChange}
                      isDisableField={false}
                    ></UserRoleForm>

                    <div className="mt-3 text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary pull-right"
                        style={{ marginRight: 10, color: "white", width: 100 }}
                      >
                        บันทึก
                      </button>
                      <a
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ยกเลิก
                      </a>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        { this.state.role && (<Formik
          initialValues={{
            role_id: this.state.role.role_id,
            role_name: this.state.role.role_name,
            dashboard: this.state.role.dashboard === 'Y' ? true : false,
            campaign: this.state.role.campaign === 'Y' ? true : false,
            package: this.state.role.package === 'Y' ? true : false,
            push_notification: this.state.role.push_notification === 'Y' ? true : false,
            banner: this.state.role.banner === 'Y' ? true : false,
            book: this.state.role.book === 'Y' ? true : false,
            category: this.state.role.category === 'Y' ? true : false,
            comment: this.state.role.comment === 'Y' ? true : false,
            member: this.state.role.member === 'Y' ? true : false,
            playlist: this.state.role.playlist === 'Y' ? true : false,
            writer: this.state.role.writer === 'Y' ? true : false,
            user: this.state.role.user === 'Y' ? true : false,
            marketing_report: this.state.role.marketing_report === 'Y' ? true : false,
            accountant_report: this.state.role.accountant_report === 'Y' ? true : false,
            writer_recommend: this.state.role.writer_recommend === 'Y' ? true : false,
            writer_income_ratio: this.state.role.writer_income_ratio === 'Y' ? true : false,
          }}
          onSubmit={async (values, { setSubmitting }) => {

            const params = {
              role_id: values.role_id,
              role_name: values.role_name,
              dashboard: values.dashboard ? 'Y' : 'N',
              campaign: values.campaign ? 'Y' : 'N',
              package: values.package ? 'Y' : 'N',
              push_notification: values.push_notification ? 'Y' : 'N',
              banner: values.banner ? 'Y' : 'N',
              book: values.book ? 'Y' : 'N',
              category: values.category ? 'Y' : 'N',
              comment: values.comment ? 'Y' : 'N',
              member: values.member ? 'Y' : 'N',
              playlist: values.playlist ? 'Y' : 'N',
              writer: values.writer ? 'Y' : 'N',
              user: values.user ? 'Y' : 'N',
              marketing_report: values.marketing_report ? 'Y' : 'N',
              accountant_report: values.accountant_report ? 'Y' : 'N',
              writer_recommend: values.writer_recommend ? 'Y' : 'N',
              writer_income_ratio: values.writer_income_ratio ? 'Y' : 'N',
            }
      
            
            const token = localStorage.getItem("token");
            const response = await httpClient.patch('admin/user-role', 
              JSON.stringify(params), {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "บันทึกข้อมูลเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 3000,
              });
              setSubmitting(false);
              this.props.history.push(`/role`);
            } else {
              Swal.fire({
                icon: "error",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                timer: 3000,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>) }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer}) => ({ userReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditRole);

import {
    HTTP_SUMSILVER_SUCCESS,
    HTTP_SUMSILVER_FETCHING,
    HTTP_SUMSILVER_FAILED,
  } from "../constants/report";
  
  const initialState = {
    resultSummary: null,
    isFetchingSummary: false,
    isErrorSummary: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_SUMSILVER_SUCCESS:        
        return { ...state, resultSummary: payload, isFetchingSummary: false, isErrorSummary: false };
      case HTTP_SUMSILVER_FAILED:
        return { ...state, resultSummary: null, isFetchingSummary: false, isErrorSummary: true };
      case HTTP_SUMSILVER_FETCHING:
        return { ...state, resultSummary: null, isFetchingSummary: true, isErrorSummary: false };
      default:
        return state;
    }
  };
  
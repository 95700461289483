import React, { Component, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { Row, Col, FormInput, Card, CardHeader, CardBody } from "shards-react";
import {
  Table,
  Tag,
  Button,
  Skeleton,
  Modal,
  message,
  DatePicker,
  Input,
  Spin,
} from "antd";
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import XLSX from "xlsx";

const { RangePicker } = DatePicker;
const { Column } = Table;

export default function ReportMemberBooks() {
  const history = useHistory();
  const [historyLoading, setHistoryLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const { member_id, member_name, member_page, from_page } = useParams();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [buyAllBook, setBuyAllBook] = useState([]);

  useEffect(() => {
    getMemberBuyBook();
    getMemberBuyAllBook();
  }, []);

  const getMemberBuyBook = async () => {
    setHistoryLoading(true);
    setExpandedRowKeys([]);

    const dataFilter = `?member_id=${member_id}?buy_all_book=N`;

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `admin/report/member_books${dataFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tableRows = result.data.purchases.map((item, index) => {
        return {
          key: index.toString(),
          book_id: item.book_id,
          member_id: item.member_id,
          book_title: item.book_title,
          count_chapter: item.count_chapter,
          sum_price: item.sum_price,
        };
      });

      setTransactions(tableRows);
      setHistoryLoading(false);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setHistoryLoading(false);
    }
  };

  const getMemberBuyAllBook = async () => {
    const dataFilter = `?member_id=${member_id}?buy_all_book=Y`;
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `admin/report/member_books${dataFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tableRows = result.data.purchases.map((item, index) => {
        return {
          key: index.toString(),
          book_id: item.book_id,
          member_id: item.member_id,
          book_title: item.book_title,
          count_chapter: item.count_chapter,
          sum_price: item.sum_price,
        };
      });

      setBuyAllBook(tableRows);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
    }
  };

  const expandedRowRender = (e) => {
    return (
      <>
        <Table className="mt-4" dataSource={chapters} pagination={true}>
          <Column
            title="วันที่ใช้เหรียญ"
            dataIndex="created_datetime"
            key="created_datetime"
            align="center"
            sorter={(a, b) =>
              new Date(a.created_datetime) - new Date(b.created_datetime)
            }
            render={(value) => {
              return moment(value).format("DD-MM-YYYY HH:mm");
            }}
          />
          <Column
            title="ชื่อตอน"
            dataIndex="chaptor_title"
            key="chaptor_title"
            align="center"
            sorter={(a, b) => a.chaptor_title.localeCompare(b.chaptor_title)}
          />
          <Column
            title="จำนวน Coin ที่ใช้"
            dataIndex="price"
            key="price"
            align="center"
          />
          <Column
            title="ประเภทเหรียญ ที่ใช้"
            dataIndex="purchased_by"
            key="purchased_by"
            align="center"
          />
        </Table>
      </>
    );
  };

  const handleRowExpand = (expanded, record) => {
    const currentExpandedRows = expandedRowKeys;

    if (currentExpandedRows.includes(record.key)) {
      const index = currentExpandedRows.indexOf(record.key);
      currentExpandedRows.splice(index, 1);
    } else {
      currentExpandedRows.push(record.key);
    }

    setExpandedRowKeys(currentExpandedRows);

    if (expanded) {
      getChapter(record.book_id, record.member_id);
    }
  };

  const getChapter = async (book_id, member_id) => {
    const dataFilter = `?&book_id=${book_id}?&member_id=${member_id}?buy_all_book=N`;
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `admin/report/member_chapters${dataFilter}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const chapter = result.data.chapters.map((item, index) => {
        return {
          key: item.chaptor_id,
          book_id: item.book_id,
          chaptor_title: item.chaptor_title,
          price: item.price,
          created_datetime: item.created_datetime,
          purchased_by: item.purchased_by,
          chaptor_title: item.chaptor_title,
        };
      });

      setChapters(chapter);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
    }
  };

  return (
    <>
      <div style={{ padding: 20 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ข้อมูลการซื้อหนังสือ</h6>
          </CardHeader>
          <CardBody>
            <div className="flex-fill mt-2">
              <h6 className="mx-1 text-primary">ชื่อ : {member_name}</h6>
            </div>
            <div className="mt-4">
              <Card small>
                <CardHeader className="border-bottom">
                  <h6 className="m-0">ซื้อทีละตอน</h6>
                </CardHeader>
                <CardBody>
                  <Table
                    dataSource={transactions}
                    loading={historyLoading}
                    expandable={{
                      expandedRowRender,
                      expandedRowKeys: expandedRowKeys,
                      onExpand: handleRowExpand,
                    }}
                  >
                    <Column
                      title="ชื่อหนังสือ"
                      dataIndex="book_title"
                      key="book_title"
                      align="center"
                    />
                    <Column
                      title="จำนวนบทที่ซื้อ"
                      dataIndex="count_chapter"
                      key="count_chapter"
                      align="center"
                    />
                    <Column
                      title="จำนวนเหรียญทั้งหมดที่ใช้"
                      dataIndex="sum_price"
                      key="sum_price"
                      align="center"
                    />
                  </Table>
                </CardBody>
              </Card>
            </div>

            <div class="mt-4">
              <Card small>
                <CardHeader className="border-bottom">
                  <h6 className="m-0">ซื้อทั้งเล่ม</h6>
                </CardHeader>
                <CardBody>
                  <Table dataSource={buyAllBook}>
                    <Column
                      title="ชื่อหนังสือ"
                      dataIndex="book_title"
                      key="book_title"
                      align="center"
                    />
                    <Column
                      title="จำนวนบทที่ซื้อ"
                      dataIndex="count_chapter"
                      key="count_chapter"
                      align="center"
                    />
                    <Column
                      title="จำนวนเหรียญทั้งหมดที่ใช้"
                      dataIndex="sum_price"
                      key="sum_price"
                      align="center"
                    />
                  </Table>
                </CardBody>
              </Card>
            </div>

            <div className="text-right mt-3">
              {from_page === "member" && (
                <Button
                  onClick={() => {
                    history.push({
                      pathname: `/member`,
                      page: member_page,
                    });
                  }}
                  type="Button"
                  className="btn btn-danger mr-2"
                >
                  Back
                </Button>
              )}
              {from_page === "active_member" && (
                <Button
                  onClick={() => {
                    history.push({
                      pathname: `/active_member`,
                      page: member_page,
                    });
                  }}
                  type="Button"
                  className="btn btn-danger mr-2"
                >
                  Back
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

import React, { Component } from "react";
import { Formik, Field } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";

import { httpClient } from "../../../utils/HttpClient";
import * as actions from "../../../actions/user.action";
import { connect } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";

import UserFrom from "../userForm";

class EditUser extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let user_id = this.props.match.params.user_id;
    this.props.getUserById(user_id);
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleBlur,
  }) => {
    return (
      <div style={{ padding: 30 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">EDIT USER</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <UserFrom
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      isDisableField={false}
                      handleBlur={handleBlur}
                    ></UserFrom>

                    <div className="mt-3 text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary pull-right"
                        style={{ marginRight: 10, color: "white", width: 100 }}
                      >
                        บันทึก
                      </button>
                      <button type="button"
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    const { result } = this.props.userReducer;

    if(result)  {
      result["user_password"] = "";
    }

    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          initialValues={
            result ? result : {}
          }
          onSubmit={async (values, { setSubmitting }) => {
            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;

            let dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

            const params = {
              user_id: values.user_id,
              user_username: values.user_username,
              user_password: values.user_admin_password,
              user_firstname: values.user_firstname,
              user_lastname: values.user_lastname,
              user_email: values.user_email,
              user_phone: values.user_phone,
              user_role: values.user_role,
              updated_by: username,
              updated_datetime: dateTime
            }
            
            const token = localStorage.getItem("token")
            let response = await httpClient.patch(
              server.USER_URL + "/" + `${values.user_id}`,
              JSON.stringify(params),
              {
                headers: {
                  "Authorization": `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
  
            if (response.status === 201 || response.status === 200) {
              setSubmitting(false);
              this.props.history.push(`/user`);
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                timer: 2500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ userReducer}) => ({ userReducer });

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);

import React, { Component } from "react";
import * as actions from "../../../actions/sub_category.action";
import * as playlistActions from "../../../actions/playlist.action";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  FormCheckbox
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import * as Yup from "yup";
import MenuContent from "../Menu";
import AsyncSelect from "react-select/async";

class SubCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SubCategoryModalOpen: false,
      category_id: "",
      sub_category_id: "",
      sub_category_name: "",
      sub_category_desc: "",
      ugc_category: "N",
      add_update: "",
      recommend_playlist_id: "",
      playlists: []
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentDidMount() {
    let categoryID = this.props.match.params.category_id;
    this.props.getSubCategory(categoryID);
    
    this.setState({
      category_id: categoryID,
    });

    await this.props.getPlaylist(null);
    const { playlists, total } = this.props.playlistReducer;

    this.setState({
      playlists: playlists.map(item => { 
        return {
          label: item.playlist_name,
          value: item.playlist_id
        }
      }) 
    })
  }

  toggleModal(row, action) {
    if (action === "edit") {
      let subCategoryID = "";
      let subCategoryName = "";
      let subCategoryDesc = "";

      if (row.sub_category_id) subCategoryID = row.sub_category_id;
      if (row.sub_category_name) subCategoryName = row.sub_category_name;
      if (row.sub_category_desc) subCategoryDesc = row.sub_category_desc;

      this.setState({
        SubCategoryModalOpen: !this.state.SubCategoryModalOpen,
        sub_category_id: subCategoryID,
        sub_category_name: subCategoryName,
        sub_category_desc: subCategoryDesc,
        ugc_category: row.ugc_category,
        recommend_playlist_id: row.recommend_playlist_id,
        currentPlaylistOption: this.state.playlists.filter(item => item.value === row.recommend_playlist_id)[0] || null,
        add_update: action,
      });
    } else {
      this.setState({
        sub_category_id: "",
        sub_category_name: "",
        sub_category_desc: "",
        SubCategoryModalOpen: !this.state.SubCategoryModalOpen,
        recommend_playlist_id: "",
        add_update: action,
      });
    }
  }

  closeModal() {
    this.setState({
      SubCategoryModalOpen: !this.state.SubCategoryModalOpen,
    });
  }

  formatCreateDateTime(cell, row) {
    if (row.created_datetime) {
      return moment(row.created_datetime)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatAction(cell, row) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-xs btn-success text-white"
          onClick={(e) => this.toggleModal(row, "edit")}
        >
          Edit
        </button>

        <span style={{ color: "grey" }}> | </span>

        <button
          type="button"
          className="btn btn-xs btn-danger"
          onClick={() => {
            return Swal.fire({
              title: "Are you sure to delete?",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Delete",
              cancelButtonText: "Cancel",
            }).then((result) => {
              if (result.value) {
                this.props.deleteSubCategory(
                  row.sub_category_id,
                  row.category_id
                );
              }
            });
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  async addEditSubCategory(
    category_id,
    sub_category_id,
    sub_category_name,
    sub_category_desc,
    ugc_category
  ) {
    if (sub_category_name) {
      let result = localStorage.getItem("user_profile");
      let username;
      if (result) username = JSON.parse(result).user_username;
      
      let dateTime = new Date();
      dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

      let formData = new FormData();

      formData.append("category_id", category_id);
      if (this.state.add_update === "edit") {
        formData.append("sub_category_id", sub_category_id);
      }

      formData.append("sub_category_name", sub_category_name);
      formData.append("sub_category_desc", sub_category_desc);
      formData.append("ugc_category", ugc_category);

      formData.append("dbstatus", "A");
      formData.append("created_datetime", dateTime);
      formData.append("created_by", username);

      formData.append("updated_datetime", dateTime);
      formData.append("updated_by", username);
      formData.append("recommend_playlist_id", this.state.recommend_playlist_id || 0);

      let object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      let json = JSON.stringify(object);

      let response = "";

      const token = localStorage.getItem("token");
      if (this.state.add_update === "add") {
        response = await httpClient.post(`${server.SUB_CATEGORY_URL}`, json, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });
      } else if (this.state.add_update === "edit") {
        response = await httpClient.patch(
          `${server.SUB_CATEGORY_URL}` + "/" + `${sub_category_id}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
            },
          }
        );
      }

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          SubCategoryModalOpen: false,
          recommend_playlist_id: ''
        });

        this.props.getSubCategory(this.state.category_id);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "กรุณาระบุ Sub Category",
        timer: 1500,
      });
    }
  }

  loadPlaylistOptions = (inputValue, callback) => {
    callback(this.filterPlaylist(inputValue));
  };

  filterPlaylist(inputValue) {
    let playlists = this.state.playlists;

    if (playlists) {
      return playlists.filter((playlist) =>
        playlist.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    }
  }

  handlePlaylistChange(inputValue) {
    if(inputValue) {
      this.setState({
        currentPlaylistOption: inputValue,
        recommend_playlist_id: inputValue.value
      })
    } else {
      this.setState({
        currentPlaylistOption: null,
        recommend_playlist_id: null
      })
    }
  }

  createTable = () => {
    try {
      const { result, isFetching } = this.props.subCategoryReducer;

      return (
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="sub_category_id"
                isKey={true}
                hidden={true}
                width="50px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                SubCategory ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="category_name"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Category
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sub_category_name"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Sub Category
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="sub_category_desc"
                width="100px"
                hidden={true}
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                SubCategory Desc
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="category_id"
                width="100px"
                hidden={true}
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                filter={{ type: "TextFilter", placeholder: " " }}
              >
                Category ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_datetime"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatCreateDateTime}
                csvFormat={this.formatCreateDateTime}
              >
                Create Date
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="updated_by"
                width="40px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Update By
              </TableHeaderColumn>

              <TableHeaderColumn
                width="40px"
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatAction.bind(this)}
              >
                Action
              </TableHeaderColumn>
            </BootstrapTable>

            <Modal
              open={this.state.SubCategoryModalOpen}
              toggle={this.toggleModal}
            >
              <ModalHeader>Add/Edit</ModalHeader>
              <ModalBody>
                <Formik
                  //enableReinitialize
                  initialValues={{
                    category_id: this.state.category_id,
                    sub_category_id: this.state.sub_category_id,
                    sub_category_name: this.state.sub_category_name,
                    sub_category_desc: this.state.sub_category_desc,
                    ugc_category: this.state.ugc_category,
                  }}
                  render={({
                    values,
                    handleChange,
                    setFieldValue,
                    errors,
                    touched,
                  }) => (
                    <React.Fragment>
                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Sub Category Name</label>
                          <FormInput
                            name="sub_category_name"
                            id="sub_category_name"
                            onChange={handleChange}
                            value={values.sub_category_name}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Sub Category Description</label>
                          <FormInput
                            name="sub_category_desc"
                            id="sub_category_desc"
                            onChange={handleChange}
                            value={values.sub_category_desc}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="12" className="form-group">
                          <FormCheckbox
                            toggle
                            inline
                            checked={values.ugc_category === "Y" ? true : false}
                            onChange={(e) => {
                              setFieldValue("ugc_category", values.ugc_category === 'Y' ? 'N' : 'Y');
                            }}
                            className="mr-0 ml-2"
                          >
                            หมวดหมู่สำหรับ UGC
                          </FormCheckbox>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="12" className="form-group">
                          <label>Playlist หนังสือแนะนำ</label>
                          <AsyncSelect
                            cacheOptions
                            isClearable={true}
                            loadOptions={this.loadPlaylistOptions}
                            defaultOptions={this.state.playlists}
                            value={this.state.currentPlaylistOption}
                            onChange={(inputValue, { action }) => {
                              this.handlePlaylistChange(inputValue)
                            }}
                          />
                        </Col>
                      </Row>

                      <div className="text-right">
                        <button
                          type="button"
                          className="btn btn-primary pull-right"
                          onClick={() => {
                            this.addEditSubCategory(
                              this.state.category_id,
                              this.state.sub_category_id,
                              values.sub_category_name,
                              values.sub_category_desc,
                              values.ugc_category
                            );
                          }}
                          style={{
                            marginRight: 10,
                            color: "white",
                            width: 100,
                          }}
                        >
                          บันทึก
                        </button>
                        <button
                          onClick={(e) => this.closeModal()}
                          type="Button"
                          className="btn btn-danger pull-right"
                          style={{ color: "white", width: 100 }}
                        >
                          ยกเลิก
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                />
              </ModalBody>
            </Modal>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuContent />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Sub Category</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={(e) => this.toggleModal(null, "add")}
                      className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Add Sub Category
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                {this.createTable()}
                <div className="mt-3 text-right">
                  <button
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                    type="Button"
                    className="btn btn-danger ml-2"
                    style={{ color: "white", width: 100 }}
                  >
                    Back
                  </button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ subCategoryReducer, playlistReducer }) => ({
  subCategoryReducer,
  playlistReducer
});

const mapDispatchToProps = {
  ...actions,
  ...playlistActions
};
export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);

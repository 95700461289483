import React, { Component } from "react";
import { getReportHistory } from "../../../actions/reportHistory.action";
import { getCategory } from "../../../actions/category.action";
import { getBooks } from "../../../actions/book.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  FormSelect,
} from "shards-react";
import { Formik } from "formik";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import MenuReport from "../menuReport";
import Autosuggest from "react-autosuggest";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";
import {
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';

class reportHistory extends Component {
  constructor() {
    super();

    this.state = {
      books: [],
      suggestions: [],
      bookId: "",
      bookTitle: "",
      categoryName: "",
      orderBy: "update",
      value: "",
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
    };
  }

  async componentDidMount() {
    this.getHistory({orderBy: this.state.orderBy});
    this.props.getCategory();

    await this.props.getBooks();
    this.setState({
      books: this.props.bookReducer.books,
      suggestions: this.props.bookReducer.books,
    });
  }

  async getHistory(filter)  {
    await this.props.getReportHistory(filter);

    const { total } = this.props.reportHistoryReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({ 
      totalPages
    });
  }
  
  changePage(page) {
    this.setState({ currentPage: page });
    this.getHistory({
      bookTitle: this.state.bookTitle,
      categoryName: this.state.categoryName,
      page: page,
      orderBy: this.state.orderBy
    });
  }

  async handleOrderBy(value) {
    await this.setState({ orderBy: value})

    this.getHistory({
      bookTitle: this.state.bookTitle,
      categoryName: this.state.categoryName,
      page: this.state.currentPage,
      orderBy: this.state.orderBy,
    })
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.books,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      bookId: suggestion.book_id,
      bookTitle: suggestion.book_title,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.books.filter((book) =>
      inputLength !== 0
        ? book.book_title.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        bookId: "",
        bookTitle: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.book_title}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onBookTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.book_title;

  bindingCategoryName = ({ handleChange, values }) => {
    try {
      const { result, isFetching } = this.props.categoryReducer;
      const options = result.map((item) => (
        <option key={item.category_id} value={item.category_name}>
          {item.category_name}
        </option>
      ));

      return (
        !isFetching &&
        result != null && (
          <FormSelect
            name="category_name"
            id="category_name"
            placeholder=""
            onChange={handleChange}
            value={values.category_name}
          >
            <option value="">เลือกหมวดหมู่</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  createTable = () => {
    try {
      const { result, isFetching } = this.props.reportHistoryReducer;
      const csv_name = "history-report.csv";

      return (  
        !isFetching &&
        result != null && (
          <div>
            <BootstrapTable
              className="table-fixed"
              data={result}
              pagination={true}
              exportCSV={{
                ignoreFooter: false,
              }}
              csvFileName={csv_name}
              noAutoBOM={false}
              striped
              hover
            >
              <TableHeaderColumn
                dataField="book_id"
                isKey={true}
                hidden={true}
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
              >
                Book ID
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="book_title"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "left" }}
                thStyle={{ whiteSpace: "normal", textAlign: "left" }}
                csvHeader="ชื่อหนังสือ"
              >
                ชื่อหนังสือ
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="created_datetime"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatDateTime}
                csvFormat={this.formatDateTime}
                csvHeader="วันที่สร้าง"
              >
                วันที่สร้าง
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="updated_datetime"
                width="100px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                dataFormat={this.formatDateTime}
                csvFormat={this.formatDateTime}
                csvHeader="วันที่แก้ไขล่าสุด"
              >
                วันที่แก้ไขล่าสุด
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="updated_by"
                width="80px"
                tdStyle={{ whiteSpace: "normal", textAlign: "center" }}
                thStyle={{ whiteSpace: "normal", textAlign: "center" }}
                csvHeader="ชื่อผู้ทำการแก้ไขล่าสุด"
              >
                ชื่อผู้ทำการแก้ไขล่าสุด
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )
      );
    } catch (e) {}
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "พิมพ์ชื่อหนังสือ",
      value,
      onChange: this.onBookTitleChange,
    };

    const { result, isFetching } = this.props.reportHistoryReducer;

    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <MenuReport />
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">รายงานประวัติการลงหนังสือ</h6>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    category_name: "",
                  }}
                  onSubmit={async (values) => {
                    const value = {};

                    if (values.category_name) {
                      value.categoryName = values.category_name;
                    }

                    value.bookTitle = this.state.bookTitle || this.state.value;
                    value.page = this.state.currentPage
                    value.orderBy = this.state.orderBy

                    this.getHistory(value)
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      handleChange,
                      isSubmitting,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col sm="5">
                            <FormGroup>
                              <label>หมวดหมู่</label>

                              {this.bindingCategoryName({
                                values,
                                handleChange,
                              })}
                            </FormGroup>
                          </Col>
                          <Col sm="5">
                            <FormGroup>
                              <label>ชื่อหนังสือ</label>

                              <div className="position-relative">
                                <Autosuggest
                                  suggestions={suggestions}
                                  onSuggestionsFetchRequested={
                                    this.onSuggestionsFetchRequested
                                  }
                                  onSuggestionsClearRequested={
                                    this.onSuggestionsClearRequested
                                  }
                                  shouldRenderSuggestions={
                                    this.shouldRenderSuggestions
                                  }
                                  onSuggestionSelected={this.onSuggestionSelected}
                                  getSuggestionValue={this.getSuggestionValue}
                                  renderSuggestion={this.renderSuggestion}
                                  inputProps={inputProps}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col sm="2">
                            <FormGroup>
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-primary"
                                style={{
                                  marginTop: 27
                                }}
                              >
                                Search
                              </button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>

                <div
                  className={
                    "content-loading-wrapper " +
                    (isFetching ? "loading" : "")
                  }
                >
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="align-middle">ชื่อหนังสือ</th>
                        <th 
                          className="text-center align-middle pointer"
                          onClick={() => this.handleOrderBy('create')}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2">วันที่สร้าง</span>
                            <CaretDownOutlined style={{ opacity: this.state.orderBy === 'create' ? '1' : '0.3'}}/>
                          </div>
                        </th>
                        <th className="text-center align-middle">ชื่อผู้ทำการเพิ่ม</th>
                        <th 
                          className="text-center align-middle pointer"
                          onClick={() => this.handleOrderBy('update')}
                        >
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="mr-2">วันที่แก้ไขล่าสุด</span>
                            <CaretDownOutlined style={{ opacity: this.state.orderBy === 'update' ? '1' : '0.3'}}/>
                          </div>
                        </th>
                        <th className="text-center align-middle">ชื่อผู้ทำการแก้ไขล่าสุด</th>
                        <th className="text-center align-middle">ตอนหนังสือ</th>
                      </tr>
                    </thead>
                    <tbody>
                      { result && result.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{ item.book_title }</td>
                            <td className="text-center">{ this.formatDateTime(item.created_datetime) }</td>
                            <td className="text-center">{ item.created_by }</td>
                            <td className="text-center">{ this.formatDateTime(item.updated_datetime) }</td>
                            <td className="text-center">{ item.updated_by }</td>
                            <td className="text-center align-middle" width="180">
                              <a
                                type="button"
                                className="btn btn-xs btn-primary text-white mr-2"
                                onClick={() =>
                                  this.props.history.push({
                                    pathname: `/report/chapter/history/${item.book_id}`,
                                    bookPage: this.state.currentPage,
                                  })
                                }
                              >
                                Chapters
                              </a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <nav className="text-right">
                  {this.state.books.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportHistoryReducer, categoryReducer, bookReducer }) => ({
  reportHistoryReducer,
  categoryReducer,
  bookReducer
});

const mapDispatchToProps = {
  getReportHistory,
  getCategory,
  getBooks
};

export default connect(mapStateToProps, mapDispatchToProps)(reportHistory);

import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import * as actions from "../../../actions/redeem.action";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import moment from "moment";
import RedeemCodeForm from "../redeemCodeForm";

const redeemValidationSchema = Yup.object().shape({
  coin: Yup.number()
          .required('กรุณากรอกจำเหรียญที่จะได้รับ')
          .positive('จำนวนเหรียญต้องมากกว่า 0')
          .integer('จำนวนเหรียญต้องเป็นจำนวนเต็ม'),
  digit: Yup.number()
          .required('กรุณากรอกจำนวนหลักของ Code')
          .positive('จำนวนหลักต้องมากกว่า 0')
          .integer('จำนวนหลักต้องเป็นจำนวนเต็ม'),
  quantity: Yup.number()
          .required('กรุณากรอกจำนวน Code ที่ต้องการสร้าง')
          .positive('จำนวน Code ต้องมากกว่า 0')
          .integer('จำนวน Code ต้องเป็นจำนวนเต็ม'),
  validDate: Yup.date()
          .required('กรุณาเลือกวันที่หมดอายุ'),
  limit: Yup.string().required('กรุณากรอกจำนวนครั้งที่สามารถใช้ได้')          
});

class GenerateRedeemCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      id: '',
      type: ''
    }
  }

  async componentDidMount() {
    let id = this.props.match.params.id;
    let type = this.props.location.type;

    if(!type)  {
      this.props.history.push({
        pathname: `/redeem`
      })
    }


    await this.props.getRedeemDetail(id, type);
    this.setState({
      data: this.props.redeemReducer.groupData,
      type: type,
      id: id
    })
  }
  
  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched
  }) => {
    return (
      <div style={{ padding: 40 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0 text-upper">Generate Redeem Code</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <RedeemCodeForm
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      isDisableField={false}
                    ></RedeemCodeForm>

                    <div className="mt-3">
                      <div className="row">
                        <div className="col-4">
                          <button
                            onClick={() => {
                              this.props.history.goBack();
                            }}
                            type="Button"
                            className="btn btn-primary pull-right"
                            style={{ color: "white", width: 100 }}
                          >
                            ย้อนกลับ
                          </button>
                        </div>
                        <div className="col-8 text-right">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary pull-right"
                            style={{ marginRight: 10, color: "white", width: 100 }}
                          >
                            บันทึก
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (  
      <React.Fragment>
        { this.state.data && (
          <Formik
            initialValues={{
              name: this.state.data.name,
              code: this.state.data.code,
              coin: this.state.data.coin,
              quantity: this.state.data.quantity || 1,
              digit: this.state.data.digit || this.state.data.code.length,
              validDate: this.state.data.valid_date,
              coinExpiredDate: this.state.data.coin_expired_date,
              status: this.state.data.status,
              isEdit: true,
              limit: this.state.data.limit,
            }}
            validationSchema={redeemValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              let params = {
                name: values.name,
                code: values.code,
                coin: Number(values.coin.toString().replace(/,/g, '')),
                valid_date: values.validDate,
                coin_expired_date: moment(values.coinExpiredDate).endOf('day'),
                status: values.status,
                limit: Number(values.limit.toString().replace(/,/g, '')),
              } 
              
              const apiUrl = this.state.type === 'group' ? `${server.REDEEM_URL}/${this.state.id}` : `${server.REDEEM_URL}/single/${this.state.id}`

              const token = localStorage.getItem("token")
              const response = await httpClient.put(apiUrl, JSON.stringify(params), {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`,
                },
              });

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setSubmitting(false);
                this.props.history.goBack();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
                  timer: 1500,
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik> 
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ redeemReducer, appReducer }) => ({
  redeemReducer,
  appReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateRedeemCode);


import React, { Component, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { withRouter, NavLink } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import {
  Row,
  Col,
  FormInput,
  Card,
  CardHeader,
  CardBody,
  button,
  ListGroup,
  ButtonGroup,
} from "shards-react";
import {
  Table,
  Tag,
  Button,
  Skeleton,
  Modal,
  message,
  DatePicker,
  Input,
  Spin,
} from "antd";
import Swal from "sweetalert2";
import { httpClient } from "../../utils/HttpClient";
import { Pie, Line } from "react-chartjs-2";
import MenuMember from "./menuMember";

const { RangePicker } = DatePicker;
const { Column } = Table;

export default function ActiveMember() {
  const [historyLoading, setHistoryLoading] = useState(false);
  const [dateStart, setDateStart] = useState(
    moment()
      .subtract(30, "day")
      .format("YYYY-MM-DD 00:00:00")
  );
  const [dateEnd, setDateEnd] = useState(
    moment().format("YYYY-MM-DD 23:59:59")
  );
  const [data, setData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [loadings, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });
  const [totalActive, setTotalActive] = useState(0);
  const [totalMember, setTotalMember] = useState(0);
  const history = useHistory();
  const [filter, setFilter] = useState({
    page: 0,
    member_active: "Y",
  });

  useEffect(() => {
    getRegisterMember(dateStart, dateEnd);
  }, []);

  useEffect(() => {
    getActiveMember();
  }, [filter]);

  const getActiveMember = async () => {
    let current_page = localStorage.getItem("active_member_page");

    if (current_page) {
      filter.page = current_page;
    } else current_page = 0;

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get("admin/member", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: filter,
      });

      setTotalActive(formatNumber(result.data.total));
      setTotalMember(formatNumber(result.data.total_all_member));

      const tableRows = result.data.members.map((item, index) => {
        return {
          key: item.member_id,
          member_name: item.member_name,
          member_email: item.member_email,
          member_username: item.member_username,
          member_coin: item.member_coin,
          member_silver_coin: item.member_silver_coin,
          created_datetime: item.created_datetime,
          last_access_date: item.last_access_date,
        };
      });

      setTransactions(tableRows);

      if (current_page) {
        await setPagination({
          ...pagination,
          current: Number(current_page) + 1,
          total: result.data.total,
        });
      } else {
        setPagination({
          ...pagination,
          total: result.data.total,
        });
      }
      setLoading(false);
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setLoading(false);
    }
  };

  const getRegisterMember = async (startDate, endDate) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await httpClient.get(
        `admin/dashboard/member_active?dateStart=${startDate}&dateEnd=${endDate}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await setLineChart(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  async function deleteMember(member_id) {
    try {
      const token = localStorage.getItem("token");
      let response = await httpClient.delete(`admin/member/${member_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 201 || response.status === 200) {
        getActiveMember();
      }
    } catch (err) {
    }
  }

  const setLineChart = async (transaction) => {
    try {
      let labelArray = [];
      let total = [];

      for (const item of transaction.data) {
        labelArray.push(moment(item.date).format("DD/MM"));
        total.push(item.total);
      }

      const lineChartData = {
        labels: labelArray,
        datasets: [
          {
            label: "ยอดคนสมัครสมาชิกรายวัน",
            fill: true,
            lineTension: 0.1,
            backgroundColor: "rgba(206,66,87,0.2)",
            borderColor: "rgba(206,66,87,1)",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            pointBorderColor: "rgba(206,66,87,1)",
            pointBackgroundColor: "#fff",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "rgba(206,66,87,1)",
            pointHoverBorderColor: "rgba(220,220,220,1)",
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: total,
          },
        ],
      };

      await setLineChartData(lineChartData);
    } catch (e) {
    }
  };

  async function onDateChange(value, dateString) {
    let start = value[0].format("YYYY-MM-DD 00:00:00");
    let end = value[1].format("YYYY-MM-DD 23:59:59");

    await setDateStart(start);
    await setDateEnd(end);
    await getRegisterMember(start, end);
  }

  function handleTableChange(pagination, filters, sorter) {
    setPagination(pagination);

    setFilter({
      ...filter,
      page: pagination.current - 1,
    });

    localStorage.setItem("active_member_page", pagination.current - 1);
  }

  function formatNumber(number) {
    if (number) {
      return new Intl.NumberFormat("en-GB", {
        style: "decimal",
      }).format(number);
    } else return 0;
  }

  const isAllowAction = () => {
    var result = localStorage.getItem("user_profile");
    if (result) {
      const priv_allow = JSON.parse(result).member;

      if (priv_allow === "Y") return true;
      else {
        Swal.fire({
          icon: "error",
          text: "No privilege!",
        });
        return false;
      }
    }
  };

  return (
    <>
      <div className="ml-2">
        <MenuMember />
      </div>
      <div style={{ padding: 10 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ยอดสมัครสมาชิกรายวัน</h6>
            <Spin spinning={historyLoading} />
          </CardHeader>
          <CardBody>
            <>
              <div className="col-12 col-sm-6">
                <RangePicker
                  placeholder={["เริ่มต้น", "สิ้นสุด"]}
                  format="DD/MM/YYYY"
                  disabledDate={false}
                  onChange={onDateChange}
                  value={[moment(dateStart), moment(dateEnd)]}
                />
              </div>
              <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-10">
                  {lineChartData && (
                    <Line
                      data={lineChartData}
                      options={{
                        maintainAspectRatio: true,
                      }}
                    />
                  )}
                </div>
                <div className="col-sm-1"></div>
              </div>
            </>
          </CardBody>
        </Card>
      </div>

      <div style={{ padding: 10 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">Active Member</h6>
            <Spin spinning={loadings} />
          </CardHeader>
          <CardBody>
            <>
              <div className="row">
                <div className="col-sm-4">
                  <h6 className="text-accent">
                    จำนวนสมาชิกทั้งหมด : {totalMember} คน
                  </h6>
                </div>
                <div className="col-sm-6">
                  <h6>
                    <span className="text-accent">
                      Active : {totalActive} คน{" "}
                    </span>
                    <span> (นับจากวันที่ปัจจุบันย้อนหลัง 1 เดือน)</span>
                  </h6>
                </div>
              </div>

              <div className="mt-3">
                <Table
                  loading={loadings}
                  dataSource={transactions}
                  pagination={pagination}
                  onChange={handleTableChange}
                  scroll={{ x: true }}
                >
                  <Column
                    title="Create Date"
                    dataIndex="created_datetime"
                    key="created_datetime"
                    align="center"
                    render={(value) => {
                      return moment(value).format("DD/MM/YYYY HH:mm");
                    }}
                  />
                  <Column
                    title="Member Name"
                    dataIndex="member_name"
                    key="member_name"
                  />
                  <Column
                    title="Username"
                    dataIndex="member_username"
                    key="member_username"
                    align="center"
                  />
                  <Column
                    title="Email"
                    dataIndex="member_email"
                    key="member_email"
                  />
                  <Column
                    title="Gold Coin"
                    dataIndex="member_coin"
                    key="member_coin"
                    align="center"
                    render={(value) => {
                      return formatNumber(value);
                    }}
                  />
                  <Column
                    title="Silver Coin"
                    dataIndex="member_silver_coin"
                    key="member_silver_coin"
                    align="center"
                    render={(value) => {
                      return formatNumber(value);
                    }}
                  />
                  <Column
                    title="Update By"
                    dataIndex="updated_by"
                    key="updated_by"
                    align="center"
                  />
                  <Column
                    title="Last Access Date"
                    dataIndex="last_access_date"
                    key="last_access_date"
                    render={(value) => {
                      return moment(value).format("DD-MM-YYYY HH:mm");
                    }}
                  />
                  <Column
                    title="Transaction History"
                    dataIndex="transation_history"
                    key="transation_history"
                    align="center"
                    render={(text, record) => {
                      return (
                        <>
                          <button
                            type="button"
                            className="btn btn-xs btn-warning"
                            onClick={() =>
                              history.push({
                                pathname: `/member_books/${record.key}/${
                                  record.member_name
                                }/${pagination.current - 1}}/active_member`,
                              })
                            }
                          >
                            Transaction History
                          </button>
                        </>
                      );
                    }}
                  />
                  <Column
                    title="Action"
                    dataIndex="action"
                    key="action"
                    align="center"
                    render={(text, record) => {
                      return (
                        <>
                          <button
                            type="button"
                            className="btn btn-xs btn-info"
                            onClick={() =>
                              history.push({
                                pathname:
                                  "/viewMember/" +
                                  record.key +
                                  "/active_member",
                                page: pagination.current - 1,
                              })
                            }
                          >
                            View
                          </button>

                          <span style={{ color: "grey" }}> | </span>

                          <button
                            type="button"
                            className="btn btn-xs btn-success text-white"
                            onClick={() =>
                              history.push({
                                pathname:
                                  "/editMember/" +
                                  record.key +
                                  "/active_member",
                                page: pagination.current - 1,
                              })
                            }
                          >
                            Edit
                          </button>

                          <span style={{ color: "grey" }}> | </span>

                          <button
                            onClick={() => {
                              return (
                                isAllowAction() &&
                                Swal.fire({
                                  text: "Are you sure to delete?",
                                  type: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: "Yes, delete it!",
                                  cancelButtonText: "No, cancel!",
                                }).then((result) => {
                                  if (result.value) {
                                    this.deleteMember(record.key);
                                  }
                                })
                              );
                            }}
                            type="button"
                            className="btn btn-xs btn-danger text-white"
                          >
                            Delete
                          </button>
                        </>
                      );
                    }}
                  />
                </Table>
              </div>
            </>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export const HTTP_PLAYLISTS_FETCHING = "HTTP_PLAYLISTS_FETCHING";
export const HTTP_PLAYLISTS_SUCCESS = "HTTP_PLAYLISTS_SUCCESS";
export const HTTP_PLAYLISTS_FAILED = "HTTP_PLAYLISTS_FAILED";

export const HTTP_PLAYLIST_FETCHING = "HTTP_PLAYLIST_FETCHING";
export const HTTP_PLAYLIST_SUCCESS = "HTTP_PLAYLIST_SUCCESS";
export const HTTP_PLAYLIST_FAILED = "HTTP_PLAYLIST_FAILED";

export const HTTP_PLAYLIST_ITEM_FETCHING = "HTTP_PLAYLIST_ITEM_FETCHING";
export const HTTP_PLAYLIST_ITEM_SUCCESS = "HTTP_PLAYLIST_ITEM_SUCCESS";
export const HTTP_PLAYLIST_ITEM_FAILED = "HTTP_PLAYLIST_ITEM_FAILED";

export const HTTP_PLAYLIST_BOOKS_FETCHING = "HTTP_PLAYLIST_BOOKS_FETCHING";
export const HTTP_PLAYLIST_BOOKS_SUCCESS = "HTTP_PLAYLIST_BOOKS_SUCCESS";
export const HTTP_PLAYLIST_BOOKS_FAILED = "HTTP_PLAYLIST_BOOKS_FAILED";

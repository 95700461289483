import React, { Component } from "react";
import * as actions from "../../../actions/book_publish.action";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DatePicker,
} from "shards-react";
import Swal from "sweetalert2";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";
import { Formik } from "formik";

class BookPublish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      books: [],
      member_id: "",
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      start_date: "",
      end_date: "",
    };
  }

  async componentDidMount() {
    await this.setState({
      member_id: this.props.match.params.member_id,
    });

    await this.getBookPublishTransaction(this.state.currentPage);
  }

  async getBookPublishTransaction(page) {
    this.setState({ loading: true });

    const value = {};
    value.member_id = this.props.match.params.member_id;
    value.page = page;
    value.start = this.state.start_date;
    value.end = this.state.end_date;

    await this.props.getBookPublishTransaction(value);

    const { books, total } = this.props.bookPublishReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      books,
      totalPages,
      loading: false,
    });
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY");
    } else return "";
  }

  formatStatusExport(status) {
    if (status == "A") return "เผยแพร่";
    else return "ไม่เผยแพร่";
  }

  getStatusLabel(status) {
    let statusBadge = "";

    if (status == "A") {
      statusBadge = (
        <Badge theme="secondary" className="font-12">
          เผยแพร่
        </Badge>
      );
    } else {
      statusBadge = (
        <Badge theme="danger" className="font-12">
          ไม่เผยแพร่
        </Badge>
      );
    }

    return statusBadge;
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">ชื่อผู้แต่ง</th>
            <th className="text-center">ชื่อหนังสือ</th>
            <th className="text-center">เผยแพร่</th>
            <th className="text-center">วันที่เพิ่มหนังสือ</th>
            <th className="text-center">วันที่เผยแพร่หนังสือ</th>
          </tr>
        </thead>
        <tbody>
          {this.state.books.map((book, index) => {
            return (
              <tr key={book.book_id}>
                <td className="text-center align-middle">
                  {book.creator_name}
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <img src={book.book_image} width="50" className="mr-3" />
                    <div>{book.book_title}</div>
                  </div>
                </td>

                <td className="text-center align-middle">
                  {this.getStatusLabel(book.book_status)}
                </td>

                <td className="text-center align-middle">
                  {this.formatDateTime(book.created_datetime)}
                </td>
                <td className="text-center align-middle">
                  {this.formatDateTime(book.startdate)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getBookPublishTransaction(page);
  }

  async exportData() {
    await this.getBookPublishTransaction("");

    let report = this.state.books.map((item, idx) => {
      return [
        item.creator_name,
        item.book_title,
        this.formatStatusExport(item.book_status),
        this.formatDateTime(item.created_datetime),
        this.formatDateTime(item.startdate),
      ];
    });

    report = [
      [
        "ชื่อผู้แต่ง",
        "ชื่อหนังสือ",
        "Status",
        "วันที่เพิ่มหนังสือ",
        "วันที่เผยแพร่หนังสือ",
      ],
      ...report,
    ];

    if (report) {
      this.downloadExportData(report);
    }
  }

  downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, ["book-publish"]);
    XLSX.writeFile(workBook, `book-publish.xlsx`);

    this.getBookPublishTransaction(0);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 20 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">ประวัติการลงหนังสือ</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        this.exportData();
                      }}
                      type="button"
                      className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody style={{ padding: "1rem" }}>
                <Formik
                  initialValues={{
                    start_date: "",
                    end_date: "",
                  }}
                  onSubmit={async (values) => {
                    let startDate = null;
                    let endDate = null;
                    if (values.start_date) {
                      startDate = moment(values.start_date).format(
                        "YYYY-MM-DD"
                      );
                    }

                    if (values.end_date) {
                      endDate = moment(values.end_date).format("YYYY-MM-DD");
                    }

                    this.setState({
                      start_date: startDate,
                      end_date: endDate
                    });

                    this.getBookPublishTransaction(this.state.currentPage);
                  }}
                >
                  {(props) => {
                    const {
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      handleChange,
                    } = props;
                    return (
                      <form onSubmit={handleSubmit}>
                        <Row form>
                          <Col md="4" className="form-group">
                            <label>วันที่เพิ่มหนังสือ เริ่มต้น/สิ้นสุด</label>
                            <InputGroup className={"d-flex my-auto date-range"}>
                              <DatePickerField
                                size="sm"
                                name="start_date"
                                onChange={setFieldValue}
                                value={values.start_date}
                                placeholder="Start Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <DatePickerField
                                size="sm"
                                name="end_date"
                                onChange={setFieldValue}
                                value={values.end_date}
                                placeholder="End Date"
                                dropdownMode="select"
                                className="text-center"
                              />
                              <InputGroupAddon type="append">
                                <InputGroupText>
                                  <i className="material-icons">&#xE916;</i>
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          <Col md="2" className="form-group">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary btn-sm ml-4"
                              style={{
                                marginRight: 10,
                                color: "white",
                                width: 100,
                                marginTop:30
                              }}
                            >
                              Search
                            </button>
                          </Col>
                        </Row>
                      </form>
                    );
                  }}
                </Formik>
              </CardBody>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.books.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>

                <div className="text-right">
                  <a
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                    type="Button"
                    className="btn btn-danger pull-right"
                    style={{ color: "white", width: 100 }}
                  >
                    Back
                  </a>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookPublishReducer }) => ({
  bookPublishReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookPublish);

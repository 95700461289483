import {
    HTTP_BANNER_VIEW_SUCCESS,
    HTTP_BANNER_VIEW_FETCHING,
    HTTP_BANNER_VIEW_FAILED,
  } from "../constants/banner";
  
  const initialState = {
    banners: null,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_BANNER_VIEW_SUCCESS:
        return {
          ...state,
          banners: payload.banners,
          total: payload.total,
          isFetching: false,
          isError: false,
        };
      case HTTP_BANNER_VIEW_FAILED:
        return { ...state, banners: null, isFetching: false, isError: true };
      case HTTP_BANNER_VIEW_FETCHING:
        return { ...state, banners: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
  
import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_USERS_SUCCESS,
  HTTP_USERS_FETCHING,
  HTTP_USERS_FAILED,
  HTTP_USER_SUCCESS,
  HTTP_USER_FETCHING,
  HTTP_USER_FAILED,
  HTTP_USER_ROLES_SUCCESS,
  HTTP_USER_ROLES_FETCHING,
  HTTP_USER_ROLES_FAILED,
  HTTP_USER_ROLE_SUCCESS,
  HTTP_USER_ROLE_FETCHING,
  HTTP_USER_ROLE_FAILED,
} from "../constants/user";

import { server } from "../constants";

const setStateUsersToSuccess = (payload) => ({
  type: HTTP_USERS_SUCCESS,
  payload: payload,
});

const setStateUsersToFetching = () => ({
  type: HTTP_USERS_FETCHING,
});

const setStateUsersToFailed = () => ({
  type: HTTP_USERS_FAILED,
});

const setStateUserToSuccess = (payload) => ({
  type: HTTP_USER_SUCCESS,
  payload: payload,
});

const setStateUserToFetching = () => ({
  type: HTTP_USER_FETCHING,
});

const setStateUserToFailed = () => ({
  type: HTTP_USER_FAILED,
});

const setStateUserRolesToSuccess = (payload) => ({
  type: HTTP_USER_ROLES_SUCCESS,
  payload: payload,
});

const setStateUserRolesToFetching = () => ({
  type: HTTP_USER_ROLES_FETCHING,
});

const setStateUserRolesToFailed = () => ({
  type: HTTP_USER_ROLES_FAILED,
});

const setStateUserRoleToSuccess = (payload) => ({
  type: HTTP_USER_ROLE_SUCCESS,
  payload: payload,
});

const setStateUserRoleToFetching = () => ({
  type: HTTP_USER_ROLE_FETCHING,
});

const setStateUserRoleToFailed = () => ({
  type: HTTP_USER_ROLE_FAILED,
});

export const getAllUser = (username, page) => {
  return async (dispatch) => {
    dispatch(setStateUsersToFetching());
    await doGetUser(dispatch, username, page);
  };
};

export const getAllUserRole = () => {
  return async (dispatch) => {
    dispatch(setStateUserRolesToFetching());

    try {
      const token = localStorage.getItem("token");
      let result = await httpClient.get("admin/user-role", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(setStateUserRolesToSuccess(result.data));
    } catch (err) {
      dispatch(setStateUserRolesToFailed());
    }
  };
};

export const getRoleById = (id) => {
  return async (dispatch) => {
    dispatch(setStateUserRoleToFetching());

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(`admin/user-role/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      dispatch(setStateUserRoleToSuccess(result.data));
    } catch (err) {
      dispatch(setStateUserRoleToFailed());
    }
  };
};

export const deleteRole = (id) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    await httpClient.delete(`admin/user-role/${id}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
  };
};

const doGetUser = async (dispatch, username, page) => {
  try {
    const token = localStorage.getItem("token");

    const result = await httpClient.get(server.USER_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        username: username,
        page: page,
      },
    });
    dispatch(setStateUsersToSuccess(result.data));
  } catch (err) {
    dispatch(setStateUsersToFailed());
  }
};

export const getUserById = (user_id) => {
  return async (dispatch) => {
    dispatch(setStateUserToFetching());
    const token = localStorage.getItem("token");
    httpClient
      .get(`${server.USER_URL}/${user_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        dispatch(setStateUserToSuccess(result.data));
      })
      .catch((err) => {
        dispatch(setStateUserToFailed());
      });
  };
};

export const getUserByUsername = () => {
  return (dispatch) => {
    dispatch(setStateUserToFetching());
    const token = localStorage.getItem("token");

    httpClient
      .get(`${server.USER_URL}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        dispatch(setStateUserToSuccess(result.data));
      })
      .catch((err) => {
        dispatch(setStateUserToFailed());
      });
  };
};

export const deleteUser = (user_id) => {
  return async (dispatch) => {
    dispatch(setStateUserToFetching());
    const token = localStorage.getItem("token");
    const response = await httpClient.delete(
      server.USER_URL + "/" + user_id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    if (response.status === 201 || response.status === 200) {
      alert("Delete Complete");
      await doGetUser(dispatch);
    }
  };
};

import React, { Component } from "react";
import {  Navbar, NavbarBrand } from "shards-react";

class SidebarMainNavbar extends Component {
  render() {
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
        >
          <NavbarBrand
            className="w-100 mr-0"
            href="#"
            style={{ lineHeight: "25px" }}
          >
            <div className="d-flex align-items-center m-auto">
              <img
                id="main-logo"
                className="d-inline-block align-top mr-1"
                style={{ maxWidth: "35px", marginLeft: "-17px" }}
                src={require("../../../../assets/img/Logo.png")}
                alt="2 Read BOF"
              />
              <span className="d-none d-md-inline">2Read Admin</span>
            </div>
          </NavbarBrand>
          <a className="toggle-sidebar d-sm-inline d-md-none d-lg-none">
            <i className="material-icons">&#xE5C4;</i>
          </a>
        </Navbar>
      </div>
    );
  }
}

export default SidebarMainNavbar;

import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import userReducer from "./user.reducer"
import memberReducer from "./member.reducer"
import reportOrderReducer from "./reportOrder.reducer"
import loginReducer from "./login.reducer"
import profileReducer from "./profile.reducer"
import campaignReducer from "./campaign.reducer"
import reportSummaryReducer from "./reportSumSilver.reducer"
import redeemReducer from "./redeem.reducer"
import packageReducer from "./package.reducer"
import bookReducer from "./book.reducer"
import reportSilverTransReducer from './reportSiverCoin.reducer'
import reportSilverTranSumReducer from './reportSumSilverTrans.reducer'
import getCampaignNameReducer from './getCampaignName.reducer'
import reportPaymentReducer from "./reportPayment.reducer"
import reportPurchaseReducer from "./reportPurchase.reducer"
import reportCoinSaleReducer from "./reportCoinSale.reducer"
import reportSaleByStoryReducer from "./reportSaleByStory.reducer"
import reportSaleByChapterReducer from "./reportSaleByChapter.reducer"
import reportCoinSpendingReducer from "./reportCoinSpending.reducer"
import reportCoinRemainReducer from "./reportCoinRemain.reducer"
import reportWriterReducer from "./reportWriter.reducer"
import orderReducer from "./order.reducer"
import reportBookRankReducer from "./reportBookRank.reducer"
import categoryReducer from "./category.reducer"
import reportViewRankReducer from "./reportViewRank.reducer"
import reportChapterRankReducer from "./reportChapterRank.reducer"
import reportPurchaseCoinRankReducer from "./reportPurchaseCoinRank.reducer"
import reportMemberTransRankReducer from "./reportMemberTrans.reducer"
import getMemberTransReducer from "./getMemberTrans.reducer"
import commentReducer from "./comment.reducer"
import playlistReducer from "./playlist.reducer"
import playlistItemReducer from "./playlistItem.reducer"
import playlistItemBookReducer from "./playlistItemBook.reducer"
import stickerSetReducer from "./stickerSet.reducer"
import stickerItemReducer from "./stickerItem.reducer"
import bannerReducer from './banner.reducer'
import subCategoryReducer from './sub_category.reducer'
import bookChapterReducer from './book_chapter.reducer'
import topupReducer from './member_topup.reducer'
import memberPurchaseReducer from './member_purchase.reducer'
import writerRecommendReducer from './writerRecommend.reducer'
import bannerViewReducer from './banner_view.reducer'
import editorReducer from './editor.reducer'
import bookLabelReducer from './book_label.reducer'
import publisherReducer from './publisher.reducer'
import reportPublisherReducer from './reportPublisher.reducer'
import reportEditorReducer from './reportEditor.reducer'
import reportWriterEditorReducer from './reportWriterEditor.reducer'
import reportBookSaleReducer from './reportBookSale.reducer'
import reportBuyAllBookReducer from './reportBuyAllBook.reducer'
import reportWriterDetailReducer from './reportWriterDetail.reducer'
import reportMemberWithdrawReducer from './reportWithdraw.reducer'
import bookPublishReducer from './book_publish.reducer'
import reportPublishBookReducer from './reportPublishBook.reducer'
import reportGiftReducer from './reportGift.reducer'
import reportInviteReducer from './reportInvite.reducer'
import bookMessageReducer from './book_message.reducer'
import bookCharacterReducer from './book_character.reducer'
import reportHistoryReducer from './reportHistory.reducer'
import reportBuyWholeBookReducer from './reportBuyWholeBook.reducer'

export default combineReducers({
    appReducer ,
    userReducer,
    memberReducer,
    reportOrderReducer,
    loginReducer,
    profileReducer,
    campaignReducer,
    redeemReducer,
    packageReducer,
    bookReducer,
    reportSummaryReducer,
    reportSilverTransReducer,
    reportSilverTranSumReducer,
    getCampaignNameReducer,
    reportPaymentReducer,
    reportPurchaseReducer,
    reportCoinSaleReducer,
    reportSaleByStoryReducer,
    reportSaleByChapterReducer,
    reportCoinSpendingReducer,
    reportCoinRemainReducer,
    reportWriterReducer,
    orderReducer,
    reportBookRankReducer,
    categoryReducer,
    reportViewRankReducer,
    reportChapterRankReducer,
    reportPurchaseCoinRankReducer,
    reportMemberTransRankReducer,
    getMemberTransReducer,
    commentReducer,
    playlistReducer,
    playlistItemReducer,
    playlistItemBookReducer,
    stickerSetReducer,
    stickerItemReducer,
    bannerReducer,
    subCategoryReducer,
    bookChapterReducer,
    topupReducer,
    memberPurchaseReducer,
    writerRecommendReducer,
    bannerViewReducer,
    editorReducer,
    bookLabelReducer,
    publisherReducer,
    reportPublisherReducer,
    reportEditorReducer,
    reportWriterEditorReducer,
    reportBookSaleReducer,
    reportBuyAllBookReducer,
    reportWriterDetailReducer,
    reportMemberWithdrawReducer,
    bookPublishReducer,
    reportPublishBookReducer,
    reportGiftReducer,
    reportInviteReducer,
    bookMessageReducer,
    bookCharacterReducer,
    reportHistoryReducer,
    reportBuyWholeBookReducer
})
import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../../constants";
import { Card, CardHeader, CardBody, Row, Col } from "shards-react";
import { httpClient } from "../../../../utils/HttpClient";
import Swal from "sweetalert2";
import ChapterForm from "../chapterForm";
import * as Yup from "yup";
import { getBookByID } from "../../../../actions/book.action";
import { connect } from "react-redux";
import { Button } from 'antd';
import imageCompression from "browser-image-compression";

const chapterValidationSchema = Yup.object().shape({
  chaptor: Yup.string().required("กรุณาระบุ Chapter Number"),
  chaptor_title: Yup.string().required("กรุณาระบุ Chapter Title"),
  chaptor_price: Yup.string().required("กรุณาระบุ ราคา (เหรียญทอง)"),
  chaptor_silver_price: Yup.string().required("กรุณาระบุ ราคา (เหรียญเงิน)"),
  silver_coin_use_checked: Yup.boolean(),
  chaptor_silver_price: Yup.string().when('silver_coin_use_checked', {
    is: true,
    then: Yup.string().required('กรุณาระบุ ราคา (เหรียญเงิน)'),
    otherwise: Yup.string()
  }),
  published_date: Yup.date().required("กรุณาเลือกวันที่เผยแพร่"),
});

class CreateChapter extends Component {
  constructor() {
    super();
    this.state = {
      book_id: null,
      characters: [],
      chapterContent: "",
      chapterTime: null,
      mainCharacter: null,
      isChatBook: false,
      chapterThumbnailFile: '',
      chapter: {
        chaptor: "",
        book_chaptor_name: "",
        chaptor_title: "",
        chaptor_price: "",
        chaptor_silver_price: "",
        chaptor_content: "",
        published_date: "",
        book_chaptor_status: "I",
        free_only_member: 'N',
        free_only_member_checked: false,
        silver_coin_use: "Y",
        silver_coin_use_checked: true,
        chapter_thumbnail: "",
        audioUrl: ""
      }
    }

    this.updateMainCharacter = this.updateMainCharacter.bind(this)
    this.updateContent = this.updateContent.bind(this)
    this.updateTime = this.updateTime.bind(this)
    this.updateThumbnail = this.updateThumbnail.bind(this)
    this.removeThumbnail = this.removeThumbnail.bind(this)
    this.updateAudio = this.updateAudio.bind(this)
  }

  async componentDidMount() {
    let bookId = this.props.match.params.book_id;
    await this.props.getBookByID(bookId);

    if(this.props.bookReducer.book) {
      const isChatBook = this.props.bookReducer.book.categories.filter(item => item.category.category_id === 12).length > 0

      await this.setState({
        isChatBook,
        characters: this.props.bookReducer.book.characters,
        book_id: bookId,
      });
    }
  }

  updateThumbnail(preview, file) {
    const chapter = this.state.chapter
    chapter.chapter_thumbnail = preview

    this.setState({ 
      chapter: chapter,
      chapterThumbnailFile: file 
    });
  } 

  removeThumbnail() {
    this.setState({ 
      chapterThumbnailFile: null
    });
  }

  updateAudio(url) {
    this.setState({audioUrl: url})
  }

  updateContent(html) {
    this.setState({ chapterContent: html });
  } 

  updateTime(time) {
    this.setState({ chapterTime: time });
  } 

  updateMainCharacter(id) {
    this.setState({mainCharacter: id})
  }

  async uploadImage(image) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
      initialQuality: 0.8
    }
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, {type: image.type});

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        const token = localStorage.getItem("token");
        formData.append("upload", imagefile);
        
        const response = await httpClient.post(server.IMG_URL, formData, {
          headers: {
            enctype: "multipart/form-data",
            "Authorization": `Bearer ${token}`
          },
        });

        if (response.status === 201 || response.status === 200) {
          return response.data.url
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง",
        timer: 3000
      });
    }
  }

  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => { 
    return (
      <div style={{ padding: 10 }}>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <Card>
            <CardHeader className="border-bottom">
              <div className="d-flex align-items-center">
                <h6 className="m-0 flex-fill">ADD CHAPTER</h6>
                <Button
                  type="button"
                  loading={isSubmitting}
                  className="btn btn-primary pull-right"
                  style={{ marginRight: 10, color: "white", width: 100 }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  บันทึก
                </Button>
              </div>
            </CardHeader>
            <CardBody className="pt-3">
              <Row>
                <Col>
                  <ChapterForm
                    values={{
                      ...values,
                      isChatBook: this.state.isChatBook,
                      main_character: this.state.mainCharacter,
                      book_id:this.state.book_id
                    }}
                    handleChange={handleChange}
                    onContentChange={this.updateContent}
                    onTimeChange={this.updateTime}
                    onUpdateThumbnail={this.updateThumbnail}
                    onRemoveThumbnail={this.removeThumbnail}
                    onUpdateAudio={this.updateAudio}
                    handleMainCharaterChange={this.updateMainCharacter}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  ></ChapterForm>

                  <div className="text-right mt-5">
                    <Button
                      type="button"
                      loading={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      บันทึก
                    </Button>
                    <a
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </a>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </form>
      </div>
    );
  };

  onSendNotification() {
    Swal.fire({
      title: "Push notification",
      text: "ยืนยันการส่ง Push Notification ให้ User ที่ติดตามหนังสือเล่มนี้?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ส่ง",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.sendNewChapterNotification();
      }
    });
  }

  async sendNewChapterNotification() {
    let params = {
      bookId: this.state.chapter.book_id,
      bookChapterId: this.state.chapter.book_chaptor_id,
    };
    
    const token = localStorage.getItem("token");
    let response = await httpClient.post(
      `admin/member/push-notification/new-chapter`,
      JSON.stringify(params),
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        { this.state.book_id &&
          <Formik
            initialValues={{
              ...this.state.chapter,
              book_id: this.state.book_id,
              isChatBook: this.state.isChatBook,
              characters: this.state.characters,
              main_character: this.state.mainCharacter
            }}
            validationSchema={chapterValidationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              let username = "";
              let result = localStorage.getItem("user_profile");
              if (result) username = JSON.parse(result).user_username;
              
              let price = Number(
                values.chaptor_price.toString().replace(/,/g, "")
              );
              
              let price_silver = Number(
                values.chaptor_silver_price.toString().replace(/,/g, "")
              );
              
              let price_audio_gold = "";
              if(values.audio_gold_price)
              {
                  price_audio_gold = Number(values.audio_gold_price.toString().replace(/,/g, ""));
              }
              else
                price_audio_gold = 0;

              let price_audio_silver = "";
              if(values.audio_silver_price)
              {
                price_audio_silver = Number(values.audio_silver_price.toString().replace(/,/g, ""));
              }
              else
                price_audio_silver  = 0;

              //package
              let price_package_gold = "";
              let price_package_silver = "";

              if(values.package_gold_price)
              {
                price_package_gold = Number(values.package_gold_price.toString().replace(/,/g, ""));
              }
              else
                price_package_gold = 0;

              if(values.package_silver_price)
              {
                price_package_silver = Number(values.package_silver_price.toString().replace(/,/g, ""));
              }
              else
                price_package_silver = 0;

              values.published_date = new Date(values.published_date)
              let startHour = 0
              let startMinute = 0

              if(this.state.chapterTime)  {
                startHour = this.state.chapterTime.getHours();
                startMinute = this.state.chapterTime.getMinutes();
              }

              values.published_date.setHours(startHour);
              values.published_date.setMinutes(startMinute);

              values.free_only_member =  values.free_only_member_checked ? 'Y' : 'N'
              values.silver_coin_use =  values.silver_coin_use_checked ? 'Y' : 'N'

              if(this.state.chapterThumbnailFile) {
                values.chapter_thumbnail = await this.uploadImage(this.state.chapterThumbnailFile)
              }

              let params = {
                chaptor: values.chaptor,
                book_id: this.state.book_id,
                book_chaptor_name: values.book_chaptor_name,
                chaptor_title: values.chaptor_title,
                published_date: values.published_date,
                chaptor_price: price || 0,
                chaptor_silver_price: price_silver || 0,
                chaptor_content: this.state.chapterContent,
                book_chaptor_status: values.book_chaptor_status,
                free_only_member: values.free_only_member,
                created_by: username,
                silver_coin_use: values.silver_coin_use,
                chapter_audio_file: this.state.audioUrl,
                chapter_thumbnail: values.chapter_thumbnail,
                audio_gold_price: price_audio_gold || 0,
                audio_silver_price: price_audio_silver || 0,
                package_gold_price: price_package_gold || 0,
                package_silver_price: price_package_silver || 0,
              };

              const token = localStorage.getItem("token");
              let response = await httpClient.post(
                server.BOOK_CHAPTER_URL,
                JSON.stringify(params),
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                  },
                }
              );

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "ข้อมูล ถูกบันทึกเรียบร้อยแล้ว",
                  showConfirmButton: false,
                  timer: 3000,
                });
                setSubmitting(false);

                if (params.book_chaptor_status === "A") {
                  // this.onSendNotification();
                }

                this.props.history.push({
                  pathname: `/book_chapters/edit/${response.data.book_chaptor_id}`,
                })
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                  timer: 3000,
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookReducer }) => ({ bookReducer });

const mapDispatchToProps = {
  getBookByID
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateChapter);


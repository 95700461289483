import { httpClient } from "../utils/HttpClient";

import {
  HTTP_WITHDRAW_TRANS_SUCCESS,
  HTTP_WITHDRAW_TRANS_FETCHING,
  HTTP_WITHDRAW_TRANS_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateWithdrawToSuccess = (payload) => ({
  type: HTTP_WITHDRAW_TRANS_SUCCESS,
  payload: payload,
});

const setStateWithdrawToFetching = () => ({
  type: HTTP_WITHDRAW_TRANS_FETCHING,
});

const setStateWithdrawToFailed = () => ({
  type: HTTP_WITHDRAW_TRANS_FAILED,
});

export const getWithdrawTransaction = (value) => {
  return async (dispatch) => {
    dispatch(setStateWithdrawToFetching());
    await doGetWithdrawTransaction(dispatch, value);
  };
};

const doGetWithdrawTransaction = async (dispatch, value) => {
  try {
    let param = {};
    if (value) {
      if (value.start) value.start = value.start + " 00:00.00";
      else value.start = "";

      if (value.end) value.end = value.end + " 23:59.00";
      else value.end = moment(new Date()).format("YYYY-MM-DD 23:59.00");

      param = {
        page: value.page,
        member_id: value.member_id,
        start_date: value.start,
        end_date: value.end,
      };
    } else {
      param = {
        page: "",
      };
    }
    const token = localStorage.getItem("token");

    const result = await httpClient.get(
      `${server.REPORT_URL}/withdraw/history`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: param,
      }
    );
    dispatch(setStateWithdrawToSuccess(result.data));
  } catch (err) {
    dispatch(setStateWithdrawToFailed());
  }
};

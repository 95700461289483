import React, { Component } from "react";
import {
  getBookLabelByBookID,
  deleteBookLabel,
} from "../../../actions/book_label.action";

import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  Button,
} from "shards-react";

import { Formik } from "formik";
import moment from "moment";
import Swal from "sweetalert2";
import ContentPagination from "../../Layout/Common/Pagination";
import { server } from "../../../constants";
import { httpClient } from "../../../utils/HttpClient";
import imageCompression from "browser-image-compression";

class BookLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookLabel: [],
      currentPage: 0,
      currentBookPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      ModalOpen: false,
      book_id: "",
      label_image: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      ModalOpen: !this.state.ModalOpen,
    });
  }

  closeModal() {
    this.setState({
      ModalOpen: !this.state.ModalOpen,
    });
  }

  async addLabel(book_id, label, label_image) {
    if (!label) {
      Swal.fire({
        icon: "error",
        text: "กรุณาระบุ ข้อความ",
        timer: 1500,
      });
      return;
    }

    let result = localStorage.getItem("user_profile");
    let username;
    if (result) username = JSON.parse(result).user_username;

    let dateTime = new Date();
    dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

    let formData = new FormData();

    formData.append("book_id", book_id);
    formData.append("label", label);
    formData.append("label_image", label_image);
    formData.append("dbstatus", "A");
    formData.append("created_datetime", dateTime);
    formData.append("created_by", username);

    formData.append("updated_datetime", dateTime);
    formData.append("updated_by", username);

    let object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    let json = JSON.stringify(object);

    let response = "";

    const token = localStorage.getItem("token");
    response = await httpClient.post(`${server.BOOK_LABEL_URL}`, json, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    if (response.status === 201 || response.status === 200) {
      Swal.fire({
        icon: "success",
        title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });

      this.setState({
        ModalOpen: false,
      });

      this.getBookLabel(this.state.currentPage);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
        timer: 1500,
      });
    }
  }

  async componentDidMount() {
    let bookID = this.props.match.params.book_id;
    await this.setState({ book_id: bookID });

    if(this.props.location.bookPage)
      await this.setState({ currentBookPage: this.props.location.bookPage });

    await this.getBookLabel(this.state.currentPage);
    if (this.props.bookLabelReducer.bookLabel) {
      this.setState({ bookLabel: this.props.bookLabelReducer.bookLabel });
    }
  }

  async getBookLabel(page) {
    this.setState({ loading: true });

    await this.props.getBookLabelByBookID(this.state.book_id, page);

    const { bookLabel, total } = this.props.bookLabelReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      bookLabel,
      totalPages,
      loading: false,
    });
  }

  formatDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatAction(id) {
    return (
      <div>
        <button
          type="button"
          className="btn btn-xs btn-danger"
          onClick={() => {
            return Swal.fire({
              title: "Are you sure to delete?",
              text: "You won't be able to revert this!",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, cancel!",
            }).then((result) => {
              if (result.value) {
                this.deleteBookLabel(id);
              }
            });
          }}
        >
          Delete
        </button>
      </div>
    );
  }

  async deleteBookLabel(id) {
    await this.props.deleteBookLabel(id, this.state.book_id);
    setTimeout(() => {
      this.getBookLabel(this.state.currentPage);
    }, 100);
  }

  async uploadImage(image) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1200,
      useWebWorker: true,
      initialQuality: 0.8
    }
    try {
      const compressedFile = await imageCompression(image, options);
      const file = new File([compressedFile], image.name, { type: image.type });

      // Call Api For Upload Image
      if (image) {
        var formDataImg = new FormData();
        var imagefile = file;
        formDataImg.append("upload", imagefile);

        let response_img = await httpClient.post(server.IMG_URL, formDataImg, {
          headers: {
            processData: false,
            enctype: "multipart/form-data",
          },
        });

        if (response_img.status === 201 || response_img.status === 200) {
          if (response_img.data.uploaded === 1) {
            this.setState({
              label_image: response_img.data.url,
            });
          }
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง",
        timer: 3000,
      });
    }
  }

  formatImage(item) {
    if (item) {
      return (
        <img
          id="label_image"
          name="label_image"
          src={item}
          width="100px"
        />
      );
    }
  }

  renderTable = () => {
    const ImageLabel = ({ name, value, onChange, placeholder }) => {
      if (value) {
        return (
          <img
            style={{ maxWidth: 300 }}
            id="label_image"
            name="label_image"
            src={value}
          />
        );
      } else {
        return (
          <img
            style={{ maxWidth: 300 }}
            id="label_image"
            name="label_image"
            src={this.state.label_image}
          />
        );
      }
    };
    return (
      <div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="text-center">Label</th>
              <th className="text-center">รูป Label</th>
              <th className="text-center">Last Update</th>
              <th className="text-center">Update By</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.bookLabel.map((label, index) => {
              return (
                <tr key={label.id}>
                  <td className="text-center align-middle" width="100">
                    {label.label}
                  </td>

                  <td className="text-center align-middle" width="150">
                    {" "}
                    {this.formatImage(label.label_image)}
                  </td>

                  <td className="text-center align-middle" width="60">
                    {this.formatDateTime(label.updated_datetime)}
                  </td>

                  <td className="text-center align-middle" width="80">
                    {label.updated_by}
                  </td>

                  <td className="text-center align-middle" width="30">
                    {this.formatAction(label.id)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Modal open={this.state.ModalOpen} toggle={this.toggleModal}>
          <ModalHeader>เพิ่ม Label</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                label: "",
                label_image: "",
              }}
              render={({ values, handleChange, setFieldValue }) => (
                <React.Fragment>
                  <Row form>
                    <Col md="12" className="form-group">
                      <label>Book Label</label>
                      <FormInput
                        name="label"
                        id="label"
                        placeholder="Label"
                        onChange={handleChange}
                        value={values.label}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="form-group">
                      <label>รูป Label</label>
                      <input
                        id="label_image"
                        name="label_image"
                        type="file"
                        onChange={(event) => {
                          this.uploadImage(
                            event.currentTarget.files[0],
                            "label_image"
                          );
                        }}
                        className="form-control"
                      />
                      <div sm="10" className="ml-2 mt-3 text-center">
                        <ImageLabel value={this.state.label_image} />
                      </div>
                    </Col>
                  </Row>

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-primary pull-right"
                      onClick={() => {
                        this.addLabel(
                          this.state.book_id,
                          values.label,
                          this.state.label_image
                        );
                      }}
                      style={{
                        marginRight: 10,
                        color: "white",
                        width: 100,
                      }}
                    >
                      บันทึก
                    </button>
                    <button
                      onClick={(e) => this.closeModal()}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </React.Fragment>
              )}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getBookLabel(page);
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 20 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Book Label</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={(e) => this.toggleModal()}
                      className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      เพิ่ม Label
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.bookLabel.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>

                <div className="text-right">
                  <Button
                    onClick={() => {
                      this.props.history.push({
                        pathname: `/book`,
                        page: this.state.currentBookPage,
                      })
                    }}
                    type="Button"
                    className="btn btn-danger mr-2"
                  >
                    Back
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ bookLabelReducer }) => ({
  bookLabelReducer,
});

const mapDispatchToProps = {
  getBookLabelByBookID,
  deleteBookLabel,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookLabel);

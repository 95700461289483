export const HTTP_TOPUP_FETCHING = "HTTP_TOPUP_FETCHING";
export const HTTP_TOPUP_SUCCESS = "HTTP_TOPUP_SUCCESS";
export const HTTP_TOPUP_FAILED = "HTTP_TOPUP_FAILED";

export const HTTP_PURCHASE_FETCHING = "HTTP_PURCHASE_FETCHING";
export const HTTP_PURCHASE_SUCCESS = "HTTP_PURCHASE_SUCCESS";
export const HTTP_PURCHASE_FAILED = "HTTP_PURCHASE_FAILED";

// Member Page
export const HTTP_MEMBERS_FETCHING = "HTTP_MEMBERS_FETCHING";
export const HTTP_MEMBERS_SUCCESS = "HTTP_MEMBERS_SUCCESS";
export const HTTP_MEMBERS_FAILED = "HTTP_MEMBERS_FAILED";

export const HTTP_MEMBER_FETCHING = "HTTP_MEMBER_FETCHING";
export const HTTP_MEMBER_SUCCESS = "HTTP_MEMBER_SUCCESS";
export const HTTP_MEMBER_FAILED = "HTTP_MEMBER_FAILED";

export const HTTP_WRITERS_FETCHING = "HTTP_WRITERS_FETCHING";
export const HTTP_WRITERS_SUCCESS = "HTTP_WRITERS_SUCCESS";
export const HTTP_WRITERS_FAILED = "HTTP_WRITERS_FAILED";

export const HTTP_WITHDRAW_TRANSACTIONS_FETCHING = "HTTP_WITHDRAW_TRANSACTIONS_FETCHING";
export const HTTP_WITHDRAW_TRANSACTIONS_SUCCESS = "HTTP_WITHDRAW_TRANSACTIONS_SUCCESS";
export const HTTP_WITHDRAW_TRANSACTIONS_FAILED = "HTTP_WITHDRAW_TRANSACTIONS_FAILED";

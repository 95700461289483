import React, { Component } from "react";
import * as actions from "../../actions/redeem.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";

import XLSX from 'xlsx';
import moment from "moment";
import Swal from "sweetalert2";

class Redeem extends Component {
  constructor() {
    super();

    this.state = {
      redeemCode: '',
      isSearchError: false,
      searchErrorMessage: ''
    }

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.searchRedeemCode = this.searchRedeemCode.bind(this);
  }

  async componentDidMount() {
    await this.props.getRedeemList();
  }

  getPartnerName(partnerId)  {
    if(partnerId === '1') {
      return 'PT'
    } else if(partnerId === '2') {
      return 'Book Fair 20'
    } else if(partnerId === '3') {
      return 'Book Fair 100'
    } else if(partnerId === '4') {
      return 'Book Fair 1000'
    } else if(partnerId === '5') {
      return '2read'
    } else if(partnerId === '6') {
      return 'Stock2read'
    }
  }

  async exportData(groupId)  {
    await this.props.getRedeemItemList(groupId);

    if(!this.props.redeemReducer.isitemListError)  {
      let redeemCodes = this.props.redeemReducer.itemList.map(item => {
        return [
          item.code,
          moment(item.valid_date).format("DD/MM/YYYY")
        ]
      })
      
      redeemCodes = [['code',"วันที่หมดอายุ"], ...redeemCodes]

      if(redeemCodes) {
        this.downloadExportData(redeemCodes)
      }
    }
  }

  downloadExportData(redeemCodes)  {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(redeemCodes);
    // const workSheet = XLSX.utils.json_to_sheet(redeemCodes);
    XLSX.utils.book_append_sheet(workBook, workSheet, ['redeem']);
    XLSX.writeFile(workBook, `redeem-code.xlsx`);
  }

  renderGeneratedCode()  {
    try {
      const { result, isFetching } = this.props.redeemReducer;
      
      if(!isFetching && result) {
        return result.generatedCodes.map((item, index) => (
          <tr key={index}>
            <td style={{ textAlign: "center" }}>
              { item.name }
            </td>
            <td style={{ textAlign: "center" }}>
              { moment(item.valid_date).format("DD/MM/YYYY") }
            </td>
            <td style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.coin)}
            </td>
            <td style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.used)}
            </td>
            <td style={{ textAlign: "center" }}>
            {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.remaining)}
            </td>
            <td style={{ textAlign: "center" }}>
            {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.total)}
            </td>
            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-sm btn-secondary mr-2"
                onClick={() =>
                  this.props.history.push({
                    pathname: `/editRedeem/${item.group_id}`,
                    type: 'group'
                  })
                }
              >
                Edit
              </button>
             
              <button
                onClick={() => {
                  this.exportData(item.group_id);
                }}
                type="button"
                className="btn btn-sm  btn-danger"
              >
                Export
              </button>
            </td>
          </tr>
        ))
      }
    } catch(e) {
    }
  }

  renderCustomCode()  {
    try {
      const { result, isFetching } = this.props.redeemReducer;

      if(!isFetching && result) {
        return result.customCodes.map((item, index) => (
          <tr key={index}>
            <td style={{ textAlign: "center" }}>
              { item.name }
            </td>
            <td style={{ textAlign: "center" }}>
              { item.code }
            </td>
            <td style={{ textAlign: "center" }}>
              { moment(item.valid_date).format("DD/MM/YYYY") }
            </td>
            <td style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.coin)}
            </td>
            <td style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(item.total_used)}
            </td>
            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-sm btn-secondary mr-2"
                onClick={() =>
                  this.props.history.push({
                    pathname: `/editRedeem/${item.id}`,
                    type: 'custom'
                  })
                }
              >
                Edit
              </button>
            </td>
          </tr>
        ))
      }
    } catch(e) {
    }
  }

  handleSearchChange(event) {
    this.setState({redeemCode: event.target.value});
  }

  async searchRedeemCode(event) {
    event.preventDefault();
    
    if(this.state.redeemCode) {
      await this.props.getRedeemByCode(this.state.redeemCode);
    
      if(!this.props.redeemReducer.isSearchError)  {
        if(this.props.redeemReducer.item) {

        } else {
          this.showSearchError('ไม่พบ Code นี้ในระบบ')
        }
      } else {
        this.showSearchError('เกิดข้อผิดพลาดในการค้นหาข้อมูล กรุณาลองใหม่อีกครั้ง')
      }
    }
  }

  showSearchError(errorMessage) {
    this.setState({
      isSearchError: true,
      searchErrorMessage: errorMessage
    })
  }

  renderRedeemStatus()  {
    if(this.props.redeemReducer.item)  {
      if(this.props.redeemReducer.item.status === '1')  {
        return 'ใช้งานได้'
      } else if(this.props.redeemReducer.item.status === '2')  {
        return 'ถูกใช้แล้ว'
      } else if(this.props.redeemReducer.item.status === '3')  {
        return 'ปิดการใช้งาน'
      } else  {
        return 'หมดอายุ'
      }
    }
  }

  render() {
    const codeSearchResult = this.props.redeemReducer.item

    return (
      <Container fluid className="main-content-container px-4">
        <Card style={{marginTop: '30px'}}>
          <CardHeader className="border-bottom">
            <Row className="align-items-center">
              <Col sm="6" className="d-flex mb-2 mb-sm-0">
                <h6 className="m-0">Generated Code (One-time use)</h6>
              </Col>
              <Col>
                <Button
                  onClick={() => this.props.history.push(`/redeemGenerate`)}
                  className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                >
                  Generate Redeem Code
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table
              id="redeem_table"
              className="table mb-0"
            >
              <thead>
                <tr>
                  <th className="border-0"style={{ textAlign: "center" }}>Campaign</th>
                  <th className="border-0" style={{ textAlign: "center" }}>วันหมดอายุ</th>
                  <th className="border-0" style={{ textAlign: "center" }}>จำนวนเหรียญที่ได้</th>
                  <th className="border-0" style={{ textAlign: "center" }}>Code ที่ใช้แล้ว</th>
                  <th className="border-0" style={{ textAlign: "center" }}>Code ที่ยังไม่ใช้</th>
                  <th className="border-0" style={{ textAlign: "center" }}>Code ทั้งหมด</th>
                  <th className="border-0" style={{ textAlign: "center" }}></th>
                </tr>
              </thead>
              <tbody>{this.renderGeneratedCode()}</tbody>
            </table>
          </CardBody>
        </Card>

        <Card style={{marginTop: '30px'}}>
          <CardHeader className="border-bottom">
            <Row className="align-items-center">
              <Col sm="6" className="d-flex mb-2 mb-sm-0">
                <h6 className="m-0">Custom Code</h6>
              </Col>
              <Col>
                <Button
                  onClick={() => this.props.history.push(`/redeemGenerate`)}
                  className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                >
                  Create Code
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table
              id="redeem_table"
              className="table mb-0"
            >
              <thead>
                <tr>
                  <th className="border-0"style={{ textAlign: "center" }}>Campaign</th>
                  <th className="border-0" style={{ textAlign: "center" }}>Code</th>
                  <th className="border-0" style={{ textAlign: "center" }}>วันหมดอายุ</th>
                  <th className="border-0" style={{ textAlign: "center" }}>จำนวนเหรียญที่ได้</th>
                  <th className="border-0" style={{ textAlign: "center" }}>จำนวนครั้งที่ใช้แล้ว</th>
                  <th className="border-0" style={{ textAlign: "center" }}></th>
                </tr>
              </thead>
              <tbody>{this.renderCustomCode()}</tbody>
            </table>
          </CardBody>
        </Card>

        <Card className="my-5">
          <CardHeader className="border-bottom">
            <h6 className="m-0">ตรวจสอบสถานะของ Code</h6>
          </CardHeader>
          
          <CardBody className="p-0">
            <div className="border-bottom pl-3">
              <Form 
                className="w-100 d-flex" 
                onSubmit={this.searchRedeemCode}
              >
                <InputGroup seamless>
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons" style={{fontSize: '18px'}}>search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput
                    className="border-0 no-shadow pl-5"
                    placeholder="ค้นหา Redeem Code"
                    value={this.state.redeemCode} 
                    onChange={this.handleSearchChange}
                    style={{height: '56px', fontSize: '16px'}}
                  />
                </InputGroup>
              </Form>
            </div>

            { !this.props.redeemReducer.isSearchError && this.props.redeemReducer.item ?
              <table
                className="table mb-0"
              >
                <thead>
                  <tr>
                    <th className="border-0"style={{ textAlign: "center" }}>Code</th>
                    <th className="border-0"style={{ textAlign: "center" }}>Campaign</th>
                    <th className="border-0" style={{ textAlign: "center" }}>จำนวนเหรียญที่ได้</th>
                    <th className="border-0" style={{ textAlign: "center" }}>วันหมดอายุ</th>
                    <th className="border-0" style={{ textAlign: "center" }}>User ที่ redeem</th>
                    <th className="border-0" style={{ textAlign: "center" }}>วันที่ Redeem</th>
                    <th className="border-0" style={{ textAlign: "center" }}>จำนวนครั้งที่ Redeem</th>
                    <th className="border-0" style={{ textAlign: "center" }}>สถานะ</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{ textAlign: "center" }}>{ codeSearchResult.code }</td>
                    <td style={{ textAlign: "center" }}>{ this.getPartnerName(codeSearchResult.partner) }</td>
                    <td style={{ textAlign: "center" }}>{ codeSearchResult.coin }</td>
                    <td style={{ textAlign: "center" }}>{ new Date(codeSearchResult.valid_date).toLocaleDateString("en-GB") }</td>
                    <td style={{ textAlign: "center" }}>
                      {codeSearchResult.member_name || codeSearchResult.member_email ?
                        <React.Fragment>
                          <div>{codeSearchResult.member_name}</div>
                          <div className="text-muted" style={{fontSize: '12px'}}>{codeSearchResult.member_email}</div>
                        </React.Fragment>
                         : '-'
                      }
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {codeSearchResult.redeem_date ? new Date(codeSearchResult.redeem_date).toLocaleDateString("en-GB") : '-'}
                    </td>
                    <td style={{ textAlign: "center" }}>{ codeSearchResult.totalRedeem }</td>
                    <td style={{ textAlign: "center" }}>{ this.renderRedeemStatus() }</td>
                  </tr>
                </tbody>
              </table>
              : this.state.isSearchError ? <div className="text-center mt-4 mb-3">{ this.state.searchErrorMessage }</div> : null
            }
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = ({ redeemReducer, appReducer }) => ({
  redeemReducer,
  appReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Redeem);

import React, { Component } from "react";
import { login , autoLogin } from "./../../actions/login.action";
import { getUserByUsername} from "./../../actions/profile.action";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
} from "shards-react";

class Login extends Component {
  componentDidMount() {
    localStorage.clear();
  }
  showForm = ({ values, handleChange, handleSubmit, isSubmitting }) => {
    return (
      <Container fluid className="mx-auto mt-5 pt-5">
        <Row noGutters className=" h-auto">
          <Col lg="3" md="6" className="mx-auto">
            <Card>
              <Form className="form-horizontal" onSubmit={handleSubmit}>
                <CardBody>
                  <img
                    className="d-table mx-auto mb-3"
                    src={require("../../assets/img/Logo.png")}
                    style={{ height: 100 }}
                    alt="2Read Admin"
                  />

                  <h5 className="auth-form__title text-center mb-4">
                    Welcome to 2READ
                  </h5>

                  <FormGroup>
                    <label htmlFor="username">Username</label>
                    <FormInput
                      name="user_username"
                      id="user_username"
                      placeholder="Enter Username"
                      onChange={handleChange}
                      value={values.user_username}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password">Password</label>
                    <FormInput
                      name="user_password"
                      id="user_password"
                      type="password"
                      placeholder="Enter Password"
                      onChange={handleChange}
                      value={values.user_password}
                    />
                  </FormGroup>

                  <FormGroup>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary btn-block d-table mt-4"
                    >
                      LOG IN
                    </button>
                  </FormGroup>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            user_username: "",
            user_password: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
              const params = {
                user_username: values.user_username,
                user_password: values.user_password
              }

              this.props.login(this.props.history, params)

              // this.props.getUserByUsername(values.user_username);
              setSubmitting(false);
              
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({loginReducer,profileReducer}) => ({ loginReducer,profileReducer })

const mapDispatchToProps = {
  login,autoLogin,getUserByUsername
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

import React, { Component } from "react";

import { Row, Col, Button, ButtonGroup, ListGroup } from "shards-react";
import { NavLink } from "react-router-dom";

export default class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountantReport: false,
      marketingReport: false,
    };
  }

  componentDidMount() {
    const userProfile = JSON.parse(localStorage.getItem("user_profile"));

    if (userProfile) {
      this.setState({
        accountantReport: userProfile.accountant_report === "Y",
        marketingReport: userProfile.marketing_report === "Y",
      });
    }
  }

  render() {
    return (
      <React.Fragment>
{/*         
        <Row className="mt-2">
          <Col className="form-group">
            <ListGroup flush>
              <label className="text-semibold h5">Select Report</label>
              {this.state.accountantReport && (
                <ButtonGroup>
                  <Row>
                    <Col>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/order"
                        size="sm"
                      >
                        Order
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/payment"
                        size="sm"
                      >
                        Payment
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/purchase"
                        size="sm"
                      >
                        Purchase
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/silver_coin"
                        size="sm"
                      >
                        Silver Coin
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/silverCoinTrans"
                        size="sm"
                      >
                        Silver Coin Transaction
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/writer"
                        size="sm"
                      >
                        Writer Sale Report
                      </Button>
                    </Col>
                  </Row>
                </ButtonGroup>
              )}
            </ListGroup>
          </Col>
        </Row> */}

        {(this.state.marketingReport || this.state.accountantReport) && (
          <Row className="mt-2">
            <Col className="form-group">
              <ListGroup flush>
                <ButtonGroup>
                  <Row>
                    <Col>
                      {/* <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/book_rank"
                        size="sm"
                      >
                        ยอดการซื้อหนังสือ
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/view_rank"
                        size="sm"
                      >
                        ยอดการดูหนังสือ
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/chapter_rank"
                        size="sm"
                      >
                        ยอดขายแต่ละบท
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/member_purcase_rank"
                        size="sm"
                      >
                        ยอดการซื้อเหรียญ
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/member_trans_rank"
                        size="sm"
                      >
                        ยอดการใช้เหรียญ
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/buy/all"
                        size="sm"
                      >
                        สมาชิกที่ซื้อหนังสือครบทุกตอน
                      </Button> */}
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/gift"
                        size="sm"
                      >
                        ประวัติการส่งของขวัญ
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/invite"
                        size="sm"
                      >
                        ประวัติการชวนเพื่อน
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/history"
                        size="sm"
                      >
                        ประวัติการลงหนังสือ
                      </Button>
                      {/* <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/report/buy_whole_book"
                        size="sm"
                      >
                        ยอดการซื้อหนังสือทั้งเล่ม
                      </Button> */}
                    </Col>
                  </Row>
                </ButtonGroup>
              </ListGroup>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

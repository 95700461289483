import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { server } from "../../../constants";
import { FormInput, FormTextarea } from "shards-react";
import { Modal, Button } from 'antd';
import { httpClient } from "../../../utils/HttpClient";
import { PlusCircleOutlined, PictureOutlined } from '@ant-design/icons';
import BookCharacterItem from '../BookChapter/BookCharacterItem';
import BookCharacterItemLoading from '../BookChapter/BookCharacterItemLoading';
import { getBookCharacters, addBookCharacter, updateBookCharacter } from "../../../actions/book_character.action";
import { setMainCharacter } from "../../../actions/book_chapter.action";
import imageCompression from "browser-image-compression";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

class BookCharacterGroup extends Component {
  constructor() {
    super();
    this.state = { 
      loading: false,
      characterModalOpen: false,
      cropModalOpen: false,
      characterId: '',
      characterImage: '',
      characterName: '',
      characterRole: '',
      characterStatus: '',
      characterBio: '',
      imageUrl: '',
      imageFile: '',
      croppedImageFile: '',
    }

    this.cropperRef = React.createRef();
  }

  async componentDidMount() {
    await this.props.getBookCharacters(this.props.bookId)

    if(this.props.bookCharacterReducer.characters.length > 0){
      this.props.setMainCharacter(this.props.bookCharacterReducer.characters[0].id)
    }
  }

  onSelectFile(e)  {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ 
          imageUrl: reader.result,
        })
      );
      reader.readAsDataURL(e.target.files[0]);

      if(e.target.files[0].size/1048576 > 1) {
        Swal.fire({
          icon: "error",
          text: "ไม่สามารถอัพโหลดรูปขนาดเกิน 1MB ได้",
          timer: 3000
        });
      } else {
        this.setState({ 
          cropModalOpen: true,
          imageFile: e.target.files[0]
        })
      }
    }
  };

  async uploadImage(image) {
    const options = {
      maxSizeMB: 0.5,
      useWebWorker: true,
      maxWidthOrHeight: 500,
      initialQuality: 0.7
    }

    try {
      let file = image
      if(image.size > 512000) {
        const compressedFile = await imageCompression(image, options);
        file = new File([compressedFile], image.name, {type: image.type});
      }

      if (image) {
        let formData = new FormData();
        let imagefile = file;

        const token = localStorage.getItem("token");
        formData.append("upload", imagefile);
        
        const response = await httpClient.post(server.IMG_URL, formData, {
          headers: {
            enctype: "multipart/form-data",
            "Authorization": `Bearer ${token}`
          },
        });

        if (response.status === 201 || response.status === 200) {
          this.setState({
            characterImage: response.data.url,
          })
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ กรุณาลองใหม่อีกครั้ง",
        timer: 3000
      });
    }
  }

  async saveCharacter()  {
    if (!this.state.characterImage && !this.state.croppedImageFile) {
      Swal.fire({
        icon: "error",
        text: "กรุณาอัพโหลดรูปตัวละคร",
        timer: 3000
      });

      return
    }

    if (!this.state.characterName) {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอกชื่อตัวละคร",
        timer: 3000
      });

      return
    }

    this.setState({loading: true})
    
    if(this.state.croppedImageFile) {
      await this.uploadImage(this.state.croppedImageFile);
    }

    const params = {
      book_id: this.props.bookId,
      name: this.state.characterName,
      role: this.state.characterRole,
      status: this.state.characterStatus,
      bio: this.state.characterBio,
      image: this.state.characterImage
    }

    if(!this.state.characterId)  {
      await this.props.addBookCharacter(params)
    } else {
      await this.props.updateBookCharacter(this.state.characterId, params)
    }
    
    if(!this.props.bookCharacterReducer.isError)  {
      this.setState({
        characterModalOpen: false,
        characterImage: '',
        characterName: '',
        characterRole: '',
        characterStatus: '',
        characterBio: '',
        characterId: ''
      })

      Swal.fire({
        icon: "success",
        title: "บันทึกข้อมูลตัวละครเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "ไม่สามารถเพิ่มตัวละครได้",
        timer: 1500
      });
    }

    this.setState({loading: false})
  }

  editCharacter(character) {
    this.setState({
      characterModalOpen: true,
      characterId: character.id,
      characterImage: character.image,
      characterName: character.name,
      characterRole: character.role,
      characterStatus: character.status,
      characterBio: character.bio
    })
  }

  cropImage()  {
    const cropper = this.cropperRef.current.cropper
    
    cropper.getCroppedCanvas().toBlob(blob => {
      const fileName = this.state.imageFile.name
      
      const file = new File([blob], fileName, {
        lastModifiedDate: new Date(),
        type: this.state.imageFile.type
      });

      this.setState({
        characterImage: cropper.getCroppedCanvas().toDataURL(),
        croppedImageFile: file
      })
      
      this.closeCropModal()
    })
  }

  openCharacterModal()  {
    this.setState({ characterModalOpen: true })
  }

  closeCropModal()  {
    this.setState({ cropModalOpen: false })
  }

  render() {
    const {
      characters,
      isFetching
    } = this.props.bookCharacterReducer

    return (
      <>
        <div className="d-flex align-items-center mt-4">
          <div className="flex-fill mr-3">
            <h6 className="m-0">ตัวละคร</h6>
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap mt-3">
          <div className="card shadow-none border mr-3 mb-3 chat-character-card add-char-btn" style={{minWidth:66}}>
            <div 
              className="card-body py-2 px-3 d-flex flex-column align-items-center justify-content-center pointer"
              onClick={() => this.setState({characterModalOpen: true})}
            >
              <PlusCircleOutlined style={{fontSize: 18}}/>
              <div className="mt-1 font-12">เพิ่ม</div>
            </div>
          </div>

          { isFetching ?
            <>
              <BookCharacterItemLoading />
            </>
            :
            characters.map(character => {
              return (
                <BookCharacterItem 
                  key={character.id}
                  chapterId={this.props.chapterId}
                  character={character} 
                  onEditCharacter={(character) => this.editCharacter(character)}
                />
              )
            })
          }
        </div>
                
        <Modal 
          title="เพิ่มตัวละคร" 
          footer={null} 
          visible={this.state.characterModalOpen} 
          onCancel={() => this.setState({characterModalOpen: false})}
        >
          <div className="form-group text-center">
            <div className="position-relative mt-2 d-inline-block">
              { this.state.characterImage ?
                <img
                  src={this.state.characterImage}
                  className="img-fluid rounded-circle mt-3"
                  width="100"
                /> :
                
                <div className="image-placeholder d-flex flex-column align-items-center justify-content-center rounded-circle" style={{width: 100, height: 100}}>
                  <div><PictureOutlined style={{fontSize: 30, color: '#bbb'}} /></div>
                  <div className="font-12">อัพโหลดรูป</div>
                </div>
              }

              <input 
                type="file" 
                accept="image/*" 
                className="input-file-hidden" 
                onChange={(event) => {
                  this.onSelectFile(event)
                }}
              />  
            </div>
          </div>

          <div className="form-group mt-4">
            <label>ชื่อตัวละคร</label>
            <FormInput
              value={this.state.characterName}  
              onChange={(event) => this.setState({ characterName: event.target.value })}
            />
          </div>

          <div className="form-group">
            <label>บทบาท</label>
            <FormInput
              value={this.state.characterRole || ''}  
              onChange={(event) => this.setState({ characterRole: event.target.value })}
            />
          </div>

          <div className="form-group">
            <label>สถานะ</label>
            <FormInput
              value={this.state.characterStatus || ''}  
              onChange={(event) => this.setState({ characterStatus: event.target.value })}
            />
          </div>

          <div className="form-group">
            <label>Bio</label>
            <FormTextarea
              value={this.state.characterBio || ''}  
              rows="3"
              onChange={(event) => this.setState({ characterBio: event.target.value })}
            />
          </div>
          
          <div className="text-right mt-4">
            <Button
              type="button"
              className="btn btn-primary mr-2"
              loading={this.state.loading}
              onClick={() => {
                this.saveCharacter()
              }}
              style={{ width: 100 }}
            >
              บันทึก
            </Button>
            <Button
              onClick={() => {
                this.setState({ characterModalOpen: false })
              }}
              type="button"
              className="btn btn-danger"
              style={{ color: "white", width: 100 }}
            >
              ยกเลิก
            </Button>
          </div>
        </Modal>

        <Modal 
          title={null}
          footer={null}
          width={600}
          maskClosable={false}
          visible={this.state.cropModalOpen} 
          onCancel={() => this.setState({cropModalOpen: false})}
        >  
          <div className="d-flex">
            <div className="flex-fill thumbnail-crop-area">
              <Cropper
                src={this.state.imageUrl}
                aspectRatio={9/9}
                autoCropArea={1}
                preview=".thumbnail-preview"
                viewMode={3}
                ref={this.cropperRef}
              />
            </div>

            <div className="mt-4 thumbnail-preview-area ml-4">
              <div className="font-weight-bold text-center mb-2">ตัวอย่างรูป</div>
              <div className="thumbnail-preview" />
            </div>
          </div>

          <div className="mt-5 text-center">
            <button
              type="button"
              onClick={() => {
                this.cropImage()
              }}
              className="btn btn-primary mr-2"
            >
              ตกลง
            </button>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                this.setState({ cropModalOpen: false })
              }}
            >
              ยกเลิก
            </button>
          </div>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({
  bookCharacterReducer
}) => ({
  bookCharacterReducer
});

const mapDispatchToProps = {
  getBookCharacters,
  addBookCharacter,
  updateBookCharacter,
  setMainCharacter
};

export default connect(mapStateToProps, mapDispatchToProps)(BookCharacterGroup);

import React, { Component, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { Row, Col, FormInput, Card, CardHeader, CardBody } from "shards-react";
import {
  Table,
  Tag,
  Button,
  Skeleton,
  Modal,
  message,
  DatePicker,
  Input,
  AutoComplete,
  Select,
} from "antd";
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";

const { RangePicker } = DatePicker;
const { Column } = Table;
const Excel = require("exceljs");
const { Option } = Select;

export default function ReportPurchases() {
  const [loading, setLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [memberNameOptions, setMemberNameOptions] = useState([]);
  const [bookTitleOptions, setBookTitleOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    data_start: "",
    date_end: "",
    member_name: "",
    book_title: "",
    page: 0,
    sortingColumn: "",
    sortingOrder: "",
    searchType: "",
  });
  const [dataSorting, setDataSorting] = useState({
    name: "",
    order: "",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });
  const [searchType, setSearchType] = useState("eachChapter");

  useEffect(() => {
    getReportPurchase();
  }, [filter]);

  const getReportPurchase = async () => {
    let pageCurrent = 0;
    const current = localStorage.getItem("purchase_current_page");

    if (current) {
      pageCurrent = Number(current);
    } else pageCurrent = filter.page;

    setHistoryLoading(true);
    try {
      const token = localStorage.getItem("token");

      let url = "";
      if (searchType === "eachChapter") url = "admin/report/purchase_history";
      else url = "admin/report/purchase_all_book";

      const result = await httpClient.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: pageCurrent,
        },
      });

      const tableRows = result.data.purchases.map((item, index) => {
        return {
          key: index.toString(),
          payment_datetime: item.payment_datetime,
          member_id: item.member_id,
          member_name: item.member_name,
          book_title: item.book_title,
          chapter_title: item.chapter_title,
          gold_coin: item.gold_coin,
          silver_coin: item.silver_coin,
          audio_gold_coin: item.audio_gold_coin,
          audio_silver_coin: item.audio_silver_coin,
        };
      });

      setTransactions(tableRows);
      setPagination({
        ...pagination,
        current: pageCurrent + 1,
        total: result.data.total,
      });
      setHistoryLoading(false);
      localStorage.removeItem("topup_current_page");
    } catch (e) {
      message.open({ content: "โหลดข้อมูลไม่สำเร็จ" });
      setHistoryLoading(false);
    }
  };

  function handleTableChange(pagination, filters, sorter) {
    setPagination(pagination);

    let sortingColumn = null;
    let sortingOrder = null;

    if (sorter.order) {
      sortingColumn = sorter.field;
      sortingOrder = sorter.order;
    }

    setFilter({
      ...filter,
      page: pagination.current - 1,
      sortingColumn: sortingColumn || "",
      sortingOrder: sortingOrder || "",
    });

    localStorage.setItem("purchase_current_page", pagination.current - 1);
  }

  const handleDateChange = (e) => {
    let dateStart = "";
    let dateEnd = "";

    if (e) {
      setPagination({
        ...pagination,
        current: 1,
      });

      dateStart = moment(e[0]).format("YYYY-MM-DD 00:00:00");
      dateEnd = moment(e[1]).format("YYYY-MM-DD 23:59:59");

      setFilter({
        ...filter,
        page: 0,
        date_start: dateStart,
        date_end: dateEnd,
      });
    } else {
      setDateStart(null);
      setDateEnd(null);

      setFilter({
        ...filter,
        page: 0,
        date_start: null,
        date_end: null,
      });
    }
    localStorage.removeItem("purchase_current_page");
  };

  const handleMemberNameSelect = (name) => {
    if (name) {
      setPagination({
        ...pagination,
        current: 1,
      });
      setFilter({
        ...filter,
        page: 0,
        member_name: name,
      });
      localStorage.removeItem("purchase_current_page");
    }
  };

  const onChangeSearchType = (searchType, e) => {
    setSearchType(searchType);

    setPagination({
      ...pagination,
      current: 1,
    });
    
    setFilter({
      ...filter,
      page: 0,
      searchType,
    });
    localStorage.removeItem("purchase_current_page");
  };

  const handleMemberNameSearch = async (name) => {
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get("admin/member", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { name },
      });
      setMemberNameOptions(
        result.data.members.map((item) => {
          return {
            label: item.member_name,
            value: item.member_name,
          };
        })
      );
    } catch (e) {
      message.open({ content: "โหลดข้อมูลชื่อ Member ไม่สำเร็จ" });
    }
  };

  const handleBookNameSelect = (title) => {
    setFilter({
      ...filter,
      book_title: title,
    });
  };

  const handleBookNameSearch = async (name) => {
    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get("admin/book/search/title", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          book_title: name,
        },
      });

      setBookTitleOptions(
        result.data.map((item) => {
          return {
            label: item.book_title,
            value: item.book_title,
          };
        })
      );
    } catch (e) {
      message.open({ content: "โหลดข้อมูลชื่อหนังสือไม่สำเร็จ" });
    }
  };

  const exportData = async () => {
    if (!filter.date_start || !filter.date_end) {
      message.open({
        content: "กรุณาเลือกวันที่เริ่มต้นและสิ้นสุดก่อนทำการ export ข้อมูล",
        duration: 4,
      });
    } else if (moment(filter.date_end).diff(filter.date_start, "months") > 3) {
      message.open({
        content: "ไม่สามารถ export ข้อมูลมากกว่า 3 เดือนได้",
        duration: 4,
      });
    } else {
      const wb = new Excel.Workbook();
      const ws = wb.addWorksheet("Purchase History");

      ws.columns = [
        { header: "Payment Date", key: "date", width: 20 },
        { header: "Name", key: "name", width: 40 },
        { header: "Book", key: "bookTitle", width: 40 },
        { header: "Chapter", key: "chapterName", width: 40 },
        { header: "เหรียญทอง", key: "goldCoin", width: 15 },
        { header: "เหรียญเงิน", key: "silverCoin", width: 15 },
        { header: "เหรียญทอง (หนังสือเสียง)", key: "audioGoldCoin", width: 20 },
        {
          header: "เหรียญเงิน (หนังสือเสียง)",
          key: "audioSilverCoin",
          width: 20,
        },
      ];

      const token = localStorage.getItem("token");

      let url = "";
      if (searchType === "eachChapter") url = "admin/report/purchase_history";
      else url = "admin/report/purchase_all_book";

      const result = await httpClient.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...filter,
          page: null,
        },
      });

      for (const item of result.data.purchases) {
        ws.addRow({
          date: moment(item.payment_datetime).format("DD/MM/YYYY HH:mm"),
          name: item.member_name,
          bookTitle: item.book_title,
          chapterName: item.chapter_title,
          goldCoin: item.gold_coin.toLocaleString(),
          silverCoin: item.silver_coin.toLocaleString(),
          audioGoldCoin: item.audio_gold_coin.toLocaleString(),
          audioSilverCoin: item.audio_silver_coin.toLocaleString(),
        });
      }

      ws.eachRow((Row, rowNum) => {
        Row.eachCell((Cell, cellNum) => {
          if (cellNum > 4) {
            Cell.alignment = {
              horizontal: "center",
            };
          }
        });
      });

      wb.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "purchase history.xlsx");
      });
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <Card small>
        <CardHeader className="border-bottom">
          <h6 className="m-0">Purchase History</h6>
        </CardHeader>
        <CardBody>
          <div className="row mt-2">
            <div className="col-sm-4">
              <RangePicker
                placeholder={["เริ่มต้น", "สิ้นสุด"]}
                format="DD/MM/YYYY"
                disabledDate={false}
                onChange={handleDateChange}
                className="w-100"
              />
            </div>

            <div className="col-sm-4">
              <AutoComplete
                className="w-100"
                placeholder="ชื่อ Member"
                onSearch={handleMemberNameSearch}
                onSelect={handleMemberNameSelect}
                options={memberNameOptions}
              />
            </div>

            <div className="col-sm-4">
              <AutoComplete
                className="w-100"
                placeholder="ชื่อหนังสือ"
                onSearch={handleBookNameSearch}
                onSelect={handleBookNameSelect}
                options={bookTitleOptions}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-4">
              <Select
                defaultValue="eachChapter"
                className="w-100"
                onSelect={(value, event) => onChangeSearchType(value, event)}
              >
                <Option value="eachChapter">ซื้อทีละตอน</Option>
                <Option value="allBook">ซื้อทั้งเล่ม</Option>
              </Select>
            </div>
          </div>

          <div className="mt-3 d-flex align-items-center">
            <div className="flex-fill">
              จำนวนการซื้อทั้งหมด{" "}
              <b className="mx-1">
                {pagination.total ? pagination.total.toLocaleString() : 0}
              </b>{" "}
              ครั้ง
            </div>
            <Button className="btn-primary px-3" onClick={exportData}>
              Export
            </Button>
          </div>
          <div className="mt-3">
            <Table
              dataSource={transactions}
              loading={historyLoading}
              pagination={pagination}
              onChange={handleTableChange}
            >
              <Column
                title="Payment Date"
                dataIndex="payment_datetime"
                key="payment_datetime"
                sorter={true}
                render={(value) => {
                  return moment(value).format("DD/MM/YYYY HH:mm");
                }}
              />
              <Column
                title="Name"
                dataIndex="member_name"
                key="member_name"
                sorter={true}
                render={(_, record) => {
                  return (
                    <a
                      href={
                        "/member_books/" +
                        record.member_id +
                        "/" +
                        record.member_name +
                        "/0/member"
                      }
                    >
                      {record.member_name}
                    </a>
                  );
                }}
              />
              <Column
                title="Book"
                dataIndex="book_title"
                key="book_title"
                sorter={true}
                render={(_, record) => {
                  return (
                    <a
                      href={`/marketing_reports/each_chapter?book_title=${record.book_title}`}
                    >
                      {record.book_title}
                    </a>
                  );
                }}
              />
              <Column
                title="Chapter"
                dataIndex="chapter_title"
                key="chapter_title"
                align="center"
              />
              <Column
                title="เหรียญทอง"
                dataIndex="gold_coin"
                key="gold_coin"
                align="center"
                render={(value) => {
                  return value.toLocaleString();
                }}
              />
              <Column
                title="เหรียญเงิน"
                dataIndex="silver_coin"
                key="silver_coin"
                align="center"
                render={(value) => {
                  return value.toLocaleString();
                }}
              />
              <Column
                title="เหรียญทอง(หนังสือเสียง)"
                dataIndex="audio_gold_coin"
                key="audio_gold_coin"
                align="center"
                render={(value) => {
                  return value.toLocaleString();
                }}
              />
              <Column
                title="เหรียญเงิน(หนังสือเสียง)"
                dataIndex="audio_silver_coin"
                key="audio_silver_coins"
                align="center"
                render={(value) => {
                  return value.toLocaleString();
                }}
              />
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

import React, { Component } from "react";
import { Formik, Field } from "formik";
import { server } from "../../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import MenuContent from "../../Menu";

import { httpClient } from "../../../../utils/HttpClient";
import { getCategoryByID } from "../../../../actions/category.action";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import CategoryForm from "../categoryForm";
import * as Yup from "yup";
import moment from "moment";

class EditCategory extends Component {
  constructor() {
    super();
    this.state = { img_web: null, img_mobile: null };
    this.change = this.change.bind(this);
  }

  change(img, type) {
    if (type === "web") {
      this.setState({ img_web: img });
    } else if (type === "mobile") {
      this.setState({ img_mobile: img });
    }
  }

  componentDidMount() {
    let category_id = this.props.match.params.category_id;
    this.props.getCategoryByID(category_id);
  }

  showForm = ({
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 10 }}>
        <MenuContent />
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">EDIT CATEGORY</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <CategoryForm
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      value={this.state.value}
                      change={this.change}
                    ></CategoryForm>

                    <div className="text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary pull-right"
                        style={{ marginRight: 10, color: "white", width: 100 }}
                      >
                        Update
                      </button>
                      <a
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        Back
                      </a>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    const { result } = this.props.categoryReducer;

    return (
      <React.Fragment>
        {result ? (
          <Formik
            enableReinitialize
            initialValues={result ? result : {}}
            onSubmit={async (values, { setSubmitting }) => {
              let formData = new FormData();
              formData.append("category_name", values.category_name);
              formData.append("category_desc", values.category_desc);

              if (this.state.img_web)
                formData.append("category_image", this.state.img_web);

              if (this.state.img_mobile)
                formData.append("category_image_mobile", this.state.img_mobile);

              formData.append("ugc_category", values.ugc_category);

              let username = "";
              var result = localStorage.getItem("user_profile");
              if (result) username = JSON.parse(result).user_username;
              formData.append("updated_by", username);

              var dateTime = new Date();
              dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
              formData.append("updated_datetime", dateTime);

              var object = {};
              formData.forEach((value, key) => {
                object[key] = value;
              });
              var json = JSON.stringify(object);
              
              const token = localStorage.getItem("token");
              let response = await httpClient.patch(
                server.CATEGORY_URL + "/" + `${values.category_id}`,
                json,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                  },
                }
              );

              if (response.status === 201 || response.status === 200) {
                Swal.fire({
                  icon: "success",
                  title: "Category ถูกบันทึกเรียบร้อยแล้ว",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setSubmitting(false);
                this.props.history.goBack();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                  timer: 1500,
                });
              }
            }}
          >
            {(props) => this.showForm(props)}
          </Formik>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ categoryReducer }) => ({ categoryReducer });

const mapDispatchToProps = {
  getCategoryByID,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Nav } from "shards-react";

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
        <Container fluid>
          <Row>
            <Nav></Nav>
            <span className="copyright m-auto p-2">Copyright 2READ.digital © 2020</span>
          </Row>
        </Container>
      </footer>
    );
  }
}

const mapStateToProps = ({ appReducer }) => ({
  appReducer
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

import { httpClient } from "../utils/HttpClient";

import {
    HTTP_HISTORY_FETCHING,
    HTTP_HISTORY_SUCCESS,
    HTTP_HISTORY_FAILED,
  } from "../constants/report";

import {server} from "../constants"

const setStateHistoryToSuccess = payload => ({
  type: HTTP_HISTORY_SUCCESS,
  payload: payload
});

const setStateHistoryToFetching = () => ({
  type: HTTP_HISTORY_FETCHING
});

const setStateHistoryToFailed = () => ({
  type: HTTP_HISTORY_FAILED
});

export const getReportHistory = (value) => {
  return async (dispatch) => {
    dispatch(setStateHistoryToFetching());
    await doGetReportHistory(dispatch,value);
  };
};
  
  const doGetReportHistory = async (dispatch, value) => {
    const token = localStorage.getItem("token");

    const params = {
      category_name: value ? value.categoryName : "",
      book_title: value ? value.bookTitle : "",
      page: value ? value.page : 1,
      order_by: value ? value.orderBy : 'update'
    }

    try {
      const result = await httpClient
      .get(`${server.REPORT_URL}/history`,  {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: params,
      })
      dispatch(setStateHistoryToSuccess(result.data));
    } catch(error) {
      alert(JSON.stringify(error));
      dispatch(setStateHistoryToFailed());
    }
  };
  
  
import {
  HTTP_BOOK_MESSAGE_FETCHING,
  HTTP_BOOK_MESSAGE_SUCCESS,
  HTTP_BOOK_MESSAGE_FAILED,
  HTTP_BOOK_MESSAGE_ADDED,
  HTTP_BOOK_MESSAGE_UPDATED,
  HTTP_BOOK_MESSAGE_DELETED,
} from "../constants/book";
  
  const initialState = {
    messages: [],
    isFetching: false,
    isError: false
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_BOOK_MESSAGE_SUCCESS:
        return { 
          ...state, 
          messages: payload, 
          isFetching: false, 
          isError: false 
      };
      case HTTP_BOOK_MESSAGE_FAILED:
        return { ...state, messages: [], isFetching: false, isError: true };
      case HTTP_BOOK_MESSAGE_FETCHING:
        return { ...state, messages: [], isFetching: true, isError: false };
      case HTTP_BOOK_MESSAGE_ADDED: {
        const messages = [...state.messages, payload]

        return {
          ...state,
          messages
        };
      }
      case HTTP_BOOK_MESSAGE_UPDATED: {
        const messages = state.messages

        const updatedMessages = messages.map(item => {
          let message = item
          
          if(item.id === payload.id)  {
            message = payload
          }

          return message
        })

        return {
          ...state,
          messages: updatedMessages
        };
      }
      case HTTP_BOOK_MESSAGE_DELETED: {
        const messages = state.messages

        const updatedMessages = messages.filter(item => item.id !== payload)

        return {
          ...state,
          messages: updatedMessages
        };
      }
      default:
        return state;
    }
  };
  
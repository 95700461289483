import {
    HTTP_BOOKS_LABEL_FETCHING,
    HTTP_BOOKS_LABEL_SUCCESS,
    HTTP_BOOKS_LABEL_FAILED,

    HTTP_BOOK_LABEL_FETCHING,
    HTTP_BOOK_LABEL_SUCCESS,
    HTTP_BOOK_LABEL_FAILED,
  } from "../constants/book";
  
  const initialState = {
    bookLabel: [],
    result: null,
    total: 0,
    isFetching: false,
    isError: false
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_BOOKS_LABEL_SUCCESS:
        return { 
          ...state, 
          bookLabel: payload.bookLabel, 
          total: payload.total, 
          isFetching: false, 
          isError: false 
      };
      case HTTP_BOOKS_LABEL_FAILED:
        return { ...state, bookLabel: [], isFetching: false, isError: true };
      case HTTP_BOOKS_LABEL_FETCHING:
        return { ...state, bookLabel: [], isFetching: true, isError: false };

      case HTTP_BOOK_LABEL_SUCCESS:
        return { ...state, result: payload, isFetching: false, isError: false };
      case HTTP_BOOK_LABEL_FAILED:
        return { ...state, result: [], isFetching: false, isError: true };
      case HTTP_BOOK_LABEL_FETCHING:
        return { ...state, result: [], isFetching: true, isError: false };
      default:
        return state;
    }
  };
  
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";
import { connect } from "react-redux";
import { server } from "../../../../../constants";

class UserActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  createRows = () => {
    try {
      // Retrieve the object from storage
      var result = localStorage.getItem("user_profile");
      // const { result } = this.props.profileReducer;
      // const username = result.user_username;
      const username = JSON.parse(result).user_username;
      //alert(username)
      return (
        <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
          <DropdownToggle caret tag={NavLink} className="text-nowrap ml-4">
            <img
              className="user-avatar rounded-circle mr-2"
              src={require("./../../../../../assets/img/Logo.png")}
              alt="User Avatar"
            />{" "}
            <span className="d-none d-md-inline-block">{username}</span>
          </DropdownToggle>
          <Collapse tag={DropdownMenu} right small open={this.state.visible}>
            <DropdownItem tag={Link} to="/user">
              <i className="material-icons">&#xE7FD;</i> Profile
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem
              tag={Link}
              to="/login"
              className="text-danger"
              onClick={() => {
                localStorage.removeItem(server.LOGIN_PASSED);
                localStorage.removeItem("user_profile");
                localStorage.removeItem("book_current_page");
                localStorage.removeItem("banner_current_page");
                localStorage.removeItem("active_member_page");
                localStorage.removeItem("member_current_page");
                localStorage.removeItem("chapter_current_page");
                localStorage.removeItem("topup_current_page");
                localStorage.removeItem("purchase_current_page");
                this.props.appReducer.app.forceUpdate();
              }}
            >
              <i className="material-icons text-danger">&#xE879;</i> Logout
            </DropdownItem>
          </Collapse>
        </NavItem>
      );
    } catch (e) {}
  };

  render() {
    return <React.Fragment>{this.createRows()}</React.Fragment>;
  }
}

const mapStateToProps = ({ appReducer, profileReducer }) => ({
  appReducer,
  profileReducer,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);

import React, { Component } from "react";
import { Container, Row, Col } from "shards-react";
import { Store } from "./flux";
import classNames from "classnames";

import Dashboard from "./components/Dashboard/dashboard";
import Users from "./components/User/users";
import CreateUser from "./components/User/createUser";
import EditUser from "./components/User/editUser";
import ViewUser from "./components/User/viewUser";
import UserRole from "./components/User/userRole/userRole";
import CreateRole from "./components/User/userRole/createRole";
import EditRole from "./components/User/userRole/editRole";

import Member from "./components/Member/member";
import CreateMember from "./components/Member/createMember";
import EditMember from "./components/Member/editMember";
import ViewMember from "./components/Member/viewMember";
import MemberTransaction from "./components/Member/history/transaction";

import Editor from "./components/Editor/editor";
import CreateEditor from "./components/Editor/createEditor";
import EditEditor from "./components/Editor/editEditor";
import ManageEditor from "./components/Editor/manageWriter";

import Publisher from "./components/Publisher/publisher";
import CreatePublisher from "./components/Publisher/createPublisher";
import EditPublisher from "./components/Publisher/editPublisher";
import ManagePublisher from "./components/Publisher/managePublisher";

import Writer from "./components/Writer/writer";
import CreateWriter from "./components/Writer/createWriter";
import EditWriter from "./components/Writer/editWriter";
import ViewWriter from "./components/Writer/viewWriter";
import Withdraw from "./components/Writer/withdraw/withdraw";
import UgcWriter from "./components/Writer/ugc";

import Botnoi from "./components/Botnoi";

import ReportOrder from "./components/Report/reportOrder";
import ReportSilverCoin from "./components/Report/reportSilverCoin";
import Login from "./components/Login/Login";

import MainNavbar from "./components/Layout/Common/MainNavbar/MainNavbar";
import Footer from "./components/Layout/Common/Footer";
import MainSidebar from "./components/Layout/Common/MainSidebar/MainSidebar";

import Logout from "./components/Login/Logout";

import Campaign from "./components/Campaign/campaign";
import CreateCampaign from "./components/Campaign/createCampaign";
import ViewCampaign from "./components/Campaign/viewCampaign";
import EditCampaign from "./components/Campaign/editCampaign";

import Redeem from "./components/Redeem/redeem";
import GenerateRedeemCode from "./components/Redeem/generateRedeemCode";
import EditRedeemCode from "./components/Redeem/editRedeemCode";

import PushNotification from "./components/PushNotification/pushNotification";

import Packages from "./components/Packages/packages";
import CreatePackage from "./components/Packages/createPackage";
import EditPackage from "./components/Packages/editPackage";

import ReportSilverCoinTrans from "./components/Report/reportSilverCoinTrans";
import ReportPayment from "./components/Report/reportPayment";
import ReportPurchase from "./components/Report/reportPurchase";
import ReportCoinSale from "./components/Report/reportCoinSale";
import ReportSaleByStory from "./components/Report/reportSaleByStory";
import ReportSaleByChapter from "./components/Report/reportSaleByChapter";
import ReportCoinSpending from "./components/Report/reportCoinSpending";
import ReportCoinRemain from "./components/Report/reportCoinRemain";
import ReportWriter from "./components/Report/reportWriter";
import ReportWriterDetail from "./components/Report/reportWriterDetail";
import ReportBookRankPurchase from "./components/Report/reportBookRank";
import ReportViewRank from "./components/Report/reportViewRank";
import ReportChapterRank from "./components/Report/reportChapterRank";
import ReportPurchaseCoinRank from "./components/Report/reportPurchaseCoinRank";
import ReportMemberTransRank from "./components/Report/reportTransactionRank";
import ReportGift from "./components/Report/reportGift";
import ReportInvite from "./components/Report/reportInvite";
import ReportHitory from "./components/Report/reportHistory";
import ReportChapterHistory from "./components/Report/reportChapterHistory/reportChapterHistory";
import GetMembertransaction from "./components/Report/reportMemberTransaction";

import CommentBook from "./components/Comment/Book";
import CommentChapter from "./components/Comment/Chapter";
import CommentWriter from "./components/Comment/Writer";

import Playlist from "./components/Playlist/playlist";
import CreatePlaylist from "./components/Playlist/createPlaylist";
import EditPlaylist from "./components/Playlist/editPlaylist";
import PlaylistItem from "./components/PlaylistItem/playlist_item";
import PlaylistDetail from "./components/PlaylistDetail/playlist_detail";

import StickerSet from "./components/StickerSet/stickerSet";
import StickerItem from "./components/StickerItem/stickerItem";

import Banner from "./components/Content/Banner";
import CreateBanner from "./components/Content/Banner/CreateBanner";
import EditBanner from "./components/Content/Banner/EditBanner";
import BannerView from "./components/Content/BannerView";

import Category from "./components/Content/Category";
import CreateCategory from "./components/Content/Category/CreateCategory";
import EditCategory from "./components/Content/Category/EditCategory";
import SubCategory from "./components/Content/SubCategory";

import Book from "./components/Content/Book";
import CreateBook from "./components/Content/Book/CreateBook";
import EditBook from "./components/Content/Book/EditBook";

import BookChapter from "./components/Content/BookChapter";
import CreateChapter from "./components/Content/BookChapter/CreateChapter";
import EditChapter from "./components/Content/BookChapter/EditChapter";

import WriterRecommend from "./components/Content/WriterRecommend/writerRecommend";
import BookLabel from "./components/Content/BookLabel";
import ReportPublisher from "./components/Report/reportPublisher";
import ReportPublishBook from "./components/Report/reportPublishBook";
import ReportEditor from "./components/Report/reportEditor";
import ReporWriterEditor from "./components/Report/reportWriterEditor";
import ReporBookSaleEditor from "./components/Report/reportBookSaleEditor";
import ReportBuyAllBook from "./components/Report/reportBuyAllBook";
import ReportChapterBotRead from "./components/Report/ReportChapterBotRead";

import ReportMemberWithdraw from "./components/Editor/withdraw/memberWithdraw";
import BookPublish from "./components/Report/reportBookPublish";
import ReportBuyWholeBook from "./components/Report/reportBuyWholeBook";

import MarketingReports from "./components/Reports/marketingMenu";
import AccountantReports from "./components/Reports/accountantMenu";

import ReportMemberBooks from "./components/Reports/reportMemberBooks";

import ReportBooksRank from "./components/Reports/reportBookRank";
import ReportEachChapter from "./components/Reports/reportEachChapter";
import ReportPurchases from "./components/Reports/reportPurchase";
import ReportTopUpTransaction from "./components/Reports/reportTopUp";
import ReportSendGift from "./components/Reports/reportSendGift";
import ReportsBuyWholeBook from "./components/Reports/reportBuyWholeBook";
import ActiveMember from "./components/Member/activeMember";
import ReportSilverCoins from "./components/Reports/reportSliverCoin";
import ReportSilverCoinTransactions from "./components/Reports/reportSilverCoinTransaction";
import ReportWriters from "./components/Reports/reportWriter";
import ReportOrders from "./components/Reports/reportOrder";
import ReportPayments from "./components/Reports/reportPayment";

import "./assets/styles/react-bootstrap-table.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/shard-dashboards.1.1.0.css";
import "./assets/styles/styles.scss";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { setApp } from "./actions/app.action";
import { connect } from "react-redux";
import { server, YES } from "./constants";

const isLoggedIn = (role) => {
  const userProfile = JSON.parse(localStorage.getItem("user_profile"));
  const isLoggedIn = localStorage.getItem(server.LOGIN_PASSED) == YES;
  let isAuthen = false;

  if (userProfile && role) {
    isAuthen = userProfile[role] === "Y";
  }

  return role ? isLoggedIn && isAuthen : isLoggedIn;
};

// Protected Route
const SecuredRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn(rest.role) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: true,
      userDetail: "",
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.setApp(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      menuVisible: Store.getMenuState(),
    });
  }

  render() {
    const classes = classNames(!this.state.menuVisible && "sidebar-hidden");

    return (
      <Router>
        <div className={classes}>
          <Switch>
            <Route path="/" exact={true} component={Login} />
            <Route path="/login" component={Login} />
            <Container fluid>
              <Row>
                {isLoggedIn() && <MainSidebar />}
                <Col
                  className="main-content p-0"
                  lg={{ size: 10, offset: 2 }}
                  md={{ size: 9, offset: 3 }}
                  sm="12"
                  tag="main"
                >
                  {isLoggedIn() && <MainNavbar />}
                  <SecuredRoute path="/dashboard" component={Dashboard} />
                  <SecuredRoute path="/user" component={Users} role="user" />
                  <SecuredRoute
                    path="/createUser"
                    component={CreateUser}
                    role="user"
                  />
                  <SecuredRoute
                    path="/editUser/:user_id"
                    component={EditUser}
                    role="user"
                  />
                  <SecuredRoute
                    path="/viewUser/:user_id"
                    component={ViewUser}
                    role="user"
                  />
                  <SecuredRoute path="/role" component={UserRole} role="user" />
                  <SecuredRoute
                    path="/createRole"
                    component={CreateRole}
                    role="user"
                  />
                  <SecuredRoute
                    path="/editRole/:id"
                    component={EditRole}
                    role="user"
                  />
                  <SecuredRoute
                    path="/member"
                    component={Member}
                    role="member"
                  />
                  <SecuredRoute
                    path="/active_member"
                    component={ActiveMember}
                    role="member"
                  />
                  <SecuredRoute
                    path="/createMember"
                    component={CreateMember}
                    role="member"
                  />
                  <SecuredRoute
                    path="/editMember/:member_id/:from_page"
                    component={EditMember}
                    role="member"
                  />
                  <SecuredRoute
                    path="/viewMember/:member_id/:from_page"
                    component={ViewMember}
                    role="member"
                  />
                  <SecuredRoute
                    path="/member_transaction/:member_id/:member_name"
                    component={MemberTransaction}
                    role="member"
                  />
                  <SecuredRoute
                    path="/editor"
                    component={Editor}
                    role="member"
                  />
                  <SecuredRoute
                    path="/manageEditor/:editor_id"
                    component={ManageEditor}
                    role="member"
                  />
                  <SecuredRoute
                    path="/createEditor"
                    component={CreateEditor}
                    role="member"
                  />
                  <SecuredRoute
                    path="/editEditor/:member_id"
                    component={EditEditor}
                    role="member"
                  />
                  <SecuredRoute
                    path="/publisher"
                    component={Publisher}
                    role="member"
                  />
                  <SecuredRoute
                    path="/createPublisher"
                    component={CreatePublisher}
                    role="member"
                  />
                  <SecuredRoute
                    path="/editPublisher/:member_id"
                    component={EditPublisher}
                    role="member"
                  />
                  <SecuredRoute
                    path="/managePublisher/:publisher_id"
                    component={ManagePublisher}
                    role="member"
                  />
                  <SecuredRoute
                    path="/report/publisher"
                    component={ReportPublisher}
                    role="member"
                  />
                  <SecuredRoute
                    path="/report/publish/book"
                    component={ReportPublishBook}
                    role="member"
                  />

                  <SecuredRoute
                    path="/report/editor"
                    component={ReportEditor}
                    role="member"
                  />
                  <SecuredRoute
                    path="/report/editors/writer"
                    component={ReporWriterEditor}
                    role="member"
                  />
                  <SecuredRoute
                    path="/report/books/writer/:writer_id"
                    component={ReporBookSaleEditor}
                    role="member"
                  />
                  <SecuredRoute
                    path="/botnoi"
                    component={Botnoi}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/writer"
                    component={Writer}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/ugc-writer"
                    component={UgcWriter}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/createWriter"
                    component={CreateWriter}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/editWriter/:member_id"
                    component={EditWriter}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/viewWriter/:member_id"
                    component={ViewWriter}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/memberTrans/:member_id"
                    component={GetMembertransaction}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/order"
                    component={ReportOrder}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/report/payment"
                    component={ReportPayment}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/purchase"
                    component={ReportPurchase}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/coin_sale"
                    component={ReportCoinSale}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/sale_by_story"
                    component={ReportSaleByStory}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/sale_by_chapter"
                    component={ReportSaleByChapter}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/coin_spending"
                    component={ReportCoinSpending}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/coin_remain"
                    component={ReportCoinRemain}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/writers/detail/:member_id"
                    component={ReportWriterDetail}
                    role="accountant_report"
                  />
                  <SecuredRoute
                    path="/report/book_rank"
                    component={ReportBookRankPurchase}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/buy_whole_book"
                    component={ReportBuyWholeBook}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/view_rank"
                    component={ReportViewRank}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/chapter_rank"
                    component={ReportChapterRank}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/chapter_botread"
                    component={ReportChapterBotRead}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/member_purcase_rank"
                    component={ReportPurchaseCoinRank}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/member_trans_rank"
                    component={ReportMemberTransRank}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/gift"
                    component={ReportGift}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/invite"
                    component={ReportInvite}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/history"
                    component={ReportHitory}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/report/chapter/history/:book_id"
                    component={ReportChapterHistory}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/campaign"
                    component={Campaign}
                    role="campaign"
                  />
                  <SecuredRoute
                    path="/createCampaign"
                    component={CreateCampaign}
                    role="campaign"
                  />
                  <SecuredRoute
                    path="/viewCampaign/:campaign_id"
                    component={ViewCampaign}
                    role="campaign"
                  />
                  <SecuredRoute
                    path="/editCampaign/:campaign_id"
                    component={EditCampaign}
                    role="campaign"
                  />
                  <SecuredRoute
                    path="/redeem"
                    component={Redeem}
                    role="campaign"
                  />
                  <SecuredRoute
                    path="/redeemGenerate"
                    component={GenerateRedeemCode}
                    role="campaign"
                  />
                  <SecuredRoute
                    path="/editRedeem/:id"
                    component={EditRedeemCode}
                    role="campaign"
                  />
                  {/* <SecuredRoute
                    path="reports/silverCoinTrans"
                    component={ReportSilverCoinTrans}
                    role="campaign"
                  /> */}
                  <SecuredRoute
                    path="/push-notification"
                    component={PushNotification}
                    role="push_notification"
                  />
                  <SecuredRoute
                    path="/packages"
                    component={Packages}
                    role="package"
                  />
                  <SecuredRoute
                    path="/package/create"
                    component={CreatePackage}
                    role="package"
                  />
                  <SecuredRoute
                    path="/comment/book"
                    component={CommentBook}
                    role="comment"
                  />
                  <SecuredRoute
                    path="/comment/chapter"
                    component={CommentChapter}
                    role="comment"
                  />
                  <SecuredRoute
                    path="/comment/writer"
                    component={CommentWriter}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/package/:id"
                    component={EditPackage}
                    role="package"
                  />
                  <SecuredRoute
                    path="/playlist"
                    component={Playlist}
                    role="playlist"
                  />
                  <SecuredRoute
                    path="/playlists/create"
                    component={CreatePlaylist}
                    role="playlist"
                  />
                  <SecuredRoute
                    path="/playlists/edit/:playlist_id"
                    component={EditPlaylist}
                    role="playlist"
                  />
                  <SecuredRoute
                    path="/playlists/item"
                    component={PlaylistItem}
                    role="playlist"
                  />
                  <SecuredRoute
                    path="/playlists/detail/:playlist_id"
                    component={PlaylistDetail}
                    role="playlist"
                  />
                  <SecuredRoute
                    path="/sticker"
                    component={StickerSet}
                    role="comment"
                  />
                  <SecuredRoute
                    path="/stickers/item"
                    component={StickerItem}
                    role="comment"
                  />
                  <SecuredRoute
                    path="/banner"
                    component={Banner}
                    role="banner"
                  />
                  <SecuredRoute
                    path="/banners/create"
                    component={CreateBanner}
                    role="banner"
                  />
                  <SecuredRoute
                    path="/banners/edit/:banner_id"
                    component={EditBanner}
                    role="banner"
                  />
                  <SecuredRoute
                    path="/banners/view"
                    component={BannerView}
                    role="banner"
                  />
                  <SecuredRoute
                    path="/category"
                    component={Category}
                    role="category"
                  />
                  <SecuredRoute
                    path="/categorys/create"
                    component={CreateCategory}
                    role="category"
                  />
                  <SecuredRoute
                    path="/categorys/edit/:category_id"
                    component={EditCategory}
                    role="category"
                  />
                  <SecuredRoute
                    path="/sub_category/:category_id"
                    component={SubCategory}
                    role="category"
                  />
                  <SecuredRoute path="/book" component={Book} role="book" />
                  <SecuredRoute
                    path="/books/create"
                    component={CreateBook}
                    role="book"
                  />
                  <SecuredRoute
                    path="/books/edit/:book_id"
                    component={EditBook}
                    role="book"
                  />
                  <SecuredRoute
                    path="/book_chapter/:book_id"
                    component={BookChapter}
                    role="book"
                  />
                  <SecuredRoute
                    path="/book_chapters/:book_id/create"
                    component={CreateChapter}
                    role="book"
                  />
                  <SecuredRoute
                    path="/book_chapters/edit/:chapter_id"
                    component={EditChapter}
                    role="book"
                  />
                  <SecuredRoute
                    path="/writer_recommend"
                    component={WriterRecommend}
                    role="writer_recommend"
                  />
                  <SecuredRoute
                    path="/book_label/:book_id"
                    component={BookLabel}
                    role="book"
                  />
                  <SecuredRoute
                    path="/report/buy/all"
                    component={ReportBuyAllBook}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/withdraw"
                    component={Withdraw}
                    role="writer"
                  />
                  <SecuredRoute
                    path="/withdraws/transaction/:member_id"
                    component={ReportMemberWithdraw}
                    role="member"
                  />
                  <SecuredRoute
                    path="/writers/publish/:member_id"
                    component={BookPublish}
                    role="marketing_report"
                  />

                  <SecuredRoute
                    path="/marketing_reports"
                    component={MarketingReports}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/accountant_reports"
                    component={AccountantReports}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/member_books/:member_id/:member_name/:member_page/:from_page"
                    component={ReportMemberBooks}
                    role="marketing_report"
                  />

                  <SecuredRoute
                    path="/marketing_reports/book_rank"
                    component={ReportBooksRank}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/marketing_reports/each_chapter"
                    component={ReportEachChapter}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/marketing_reports/purchases"
                    component={ReportPurchases}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/marketing_reports/topup"
                    component={ReportTopUpTransaction}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/marketing_reports/gift"
                    component={ReportSendGift}
                    role="marketing_report"
                  />
                  <SecuredRoute
                    path="/marketing_reports/all_book"
                    component={ReportsBuyWholeBook}
                    role="marketing_report"
                  />

                  <SecuredRoute
                    path="/accountant_reports/silver_coins"
                    component={ReportSilverCoins}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/accountant_reports/silver_coins_transaction"
                    component={ReportSilverCoinTransactions}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/accountant_reports/writers"
                    component={ReportWriters}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/accountant_reports/orders"
                    component={ReportOrders}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/accountant_reports/payments"
                    component={ReportPayments}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/accountant_reports/purchases"
                    component={ReportPurchases}
                    role="accountant_report"
                  />

                  <SecuredRoute
                    path="/accountant_reports/book_rank"
                    component={ReportBooksRank}
                    role="accountant_report"
                  />

                  <Route path="/logout" component={Logout} />
                  {/* {isLoggedIn() && <Footer />} */}
                </Col>
              </Row>
            </Container>
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => ({ loginReducer });

const mapDispatchToProps = {
  setApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

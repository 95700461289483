import { httpClient } from "./../utils/HttpClient";

import {
  HTTP_REPORT_COIN_SUCCESS,
  HTTP_REPORT_COIN_FETCHING,
  HTTP_REPORT_COIN_FAILED,

  server,
} from "../constants";

/* REPORT COIN SALE ACTION */
const setStateReportCoinToSuccess = (payload) => ({
  type: HTTP_REPORT_COIN_SUCCESS,
  payload: payload,
});

const setStateReportCoinToFetching = () => ({
  type: HTTP_REPORT_COIN_FETCHING,
});

const setStateReportCoinToFailed = () => ({
  type: HTTP_REPORT_COIN_FAILED,
});


/* Get Report Coin Sale By User */
export const getReportCoinSale = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportCoinToFetching());
    await doGetReportCoinSale(dispatch,value);
  };
};

const doGetReportCoinSale = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if(!value.start)
        dateFilter += "?end_date=" + value.end + " 23:59.00";
      else
        dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }
  
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/coin_sale` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateReportCoinToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateReportCoinToFailed());
    });
};


import React, { Component } from "react";
import { getReportWriterEditor } from "../../../actions/reportWriterEditor.action";
import { getReportEditor } from "../../../actions/reportEditor.action";
import { getAllMember } from "../../../actions/member.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  InputGroupText,
  DatePicker,
  FormSelect,
  Badge
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";
import MenuReport from "../reportPublisher/menu/menuReportPublisher";

class ReporWriterEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      writers: [],
      editors: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      start_date: "",
      end_date: "",
      publisher_id: "",
      editor_id: "",
      all_editors: [],
      all_writers: []
    };
  }

  async componentDidMount() {
    await this.getReportWriterEditor(this.state.currentPage);
    const { writers } = this.props.reportWriterEditorReducer;
    if (this.props.reportWriterEditorReducer.writers) {
      this.setState({
        all_writers: writers
      });
    }

    await this.getReportEditor("");
    await this.props.getAllMember(4, "", "", ""); //get member_type =4 (4 equal Publisher)
    const { editors } = this.props.reportEditorReducer;
    if (this.props.reportEditorReducer.editors) {
      this.setState({
        all_editors: editors
      });
    }
  }

  bindingPublisherName = ({ handleChange, values }) => {
    try {
      const { members, isFetching } = this.props.memberReducer;

      const options = members.map(item => (
        <option key={item.value} value={item.member_id}>
          {item.member_name}
        </option>
      ));

      return (
        !isFetching &&
        members != null && (
          <FormSelect
            name="publisher_id"
            id="publisher_id"
            placeholder=""
            onChange={handleChange}
            value={values.publisher_id}
          >
            <option value="">Select Publisher</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  bindingEditorName = ({ handleChange, values }) => {
    try {
      const options = this.state.all_editors.map(item => (
        <option key={item.value} value={item.editor_id}>
          {item.editor_name}
        </option>
      ));

      return (
        this.state.all_editors != null && (
          <FormSelect
            name="editor_id"
            id="editor_id"
            placeholder=""
            onChange={handleChange}
            value={values.editor_id}
          >
            <option value="">Select Editor</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  bindingWriterName = ({ handleChange, values }) => {
    try {
      const options = this.state.all_writers.map(item => (
        <option key={item.value} value={item.writer_id}>
          {item.writer_name}
        </option>
      ));

      return (
        this.state.all_editors != null && (
          <FormSelect
            name="writer_id"
            id="writer_id"
            placeholder=""
            onChange={handleChange}
            value={values.writer_id}
          >
            <option value="">Select Writer</option>
            {options}
          </FormSelect>
        )
      );
    } catch (e) {}
  };

  async getReportWriterEditor(page) {
    this.setState({ loading: true });

    const value = {};
    value.start_date = this.state.start_date;
    value.end_date = this.state.end_date;
    value.publisher_id = this.state.publisher_id;
    value.editor_id = this.state.editor_id;
    value.writer_id = this.state.writer_id;
    value.page = page;

    await this.props.getReportWriterEditor(value);

    const { writers, total } = this.props.reportWriterEditorReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.reportWriterEditorReducer.writers) {
      this.setState({
        writers,
        totalPages
      });
    }
    this.setState({ loading: false });
  }

  async getReportEditor(page) {
    this.setState({ loading: true });

    const value = {};
    value.page = page;

    await this.props.getReportEditor(value);

    const { editors, total } = this.props.reportEditorReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.reportEditorReducer.editors) {
      this.setState({
        editors,
        totalPages
      });
    }
    this.setState({ loading: false });
  }

  formatCoin(item) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal"
    }).format(item);
  }

  createSummaryFooter = () => {
    try {
      const { writers, total } = this.props.reportWriterEditorReducer;

      const summaryTotal = writers.reduce(
        (summary, data) => summary + parseInt(data.sum_price),
        0
      );

      const goldTotal = writers.reduce(
        (summary_gold, data) => summary_gold + parseInt(data.sum_gold_price),
        0
      );

      const silverTotal = writers.reduce(
        (summary_silver, data) =>
          summary_silver + parseInt(data.sum_silver_price),
        0
      );

      if (writers != null) {
        return (
          <tr>
            <td className="text-center font-weight-bold">Grand Total</td>
            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal"
              }).format(summaryTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal"
              }).format(goldTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal"
              }).format(silverTotal)}
            </td>
          </tr>
        );
      }
    } catch (e) {}
  };

  renderTable = () => {
    return (
      <React.Fragment>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>ชื่อนักเขียน</th>
              <th style={{ textAlign: "center" }}>จำนวนเหรียญที่ซื้อทั้งหมด</th>
              <th style={{ textAlign: "center" }}>เหรียญทอง</th>
              <th style={{ textAlign: "center" }}>เหรียญเงิน</th>
              <th style={{ textAlign: "center" }}>ยอดขายหนังสือแต่ละเล่ม</th>
            </tr>
          </thead>
          <tbody>
            {this.state.writers.map((item, index) => {
              return (
                <tr key={item.writer_id}>
                  <td className="text-center align-middle">
                    {item.writer_name}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatCoin(item.sum_price)}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatCoin(item.sum_gold_price)}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatCoin(item.sum_silver_price)}
                  </td>
                  <td className="text-center align-middle">
                    <button
                      type="button"
                      className="btn btn-xs btn-warning"
                      onClick={() =>
                        this.props.history.push(
                          `/report/books/writer/${item.writer_id}`
                        )
                      }
                    >
                      ยอดขายหนังสือแต่ละเล่ม
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Row>
          <Col sm="7" className="sm-7 ml-3">
            <Card small className="lo-stats h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Summary</h6>
                <div className="block-handle" />
              </CardHeader>

              <CardBody className="p-0">
                <Container fluid className="px-0">
                  <table className="table mb-0 table-striped table-hover table-fixed">
                    <thead className="py-2 text-semibold border-bottom">
                      <tr>
                        <th className="text-center"></th>
                        <th className="text-center">
                          จำนวนเหรียญที่ซื้อทั้งหมด
                        </th>
                        <th className="text-center">เหรียญทอง</th>
                        <th className="text-center">เหรียญเงิน</th>
                      </tr>
                    </thead>
                    <tfoot>{this.createSummaryFooter()}</tfoot>
                  </table>
                </Container>
              </CardBody>
            </Card>
          </Col>
          <Col sm="5" className="sm-5"></Col>
        </Row>
      </React.Fragment>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getReportWriterEditor(page);
  }

  async exportData() {
    await this.getReportWriterEditor("");

    let report = this.state.editors.map((item, idx) => {
      return [
        item.writer_name,
        this.formatCoin(item.sum_price),
        this.formatCoin(item.sum_silver_price),
        this.formatCoin(item.sum_gold_price)
      ];
    });

    report = [
      ["ชื่อนักเขียน", "จำนวนเหรียญที่ซื้อทั้งหมด", "เหรียญทอง", "เหรียญเงิน"],
      ...report
    ];

    if (report) {
      this.downloadExportData(report);
    }
  }

  downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, [
      "report-writer-publisher"
    ]);
    XLSX.writeFile(workBook, `report-writer-publisher.xlsx`);

    this.getReportWriterEditor(0);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={val => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <Container fluid className="main-content-container px-4">
        <MenuReport />
        <div style={{ padding: 10 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">ยอดขายของนักเขียน</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.exportData();
                    }}
                    type="button"
                    className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Export
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ padding: "1rem" }}>
              <Formik
                initialValues={{
                  start_date: "",
                  end_date: "",
                  publisher_id: "",
                  writer_id: "",
                  page: ""
                }}
                onSubmit={async values => {
                  let startDate = null;
                  let endDate = null;

                  if (values.start_date) {
                    startDate = moment(values.start_date).format("YYYY-MM-DD");
                  }

                  if (values.end_date) {
                    endDate = moment(values.end_date).format("YYYY-MM-DD");
                  }

                  this.setState({
                    start_date: startDate,
                    end_date: endDate,
                    publisher_id: values.publisher_id,
                    editor_id: values.editor_id,
                    writer_id: values.writer_id
                  });

                  this.getReportWriterEditor(this.state.currentPage);
                }}
              >
                {props => {
                  const {
                    values,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    handleChange
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row form>
                        <Col sm="6" className="form-group">
                          <label>วันที่เริ่มต้น/สิ้นสุด</label>
                          <InputGroup className={"d-flex my-auto date-range"}>
                            <DatePickerField
                              size="sm"
                              name="start_date"
                              onChange={setFieldValue}
                              value={values.start_date}
                              placeholder="Start Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <DatePickerField
                              size="sm"
                              name="end_date"
                              onChange={setFieldValue}
                              value={values.end_date}
                              placeholder="End Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <i className="material-icons">&#xE916;</i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <label>ชื่อนักเขียน</label>

                            {this.bindingWriterName({
                              values,
                              handleChange
                            })}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm="4">
                          <FormGroup>
                            <label>ชื่อบรรณาธิการ</label>

                            {this.bindingEditorName({
                              values,
                              handleChange
                            })}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <label>ชื่อสำนักพิมพ์</label>

                            {this.bindingPublisherName({
                              values,
                              handleChange
                            })}
                          </FormGroup>
                        </Col>
                        <Col sm="2" className="text-right">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary btn-sm"
                            style={{
                              marginLeft: 30,
                              marginTop: 27,
                              color: "white",
                              width: 100
                            }}
                          >
                            Search
                          </button>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </CardBody>

            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.editors.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({
  reportWriterEditorReducer,
  reportEditorReducer,
  memberReducer
}) => ({
  reportWriterEditorReducer,
  reportEditorReducer,
  memberReducer
});

const mapDispatchToProps = {
  getReportWriterEditor,
  getReportEditor,
  getAllMember
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReporWriterEditor);

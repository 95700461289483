import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../../constants";
import { Card, CardHeader, CardBody, Row, Col } from "shards-react";
import { httpClient } from "../../../../utils/HttpClient";
import Swal from "sweetalert2";
import CategoryForm from "../categoryForm";
import * as Yup from "yup";
import moment from "moment";
import MenuContent from "../../Menu";

const categoryValidationSchema = Yup.object().shape({
  category_name: Yup.string().required("กรุณากรอก ชื่อ Category"),
});

class CreateCategory extends Component {
  constructor() {
    super();
    this.state = { img_web: null, img_mobile: null };
    this.change = this.change.bind(this);
  }

  change(img, type) {
    if (type === "web") {
      this.setState({ img_web: img });
    } else if (type === "mobile") {
      this.setState({ img_mobile: img });
    }
  }

  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  }) => {
    return (
      <div style={{ padding: 10 }}>
        <MenuContent />
        <Card>
          <CardHeader className="border-bottom">
            <h6 className="m-0">ADD CATEGORY</h6>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <CategoryForm
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                    value={this.state.value}
                    change={this.change}
                  ></CategoryForm>

                  <div className="text-right">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary pull-right"
                      style={{ marginRight: 10, color: "white", width: 100 }}
                    >
                      บันทึก
                    </button>
                    <button
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            category_name: "",
            category_desc: "",
            img_web: "",
            img_mobile: "",
            category_image: "",
            category_image_mobile: "",
            ugc_category: "N"
          }}
          validationSchema={categoryValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            let formData = new FormData();
            formData.append("category_name", values.category_name);
            formData.append("category_desc", values.category_desc);

            if (this.state.img_web)
              formData.append("category_image", this.state.img_web);

            if (this.state.img_mobile)
              formData.append("category_image_mobile", this.state.img_mobile);

            formData.append("ugc_category", values.ugc_category);

            let username = "";
            var result = localStorage.getItem("user_profile");
            if (result) username = JSON.parse(result).user_username;
            formData.append("created_by", username);
            formData.append("updated_by", username);

            var dateTime = new Date();
            dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
            formData.append("created_datetime", dateTime);
            formData.append("updated_datetime", dateTime);

            formData.append("dbstatus", "A");

            var object = {};
            formData.forEach((value, key) => {
              object[key] = value;
            });
            var json = JSON.stringify(object);

            const token = localStorage.getItem("token");
            let response = await httpClient.post(server.CATEGORY_URL, json, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
              },
            });

            if (response.status === 201 || response.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Category ถูกบันทึกเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 1500,
              });
              setSubmitting(false);
              this.props.history.goBack();
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่อีกครั้ง",
                timer: 1500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

export default CreateCategory;

import React, { Component } from "react";
import * as actions from "../../../actions/user.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormInput,
  FormGroup
} from "shards-react";

import Swal from "sweetalert2";
import ContentPagination from "../../Layout/Common/Pagination";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      username: "",
      email: "",
    };
  }

  async componentDidMount() {
    await this.getUsers(this.state.currentPage);
  }

  async getUsers(page) {
    this.setState({ loading: true });

    await this.props.getAllUser(this.state.username,page);
    const { users, total } = this.props.userReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.userReducer.users) {
      this.setState({
        users: this.props.userReducer.users,
        totalPages,
      });
    }

    this.setState({ loading: false });
  }

  btnFormatter(user_id) {
    return (
      <React.Fragment>
        <a
          type="button"
          className="btn btn-sm btn-secondary mr-2"
          href={"/editUser/" + user_id}
        >
          Edit
        </a>

        <a
          onClick={() => {
            Swal.fire({
              title: "Are you sure to delete?",
              text: "You won't be able to revert this!",
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes, delete it!",
              cancelButtonText: "No, cancel!",
            }).then((result) => {
              if (result.value) {
                this.deleteUser(user_id);
              }
            });
          }}
          type="button"
          className="btn btn-sm btn-danger"
          style={{ color: "white" }}
        >
          Delete
        </a>
      </React.Fragment>
    );
  }

  async deleteUser(user_id) {
    await this.props.deleteUser(user_id, this.state.currentPage);
    setTimeout(() => {
      this.getUsers(this.state.currentPage);
    }, 100);
  }

  formatName(firstname, lastname) {
    return (
      <div>
        <div>
          {firstname} {lastname}
        </div>
      </div>
    );
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">Username</th>
            <th className="text-center">Name</th>
            <th className="text-center">Email</th>
            <th className="text-center">Phone</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {this.state.users.map((user, index) => {
            return (
              <tr key={user.user_id}>
                <td className="text-center align-middle">
                  {user.user_username}
                </td>
                <td className="text-center align-middle">
                  {this.formatName(user.user_firstname, user.user_lastname)}
                </td>
                <td className="text-center align-middle">{user.user_email}</td>
                <td className="text-center align-middle">{user.user_phone}</td>

                <td className="text-center align-middle" width="250">
                  {this.btnFormatter(user.user_id)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getUsers(page);
  }

  render() {
    return (
      <Container fluid>
        <div style={{ padding: 20 }}>
          <Card className="mb-3">
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Active Users</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => this.props.history.push(`/createUser`)}
                    className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add User
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody className="pb-2">
              <Row>
                <Col sm="4" className="pr-1">
                  <FormGroup>
                    <label>Username</label>
                    <FormInput
                      name="username"
                      id="username"
                      placeholder=""
                      onChange={(e) =>
                        this.setState({ username: e.target.value }, () => {
                          this.getUsers(this.state.currentPage);
                        })
                      }
                      value={this.state.username}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>

            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.users.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ userReducer, appReducer }) => ({
  userReducer,
  appReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

import { httpClient } from "../utils/HttpClient";

import {
    HTTP_MEMBER_TRANS_FETCHING,
    HTTP_MEMBER_TRANS_SUCCESS,
    HTTP_MEMBER_TRANS_FAILED,
} from "../constants/report";

import { server } from "../constants";

const setStateRankTransactionToSuccess = (payload) => ({
  type: HTTP_MEMBER_TRANS_SUCCESS,
  payload: payload,
});

const setStateRankTransactionToFetching = () => ({
  type: HTTP_MEMBER_TRANS_FETCHING,
});

const setStateRankTransactionToFailed = () => ({
    type: HTTP_MEMBER_TRANS_FAILED,
  });

/* Get Report Transaction Rank*/
export const getReportTransactionRank = (value) => {
  return async (dispatch) => {
    dispatch(setStateRankTransactionToFetching());
    await doGetReportTransactionRank(dispatch, value);
  };
};

const doGetReportTransactionRank = (dispatch, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dateFilter += "?end_date=" + value.end + " 23:59.00";
      else dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }
  const token = localStorage.getItem("token");

  httpClient
    .get(`${server.REPORT_URL}/member_use_coin` + dateFilter,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      dispatch(setStateRankTransactionToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateRankTransactionToFailed());
    });
};

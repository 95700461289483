import { httpClient } from "../utils/HttpClient";
import {server} from "../constants"

import {
  HTTP_CATEGORY_FETCHING,
  HTTP_CATEGORY_SUCCESS,
  HTTP_CATEGORY_FAILED
} from "../constants/category";

const setStateCategoryToSuccess = payload => ({
  type: HTTP_CATEGORY_SUCCESS,
  payload: payload
});

const setStateCategoryToFetching = () => ({
  type: HTTP_CATEGORY_FETCHING
});

const setStateCategoryToFailed = () => ({
  type: HTTP_CATEGORY_FAILED
});

/* Get Category */
export const getCategory = () => {
  return async (dispatch) => {
    dispatch(setStateCategoryToFetching());
    await doGetCategory(dispatch);
  };
};

const doGetCategory = async (dispatch) => {
  try {
    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.CATEGORY_URL}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    dispatch(setStateCategoryToSuccess(result.data));
  } catch(err)  {
    dispatch(setStateCategoryToFailed());
  }
};

export const getCategoryByID = (category_id) => {
  return async (dispatch) => {
    dispatch(setStateCategoryToFetching());

    try {
      const token = localStorage.getItem("token");

      const result = await httpClient.get(`${server.CATEGORY_URL}/id/${category_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      dispatch(setStateCategoryToSuccess(result.data));
    } catch(err)  {
      dispatch(setStateCategoryToFailed());
    }
  };
};

export const deleteCategory = (category_id) => {
  return async (dispatch) => {
    dispatch(setStateCategoryToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.delete(
      `${server.CATEGORY_URL}/${category_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(result)
    {
      alert('Delete Complete')
      await doGetCategory(dispatch);
    }
  };
};

export const resetOrderBook = (category_id) => {
  return async (dispatch) => {
    dispatch(setStateCategoryToFetching());
    const token = localStorage.getItem("token");

    const result = await httpClient.patch(
      `${server.CATEGORY_URL}/reset_order/${category_id}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if(result)
    {
      alert('Reset Complete')
      await doGetCategory(dispatch);
    }
  };
};



import React, { Component } from "react";

import {
  getWriterByEditorID,
  deleteWriter,
} from "../../../actions/editor.action";

import { getWriterName } from "../../../actions/member.action";

import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  FormSelect,
  FormGroup,
} from "shards-react";

import { Formik } from "formik";

import moment from "moment";
import { httpClient } from "../../../utils/HttpClient";
import { server } from "../../../constants";
import Swal from "sweetalert2";
import ContentPagination from "../../Layout/Common/Pagination";
import Autosuggest from "react-autosuggest";

class ManageEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      writers: [],
      editor_id: "",
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      start_date: "",
      end_date: "",
      ModalOpen: false,
      member_id: "",
      members: [],
      suggestions: [],
      writer_name: "",
      value: "",
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({
      ModalOpen: !this.state.ModalOpen,
      member_id: "",
      writer_name: "",
      value: "",
    });
  }

  closeModal() {
    this.setState({
      ModalOpen: !this.state.ModalOpen,
      writer_name: "",
      value: "",
    });
  }

  async addWriterToEditor(member_id) {
    try {
      if (!member_id) {
        Swal.fire({
          icon: "error",
          text: "กรุณาระบุ นักเขียน",
          timer: 1500,
        });
        return;
      }

      let result = localStorage.getItem("user_profile");
      let username;
      if (result) username = JSON.parse(result).user_username;

      let dateTime = new Date();
      dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");

      let formData = new FormData();

      formData.append("editor_id", this.state.editor_id);
      formData.append("member_id", member_id);
      formData.append("dbstatus", "A");
      formData.append("created_datetime", dateTime);
      formData.append("created_by", username);

      formData.append("updated_datetime", dateTime);
      formData.append("updated_by", username);

      let object = {};
      formData.forEach((value, key) => {
        object[key] = value;
      });
      let json = JSON.stringify(object);

      let response = "";

      const token = localStorage.getItem("token")
      response = await httpClient.post(`${server.EDITOR_URL}`, json, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });

      if(response.data === -1)
      {
        Swal.fire({
          icon: "error",
          text: 'นักเขียนคนนี้มีบรรณาธิการอยู่แล้ว กรุณาตรวจสอบ',
          timer: 3000,
        });
        return;
      }

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500,
        });

        this.setState({
          ModalOpen: false,
        });

        this.getAllWriter(this.state.currentPage);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        text: err,
        timer: 3000,
      });
    }
  }

  async componentDidMount() {
    await this.getAllWriter(this.state.currentPage);
    await this.props.getWriterName();

    const { writers, isFetching } = this.props.memberReducer;

    this.setState({
      editor_id: this.props.match.params.editor_id,
      members: writers,
      suggestions: writers,
    });
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.state.members,
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    this.setState({
      member_id: suggestion.member_id,
      writer_name: suggestion.writer_name,
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const result = this.state.members.filter((member) =>
      inputLength !== 0
        ? member.writer_name.toLowerCase().includes(inputValue)
        : true
    );

    if (inputLength === 0) {
      this.setState({
        member_id: "",
        writer_name: "",
      });
    }

    return result;
  };

  renderSuggestion = (suggestion) => <div>{suggestion.writer_name}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  onWriterTitleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => suggestion.writer_name;

  async getAllWriter(page) {
    this.setState({ loading: true });

    const value = {};
    value.editor_id = this.props.match.params.editor_id;
    value.page = page;

    await this.props.getWriterByEditorID(value);

    const { writers, total } = this.props.editorReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.editorReducer.writers) {
      this.setState({
        writers,
        totalPages,
      });
    }
    this.setState({ loading: false });
  }

  formatDateTime(item) {
    if (item) {
      return moment(item).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  renderTable = () => {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: "พิมพ์ชื่อนักเขียน",
      value,
      onChange: this.onWriterTitleChange,
    };

    return (
      <React.Fragment>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>บรรณาธิการ</th>
              <th style={{ textAlign: "center" }}>นักเขียน</th>
              <th style={{ textAlign: "center" }}>แก้ไขโดย</th>
              <th style={{ textAlign: "center" }}>วันที่แก้ไขล่าสุด</th>
              <th style={{ textAlign: "center" }}>ประวัติการลงหนังสือ</th>
              <th style={{ textAlign: "center" }}>ลบ</th>
            </tr>
          </thead>
          <tbody>
            {this.state.writers.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td className="text-center align-middle">
                    {item.editor_name}
                  </td>
                  <td className="text-center align-middle">
                    {item.writer_name}
                  </td>
                  <td className="text-center align-middle">
                    {item.updated_by}
                  </td>
                  <td className="text-center align-middle">
                    {" "}
                    {this.formatDateTime(item.updated_datetime)}
                  </td>

                  <td className="text-center align-middle">
                    <div className="mt-1">
                      <a
                        type="button"
                        className="btn btn-xs btn-accent"
                        href={"/writers/publish/" + item.writer_id}
                      >
                        ประวัติการลงหนังสือ
                      </a>
                    </div>
                  </td>

                  <td className="text-center align-middle">
                    <button
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure to delete?",
                          text: "You won't be able to revert this!",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes, delete it!",
                          cancelButtonText: "No, cancel!",
                        }).then((result) => {
                          if (result.value) {
                            this.deleteWriter(item.id, this.state.editor_id);
                          }
                        });
                      }}
                      type="button"
                      className="btn btn-sm  btn-danger"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Modal open={this.state.ModalOpen} toggle={this.toggleModal}>
          <ModalHeader>เพิ่มนักเขียน</ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                member_id: "",
                order: "",
              }}
              render={({ values, handleChange, setFieldValue }) => (
                <React.Fragment>
                  <Row form>
                    <Col md="12" className="form-group">
                      <FormGroup>
                        <label>ผู้เขียน</label>

                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.onSuggestionsClearRequested
                          }
                          shouldRenderSuggestions={this.shouldRenderSuggestions}
                          onSuggestionSelected={this.onSuggestionSelected}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          inputProps={inputProps}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-primary pull-right"
                      onClick={() => {
                        this.addWriterToEditor(this.state.member_id);
                      }}
                      style={{
                        marginRight: 10,
                        color: "white",
                        width: 100,
                      }}
                    >
                      บันทึก
                    </button>
                    <button
                      onClick={(e) => this.closeModal()}
                      type="Button"
                      className="btn btn-danger pull-right"
                      style={{ color: "white", width: 100 }}
                    >
                      ยกเลิก
                    </button>
                  </div>
                </React.Fragment>
              )}
            />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  };

  async deleteWriter(id, editor_id) {
    const value = {};
    value.id = id;
    value.editor_id = editor_id;
    value.page = this.state.currentPage;

    await this.props.deleteWriter(value);
    setTimeout(() => {
      this.getAllWriter(this.state.currentPage);
    }, 100);
  }

  changePage(page) {
    this.setState({ currentPage: page });
    this.getAllWriter(page);
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <div style={{ padding: 20 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">จัดการนักเขียน</h6>
                </Col>
                <Col>
                  <Button
                    onClick={(e) => this.toggleModal()}
                    className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    เพิ่มนักเขียน
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.writers.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}
              </nav>

              <div className="text-right">
                <a
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                  type="Button"
                  className="btn btn-danger pull-right"
                  style={{ color: "white", width: 100 }}
                >
                  Back
                </a>
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ editorReducer, memberReducer }) => ({
  editorReducer,
  memberReducer,
});

const mapDispatchToProps = {
  getWriterByEditorID,
  deleteWriter,
  getWriterName,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageEditor);

import React, { Component } from "react";
import { Formik } from "formik";
import { server } from "../../../constants";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from "shards-react";
import { httpClient } from "../../../utils/HttpClient";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import moment from "moment";
import RedeemCodeForm from "../redeemCodeForm";

const redeemValidationSchema = Yup.object().shape({
  name: Yup.string().required('กรุณากรอกชื่อแคมเปญ'),
  coin: Yup.string().required('กรุณากรอกจำเหรียญที่จะได้รับ'),
  digit: Yup.string().required('กรุณากรอกจำนวนหลักของ Code'),
  quantity: Yup.string().required('กรุณากรอกจำนวน Code ที่ต้องการสร้าง'),
  validDate: Yup.date().required('กรุณาเลือกวันที่หมดอายุ'),
  coinExpiredDate: Yup.date().required('กรุณาเลือกวันที่หมดอายุ'),
  limit: Yup.string().required('กรุณากรอกจำนวนครั้งที่สามารถใช้ได้')          
});

class GenerateRedeemCode extends Component {
  showForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    errors,
    touched
  }) => {
    return (
      <div style={{ padding: 40 }}>
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0 text-upper">Generate Redeem Code</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <form className="form-horizontal" onSubmit={handleSubmit}>
                    <RedeemCodeForm
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      touched={touched}
                      isDisableField={false}
                    ></RedeemCodeForm>

                    <div className="mt-3 text-right">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary pull-right"
                        style={{ marginRight: 10, color: "white", width: 100 }}
                      >
                        Generate
                      </button>
                      <button
                        onClick={() => {
                          this.props.history.goBack();
                        }}
                        type="Button"
                        className="btn btn-danger pull-right"
                        style={{ color: "white", width: 100 }}
                      >
                        Back
                      </button>
                    </div>
                  </form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            partner: '',
            name: '',
            code: '',
            coin: 0,
            quantity: 0,
            digit: 10,
            validDate: '',
            coinExpiredDate: '',
            isEdit: false,
            status: 1,
            limit: 1
          }}
          validationSchema={redeemValidationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            let result = localStorage.getItem("user_profile");
            let username;
            if (result) username = JSON.parse(result).user_username;

            const params = {
              name: values.name,
              code: values.code,
              partner: values.partner,
              coin: Number(values.coin.toString().replace(/,/g, '')),
              valid_date: values.validDate,
              quantity: Number(values.quantity.toString().replace(/,/g, '')),
              digit: Number(values.digit.toString().replace(/,/g, '')),
              coin_expired_date: moment(values.coinExpiredDate).endOf('day'),
              status: values.status,
              limit: Number(values.limit.toString().replace(/,/g, '')),
              created_by: username
            }

            try {
              const token = localStorage.getItem("token")

              await httpClient.post(`${server.REDEEM_URL}/generate`, JSON.stringify(params), {
                headers: {
                  "Content-Type": "application/json",
                  "Authorization": `Bearer ${token}`
                },
              });

              Swal.fire({
                icon: "success",
                title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
                showConfirmButton: false,
                timer: 1500,
              });
              setSubmitting(false);
              this.props.history.goBack();
              
            } catch(e) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
                timer: 1500,
              });
            }
          }}
        >
          {(props) => this.showForm(props)}
        </Formik>
      </React.Fragment>
    );
  }
}

export default GenerateRedeemCode;

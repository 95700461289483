import {
    HTTP_WRITER_EDITOR_SUCCESS,
    HTTP_WRITER_EDITOR_FETCHING,
    HTTP_WRITER_EDITOR_FAILED,
  } from "../constants/report";
  
  const initialState = {
    writers: null,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_WRITER_EDITOR_SUCCESS:
        return {
          ...state,
          writers: payload.writers,
          total: payload.total,
          isFetching: false,
          isError: false,
        };
      case HTTP_WRITER_EDITOR_FAILED:
        return { ...state, writers: null, isFetching: false, isError: true };
      case HTTP_WRITER_EDITOR_FETCHING:
        return { ...state, writers: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
  
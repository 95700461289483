import React, { Component } from "react";
import { getReportBookSale } from "../../../actions/reportBookSale.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  InputGroupText,
  DatePicker,
  FormSelect,
  Badge,
} from "shards-react";
import { Formik } from "formik";
import moment from "moment";
import ContentPagination from "../../Layout/Common/Pagination";
import XLSX from "xlsx";

class ReporBookSaleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      books: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      writer_id: "",
    };
  }

  async componentDidMount() {
    await this.setState({
      writer_id: this.props.match.params.writer_id,
    });

    await this.getReportBookSale(this.state.currentPage);
  }

  async getReportBookSale(page) {
    this.setState({ loading: true });

    const value = {};
    value.start_date = this.state.start_date;
    value.end_date = this.state.end_date;
    value.writer_id = this.state.writer_id;
    value.page = page;

    await this.props.getReportBookSale(value);

    const { books, total } = this.props.reportBookSaleReducer;

    const totalPages = Math.ceil(total / this.state.perPage);

    if (this.props.reportBookSaleReducer.books) {
      this.setState({
        books,
        totalPages,
      });
    }
    this.setState({ loading: false });
  }

  formatCoin(item) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(item);
  }

  createSummaryFooter = () => {
    try {
      const { books, total } = this.props.reportBookSaleReducer;

      const summaryTotal = books.reduce(
        (summary, data) => summary + parseInt(data.sum_price),
        0
      );

      const goldTotal = books.reduce(
        (summary_gold, data) => summary_gold + parseInt(data.sum_gold_price),
        0
      );

      const silverTotal = books.reduce(
        (summary_silver, data) =>
          summary_silver + parseInt(data.sum_silver_price),
        0
      );

      if (books != null) {
        return (
          <tr>
            <td className="text-center font-weight-bold">Grand Total</td>
            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(summaryTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(goldTotal)}
            </td>

            <td className="text-center font-weight-bold">
              {new Intl.NumberFormat("en-GB", {
                style: "decimal",
              }).format(silverTotal)}
            </td>
          </tr>
        );
      }
    } catch (e) {}
  };

  renderTable = () => {
    return (
      <React.Fragment>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>ชื่อหนังสือ</th>
              <th style={{ textAlign: "center" }}>จำนวนเหรียญที่ซื้อทั้งหมด</th>
              <th style={{ textAlign: "center" }}>เหรียญทอง</th>
              <th style={{ textAlign: "center" }}>เหรียญเงิน</th>
            </tr>
          </thead>
          <tbody>
            {this.state.books.map((item, index) => {
              return (
                <tr key={item.book_id}>
                  <td className="text-center align-middle">
                    {item.book_title}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatCoin(item.sum_price)}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatCoin(item.sum_gold_price)}
                  </td>
                  <td className="text-center align-middle">
                    {this.formatCoin(item.sum_silver_price)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Row>
          <Col sm="7" className="sm-7 ml-3">
            <Card small className="lo-stats h-100">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Summary</h6>
                <div className="block-handle" />
              </CardHeader>

              <CardBody className="p-0">
                <Container fluid className="px-0">
                  <table className="table mb-0 table-striped table-hover table-fixed">
                    <thead className="py-2 text-semibold border-bottom">
                      <tr>
                        <th className="text-center"></th>
                        <th className="text-center">
                          จำนวนเหรียญที่ซื้อทั้งหมด
                        </th>
                        <th className="text-center">เหรียญทอง</th>
                        <th className="text-center">เหรียญเงิน</th>
                      </tr>
                    </thead>
                    <tfoot>{this.createSummaryFooter()}</tfoot>
                  </table>
                </Container>
              </CardBody>
            </Card>
          </Col>
          <Col sm="5" className="sm-5"></Col>
        </Row>
      </React.Fragment>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getReportBookSale(page);
  }

  async exportData() {
    await this.getReportBookSale("");

    let report = this.state.books.map((item, idx) => {
      return [
        item.book_title,
        this.formatCoin(item.sum_price),
        this.formatCoin(item.sum_silver_price),
        this.formatCoin(item.sum_gold_price),
      ];
    });

    report = [
      ["ชื่อหนังสือ", "จำนวนเหรียญที่ซื้อทั้งหมด", "เหรียญทอง", "เหรียญเงิน"],
      ...report,
    ];

    if (report) {
      this.downloadExportData(report);
    }
  }

  downloadExportData(report) {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(report);
    XLSX.utils.book_append_sheet(workBook, workSheet, [
      "report-book-publisher",
    ]);
    XLSX.writeFile(workBook, `report-book-publisher.xlsx`);

    this.getReportBookSale(0);
  }

  render() {
    const DatePickerField = ({ name, value, onChange, placeholder }) => {
      return (
        <DatePicker
          selected={(value && new Date(value)) || null}
          className="text-center"
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          onChange={(val) => {
            onChange(name, val);
          }}
        />
      );
    };
    return (
      <Container fluid className="main-content-container px-4">
        <div style={{ padding: 20 }}>
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">ยอดขายของหนังสือ</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.exportData();
                    }}
                    type="button"
                    className=" btn btn-sm btn-danger d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Export
                  </Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody style={{ padding: "1rem" }}>
              <Formik
                initialValues={{
                  start_date: "",
                  end_date: "",
                  page: "",
                }}
                onSubmit={async (values) => {
                  let startDate = null;
                  let endDate = null;

                  if (values.start_date) {
                    startDate = moment(values.start_date).format("YYYY-MM-DD");
                  }

                  if (values.end_date) {
                    endDate = moment(values.end_date).format("YYYY-MM-DD");
                  }

                  this.setState({
                    start_date: startDate,
                    end_date: endDate,
                  });

                  this.getReportBookSale(this.state.currentPage);
                }}
              >
                {(props) => {
                  const {
                    values,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    handleChange,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Row form>
                        <Col sm="6" className="form-group">
                          <label>วันที่เริ่มต้น/สิ้นสุด</label>
                          <InputGroup className={"d-flex my-auto date-range"}>
                            <DatePickerField
                              size="sm"
                              name="start_date"
                              onChange={setFieldValue}
                              value={values.start_date}
                              placeholder="Start Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <DatePickerField
                              size="sm"
                              name="end_date"
                              onChange={setFieldValue}
                              value={values.end_date}
                              placeholder="End Date"
                              dropdownMode="select"
                              className="text-center"
                            />
                            <InputGroupAddon type="append">
                              <InputGroupText>
                                <i className="material-icons">&#xE916;</i>
                              </InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </Col>
                      </Row>
                    </form>
                  );
                }}
              </Formik>
            </CardBody>

            <div
              className={
                "content-loading-wrapper " +
                (this.state.loading ? "loading" : "")
              }
            >
              {this.renderTable()}
            </div>

            <CardBody className="pt-2">
              <nav className="text-right">
                {this.state.books.length > 0 && (
                  <ContentPagination
                    currentPage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.changePage.bind(this)}
                    pageShow={5}
                  />
                )}

                <a
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                  type="Button"
                  className="btn btn-danger pull-right"
                  style={{ color: "white", width: 100 }}
                >
                  Back
                </a>
              </nav>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ reportBookSaleReducer }) => ({
  reportBookSaleReducer,
});

const mapDispatchToProps = {
  getReportBookSale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReporBookSaleEditor);

import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Menu, Dropdown } from 'antd';
import * as actions from "../../../actions/book_character.action";
import { setMainCharacter } from "../../../actions/book_chapter.action";
import { 
  EllipsisOutlined
} from '@ant-design/icons';

class BookCharacterItem extends Component {
  constructor() {
    super();
    this.state = { 
      characterModalOpen: false,
      characterImage: '',
      characterName: '',
      characterRole: '',
      characterStatus: '',
      characterBio: '' 
    }
  }

  componentDidMount() {
  }

  editCharacter() {
    this.props.onEditCharacter(this.props.character)
  }

  async updateCharacter() {
    if (!this.state.characterImage) {
      Swal.fire({
        icon: "error",
        text: "กรุณาอัพโหลดรูปตัวละคร",
        timer: 3000
      });

      return
    }

    if (!this.state.characterName) {
      Swal.fire({
        icon: "error",
        text: "กรุณากรอกชื่อตัวละคร",
        timer: 3000
      });

      return
    }

    const params = {
      id: this.props.character.id,
      name: this.state.characterName,
      role: this.state.characterRole,
      status: this.state.characterStatus,
      bio: this.state.characterBio,
      image: this.state.characterImage
    }

    await this.props.updateBookCharacter(params)
    
    if(!this.props.bookCharacterReducer.isError)  {
      this.setState({
        characterModalOpen: false,
        characterImage: '',
        characterName: '',
        characterStatus: '',
        characterBio: '',
        characterRole: ''
      })

      Swal.fire({
        icon: "success",
        title: "แก้ไขตัวละครเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "ไม่สามารถแก้ไขตัวละครได้ กรุณาลองใหม่อีกครั้ง",
        timer: 1500
      });
    }
  }

  deleteConfirmation() {
    Swal.fire({
      text: "ยืนยันการลบตัวละคร?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.value) {
        this.deleteCharacter()
      }
    });
  }

  async deleteCharacter() {
    await this.props.deleteBookCharacter(this.props.character.id);

    if(!this.props.bookCharacterReducer.isError)  {
      Swal.fire({
        icon: "success",
        title: "ลบตัวละครเรียบร้อยแล้ว",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        text: "ไม่สามารถลบตัวละครได้ กรุณาลองใหม่อีกครั้ง",
        timer: 1500
      });
    }
  }

  setMainCharacter()  {
    this.props.setMainCharacter(this.props.character.id)
  }

  render() {
    const { mainCharacter } = this.props.bookChapterReducer
    
    const menu = (
      <Menu style={{width: 130}}>
        <Menu.Item className="font-12" onClick={() => this.setMainCharacter()}>ตั้งเป็นตัวละครหลัก</Menu.Item>
        <Menu.Item className="font-12" onClick={() => this.editCharacter()}>แก้ไข</Menu.Item>    
        <Menu.Item className="font-12" onClick={() => this.deleteConfirmation()}>ลบ</Menu.Item>    
      </Menu>
    );

    const activeClass = this.props.character.id === mainCharacter  ? "active" : ""

    return (
      <>
        <div 
          className={`card shadow-none border mr-3 mb-3 chat-character-card pointer ${activeClass}`} key={this.props.character.id}
        >
          <Dropdown 
            overlay={menu} 
            trigger={['click']} 
            placement="bottomRight"
          >
            <div 
              className="edit-options text-muted"
            ><EllipsisOutlined /></div>
          </Dropdown>
          <div className="card-body py-2 px-3 d-flex align-items-center">
            <img 
              src={this.props.character.image} 
              alt="" 
              width="50" 
              className="rounded-circle mr-3" 
            />
            
            <div>
              <div className="font-weight-bold font-16">{ this.props.character.name }</div>
              <div className="text-muted font-12">{ this.props.character.status }</div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ bookCharacterReducer, bookChapterReducer }) => ({
  bookCharacterReducer,
  bookChapterReducer
});

const mapDispatchToProps = {
  ...actions,
  setMainCharacter
};

export default connect(mapStateToProps, mapDispatchToProps)(BookCharacterItem);

import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink, Collapse } from "shards-react";

class SidebarNavItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      item: ''
    };
  }

  async componentDidMount() {
    this.setState({item: this.props.item})
  }

  toggleSubMenu() {
    const item = this.state.item
    item.open = !item.open
    this.setState({item})
  }

  render()  {
    const item = this.state.item

    return (
      <NavItem>
        {item.show && (
          <NavLink 
            className={"pointer d-flex " + (item.open ? "open" : "")}
            {...(!item.menus && { tag: RouteNavLink })}
            onClick={() => item.menus ? this.toggleSubMenu() : () => false }
            to={item.to}
          >
            {item.htmlBefore && (
              <div
                className="d-inline-block item-icon-wrapper"
                dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
              />
            )}
            {item.title && <span className="flex-fill">{item.title}</span>}
            {item.htmlAfter && (
              <div
                className="d-inline-block item-icon-wrapper menu-arrow"
                dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
              />
            )}
          </NavLink>
        )}
        { item.menus && (
          <Collapse open={item.open} navbar>
            <ul className="list-unstyled">
              { 
                item.menus.map((menu, index) => {    
                  if(menu.show) {
                    return (
                      <NavItem key={`menu${index}`}>
                        <NavLink tag={RouteNavLink} to={menu.to} key={index}>{menu.title}</NavLink> 
                      </NavItem>
                    )
                  }
                })
              }
            </ul>
          </Collapse>
          )
        }
      </NavItem>
    )
  }
}

export default SidebarNavItem;

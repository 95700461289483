import React, { Component, useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import moment from "moment"
import { withRouter } from 'react-router-dom';
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { DatePicker, Table, Spin } from 'antd';
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker

const columns = [
  { 
    title: 'ชื่อหนังสือ', 
    dataIndex: 'bookTitle', 
    key: 'bookTitle' 
  },
  { 
    title: 'รวมเป็นเงิน (บาท)', 
    dataIndex: 'income', 
    key: 'income',
    align: 'center'
  },
  { 
    title: 'เหรียญทอง',
    dataIndex: 'goldCoin', 
    key: 'goldCoin', 
    align: 'center' 
  },
  { 
    title: 'เหรียญเงิน', 
    dataIndex: 'silverCoin', 
    key: 'silverCoin', 
    align: 'center' 
  },
  { 
    title: 'เหรียญทอง (หนังสือเสียง)', 
    dataIndex: 'audioGoldCoin', 
    key: 'audioGoldCoin', 
    align: 'center' 
  },
  { 
    title: 'เหรียญเงิน (หนังสือเสียง)', 
    dataIndex: 'audioSilverCoin', 
    key: 'audioSilverCoin', 
    align: 'center' 
  }
]

export default function WriterIncome({ member }) {
  const [loading, setLoading] = useState(false)
  const [chapterLoading, setChapterLoading] = useState({})
  const [memberId, setMemberId] = useState(null)
  const [books, setBooks] = useState([])
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const params = useParams()

  useEffect(() => {
    getMemberIncome('', '')
  }, [])

  const getMemberIncome = async (dataStart, dateEnd) => {
    const token = localStorage.getItem("token")
    const dataFilter = `?start_date=${dataStart}&end_date=${dateEnd}`

    setExpandedRowKeys([])
    setLoading(true)

    let writerResult = []
    let editorResult = []
    let publisherResult = []
    let totalBooks = []

    try {
      writerResult = await httpClient.get(`admin/member/${params.member_id}/income${dataFilter}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      totalBooks = writerResult.data.map(book => ({
        ...book, 
        owner: 'writer'
      }))

      if(member.member_type === 3) {
        editorResult = await httpClient.get(`admin/editor/${params.member_id}/income${dataFilter}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        totalBooks = [...totalBooks, ...editorResult.data.map(book => ({ ...book, owner: 'editor' }))]
      }

      if(member.member_type === 4) {
        publisherResult = await httpClient.get(`admin/publisher/${params.member_id}/income${dataFilter}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        totalBooks = [...totalBooks, ...publisherResult.data.map(book => ({ ...book, owner: 'publisher' }))]
      }

      const books = totalBooks.map((item, index) => {
        const goldCoin = item.gold_coin.toLocaleString(undefined, {minimumFractionDigits: 2})
        const silverCoin = item.silver_coin.toLocaleString(undefined, {minimumFractionDigits: 2})
        const audioGoldCoin = item.audio_gold_coin.toLocaleString(undefined, {minimumFractionDigits: 2})
        const audioSilverCoin = item.audio_silver_coin.toLocaleString(undefined, {minimumFractionDigits: 2})

        let goldPercentage = 0
        let silverPercentage = 0

        if(item.owner === 'writer') {
          goldPercentage = member.gold_coin_earning_percentage
          silverPercentage = member.silver_coin_earning_percentage
        } else if(item.owner === 'editor') {
          goldPercentage = item.editor_percentage
          silverPercentage = item.editor_percentage
        } else if(item.owner === 'publisher') {
          goldPercentage = item.publisher_percentage
          silverPercentage = item.publisher_percentage
        }

        const totalGoldIncome = convertCoinToBaht(item.income_gold, goldPercentage)
        const totalSilverIncome = convertCoinToBaht(item.income_silver, silverPercentage)
        const totalAudioGoldIncome = convertCoinToBaht(item.audio_income_gold, goldPercentage)
        const totalAudioSilverIncome = convertCoinToBaht(item.audio_income_silver, silverPercentage)

        const totalIncome = totalGoldIncome + totalSilverIncome + totalAudioGoldIncome + totalAudioSilverIncome

        return {
          key: item.book_id.toString(),
          bookTitle: item.book_title,
          editor_percentage: item.editor_percentage,
          publisher_percentage: item.publisher_percentage,
          goldCoin: goldCoin,
          silverCoin: silverCoin,
          audioGoldCoin: audioGoldCoin,
          audioSilverCoin: audioSilverCoin,
          income: totalIncome.toLocaleString(undefined, {minimumFractionDigits: 2}),
          chapters: [],
          owner: item.owner
        }
      })
      setBooks(books)
      setLoading(false)

    } catch(e) {
      setLoading(false)
    }
  }

  const convertCoinToBaht = (totalCoin, percentage) => {
    const totalIncome = totalCoin/2
    const shareIncome = totalIncome*(percentage/100)

    return shareIncome
  }

  const onDateChange = (e) => {
    let dateStart = ''
    let dateEnd = ''

    if(e) {
      dateStart = moment(e[0]).format('YYYY-MM-DD 00:00:00')
      dateEnd = moment(e[1]).format('YYYY-MM-DD 23:59:59')

      setDateStart(dateStart)
      setDateEnd(dateEnd)
    }

    getMemberIncome(dateStart, dateEnd)
  }

  const handleRowExpand = (expanded, record) => {
    const currentExpandedRows = expandedRowKeys

    if(currentExpandedRows.includes(record.key)) {
      const index = currentExpandedRows.indexOf(record.key);  
      currentExpandedRows.splice(index, 1)
    } else {
      currentExpandedRows.push(record.key)
    }
    
    setExpandedRowKeys(currentExpandedRows)

    if(expanded) {
      getChaptersIncome(record)
    }
  }

  const getChaptersIncome = async (book) => {
    const newBooks = books

    setChapterLoading({
      [book.key]: true
    })

    try {
      const token = localStorage.getItem("token")
      
      const result = await httpClient.get(`/admin/book/${book.key}/income?start_date=${dateStart}&end_date=${dateEnd}`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      })

      const chapters = result.data.chapters.map(chapter => {
        const goldCoin = Math.round(chapter.gold_coin)
        const silverCoin = Math.round(chapter.silver_coin)
        const audioGoldCoin = Math.round(chapter.audio_gold_coin)
        const audioSilverCoin = Math.round(chapter.audio_silver_coin)
        
        let goldPercentage = 0
        let silverPercentage = 0

        if(book.owner === 'writer') {
          goldPercentage = member.gold_coin_earning_percentage
          silverPercentage = member.silver_coin_earning_percentage
        } else if(book.owner === 'editor') {
          goldPercentage = book.editor_percentage
          silverPercentage = book.editor_percentage
        } else if(book.owner === 'publisher') {
          goldPercentage = book.publisher_percentage
          silverPercentage = book.publisher_percentage
        }

        const totalGoldIncome = convertCoinToBaht(chapter.income_gold, goldPercentage)
        const totalSilverIncome = convertCoinToBaht(chapter.income_silver, silverPercentage)
        const totalAudioGoldIncome = convertCoinToBaht(chapter.audio_income_gold, goldPercentage)
        const totalAudioSilverIncome = convertCoinToBaht(chapter.audio_income_silver, silverPercentage)

        return {
          key: chapter.book_chaptor_id.toString(), 
          chaptor_title: chapter.chaptor_title,
          gold_coin: goldCoin.toLocaleString(),
          silver_coin: silverCoin.toLocaleString(),
          audio_gold_coin: audioGoldCoin.toLocaleString(),
          audio_silver_coin: audioSilverCoin.toLocaleString(),
          income: (totalGoldIncome + totalSilverIncome + totalAudioGoldIncome + totalAudioSilverIncome).toLocaleString(),
        }
      })

      for(const newBook of newBooks) {
        if(book.key === newBook.key) {
          book.chapters = chapters
        }
      }

      setBooks(newBooks)
      setChapterLoading({
        [book.key]: false
      })
    } catch(e) {
      setChapterLoading({
        [book.key]: false
      })
    }
    setBooks(newBooks)
  }

  const expandedRowRender = (e) => {
    const columns = [
      {
        title: 'ชื่อตอน',
        dataIndex: 'chaptor_title',
        key: 'chaptor_title'
      },
      { 
        title: 'รวมเป็นเงิน (บาท)',
        dataIndex: 'income', 
        key: 'income', 
        align: 'center' 
      },
      { 
        title: 'เหรียญทอง',
        dataIndex: 'gold_coin', 
        key: 'gold_coin', 
        align: 'center' 
      },
      { 
        title: 'เหรียญเงิน', 
        dataIndex: 'silver_coin', 
        key: 'silver_coin', 
        align: 'center' 
      },
      { 
        title: 'เหรียญทอง (หนังสือเสียง)', 
        dataIndex: 'audio_gold_coin', 
        key: 'audio_gold_coin', 
        align: 'center' 
      },
      { 
        title: 'เหรียญเงิน (หนังสือเสียง)', 
        dataIndex: 'audio_silver_coin', 
        key: 'audio_silver_coin', 
        align: 'center' 
      }
    ]
    
    return (
      <Table 
        columns={columns} 
        dataSource={e.chapters} 
        pagination={false} 
        loading={chapterLoading[e.key]}
      />
    )
  }

  return (
    <>
      <RangePicker 
        placeholder={['เริ่มต้น', 'สิ้นสุด']} 
        format="DD/MM/YYYY"
        disabledDate={false}
        onChange={onDateChange}
      />

      <div className="mt-4">
        <Table
          columns={columns}
          expandable={{
            expandedRowRender, 
            expandedRowKeys: expandedRowKeys,
            onExpand: handleRowExpand
          }}
          dataSource={books}
          loading={loading}
        />
      </div>
    </>
  )
}
import React, { Component, useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import moment from "moment"
import { withRouter } from 'react-router-dom';
import * as actions from "../../actions/member.action";
import { server } from "../../constants";
import { Row, Col, FormInput } from "shards-react";
import { Table, Tag, Button, Skeleton, Modal, message } from 'antd';
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

const { Column } = Table;

export default function WriterWithdraw({ member }) {
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  const [withdrawing, setWithdrawing] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [goldCoinRemain, setGoldCoinRemain] = useState(0)
  const [silverCoinRemain, setSilverCoinRemain] = useState(0)
  const [sharedGoldBaht, setSharedGoldBaht] = useState(0)
  const [sharedSilverBaht, setSharedSilverBaht] = useState(0)
  const [isModalVisible, setModalVisible] = useState(false)
  
  useEffect(() => {
    getMemberCurrentBalance()
    getWithdrawTransaction()
  }, [])

  const getMemberCurrentBalance = async () => {
    setLoading(true)

    try {
      const token = localStorage.getItem("token");
  
      const result = await httpClient.get(
        `admin/member/${member.member_id}/balance`, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        }
      ) 

      setGoldCoinRemain(result.data.goldCoinRemain)
      setSilverCoinRemain(result.data.silverCoinRemain)
      setSharedGoldBaht(result.data.totalGoldBaht)
      setSharedSilverBaht(result.data.totalSilverBaht)
      
      setLoading(false)

    } catch(e) {
      message.open({ content: 'โหลดข้อมูลรายได้ไม่สำเร็จ' })
      setLoading(false)
    }
  }

  const getWithdrawTransaction = async () => {
    setHistoryLoading(true)

    try {
      const token = localStorage.getItem("token");
      const result = await httpClient.get(
        `admin/member/withdraw/${member.member_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      )

      const tableRows = result.data.map((item, index) => {
        return {
          key: index.toString(),
          id: item.id,
          goldCoin: item.gold_coin.toLocaleString(),
          silverCoin: item.silver_coin.toLocaleString(),
          amount: item.amount.toLocaleString(),
          date: moment(item.created_datetime).format("DD/MM/YYYY"),
          status: item.status,
          tax: item.tax_document_url
        }
      })
      
      setTransactions(tableRows)
      setHistoryLoading(false)
    } catch(e) {
      message.open({ content: 'โหลดข้อมูลการถอนเงินไม่สำเร็จ' })
      setHistoryLoading(false)
    }
  }

  const openModal = () => {
    setModalVisible(true)
  }

  const closeModal = () => {
    setModalVisible(false)
  }

  const handleTaxUpload = async (id, file) => {
    setUploading(true)

    try {
      const formData = new FormData();
      formData.append("upload", file);

      const response = await httpClient.post(server.IMG_URL, formData, {
        headers: {
          processData: false,
          enctype: "multipart/form-data",
        },
      });

      updateWithdrawTax(id, response.data.url)
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "เกิดข้อผิดพลาดในการอัพโหลดไฟล์ กรุณาลองใหม่อีกครั้ง",
        timer: 3000,
      });
    }

    setUploading(false)
  }

  const updateWithdrawTax = async (id, url) => {
    let username = "";
    const result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username

    const updatedTime = moment().format("YYYY-MM-DD HH:mm:ss")

    try {
      const token = localStorage.getItem("token")

      await httpClient.patch(
        `/admin/member/withdraw/${id}/tax`,
        JSON.stringify({
          tax_document_url: url,
          updated_by: username,
          updated_datetime: updatedTime
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );


      getWithdrawTransaction()
    } catch(err)  {

    }
  }

  const withdrawMoney = async () => {
    if (parseFloat(sharedGoldBaht) + parseFloat(sharedSilverBaht) > 0) {
      setWithdrawing(true)
      const result = localStorage.getItem("user_profile");
      let username

      if (result) {
        username = JSON.parse(result).user_username;
      }

      const params = {
        member_id: member.member_id,
        gold_coin: goldCoinRemain,
        silver_coin: silverCoinRemain,
        amount: parseFloat(sharedGoldBaht) + parseFloat(sharedSilverBaht),
        status: '1',
        created_by: username
      }

      try {
        const token = localStorage.getItem("token")
        let response = await httpClient.post(`admin/member/withdraw`, params, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
          },
        });

      
        Swal.fire({
          icon: "success",
          title: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
          showConfirmButton: false,
          timer: 1500
        })

        getMemberCurrentBalance()
        getWithdrawTransaction()
        setWithdrawing(false)
        closeModal()
      } catch(e) {
        setWithdrawing(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "เกิดข้อผิดพลาดในการบันทึกข้อมูล!",
          timer: 1500
        })
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "ยอดการถอนเงินต้องมากกว่า 0",
        timer: 1500
      })
    }
  }

  return (
    <>
      <div className="d-flex align-items-center mb-4 container-fluid">
        <div className="flex-fill d-flex">
          <div className="d-flex align-items-center mr-4">
            <span className="text-nowrap">เหรียญทอง:</span>

            <div className="ml-1">
              { loading ?
                <Skeleton.Button active={true} size="small" style={{ width: 60, marginTop: 5 }}/> :
                <b style={{ fontSize: 16 }}> { parseFloat(goldCoinRemain).toLocaleString(undefined, {minimumFractionDigits: 2}) }</b>
              }
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span className="text-nowrap">เหรียญเงิน:</span>

            <div className="ml-1">
              { loading ?
                <Skeleton.Button active={true} size="small" style={{ width: 60, marginTop: 5 }}/> :
                <b style={{ fontSize: 16 }}> { parseFloat(silverCoinRemain).toLocaleString(undefined, {minimumFractionDigits: 2}) }</b>
              }
            </div>
          </div>
        </div>
        <Button
          className="btn btn-secondary"
          disabled={loading || historyLoading}
          onClick={openModal}
        >
          ถอนเงิน
        </Button>
      </div>

      <Table
        dataSource={transactions}
        loading={historyLoading}
      >
        <Column
          title="เหรียญทอง"
          dataIndex="goldCoin"
          key="goldCoin"
          align="center"
        />
        <Column
          title="เหรียญเงิน"
          dataIndex="silverCoin"
          key="silverCoin"
          align="center"
        />
        <Column
          title="คิดเป็นเงิน (บาท)"
          dataIndex="amount"
          key="amount"
          align="center"
        />
        <Column
          title="วันที่ทำรายการ"
          dataIndex="date"
          key="date"
          align="center"
        />
        <Column 
          title="สถานะ"
          dataIndex="status"
          key="status"
          align="center"
          render={(_, { status }) => {
            let color
            let text 

            if(status === '1') {
              color = '#ffb703'
              text = 'รอการยืนยัน'
            } else if(status === '2') {
              color = '#87d068'
              text = 'จ่ายแล้ว'
            } else if(status === '3') {
              color = '#c1121f'
              text = 'ยกเลิก'
            }

            return <Tag color={color}>{ text }</Tag>
          }}
        />
        <Column 
          title="ใบหักภาษี"
          dataIndex="tax"
          key="tax"
          align="center"
          render={(_, { tax }) => {
            const docElm = tax ?
            <a href={tax} target="_blank" rel="noopener noreferrer">
              <Tag color="#87d068" className="py-1">เปิดไฟล์</Tag>
            </a> :
            <Tag color="#646E78">ไม่มีเอกสาร</Tag>
            
            return docElm
          }}
        />
        <Column
          title=""
          dataIndex="upload"
          key="upload"
          render={(_, record) => (
            <div className="position-relative">
              <button type="button" className="btn btn-danger btn-xs">อัพโหลดใบหักภาษี</button>
              <input type="file" className="pointer" style={{position:'absolute', top:0, left:0, right:0, bottom:0, opacity:0, zIndex:2}} onChange={(e) => handleTaxUpload(record.id, e.target.files[0])} />
            </div>
          )}
        />
      </Table>

      <Modal
        title={null}
        footer={null}
        width={700}
        visible={isModalVisible}
        onCancel={closeModal}
      >
        <div className="d-flex">
          <div className="d-flex align-items-center mr-4">
            <span className="text-nowrap">เหรียญทอง:</span>

            <div className="ml-1">
              { loading ?
                <Skeleton.Button active={true} size="small" style={{ width: 60, marginTop: 5 }}/> :
                <b style={{ fontSize: 16 }}> { parseFloat(goldCoinRemain).toLocaleString(undefined, {minimumFractionDigits: 2}) }</b>
              }
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span className="text-nowrap">เหรียญเงิน:</span>

            <div className="ml-1">
              { loading ?
                <Skeleton.Button active={true} size="small" style={{ width: 60, marginTop: 5 }}/> :
                <b style={{ fontSize: 16 }}> { parseFloat(silverCoinRemain).toLocaleString(undefined, {minimumFractionDigits: 2}) }</b>
              }
            </div>
          </div>
        </div>

        <hr />

        <div className="table-responsive mb-0 mt-2">
          <table className="table table-bordered">
            <thead className="font-12">
              <tr>
                <th style={{ textAlign: "center" }}>เหรียญทอง</th>
                <th style={{ textAlign: "center" }}>คิดเป็นเงิน (บาท)</th>
                <th style={{ textAlign: "center" }}>เหรียญเงิน</th>
                <th style={{ textAlign: "center" }}>คิดเป็นเงิน (บาท)</th>
              </tr>
            </thead>
            <tbody>

            </tbody>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  { parseFloat(goldCoinRemain).toLocaleString() || 0 }
                </td>
                <td
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  { parseFloat(sharedGoldBaht).toLocaleString() }
                </td>
                <td style={{ textAlign: "center" }}>
                  { parseFloat(silverCoinRemain).toLocaleString() }
                </td>
                <td
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  { parseFloat(sharedSilverBaht).toLocaleString() }
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-4 d-flex align-items-center">
          จำนวนเงินที่ถอนทั้งหมด:
          <b className="h5 mb-0 mx-2 font-weight-bold">
            { (parseFloat(sharedGoldBaht) + parseFloat(sharedSilverBaht)).toFixed(2) }
          </b>
          บาท
        </div>

        <div className="mt-4 text-right">
          <Button
            className="btn btn-primary mr-3"
            loading={withdrawing}
            onClick={withdrawMoney}
          >
            บันทึก
          </Button>
          <Button
            className="btn btn-secondary"
            disabled={withdrawing}
            onClick={closeModal}
          >
            ยกเลิก
          </Button>
        </div>
      </Modal>
    </>
  )
}
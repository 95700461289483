import React, { Component } from "react";
import * as actions from "../../../actions/member.action";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormInput,
  FormGroup,
  FormSelect,
} from "shards-react";
import Swal from "sweetalert2";
import moment from "moment";
import NumberFormat from "react-number-format";
import ContentPagination from "../../Layout/Common/Pagination";

const isAllowAction = () => {
  const result = localStorage.getItem("user_profile");

  if (result) {
    const priv_allow = JSON.parse(result).writer;

    if (priv_allow === "Y") return true;
    else {
      Swal.fire({
        icon: "error",
        text: "No privilege!",
      });
      return false;
    }
  }
};

class Writers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      name: "",
      email: "",
    };
  }

  async componentDidMount() {
    const current_page = localStorage.getItem("writer_current_page");

    if (this.props.location.page)
      await this.setState({ currentPage: this.props.location.page });
    else if (current_page)
      await this.setState({ currentPage: Number(current_page) });

    await this.getMembers(this.state.currentPage); //get member_type =2 (1 equal Member,2 equal Writer)
    this.props.getWriterName();

    localStorage.removeItem("writer_current_page");
    localStorage.removeItem("topup_current_page");
    localStorage.removeItem("purchase_current_page");
  }

  async getMembers(page) {
    this.setState({ loading: true });

    await this.props.getAllMember(
      2,
      this.state.name,
      "",
      "",
      this.state.email,
      page
    );

    const { members, total } = this.props.memberReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      members,
      totalPages,
      loading: false,
    });
  }

  btnFormatter(member_id) {
    let username = "";
    var result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    return (
      <React.Fragment>
        <div className="mt-1">
          <a
            type="button"
            className="btn btn-xs btn-success text-white"
            //href={"/editWriter/" + member_id}
            onClick={() =>
              this.props.history.push(
                {
                  pathname: "/editWriter/" + member_id,
                  page: this.state.currentPage,
                },
                localStorage.setItem(
                  "writer_current_page",
                  Number(this.state.currentPage)
                )
              )
            }
          >
            Edit
          </a>

          <span style={{ color: "grey" }}> | </span>

          <a
            onClick={() => {
              return (
                isAllowAction() &&
                Swal.fire({
                  text: "Are you sure to delete?",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes, delete it!",
                  cancelButtonText: "No, cancel!",
                }).then((result) => {
                  if (result.value) {
                    this.deleteMember(member_id); // (1 equal Member,2 equal Writer)
                  }
                })
              );
            }}
            type="button"
            className="btn btn-xs btn-danger text-white"
          >
            Delete
          </a>
        </div>
      </React.Fragment>
    );
  }

  async deleteMember(member_id) {
    await this.props.deleteMember(member_id, 2, this.state.currentPage); //2 Equal Writer
    setTimeout(() => {
      this.getMembers(this.state.currentPage);
    }, 100);
  }

  formatLastDateTime(value) {
    if (value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatGoldCoin(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatSilverCoin(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatWallet(value) {
    // return new Intl.NumberFormat("en-GB", {
    //   style: "decimal",
    // }).format(value);

    return (
      <NumberFormat
        thousandSeparator={true}
        displayType="text"
        value={Math.round(value)}
      />
    );
  }

  formatSilverWallet(value) {
    // return new Intl.NumberFormat("en-GB", {
    //   style: "decimal",
    // }).format(value);

    return (
      <NumberFormat
        thousandSeparator={true}
        displayType="text"
        value={Math.round(value)}
      />
    );
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">Member Name</th>
            <th className="text-center">Email</th>
            <th className="text-center">Phone</th>
            <th className="text-center">Username</th>
            <th className="text-center">Gold Coin</th>
            <th className="text-center">Silver Coin</th>
            <th className="text-center">Last Update</th>
            <th className="text-center">Update By</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {this.state.members.map((member, index) => {
            return (
              <tr key={member.member_id}>
                <td className="align-middle">{member.member_name}</td>
                <td className="align-middle">{member.member_email}</td>
                <td className="text-center align-middle">
                  {member.member_phone}
                </td>
                <td className="text-center align-middle">
                  {member.member_username}
                </td>
                <td className="text-center align-middle">
                  {this.formatGoldCoin(member.member_coin)}
                </td>
                <td className="text-center align-middle">
                  {this.formatSilverCoin(member.member_silver_coin)}
                </td>
                <td className="text-center align-middle">
                  {this.formatLastDateTime(member.updated_datetime)}
                </td>
                <td className="text-center align-middle">
                  {member.updated_by}
                </td>

                <td className="text-center align-middle" width="120">
                  {this.btnFormatter(member.member_id)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getMembers(page);
  }

  handleChange = (e) => {
    const val = e.target.value;

    this.setState({ value: val }, () => {
      this.changeSearch(val);
    });
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">Active Writer</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        return (
                          isAllowAction() &&
                          this.props.history.push(`/createWriter`)
                        );
                      }}
                      className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Add Writer
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="pb-2">
                <Row>
                  <Col sm="6" className="pr-1">
                    <FormGroup>
                      <label>นักเขียน</label>

                      <FormInput
                        name="name"
                        id="name"
                        placeholder=""
                        onChange={(e) =>
                          this.setState(
                            { name: e.target.value, currentPage: 0 },
                            () => {
                              this.getMembers(this.state.currentPage);
                            }
                          )
                        }
                        value={this.state.name}
                      />
                      {/* {this.bindingWriterName()} */}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.members.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ memberReducer }) => ({
  memberReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Writers);

import { httpClient } from "../utils/HttpClient";

import {
  HTTP_REPORT_GIFT_SUCCESS,
  HTTP_REPORT_GIFT_FETCHING,
  HTTP_REPORT_GIFT_FAILED,
} from "../constants/report";

import { server } from "../constants";
import moment from "moment";

const setStateReportGiftToSuccess = (payload) => ({
  type: HTTP_REPORT_GIFT_SUCCESS,
  payload: payload,
});

const setStateReportGiftToFetching = () => ({
  type: HTTP_REPORT_GIFT_FETCHING,
});

const setStateReportGiftToFailed = () => ({
  type: HTTP_REPORT_GIFT_FAILED,
});

export const getReportGift = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportGiftToFetching());
    await doGetReportGift(dispatch,value);
  };
};

const doGetReportGift = async (dispatch, value) => {
  try {
    let params = "";

    if (value) {
      if (value.start_date) value.start_date = value.start_date + " 00:00:00";
        else value.start_date = "";

        if (value.end_date) value.end_date = value.end_date + " 23:59:59";
        else
          value.end_date = moment(new Date())
            .format("YYYY-MM-DD 23:59:59");

          params = value
    } else {
      params = {
        page: ""
      };
    }

    const token = localStorage.getItem("token");

    const result = await httpClient.get(`${server.REPORT_URL}/gift`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });
    dispatch(setStateReportGiftToSuccess(result.data));

  } catch (err) {
    dispatch(setStateReportGiftToFailed());
  }
  // if (value) {
  //   if (value.start) {
  //     dataFilter = "?start_date=" + value.start + " 00:00.00";
  //   }

  //   if (value.end) {
  //     if(!value.start)
  //     dataFilter += "?end_date=" + value.end + " 23:59.00";
  //     else
  //     dataFilter += "&end_date=" + value.end + " 23:59.00";
  //   }

  //   if (value.bookTitle) {
  //     if (dataFilter.length > 0)
  //     dataFilter += "&book_title=" + value.bookTitle;
  //     else dataFilter += "?book_title=" + value.bookTitle;
  //   }
  // }
  // else 
  //   dataFilter = "?end_date=" + moment(new Date()).format("YYYY-MM-DD 23:59.00");

  // httpClient
  // .get(`${server.REPORT_URL}/gift` + dataFilter)
  // .then((result) => {
  //   dispatch(setStateReportGiftToSuccess(result.data));
  // })
  // .catch((error) => {
  //   alert(JSON.stringify(error));
  //   dispatch(setStateReportGiftToFailed());
  // });
  
};

import React, { Component } from "react";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormInput,
  FormGroup,
  FormSelect,
} from "shards-react";
import Swal from "sweetalert2";
import moment from "moment";
import ContentPagination from "../Layout/Common/Pagination";

const isAllowAction = () => {
  var result = localStorage.getItem("user_profile");
  if (result) {
    const priv_allow = JSON.parse(result).writer;

    if (priv_allow === "Y") return true;
    else {
      Swal.fire({
        icon: "error",
        text: "No privilege!",
      });
      return false;
    }
  }
};

class Editors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      currentPage: 0,
      totalPages: 1,
      perPage: 10,
      loading: false,
      name: "",
      email: "",
    };
  }

  async componentDidMount() {
    await this.getMembers(this.state.currentPage);
    if (this.props.memberReducer.result) {
      this.setState({ members: this.props.memberReducer.result });
    }
  }

  async getMembers(page) {
    this.setState({ loading: true });

    await this.props.getAllMember(3, this.state.name, this.state.email, page); //get member_type =3 (3 equal Editor)

    const { members, total } = this.props.memberReducer;
    const totalPages = Math.ceil(total / this.state.perPage);

    this.setState({
      members,
      totalPages,
      loading: false,
    });
  }

  btnFormatter(member_id) {
    let username = "";
    var result = localStorage.getItem("user_profile");
    if (result) username = JSON.parse(result).user_username;

    return (
      <React.Fragment>
        <div className="mt-1">
          <a
            type="button"
            className="btn btn-xs btn-success text-white"
            href={"/editEditor/" + member_id}
          >
            Edit
          </a>

          <span style={{ color: "grey" }}> | </span>

          <a
            onClick={() => {
              return (
                isAllowAction() &&
                Swal.fire({
                  text: "Are you sure to delete?",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Yes, delete it!",
                  cancelButtonText: "No, cancel!",
                }).then((result) => {
                  if (result.value) {
                    this.deleteMember(member_id); // (1 equal Member,2 equal Writer)
                  }
                })
              );
            }}
            type="button"
            className="btn btn-xs btn-danger text-white"
          >
            Delete
          </a>
        </div>
      </React.Fragment>
    );
  }

  async deleteMember(member_id) {
    await this.props.deleteMember(member_id, 3, this.state.currentPage); //3 = Editor
    setTimeout(() => {
      this.getMembers(this.state.currentPage);
    }, 100);
  }

  formatLastDateTime(value) {
    if (value) {
      return moment(value)
        .format("DD/MM/YYYY HH:mm:ss");
    }
  }

  formatGoldCoin(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatSilverCoin(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatWallet(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  formatSilverWallet(value) {
    return new Intl.NumberFormat("en-GB", {
      style: "decimal",
    }).format(value);
  }

  renderTable = () => {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="text-center">ชื่อบรรณาธิการ</th>
            <th className="text-center">อีเมลล์</th>
            <th className="text-center">โทรศัพท์</th>
            <th className="text-center">Username</th>
            <th className="text-center">วันที่แก้ไขล่าสุด</th>
            <th className="text-center">แก้ไขโดย</th>
            <th className="text-center">ข้อมูลนักเขียน</th>
            <th className="text-center">ประวัติการถอนเงิน</th>
            <th className="text-center">แก้ไข/ลบ</th>
          </tr>
        </thead>
        <tbody>
          {this.state.members.map((member, index) => {
            return (
              <tr key={member.member_id}>
                <td className="align-middle">{member.member_name}</td>
                <td className="align-middle">{member.member_email}</td>
                <td className="text-center align-middle">
                  {member.member_phone}
                </td>
                <td className="text-center align-middle">
                  {member.member_username}
                </td>
                <td className="text-center align-middle">
                  {this.formatLastDateTime(member.updated_datetime)}
                </td>
                <td className="text-center align-middle">
                  {member.updated_by}
                </td>
                <td className="text-center align-middle">
                  <div className="mt-1">
                    <a
                      type="button"
                      className="btn btn-xs btn-warning"
                      href={"/manageEditor/" + member.member_id}
                    >
                      ข้อมูลนักเขียน
                    </a>
                  </div>
                </td>
                <td className="text-center align-middle">
                  <div className="mt-1">
                    <a
                      type="button"
                      className="btn btn-xs btn-accent"
                      href={"/withdraws/transaction/" + member.member_id}
                    >
                      ประวัติการถอนเงิน
                    </a>
                  </div>
                </td>

                <td className="text-center align-middle" width="120">
                  {this.btnFormatter(member.member_id)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  changePage(page) {
    this.setState({ currentPage: page });
    this.getMembers(page);
  }

  handleChange = (e) => {
    const val = e.target.value;

    this.setState({ value: val }, () => {
      this.changeSearch(val);
    });
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div style={{ padding: 10 }}>
            <Card>
              <CardHeader className="border-bottom">
                <Row>
                  <Col sm="6" className="d-flex mb-2 mb-sm-0">
                    <h6 className="mt-1">บรรณาธิการทั้งหมด</h6>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        return (
                          isAllowAction() &&
                          this.props.history.push(`/createEditor`)
                        );
                      }}
                      className=" btn btn-sm d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                    >
                      Add Editor
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="pb-2">
                <Row>
                  <Col sm="6" className="pr-1">
                    <FormGroup>
                      <label>ค้นหาบรรณาธิการ</label>

                      <FormInput
                        name="name"
                        id="name"
                        placeholder=""
                        onChange={(e) =>
                          this.setState({ name: e.target.value }, () => {
                            this.getMembers(this.state.currentPage);
                          })
                        }
                        value={this.state.name}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>

              <div
                className={
                  "content-loading-wrapper " +
                  (this.state.loading ? "loading" : "")
                }
              >
                {this.renderTable()}
              </div>

              <CardBody className="pt-2">
                <nav className="text-right">
                  {this.state.members.length > 0 && (
                    <ContentPagination
                      currentPage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.changePage.bind(this)}
                      pageShow={5}
                    />
                  )}
                </nav>
              </CardBody>
            </Card>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ memberReducer }) => ({
  memberReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editors);

import {
  HTTP_MEMBERS_SUCCESS,
  HTTP_MEMBERS_FETCHING,
  HTTP_MEMBERS_FAILED,
  HTTP_MEMBER_SUCCESS,
  HTTP_MEMBER_FETCHING,
  HTTP_MEMBER_FAILED,
  HTTP_WRITERS_SUCCESS,
  HTTP_WRITERS_FETCHING,
  HTTP_WRITERS_FAILED,
  HTTP_WITHDRAW_TRANSACTIONS_SUCCESS,
  HTTP_WITHDRAW_TRANSACTIONS_FETCHING,
  HTTP_WITHDRAW_TRANSACTIONS_FAILED,
} from "../constants/member";


const initialState = {
  members: null,
  writers: null,
  withdrawTransactions: null,
  total: 0,
  isFetching: false,
  isError: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HTTP_MEMBERS_SUCCESS:
      return {
        ...state,
        members: payload.members,
        total: payload.total,
        isFetching: false,
        isError: false,
      };
    case HTTP_MEMBERS_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_MEMBERS_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case HTTP_MEMBER_SUCCESS:
      return { ...state, result: payload, isFetching: false, isError: false };
    case HTTP_MEMBER_FAILED:
      return { ...state, result: null, isFetching: false, isError: true };
    case HTTP_MEMBER_FETCHING:
      return { ...state, result: null, isFetching: true, isError: false };
    case HTTP_WRITERS_SUCCESS:
      return { ...state, writers: payload, isFetching: false, isError: false };
    case HTTP_WRITERS_FAILED:
      return { ...state, writers: null, isFetching: false, isError: true };
    case HTTP_WRITERS_FETCHING:
      return { ...state, writers: null, isFetching: true, isError: false };
    case HTTP_WITHDRAW_TRANSACTIONS_SUCCESS:
      return { 
        ...state, withdrawTransactions: payload.transactions, 
        isFetching: false, 
        isError: false,
        total: payload.total
    };
    case HTTP_WITHDRAW_TRANSACTIONS_FAILED:
      return { ...state, withdrawTransactions: null, isFetching: false, isError: true };
    case HTTP_WITHDRAW_TRANSACTIONS_FETCHING:
      return { ...state, withdrawTransactions: null, isFetching: true, isError: false };
    default:
      return state;
  }
};

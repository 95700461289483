import { httpClient } from "./../utils/HttpClient";

import {
    HTTP_REPORT_PAYMENT_SUCCESS,
    HTTP_REPORT_PAYMENT_FETCHING,
    HTTP_REPORT_PAYMENT_FAILED,
} from "../constants/report";

import {
  server,
} from "../constants";

import moment from "moment";

/* REPORT PAYMENT ACTION */
const setStateReportPaymentToSuccess = (payload) => ({
  type: HTTP_REPORT_PAYMENT_SUCCESS,
  payload: payload,
});

const setStateReportPaymentToFetching = () => ({
  type: HTTP_REPORT_PAYMENT_FETCHING,
});

const setStateReportPaymentToFailed = () => ({
  type: HTTP_REPORT_PAYMENT_FAILED,
});


/* Get Report Payment */
export const getReportPayment = (value) => {
  return async (dispatch) => {
    dispatch(setStateReportPaymentToFetching());
    await doGetReportpayment(dispatch,value);
  };
};

const doGetReportpayment = async (dispatch, value) => {
  let param = {};

  if (value) {
    if (value.start) value.start = value.start + " 00:00.00";
    else value.start = "";

    if (value.end) value.end = value.end + " 23:59.00";
    else
      value.end = moment(new Date())
        .format("YYYY-MM-DD 23:59.00");

    param = {
      start_date: value.start,
      end_date: value.end,
      page: value.page,
      payment_type: value.payment_type,
      package_coin_type: value.package_coin_type,
      payment_result: value.payment_result,
      member_name: value.member_name,
      member_email: value.member_email,
    };
  }
  else{
    param = {
      page: '',
    };
  }

  const token = localStorage.getItem("token");

  await httpClient
    .get(`${server.REPORT_URL}/payment`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: param,
    })
    .then((result) => {
      dispatch(setStateReportPaymentToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateReportPaymentToFailed());
    });
};


import React, { Component, useEffect, useState } from "react";
import { useParams, useHistory, NavLink } from "react-router-dom";
import moment from "moment";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions/member.action";
import { connect } from "react-redux";
import { httpClient } from "../../utils/HttpClient";
import { LoadingOutlined } from "@ant-design/icons";

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "shards-react";
import { Descriptions, Empty, Tabs, Radio } from "antd";

const { TabPane } = Tabs;

export default function MarketingReports() {
  const history = useHistory();
  const [marketingReport, setMarketingReport] = useState(false);

  useEffect(() => {
    const userProfile = JSON.parse(localStorage.getItem("user_profile"));

    if (userProfile) {
      if (userProfile.marketing_report === "Y") setMarketingReport(true);
    }
  }, []);

  return (
    <React.Fragment>
      <Row className="mt-4 ml-1">
        <Col className="form-group">
          <ListGroup flush>
            <ButtonGroup>
              {marketingReport && (
                <>
                  <Row>
                    <Col>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/marketing_reports/book_rank"
                      >
                        ยอดการซื้อหนังสือ
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/marketing_reports/each_chapter"
                      >
                        ยอดขายแต่ละบท
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/marketing_reports/purchases"
                      >
                        Purchase History
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/marketing_reports/topup"
                      >
                        Top up Transaction
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/marketing_reports/gift"
                      >
                        ประวัติการส่งของขวัญ
                      </Button>
                      <Button
                        squared
                        theme="white"
                        tag={NavLink}
                        to="/marketing_reports/all_book"
                      >
                        ยอดซื้อหนังสือทั้งเล่ม
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ButtonGroup>
          </ListGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

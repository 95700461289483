import { httpClient } from "../utils/HttpClient";

import {
  HTTP_PURCHASE_SUCCESS,
  HTTP_PURCHASE_FETCHING,
  HTTP_PURCHASE_FAILED,
} from "../constants/member";

import { server } from "../constants";

const setStateMemberPurchaseToSuccess = (payload) => ({
  type: HTTP_PURCHASE_SUCCESS,
  payload: payload,
});

const setStateMemberPurchaseToFetching = () => ({
  type: HTTP_PURCHASE_FETCHING,
});

const setStateMemberPurchaseToFailed = () => ({
  type: HTTP_PURCHASE_FAILED,
});

/* Get Member Purchase */
export const getMemberPurchase = (member_id, value) => {
  return async (dispatch) => {
    dispatch(setStateMemberPurchaseToFetching());
    await doGetMemberPurchase(dispatch, member_id, value);
  };
};

const doGetMemberPurchase = (dispatch, member_id, value) => {
  let dateFilter = "";

  if (value) {
    if (value.start) {
      dateFilter = "?start_date=" + value.start + " 00:00.00";
    }

    if (value.end) {
      if (!value.start) dateFilter += "?end_date=" + value.end + " 23:59.00";
      else dateFilter += "&end_date=" + value.end + " 23:59.00";
    }
  }

  const token = localStorage.getItem("token");

  httpClient
  .get(`${server.REPORT_URL}/member_trans_list/id/${member_id}` + dateFilter,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((result) => {
      dispatch(setStateMemberPurchaseToSuccess(result.data));
    })
    .catch((error) => {
      alert(JSON.stringify(error));
      dispatch(setStateMemberPurchaseToFailed());
    });
};

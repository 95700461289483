import React, { Component } from "react";
import * as actions from "../../../actions/banner.action";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
} from "shards-react";
import Swal from "sweetalert2";
import MenuBanner from "./menuBanner";

class Banner extends Component {
  async componentDidMount() {
    this.props.getBanner();
  }

  formatDate(date) {
    if (date) return new Date(date).toLocaleDateString("en-GB");
  }

  formatDisplayBanner(status) {
    if (status === "A") {
      return (
        <a type="button" className="btn btn-sm btn-success">
          แสดง
        </a>
      );
    } else {
      return (
        <a type="button" className="btn btn-sm btn-warning">
          ไม่แสดง
        </a>
      );
    }
  }

  createRows = () => {
    try {
      const { result, isFetching } = this.props.bannerReducer;
      return (
        !isFetching &&
        result != null &&
        result.map((item) => (
          <tr key={item.banner_id}>
            <td>{item.banner_name}</td>

            <td style={{ textAlign: "center" }}>
              <img
                id="banner_image"
                name="banner_image"
                src={item.banner_image}
                width="180px"
              />
            </td>

            <td style={{ textAlign: "center" }}>
              {this.formatDate(item.startdate)}
            </td>

            <td style={{ textAlign: "center" }}>
              {this.formatDate(item.enddate)}
            </td>

            <td style={{ textAlign: "center" }}>
              {this.formatDisplayBanner(item.banner_status)}
            </td>

            <td style={{ textAlign: "center" }}>
              <button
                type="button"
                className="btn btn-sm btn-primary mr-2"
                onClick={() =>
                  this.props.history.push(`/banners/edit/${item.banner_id}`)
                }
              >
                Edit
              </button>

              <button
                onClick={() => {
                  Swal.fire({
                    title: "Are you sure to delete?",
                    text: "You won't be able to revert this!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!",
                  }).then((result) => {
                    if (result.value) {
                      this.props.deleteBanner(item.banner_id);
                    }
                  });
                }}
                type="button"
                className="btn btn-sm  btn-danger"
              >
                Delete
              </button>
            </td>
          </tr>
        ))
      );
    } catch (e) {}
  };

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <div style={{ padding: 10 }}>
          <MenuBanner />
          <Card>
            <CardHeader className="border-bottom">
              <Row>
                <Col sm="6" className="d-flex mb-2 mb-sm-0">
                  <h6 className="mt-1">Banner</h6>
                </Col>
                <Col>
                  <Button
                    onClick={() => this.props.history.push(`/banners/create`)}
                    className=" btn btn-md d-flex ml-auto mr-sm-0 mt-3 mt-sm-0"
                  >
                    Add Banner
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table id="campaign_table" className="table mb-0">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>ชื่อ Banner</th>
                    <th style={{ textAlign: "center" }}>รูป Banner</th>
                    <th style={{ textAlign: "center" }}>วันที่เริ่มต้น</th>
                    <th style={{ textAlign: "center" }}>วันที่สิ้นสุด</th>
                    <th style={{ textAlign: "center" }}>สถานะ</th>
                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>{this.createRows()}</tbody>
              </table>
            </CardBody>
          </Card>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ bannerReducer, appReducer }) => ({
  bannerReducer,
  appReducer,
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);

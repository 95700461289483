import { httpClient } from "../utils/HttpClient";

import {
    HTTP_CHAPTER_RANK_FETCHING,
    HTTP_CHAPTER_RANK_SUCCESS,
    HTTP_CHAPTER_RANK_FAILED,
  } from "../constants/report";
  

import {server} from "../constants"
import moment from "moment";

const setStateChapterRankToSuccess = payload => ({
    type: HTTP_CHAPTER_RANK_SUCCESS,
    payload: payload
  });
  
  const setStateChapterRankToFetching = () => ({
    type: HTTP_CHAPTER_RANK_FETCHING
  });
  
  const setStateChapterRankToFailed = () => ({
    type: HTTP_CHAPTER_RANK_FAILED
  });

  /* Get Report Chapter Rank */
export const getReportChapterRank = (value) => {
    return async (dispatch) => {
      dispatch(setStateChapterRankToFetching());
      await doGetReportChapterRank(dispatch,value);
    };
  };
  
  const doGetReportChapterRank = async (dispatch, value) => {
    let dataFilter = "";
  
    if (value) {
      if (value.start) {
        dataFilter = "?start_date=" + value.start + " 00:00.00";
      }
  
      if (value.end) {
        if(!value.start)
        dataFilter += "?end_date=" + value.end + " 23:59.00";
        else
        dataFilter += "&end_date=" + value.end + " 23:59.00";
      }
  
      if (value.bookTitle) {
        if (dataFilter.length > 0)
        dataFilter += "&book_title=" + value.bookTitle;
        else dataFilter += "?book_title=" + value.bookTitle;
      }

      dataFilter += "page=" + value.page;
    }
    else 
      dataFilter = "?end_date=" + moment(new Date()).format("YYYY-MM-DD 23:59.00");
  
    const token = localStorage.getItem("token");

    try {
      const result = await httpClient
      .get(`${server.REPORT_URL}/chapter_rank`, {
        params: {
          start_date: value.start ? `${value.start} 00:00.00` : '',
          end_date: value.end ? `${value.end} 23:59.59` : '',
          book_title: value.bookTitle || '',
          page: value.page
        },
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
      dispatch(setStateChapterRankToSuccess(result.data))
    } catch(error) {
      alert(JSON.stringify(error));
      dispatch(setStateChapterRankToFailed());
    }
  };
  
  
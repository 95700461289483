import {
    HTTP_CAMPAIGNS_SUCCESS,
    HTTP_CAMPAIGNS_FETCHING,
    HTTP_CAMPAIGNS_FAILED,

    HTTP_CAMPAIGN_SUCCESS,
    HTTP_CAMPAIGN_FETCHING,
    HTTP_CAMPAIGN_FAILED,
  } from "../constants";
  
  const initialState = {
    campaigns: null,
    result: null,
    isFetching: false,
    isError: false,  
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case HTTP_CAMPAIGNS_SUCCESS:
        return {
          ...state,
          campaigns: payload.campaigns,
          total: payload.total,
          isFetching: false,
          isError: false,
        };
      case HTTP_CAMPAIGNS_FAILED:
        return { ...state, campaigns: null, isFetching: false, isError: true };
      case HTTP_CAMPAIGNS_FETCHING:
        return { ...state, campaigns: null, isFetching: true, isError: false };

      case HTTP_CAMPAIGN_SUCCESS:        
        return { ...state, result: payload, isFetching: false, isError: false };
      case HTTP_CAMPAIGN_FAILED:
        return { ...state, result: null, isFetching: false, isError: true };
      case HTTP_CAMPAIGN_FETCHING:
        return { ...state, result: null, isFetching: true, isError: false };
      default:
        return state;
    }
  };
  